import {
  CREATE_BRANCH,
  GET_BRANCH_LIST,
  PUSH_NOTI_PRODUCT_SUCCESS,
  DEACTIVE_NOTI_PRODUCT
} from "actions/types"
import { takeLatest, call, put, select, delay } from 'redux-saga/effects'
import { LINK_API } from 'constants/API.js'
import axios from 'axios'
import responseCode from "constants/errorCode.js"

const getToken = (state) => state.userInfo
//const getSaveSetting = (state) => state.settingList.data

// Create Branch
function * watchCreateBranch (data) {
  const token = yield select(getToken)
  const { response, error } = yield call(createBranch, data.data, token.data.token)
  if (response) {
    const result = response.data
    if (result.errorCode === 1) {
      yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Tạo chi nhánh thành công", color: 'success' })
      yield put({ type: GET_BRANCH_LIST })
      yield delay(2000)
      yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
    } else {
      if (responseCode[`${result.name}`].vi) {
        yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: responseCode[`${result.name}`].vi, color: 'error' })
        yield delay(2000)
        yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
      } else {
        yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Tạo chi nhánh thất bại", color: 'error' })
        yield delay(2000)
        yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
      }
    }
  } else {
    if (error.response.data.name) {
      yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: responseCode[`${error.response.data.name}`].vi, color: 'error' })
      yield delay(2000)
      yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
    } else {
      yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Tạo chi nhánh thất bại", color: 'error' })
      yield delay(2000)
      yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
    }
  }
}
function createBranch (data, checktoken) {
  axios.defaults.headers.common.Authorization = checktoken
  return axios.post(LINK_API.CREATE_BRANCH, data)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

const createBranchSaga = [
  takeLatest(CREATE_BRANCH, watchCreateBranch)
]
export default createBranchSaga

