import {
    CREATE_BRANCH_FOR_PARTNER,
    GET_PRODUCT_TO_BRANCHS,
    PUSH_NOTI_PRODUCT_SUCCESS,
    DEACTIVE_NOTI_PRODUCT,
    SAVE_NOTIFICATION,
  } from "actions/types"
  import { takeLatest, call, put, select, delay } from 'redux-saga/effects'
  import { LINK_API } from 'constants/API.js'
  import axios from 'axios'
  import responseCode from "constants/errorCode.js"
  
  const getToken = (state) => state.userInfo

  // Create Production Area
  function * watchCreateBranch (data) {
    yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Đang xử lý", color: 'warning' })
    yield delay(2000)
    yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
    const token = yield select(getToken)
    const { response, error } = yield call(createBranch, data.data, token.data.token)
    if (response) {
      const result = response.data
      if (result.errorCode === 1) {
        yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Tạo chi nhánh thành công", color: 'success' })
        yield put({ type: SAVE_NOTIFICATION, data: { type: 'success', message: "Tạo chi nhánh thành công " + result.data.uuid} })
        yield put({ type: GET_PRODUCT_TO_BRANCHS, id: data.data.uuid, page: 1, size:  5})
        yield delay(2000)
        yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
      } else {
        if (responseCode[`${result.name}`].vi) {
          yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: responseCode[`${result.name}`].vi, color: 'error' })
          yield put({ type: SAVE_NOTIFICATION, data: { type: 'success', message: responseCode[`${result.name}`].vi} })
          yield delay(2000)
          yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
        } else {
          yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Tạo chi nhánh thất bại", color: 'error' })
          yield put({ type: SAVE_NOTIFICATION, data: { type: 'success', message: "Tạo chi nhánh thất bại"} })
          yield delay(2000)
          yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
        }
      }
    } else {
      if (error.response.data.name) {
        yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: responseCode[`${error.response.data.name}`].vi, color: 'error' })
        yield put({ type: SAVE_NOTIFICATION, data: { type: 'success', message: responseCode[`${error.response.data.name}`].vi} })
        yield delay(2000)
        yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
      } else {
        yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Tạo chi nhánh thất bại", color: 'error' })
        yield put({ type: SAVE_NOTIFICATION, data: { type: 'success', message: "Tạo chi nhánh thất bại"} })
        yield delay(2000)
        yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
      }
    }
  }
  function createBranch (data, checktoken) {
    axios.defaults.headers.common.Authorization = checktoken
    return axios.post(LINK_API.ADD_BRANCH + data.uuid, data)
      .then(response => ({ response }))
      .catch(error => ({ error }))
  }
  
  
  const createAreaSaga = [
    takeLatest(CREATE_BRANCH_FOR_PARTNER, watchCreateBranch)
  ]
  export default createAreaSaga
  
  