import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import AutosizeInput from 'react-input-autosize';
import Draggable from 'react-draggable';
import { Button, Modal, ModalBody, Input } from "reactstrap"
import EditIcon from "mdi-react/PencilIcon";
import DeleteIcon from "mdi-react/CloseIcon";
import { CompactPicker } from 'react-color'

function TextBox(props) {
  const [value, setValue] = React.useState("");
  const [modal, setModal] = React.useState(false);
  const [color, setColor] = React.useState("#000");
  const [fontSize, setFontSize] = React.useState(15);
  const [remove, setRemove] = React.useState(false);
  const [positionTextBox, setPositionTextBox] = React.useState({
    x: 15, y: -26, fontSize: 15, color: "#000000",
  })

  const {
    handleSetData,
    id,
    item
  } = props;


  const handleModal = () => {
    setModal(!modal)
  }

  const handleDragTextBox = (e, ui) => {
    const newPosition = {
      ...positionTextBox,
      x: ui.x,
      y: ui.y
    }
    setPositionTextBox(newPosition)
    handleSetData(value, id, newPosition)
  }

  const handleEdit = () => {
    setModal(true)
  }


  const handleSaveEdit = () => {
    const newPosition = {
      ...positionTextBox,
      fontSize: parseInt(fontSize),
      color: color
    }

    setPositionTextBox(newPosition)
    handleSetData(value, id, newPosition)
    setModal(false)
  }



  React.useEffect(() => {
    if(item) {
      setPositionTextBox({
        x: item.x,
        y: item.y,
        fontSize: item.fontSize,
        color: item.color
      })

      setValue(item.value)
    }
  }, [item])

  const enterValue = () => {
    return <div>
      <div>
        <div className="mb-2">
          Màu chữ:
        </div>
        <div>
          <CompactPicker color={color} onChange={newColor => setColor(newColor.hex)} />
        </div>
      </div>
      <div className="mt-3">
        <div className="mb-2">
          Kích thước chữ:
        </div>
        <div style={{ width: 240 }}>
          <Input
            type="number"
            value={fontSize}
            onChange={(e) => setFontSize(e.target.value)}
          />
        </div>
        {
          fontSize ?
            null
            :
            <div className="text-danger font-size-sm mt-2">
              Thiếu kích thước chữ
              </div>
        }
      </div>

      <div className="d-flex mt-4">
        <Button onClick={() => setModal(false)} color="neutral-info" className="mr-3">
          Huỷ
        </Button>
        <Button disabled={!fontSize} onClick={() => handleSaveEdit()} color="info">
          Lưu
        </Button>
      </div>
    </div>
  }

  return (
    <>
      <div style={{ position: "absolute", display: remove ? "none" : "" }}>
        <Draggable position={{ x: positionTextBox.x, y: positionTextBox.y }} onDrag={(e, ui) => handleDragTextBox(e, ui)}>
          <div className="d-flex">
            <AutosizeInput
              inputStyle={{ fontSize: positionTextBox.fontSize, color: positionTextBox.color }}
              name="form-field-name"
              value={value}
              onChange={(event) => {
                setValue(event.target.value);
                handleSetData(event.target.value, id, positionTextBox)
              }}
            />
            <div style={{ display: "block", width: 20, height: 20, marginTop: "-20px" }}>
              <Button onClick={() => {
                handleSetData(null, id, null)
                setRemove(true)
              }} color="link" className="p-0" style={{ height: 5 }}>
                <DeleteIcon size={13} className="text-danger font-size-sm" />
              </Button>
              <Button onClick={() => handleEdit()} color="link" className="p-0" style={{ height: 5, marginTop: "-10px" }}>
                <EditIcon size={13} className="text-danger font-size-sm" />
              </Button>
            </div>
          </div>
        </Draggable>

        <Modal zIndex={2000} centered isOpen={modal} toggle={handleModal}>
          <ModalBody className="d-flex justify-content-center">
            {enterValue()}
          </ModalBody>
        </Modal>
      </div>
    </>
  );
}

export default withTranslation('common')(connect(null, null)(TextBox));