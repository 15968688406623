import axios from 'axios';
import LoadingTable from "components/LoadingTable/index.js";
import Pagination from "components/Pagination/index.jsx";
import { LINK_API } from 'constants/API.js';
import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ReactTable from 'react-table-6';
import "../../css/reactTable.css";

function StampList(props) {
  const [activityRender, setActivity] = React.useState([])
  const [loadingState, setLoading] = React.useState(true)
  const { t } = props;

  const {
    userInfo
  } = props;

  const genTable = React.useCallback((data) => {
    // let dataRender = data.map((item, index) => {
    //   let dataDetail = []
    //   if (item.AccessInfo && item.AccessInfo.length > 0) {
    //     dataDetail = item.AccessInfo.map(itemDiary => {
    //       return {
    //         stampId: itemDiary.id,
    //         type: itemDiary.type === 0
    //           ? t('statistic.notExist')
    //           : itemDiary.type === 1
    //             ? t('statistic.stamp_trial')
    //             : itemDiary.type === 2
    //               ? t('activeStamp.unit')
    //               : itemDiary.type === 3
    //                 ? t('activeStamp.box')
    //                 : t('statistic.stamp_object'),
    //         scanTotal: itemDiary.count,
    //         scanDate: itemDiary.info && itemDiary.info.length > 0
    //           ?
    //           itemDiary.info.map(prop => {
    //             return <div>{prop.date}</div>
    //           })
    //           : <div> </div>
    //       }
    //     })
    //   }
    //   return {
    //     productId: item.uuid,
    //     name: item.name,
    //     dataDetail: dataDetail
    //   }
    // })
    setActivity(data)
    setLoading(false)
  }, [])

  const getData = async (startDate, endDate) => {
    axios.defaults.headers.common.Authorization = userInfo.data.token
    const response = await axios({
      method: 'GET',
      url: LINK_API.GET_ALL_SCAN_STAMP.concat('?startTime=', startDate, '&endTime=', endDate)
    })
    if (response && response.data && response.data.data) {
      genTable(response.data.data)
    }
    else {
      genTable([])
    }
  }

  useEffect(() => {
    setLoading(true)
    getData(props.startDate, props.endDate)

  }, [props.startDate, props.endDate]);

  const columns = React.useMemo(
    () => [
      {
        Header: t('import.traceabilityCode'),
        accessor: "id",
        sortable: false
      },
      {
        Header: t('statistic.scanStampDate'),
        accessor: "date",
        filterable: false
      },
      {
        Header: t('statistic.location'),
        accessor: "location",
        filterable: false
      },
      {
        Header: t('stamp.type'),
        accessor: "type",
        filterable: false
      },
      {
        Header: t('price.product'),
        accessor: "pt",
        filterable: false
      },
    ],
    [t]
  );

  return (
    <>
      <div className="divider" />
      <div className="card-body pt-2 px-4 pb-4">
        <ReactTable
          noDataText={t('noData')}
          data={activityRender}
          columns={columns}
          previousText={"<"}
          nextText={">"}
          rowsText={t('row')}
          ofText="/"
          // manual
          defaultPageSize={5}
          showPaginationBottom={true}
          sortable={false}
          resizable={true}
          PaginationComponent={Pagination}
          // pages={totalPage}
          className="-striped -highlight"
          loading={loadingState}
          LoadingComponent={LoadingTable}
          // SubComponent={row => {
          //   return (
          //     <SubTable columns={columnSubTable} sizePerPage={5} data={row.original.dataDetail} />
          //   )
          // }}
        // onFetchData={async (state, instance) => {
        //   setLoading(true)
        //   let x = state.page + 1;
        //   const page = x;
        //   const size = state.pageSize;
        // }}
        />
      </div>
      {/* </Card> */}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
    allScanStamp: state.allScanStamp,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getAllScanStamp: (data) => dispatch({ type: "GET_ALL_SCAN_STAMP", data: data }),
});

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(StampList));