import LoadingTable from "components/LoadingTable/index.js";
import Pagination from "components/Pagination/index.jsx";
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ReactTable from 'react-table-6';
import { Card, Container } from 'reactstrap';
import "../../css/reactTable.css";
import Header from "components/Headers/Header"
import { LINK_API } from 'constants/API'
import axios from 'axios';
import moment from 'moment';

function ObjectProductionList(props) {

  const { t, userInfo } = props;
  const [totalPage, setTotalPage] = React.useState([])
  const [loadingState, setLoading] = React.useState(false)
  const [stampList, setStampList] = React.useState([])
  const columns = React.useMemo(
    () => [
      {
        accessor: "id",
        filterable: false,
        className: "center",
        maxWidth: 150,
        Header: () => (
          <div style={{
            textAlign: "center"
          }}>
            STT
          </div>
        )
      },
      {
        Header: "Tên",
        minWidth: 107,
        accessor: "name",
        filterable: false
      },
      {
        Header: "Ngày cấp",
        accessor: "date",
        filterable: false
      }
    ],
    [t]
  );

  React.useEffect(() => {
    axios.get(LINK_API.GET_SAMPLE_STAMP_FOR_USER)
      .then(res => {
        genTable(res.data.data)
      })
      .catch(err => console.log("err", err))
  }, [])
  
  const genTable = React.useCallback((listData) => {
    if (listData.length > 0) {
      let stamp = listData.map((prop, index) => {
        return {
          id: index + 1,
          name: prop.name,
          uuid: prop.uuid,
          date: moment(prop.createdAt).format("DD/MM/YYYY")
        }
      })
      setStampList(stamp)
      setLoading(false)
    }

  }, [])

  return (
    <>
      <Header />
      <Container className='mt--7' fluid>
        <Card className="card-box mb-5">
          <div className="card-header py-3 d-flex justify-content-between">
            <div className=" font-weight-bold card-header--title font-size-lg">Danh sách tem mẫu</div>
          </div>
          <div className="divider" />
          <div className="card-body pt-2 px-4 pb-4">
            <ReactTable
              noDataText={t('noData')}
              data={stampList}
              columns={columns}
              previousText={"<"}
              nextText={">"}
              rowsText={t('row')}
              ofText="/"
              loading={loadingState}
              LoadingComponent={LoadingTable}
              defaultPageSize={5}
              showPaginationBottom={true}
              sortable={false}
              resizable={true}
              PaginationComponent={Pagination}
              className="-striped -highlight"
            />
          </div>
        </Card>
      </Container>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
    userProfile: state.userProfile.data,
    objectList: state.objectList,
    permission: state.permission.data
  }
}

const mapDispatchToProps = (dispatch) => ({
  getObjectList: (page, size) => dispatch({ type: "GET_OBJECT_LIST", page: page, size: size }),
  getObjectFilter: (page, name, value, size) => dispatch({ type: "GET_OBJECT_PRODUCTION_FILTER", page: page, name: name, value: value, size: size }),
});

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(ObjectProductionList));