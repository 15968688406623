import {

    GET_ALL_DISTRIBUTOR,
    GET_ALL_DISTRIBUTOR_SUCCESS,
    GET_ALL_DISTRIBUTOR_FAILURE,
    
    GET_ALL_DISTRIBUTOR_FILTER
} from "actions/types"
import axios from 'axios'
import { LINK_API } from 'constants/API.js'
import { call, put, select, takeLatest } from 'redux-saga/effects'
  
const getToken = (state) => state.userInfo
const getFullDis = (state) => state.allDistributor.fullData
const getTotal = (state) => state.allDistributor.totalItem
// Get approve list admin
function * getAdminList (page) {
    try {
        const tokenInfo = yield select(getToken)
        let fullData = yield select(getFullDis)
        const totalItem = yield select(getTotal)
       // const setting_data = yield select(getSaveSetting)
       // const size = setting_data.rowOfPage ? setting_data.rowOfPage : 5
       const size = page.size;
        if (fullData[`${page.page}`]) {
          yield put({
            type: GET_ALL_DISTRIBUTOR_SUCCESS,
            data: fullData[`${page.page}`],
            fullData: fullData
          })
        }
        const response = yield call(fetchAdminList, page.page, size, tokenInfo.data.token)
        const data = response.data.data
        if (totalItem !== response.data.totalItem) {
          const newList = data
          fullData[`${page.page}`] = newList
          yield put({
            type: GET_ALL_DISTRIBUTOR_SUCCESS,
            data: newList,
            errorCode: response.data.errorCode,
            totalItem: response.data.totalItem,
            totalPage: response.data.totalPage,
            fullData: fullData
          })
        } else {
          if (!fullData[`${page.page}`]) {
            const newList = data
            fullData[`${page.page}`] = newList
            yield put({
              type: GET_ALL_DISTRIBUTOR_SUCCESS,
              data: newList,
              errorCode: response.data.errorCode,
              totalItem: response.data.totalItem,
              totalPage: response.data.totalPage,
              fullData: fullData
            })
          }else{
            if(fullData[`${page.page}`] !== data){
              const newList = data
            fullData[`${page.page}`] = newList
            yield put({
              type: GET_ALL_DISTRIBUTOR_SUCCESS,
              data: newList,
              errorCode: response.data.errorCode,
              totalItem: response.data.totalItem,
              totalPage: response.data.totalPage,
              fullData: fullData
            })
            }
          }
        }
      } catch (error) {
        if(error.response.status === 401){
          yield put({ type: "CLEAN_STORE", payload: null, errorCode: 0 })
        }
        yield put({ type: GET_ALL_DISTRIBUTOR_FAILURE, payload: '', errorCode: 0 })
      }
}
// Get product list filter
function * getDistributorFilter (page) {
    try {
      const tokenInfo = yield select(getToken)
      const fullData = yield select(getFullDis)
      const totalItem = yield select(getTotal)
      //const setting_data = yield select(getSaveSetting)
      const size = page.size;
  
      if (fullData[`${page.page}`]) {
        yield put({
          type: GET_ALL_DISTRIBUTOR_SUCCESS,
          data: fullData[`${page.page}`],
          fullData: fullData
        })
      }
      const response = yield call(fetchAdminListFilter, page.page, page.name, page.value, tokenInfo.data.token, size)
      const data = response.data.data
      if (totalItem !== response.data.totalItem) {
        const newList = data
        fullData[`${page.page}`] = newList
        yield put({
          type: GET_ALL_DISTRIBUTOR_SUCCESS,
          data: newList,
          errorCode: response.data.errorCode,
          totalItem: response.data.totalItem,
          totalPage: response.data.totalPage,
          fullData: fullData
        })
      } else {
        if (!fullData[`${page.page}`]) {
          const newList = data
          fullData[`${page.page}`] = newList
          yield put({
            type: GET_ALL_DISTRIBUTOR_SUCCESS,
            data: newList,
            errorCode: response.data.errorCode,
            totalItem: response.data.totalItem,
            totalPage: response.data.totalPage,
            fullData: fullData
          })
        }
      }
    } catch (error) {
      yield put({ type: GET_ALL_DISTRIBUTOR_FAILURE, payload: '', errorCode: 0 })
    }
  }
function fetchAdminList (page, size, checktoken) {
  axios.defaults.headers.common.Authorization = checktoken
  return axios({
    method: 'GET',
    url: LINK_API.GET_ALL_DISTRIBUTOR.concat( '?page=' , page , '&size=' , size) 
  })
}
function fetchAdminListFilter (page, name, value, checktoken, size)  {
    axios.defaults.headers.common.Authorization = checktoken
    return axios({
      method: 'GET',
      url: LINK_API.GET_ALL_DISTRIBUTOR.concat( '?page=' , page , '&size=' , size + '&' , name , '=' , value) 
    })
  }
  const actions = [
    takeLatest(GET_ALL_DISTRIBUTOR, getAdminList),
    takeLatest(GET_ALL_DISTRIBUTOR_FILTER, getDistributorFilter)
  ]
  export default actions
  