import React from "react"
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import breadPath from "constants/breadcum"
import "./css/index.css"
const BreadCrumbs = (props) => {
    const history = props.history.location.pathname;
    const [route, setRoute] = React.useState([]);
    React.useEffect(()=>{
        if(history){
            if(breadPath[`${history}`]){
                setRoute([...breadPath[`${history}`]])
            }
        }
    },[history])
    return (
        <>
            <div className="mb-0 fixBread">
            <Breadcrumb className="mb-0">
                {route.map((item, idx)=>(
                    idx < (route.length - 1) ? 
                    <BreadcrumbItem key ={idx} ><a href={item.path} className="text-info font-weight-bold">{item.name}</a></BreadcrumbItem> :
                    <BreadcrumbItem key ={idx} active><span className="text-black-50 font-weight-bold">{item.name}</span></BreadcrumbItem>
                ))}
            </Breadcrumb>
            </div>
           
        </>
    )
}
export default withRouter(BreadCrumbs);