import {
  GET_SUBSCRIPTION,
  SUBSCRIPTION_SUCCESS,
  SUBSCRIPTION_FAILURE,

} from "actions/types"
import { takeLatest, call, put, select } from 'redux-saga/effects'
import { LINK_API } from 'constants/API.js'
import axios from 'axios'

const getToken = (state) => state.userInfo


// Get subcription list
function * getSubscription () {
  try {
    const tokenInfo = yield select(getToken)
    const response = yield call(fetchSubscription, tokenInfo.data.token)
    const data = response.data
    yield put({ type: SUBSCRIPTION_SUCCESS, data: data.data, errorCode: data.errorCode })
  } catch (error) {
    yield put({ type: SUBSCRIPTION_FAILURE, payload: '', errorCode: 0 })
  }
}
function fetchSubscription (checktoken) {
  axios.defaults.headers.common.Authorization = checktoken
  return axios({
    method: 'GET',
    url: LINK_API.GET_SUBSCRIPTION
  })
}


const Subscription = [
  takeLatest(GET_SUBSCRIPTION, getSubscription)
]
export default Subscription

