import React from "react";
import { withTranslation } from 'react-i18next';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import "./css/sweet.css";
function ContentAlert(...props) {
    const [modal1, setModal1] = React.useState(true);
   // const t = props[0].t;
    const toggle1 = () => setModal1(!modal1);
    const handleCancel = () => {
        props[0].onCancel();
    }
    const propChild = props[0].child;
    const [child, setChild] = React.useState(null);
    React.useEffect(()=>{
        if(propChild){
            setChild(propChild)
        }
    },[propChild])
    return (
        <Modal zIndex={2000} centered isOpen={modal1} toggle={handleCancel}>
            <ModalHeader toggle={handleCancel}>{props[0].title}</ModalHeader>
            <ModalBody>
              {child}
            </ModalBody>
            {props[0].hideCancel && props[0].hideConfirm ? null :
            <ModalFooter>
                {props[0].hideCancel ? null :
                    <Button onClick={handleCancel} color="neutral-dark" className="mx-1">
                        <span className="btn-wrapper--label">
                            {props[0].cancelBtnText ? props[0].cancelBtnText : props[0].t('cancel')}
                        </span>
                    </Button>
                }
                 {props[0].hideConfirm ? null :
                <Button onClick={toggle1} color="info" className="mx-1">
                    <span className="btn-wrapper--label">
                        {props[0].confirmBtnText ? props[0].confirmBtnText : props[0].t('confirm')}
                    </span>
                </Button>
                }
            </ModalFooter>}

        </Modal>
    )
}

export default withTranslation('common')(ContentAlert);