const initialState = { dataOne: '', dataTwo: '', check: false }
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SAVE_DATA_EDIT_EMPLOYEE":
      return {
        ...state
      }
      case "SAVE_DATA_EDIT_EMPLOYEE_SUCCESS":
      return {
        ...state,
        dataOne: action.dataOne ? action.dataOne : (action.check === false ? state.dataOne : ''),
        dataTwo: action.dataTwo ? action.dataTwo : (action.check === false ? state.dataTwo : ''),
      }
      case "SAVE_DATA_EDIT_EMPLOYEE_FAILURE":
      return {
        ...state,
      }
    default:
      return state
  }
}
export default reducer
