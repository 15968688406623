//formik
import { useFormik } from 'formik';
import React, { useEffect } from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Button, CustomInput, UncontrolledAlert } from "reactstrap";
//style
import "view/css/select.css";
import * as Yup from 'yup';
import "../../css/index.css";


const CreateArea = (props) => {
  const t = props.t;
  const {
    isSubmitting,
    onBack,
    cantBack
  } = props;
  const [employeeList, setEmployeeList] = React.useState([]);
  const permission = props.permission;
  const [checkPer, setPer] = React.useState(false)
  React.useState(() => {
      if(permission){
        setPer(permission.advanceSetting)
      }
  }, [permission])
  const renderEmp = props.renderEmp;
  useEffect(() => {
    renderEmp(1, 100)
  }, [renderEmp]);

  const empList = props.empList.data;
  useEffect(() => {
    setEmployeeList(empList);
  }, [empList]);

  const handleBack = async () => {
    let values = {
     ...formik.values
    }
    await props.mergeData(values);
    onBack();
}

  const formik = useFormik({
    initialValues: {
      employee: '',
      portal: false,
    },
    validationSchema: Yup.object({
      employee: Yup.string()
      // portal: Yup.boolean()
      //   .required(t('require')),
    }),
    onSubmit: async (values) => {
      await props.mergeData(values);
      props.onNext(values);
    },
  });
  const dataStepTwo = props.data; 
  React.useEffect(()=>{
      formik.setFieldValue("employee", dataStepTwo.employee)
      formik.setFieldValue("portal", dataStepTwo.portal)
      
  },[dataStepTwo])
  return (
    <>
        <div className="wizard-steps horizontal">
          <form onSubmit={formik.handleSubmit}>
            <div className="p-4">
              <h5 className="font-size-xl font-weight-bold">{t('settingAdvanced')}</h5>
              <p className="text-black-50 mb-4">{t('area.setting')}</p>
             { !checkPer ? <UncontrolledAlert color="warning">
              {t('notiPlan')}
            </UncontrolledAlert>:null}
              <div className="form-row ">
                <div className="form-group col-md-6 pr-3">
                <label className="font-weight-bold" >{t('area.managerInCharge')}</label>
                <CustomInput type="select" id="selectUnit" placeholder={t('area.enterManagerInCharge')}
                    value={formik.values.employee}
                    onChange={formik.handleChange}
                    disabled={!checkPer}
                    valid={formik.touched.employee && formik.errors.employee ? false : (formik.values.employee ? true : false)}
                    invalid={formik.touched.employee && formik.errors.employee ? true : false}
                    name="customSelect"
                    {...formik.getFieldProps('employee')}
                  >
                    <option value="">{t('area.enterManagerInCharge')}</option>
                    {employeeList.map((item, idx)=>(
                        <option key={idx} value={item.value}>{item.label}</option>
                    ))}
                  </CustomInput>
                  {formik.touched.employee && formik.errors.employee ? (
                    <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.employee}</p>
                  ) : null}
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-12">
                  <p><span className="text-danger">(*)</span> {t('require')}</p>
                </div>
              </div>
              <div className="form-row ">
                            <div className="form-group col-md-6 d-flex justify-content-start">
                                <Button
                                    size="lg"
                                    className="text-uppercase font-weight-bold font-size-sm"
                                    outline
                                    onClick={handleBack}
                                    disabled={isSubmitting || cantBack}
                                    color="info">
                                    {t('pre')}
                                </Button>
                            </div>
                            <div className="form-group col-md-6 d-flex justify-content-end">
                                    <Button
                                        size="lg"
                                        type="submit"
                                        className="text-uppercase font-weight-bold font-size-sm"
                                        color="info">
                                        {t('confirm')}
                                    </Button> 
                            </div>
                        </div>

            </div>

          </form>
        </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
    empList: state.renderEmp,
    permission: state.permission.data
  }
}

const mapDispatchToProps = (dispatch) => ({
  createArea: (data) => dispatch({ type: "CREATE_AREA", data: data }),
  renderEmp: (page, size) => dispatch({ type: "RENDER_EMPLOYEE_LIST", page: page, size: size }),
});

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(CreateArea));