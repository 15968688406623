import React from 'react';
import { withTranslation } from 'react-i18next';
import Lottie from 'react-lottie';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
//import css
import "./index.css";
import * as animationData from './success.json';
import axios from 'axios'
import { LINK_API } from 'constants/API'
import { toast, Zoom } from 'react-toastify';
import Countdown from 'react-countdown';
import cookie from "react-cookies"
const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData.default,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};
function Step1(props) {
    const t = props.t;
    const [countDown, setCountDown] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [disabled, setDisabled] = React.useState(false);
    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            return null;
        } else {
            // Render a countdown
            return <span style={{ fontFamily: "Muli", fontWeight: "600", marginTop: "20px" }}>{t('register.trySendMail')} <a href="# " style={{ color: "#0f7343" }}>{hours}:{minutes}:{seconds}</a></span>;
        }
    };
    // const onNextPage = () => {
    //     if (index !== null) {
    //         props.nextHandler()
    //     }
    // }

    // const active = (value) => {

    //     props.nextHandler()
    //     props.setData(value)
    // }
    const onLogIn = () => {
        props.history.push('/auth/login')
    }
    const handleSendMail = () => {
        setLoading(true);
        setDisabled(true);
        let email_save = cookie.load('email')
        let data = {
            email: email_save
        }
        axios.post(LINK_API.RESEND_EMAIL, data)
            .then(res => {
                if (res.data.errorCode === 1) {
                    toast.success('Gửi email thành công!', { containerId: 'B', transition: Zoom });
                    setLoading(false)
                    setTimeout(() => {
                        setDisabled(false);
                        setCountDown(null)
                    }, 60000)
                    setCountDown(<Countdown
                        date={Date.now() + 60000}
                        renderer={(data) => renderer(data)}
                    />)
                } else {
                    setDisabled(false)
                    setLoading(false)
                    toast.error('Gửi email thất bại! Vui lòng thử lại!', { containerId: 'B', transition: Zoom });
                }
            })
            .catch(err => {
                setDisabled(false)
                setLoading(false)
                toast.error('Gửi email thất bại! Vui lòng thử lại!', { containerId: 'B', transition: Zoom });
            })
    }
    return (
        <>
          
                <Row className='h-100'>
                    <Col md={12} className='py-5 d-flex justify-content-center align-items-center h-100'>
                        <div className='w-360 d-flex justify-content-center align-items-center'>
                            <div className='w-100 text-center'>
                                <div className="text-center">
                                    <Lottie
                                        options={defaultOptions}
                                        height={400}
                                        width={400}
                                        length={400}
                                    />
                                </div>
                                <h4 className=" font-weight-bold mt-4">
                                    Tạo tài khoản thành công
                            </h4>
                                <p className='text-black-50'>Vui lòng kiểm tra hộp thư email để xác thực tài khoản</p>
                                <div className='d-flex flex-row'>
                                    <Button color="info" outline className='btn-block mt-4 mr-1' type='submit'>Liên hệ</Button>
                                    <Button color="info" disabled={disabled} className='btn-block mt-4 ml-1' type='submit' onClick={handleSendMail}>
                                        {loading ? <span className="btn-wrapper--icon spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> : null}
                                        <span className="btn-wrapper--label">{loading ? 'Đang xử lý' : 'Gửi lại email'}</span>
                                    </Button>
                                </div>
                                <div className='mt-4'>
                                    {countDown}
                                </div>
                                <div className='form-group text-center mt-4'>
                                    <p>Đã xác thực email? <span className='font-weight-bold text-success cursor-pointer' onClick={onLogIn}>Đăng nhập</span></p>
                                </div>
                            </div>
                        </div>

                    </Col>

                </Row>
          
        </>
    )

}


const mapDispatchToProps = (dispatch) => {
    return {
        saveUserInfo: (data, password) => dispatch({ type: "SAVE_USER_INFO", data: data, password: password }),
        cleanStore: () => dispatch({ type: "CLEAN_STORE" }),
    }
}
const mapStateToProps = (state) => {
    return {
        userInfo: state.userInfo,
    }
}

export default withTranslation('common')(withRouter(connect(mapStateToProps, mapDispatchToProps)(Step1)));
