import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import activeStamp from "./activeStamp";
import activeStampProductType from './activeStampProductType';
import activityAdmin from "./activityAdmin";
import adminList from "./adminList";
import allBox from "./allBox";
import allDistributor from "./allDistributor";
import approveList from './approveList';
import banner from './banner';
import billList from "./billList";
import branchList from './branch.js';
import branchStatistic from './branchStatistic';
import checkStamp from './checkStamp';
import cropList from './crop';
import cropObject from './cropObject';
import cropObjectProcessing from './cropObjectProcessing';
import cropStatistic from './cropStatistic';
import dataEditArea from "./dataEditArea";
import dataEditEmployee from "./dataEditEmployee";
import dataEditProduct from "./dataEditProduct";
import detailAccount from "./detailAccount";
import detailPackage from "./detailPackage";
import distributorList from './distributor';
import employeeList from './employee';
import feedback from "./feedbackList";
import general from "./general";
import generalStampActive from './generalStampActive';
import harvestCropStatistic from './getHarvestCropStatistic';
import objectDetail from './getObjectDetail';
import objectProccessDetail from './getObjectProcessingDetail';
import userProfile from './getUserProfile';
import harvestList from './harvestList';
import harvestStatistic from './harvestStatistic';
import importList from './importList';
import infoActiveStamp from './infoActiveStamp';
import listCustomer from './listCustomer';
import listProductRetail from './listProductRetail';
import logo from './logo';
import notification from './notification';
import notiList from "./notiList";
import numberAccesses from './numberAccesses';
import numberCropProductType from './numberCropProductType';
import numProductToBranch from './numProductToBranch';
import numProductToDistributor from './numProductToDistributor';
import objectArea from './objectArea';
import objectProcessList from './objectProcess';
import objectList from './objectProduction';
import permission from "./permission";
import planList from './plan';
import planRegister from "./planRegister";
import pirceList from "./priceList";
import processList from './process';
import productArea from './productionArea';
import productType from './productType';
import pushNoti from './pushNoti';
import quantityHarvest from "./quantityHarvest";
import renderArea from './renderAreaList';
import renderEmp from './renderEmpList';
import renderProcess from "./renderProcess";
import renderProduct from "./renderProduct";
import userInfo from './saveInfoUser';
import savePlan from './savePlan';
import scanLocation from "./scanLocation";
import scanStamp from "./scanStamp";
import settingList from './settingList';
import sidebarStatus from './sidebarstatus';
import subscription from './subscription';
import ThemeOptions from './ThemeOptions';
import tooltip from './tooltip';
import unPaidList from './unPaidList';
import usedStamp from "./usedStamp";
import version from "./version";
import actionSystem from "./actionSystem"
import allManu from "./allManu"
import allAccount from "./allAccount"
import validStampRange from "./validStampRange"
import portalSetting from "./portalSetting"
import guardList from "./guardList"
import farmerList from "./farmerList"
import shipList from "./shipList"
import boxList from "./boxList"
import historyImport from "./historyImport"
import receivedBox from "./receivedBox"
import boxSystem from "./boxSystem"
const appReducer = combineReducers( {
  pushNoti,
  notification,
  tooltip,
  receivedBox,

  productType,
  productArea,
  employeeList,
  objectList,
  objectProcessList,
  cropList,
  processList,
  cropObject,
  cropObjectProcessing,
  branchList,
  harvestList,
  distributorList,
  planList,
  listCustomer,
  importList,
  unPaidList,
  approveList,
  renderArea,
  renderProduct,
  renderEmp,
  renderProcess,
  savePlan,
  listProductRetail,
  allBox,
  planRegister,
  billList,
  pirceList,
  feedback,
  detailPackage,
  activityAdmin,
  adminList,
  allDistributor,
  actionSystem,
  allManu,
  allAccount,
  guardList,
  boxList,
  boxSystem,

  banner,
  logo,
  sidebarStatus,
  checkStamp,

  objectDetail,
  objectProccessDetail,
  objectArea,

  userInfo,
  userProfile,
  historyImport,

  harvestStatistic,
  branchStatistic,
  cropStatistic,

  subscription,
  numberAccesses,

  numProductToBranch,
  numProductToDistributor,
  activeStampProductType,
  numberCropProductType,
  generalStampActive,
  infoActiveStamp,
  harvestCropStatistic,

  settingList,
  usedStamp,

  dataEditArea,
  dataEditEmployee,
  dataEditProduct,

  ThemeOptions,
  activeStamp,

  version,
  permission,
  
  scanStamp,
  scanLocation,
  quantityHarvest,
  general,
  notiList,

  detailAccount,

  portalSetting,
  validStampRange,
  farmerList,
  shipList  
});

const rootReducer = (state, action) => {
  if (action.type === 'CLEAN_STORE') {
    state = undefined
    storage.removeItem('persist:root')
  }

  return appReducer(state, action)
}

export default rootReducer
