import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import {
  Row,
  Col,
  Button
} from 'reactstrap';
//import css
import "./css/index.css"


function Step1(...props) {

  const index = props[0].data
  const t = props[0].t

  const onLogIn = () => {
    props[0].history.push("/login")
  }
  const onNextPage = () => {
    if (index !== null) {
      props[0].nextHandler()
    }
  }

  const active = (index) => {
    props[0].nextHandler()
    props[0].setData(index)
  }

  const data = [
    {
      name: t('register.agriculture'),
      data: "https://res.cloudinary.com/agridential/image/upload/c_scale,w_150/v1588652614/images/undraw_blooming_jtv6_zt3pok.png",
      active: false,
      className: "cardlinhvucx"
    },
    {
      name: t('register.processingPricing'),
      data: "https://res.cloudinary.com/agridential/image/upload/c_scale,w_150/v1588651960/images/undraw_barbecue_3x93_i016bz.png",
      active: false,
      className: "cardlinhvucx"
    },
    {
      name: t('register.consumer'),
      data: "https://res.cloudinary.com/agridential/image/upload/c_scale,w_150/v1588653211/images/undraw_shopping_app_flsj_olyz9b.png",
      active: false,
      className: "cardlinhvucx"
    },
    {
      name: t('register.other'),
      data: "https://res.cloudinary.com/agridential/image/upload/c_scale,w_150/v1588652614/images/undraw_invest_88iw_ozu0sb.png",
      active: false,
      className: "cardlinhvucx"
    }
  ]

  return (
    <>
      <div className="paddingCard">
        <h5 className="font-size-xl font-weight-bold">
          {t('register.fieldWhat')}
        </h5>
        <p className="text-black-50 mb-5">
          {t('register.fieldSelect')}
        </p>

        <Row justify="center" style={{ marginTop: "20px" }}>
          {data.map((service, idx) => (
            <Col xs={12} sm={12} md={6} lg={3} onClick={() => active(idx + 1)} >
              <div className={idx === index ? "cardlinhvucxActive" : service.className}>
                <img alt="Service" src={service.data} style={{ height: "14vh", marginTop: "10px" }}></img>
                <p className="textlinhvucx">{service.name}</p>
              </div>
            </Col>
          ))}
        </Row>

        <Row justify="flex-end" style={{ marginTop: "8%" }}>
          <Col className="paddingCol" xs={6} alignItems="flex-end" style={{ textAlign: "start" }}>
            <div style={{ width: "100%", height: "100%", textAlign: "start"}}>
              <Button className="paddingButton" color="info" outline onClick={onLogIn}>{t('register.logIn')}</Button>
            </div>
          </Col>
          <Col xs={6} alignItems="flex-end" style={{ textAlign: "end" }}>
            <div style={{ width: "100%", height: "100%", textAlign: "end"}}>
              <Button className="pl-3 pr-3" color="info" onClick={onNextPage}>{t('register.continue')}</Button>
            </div>
          </Col>
        </Row>
      </div>
    </>
  )

}


const mapDispatchToProps = (dispatch) => {
  return {
    saveUserInfo: (data, password) => dispatch({ type: "SAVE_USER_INFO", data: data, password: password }),
    cleanStore: () => dispatch({ type: "CLEAN_STORE" }),
  }
}
const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
  }
}

export default withTranslation('common')(withRouter(connect(mapStateToProps, mapDispatchToProps)(Step1)));
