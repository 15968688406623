import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import {
  Row,
  Button,
} from 'reactstrap';
//import css
import "./css/index.css"

import "view/css/select.css";

import Loading from 'components/alert/loading'
import Error from 'components/alert/error'
import Success from 'components/alert/success'
import { LINK_API } from 'constants/API'
import axios from 'axios';
import Countdown from 'react-countdown';
import ConfirmAlert from "components/alert/confirmAlert.js";

function Step2(...props) {

  const t = props[0].t
  const [disable, setDisable] = React.useState(false);
  const [alert, setAlert] = React.useState(null)
  const [countDown, setCountDown] = React.useState(null)
  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return null;
    } else {
      // Render a countdown
      return <span style={{ fontFamily: "Muli", fontWeight: "600", marginTop: "20px" }}>{t('register.trySendMail')} <a href="# " style={{ color: "#0f7343" }}>{hours}:{minutes}:{seconds}</a></span>;
    }
  };

  const handleDisableMail = () => {
    setCountDown(null)
    setDisable(false)
  }

  const hideAlert = () => {

    setAlert(null)
    setDisable(true)
    setCountDown(<Countdown
      date={Date.now() + 60000}
      renderer={(data) => renderer(data)}
    />)
    setTimeout(handleDisableMail, 60000)
  }

  const trAgain = () => {
    props[0].backHandler()
  }

  const active = (index) => {
    props[0].nextHandler()
    props[0].getData(index)
  }

  const handleSendMail = () => {
    setAlert(<Loading />)
    const data = {
      email: props[0].dataStep2.email
    }

    axios.post(LINK_API.RESEND_EMAIL, data)
      .then(res => {
        if (res.data.errorCode === 1) {
          setAlert(<Success hideCancel={true} onConfirm={() => hideAlert()} onClose={() => hideAlert()} confirmBtnText={t('close')} />)

        } else {
          setAlert(<Error onConfirm={() => hideAlert()} onClose={() => hideAlert()} confirmBtnText={t('close')} />)
        }
      })
      .catch(err => {
        if(err.response.data.name === "EMAIL_HAD_ALREADY_VERIFIED"){
          let ChildComponent = <div>{t('register.verifiedEmail')}</div>
          setAlert(
            <ConfirmAlert
              onClose={hideAlert}
              hideCancel={true}
              onConfirm={() => hideAlert()}
              confirmBtnText={"OK"}
              child={ChildComponent}
            />
          )
        }
        else{
          setAlert(<Error onConfirm={() => hideAlert()} onClose={() => hideAlert()} confirmBtnText={t('close')} />)
        }
      })



  }

  const onLogIn = () => {
    window.open("https://www.facebook.com/agridential/", "_blank");
  }

  return (
    <>

      <div className="paddingCard">
        {props[0].dataStep2.success ?
          <div>
            <Row justify="center" style={{ marginTop: "20px", display: "flex", flexFlow: "column", alignItems: "center", justifyContent: "center" }}>
              <img alt="AGD" src={"https://res.cloudinary.com/agridential/image/upload/v1592194801/AGDImage/success_bdrrtu.png"} style={{ maxWidth: "256px" }} />
              <p className="textSuccess">{t('register.regisSuccess')}</p>
              <p className="textProcessingSub">{t('register.checkMail')}</p>
              {props[0].match && props[0].match.path === "/adminonly/subscription/create" ? "" :
                <div style={{ width: "100%", display: "flex", justifyContent: 'center', alignItems: "center", flexFlow: "row" }}>
                  <Button color="info" outline onClick={() => onLogIn()} >{t('contact')}</Button>
                  <Button color="info" onClick={() => handleSendMail()} disabled={disable} style={{ marginLeft: "5px" }} >{t('register.resendEmail')}</Button>
                </div>
              }
              {countDown}
            </Row>
          </div>
          :
          <div>
            <Row justify="center" style={{ marginTop: "20px", display: "flex", flexFlow: "column", alignItems: "center", justifyContent: "center" }}>
              <img alt="AGD" src={"https://res.cloudinary.com/agridential/image/upload/v1592205852/AGDImage/error_wqaxbm.png"} style={{ maxWidth: "256px" }} />
              <p className="textError">{t('register.regisFailed')}</p>

              <div style={{ width: "100%", display: "flex", justifyContent: 'center', alignItems: "center", flexFlow: "row" }}>
                {props[0].match && props[0].match.path === "/adminonly/subscription/create" ? "" :
                  <Button color="info" outline onClick={() => onLogIn()} >{t('contact')}</Button>
                }
                <Button color="info" onClick={() => trAgain()} style={{ marginLeft: "5px" }} >{t('register.tryAgain')}</Button>
              </div>
              {countDown}
            </Row>
          </div>
        }
        {alert}
      </div>
    </>
  )

}


const mapDispatchToProps = (dispatch) => {
  return {
    saveUserInfo: (data, password) => dispatch({ type: "SAVE_USER_INFO", data: data, password: password }),
    cleanStore: () => dispatch({ type: "CLEAN_STORE" }),
  }
}
const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
  }
}

export default withTranslation('common')(withRouter(connect(mapStateToProps, mapDispatchToProps)(Step2)));
