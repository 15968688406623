import React from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import {
  Container,
} from 'reactstrap';

//import image
import { AGD } from "constants/image"
//import css
import "./css/index.css"

import Loki from 'react-loki';
import { toast, Zoom } from 'react-toastify';

import clsx from 'clsx';
import Step1 from "./step1"
import Step2 from "./step2"
import Step3 from "./step3"

import Header from "components/Headers/Header"

function Login(...props) {
  const t = props[0].t

  const customSteps = [
    {
      label: t('register.field'),
      number: '1',
      component: null
    },
    {
      label: t('register.infoStep'),
      number: '2',
      component: null
    },
    {
      label: t('register.confirm'),
      number: '3',
      component: null
    }
  ];


  const [dataStep1, setDataStep1] = React.useState(null)
  const [dataStep2, setDataStep2] = React.useState({
    time: null,
    packageStamp: null
  })
  const [dataStep3, setDataStep3] = React.useState(null)

  const setData = (data) => {
    setDataStep1(data)
  }

  const setData_Step2 = (data) => {
    setDataStep2(data)
  }

  const setData_Step3 = (data) => {
    setDataStep3(data)
  }

  const handleClickStep = (e, backHandler, canBack) => {
    e.preventDefault();
    if (canBack) {
      backHandler();
    }
  }


  const _customRenderer = ({ currentStep }) => {
    const steps = customSteps.map((step, index) => {
      const isActive = currentStep === index + 1;
      return (
        <li key={index} className={clsx('card-box paddingRight', { current: isActive })}>
          <a href="#/" onClick={(e) => e.preventDefault()}>
            {
              index === 2 ? 
              null : null
            //   <FontAwesomeIcon
            //   className="widthIcon"
            //   icon={['fas', 'chevron-right']}
            // />
            }
            
            <div className="label styleFontStep">{step.label}</div>
            <div className="step-indicator">{step.number}</div>


          </a>

        </li>
      );
    });

    return (
      <div className="horizontal">
        <ul className="steps-indicator">{steps}</ul>
      </div>
    );
  };

  const _customActions = ({
    isComplete,
    cantBack,
    isInFinalStep,
    backHandler,
    nextHandler,
    currentStep
  }) => {

    if (currentStep === 1) {
      return <Step1 nextHandler={nextHandler} setData={setData} data={dataStep1} />
    }
    else if (currentStep === 2) {
      return <Step2 dataStep1={dataStep1} data={dataStep2} setData={setData_Step2} nextHandler={nextHandler} isComplete={isComplete} cantBack={cantBack} isInFinalStep={isInFinalStep} backHandler={backHandler} />
    }
    else {
      return <Step3 dataStep1={dataStep1} dataStep2={dataStep2} data={dataStep3} setData={setData_Step3} nextHandler={nextHandler} isComplete={isComplete} cantBack={cantBack} isInFinalStep={isInFinalStep} backHandler={backHandler} />
    }

  };

  const _onFinish = () => {
    toast.success("Yes, you've successfully reached the last wizard step !", {
      containerId: 'B',
      transition: Zoom
    });
  };


  return (
    <>
    <Header></Header>
      {props[0].match &&  (props[0].match.path === "/admin/createSub" || props[0].match.path === "/sadmin/createSub") ?
        <Container className='mt--7' fluid>
          <div className="card card-box">
            <div className="card-header d-flex justify-content-between">
              <div className="card-header--title">
                <b style={{ fontSize: "25px" }}>{t('register.register')}</b>
              </div>
              <div className="card-header--actions">
                <div className="text-center">
                  <img src={'https://res.cloudinary.com/dinhtq98/image/upload/v1607266631/Kontum%20Project/Kontum_logo-removebg-preview_rpu0xl.png'} alt="logo_agd" className="logoAGDRegister" />
                </div>
              </div>
            </div>
            <div className="wizard">
              <Loki
                steps={customSteps}
                renderSteps={_customRenderer}
                renderActions={_customActions}
                onFinish={_onFinish}
              />
            </div>
          </div>
        </Container>
        :
        <div className="app-wrapper min-vh-100 bg-white">
          <div className="hero-wrapper w-100 bg-composed-wrapper bg-midnight-bloom min-vh-100">
            <div className="flex-grow-1 w-100 d-flex align-items-center">
              <div
                className="bg-composed-wrapper--image opacity-6"
                style={{ backgroundImage: 'url(https://res.cloudinary.com/agridential/image/upload/v1590482377/images/loginimage_hbw3zo.jpg)' }}
              />
              <div className="bg-composed-wrapper--bg bg-second opacity-7" />
              <div className="bg-composed-wrapper--content pt-3 pb-3">
                <Container>
                  <div className="card card-box">
                    <div className="card-header">
                      <div className="card-header--title">
                        <b style={{ fontSize: "25px" }}>{t('register.register')}</b>
                      </div>
                      <div className="card-header--actions">
                        <div className="text-center">
                          <img src={AGD} alt="logo_agd" className="logoAGDRegister" />
                        </div>
                      </div>
                    </div>
                    <div className="wizard">
                      <Loki
                        steps={customSteps}
                        renderSteps={_customRenderer}
                        renderActions={_customActions}
                        onFinish={_onFinish}
                      />
                    </div>
                  </div>
                </Container>
              </div>
            </div>

          </div>
        </div>
      }
    </>
  );
}



const mapDispatchToProps = (dispatch) => {
  return {
    saveUserInfo: (data, password) => dispatch({ type: "SAVE_USER_INFO", data: data, password: password }),
    cleanStore: () => dispatch({ type: "CLEAN_STORE" }),
  }
}
const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
  }
}

export default withTranslation('common')(withRouter(connect(mapStateToProps, mapDispatchToProps)(Login)));
