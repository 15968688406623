import clsx from "clsx";
import PageTitle from 'layout-components/PageTitle/index.js';
import AreaIcon from "mdi-react/FaceIcon";
import React from "react";
import cookie from 'react-cookies';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { Button, Nav, NavItem, NavLink as NavLinkStrap, TabContent, TabPane, Card, Container } from "reactstrap";
import "./css/edit.css";
import Step1 from "./steps/stepOne";
import Step2 from "./steps/stepTwo";
import Header from "components/Headers/Header"
function EditEmp(props) {
    const dataUpdate = props.dataUpdate;
    const stepOneRef = React.createRef();
    const uuid = cookie.load("uuidEmp");
    const stepTwoRef = React.createRef();
    const t = props.t;
    const [activeTab, setActiveTab] = React.useState('1');
    const [dataEmp, setEmp] = React.useState('');

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }
    const employeeList = props.employeeList;
    React.useEffect(() => {
        let infoEmployee = "";
        for (let i = 0; i < employeeList.length; i++) {
            if (employeeList[i].uuid === uuid) {
                infoEmployee = employeeList[i]
                break;
            }
        }
        setEmp(infoEmployee)
    }, [uuid, employeeList])
    React.useEffect(() => {
        if (dataUpdate.dataOne && dataUpdate.dataTwo) {
            let dataOne = dataUpdate.dataOne;
            let dataTwo = dataUpdate.dataTwo;
            let data = {
                uuid: uuid,
                name: {
                    vi: dataOne.name
                },
                addManageZone: dataTwo.addZone,
                deleteManageZone: dataTwo.deleteZone,
                phone: dataOne.phone ? dataOne.phone : "",
                // avatar: dataOne.avatar ? dataOne.avatar : "" ,
            }
            if (dataOne.avatar !== "dontChange") {
                data.avatar = dataOne.avatar ? dataOne.avatar : ""
            }
            props.updateEmployee(data);
            props.history.push('/employee/list');
            props.saveData('', '', true)
        }
    }, [dataUpdate])

    const handleGetData = async () => {
        await stepOneRef.current.handleMergeData()
        await stepTwoRef.current.handleMergeData()
        // setTimeout(handleSubmit, 5000)
    }
    return (
        <>
            <Header/>
            <Container className='mt--7' fluid>
            <PageTitle
                titleHeading="Nhân viên"
                titleDescription="Chỉnh sửa thông tin nhân viên"
                pageTitleDescription={true}
                titleIcon={<AreaIcon className="textSuccess" style={{ fill: "#4FB779" }} />}
                children={<Button size="sm" color="info" onClick={handleGetData}>{t('save')}</Button>}
            />
                <Card className="card-box mb-5">



                    <Nav tabs>
                        <NavItem className="w-50 pr-1">
                            <NavLinkStrap
                                className="bg-white noBorder"
                                onClick={() => {
                                    toggle('1');
                                }}
                            >
                                <li className={clsx("card-box", { ' border-bottom-active-tab': activeTab === '1' })}><a href="# " onClick={(e) => e.preventDefault()}>
                                    <div className="step-indicator p-3">
                                        <div className="d-flex">
                                            <div className={activeTab === '1' ? "font-size-lg d-44 rounded-sm mr-2 stepper-wrapper text-center bg-info text-white" : "font-size-lg d-44 rounded-sm mr-2 stepper-wrapper text-center bg-gray-300"}>
                                                <span className="font-weight-bold">1</span>
                                            </div>
                                            <div>
                                                <div className={activeTab === '1' ? "font-weight-bold text-info text-transform-none lowcase" : "font-weight-bold lowcase"}>Miêu tả</div>
                                                <small className="text-black-50 lowcase">Thông tin cơ bản</small></div>
                                        </div>
                                    </div>
                                </a></li>
                            </NavLinkStrap>
                        </NavItem>
                        {/* <NavItem className="w-50 pl-1">
                            <NavLinkStrap
                                className='bg-white noBorder'
                                onClick={() => {
                                    toggle('2');
                                }}
                            >
                                <li className={clsx("card-box", { ' border-bottom-active-tab': activeTab === '2' })}><a href="# " onClick={(e) => e.preventDefault()}>
                                    <div className="step-indicator p-3">
                                        <div className="d-flex">
                                            <div className={activeTab === '2' ? "font-size-lg d-44 rounded-sm mr-2 stepper-wrapper text-center bg-info text-white" : "font-size-lg d-44 rounded-sm mr-2 stepper-wrapper text-center bg-gray-300"}>
                                                <span className="font-weight-bold">2</span>
                                            </div>
                                            <div>
                                                <div className={activeTab === '2' ? "font-weight-bold text-info lowcase" : "font-weight-bold lowcase"}>Cài đặt nâng cao</div>
                                                <small className="text-black-50 lowcase">Thiết lập quản lý và hiển thị</small></div>
                                        </div>
                                    </div>
                                </a></li>
                            </NavLinkStrap>
                        </NavItem> */}
                    </Nav>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                            <div className="bg-white">
                                <Step1
                                    data={dataEmp}
                                    ref={stepOneRef} />
                            </div>
                        </TabPane>
                        {/* <TabPane tabId="2">
                            <div className="bg-white">
                                <Step2 data={dataEmp}
                                    ref={stepTwoRef} />
                            </div>
                        </TabPane> */}
                    </TabContent>
                </Card>
            </Container>
        </>
    )
}
const mapStateToProps = (state) => ({
    dataUpdate: state.dataEditEmployee,
    employeeList: state.employeeList.data,
    dataUpdate: state.dataEditEmployee
})
const mapDispatchToProps = (dispatch) => ({
    updateEmployee: (data) => dispatch({ type: "UPDATE_INFO_EMPLOYEE", data: data }),
    saveData: (dataOne, dataTwo, check) => dispatch({ type: "SAVE_DATA_EDIT_EMPLOYEE", dataOne: dataOne, dataTwo: dataTwo, check: check }),
});

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(EditEmp));