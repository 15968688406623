import axios from 'axios';
import LoadingTable from "components/LoadingTable/index.js";
import Pagination from "components/Pagination/index.jsx";
import { LINK_API } from 'constants/API.js';
import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ReactTable from 'react-table-6';
import SubTable from "components/SubTable/table.js";
import Header from "components/Headers/Header";
import { Card, Container, Button, UncontrolledTooltip } from 'reactstrap';
import DetailIcon from "mdi-react/InfoOutlineIcon"
import cookie from "react-cookies";
// import "../../css/reactTable.css";

function StampList(props) {
  const [activityRender, setActivity] = React.useState([])
  const [loadingState, setLoading] = React.useState(true)
  const { t } = props;

  const {
    userInfo
  } = props;

  const handleDetail = (uuid, ownedBy) => {
    cookie.save("uuidOwner", ownedBy, { path: "/" });
    cookie.save("uuidLedger", uuid, { path: "/" });
    if (props.userInfo.data.role === 'Z') {
      props.history.push('/admin/editLedger')
    } else {
      props.history.push('/sadmin/editLedger')
    }
  }

  useEffect(() => {
    setLoading(true)
    try {
      axios.defaults.headers.common.Authorization = userInfo.data.token
      axios({
        method: 'GET',
        url: LINK_API.ADMIN_GET_ALL_LEGDER
      }).then((response) => {
        if (response && response.data && response.data.data) {
          let dataRender = response.data.data.map((item) => {
            let dataDetail = []
            if (item.DiaryLedgerInfo && item.DiaryLedgerInfo.length > 0) {
              dataDetail = item.DiaryLedgerInfo.map((itemDiary, index) => {
                return {
                  name: itemDiary.name,
                  processName: itemDiary.BusinessProcessInfo ? itemDiary.BusinessProcessInfo.name : "",
                  productObjectName: itemDiary.ProductionObjectInfo ? itemDiary.ProductionObjectInfo.name : "",
                  actions: <div>
                    <Button onClick={() => handleDetail(itemDiary.uuid, itemDiary.ownedBy)} color="neutral-success" className="mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center" id={"detail" + index}>
                      <span className="btn-wrapper--icon d-flex">
                        <DetailIcon
                          size={18}
                          className="text-info font-size-sm"
                        />
                      </span>
                    </Button>
                    <UncontrolledTooltip trigger="hover" popperClassName="tooltip-black" placement="top" container="body" target={"detail" + index}>
                      Chi tiết nhật ký truy xuất của hồ sơ
                    </UncontrolledTooltip>
                  </div>
                }
              })
            }
            return {
              uuid: item.uuid,
              name: item.name,
              subData: dataDetail
            }
          })
          console.log("dataRender", dataRender)
          setActivity(dataRender)
          setLoading(false)
        }
        else {
          setActivity([])
          setLoading(false)
        }
      })
        .catch(() => {
          setActivity([])
          setLoading(false)
        })
    }
    catch {
      setLoading(false)
    }
  }, [props.date, userInfo.data.token]);

  const columns = React.useMemo(
    () => [
      {
        Header: t('profile.uuid'),
        accessor: "uuid",
        sortable: false
      },
      {
        Header: t('profile.name'),
        accessor: "name",
        filterable: false
      }
    ],
    [t]
  );


  const columnSubTable = React.useMemo(
    () => [
      {
        Header: "Tên hồ sơ",
        accessor: "name",
        sortable: false
      },
      {
        Header: "Tên sản phẩm",
        accessor: "productObjectName",
        filterable: false
      },
      {
        Header: "Tên quy trình",
        accessor: "processName",
        filterable: false
      },
      {
        Header: "Hành động",
        accessor: "actions",
        filterable: false
      },
    ],
    [t]
  );


  return (
    <>
      <Header></Header>
      <Container className='mt--7' fluid>
        <Card className="card-box mb-5">
          <div className="card-header py-3">
            <div className=" font-weight-bold card-header--title font-size-lg">{"Danh sách hồ sơ truy xuất"}</div>
            <div className="card-header--actions">
            </div>
          </div>
          <div className="divider" />
          <div className="card-body pt-2 px-4 pb-4">
            <ReactTable
              noDataText={t('noData')}
              data={activityRender}
              columns={columns}
              previousText={"<"}
              nextText={">"}
              rowsText={t('row')}
              ofText="/"
              defaultPageSize={5}
              showPaginationBottom={true}
              sortable={false}
              resizable={true}
              PaginationComponent={Pagination}
              className="-striped -highlight"
              loading={loadingState}
              LoadingComponent={LoadingTable}
              SubComponent={row => {
                return (
                  <div className="card-body pt-2 px-4 pb-4">
                    <ReactTable
                      noDataText={t('noData')}
                      data={row.original.subData}
                      columns={columnSubTable}
                      previousText={"<"}
                      nextText={">"}
                      rowsText={t('row')}
                      ofText="/"
                      defaultPageSize={5}
                      showPaginationBottom={true}
                      sortable={false}
                      resizable={true}
                      PaginationComponent={Pagination}
                      className="-striped -highlight"
                      loading={loadingState}
                      LoadingComponent={LoadingTable}
                    />
                  </div>
                )
              }}
            />
          </div>

        </Card>
      </Container>
      {/* </Card> */}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
    allScanStamp: state.allScanStamp,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getAllScanStamp: (data) => dispatch({ type: "GET_ALL_SCAN_STAMP", data: data }),
});

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(StampList));