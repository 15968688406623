import React from 'react'
import "./style.css"
import QRCode from 'qrcode.react'
import Draggable from 'react-draggable';
import cookie from 'react-cookies';

class EditStamp extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      renderList: []
    }

  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.values
      && nextProps.values.startID
      && nextProps.values.endID
      && nextProps.values.productName
      && nextProps.choosedSampleStamp
      && parseInt(nextProps.values.endID) >= parseInt(nextProps.values.startID)
      && parseInt(nextProps.values.endID) - parseInt(nextProps.values.startID) <= 300
      && nextProps.numberCol
      && nextProps.numberRow
    ) {
      this.handlePrint(
        nextProps.values.startID,
        nextProps.values.endID,
        nextProps.choosedSampleStamp,
        nextProps.values.productName.name || nextProps.values.productName,
        nextProps.userName,
        nextProps.numberCol,
        nextProps.numberRow,
        nextProps.lengthStamp
      );
    }
  }


  toDataUrl = (url) => {
    return new Promise((resolve, reject) => {
      var xhr = new XMLHttpRequest();
      xhr.onload = function () {
        var reader = new FileReader();
        reader.onloadend = function () {
          // callback(reader.result);
          console.log("reader.result", reader.result)
          return resolve(reader.result)
        }
        reader.readAsDataURL(xhr.response);
      };
      xhr.open('GET', url);
      xhr.responseType = 'blob';
      xhr.send();
    })
  }

  toDataUrl2 = (url, callback) => {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      }
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }

  handlePrint = async (startId, endId, listPosition, productName, userName, numberColPage, numberRowAll, lengthStamp) => {
    const numberCol = numberColPage || 5;
    const numberRow = numberColPage > 9
      ? parseInt(numberColPage * 12 / 5) - 4
      : numberColPage > 8
        ? parseInt(numberColPage * 12 / 5) - 2
        : numberColPage > 7
          ? parseInt(numberColPage * 12 / 5) - 1
          : numberColPage > 4
            ? parseInt(numberColPage * 12 / 5)
            : numberColPage > 2
              ? parseInt(numberColPage * 12 / 5) + 1
              : parseInt(numberColPage * 12 / 5) + 1
      ;

    const numberStamp = numberRowAll * numberCol > parseInt(endId) - parseInt(startId) + 1 ? parseInt(endId) - parseInt(startId) + 1 : numberRowAll * numberCol;
    const numberStampInPage = numberCol * numberRow;
    const numberPage = Math.ceil(numberStamp / numberStampInPage);
    const heightOfA4 = 1122;

    let dataRender = []
    let currentStamp = startId;

    const widthCard = 650 / numberCol;
    const heightCard = widthCard * 168 / 335
    const scaleWidth = widthCard / 335;
    const scaleHeight = heightCard / 168;

    const positionEnterprise = listPosition.enterpriseName;
    const positionProduct = listPosition.productName;
    const positionStampID = listPosition.stampid;
    const positionQRCode = listPosition.qrcode;
    const positionLogo = listPosition.logos;
    const positionField = listPosition.fields;

    if (positionEnterprise && positionProduct && positionStampID && positionQRCode) {

      let backgroundImage = ""
      
      if (listPosition.background && listPosition.background.secure_url) {
        backgroundImage = await this.toDataUrl(listPosition.background.secure_url);
      }
      else {
        backgroundImage = listPosition.background || ""
      }

      const enterpriseData = {
        x: positionEnterprise.x * scaleWidth,
        y: positionEnterprise.y * scaleHeight,
        fontSize: positionEnterprise.fontSize * scaleWidth,
        color: positionEnterprise.color
      }

      const productData = {
        x: positionProduct.x * scaleWidth,
        y: positionProduct.y * scaleHeight,
        fontSize: positionProduct.fontSize * scaleWidth,
        color: positionProduct.color
      }

      const stampData = {
        x: positionStampID.x * scaleWidth,
        y: positionStampID.y * scaleHeight,
        fontSize: positionStampID.fontSize * scaleWidth,
        color: positionStampID.color
      }

      const qrData = {
        x: positionQRCode.x * scaleWidth,
        y: positionQRCode.y * scaleHeight,
        height: (positionQRCode.height) * scaleWidth
      }

      let logoData = [];
      console.log("listPosition", positionLogo)

      for(let item of positionLogo){
        let image = ""
        if (item.image && item.image.secure_url) {
          image = await this.toDataUrl(item.image.secure_url)
        }
        else {
          image = item.image || ""
        }
        
        logoData.push({
          x: item.x * scaleWidth,
          y: item.y * scaleHeight - 5,
          height: item.height * scaleWidth,
          url: image
        })
      }

      const listField = positionField.map(item => {
        return {
          ...item,
          x: item.x * scaleWidth,
          y: item.y * scaleHeight,
          fontSize: item.fontSize * scaleWidth
        }
      })

      const endStamp = startId + numberStamp - 1;

      for (let page = 0; page < numberPage; page++) {
        if (currentStamp > endStamp) break;
        let itemInPage = [];
        for (let row = 0; row < numberRow; row++) {
          if (currentStamp > endStamp) break;
          let itemInRow = []
          for (let col = 0; col < numberCol; col++) {
            if (currentStamp > endStamp) break;
            let item = <div style={{ width: `${650 / numberCol}px`, height: "auto", marginRight: "24px", marginTop: "24px" }}>
              <div className='stampStyle' style={{ width: `${650 / numberCol}px`, height: heightCard, backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundImage: backgroundImage ? `url(${backgroundImage})` : "", border: backgroundImage ? "0px" : "1px dashed black", borderRadius: backgroundImage ? "0px" : "5px" }}>
                <div>
                  <Draggable position={{ x: enterpriseData.x, y: enterpriseData.y }}>
                    <div style={{ fontSize: enterpriseData.fontSize, color: enterpriseData.color }}>{userName}</div>
                  </Draggable>
                </div>
                <div style={{ position: "absolute" }}>
                  <Draggable position={{ x: productData.x, y: productData.y + 3 }}>
                    <div className="box" style={{ fontSize: productData.fontSize, color: productData.color }}>{productName ? productName.split("-")[0] : ""}</div>
                  </Draggable>
                </div>
                <div style={{ position: "absolute" }}>
                  <Draggable position={{ x: stampData.x, y: stampData.y }}>
                    <div className="box" style={{ fontSize: stampData.fontSize, color: stampData.color }} >{!currentStamp ? "000000000000" : currentStamp.toString().padStart(lengthStamp, "0")}</div>
                  </Draggable>
                </div>
                <div style={{ position: "absolute", marginLeft: qrData.x, marginTop: qrData.y }}>
                  <Draggable position={{ x: 0, y: 0 }}>
                    <QRCode value={`http://167.99.70.59:3001/lookup/${currentStamp.toString().padStart(lengthStamp, "0")}`} size={qrData.height} />
                  </Draggable>
                </div>

                {
                  logoData.map(logo => {
                    return (
                      <div style={{ position: "absolute" }}>
                        <Draggable position={{ x: logo.x, y: logo.y }}>
                          <img src={logo.url} style={{ height: logo.height, width: "auto" }}></img>
                        </Draggable>
                      </div>
                    )
                  })
                }

                {
                  listField.map(item => {
                    return (
                      <div style={{ position: "absolute" }}>
                        <Draggable position={{ x: item.x, y: item.y }}>
                          <div className="box" style={{ fontSize: item.fontSize, color: item.color }} >{item.value}</div>
                        </Draggable>
                      </div>
                    )
                  })
                }



              </div>
            </div>

            itemInRow.push(item)
            currentStamp = currentStamp + 1;
          }

          let dataRow = <div style={{display: "flex" , marginLeft: "24px"}}>
            {
              itemInRow.map(item => item)
            }
          </div>
          itemInPage.push(dataRow)
        }

        dataRender.push(
          <div className="" style={{ height: heightOfA4, minHeight: heightOfA4, maxHeight: heightOfA4 }}>
            {itemInPage.map(item => item)}
          </div>
        )
      }

      console.log("dataRender", dataRender)
      this.setState({ renderList: dataRender })
    }

  }

  componentDidMount = () => {
    if (this.props.values
      && this.props.values.startID
      && this.props.values.endID
      && this.props.values.productName
      && this.props.choosedSampleStamp
      && parseInt(this.props.values.endID) >= parseInt(this.props.values.startID)
      && parseInt(this.props.values.endID) - parseInt(this.props.values.startID) <= 500
      && this.props.numberCol
      && this.props.numberRow
    ) {
      this.handlePrint(
        this.props.values.startID,
        this.props.values.endID,
        this.props.choosedSampleStamp,
        this.props.values.productName.name || this.props.values.productName,
        this.props.userName,
        this.props.numberCol,
        this.props.numberRow,
        this.props.lengthStamp
      );
    }
  }

  render() {


    return (
      <>
        <div>
          {
            this.state.renderList.map(item => item)
          }
        </div>
      </>
    )
  }

}

export default EditStamp