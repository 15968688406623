import {
  GET_VERSION,
  GET_VERSION_SUCCESS,
  GET_VERSION_FAILURE,
} from "actions/types"
import { takeLatest, call, put, select } from 'redux-saga/effects'
import { LINK_API } from 'constants/API.js'
import axios from 'axios'

const getToken = (state) => state.userInfo

// Get user profile
function* getVersion() {
  try {
    const token = yield select(getToken)
    const response = yield call(fetchVersion, token.data.token)
    const data = response.data
    yield put({ type: GET_VERSION_SUCCESS, data: data.data })
  } catch (error) {
    yield put({ type: GET_VERSION_FAILURE })
  }
}
function fetchVersion(checktoken) {
  axios.defaults.headers.common.Authorization = checktoken
  return axios({
    method: 'GET',
    url: LINK_API.GET_VERSION
  })
}
const actions = [
  takeLatest(GET_VERSION, getVersion)
]
export default actions

