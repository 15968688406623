import {
  RENDER_EMPLOYEE_LIST,
  RENDER_EMPLOYEE_LIST_FAILURE,
  RENDER_EMPLOYEE_LIST_SUCCESS,
} from "actions/types"
import { takeLatest, call, put, select } from 'redux-saga/effects'
import { LINK_API } from 'constants/API.js'
import axios from 'axios'

const getToken = (state) => state.userInfo

function fetchEmployee (page, size, checktoken) {
  axios.defaults.headers.common.Authorization = checktoken
  return axios({
    method: 'GET',
    url: LINK_API.GET_EMPLOYEE_LIST.concat('?' , 'page=' , page , '&size=' , size) 
  })
}

// Get employee list
function * renderEmployee (page, size) {
  try {
    const tokenInfo = yield select(getToken)
    const response = yield call(fetchEmployee, page.page, page.size, tokenInfo.data.token)
    const data = response.data.data
    const options = []
    for (let i = 0; i < data.length; i++) {
      options.push({
        value: data[i].uuid,
        label: data[i].name + ' - ' + data[i].uuid
      })
    }
    yield put({ type: RENDER_EMPLOYEE_LIST_SUCCESS, data: options, errorCode: data.errorCode })
  } catch (error) {
    yield put({ type: RENDER_EMPLOYEE_LIST_FAILURE, payload: '', errorCode: 0 })
  }
}


const renderEmployeeList = [
  takeLatest(RENDER_EMPLOYEE_LIST, renderEmployee)
]
export default renderEmployeeList

