import LoadingTable from "components/LoadingTable/index.js";
import Pagination from "components/Pagination/index.jsx";
import { AGD_LOGO } from "constants/image";
import InfoIcon from "mdi-react/InformationIcon";
import PlusCircle from "mdi-react/PlusCircleIcon";
import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ReactTable from 'react-table-6';
import Truncate from 'react-truncate';
import { DropdownMenu, Button, Card, Col, Container, Row, UncontrolledTooltip, Nav, NavItem, NavLink as NavLinkStrap, CustomInput, Input, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import "../../css/reactTable.css";
import cookie from 'react-cookies';
import { motion } from "framer-motion";
import Header from "components/Headers/Header"
import * as Yup from 'yup';
import { useFormik } from 'formik';
import ConfirmAlert from "components/alert/confirmAlert.js";
import axios from 'axios';
import { LINK_API } from 'constants/API'
import DeleteIcon from "mdi-react/DeleteIcon";
import Error from 'components/alert/error'
import Success from 'components/alert/success'

function ProductAreaList(props) {
  const getPartnerList = props.getPartnerList;
  const permission = props.permission;

  const [dataPartnerList, setDataPartnerList] = React.useState([])
  const [loadingState, setLoading] = React.useState(true)
  const [filterStatus, setFilter] = React.useState(false)
  const [alert, setAlert] = React.useState(null)

  const { t } = props;

  let linkUrl = "user/";
  if (props.userInfo && props.userInfo.data && props.userInfo.data.type === "F") {
    linkUrl = "distributor/"
  }

  const isEmptyObject = (obj) => {
    if (!obj) return true;
    if (obj.length === 0) return true;
    return Object.entries(obj).length === 0 && obj.constructor === Object
  }
  const formik = useFormik({
    initialValues: {
      name: 'name',
      value: '',
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required(t('require')),
      value: Yup.string()
        .required(t('require')),
    }),
    onSubmit: React.useCallback(async (values) => {
      setFilter(true);
      setLoading(true);
      getPartnerList(values.name, values.value)
      // getAreaListFilter(1, values.name, values.value, 5)
    }, []),
  });
  const handleClearFilter = () => {
    formik.resetForm();
  }
  const handleResetData = () => {
    setFilter(false);
    getPartnerList()
  }
  const handleRole = React.useCallback((type) => {
    if (type === 'RC') return (t('partner.retailCustomer'));
    if (type === 'R') return (t('partner.retailer'));
    if (type === 'W') return (t('partner.wholesaleCustomers'));
    return (t('partner.distributor'));
  }, [t])

  const history = props.history;
  const handleClickDetail = React.useCallback(async (uuid, role) => {
    await cookie.save("uuidPartner", uuid, { path: "/" });
    await cookie.save("rolePartner", role, { path: "/" });
    history.push(`/${linkUrl}partnerDetail`)
  }, [history, linkUrl])

  const hideAlert = () => {
    setAlert(null)
  }

  const confirm = (id) => {
    axios.delete(LINK_API.DELETE_PARTNER + id)
      .then(async res => {
        getPartnerList(1, 5)
        setAlert(<Success onClose={hideAlert} hideCancel={true} onConfirm={hideAlert} confirmBtnText={t('close')} />)
      })
      .catch(err => {
        setAlert(<Error onClose={hideAlert} confirmBtnText={t('close')} />)
      })
  }

  const handleDelete = (id) => {
    let ChildComponent = <div>{"Bạn chắc chắn xoá vùng sản xuất này?"}</div>
    setAlert(
      <ConfirmAlert
        onClose={hideAlert}
        onConfirm={() => confirm(id)}
        confirmBtnText={t('confirm')}
        child={ChildComponent}
      />
    )
  }

  const genTable = React.useCallback((productAreaList) => {
    let data = productAreaList.map((prop, index) => {
      return {
        id: prop.uuid,
        name:
        <div style={{ display: "flex", flexFlow: "row", alignItems: "center" }}>
        <Container className="pl-0">
          <Row>
            <Col lg="3" style={{ textAlign: "start" }}>
              {(prop.images360) && (prop.images360.length > 0) ? (
                <img alt="profileAGD" src={prop.images360[0].secure_url} style={{ height: "37px", width: "37px" }} />
              ) :
                (prop.images) && (prop.images.length > 0) ? (
                  <img alt="profileAGD" src={prop.images[0].secure_url} style={{ height: "37px", width: "37px" }} />
                ) :
                  <img alt="profileAGD" src={AGD_LOGO} style={{ height: "37px", width: "37px" }} />}
            </Col >
            <Col lg="9" style={{ fontWeight: 100 }}>
              <div className="font-weight-bold" style={{ textAlign: "start" }}>
                <Truncate lines={2} ellipsis={<span>...</span>}>
                  {prop.name}
                </Truncate>
              </div>
              <div style={{ textAlign: "start" }}>
                {`( Mã: ${prop.uuid} )`}
              </div>
            </Col>
          </Row>
        </Container>
      </div>,
        address: prop.address,
        total: prop.totalNumberCase,
        type: handleRole(prop.partnerRole),
        actions: <div>
          <Button disabled={permission.agdqr} onClick={() => handleClickDetail(prop.uuid, prop.partnerRole)} color="neutral-info" className="mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center" id={"partnerInfo" + index}>
            <span className="btn-wrapper--icon d-flex">
              <InfoIcon
                size={18}
                className="text-success font-size-sm"
              />
            </span>
          </Button>
          <UncontrolledTooltip trigger="hover" popperClassName="tooltip-black" placement="top" container="body" target={"partnerInfo" + index}>
            {t('partner.detail')}
          </UncontrolledTooltip>
          <Button onClick={() => handleDelete(prop.uuid)} color="neutral-danger" className="mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center" id={"active" + index}>
            <span className="btn-wrapper--icon d-flex">
              <DeleteIcon
                size={18}
                className="text-info font-size-sm"
              />
            </span>
          </Button>
          <UncontrolledTooltip trigger="hover" popperClassName="tooltip-black" placement="top" container="body" target={"active" + index}>
            Xoá nhà phân phối
          </UncontrolledTooltip>
        </div>
      }
    })
    setDataPartnerList(data)
    setLoading(false)
  }, [t, handleRole, handleClickDetail])

  useEffect(() => {
    getPartnerList(1, 5)
  }, [getPartnerList]);

  const partnerList = props.partnerList.data ? props.partnerList.data.reverse() : []
  useEffect(() => {
    if (partnerList && partnerList.length > 0) {
      genTable(partnerList)
    } else {
      genTable([])
    }

  }, [partnerList, genTable]);


  const columns = React.useMemo(
    () => [
      // {
      //   accessor: "id",
      //   filterable: false,
      //   className: "center",
      //   Header: () => (
      //     <div style={{
      //       textAlign: "center"
      //     }}>
      //       #
      //     </div>
      //   )
      // },
      {
        Header: t('partner.name'),
        accessor: "name",
        sortable: false
      },
      {
        Header: t('partner.address'),
        accessor: "address",
        filterable: false
      },
      {
        Header: "Tổng sản phẩm",
        accessor: "total",
        filterable: false
      },
      {
        Header: t('actions'),
        accessor: "actions",
        filterable: false
      },
    ],
    [t]
  );



  return (

    <>
      <motion.div
        className="w-100"
        initial={{ x: -100 }}
        animate={{ x: 0 }}
        transition={{ ease: "easeOut", duration: 0.3 }}
      >
        <Header />
        <Container className='mt--7' fluid>
          <Card className="card-box mb-5">
            <div className="card-header py-3 d-flex justify-content-between">
              <div className=" font-weight-bold card-header--title font-size-lg">{t('partner.list1')}</div>
              <div className="card-header--actions d-flex flex-row">
                {/* <UncontrolledDropdown>
                  <DropdownToggle id="filterButton" color="neutral-success" className="mx-2">
                    <FilterIcon size={20} /> {t('filter.filter')}
                  </DropdownToggle>
                  <DropdownMenu right className="dropdown-menu-xxl p-0">
                    <form onSubmit={formik.handleSubmit}>
                      <div className="p-3">
                        <Row>
                          <Col md="6">
                            <small className="font-weight-bold pb-2 text-uppercase text-primary d-block">
                              {t('filter.name')}
                            </small>
                            <CustomInput
                              type="select"
                              placeholder={t('filter.name')}
                              value={formik.values.name}
                              valid={formik.touched.name && formik.errors.name ? false : (formik.values.name ? true : false)}
                              invalid={formik.touched.name && formik.errors.name ? true : false}
                              {...formik.getFieldProps('name')}
                            >
                              <option value="name">Tên nhà phân phối</option>
                              <option value="uuid">Mã nhà phân phối</option>
                              <option value="address">Địa chỉ</option>
                              <option value="type">Loại</option>
                            </CustomInput>
                          </Col>
                          <Col md="6">
                            <small className="font-weight-bold pb-2 text-uppercase text-primary d-block">
                              {t('filter.value')}
                            </small>
                            {formik.values.name !== "type" ?
                              <Input
                                placeholder={t('filter.value')}
                                value={formik.values.value}
                                valid={formik.touched.value && formik.errors.value ? false : (formik.values.value ? true : false)}
                                invalid={formik.touched.value && formik.errors.value ? true : false}
                                {...formik.getFieldProps('value')}
                              >
                              </Input> : <CustomInput
                                type="select"
                                placeholder={t('filter.value')}
                                value={formik.values.value}
                                valid={formik.touched.value && formik.errors.value ? false : (formik.values.value || formik.values.value === 0 ? true : false)}
                                invalid={formik.touched.value && formik.errors.value ? true : false}
                                {...formik.getFieldProps('value')}
                              >
                                <option value={0}>Nhà phân phối</option>
                                <option value={1}>Nhà bán lẻ</option>
                                <option value={2}>Khách sỉ</option>
                                <option value={3}>Khách lẻ</option>
                              </CustomInput>}
                          </Col>
                        </Row>

                      </div>
                      <div className="divider" />
                      <div className="p-3 text-center bg-secondary">
                        <Button color="primary" type="submit">
                          {t('filter.search')}
                        </Button>
                      </div>
                    </form>
                    <div className="divider" />
                    <div className="p-3">
                      <Row>
                        <Col md="6">
                          <Nav className="nav-neutral-danger flex-column p-0">
                            <NavItem>
                              <NavLinkStrap className="d-flex rounded-sm justify-content-center" onClick={handleClearFilter}>
                                <div className="nav-link-icon">
                                  <CloseIcon />
                                </div>
                                <span>{t('filter.clear')}</span>
                              </NavLinkStrap>
                            </NavItem>
                          </Nav>
                        </Col>
                        <Col md="6">
                          <Nav className="nav-neutral-info flex-column p-0">
                            <NavItem>
                              <NavLinkStrap className="d-flex rounded-sm justify-content-center" onClick={handleResetData}>
                                <div className="nav-link-icon">
                                  <ResetIcon />
                                </div>
                                <span>{t('filter.reset')}</span>
                              </NavLinkStrap>
                            </NavItem>
                          </Nav>
                        </Col>
                      </Row>
                    </div>
                  </DropdownMenu>
                </UncontrolledDropdown>
                <UncontrolledTooltip trigger="hover" popperClassName="tooltip-black" placement="top" container="body" target={"filterButton"}>
                  {t('filter.filter')}
                </UncontrolledTooltip> */}
                <Button disabled={permission.agdqr} onClick={() => props.history.push(`/${linkUrl}addPartner`)} color="info">
                  <span className="btn-wrapper--icon">
                    <PlusCircle size={16} />
                  </span>
                  <span className="btn-wrapper--label">{t('product.add')}</span>
                </Button>
              </div>
            </div>

            <div className="divider" />
            <div className="card-body pt-2 px-4 pb-4">

              <ReactTable
                noDataText={t('noData')}
                data={dataPartnerList}
                columns={columns}
                previousText={"<"}
                nextText={">"}
                rowsText={t('row')}
                ofText="/"
                defaultPageSize={5}
                showPaginationBottom={true}
                sortable={false}
                resizable={true}
                PaginationComponent={Pagination}
                className="-striped -highlight"
                loading={loadingState}
                LoadingComponent={LoadingTable}
              // onFetchData={async (state, instance) => {
              //   setLoading(true)
              //   let x = state.page + 1;
              //   getPartnerList(x, state.pageSize)
              // }}
              />
            </div>
          </Card>
          {alert}
        </Container>
      </motion.div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
    userProfile: state.userProfile.data,
    partnerList: state.distributorList,
    permission: state.permission.data
  }
}

const mapDispatchToProps = (dispatch) => ({
  getPartnerList: (name, value) => dispatch({ type: "GET_DISTRIBUTOR_LIST", name: name, value: value }),
});

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(ProductAreaList));