/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import axios from 'axios';
import ConfirmAlert from "components/alert/confirmAlert.js";
import Loading from "components/alert/loading";
import { LINK_API } from 'constants/API';
import { useFormik } from 'formik';
import React from "react";
import cookie from "react-cookies";
import ReactGoogleMapLoader from "react-google-maps-loader";
import ReactGooglePlacesSuggest from "react-google-places-suggest";
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux"
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Input,
    Row,
    Container
} from "reactstrap";
import Success from "components/alert/success"
import * as Yup from 'yup';
import Header from "components/Headers/Header"
import Error from "components/alert/error";

const ethers = require('ethers');
const CryptoJS = require("crypto-js");
const EthereumTx = require('ethereumjs-tx');
const MY_API_KEY = "AIzaSyCB7o1cgt10O8xP9lLbk7Iikfv5ypvr528";
const phone_regex = /((09|03|07|08|05)+([0-9]{8})\b)/g;

const Register = (props) => {
    const t = props.t
    const handleSelectSuggest = (geocodedPrediction) => {
        formik.setFieldValue("address", geocodedPrediction.formatted_address)
        formik.setFieldValue("search", "")
    }

    const handleLogin = () => {
        props.history.push('/auth/login')
    }
    const [alert, setAlert] = React.useState(null)

    const formik = useFormik({
        initialValues: {
            name: "",
            address: "",
            phone: "",
            email: "",
            tax: "",
            password: "",
            rePassx: "",
            representative: "",
        },
        validationSchema: Yup.object({
            name: Yup.string()
                .required(t('require')),
            phone: Yup.string().matches(phone_regex, 'Số điện thoại không hợp lệ'),
            email: Yup.string()
                // .email(t('register.invalidEmail'))
                .required(t('require')),
            password: Yup.string()
                .min(6, t('numberCharOfPassword'))
                .required(t('require')),
            rePassx: Yup.string()
                .oneOf([Yup.ref('password'), null], t('noMatching'))
                .required(t('require')),
        }),
        onSubmit: async (values) => {
            let ChildComponent = <div>{"Bạn chắc chắn đã kiểm tra kỹ thông tin?"}</div>
            setAlert(
                <ConfirmAlert
                onClose={hideAlert}
                onConfirm={() => handleSubmit(values)}
                confirmBtnText={t('confirm')}
                child={ChildComponent}
                />
            )
        },
    });

    const hideAlert = () => {
        setAlert(null)
    }
    const handleSubmit = async (values) => {
        setAlert(<Loading />)
        console.log(props.userInfo.data)
        axios.defaults.headers.common['Authorization'] = props.userInfo.data.token

        const data = {
            "email":'',
            "name": values.name,
            "pw_hash": values.password,
            "address": values.address,
            "phone": values.phone ? values.phone : "",
            "username": values.email,
            "type": "M",
            "network": "88888",
            "field": 1,
            "numberOfStampUnix": "",
            "stampBrand": false,
            "representation": values.representative,
            "tax": values.tax
        }
        cookie.save("email", values.email, { path: "/" });

        const path_get = await axios.get(LINK_API.GET_PATH_USER);
        const pathCount = path_get.data.data.countMasterAccount.toString()

        let wallet = ethers.Wallet.createRandom();
        let randomMnemonic = wallet.mnemonic;
        let walletPath = {
            "standard": "m/44'/60'/0'/" + pathCount + "/0",
        };
        let hdnode = ethers.utils.HDNode.fromMnemonic(randomMnemonic);
        let node = hdnode.derivePath(walletPath.standard);
        const accountAddress = node.address;
        const privatekey = node.privateKey;
        const privatekey_slice = privatekey.substring(2, privatekey.length)
        const encryptedSeed = await CryptoJS.AES.encrypt(randomMnemonic, data.pw_hash);
        const encryptedPrivateKey = await CryptoJS.AES.encrypt(privatekey, data.pw_hash);
        var temp = new Buffer(data.pw_hash).toString('hex')
        const encryptedPass = await ethers.utils.keccak256('0x' + temp);
        data.password = data.pw_hash //password
        data.pw_hash = encryptedPass; //pass hash
        data.privateEncrypted = encryptedPrivateKey.toString(); // encrypted privatekey
        data.seedEncrypted = encryptedSeed.toString(); // encrypted seed
        data.bcAddress = accountAddress; // address of account on bc
        data.currentIndexPath = pathCount; // index path of account
        axios.post(LINK_API.REGISTER_TRIAL, data)
            .then(async response => {
                if (response.data.errorCode === 1) {
                    const Buffer_privatekey = Buffer.from(privatekey_slice.toString(), 'hex');
                    const tx = new EthereumTx(response.data.data.raw, { chain: 4 });
                    await tx.sign(Buffer_privatekey);
                    const rawTx = '0x' + tx.serialize().toString('hex');
                    const raw_to_send = {
                        ...data,
                        "raw": rawTx,
                        "sign": "none",
                        "date": "none",
                        "bcAddress": accountAddress,
                        "uuid": response.data.data.uuid,
                        "nonce": response.data.data.nonce,
                        "creator_nonce": response.data.data.creator_nonce
                    }
                    axios.post(LINK_API.SEND_RAW, raw_to_send)
                        .then(response => {
                            if (response.data.errorCode === 1) {
                                // setAlert(<Success
                                //     title='Đăng ký thành công'
                                //    onClose={hideAlert}
                                //    hideCancel={false}
                                //    onConfirm={handleLogin} 
                                //   confirmBtnText={t('login.login')}
                                //   cancleBtnText={t('login.sendEmail')}
                                //   />)
                                setAlert(<Success onConfirm={handleChangeRoute} onClose={hideAlert} hideCancel={true} confirmBtnText='Xác nhận' />)
                            } else {
                                setAlert(<Error onClose={hideAlert} confirmBtnText={t('close')} />)
                            }
                        })
                        .catch(err => {
                            setAlert(<Error onClose={hideAlert} confirmBtnText={t('close')} />)
                        })
                } else {

                }
            })
            .catch(err => {
                if (err.response.data.name === "EMAIL_EXIST") {
                    let ChildComponent = <div>{t('register.existedEmail')}</div>
                    setAlert(
                        <ConfirmAlert
                            hideCancel={true}
                            onClose={hideAlert}
                            onConfirm={hideAlert}
                            confirmBtnText={t('confirm')}
                            child={ChildComponent}
                            color="warning"
                        />
                    )
                }

                else if (err.response.data.name === "PHONE_EXIST") {
                    let ChildComponent = <div>{t('register.existedPhone')}</div>
                    setAlert(
                        <ConfirmAlert
                            hideCancel={true}
                            onClose={hideAlert}
                            onConfirm={hideAlert}
                            confirmBtnText={t('confirm')}
                            child={ChildComponent}
                            color="warning"
                        />
                    )
                }

                else if (err.response.data.name === "ENTITY_IS_EXIST") {
                    let ChildComponent = <div>{"Tên đăng nhập đã tồn tại"}</div>
                    setAlert(
                        <ConfirmAlert
                            hideCancel={true}
                            onClose={hideAlert}
                            onConfirm={hideAlert}
                            confirmBtnText={t('confirm')}
                            child={ChildComponent}
                            color="warning"
                        />
                    )
                }

                else {
                    setAlert(<Error onClose={hideAlert} confirmBtnText={t('close')} />)
                }
            })
    }
    const handleNoResult = () => {
        console.log('Không có kết quả')
    }
    const handleChangeRoute = () => {
        if (props.userInfo.data.type === 'S') {
            props.history.push('/sadmin/allManu')
        }
        if (props.userInfo.data.type === 'Z') {
            props.history.push('/admin/allManu')
        }
    }
    const handleChangeAddress = name => event => {
        let values = event.target.value;
        if (values) {
            formik.setFieldValue("search", values);
            formik.setFieldValue("address", values, true);
        } else {
            formik.setFieldValue("search", values);
            formik.setFieldValue("address", values, true);
            formik.setFieldError("address", t('require'))
        }

    }
    const handleChangeField = (name) => async event => {
        formik.setFieldValue(name, event.target.value, true)
    }
    return (
        <>
            <Header />
            <Container className='mt--7' fluid>
                <Card className="bg-secondary shadow border-0">
                    <CardHeader className="bg-transparent d-flex justify-content-center align-items-center">
                        <img alt='logoVBC' src={'https://res.cloudinary.com/dinhtq98/image/upload/v1607266631/Kontum%20Project/Kontum_logo-removebg-preview_rpu0xl.png'} style={{ height: "50px" }} />
                    </CardHeader>
                    <CardBody className="px-lg-5 py-lg-3">
                        <form onSubmit={formik.handleSubmit}>
                            <div >
                                <p className="text-black-50 mb-4"></p>
                                <div className="form-row ">
                                    <div className="form-group col-md-6 pr-3">

                                        <div className="form-group">
                                            <label className="font-weight-bold">{"Tên thành viên"} <span className="text-danger">(*)</span></label>
                                            <Input
                                                placeholder={"Tên thành viên"}
                                                type="text"
                                                name="name"
                                                value={formik.values.name}
                                                valid={formik.touched.name && formik.errors.name ? false : (formik.values.name ? true : false)}
                                                invalid={formik.touched.name && formik.errors.name ? true : false}
                                                {...formik.getFieldProps('name')}
                                            />
                                            {formik.touched.name && formik.errors.name ? (
                                                <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.name}</p>
                                            ) : null}
                                        </div>


                                        <div className="form-group">
                                            <label className="font-weight-bold">Tên đăng nhập <span className="text-danger">(*)</span></label>
                                            <Input
                                                placeholder='Tên đăng nhập'
                                                type="text"
                                                name="email"
                                                value={formik.values.email}
                                                valid={formik.touched.email && formik.errors.email ? false : (formik.values.email ? true : false)}
                                                invalid={formik.touched.email && formik.errors.email ? true : false}
                                                {...formik.getFieldProps('email')}
                                            />
                                            {formik.touched.email && formik.errors.email ? (
                                                <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.email}</p>
                                            ) : null}
                                        </div>
                                        <div className="form-group">
                                            <label className="font-weight-bold">{t('register.password')} <span className="text-danger">(*)</span></label>
                                            <Input
                                                placeholder={t('register.password')}
                                                type="password"
                                                name="password"
                                                value={formik.values.password}
                                                valid={formik.touched.password && formik.errors.password ? false : (formik.values.password ? true : false)}
                                                invalid={formik.touched.password && formik.errors.password ? true : false}
                                                {...formik.getFieldProps('password')}
                                            />
                                            {formik.touched.password && formik.errors.password ? (
                                                <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.password}</p>
                                            ) : null}
                                        </div>

                                        <div className="form-group">
                                            <label className="font-weight-bold">{t('register.rePassx')} <span className="text-danger">(*)</span></label>
                                            <Input
                                                placeholder={t('register.rePassx')}
                                                type="password"
                                                name="rePassx"
                                                value={formik.values.rePassx}
                                                valid={formik.touched.rePassx && formik.errors.rePassx ? false : (formik.values.rePassx ? true : false)}
                                                invalid={formik.touched.rePassx && formik.errors.rePassx ? true : false}
                                                {...formik.getFieldProps('rePassx')}
                                            />
                                            {formik.touched.rePassx && formik.errors.rePassx ? (
                                                <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.rePassx}</p>
                                            ) : null}
                                        </div>

                                        <div className="form-group responseCompulsory text-sm ">
                                            <p><span className="text-danger text-sm">(*)</span> Trường thông tin bắt buộc</p>
                                        </div>
                                    </div>

                                    <div className="form-group col-md-6 pr-3">
                                        <div className="form-group">
                                            <label className="font-weight-bold">{t('phone')}</label>
                                            <Input
                                                type='number'
                                                placeholder='Số điện thoại liên hệ'
                                                value={formik.values.phone}
                                                valid={formik.touched.phone && formik.errors.phone ? false : (formik.values.phone ? true : false)}
                                                invalid={formik.touched.phone && formik.errors.phone ? true : false}
                                                onChange={handleChangeField('phone')}
                                            />
                                            {formik.touched.phone && formik.errors.phone ? (
                                                <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.phone}</p>
                                            ) : null}
                                        </div>

                                        <div className="form-group">
                                            <label className="font-weight-bold">{t('product.address')}</label>
                                            <ReactGoogleMapLoader
                                                params={{
                                                    key: MY_API_KEY,
                                                    libraries: "places,geocode",
                                                }}
                                                render={googleMaps =>
                                                    googleMaps && (
                                                        <div>
                                                            <ReactGooglePlacesSuggest
                                                                autocompletionRequest={{ input: formik.values.search }}
                                                                googleMaps={googleMaps}
                                                                onSelectSuggest={handleSelectSuggest}
                                                                displayPoweredByGoogle={false}
                                                                onNoResult={handleNoResult}

                                                            >
                                                                <Input
                                                                    placeholder={t('product.address')}
                                                                    onChange={handleChangeAddress('address')}
                                                                    type="text"
                                                                    name="address"
                                                                    value={formik.values.address}
                                                                    valid={formik.touched.address && formik.errors.address ? false : (formik.values.address ? true : false)}
                                                                    invalid={formik.touched.address && formik.errors.address ? true : false}
                                                                // {...formik.getFieldProps('address')}
                                                                />
                                                            </ReactGooglePlacesSuggest>
                                                        </div>
                                                    )
                                                }
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label className="font-weight-bold">{t('product.tax')}</label>
                                            <Input
                                                placeholder={t('product.tax')}
                                                type="text"
                                                name="name"
                                                {...formik.getFieldProps('tax')}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label className="font-weight-bold">{t('register.representative')}</label>
                                            <Input
                                                placeholder={t('register.representative')}
                                                type="text"
                                                name="name"
                                                {...formik.getFieldProps('representative')}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Row justify="flex-end">
                                <Col xs={6} alignItems="flex-start" style={{ textAlign: "start" }}>
                                    <div style={{ width: "100%", height: "100%", textAlign: "start" }}>
                                        <Button disabled={false} color="info" type="submit">{t('login.register')}</Button>
                                    </div>
                                </Col>
                            </Row>
                        </form>
                    </CardBody>
                </Card>
            </Container>
            {alert}
        </>
    );

}
const mapStateToProps = (state) => {
    return {
        userInfo: state.userInfo
    }
}
export default withRouter(withTranslation('common')(connect(mapStateToProps, null)(Register)));
