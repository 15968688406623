import { SAVE_USER_INFO, SAVE_USER_INFO_SUCCESS, SAVE_USER_INFO_FAILURE} from 'actions/types'
const initialState = { data: '', password: ''}
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_USER_INFO:
      return {
        ...state
      }
    case SAVE_USER_INFO_SUCCESS:
      console.log(action.data)
      return {
        ...state,
        data: action.data,
        password: action.password,
      }
    case SAVE_USER_INFO_FAILURE:
      return {
        ...state,
        data: action.data,
        password: action.password
      }
    default:
      return state
  }
}
export default reducer
