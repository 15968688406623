import React from 'react'
import "./style.css"
import QRCode from 'qrcode.react'
import Draggable from 'react-draggable';
import cookie from 'react-cookies';

class EditStamp extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      renderList: []
    }

  }

  componentDidMount = () => {

    const values = this.props.value;
    console.log("values", values)
    const numberCol = 4;
    const startId = 1;
    const endId = 1;
    const numberStamp = parseInt(endId) - parseInt(startId) + 1;
    const numberRow = 10;
    const numberStampInPage = numberCol * numberRow;
    const numberPage = Math.ceil(numberStamp / numberStampInPage);
    const heightOfA4 = 1590


    let backgroundImageLoad = cookie.load("backgroundImage");
    let backgroundImage = "https://res.cloudinary.com/agridential/image/upload/v1604859860/AGDImage/embyo_e0mo73-svg_lnvtvb.png"

    let logoLoad = cookie.load("logoData");
    let logo = logoLoad ? "https://res.cloudinary.com/dinhtq98/image/upload/v1607266631/Kontum%20Project/Kontum_logo-removebg-preview_rpu0xl.png" : ""


    let dataRender = []
    let currentStamp = startId;

    const widthCard = 1000 / numberCol;
    const heightCard = widthCard * 168 / 335
    const scaleWidth = widthCard / 335;
    const scaleHeight = heightCard / 168;

    const positionEnterprise = cookie.load("positionEnterprise");
    const positionProduct = cookie.load("positionProduct");
    const positionStampID = cookie.load("positionStampID");
    const positionQRCode = cookie.load("positionQRCode");
    const positionLogo = cookie.load("listLogo");

    if (positionEnterprise && positionProduct && positionStampID && positionQRCode ) {
      const enterpriseData = {
        x: positionEnterprise.x * scaleWidth,
        y: positionEnterprise.y * scaleHeight
      }

      const productData = {
        x: positionProduct.x * scaleWidth,
        y: positionProduct.y * scaleHeight
      }

      const stampData = {
        x: positionStampID.x * scaleWidth,
        y: positionStampID.y * scaleHeight
      }

      const qrData = {
        x: positionQRCode.x * scaleWidth,
        y: positionQRCode.y * scaleHeight
      }

      const logoData = {
        // x: positionLogo.x * scaleWidth,
        // y: positionLogo.y * scaleHeight
        x: 3,
        y: 5
      }

      for (let page = 0; page < numberPage; page++) {
        if (currentStamp > endId) break;
        let itemInPage = [];
        for (let row = 0; row < numberRow; row++) {
          if (currentStamp > endId) break;
          let itemInRow = []
          for (let col = 0; col < numberCol; col++) {
            if (currentStamp > endId) break;
            let item = <div className="pt-4 pl-4" style={{ width: `${1000 / numberCol}px`, height: "auto" }}>
              <div className='stampStyle' style={{ width: "100%", height: heightCard, backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundImage: `url(${backgroundImage})`, border: backgroundImage ? "0px" : "1px dashed black", borderRadius: backgroundImage ? "0px" : "5px" }}>
                <div>
                  <Draggable position={{ x: enterpriseData.x, y: enterpriseData.y }}>
                    <div style={{ fontSize: widthCard / 20 }}>Karaosaka</div>
                  </Draggable>
                </div>
                <div style={{ position: "absolute" }}>
                  <Draggable position={{ x: productData.x, y: productData.y + 3 }}>
                    <div className="box" style={{ fontSize: widthCard / 16 }}>Rau xà lách</div>
                  </Draggable>
                </div>
                <div style={{ position: "absolute" }}>
                  <Draggable position={{ x: stampData.x, y: stampData.y }}>
                    <div className="box" style={{ fontSize: widthCard / 25 }} >0000000000000</div>
                  </Draggable>
                </div>
                <div style={{ position: "absolute", marginLeft: qrData.x, marginTop: qrData.y }}>
                  <Draggable position={{ x: 0, y: 0 }}>
                    <QRCode value='https://res.cloudinary.com/agridential/image/upload/v1603961693/images/qr-code_sw33nc.png' size={ 80 * widthCard / 335 } />
                  </Draggable>
                </div>

                <div style={{ position: "absolute" }}>
                  <Draggable position={{ x: logoData.x, y: logoData.y }}>
                    <img src={logo} style={{ height: 20 * scaleHeight, width: 140 * scaleWidth }}></img>
                  </Draggable>
                </div>
              </div>
            </div>

            itemInRow.push(item)
            currentStamp = currentStamp + 1;
          }

          let dataRow = <div className="d-flex ml-4">
            {
              itemInRow.map(item => item)
            }
          </div>
          itemInPage.push(dataRow)
        }

        dataRender.push(
          <div className="" style={{ height: heightOfA4 }}>
            {itemInPage.map(item => item)}
          </div>
        )
      }

      this.setState({ renderList: dataRender })
    }


  }

  render() {


    return (
      <>
        <div>
          {
            this.state.renderList.map(item => item)
          }
        </div>
      </>
    )
  }

}

export default EditStamp