import { 
    GET_OBJECT_PROCESSING_LIST,
    GET_OBJECT_PROCESSING_LIST_FAILURE,
    GET_OBJECT_PROCESSING_LIST_SUCCESS,
    DEACTIVE_NOTI_PRODUCT,

    PUSH_NOTI_PRODUCT_SUCCESS,
    SAVE_NOTIFICATION,
    CREATE_OBJECT_PROCESS,
    UPDATE_OBJECT_PROCESSING,
    GET_OBJECT_LIST,
    GET_OBJECT_PROCESSING_FILTER
   } from "actions/types"
  import { takeLatest, call, put, select, delay } from 'redux-saga/effects'
  import { LINK_API } from 'constants/API.js'
  import axios from 'axios'
  import responseCode from "constants/errorCode.js"
  
  const getToken = (state) => state.userInfo
  const getFullObjectProcessingList = (state) => state.objectProcessList.fullData
  const getTotalItemProcessing = (state) => state.objectProcessList.totalItem
  // const getSaveSetting = (state) => state.settingList.data
   //create Object
   function * watchCreateObject (data) {
    yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Đang xử lý", color: 'warning' })
    yield delay(2000)
    yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
    const token = yield select(getToken)
    const { response, error } = yield call(createObject, data.data, token.data.token)
    if (response) {
      const result = response.data
      if (result.errorCode === 1) {
        yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Tạo sản phẩm thành công", color: 'success' })
        yield put({ type: SAVE_NOTIFICATION, data: { type: 'success', message: "Tạo sản phẩm thành công" } })
        yield put({ type: GET_OBJECT_PROCESSING_LIST, page: 1, size: 5 })
        yield delay(3000)
        yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
      } else {
        if (responseCode[`${result.name}`]) {
          yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: responseCode[`${result.name}`].vi, color: 'danger' })
          yield put({ type: SAVE_NOTIFICATION, data: { type: 'error', message: responseCode[`${result.name}`].vi } })
          yield delay(3000)
          yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
        } else {
          yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Tạo sản phẩm thất bại", color: 'danger' })
          yield put({ type: SAVE_NOTIFICATION, data: { type: 'error', message: "Tạo sản phẩm thất bại" } })
          yield delay(3000)
          yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
        }
      }
    } else {
      if (error) {
        if (error.response.data.name) {
          if (responseCode[`${error.response.data.name}`]) {
            yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: responseCode[`${error.response.data.name}`].vi, color: 'danger' })
            yield put({ type: SAVE_NOTIFICATION, data: { type: 'error', message: responseCode[`${error.response.data.name}`].vi } })
            yield delay(3000)
            yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
          } else {
            yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Tạo sản phẩm thất bại", color: 'danger' })
            yield put({ type: SAVE_NOTIFICATION, data: { type: 'error', message: "Tạo sản phẩm thất bại" } })
            yield delay(3000)
            yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
          }
        } else {
          yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Tạo sản phẩm thất bại", color: 'danger' })
          yield put({ type: SAVE_NOTIFICATION, data: { type: 'error', message: "Tạo sản phẩm thất bại" } })
          yield delay(3000)
          yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
        }
      } else {
        yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Tạo sản phẩm thất bại", color: 'danger' })
        yield put({ type: SAVE_NOTIFICATION, data: { type: 'error', message: "Tạo sản phẩm thất bại" } })
        yield delay(3000)
        yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
      }
    }
  }
  function createObject (data, checktoken) {
    axios.defaults.headers.common.Authorization = checktoken
    return axios.post(LINK_API.CREATE_OBJECT_PROCESS, data)
      .then(response => ({ response }))
      .catch(error => ({ error }))
  }
  //get object processing list
  function * getObjectProcessList (page) {
    try {
      const tokenInfo = yield select(getToken)
      const fullData = yield select(getFullObjectProcessingList)
      const totalItem = yield select(getTotalItemProcessing)
      const size = page.size;
      if (fullData[`${page.page}`]) {
        yield put({
          type: GET_OBJECT_PROCESSING_LIST_SUCCESS,
          data: fullData[`${page.page}`],
          fullData: fullData
        })
      }
      const response = yield call(fetchObjectProcess, page.page, tokenInfo.data.token, size)
      const data = response.data.data
      if (totalItem !== response.data.totalItem) {
        const newList = data
        fullData[`${page.page}`] = newList
        yield put({
          type: GET_OBJECT_PROCESSING_LIST_SUCCESS,
          data: newList,
          errorCode: response.data.errorCode,
          totalItem: response.data.totalItem,
          totalPage: response.data.totalPage,
          fullData: fullData
        })
      } else {
        if (!fullData[`${page.page}`]) {
          const newList = data
          fullData[`${page.page}`] = newList
          yield put({
            type: GET_OBJECT_PROCESSING_LIST_SUCCESS,
            data: newList,
            errorCode: response.data.errorCode,
            totalItem: response.data.totalItem,
            totalPage: response.data.totalPage,
            fullData: fullData
          })
        }else{
            if(fullData[`${page.page}`] !== data){
                const newList = data
                fullData[`${page.page}`] = newList
                yield put({
                  type: GET_OBJECT_PROCESSING_LIST_SUCCESS,
                  data: newList,
                  errorCode: response.data.errorCode,
                  totalItem: response.data.totalItem,
                  totalPage: response.data.totalPage,
                  fullData: fullData
                })
            }
        }
      }
    } catch (error) {
      yield put({ type: GET_OBJECT_PROCESSING_LIST_FAILURE, payload: '', errorCode: 0 })
    }
  }
  function fetchObjectProcess (page, checktoken, size) {
    axios.defaults.headers.common.Authorization = checktoken
    return axios({
      method: 'GET',
      url: LINK_API.CREATE_OBJECT_PROCESS + '?page=' + page + '&size=' + size
    })
  }

  function updateInfoObject(data, checktoken) {
    axios.defaults.headers.common.Authorization = checktoken
    return axios.post(LINK_API.UPDATE_OBJECT_PROCESSING, data)
      .then(response => ({ response }))
      .catch(error => ({ error }))
  }


  //udpate info object
  function* watchUpdateObject(data) {
    yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Đang xử lý", color: 'warning' })
    yield delay(1000)
    yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
    try {
      const tokenInfo = yield select(getToken)
      const { response } = yield call(updateInfoObject, data.data, tokenInfo.data.token)
      const result = response.data
      if (result.errorCode === 1) {
        yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Cập nhật thông tin sản phẩm thành công", color: 'success' })
        yield put({ type: GET_OBJECT_LIST, page: 1, size: 5 })
        yield delay(2000)
        yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
      } else {
        yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Cập nhật thông tin sản phẩm thất bại", color: 'error' })
        yield put({ type: GET_OBJECT_LIST, page: 1, size: 5 })
        yield delay(2000)
        yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
      }
    } catch (error) {
      yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Cập nhật thông tin chế biến thất bại", color: 'error' })
      yield put({ type: GET_OBJECT_LIST, page: 1, size: 5 })
      yield delay(2000)
      yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
    }
  }
  function * getObjectProcessingListFilter (page) {
    try {
      const tokenInfo = yield select(getToken)
      const fullData = yield select(getFullObjectProcessingList)
      const totalItem = yield select(getTotalItemProcessing)
      const size = page.size;
      if (fullData[`${page.page}`]) {
        yield put({
          type: GET_OBJECT_PROCESSING_LIST_SUCCESS,
          data: fullData[`${page.page}`],
          fullData: fullData
        })
      }
      const response = yield call(fetchObjectProcessFilter, page.page, page.name, page.value, tokenInfo.data.token, size)
      const data = response.data.data
      if (totalItem !== response.data.totalItem) {
        const newList = data
        fullData[`${page.page}`] = newList
        yield put({
          type: GET_OBJECT_PROCESSING_LIST_SUCCESS,
          data: newList,
          errorCode: response.data.errorCode,
          totalItem: response.data.totalItem,
          totalPage: response.data.totalPage,
          fullData: fullData
        })
      } else {
        if (!fullData[`${page.page}`]) {
          const newList = data
          fullData[`${page.page}`] = newList
          yield put({
            type: GET_OBJECT_PROCESSING_LIST_SUCCESS,
            data: newList,
            errorCode: response.data.errorCode,
            totalItem: response.data.totalItem,
            totalPage: response.data.totalPage,
            fullData: fullData
          })
        }
      }
    } catch (error) {
      yield put({ type: GET_OBJECT_PROCESSING_LIST_FAILURE, payload: '', errorCode: 0 })
    }
  }
  function fetchObjectProcessFilter (page, name, value, checktoken, size) {
    axios.defaults.headers.common.Authorization = checktoken
    return axios({
      method: 'GET',
      url: LINK_API.CREATE_OBJECT_PROCESS + page + '&size=' + size +'&' + name + '=' + value
    })
  }
  
  const oProcessing = [
    takeLatest(GET_OBJECT_PROCESSING_LIST, getObjectProcessList),
    takeLatest(CREATE_OBJECT_PROCESS, watchCreateObject),
    takeLatest(UPDATE_OBJECT_PROCESSING, watchUpdateObject),
    takeLatest(GET_OBJECT_PROCESSING_FILTER, getObjectProcessingListFilter)

  ]
  export default oProcessing
  
  