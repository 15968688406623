import { useFormik } from 'formik';
import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import {
  Button,
  CustomInput,
  Input,
  UncontrolledPopover,
  ListGroup,
  ListGroupItem,
  Alert,
} from "reactstrap";
//style
import "view/css/select.css";
import * as Yup from 'yup';
import Error from "components/alert/error";
import Loading from "components/alert/loading";
import Success from "components/alert/success";
import { LINK_API } from "constants/API";
import axios from "axios"
import Select from 'react-select';
import moment from "moment"
import CalenderIcon from "mdi-react/CalendarIcon";
import HelpBoxIcon from "mdi-react/HelpBoxIcon";
import GenieToken from "../employObject/abi/GenieToken.js"
import DatePicker, { registerLocale } from "react-datepicker";
import el from "date-fns/locale/vi";
import cookie from "react-cookies";
import  Contract  from 'web3-eth-contract';
import DateTimePicker from 'react-datetime-picker'

registerLocale("el", el);

const CryptoJS = require('crypto-js')
const EthereumTx = require('ethereumjs-tx');

const ActivateStamp = (props) => {
  const t = props.t;
  const userInfo = props.userInfo;
  const [alert, setAlert] = React.useState(null);
  const {
    harvestList,
    validStampRange,
    getValidStampRange
  } = props;

  const [checked, setCheck] = React.useState(false)
  const [listHavest, setListHavest] = React.useState([])
  const [listHavestRender, setListHavestRender] = React.useState([])
  const [productDate, setProductDate] = React.useState(new Date())
  const [diaryRecordList, setDiaryRecordList] = React.useState([])
  const [diaryRecord, setDiaryRecord] = React.useState([])
  const [IDRecord, setIDRecord] = React.useState([])
  const [harvestTime, setHarvestTime] = React.useState(new Date());
  
  const handleCheckBox = () => {
    setCheck(!checked)
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      startID: '',
      endID: '',
      harvest: '',
      boxID: '',
    },
    validationSchema: Yup.object({
      startID: Yup.number()
        .required(t('require'))
        .integer(t('stamp.integer')),

      endID: Yup.number()
        .required(t('require'))
        .integer(t('stamp.integer'))
        .test(t('stamp.startIDLessEndID'), t('stamp.startIDLessEndID'),
          function (value) {
            return value >= this.parent.startID;
          }
        ),

      harvest: Yup.string()
        .required(t('require')),

      boxID: Yup.number()
        .test(t('require'), t('require'),
          function (value) {
            return ( checked && value ) || !checked
          }
        ),

    }),
    onSubmit: async (values) => {
      setAlert(<Loading />)
      axios.defaults.headers.common.Authorization = userInfo.data.token;
      try {
        const response = await axios({
          method: 'POST',
          url: LINK_API.ACTIVATE_STAMP_GET_RAW,
          data: {
            "startId": values.startID,
            "endId": values.endID,
            "date": Date.parse(harvestTime),
            "idOfObject": values.harvest.value,
            "mfDate": Date.parse(productDate),
            "moreInfo": {},
            "boxId": values.boxID,
            "diaryLedgerUuid": diaryRecord[0] ? diaryRecord[0].value : diaryRecord.value
          }
        })

        if (response.data.errorCode === 1) {
          const userInfo = props.userInfo
          var bytes = CryptoJS.AES.decrypt(userInfo.data.privateEncrypted.toString(), userInfo.password);
          const privateKey = bytes.toString(CryptoJS.enc.Utf8);
          const privatekey_slice = privateKey.substring(2, privateKey.length);
          const Buffer_privatekey = Buffer.from(privatekey_slice.toString(), 'hex');
          const tx = new EthereumTx(response.data.data.raw, { chain: 4 });
          tx.sign(Buffer_privatekey);
          const rawTx = '0x' + tx.serialize().toString('hex');

          const dataX = {
            ...response.data.data,
            raw: rawTx
          };

          handleSendRaw(dataX)

          // sendRaw(response.data.data, values.boxID)
        }
      }
      catch (err) {
        setAlert(<Error onClose={() => setAlert(null)} title="Khoảng tem không hợp lệ" confirmBtnText={t('confirm')} />)
      }

    },
  });

  const handleSendRaw = async (data) => {
    axios.defaults.headers.common.Authorization = userInfo.data.token;
    const response_send_raw = await axios({
      method: 'POST',
      url: LINK_API.ACTIVATE_STAMP_SEND_RAW,
      data: data
    })

    if (response_send_raw.data.errorCode === 1) {
      getValidStampRange();
      setAlert(<Success onClose={() => setAlert(null)} hideCancel={true} onConfirm={() => setAlert(null)} confirmBtnText={t('close')} />)
    } else {
      setAlert(<Error onClose={() => setAlert(null)} confirmBtnText={t('confirm')} />)
    }
  }

  const creatRawActiveStamp = async (
    code,
    from,
    to,
    uri = '',
    contractAddress,
    genieTokenAddress,
    nonce,
  ) => {
    try {
      const genieTokenABI = GenieToken.abi;
      const genieTokenDeployed = new Contract(
        genieTokenABI,
        genieTokenAddress,
      );
      const data = genieTokenDeployed.methods
        .Non_FungibleAsset_NewNon_FungibleProductBatchCreated(
          code,
          from,
          to,
          uri,
          contractAddress,
        )
        .encodeABI();

      const txParam = {
        from: userInfo.data.bcAddress,
        nonce,
        gasPrice: 0,
        gasLimit: 8000000,
        to: genieTokenAddress,
        data,
      };

      const tx = await new EthereumTx(txParam, { chain: 4 });
      var bytes = CryptoJS.AES.decrypt(userInfo.data.privateEncrypted.toString(), props.userInfo.password)
      const decrypt_privatekey = bytes.toString(CryptoJS.enc.Utf8)
      const privateKey = decrypt_privatekey.substring(2, decrypt_privatekey.length)
      await tx.sign(Buffer.from(privateKey, 'hex'));
      const rawTx = await `0x${tx.serialize().toString('hex')}`;
      return rawTx;
    } catch (err) {
      setAlert(<Error onClose={() => setAlert(null)} title="Khoảng tem không hợp lệ" confirmBtnText={t('confirm')} />)
    }
  };

  const sendRaw = async (dataRaw, boxId) => {
    const {
      productTypeId,
      contractAddress,
      genieToken,
      nonce,
      uri,
    } = dataRaw;
    const { startId, endId } = dataRaw.stampUnitNonGs1;
    const raw = await creatRawActiveStamp(
      productTypeId,
      startId,
      endId,
      '',
      contractAddress,
      genieToken,
      nonce,
    );
    let data = {};
    if (checked) {
      const rawBox = await creatRawActiveStamp(
        productTypeId,
        boxId,
        boxId,
        uri,
        contractAddress,
        genieToken,
        nonce + 1,
      );
      data = {
        ...dataRaw,
        raw,
        rawBox,
        sign: 'a',
      };
    } else {
      data = {
        ...dataRaw,
        raw,
        sign: 'a',
      };
    }
    axios.defaults.headers.common.Authorization = userInfo.data.token;
    try {
      const response_send_raw = await axios({
        method: 'POST',
        url: LINK_API.ACTIVATE_STAMP_SEND_RAW,
        data: data
      })

      if (response_send_raw.data.errorCode === 1) {
        getValidStampRange();
        setAlert(<Success onClose={() => setAlert(null)} hideCancel={true} onConfirm={() => setAlert(null)} confirmBtnText={t('close')} />)
      } else {
        setAlert(<Error onClose={() => setAlert(null)} confirmBtnText={t('confirm')} />)
      }
    }
    catch (err) {
      setAlert(<Error onClose={() => setAlert(null)} title="Khoảng tem không hợp lệ" confirmBtnText={t('confirm')} />)
    }
  }

  React.useEffect(() => {
    if (harvestList.data) {
      const dataSelectHarvest = harvestList.data.map(item => {
        return {
          time: item.date,
          value: item.id,
          label: <div>
            <div>
              {`${item.id}: ${moment(parseInt(item.date)).format("DD/MM/YYYY HH:mm:ss")} ${item.productTypeName}`}
            </div>
          </div>
        }
      })
      console.log('dataSelectHarvest', dataSelectHarvest)
      setListHavest(dataSelectHarvest)
    }
  }, [harvestList])

  React.useEffect(() => {
    const idRecord = cookie.load("diaryRecord");
    const dataDiaryFilter = diaryRecordList.filter(item => item.value === idRecord)
    setDiaryRecord(dataDiaryFilter)
    const harvest = dataDiaryFilter[0] ? dataDiaryFilter[0].harvest : [];
    const data = listHavest.filter((x) => harvest.includes(x['value']))
    setListHavestRender(data)
  }, [diaryRecordList])
  

  const ExampleCustomInput = ({ value, onClick }) => (
    <Button block color="link" className="py-2 d-flex" style={{ border: "1px solid #d1d2db" }} size="sm" onClick={onClick}>
      <span className="btn-wrapper--icon">
        <CalenderIcon size={20} />
      </span>
      <span className="btn-wrapper--label">
        {moment(value).format("DD/MM/YYYY")}
      </span>
    </Button>
  );


  React.useEffect(() => {
    axios.defaults.headers.common['Authorization'] = userInfo.data.token;
    axios.get(LINK_API.GET_ALL_DIARY_RECORD)
      .then(res => {
        const data = res.data.data;
        const listDiaryRecord = data.map(item => {
          return {
            value: item.uuid,
            label: item.name,
            data: item,
            harvest: item.CropInfo ? item.CropInfo.harvests : []
          }
        })
        setDiaryRecordList(listDiaryRecord)
      })
      .catch(err => {
      })
  }, []);

  return (
    <>
      <div className="d-flex justify-content-center">
        <form onSubmit={formik.handleSubmit}>
          <div className="p-0">
            <div className="form-row">
            <div className="form-group col-md-12">
                <label className="font-weight-bold">
                 Chọn hồ sơ
                  <span className="text-danger ml-1">(*)
                  </span>
                </label>
                <Select
                  placeholder="Chọn hồ sơ"
                  options={diaryRecordList}
                  value={diaryRecord}
                  onChange={(selectedOption) => {
                    console.log("selectedOption", selectedOption)
                    const harvest = selectedOption.harvest;
                    const data = listHavest.filter((x) => harvest.includes(x['value']))
                    setListHavestRender(data)
                    setDiaryRecord(selectedOption)
                    formik.setFieldValue("harvest", "")
                    // formik.setFieldValue("diaryRecord", selectedOption)
                  }}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: '0.29rem',
                    borderWidth: 0.5,
                    height: 30,
                    colors: {
                      ...theme.colors,
                      primary25: 'rgba(60,68,177,0.15)',
                      primary50: 'rgba(60,68,177,0.15)',
                      primary: '#50b77a'
                    }
                  })}
                />
              </div>
              <div className="form-group col-md-12">
                <label className="font-weight-bold">
                  {t("statistic.harvest")}
                  <span className="text-danger ml-1">(*)
                  </span>
                </label>
                <Select
                  placeholder="Chọn đợt thu hoạch"
                  options={listHavestRender}
                  value={formik.harvest}
                  onChange={(selectedOption) => {
                    formik.setFieldValue("harvest", selectedOption)
                    setProductDate(selectedOption.time)
                  }}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: '0.29rem',
                    borderWidth: 0.5,
                    height: 30,
                    colors: {
                      ...theme.colors,
                      primary25: 'rgba(60,68,177,0.15)',
                      primary50: 'rgba(60,68,177,0.15)',
                      primary: '#50b77a'
                    }
                  })}
                />
                {formik.touched.harvest && formik.errors.harvest ? (
                  <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.startID}</p>
                ) : null}
              </div>
              <div className="form-group col-md-12">
                <div className="d-flex">
                  <label className="font-weight-bold">{t('stamp.start')}
                    <span className="text-danger ml-1">(*)
                    </span>
                  </label>
                  <HelpBoxIcon id="helpObject" className="text-info ml-2 cursor-pointer card-box-hover  card-box-hover-rise" />
                  <UncontrolledPopover placement="top" trigger="hover" target="helpObject" className="popover-custom-wrapper popover-custom-lg">
                    <ListGroup flush className="text-left bg-transparent">
                      <ListGroupItem className="rounded">
                        <div className="align-box-row">
                          <div className="pl-2">
                            <span className="pb-1 d-block">
                              <span className="font-weight-bold">
                                Khoảng tem bạn có là
                              </span>
                            </span>
                          </div>
                        </div>
                        <Alert color="info" className="mt-2">
                          <div className="mt-1">
                            {
                              validStampRange && validStampRange.products && validStampRange.products.map(item => {
                                return <div>{`- Từ ${item.startId} đến ${item.endId}`}</div>
                              })
                            }
                          </div>
                        </Alert>
                      </ListGroupItem>
                    </ListGroup>
                  </UncontrolledPopover>
                </div>
                <Input
                  placeholder={t('stamp.start')}
                  type="number"
                  value={formik.startID}
                  valid={formik.touched.startID && formik.errors.startID ? false : (formik.values.startID ? true : false)}
                  invalid={formik.touched.startID && formik.errors.startID ? true : false}
                  {...formik.getFieldProps('startID')}
                />
                {formik.touched.startID && formik.errors.startID ? (
                  <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.startID}</p>
                ) : null}
              </div>
              <div className="form-group col-md-12">
                <div className="d-flex">
                  <label className="font-weight-bold">{t('stamp.end')}
                    <span className="text-danger ml-1">(*)
                                                    </span>
                  </label>
                  <HelpBoxIcon id="helpObjectend" className="text-info ml-2 cursor-pointer card-box-hover  card-box-hover-rise" />
                  <UncontrolledPopover placement="top" trigger="hover" target="helpObjectend" className="popover-custom-wrapper popover-custom-lg">
                    <ListGroup flush className="text-left bg-transparent">
                      <ListGroupItem className="rounded">
                        <div className="align-box-row">
                          <div className="pl-2">
                            <span className="pb-1 d-block">
                              <span className="font-weight-bold">
                                Khoảng tem bạn có là
                              </span>
                            </span>
                          </div>
                        </div>
                        <Alert color="info" className="mt-2">
                          <div className="mt-1">
                            {
                              validStampRange && validStampRange.products && validStampRange.products.map(item => {
                                return <div>{`- Từ ${item.startId} đến ${item.endId}`}</div>
                              })
                            }
                          </div>
                        </Alert>
                      </ListGroupItem>
                    </ListGroup>
                  </UncontrolledPopover>
                </div>
                <Input
                  placeholder={t('stamp.end')}
                  type="number"
                  value={formik.endID}
                  valid={formik.touched.endID && formik.errors.endID ? false : (formik.values.endID ? true : false)}
                  invalid={formik.touched.endID && formik.errors.endID ? true : false}
                  {...formik.getFieldProps('endID')}
                />
                {formik.touched.endID && formik.errors.endID ? (
                  <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.endID}</p>
                ) : null}
              </div>
              <div className="form-group col-md-12">
              <label className="font-weight-bold"> Thời gian kích hoạt <span className="text-danger">(*)</span></label>
                      <DateTimePicker
                        className='form-control'
                        onChange={setHarvestTime}
                        value={harvestTime}
                        format="dd/MM/y HH:mm"
                        disableClock={true}
                        disableCalendar={true}
                      />
                      {harvestTime ? null : (
                        <p className="font-weight-regular font-size-sm text-danger" >Thông tin bắt buộc</p>
                      )}
                    </div>
            </div>
            {
              productDate ?
                <div className="form-row ">
                  <div className="form-group col-md-12">
                    <label className="font-weight-bold">{t("stamp.productDate")}</label>
                    <DatePicker className="form-control"
                      selected={productDate}
                      onChange={date => setProductDate(date)}
                      customInput={<ExampleCustomInput />}
                      locale="el"
                    />
                  </div>
                </div>
                :
                null
            }

            {/* <div className="form-row ">
              <div className="form-group col-md-12">
                <div className="">
                  <CustomInput
                    onChange={handleCheckBox}
                    className="" type="checkbox" id="exampleCustomCheckbox" value={checked}
                    label={t('import.closedBox')} />
                </div>
              </div>
            </div> */}

            {/* {
              checked ?
                <div className="form-row ">
                  <div className="form-group col-md-12">
                    <div className="d-flex">
                      <label className="font-weight-bold">{t('stamp.boxID')}
                        <span className="text-danger ml-1">(*)
                                                    </span>
                      </label>
                      <HelpBoxIcon id="helpBox" className="text-info ml-2 cursor-pointer card-box-hover  card-box-hover-rise" />
                      <UncontrolledPopover placement="top" trigger="hover" target="helpBox" className="popover-custom-wrapper popover-custom-lg">
                        <ListGroup flush className="text-left bg-transparent">
                          <ListGroupItem className="rounded">
                            <div className="align-box-row">
                              <div className="pl-2">
                                <span className="pb-1 d-block">
                                  <span className="font-weight-bold">
                                    Khoảng tem thùng bạn có là
                                  </span>
                                </span>
                              </div>
                            </div>
                            <Alert color="info" className="mt-2">
                              <div className="mt-1">
                                {
                                  validStampRange && validStampRange.cases && validStampRange.cases.map(item => {
                                    return <div>{`- Từ ${item.startId} đến ${item.endId}`}</div>
                                  })
                                }
                              </div>
                            </Alert>
                          </ListGroupItem>
                        </ListGroup>
                      </UncontrolledPopover>
                    </div>
                    <Input
                      placeholder={t('stamp.end')}
                      type="number"
                      value={formik.boxID}
                      valid={formik.touched.boxID && formik.errors.boxID ? false : (formik.values.boxID ? true : false)}
                      invalid={formik.touched.boxID && formik.errors.boxID ? true : false}
                      {...formik.getFieldProps('boxID')}
                    />
                    {formik.touched.boxID && formik.errors.boxID ? (
                      <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.boxID}</p>
                    ) : null}
                  </div>

                </div>
                :
                null
            } */}

            <div className="form-row ">
              <div className="form-group col-md-12 mb-0">
                <p><span className="text-danger">(*)</span> Trường thông tin bắt buộc</p>
              </div>

            </div>
            <div className="form-row ">
              <div className="form-group col-md-12 mb-0">
                <Button
                  size="lg"
                  disabled={!harvestTime}
                  type="submit"
                  className="text-uppercase font-weight-bold font-size-sm"
                  color="info">
                  {t('stamp.activate')}
                </Button>
              </div>
            </div>
          </div>
        </form>
      </div>

      {alert}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
    permission: state.permission,
    harvestList: state.harvestList,
    validStampRange: state.validStampRange
  }
}

const mapDispatchToProps = (dispatch) => ({
  getAllHarvest: () => dispatch({ type: "GET_HARVEST_LIST" }),
  getValidStampRange: () => dispatch({ type: "GET_VALID_STAMPS_RANGE" }),
});

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(ActivateStamp));