import { SAVE_USER_INFO, SAVE_USER_INFO_SUCCESS } from "actions/types"
import { put, takeLatest } from 'redux-saga/effects'

// Save User Info
function * saveUserInfo (data) {
    yield put({ type: SAVE_USER_INFO_SUCCESS, data: data.data, password: data.password})
}

//login saga
const userSaga = [
    takeLatest(SAVE_USER_INFO, saveUserInfo)
]
export default userSaga


