const initialState = { data: '', errorCode: '' }
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_BANNER':
      return {
        ...state
      }
    case 'UPDATE_BANNER_SUCCESS':
      return {
        ...state
      }
    case 'UPDATE_BANNER_FAILURE':
      return {
        ...state
      }
    default:
      return state
  }
}
export default reducer
