import React from "react"
import { withTranslation } from "react-i18next"
import { Button, Input } from "reactstrap"
import Switch from 'rc-switch';
import { useFormik } from 'formik';
import * as Yup from "yup"
const StepBasic = (props) => {
    const t = props.t;
    const {
        onNext
    } = props;
    const [checkedStamp, setStamp] = React.useState(false);
    const [checkedBox, setBox] = React.useState(false);
    const toggleStamp = async () => {
        await setStamp(!checkedStamp)
    };
    const toggleBox = async () => {
        await setBox(!checkedBox)
    };
    const formik = useFormik({
        initialValues: {
            process_name: '',
            nameStamp: 'Đóng gói và kích hoạt tem',
            nameBox: 'Đóng thùng',
            desStamp: 'Sản phẩm sau khi sơ chế sẽ được đóng gói, dán tem. Mỗi con tem QR sẽ chứa thông tin định danh của một sản phẩm, nhà sản xuất sẽ kích hoạt để có thể hiển thị thông tin khi người dùng quét tem truy xuất',
            desBox: 'Các sản phẩm sau khi đóng gói sẽ được xếp vào thùng hàng. Mỗi thùng sẽ được dán tem QR dùng để định danh, theo dõi luồng di chuyển trong chuỗi cung ứng.'
        },
        validationSchema: Yup.object({
            process_name: Yup.string()
                .required(t('require')),
        }),
        onSubmit: React.useCallback(async (values) => {
            let data = {
                "process_name": values.process_name,
                "type": 1,
                "roles": [{ "uuid": "0001", "role": "C" }],
                "specialStep": {
                    "activateStamp": { "show": checkedStamp, "name": values.nameStamp, "description": values.desStamp },
                    "packed": {
                        "show": checkedBox, "name": values.nameBox, "description": values.desBox
                    }
                }
            }
            onNext(data)

        }, [onNext, checkedStamp, checkedBox]),
    });
    const dataBasic = props.data;
    React.useEffect(() => {
        setTimeout(() => {
            if (dataBasic) {
                if (dataBasic[0]) {
                    let temp = dataBasic[0];
                    formik.setValues({
                        process_name: temp.process_name,
                        nameStamp: temp.specialStep.activateStamp.name,
                        nameBox: temp.specialStep.packed.name,
                        desStamp: temp.specialStep.activateStamp.description,
                        desBox: temp.specialStep.packed.description,
                    })
                    setStamp(temp.specialStep.activateStamp.show)
                    setBox(temp.specialStep.packed.show)
                }
            }
        }, 1000);
    }, [dataBasic])
    React.useEffect(() => {
        if (dataBasic) {
            if (dataBasic[0]) {
                let temp = dataBasic[0];
                formik.setValues({
                    process_name: temp.process_name,
                    nameStamp: temp.specialStep.activateStamp.name,
                    nameBox: temp.specialStep.packed.name,
                    desStamp: temp.specialStep.activateStamp.description,
                    desBox: temp.specialStep.packed.description,
                })
                setStamp(temp.specialStep.activateStamp.show)
                setBox(temp.specialStep.packed.show)
            }
        }
    }, [dataBasic])
    return (
        <>
            <div className="wizard-steps vertical">
                <form onSubmit={formik.handleSubmit} className="w-100">
                    <div className="p-4 w-100">
                        <h5 className="font-size-xl font-weight-bold">{t("process.basicInfo")}</h5>
                        <p className="text-black-50 mb-4">{t('process.basicDes')}</p>
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label className="font-weight-bold">{t('process.name')} <span className="text-danger">(*)</span></label>
                                <Input
                                    className="form-control"
                                    id="process_name"
                                    value={formik.values.process_name}
                                    placeholder={t('process.name')}
                                    valid={formik.touched.process_name && formik.errors.process_name ? false : (formik.values.process_name ? true : false)}
                                    invalid={formik.touched.process_name && formik.errors.process_name ? true : false}
                                    type="text"
                                    {...formik.getFieldProps('process_name')}
                                />
                                {formik.touched.process_name && formik.errors.process_name ? (
                                    <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.process_name}</p>
                                ) : null}
                            </div>
                        </div>
                        <div className="m-2 form-row">
                            <Switch checked={checkedStamp} onClick={toggleStamp} className="switch-small toggle-switch-success"></Switch>
                            <p className="font-weight-bold ml-2">{t(`process.activeStamp`)}</p>
                        </div>
                        {checkedStamp ?
                            <div className="form-row">
                                <div className="form-group col-md-12">
                                    <label className="font-weight-bold">{t('process.nameStep')}</label>
                                    <Input
                                        className="form-control"
                                        id="inputEmail4"
                                        placeholder={t('process.nameStep')}
                                        values={formik.values.nameStamp}
                                        valid={formik.touched.nameStamp && formik.errors.nameStamp ? false : (formik.values.nameStamp ? true : false)}
                                        invalid={formik.touched.nameStamp && formik.errors.nameStamp ? true : false}
                                        type="text"
                                        {...formik.getFieldProps('nameStamp')}
                                    />
                                </div>
                                <div className="form-group col-md-12">
                                    <label className="font-weight-bold">{t('process.desStep')}</label>
                                    <Input
                                        className="form-control"
                                        id="inputEmail4"
                                        placeholder={t('process.desStep')}
                                        values={formik.values.desStamp}
                                        valid={formik.touched.desStamp && formik.errors.desStamp ? false : (formik.values.desStamp ? true : false)}
                                        invalid={formik.touched.desStamp && formik.errors.desStamp ? true : false}
                                        type="text"
                                        {...formik.getFieldProps('desStamp')}
                                    />
                                </div>
                            </div>
                            : null}
                        <div className="m-2 form-row">
                            <Switch checked={checkedBox} onClick={toggleBox} className="switch-small toggle-switch-success"></Switch>
                            <p className="font-weight-bold ml-2">{t(`process.wrapBox`)}</p>
                        </div>
                        {checkedBox ?
                            <div className="form-row">
                                <div className="form-group col-md-12">
                                    <label className="font-weight-bold">{t('process.nameStep')}</label>
                                    <Input
                                        className="form-control"
                                        id="inputEmail4"
                                        placeholder={t('process.nameStep')}
                                        type="text"
                                        values={formik.values.nameBox}
                                        valid={formik.touched.nameBox && formik.errors.nameBox ? false : (formik.values.nameBox ? true : false)}
                                        invalid={formik.touched.nameBox && formik.errors.nameBox ? true : false}
                                        type="text"
                                        {...formik.getFieldProps('nameBox')}
                                    />
                                </div>
                                <div className="form-group col-md-12">
                                    <label className="font-weight-bold">{t('process.desStep')}</label>
                                    <Input
                                        className="form-control"
                                        id="inputEmail4"
                                        placeholder={t('process.desStep')}
                                        values={formik.values.desBox}
                                        valid={formik.touched.desBox && formik.errors.desBox ? false : (formik.values.desBox ? true : false)}
                                        invalid={formik.touched.desBox && formik.errors.desBox ? true : false}
                                        type="text"
                                        {...formik.getFieldProps('desBox')}
                                    />
                                </div>
                            </div> : null}
                        <div className="form-row ">
                            <div className="form-group col-md-6 mb-0 d-flex align-items-center">
                                <p><span className="text-danger">(*)</span> {t('require')}</p>
                            </div>
                            <div className="form-group col-md-6 d-flex justify-content-end mb-0">
                                <Button
                                    size="lg"
                                    type="submit"
                                    className="text-uppercase font-weight-bold font-size-sm"
                                    color="neutral-success">
                                    {t('next')}
                                </Button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}
export default withTranslation('common')(StepBasic)