import {
  CREATE_EMPLOYEE,
  PUSH_NOTI_PRODUCT_SUCCESS,
  DEACTIVE_NOTI_PRODUCT,
  GET_EMPLOYEE_LIST,
  SAVE_DATA_EDIT_EMPLOYEE_SUCCESS,
  SAVE_DATA_EDIT_EMPLOYEE,
  SAVE_NOTIFICATION
} from "actions/types"
import { takeLatest, call, put, select, delay } from 'redux-saga/effects'
import { LINK_API } from 'constants/API.js'
import axios from 'axios'
import responseCode from 'constants/errorCode.js'

const getToken = (state) => state.userInfo
// const getSaveSetting = (state) => state.settingList.data
const CryptoJS = require('crypto-js')
const EthereumTx = require('ethereumjs-tx');
// const t = props.t;

//SAVE DATA EDIT EMP


// Create Employee
function* watchCreateEmployee(data) {
  yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Đang xử lý", color: 'warning' })
  yield delay(2000)
  yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
  const token = yield select(getToken)
  const { response, error } = yield call(createEmployee, data.data, token.data.token)
  if (response) {
    const result = response.data
    if (result.errorCode === 1) {
      // Sign Raw transaction
      const dataraw = data.data
      var bytes = CryptoJS.AES.decrypt(token.data.privateEncrypted.toString(), token.password)
      const decrypt_privatekey = bytes.toString(CryptoJS.enc.Utf8)
      const privatekey_slice = decrypt_privatekey.substring(2, decrypt_privatekey.length)
      const Buffer_privatekey = Buffer.from(privatekey_slice.toString(), 'hex')
      const tx = new EthereumTx(result.data.raw, { chain: 4 })
      tx.sign(Buffer_privatekey)
      const rawTx = '0x' + tx.serialize().toString('hex')
      dataraw.raw = rawTx
      dataraw.uuid = result.data.uuid
      dataraw.nonce = result.data.nonce
      // Send raw and data to backend
      const { final_result, error_raw } = yield call(sendRawEmployee, dataraw, token.data.token)
      if (final_result) {
        if (final_result.data.errorCode === 1) {
          yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Tạo nhân viên thành công", color: 'success' })
          yield put({ type: SAVE_NOTIFICATION, data: { type: 'success', message: "Tạo nhân viên thành công" + result.data.uuid} })
          yield put({ type: GET_EMPLOYEE_LIST, page: 1, size: 5 })
          yield delay(2000)
          yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
        } else {
          if (responseCode[`${final_result.data.data.name}`]) {

            yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: responseCode[`${final_result.data.data.name}`].vi, color: 'error' })
            yield put({ type: SAVE_NOTIFICATION, data: { type: 'error', message: "Tạo nhân viên thất bại"} })
            yield put({ type: GET_EMPLOYEE_LIST, page: 1, size: 5 })
            yield delay(2000)
            yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })

          } else {
            yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Tạo nhân viên thất bại", color: 'error' })
            yield put({ type: SAVE_NOTIFICATION, data: { type: 'error', message: "Tạo nhân viên thất bại"} })
            yield put({ type: GET_EMPLOYEE_LIST, page: 1, size: 5 })
            yield delay(2000)
            yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
          }
        }
      } else {
        if (error_raw.response.data.name) {
          yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: responseCode[`${error_raw.response.data.name}`].vi, color: 'error' })
          yield put({ type: SAVE_NOTIFICATION, data: { type: 'error', message: "Tạo nhân viên thất bại"} })
          yield put({ type: GET_EMPLOYEE_LIST, page: 1, size: 5 })
          yield delay(2000)
          yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
        } else {
          yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Tạo nhân viên thất bại", color: 'error' })
          yield put({ type: SAVE_NOTIFICATION, data: { type: 'error', message: "Tạo nhân viên thất bại"} })
          yield put({ type: GET_EMPLOYEE_LIST, page: 1, size: 5 })
          yield delay(2000)
          yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
        }
      }
    } else {
      if (responseCode[`${result.name}`]) {
        yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: responseCode[`${result.name}`].vi, color: 'error' })
        yield put({ type: SAVE_NOTIFICATION, data: { type: 'error', message: responseCode[`${result.name}`].vi} })
        yield delay(2000)
        yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
      } else {
        yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Tạo nhân viên thất bại", color: 'error' })
        yield put({ type: SAVE_NOTIFICATION, data: { type: 'error', message: "Tạo nhân viên thất bại"} })
        yield delay(2000)
        yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
      }
    }
  } else {
    if (error.response.data.name) {
      yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: responseCode[`${error.response.data.name}`].vi, color: 'error' })
      yield put({ type: SAVE_NOTIFICATION, data: { type: 'error', message: responseCode[`${error.response.data.name}`].vi} })
      yield delay(2000)
      yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
    } else {
      yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: responseCode[`${error.response.data.name}`].vi, color: 'error' })
      yield put({ type: SAVE_NOTIFICATION, data: { type: 'error', message: responseCode[`${error.response.data.name}`].vi} })
      yield delay(2000)
      yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
    }
  }
}
function *saveDataEmployee(data){
  yield put({type: SAVE_DATA_EDIT_EMPLOYEE_SUCCESS, dataOne: data.dataOne, dataTwo: data.dataTwo, check: data.check})
}
//raw
function sendRawEmployee(data, checktoken) {
  axios.defaults.headers.common.Authorization = checktoken
  return axios.post(LINK_API.SEND_RAW_EMPLOYEE, data)
    .then(final_result => ({ final_result }))
    .catch(error_raw => ({ error_raw }))
}


function createEmployee(data, checktoken) {
  axios.defaults.headers.common.Authorization = checktoken
  return axios.post(LINK_API.CREATE_EMPLOYEE, data)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

const handleCreateEmployee = [
  takeLatest(CREATE_EMPLOYEE, watchCreateEmployee),
  takeLatest(SAVE_DATA_EDIT_EMPLOYEE, saveDataEmployee)
]

export default handleCreateEmployee;
