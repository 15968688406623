import axios from 'axios';
import clsx from "clsx";
import { LINK_API } from 'constants/API.js';
import PageTitle from 'layout-components/PageTitle/index.js';
import AreaIcon from "mdi-react/MountainIcon";
import React from "react";
import cookie from 'react-cookies';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { Button, Nav, NavItem, NavLink as NavLinkStrap, TabContent, TabPane, Card, Container } from "reactstrap";
import "./css/edit.css";
import Step1 from "./steps/step1";
import Step2 from "./steps/step2";
import Header from "components/Headers/Header"
function EditArea(props) {
    const stepOneRef = React.createRef();
    const uuid = cookie.load("uuidArea");
    const permission = props.permission.data;
    const stepTwoRef = React.createRef();
    const [checkSetting, setPermission] = React.useState(false)
    const dataUpdate = props.dataUpdate;
    const t = props.t;
    const [activeTab, setActiveTab] = React.useState('1');
    const [dataArea, setArea] = React.useState('');
    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }
    React.useEffect(() => {
        setPermission(permission.advanceSetting)
    }, [permission])
    React.useEffect(() => {
        axios.get(LINK_API.GET_AREA_DETAIL + uuid)
            .then(async response => {
                if (response.data.errorCode === 1) {
                    setArea(response.data.data)
                }
            })
            .catch(err => {
                console.log(err);
            })
    }, [uuid])
    React.useEffect(() => {
        if (dataUpdate.dataOne && dataUpdate.dataTwo) {
            let dataOne = dataUpdate.dataOne;
            let dataTwo = dataUpdate.dataTwo;
            let data = {
                "uuid": uuid,
                "name": {
                    "vi": dataOne.nameArea
                },
                "description": {
                    "vi": dataOne.description
                },
                "address": {
                    "vi": dataOne.address
                },
                "gln": dataOne.gln ? dataOne.gln : '',
                "type": dataOne.type,
                "managerId": dataTwo.employee ? dataTwo.employee : "",
                "district": "",
                "glnCheckbox": dataTwo.gln ? true : false,
                "isShowOnPortal": dataTwo.portal === "true" ? true : false
            }
            props.updateArea(data);
            props.history.push('/user/area');
            props.saveArea('', '', true)
        }
        if (checkSetting === false && dataUpdate.dataOne) {
            let dataOne = dataUpdate.dataOne;
            // let dataTwo = dataUpdate.dataTwo;
            let data = {
                "uuid": uuid,
                "name": {
                    "vi": dataOne.nameArea
                },
                "description": {
                    "vi": dataOne.description
                },
                "address": {
                    "vi": dataOne.address
                },
                "gln": dataOne.gln ? dataOne.gln : '',
                "type": dataOne.type,
                "managerId": "",
                "district": "",
                "glnCheckbox": false,
                "isShowOnPortal": false
            }
            props.updateArea(data);
            props.history.push('/user/area');
            props.saveArea('', '', true)
        }
    }, [dataUpdate])

    const handleGetData = async () => {
        await stepOneRef.current.handleMergeData();
        if (checkSetting) {
            await stepTwoRef.current.handleMergeData()
        }
        // setTimeout(handleSubmit, 5000)
    }
    return (
        <>
           <Header/>
        <Container className="mt--7" fluid>
        {permission.agdqr ? null :<PageTitle
                titleHeading="Nhóm sản phẩm"
                titleDescription="Chỉnh sửa thông tin nhóm sản phẩm"
                pageTitleDescription={true}
                titleIcon={<AreaIcon className="textSuccess" style={{ fill: "#4FB779" }} />}
                children={<Button color="info" size="sm" onClick={handleGetData}>{t('save')}</Button>}
            />}
        <Card className="card-box my-5">
            {permission.agdqr ? <div> </div> :
                <>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                            <div className="bg-white">
                                <Step1
                                    data={dataArea}
                                    ref={stepOneRef} />
                            </div>
                        </TabPane>

                        <TabPane tabId="2">
                            <div className="bg-white">
                                <Step2 data={dataArea}
                                    ref={stepTwoRef} />
                            </div>
                        </TabPane>
                    </TabContent>
                </>
            }
            </Card>
            </Container>
        </>
    )
}
const mapStateToProps = (state) => ({
    dataUpdate: state.dataEditArea,
    permission: state.permission
})
const mapDispatchToProps = (dispatch) => ({
    updateArea: (data) => dispatch({ type: "UPDATE_INFO_AREA", data: data }),
    saveArea: (dataOne, dataTwo, check) => dispatch({ type: "SAVE_DATA_EDIT_AREA", dataOne: dataOne, dataTwo: dataTwo, check: check }),
});

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(EditArea));