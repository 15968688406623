import axios from 'axios';
import LoadingTable from "components/LoadingTable/index.js";
import Pagination from "components/Pagination/index.jsx";
import { LINK_API } from 'constants/API.js';
import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ReactTable from 'react-table-6';
import SubTable from "components/SubTable/table.js";
// import "../../css/reactTable.css";

function StampList(props) {
  const [activityRender, setActivity] = React.useState([])
  const [loadingState, setLoading] = React.useState(true)
  const { t } = props;

  const {
    userInfo
  } = props;

  useEffect(() => {
    setLoading(true)
    try {
      axios.defaults.headers.common.Authorization = userInfo.data.token
      axios({
        method: 'GET',
        url: LINK_API.GET_HISTORY_DIARY_REPORT.concat('?date=', props.date)
      }).then((response) => {
        if (response && response.data && response.data.data) {
          let dataRender = response.data.data.map((item) => {
            let dataDetail = []
            if (item.activity && item.activity.length > 0) {
              dataDetail = item.activity.map(itemDiary => {
                return itemDiary;
              })
            }
            return {
              uuid: item.uuid,
              name: item.name,
              dataDetail: dataDetail
            }
          })
          setActivity(dataRender)
          setLoading(false)
        }
        else {
          setActivity([])
          setLoading(false)
        }
      })
        .catch(() => {
          setActivity([])
          setLoading(false)
        })
    }
    catch {
      setLoading(false)
    }
  }, [props.date, userInfo.data.token]);

  const columns = React.useMemo(
    () => [
      {
        Header: t('profile.uuid'),
        accessor: "uuid",
        sortable: false,
        filterable: true,
        filterMethod: (filter, row) => {
          if (row.uuid) {
            const timeInRow = row.uuid.toUpperCase().search(filter.value.toUpperCase());
            if (timeInRow === -1) return false;
            return true;
          }
          return false;
        },
      },
      {
        Header: t('profile.name'),
        accessor: "name",
        filterable: true,
        filterMethod: (filter, row) => {
          if (row.name) {
            const timeInRow = row.name.toUpperCase().search(filter.value.toUpperCase());
            if (timeInRow === -1) return false;
            return true;
          }
          return false;
        },
      }
    ],
    [t]
  );


  const columnSubTable = [
    {
      accessor: 'stepName',
      Header: "Tên bước",
      filterable: true,
      filterMethod: (filter, row) => {
        if (row.stepName) {
          const timeInRow = row.stepName.toUpperCase().search(filter.value.toUpperCase());
          if (timeInRow === -1) return false;
          return true;
        }
        return false;
      },
    },
    // {
    //   accessor: 'actor',
    //   Header: "Người thực hiện",
    //   filterable: true,
    //   filterMethod: (filter, row) => {
    //     if (row.actor) {
    //       const timeInRow = row.actor.toUpperCase().search(filter.value.toUpperCase());
    //       if (timeInRow === -1) return false;
    //       return true;
    //     }
    //     return false;
    //   },
    // },
    {
      accessor: 'date',
      Header: "Thời gian thực hiện",
      filterable: true,
      filterMethod: (filter, row) => {
        if (row.date) {
          const timeInRow = row.date.toUpperCase().search(filter.value.toUpperCase());
          if (timeInRow === -1) return false;
          return true;
        }
        return false;
      },
    },
    {
      accessor: 'po',
      Header: "Tên sản phẩm",
      filterable: true,
      filterMethod: (filter, row) => {
        if (row.po) {
          const timeInRow = row.po.toUpperCase().search(filter.value.toUpperCase());
          if (timeInRow === -1) return false;
          return true;
        }
        return false;
      },
    },
    {
      accessor: 'process_name',
      Header: "Tên quy trình",
    },
  ]


  return (
    <>
      <div className="divider" />
      <div className="card-body pt-2 px-4 pb-4">
        <ReactTable
          noDataText={t('noData')}
          data={activityRender}
          columns={columns}
          previousText={"<"}
          nextText={">"}
          rowsText={t('row')}
          ofText="/"
          // manual
          defaultPageSize={5}
          showPaginationBottom={true}
          sortable={false}
          resizable={true}
          PaginationComponent={Pagination}
          // pages={totalPage}
          className="-striped -highlight"
          loading={loadingState}
          LoadingComponent={LoadingTable}
          SubComponent={row => {
            return (
              <ReactTable
                noDataText={t('noData')}
                data={row.original.dataDetail}
                columns={columnSubTable}
                previousText={"<"}
                nextText={">"}
                rowsText={t('row')}
                ofText="/"
                defaultPageSize={5}
                showPaginationBottom={true}
                sortable={false}
                resizable={true}
                PaginationComponent={Pagination}
                className="-striped -highlight"
                loading={loadingState}
                LoadingComponent={LoadingTable}
              />
            )
          }}
        />
      </div>
      {/* </Card> */}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
    allScanStamp: state.allScanStamp,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getAllScanStamp: (data) => dispatch({ type: "GET_ALL_SCAN_STAMP", data: data }),
});

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(StampList));