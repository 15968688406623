import LoadingTable from "components/LoadingTable/index.js";
import Pagination from "components/Pagination/index.jsx";
import { AGD_LOGO } from "constants/image";
import DetailIcon from "mdi-react/InformationIcon";
import PlusCircle from "mdi-react/PlusCircleIcon";
import CloseIcon from "mdi-react/CloseIcon"
import moment from "moment";
import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ReactTable from 'react-table-6';
import Truncate from 'react-truncate';
import { Button, Card, Col, Container, Row, UncontrolledTooltip, UncontrolledDropdown, DropdownToggle, DropdownMenu, NavItem, Nav, Input, CustomInput, NavLink as NavLinkStrap } from 'reactstrap';
import "../../css/reactTable.css";
import cookie from "react-cookies";
import FilterIcon from "mdi-react/FilterIcon"
import { useFormik } from 'formik';
import ResetIcon from "mdi-react/RestoreIcon"
import * as Yup from 'yup';
import { motion } from "framer-motion";
import Header from "components/Headers/Header"
function ObjectProcessingList(props) {
  const getObjectList = props.getObjectList;
  const getObjectFilter = props.getObjectFilter;
  const [filterStatus, setFilter] = React.useState(false)
  const [dataObjectList, setDataObjectList] = React.useState([])
  const [totalPage, setTotalPage] = React.useState([])
  const [loadingState, setLoading] = React.useState(true)
  const permission = props.permission;
  const [checkPer, setPer] = React.useState(false);
  React.useEffect(() => {
    if (permission) {
      if(permission.maxObject !== -1)
      if (permission.object >= permission.maxObject) {
        setPer(true)
      }
    }
  }, [permission])
  const handleChangePage = () => {
    props.history.push("/user/createObjectProcessing")
  }
  const { t } = props;
  const handleType = React.useCallback((type) => {
    if (type === 0) return (t('oProduction.row'));
    if (type === 1) return (t('oProduction.tree'));
    if (type === 2) return (t('oProduction.all'));
    if (type === 3) return (t('oProduction.batch'));
    if (type === 4) return (t('oProduction.batch'));
    return "";
  }, [t])

  const handleDetailPartner = (uuid) => {
    cookie.save("uuidOProcessing", uuid, { path: "/" });
    props.history.push("/user/detailObjectProcessing")
  }

  const genTable = React.useCallback((data) => {
    let listObject = data.map((prop, index) => {
      return {
        id: prop.uuid,
        name:
          <div style={{ display: "flex", flexFlow: "row", alignItems: "center" }}>
            <Container>
              <Row>
                <Col xs={12} sm={12} md={5} style={{ textAlign: "start" }}>
                  {(prop.images360) && (prop.images360.length > 0) ? (
                    <img alt="profileAGD" src={prop.images360[0].secure_url} style={{ height: "37px", width: "37px" }} />
                  ) :
                    (prop.image) && (prop.image.length > 0) ? (
                      <img alt="profileAGD" src={prop.image[0].secure_url} style={{ height: "37px", width: "37px" }} />
                    ) :
                      <img alt="profileAGD" src={AGD_LOGO} style={{ height: "37px", width: "37px" }} />}
                </Col >
                <Col xs={12} sm={12} md={7} style={{ display: "flex", alignItems: "center", textAlign: "left" }}>
                  <Truncate lines={2} ellipsis={<span>...</span>}>
                    {prop.name}
                  </Truncate>
                </Col>
              </Row>
            </Container>
          </div>,
        date: moment(prop.registeredDate).format("DD/MM/YYYY"),
        area: prop.nameOfZone,
        type: handleType(prop.type),
        actions: <div>
          <Button color="neutral-info" disabled={permission.agdqr} onClick={() => handleDetailPartner(prop.uuid)} className="mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center" id={"oInfo" + index}>
            <span className="btn-wrapper--icon d-flex">
              <DetailIcon size={18} className="text-info font-size-sm" />
            </span>
          </Button>
          <UncontrolledTooltip trigger="hover" popperClassName="tooltip-black" placement="top" container="body" target={"oInfo" + index}>
            {t('oProduction.detail')}
          </UncontrolledTooltip>
          <Button  disabled={permission.agdqr} color="neutral-danger" className="mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center" id={"oProductionDeactive" + index}>
            <span className="btn-wrapper--icon d-flex">
              <CloseIcon
                size={18}
                className="text-danger font-size-sm"
              />
            </span>
          </Button>
          <UncontrolledTooltip trigger="hover" popperClassName="tooltip-black" placement="top" container="body" target={"oProductionDeactive" + index}>
            {t('oProduction.deActive')}
          </UncontrolledTooltip>
        </div>
      }
    })
    setDataObjectList(listObject)
    setLoading(false)
  }, [t, handleType])

  useEffect(() => {
    getObjectList(1, 5)
  }, [getObjectList]);
  const formik = useFormik({
    initialValues: {
      name: 'name',
      value: '',
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required(t('require')),
      value: Yup.string()
        .required(t('require')),
    }),
    onSubmit: React.useCallback(async (values) => {
      setFilter(true);
      setLoading(true);
      getObjectFilter(1, values.name, values.value, 5)
    }, [getObjectFilter]),
  });
  const objectList = props.objectList.data
  const totalPageProps = props.objectList.totalPage
  useEffect(() => {
    if (objectList && objectList.length > 0) {
      genTable(objectList)
      setTotalPage(totalPageProps)
    } else {
      genTable([])
      setTotalPage(0)
    }

  }, [totalPageProps, objectList, genTable]);


  const columns = React.useMemo(
    () => [
      {
        accessor: "id",
        filterable: false,
        className: "center",
        maxWidth: 150,
        Header: () => (
          <div style={{
            textAlign: "center"
          }}>
            {t('oProduction.id')}
          </div>
        )
      },
      {
        Header: t('oProduction.name'),
        minWidth: 107,
        accessor: "name",
        filterable: false
      },
      {
        Header: t('oProduction.date'),
        accessor: "date",
        filterable: false,
        resizable: true,
      },
      {
        Header: t('oProduction.area'),
        accessor: "area",
        filterable: false,
        resizable: true,
      },
      {
        Header: t('oProduction.typeProcess'),
        accessor: "type",
        filterable: false
      },
      {
        Header: t('actions'),
        accessor: "actions",
        filterable: false
      },
    ],
    [t]
  );

  const handleResetData = () => {
    setFilter(false);
    getObjectList(1, 5)
  }
  const handleClearFilter = () => {
    formik.resetForm();
  }

  return (

    <>
     <motion.div
                className="w-100"
                initial={{ x: -100 }}
                animate={{ x: 0 }}
                transition={{ ease: "easeOut", duration: 0.3 }}
            >
               <Header/>
              <Container className='mt--7' fluid>
      <Card className="card-box mb-5">
        <div className="card-header py-3 d-flex justify-content-between">
      
          <div className=" font-weight-bold card-header--title font-size-lg">{t('oProduction.listProcess')}</div>
          <div className="card-header--actions d-flex flex-row">
            <UncontrolledDropdown>
              <DropdownToggle id="filterButton" color="neutral-success" className="mx-2">
                <FilterIcon size={20} /> {t('filter.filter')}
              </DropdownToggle>
              <DropdownMenu right className="dropdown-menu-xxl p-0">
                <form onSubmit={formik.handleSubmit}>
                  <div className="p-3">
                    <Row>
                      <Col md="6">
                        <small className="font-weight-bold pb-2 text-uppercase text-primary d-block">
                          {t('filter.name')}
                        </small>
                        <CustomInput
                          type="select"
                          placeholder={t('filter.name')}
                          value={formik.values.name}
                          valid={formik.touched.name && formik.errors.name ? false : (formik.values.name ? true : false)}
                          invalid={formik.touched.name && formik.errors.name ? true : false}
                          {...formik.getFieldProps('name')}
                        >
                          <option value="name">{t('oProduction.name')}</option>
                          <option value="uuid">{t('oProduction.id')}</option>
                          {/* <option value="status">{t('product.status')}</option> */}
                        </CustomInput>
                      </Col>
                      <Col md="6">
                        <small className="font-weight-bold pb-2 text-uppercase text-primary d-block">
                          {t('filter.value')}
                        </small>
                        {formik.values.name !== "status" ?
                          <Input
                            placeholder={t('filter.value')}
                            value={formik.values.value}
                            valid={formik.touched.value && formik.errors.value ? false : (formik.values.value ? true : false)}
                            invalid={formik.touched.value && formik.errors.value ? true : false}
                            {...formik.getFieldProps('value')}
                          >
                          </Input> : <CustomInput
                            type="select"
                            placeholder={t('filter.value')}
                            value={formik.values.value}
                            valid={formik.touched.value && formik.errors.value ? false : (formik.values.value || formik.values.value === 0 ? true : false)}
                            invalid={formik.touched.value && formik.errors.value ? true : false}
                            {...formik.getFieldProps('value')}
                          >
                            <option value={1}>{t('product.inProduction')}</option>
                            <option value={0}>{t('product.stopProduction')}</option>
                          </CustomInput>}
                      </Col>
                  `</Row>

                  </div>
                  <div className="divider" />
                  <div className="p-3 text-center bg-secondary">
                    <Button color="primary" type="submit">
                      {t('filter.search')}
                    </Button>
                  </div>
                </form>
                <div className="divider" />
                <div className="p-3">
                  <Row>
                    <Col md="6">
                      <Nav className="nav-neutral-danger flex-column p-0">
                        <NavItem>
                          <NavLinkStrap className="d-flex rounded-sm justify-content-center" onClick={handleClearFilter}>
                            <div className="nav-link-icon">
                              <CloseIcon />
                            </div>
                            <span>{t('filter.clear')}</span>
                          </NavLinkStrap>
                        </NavItem>
                      </Nav>
                    </Col>
                    <Col md="6">
                      <Nav className="nav-neutral-info flex-column p-0">
                        <NavItem>
                          <NavLinkStrap className="d-flex rounded-sm justify-content-center" onClick={handleResetData}>
                            <div className="nav-link-icon">
                              <ResetIcon />
                            </div>
                            <span>{t('filter.reset')}</span>
                          </NavLinkStrap>
                        </NavItem>
                      </Nav>
                    </Col>
                  </Row>
                </div>
              </DropdownMenu>
            </UncontrolledDropdown>
            <UncontrolledTooltip trigger="hover" popperClassName="tooltip-black" placement="top" container="body" target={"filterButton"}>
              {t('filter.filter')}
            </UncontrolledTooltip>
            <div id="notiPlan" className="d-inline-block">
              <Button disabled={checkPer || permission.agdqr} color="info" onClick={handleChangePage}>
                <span className="btn-wrapper--icon">
                  <PlusCircle size={16} />
                </span>
                <span className="btn-wrapper--label">{t('product.add')}</span>
              </Button>
            </div>

          </div>
        </div>
        {checkPer || permission.agdqr ?
          <UncontrolledTooltip trigger="hover" popperClassName="tooltip-warning" placement="top" container="body" target={"notiPlan"}>
            {t('notiPlan')}
          </UncontrolledTooltip> : null}
        <div className="divider" />
        <div className="card-body pt-2 px-4 pb-4">

          <ReactTable
            noDataText={t('noData')}
            data={dataObjectList}
            columns={columns}
            previousText={"<"}
            nextText={">"}
            rowsText={t('row')}
            ofText="/"
            loading={loadingState}
            LoadingComponent={LoadingTable}
            manual
            defaultPageSize={5}
            showPaginationBottom={true}
            sortable={false}
            resizable={true}
            PaginationComponent={Pagination}
            pages={totalPage}
            className="-striped -highlight"
            onFetchData={async (state, instance) => {
              setLoading(true)
              let x = state.page + 1;
              //getObjectList(x, state.pageSize);
              if (filterStatus === false) {
                getObjectList(x, state.pageSize)
              } else {
                getObjectFilter(x, formik.values.name, formik.values.value, state.pageSize)
              }
            }}
          />
        </div>
      </Card>
      </Container>
      </motion.div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
    userProfile: state.userProfile.data,
    objectList: state.objectProcessList,
    permission: state.permission.data
  }
}

const mapDispatchToProps = (dispatch) => ({
  getObjectList: (page, size) => dispatch({ type: "GET_OBJECT_PROCESSING_LIST", page: page, size: size }),
  getObjectFilter: (page, name, value, size) => dispatch({ type: "GET_OBJECT_RPOCESSING_FILTER", page: page, name: name, value: value, size: size }),

});

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(ObjectProcessingList));