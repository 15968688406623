import LoadingTable from "components/LoadingTable/index.js";
import Pagination from "components/Pagination/index.jsx";
import moment from 'moment';
import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ReactTable from 'react-table-6';
import { Card, Container } from 'reactstrap';
import "../../css/reactTable.css";
import cookie from 'react-cookies';
import SubTable from "components/SubTable/table.js";
import Header from "components/Headers/Header"

function StampList(props) {
  const getUsedStampList = props.getUsedStampList;
  const [dataStampList, setStampList] = React.useState([])
  const [totalPage, setTotalPage] = React.useState([])
  const [loadingState, setLoading] = React.useState(true)
  const { t } = props;

  useEffect(() => {
    const type = cookie.load('type', { path: '/' });
    const minId = cookie.load('minId', { path: '/' });
    const maxId = cookie.load('maxId', { path: '/' });
    const startdate = cookie.load('startdate', { path: '/' });
    const enddate = cookie.load('enddate', { path: '/' });

    const startTime = startdate;
    const endTime = enddate;
    cookie.remove('startdate', { path: '/' });
    cookie.remove('enddate', { path: '/' });
    const page = 1;
    const size = 5;
    const data = {
      type: type,
      startTime: startTime,
      endTime: endTime,
      maxId: maxId,
      minId: minId,
      page: page,
      size: size
    }

    getUsedStampList(data)
  }, [getUsedStampList]);

  const stampListData = props.usedStamp.data
  const totalPageProps = props.usedStamp.totalPage
  useEffect(() => {
    if (stampListData && stampListData.length > 0) {
      // genTable(stampListData)
      setTotalPage(totalPageProps)
      let used = stampListData.map((item, index) => {
        let dataDetail = []
        if (item.data && item.data.length > 0) {
          dataDetail = item.data.map(prop => {
            return {
              stampValue: `${prop.startId} - ${prop.endId}`,
              total: parseInt(prop.endId) - parseInt(prop.startId) + 1,
              activeTime: moment(prop.date).format("DD/MM/YYYY")
            }
          })
        }

        return {
          ...item,
          id: index + 1,
          dataDetail
        }
      })

      setStampList(used)
      setLoading(false)
    } else {
      // genTable([])
      setStampList([])
      setLoading(false)
      setTotalPage(0)
    }

  }, [totalPageProps, stampListData]);


  const columns = React.useMemo(
    () => [
      {
        accessor: "id",
        filterable: false,
        className: "center",
        maxWidth: 20,
        Header: () => (
          <div style={{
            textAlign: "center"
          }}>
            #
          </div>
        )
      },
      {
        Header: t('product.link'),
        accessor: "productName",
        sortable: false
      },
      {
        Header: t('stamp.number'),
        accessor: "amount",
        filterable: false
      },
    ],
    [t]
  );



  const columnSubTable = [
    {
      dataField: 'stampValue',
      text: t('stamp.StampRange')
    },
    {
      dataField: 'total',
      text: t('stamp.number'),
      width: 10,
    },
    {
      dataField: 'activeTime',
      text: t('stamp.ActivatedDate'),
    }
  ]


  const usedSum = cookie.load('used', { path: '/' });

  return (

    <>
      <Header />
      <Container fluid className='mt--7'>
        <Card className="card-box mb-5">
          <div className="card-header py-3">
            <div className=" font-weight-bold card-header--title font-size-lg">{`${t('stamp.usedStamp')} (  ${usedSum} ${t('stamp.stamp')} )`}</div>
          </div>

          <div className="divider" />
          <div className="card-body pt-2 px-4 pb-4">

            <ReactTable
              noDataText={t('noData')}
              data={dataStampList}
              columns={columns}
              previousText={"<"}
              nextText={">"}
              rowsText={t('row')}
              ofText="/"
              manual
              defaultPageSize={5}
              showPaginationBottom={true}
              sortable={false}
              resizable={true}
              PaginationComponent={Pagination}
              pages={totalPage}
              className="-striped -highlight"
              loading={loadingState}
              LoadingComponent={LoadingTable}
              SubComponent={row => {
                return (
                  <SubTable columns={columnSubTable} data={row.original.dataDetail} />
                )
              }}
              onFetchData={async (state, instance) => {
                setLoading(true)
                let x = state.page + 1;
                const type = cookie.load('type', { path: '/' });
                const minId = cookie.load('minId', { path: '/' });
                const maxId = cookie.load('maxId', { path: '/' });
                const startdate = cookie.load('startdate', { path: '/' });
                const enddate = cookie.load('enddate', { path: '/' });

                const startTime = startdate;
                const endTime = enddate;
                cookie.remove('startdate', { path: '/' });
                cookie.remove('enddate', { path: '/' });
                const page = x;
                const size = state.pageSize;
                const data = {
                  type: type,
                  startTime: startTime,
                  endTime: endTime,
                  maxId: maxId,
                  minId: minId,
                  page: page,
                  size: size
                }

                getUsedStampList(data)
              }}
            />
          </div>
        </Card>
      </Container>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
    userProfile: state.userProfile.data,
    usedStamp: state.usedStamp,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getUsedStampList: (data) => dispatch({ type: "GET_USED_STAMP_LIST", data: data }),
});

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(StampList));