import {
  GET_PRODUCT_TO_BRANCHS,
  GET_PRODUCT_TO_BRANCHS_SUCCESS,
  GET_PRODUCT_TO_BRANCHS_FAILURE,
} from "actions/types"
import { takeLatest, call, put, select } from 'redux-saga/effects'
import { LINK_API } from 'constants/API.js'
import axios from 'axios'

const getToken = (state) => state.userInfo


// Get number product to branchs
function* getNumberProductToBranch(id) {
  try {
    const tokenInfo = yield select(getToken)
    const response = yield call(fetchNumberProductToBranch, tokenInfo.data.token, id.id)
    const data = response.data
    yield put({ type: GET_PRODUCT_TO_BRANCHS_SUCCESS, data: data.data, errorCode: data.errorCode })
  } catch (error) {
    yield put({ type: GET_PRODUCT_TO_BRANCHS_FAILURE, payload: '', errorCode: 0 })
  }
}

function fetchNumberProductToBranch(checktoken, id) {
  axios.defaults.headers.common.Authorization = checktoken
  return axios({
    method: 'GET',
    url: LINK_API.GET_PRODUCT_TO_BRANCH + "?uuid=" + id
  })
}


const handleDetailPartner = [
  takeLatest(GET_PRODUCT_TO_BRANCHS, getNumberProductToBranch)
]

export default handleDetailPartner;
