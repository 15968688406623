import LoadingTable from "components/LoadingTable/index.js";
import Pagination from "components/Pagination/index.jsx";
import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ReactTable from 'react-table-6';
import { Card, Container } from 'reactstrap';
import "../../css/reactTable.css";
import SubTable from "components/SubTable/table.js";
import Header from "components/Headers/Header"
function ImportList(props) {
  const BoxUnitList = props.BoxUnitList;
  const [dataImportList, setImportList] = React.useState([])
  const [loadingState, setLoading] = React.useState(true)

  const { t } = props;

  const genTable = React.useCallback((importList) => {
    const data = importList.map((prop, key) => {
      const productList = [];
      for (let i = 0; i < prop.product.length; i++) {
        const id = i + 1;
        const traceabilityCode = prop.product[i].uuid;
        const identificationCode = prop.product[i].id;
        const status = prop.product[i].status !== 1 && prop.product[i].status !== 5 && prop.product[i].status !== 6 ? t('import.notSoldYet') : t('import.Sold')
        productList.push({
          id,
          traceabilityCode,
          identificationCode,
          status
        })
      }
      return {
        id: key + 1,
        boxID: prop.id,
        status: prop.status === 0 ? t('import.closedBox') : prop.status === 1 ? t('import.Sold') : prop.status === 2 ? t('import.openedBox') : prop.status === 3 ? t('import.deliveried') : prop.status === 4 ? t('import.received') : "",
        startID: prop.product[0].id,
        endID: prop.product[prop.product.length - 1].id,
        amount: prop.product.length,
        product: productList,
      };
    })

    setImportList(data)
    setLoading(false)
  }, [t])

  useEffect(() => {
    BoxUnitList()
  }, [BoxUnitList]);

  const importList = props.importList.data;
  useEffect(() => {
    if (importList && importList.length > 0) {
      genTable(importList)
    } else {
      genTable([])
    }

  }, [importList]);


  const columns = React.useMemo(
    () => [
      {
        accessor: "id",
        filterable: false,
        className: "center",
        Header: () => (
          <div style={{
            textAlign: "center"
          }}>
            #
          </div>
        )
      },
      {
        Header: t('import.ID'),
        accessor: "boxID",
        sortable: false
      },
      {
        Header: t('import.amount'),
        accessor: "amount",
        filterable: false
      },
      {
        Header: t('import.startID'),
        accessor: "startID",
        sortable: false
      },
      {
        Header: t('import.endID'),
        accessor: "endID",
        filterable: false
      },
      {
        Header: t('import.status'),
        accessor: "status",
        sortable: false
      },
    ],
    [t]
  );



  const columnSubTable = [
    {
      dataField: 'id',
      text: "#",
    },
    {
      dataField: 'traceabilityCode',
      text: t('import.traceabilityCode'),
    },
    {
      dataField: 'identificationCode',
      text: t('import.identificationCode'),
    },
    {
      dataField: 'status',
      text: t('import.status'),
    }
  ]

  return (

    <>
    <Header/>
    <Container className='mt--7' fluid>
      <Card className="card-box mb-5">
        <div className="card-header py-3">
          <div className=" font-weight-bold card-header--title font-size-lg">{`${t('import.title')}`}</div>
        </div>

        <div className="divider" />
        <div className="card-body pt-2 px-4 pb-4">

          <ReactTable
            noDataText={t('noData')}
            data={dataImportList}
            columns={columns}
            previousText={"<"}
            nextText={">"}
            rowsText={t('row')}
            ofText="/"
            defaultPageSize={5}
            showPaginationBottom={true}
            sortable={false}
            resizable={true}
            PaginationComponent={Pagination}
            // pages={totalPage}
            className="-striped -highlight"
            loading={loadingState}
            LoadingComponent={LoadingTable}
            SubComponent={row => {
              return (
                <SubTable columns={columnSubTable} data={row.original.product} />
              )
            }}
          // onFetchData={async (state, instance) => {
          //   setLoading(true)
          //   let x = state.page + 1;
          //   getStampList(x, state.pageSize)
          // }}
          />
        </div>
      </Card>
      </Container>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
    importList: state.importList
  }
}

const mapDispatchToProps = (dispatch) => ({
  BoxUnitList: () => dispatch({ type: "GET_BOX_UNIT" }),
});

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(ImportList));