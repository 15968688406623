/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import routes from "routes.js";
import {connect} from "react-redux"
class Admin extends React.Component {
    constructor(props){
        super(props);
        this.state  = {
            routes: []
        }
        this.filRoute = this.filRoute.bind(this);
    }
   
  componentDidUpdate(e) {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.mainContent.scrollTop = 0;
  }
  componentDidMount(){
      this.filRoute(routes)
  }
  filRoute = (routes) => {
      let temp = [];
      for(let i = 0 ; i< routes.length; i++){
          if(routes[i].layout === '/farmer'){
            temp.push(routes[i])
          }
      }
      this.setState({
          routes: temp
      })
  }
  getRoutes = routes => {
     
    return routes.map((prop, key) => {
      if (prop.layout === "/farmer" || prop.layout === '/auth') {
        console.log(prop)
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };
  render() {
    return (
      <>
        <Sidebar
          {...this.props}
          routes={this.state.routes}
          logo={{
            innerLink: "/farmer/list",
            imgSrc: require("assets/img/brand/argon-react.png"),
            imgAlt: "..."
          }}
        />
        <div className="main-content" ref="mainContent">
          <AdminNavbar
            {...this.props}
            brandText={this.getBrandText(this.props.location.pathname)}
          />
          <Switch>
            {this.getRoutes(routes)}
            <Redirect from="*" to="/farmer/list" />
          </Switch>
          <Container fluid>
            <AdminFooter />
          </Container>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
    return {
      userInfo: state.userInfo,
    }
  } 
export default connect(mapStateToProps, null)(Admin);
