import {
  UPDATE_INFO_EMPLOYEE,
  PUSH_NOTI_PRODUCT_SUCCESS,
  GET_EMPLOYEE_LIST,
  DEACTIVE_NOTI_PRODUCT
} from "actions/types"
import { takeLatest, call, put, select, delay } from 'redux-saga/effects'
import { LINK_API } from 'constants/API.js'
import axios from 'axios'

const getToken = (state) => state.userInfo
// Update info Employee
function* watchUpdateEmployee(data) {
  yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Đang xử lý", color: 'warning' })
  yield delay(1000)
  yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
  try {
    const tokenInfo = yield select(getToken)
    const { response } = yield call(updateInfoEmployee, data.data, tokenInfo.data.token)
    const result = response.data
    if (result.errorCode === 1) {
      yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Cập nhật thông tin nhân viên thành công", color: 'success' })
      yield put({ type: GET_EMPLOYEE_LIST, page: 1, size: 5 })
      yield delay(2000)
      yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
    } else {
      yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Cập nhật thông tin nhân viên thất bại", color: 'error' })
      yield put({ type: GET_EMPLOYEE_LIST, page: 1, size: 5 })
      yield delay(2000)
      yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
    }
  } catch (error) {
    yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Cập nhật thông tin nhân viên thất bại", color: 'error' })
    yield put({ type: GET_EMPLOYEE_LIST, page: 1, size: 5 })
    yield delay(2000)
    yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
  }
}
function updateInfoEmployee(data, checktoken) {
  axios.defaults.headers.common.Authorization = checktoken
  return axios.post(LINK_API.UPDATE_INFO_EMPLOYEE, data)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
const handleCreateEmployee = [
  takeLatest(UPDATE_INFO_EMPLOYEE, watchUpdateEmployee)
]
export default handleCreateEmployee

