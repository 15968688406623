import React from "react"
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import Slider from 'react-slick';
import NextIcon from 'mdi-react/ChevronRightIcon'
import PreIcon from 'mdi-react/ChevronLeftIcon'
import "./css/cert.css"
function SliderArrowNext(props) {
    const { className, onClick } = props;
    return (
        <div className={className} onClick={onClick}><NextIcon icon={['fas', 'chevron-right']} /></div>
    );
}

function SliderArrowPrev(props) {
    const { className, onClick } = props;
    return (
        <div className={className} onClick={onClick}><PreIcon icon={['fas', 'chevron-left']} /></div>
    );
}

function Cert(props) {
    const t = props.t;
    const userProfile = props.userProfile;
    const [certList, setList] = React.useState([])
    const widgetsCarousels2A = {
        dots: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        nextArrow: <SliderArrowNext />,
        prevArrow: <SliderArrowPrev />,
        responsive: [
            {
                breakpoint: 1450,
                settings: { slidesToShow: 1, slidesToScroll: 1 }
            },
            {
                breakpoint: 1300,
                settings: { slidesToShow: 1, slidesToScroll: 1 }
            }
        ]
    };
    React.useEffect(() => {
        if (userProfile.certificates) {
            if (userProfile.certificates.length > 0) {
                setList([...userProfile.certificates])
            }
        }
    }, [userProfile])
    return (
        <>
            <div className="p-4">
                <h5 className="font-size-xl font-weight-bold">{t('profile.cert')}</h5>
                <p className="text-black-50 mb-4">{t('profile.aboutCert')}</p>
                <div className="divider my-4" />
                <Slider className="overflow-hidden text-center" {...widgetsCarousels2A}>
                    {certList.map((cert, idx) => (
                        <div key={idx}>
                            <div href="#/" onClick={e => e.preventDefault()}
                                className="mx-3 mx-xl-5 my-5 card shadow-xxl card-border-top br-tl br-tr card-box-hover-rise border-primary text-center p-3 p-xl-4 mb-5 d-block wrapImage" >
                                <img src={cert} style={{ maxHeight: "500px" }} />
                            </div>
                        </div>
                    ))}


                </Slider>
            </div>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        userProfile: state.userProfile.data,
        userInfo: state.userInfo
    }
}
export default withTranslation('common')(connect(mapStateToProps, null)(Cert));
