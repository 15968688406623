import React from 'react'
import {IMG_LOADING} from 'constants/image'
import { withTranslation } from 'react-i18next';
import "./index.css"

function Loading(props){
    return (
      props.loading
        ? <div className='-loading -active'>
            <div className='-loading-inner'>
              <img alt="loading" src={IMG_LOADING} className="loadingImage"/>
    <p className="textTableLoading">{props.t('processing')}</p>
            </div>
          </div>
        : null
    )
}
export default withTranslation('common')(Loading)