import {
    GET_ALL_BOX_SYSTEM,
    GET_ALL_BOX_SYSTEM_SUCCESS,
    GET_ALL_BOX_SYSTEM_FAILURE
} from "actions/types"
import axios from 'axios'
import { LINK_API } from 'constants/API.js'
import { call, put, select, takeLatest } from 'redux-saga/effects'
const getToken = (state) => state.userInfo
// Get approve list admin
function* getAdminList() {
    console.log("Check saga nè")
    try {
        const tokenInfo = yield select(getToken)
        const response = yield call(fetchAdminList, tokenInfo.data.token)
        const data = response.data;
        let temp = [{
            label: "Cập nhật sau",
            value: null
        }];
        
        for (let i = 0; i < data.data.length; i++) {
            const name = data.data[i].ProductionObjectInfo ? data.data[i].ProductionObjectInfo.name : ""
            temp.push({
                label: name ? data.data[i].ownedBy +  data.data[i].uuid + " - " + name : data.data[i].ownedBy +  data.data[i].uuid,
                value: data.data[i].ownedBy +  data.data[i].uuid,
            })
        }
        yield put({ type: GET_ALL_BOX_SYSTEM_SUCCESS, data: data.data, errorCode: data.errorCode, options: temp })
    } catch (error) {
        yield put({ type: GET_ALL_BOX_SYSTEM_FAILURE, payload: '', errorCode: 0 })
    }
}
function fetchAdminList(checktoken) {
    axios.defaults.headers.common.Authorization = checktoken
    console.log('Check guard', LINK_API.GET_ALL_BOX_SYSTEM)
    return axios({
        method: 'GET',
        url: LINK_API.GET_ALL_BOX_SYSTEM
    })
}
const actions = [
    takeLatest(GET_ALL_BOX_SYSTEM, getAdminList)
]
export default actions
