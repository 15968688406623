import {
    VERIFY_ACCOUNT
} from "actions/types"
import axios from 'axios'
import { LINK_API } from 'constants/API.js'
import { call, takeLatest } from 'redux-saga/effects'
const CryptoJS = require('crypto-js')
const EthereumTx = require('ethereumjs-tx');

// Get user profile
function* verifyAccount(data) {
    const userInfo = data.userInfo;
    const password = data.password;
    const data_Send = {
        "email": userInfo.email,
        "bcAddress": userInfo.bcAddress,
        "uuid": userInfo.uuid
    }
    const response = yield call(sendVerify, data_Send, userInfo.token);
    var bytes = CryptoJS.AES.decrypt(userInfo.privateEncrypted.toString(), password);
    const privatekey = bytes.toString(CryptoJS.enc.Utf8);

    const privatekey_slice = privatekey.substring(2, privatekey.length)
    const Buffer_privatekey = Buffer.from(privatekey_slice.toString(), 'hex');
    const tx = new EthereumTx(response.data.data.raw, { chain: 4 });
    const tx_rawCheckClaim = response.data.data.rawCheckClaim ? new EthereumTx(response.data.data.rawCheckClaim, { chain: 4 }) : "";
    if (tx_rawCheckClaim) {
        tx_rawCheckClaim.sign(Buffer_privatekey)
    }
    tx.sign(Buffer_privatekey);
    const rawTx = '0x' + tx.serialize().toString('hex');
    const rawTx_rawCheckClaim = '0x' + tx_rawCheckClaim.serialize().toString('hex');
    const raw_to_send = {
        "raw": [],
        "sign": "none",
        "date": "none",
        "bcAddress": userInfo.bcAddress,
        "uuid": userInfo.uuid,
        "nonce": "none",
        "rawCheckClaim": response.data.data.rawCheckClaim ? rawTx_rawCheckClaim : ""
    }
    raw_to_send.raw.push(rawTx);
    yield call(sendVerifyRaw, raw_to_send, userInfo.token);
}
function sendVerify(data, token) {
    axios.defaults.headers.common.Authorization = token
    return axios({
        method: 'POST',
        url: LINK_API.VERIFY_ACCOUNT,
        data: data
    })
}
function sendVerifyRaw(data, token) {
    axios.defaults.headers.common.Authorization = token
    return axios({
        method: 'POST',
        url: LINK_API.SEND_RAW_VERIFY,
        data: data
    })
}
const actions = [
    takeLatest(VERIFY_ACCOUNT, verifyAccount)
]
export default actions

