import DropZone from "components/dropZone/index.js";
import { useFormik } from 'formik';
import CloseBoxIcon from 'mdi-react/CloseBoxIcon';
import PlusCircle from "mdi-react/PlusCircleIcon";
import Switch from 'rc-switch';
import React from "react";
import { withTranslation } from "react-i18next";
import { Button, Input, UncontrolledTooltip } from "reactstrap";
import CustomInput from 'reactstrap/lib/CustomInput';
import * as Yup from "yup";
import "./css/index.css";
const StepBasic = (props) => {
    const t = props.t;
    const {
        onNext,
        onBack
    } = props;
    const [checkedPortal, setPortal] = React.useState(false);
    const [checkedMobile, setMobile] = React.useState(true);
    const [listImage, setImage] = React.useState([])
    const [mobileStep, setStep] = React.useState([]);
    const dataStep = props.data;
    const indexStep = props.index + 1;
    
    const handleImage = async (data) => {
        let temp = listImage;
        for(let i=0; i< data.length; i++){
          temp[i] = data[i]
        }
        console.log(temp)
        await setImage([...temp])
    }
    const handleAddField = async () => {
        let temp = mobileStep;
        await temp.push({
            label: "",
            placeholder: "",
            required: false,
            type: "InputBox",
            typeOfValue: "",
            defaultValue: ""
        })
        setStep([...temp]);
    }

    const handleChange = (name, idx) => async event => {
        let temp = await mobileStep.map((item, index) => {
            if (index === idx) {
                return {
                    ...item,
                    [name]: event.target.value
                }
            } else {
                return item;
            }
        })
        setStep(temp);
    }
    const toggleField = (idx) => {
        let temp = mobileStep.map((item, index) => {
            if (index === idx) {
                return {
                    ...item,
                    required: !item.required
                }
            } else {
                return item;
            }
        })
        setStep(temp);
    }
    const togglePortal = () => {
        setPortal(!checkedPortal)
    };
    
    const formik = useFormik({
        initialValues: {
            name: '',
            description: '',
            icon: 'defaultIcon',
            iconIndex: 1
        },
        validationSchema: Yup.object({
            name: Yup.string()
                .required(t('require')),
        }),
        onSubmit: React.useCallback(async (values) => {
            console.log(listImage)
            let data = {
                "index": props.index + 1,
                "name": values.name,
                "description": values.description,
                "icon": values.icon + ".svg",
                'mobileIcon': values.icon,
                "iconIndex": values.iconIndex,
                "isHarvest": false,
                "authority": ["M", "SP"],
                "isPublic": checkedPortal,
                "mobileIgnore": !checkedMobile,
                "actions_image": listImage,
                "listElement": mobileStep, 
            }
            console.log('data submit', data)
            formik.setFieldValue("name", "", false)
            formik.setFieldValue("description", "", false)
            formik.setFieldValue("icon", "", false)
            formik.setFieldValue("iconIndex", 1, false)
            setStep([]);
            setPortal(true);
            setMobile(true);
            onNext(data);
        }, [onNext, mobileStep, checkedMobile, checkedPortal, indexStep]),
    });
    const handleBack = async () => {
        let data = {
            "index": props.index + 1,
            "name": formik.values.name,
            "description": formik.values.description,
            "icon": formik.values.icon + ".svg",
            'mobileIcon': formik.values.icon,
            "iconIndex": formik.values.iconIndex,
            "isHarvest": false,
            "authority": ["M", "SP"],
            "isPublic": checkedPortal,
            "mobileIgnore": !checkedMobile,
            "actions_image": listImage,
            "listElement": mobileStep
        }

        formik.setFieldValue("name", "", false)
        formik.setFieldValue("description", "", false)
        formik.setFieldValue("icon", "", false)
        formik.setFieldValue("iconIndex", 1, false)
        setStep([]);
        setPortal(true);
        setMobile(true);

        if (props.merge) {
            props.merge(data)
        }
        onBack();
    }
    const handleDeleteField = async (idx) => {
        let temp = await mobileStep.filter((item, index) => index !== idx);
        setStep([...temp])
    }
    React.useEffect(() => {
        if (dataStep) {
            let temp = dataStep[indexStep];
            if (temp) {

                formik.setValues({ name: temp.name, description: temp.description, iconIndex: temp.iconIndex })
                setPortal(temp.isPublic)
                setMobile(!temp.mobileIgnore)
                setStep([...temp.listElement])
            }

        }
    }, [dataStep, indexStep])
    return (
        <>
            <div className="wizard-steps vertical">
                <form onSubmit={formik.handleSubmit} className="w-100">
                    <div className="p-4 w-100">
                        <h5 className="font-size-xl font-weight-bold">{t("process.basicInfo")}</h5>
                        <p className="text-black-50 mb-4">{t('process.basicDes')}</p>
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label className="font-weight-bold">{t('process.nameStep')} <span className="text-danger">(*)</span></label>
                                <div className="d-flex flex-row">
                                    {/* <div >
                                        <IconPicker
                                            submit={hanldeSetIcon}
                                            index={formik.values.iconIndex}
                                        />
                                    </div> */}
                                    <div className="w-100 d-flex flex-column">
                                        <Input
                                            className="form-control"
                                            id="process_name"
                                            value={formik.values.name}
                                            placeholder={t('process.inputField')}
                                            valid={formik.touched.name && formik.errors.name ? false : (formik.values.name ? true : false)}
                                            invalid={formik.touched.name && formik.errors.name ? true : false}
                                            type="text"
                                            {...formik.getFieldProps('name')}
                                        />
                                        {formik.touched.name && formik.errors.name ? (
                                            <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.name}</p>
                                        ) : null}
                                    </div>
                                </div>

                            </div>
                            <div className="form-group col-md-12">
                                <label className="font-weight-bold">{t('process.desStep')}</label>
                                <Input
                                    className="form-control"
                                    placeholder={t('process.desStep')}
                                    values={formik.values.description}
                                    valid={formik.touched.description && formik.errors.description ? false : (formik.values.description ? true : false)}
                                    invalid={formik.touched.description && formik.errors.description ? true : false}
                                    type="text"
                                    {...formik.getFieldProps('description')}
                                />
                            </div>
                        </div>
                        <div className="form-group col-md-12">
                                <label className="font-weight-bold">Hình ảnh mô tả</label>
                                <DropZone
                                max={3}
                                onConfirm={handleImage}

                                />
                            
                        </div>
                        <div className="m-2 form-row">
                            <Switch checked={checkedPortal} onClick={togglePortal} className="switch-small toggle-switch-success"></Switch>
                            <p className="font-weight-bold ml-2">{t(`process.showPortal`)}</p>
                        </div>
                        {/* <div className="m-2 form-row">
                            <Switch checked={checkedMobile} onClick={toggleMobile} className="switch-small toggle-switch-success"></Switch>
                            <p className="font-weight-bold ml-2">{t(`process.showMobile`)}</p>
                        </div> */}

                        <div className="form-row ">
                        <div className="form-group col-md-12">
                                <label className="font-weight-bold mb-0 mt-2">{t('process.moreInfo')}</label>
                                <Button onClick={handleAddField} id="addField" color="info" className="ml-2 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center">
                                    <span className="btn-wrapper--icon d-flex">
                                        <PlusCircle
                                            size={18}
                                            className="text-white font-size-sm"
                                        />
                                    </span>
                                </Button>
                                {/* <UncontrolledPopover trigger="legacy" placement="top" target="addField">
                                    <PopoverHeader>Loại trường</PopoverHeader>
                                    <PopoverBody>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</PopoverBody>
                                </UncontrolledPopover> */}
                                {/* <UncontrolledTooltip trigger="hover" popperClassName="tooltip-black" placement="top" container="body" target="addField">
                                    {t('process.addField')}
                                </UncontrolledTooltip> */}
                            </div>
                        </div>

                        {mobileStep.map((item, idx) => (
                            <div key={idx} className="form-row">
                                <div className="form-group col-md-4 pl-0">
                                    <label className="font-weight-bold">{t('process.nameField') + " #" + idx}</label>
                                    <Input
                                        className="form-control"
                                        placeholder={t('process.nameField')}
                                        value={item.label}
                                        valid={item.label ? true : false}
                                        type="text"
                                        onChange={handleChange("label", idx)}
                                    />
                                </div>
                                <div className="form-group col-md-4">
                                    <label className="font-weight-bold">{t('process.typeField') + " #" + idx}</label>
                                    <CustomInput id="typeFields" type="select" placeholder={t('process.typeField')}
                                        value={item.typeOfValue}
                                        name="customSelect"
                                        valid={true}
                                        onChange={handleChange("typeOfValue", idx)}
                                    >
                                        <option value={""}>{t('process.text')}</option>
                                        <option value={"numeric"}>{t('process.number')}</option>
                                        <option value={"link"}>{t('process.linkField')}</option>
                                        {/* <option value={"RNSelect"}>{t('process.select')}</option> */}
                                    </CustomInput>
                                </div>
                                <div className="form-group col-md-4 pr-0">
                                    <label className="font-weight-bold">{t('process.defaultValue') + " #" + idx}</label>
                                    <div className="d-flex flex-row">
                                        <div>
                                            <Input
                                                className="form-control"
                                                placeholder={t('process.defaultValue') + " #" + idx}
                                                value={item.defaultValue}
                                                valid={item.defaultValue ? true : false}
                                                type="text"
                                                onChange={handleChange("defaultValue", idx)}
                                            />
                                        </div>
                                        <div>
                                            <Button onClick={()=>handleDeleteField(idx)} color="danger" className="btn-icon btn-animated-icon btn-transition-none d-40 p-0 ml-2" id={"deleteField" + idx}>
                                                <span className="btn-wrapper--icon d-flex">
                                                    <CloseBoxIcon className="font-size-xs text-white" />
                                                </span>
                                            </Button>
                                            <UncontrolledTooltip trigger="hover" popperClassName="tooltip-black" placement="top" container="body" target={"deleteField" + idx}>
                                                {t('process.deleteField')}
                                            </UncontrolledTooltip>
                                        </div>
                                    </div>

                                </div>
                                <div className="form-group col-md-12 px-0">
                                    <div className="m-1 form-row">
                                        <Switch checked={item.required} onClick={() => toggleField(idx)} className="switch-small toggle-switch-success"></Switch>
                                        <p className="font-weight-bold ml-2">{t(`process.fieldRequire`)}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                        {mobileStep.length === 0 ?
                            <div className="form-group col-md-12 px-0 p-3 w-100 text-center bg-danger rounded border-0 mt-3">
                                <p className="text-white mb-0">Chưa có trường thông tin ghi nhận khi sản xuất</p>
                            </div> : null}

                        <div className="form-row ">
                            <div className="form-group col-md-12 mb-0">
                                <p><span className="text-danger">(*)</span> {t('require')}</p>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-6 d-flex justify-content-start mb-0">
                                <Button
                                    size="lg"
                                    outline
                                    onClick={handleBack}
                                    className="text-uppercase font-weight-bold font-size-sm"
                                    color="info">
                                    {t('pre')}
                                </Button>
                            </div>
                            <div className="form-group col-md-6 d-flex justify-content-end mb-0">
                                <Button
                                    size="lg"
                                    type="submit"
                                    className="text-uppercase font-weight-bold font-size-sm"
                                    color="info">
                                    {t('next')}
                                </Button>
                            </div>

                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}
export default withTranslation('common')(StepBasic)