import React from "react";
import { withTranslation } from "react-i18next";
import "view/css/select.css";
import "../css/index.css";
import Switch from "rc-switch";
import { Input, Button, Card } from "reactstrap";
import Select from "react-select";
import DropZone from "components/dropZone/index";

const middlePositionList = [
  {
    value: "above-content",
    label: "Trên nội dung",
  },
  {
    value: "below-news",
    label: "Dưới phần Tin tức",
  },
  {
    value: "below-product",
    label: "Dưới phần Sản phẩm",
  },
  {
    value: "below-content",
    label: "Dưới nội dung",
  },
];

const PositionContainer = React.memo((props) => {
  const t = props.t;
  const { tab, onLoadData, isEdit, onUpdateImages } = props;
  const [data, setData] = React.useState(props.data);
  const updatedImages = props.images;

  React.useEffect(() => {
    setData(props.data);
  }, [props.data, tab]);

  const handleAddImage = () => {
    const temp = data.images;
    temp.push({
      image: {
        uri: "",
      },
      link: "",
    });
    handleLoadData("images", temp);
  };

  const handleRemoveImage = () => {
    const temp = data.images;
    if (temp[data.images.length - 1].image.secure_url) {
      updatedImages.deleteImages.push({
        image: temp[data.images.length - 1].image,
      });
    }
    temp.pop();
    handleLoadData("images", temp);
  };

  const toggleShow = () => {
    const temp = data.show;
    handleLoadData("show", !temp);
  };

  const handleChangeImage = (idx) => (image) => {
    const temp = data.images;
    if (!image.length && temp[idx].image.secure_url) {
      updatedImages.deleteImages.push({ image: temp[idx].image });
    }
    temp[idx].image = image;
    handleLoadData("images", temp);
  };

  const handleChangeLink = (idx) => (event) => {
    const temp = data.images;
    temp[idx].link = event.target.value;
    handleLoadData("images", temp);
  };

  const handleLoadData = (name, value) => {
    const info = {
      ...data,
      [name]: value,
    };
    setData(info);
    onLoadData(info);
    onUpdateImages(updatedImages);
  };

  let seletedValue = middlePositionList[0]
  if (data && data.amongPagePosition) {
    seletedValue = middlePositionList.filter(
      (item) => item.value === data.amongPagePosition
    );
  }

  if (data && data.images && !data.images.length) {
    data.images.push({
      image: '',
      link: ''
    })
  }

  return (
    <>
      <div className="m-2 form-row">
        <div className="col-lg-3 col-md-4">
          <p className="font-weight-bold mr-2">{t(`portal.show`)}</p>
        </div>
        <div className="col-lg-9 col-md-8">
          <Switch
            id={`switch-${tab}`}
            checked={data.show}
            onClick={toggleShow}
            className="switch-small toggle-switch-success"
            disabled={!isEdit}
          ></Switch>
        </div>
      </div>

      {tab === 1 ? (
        <div className="m-2 form-row">
          <div className="col-lg-3 col-md-4">
            <p className="font-weight-bold mr-2">
              {t(`portal.amongPagePosition`)}
            </p>
          </div>
          <div className="col-md-9 col-md-8">
            <Select
              isDisabled={!isEdit}
              placeholder="Chọn vị trí"
              options={middlePositionList}
              value={seletedValue}
              onChange={(selectedOption) => {
                handleLoadData("amongPagePosition", selectedOption.value);
              }}
              theme={(theme) => ({
                ...theme,
                borderRadius: "0.29rem",
                borderWidth: 1,
                colors: {
                  ...theme.colors,
                  primary25: "rgba(60,68,177,0.15)",
                  primary50: "rgba(60,68,177,0.15)",
                  primary: "#4FB779",
                },
              })}
            />
          </div>
        </div>
      ) : null}

      <div className="m-2 form-row">
        <div className="col-lg-3 col-md-12">
          <p className="font-weight-bold mr-2">{t(`portal.adsImage`)}</p>
        </div>
        <div className="col-12">
          <div className="row">
            {data.images.map((item, idx) => (
              <div
                id={`card-${tab}-${idx}`}
                key={idx}
                className="col-lg-4 col-md-6 mt-2"
              >
                <Card className="card-box px-3 py-2">
                  <div className="form-row">
                    <div className="form-group col-md-12 text-center">
                      {isEdit ? (
                        <DropZone
                          id={`image-${tab}-${idx}`}
                          onConfirm={handleChangeImage(idx)}
                          data={
                            item.image && item.image.secure_url
                              ? [item.image.secure_url]
                              : (item.image || [])
                          }
                          max={1}
                        />
                      ) : (
                        <img
                          style={{ maxWidth: "100%", width: "auto", height: "130px" }}
                          src={ item.image && item.image.secure_url ? item.image.secure_url : 'https://res.cloudinary.com/agridential/image/upload/v1603358380/images/no_image_ybayma.png'}
                          alt="ads-img"
                        />
                      )}
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-12">
                      <Input
                        disabled={!isEdit}
                        id={`input-${tab}-${idx}`}
                        placeholder={t("portal.link")}
                        type="text"
                        name={`input-${tab}-${idx}`}
                        value={item.link || ""}
                        // valid={nameRep ? true : false}
                        onChange={handleChangeLink(idx)}
                      />
                    </div>
                  </div>
                </Card>
              </div>
            ))}
          </div>
          {isEdit && tab === 1 ? (
            <div className="form-group ml-3 mt-2">
              <Button
                color="info"
                onClick={() => handleAddImage()}
              >
                {t("portal.addImage")}
              </Button>
              <Button
                disabled={data.images.length < 2}
                color="danger"
                onClick={() => handleRemoveImage()}
              >
                {t("portal.removeImage")}
              </Button>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
});

export default withTranslation("common")(PositionContainer);
