import DropZone from "components/dropZone";
import { LINK_API } from "constants/API";
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
//formik
import { useFormik } from 'formik';
import htmlToDraft from 'html-to-draftjs';
import React, { useEffect } from "react";
import cookie from 'react-cookies';
import { Editor } from 'react-draft-wysiwyg';
import ReactGoogleMapLoader from "react-google-maps-loader";
import ReactGooglePlacesSuggest from "react-google-places-suggest";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { CustomInput, Input } from "reactstrap";
//style
import "view/css/select.css";
import * as Yup from 'yup';
import "../../css/index.css";

const MY_API_KEY = "AIzaSyCB7o1cgt10O8xP9lLbk7Iikfv5ypvr528";


const Step1 = React.forwardRef((props, ref) => {
  const t = props.t;
  const uuid = cookie.load("uuidArea");
  const [listImage, setImage] = React.useState([]);
  
  const [editorState, setEditor] = React.useState(EditorState.createEmpty());

  const handleChangeEditor = async (editorState) => {
    document.getElementById("desNoti").innerHTML = "";
    setEditor(editorState)
  }


  const renderEmp = props.renderEmp;
  useEffect(() => {
    renderEmp(1, 100)
  }, [renderEmp]);

  const handleAva = (data) => {
    setImage(data);
  }


  const formik = useFormik({
    initialValues: {
      nameArea: '',
      address: '',
      gln: '',
      type: ''
    },
    validationSchema: Yup.object({
      nameArea: Yup.string()
        .required(t('require')),
      address: Yup.string()
        .required(t('require')),
      type: Yup.string()
        .required(t('require')),
    }),
    onSubmit: async (values) => {
      let dynamicContent = await draftToHtml(convertToRaw(editorState.getCurrentContent()))
      dynamicContent = await dynamicContent.toString()
      dynamicContent = await dynamicContent.replace("↵", '')
      // if (dynamicContent === "<p></p>\n") {
      //     document.getElementById("desNoti").innerHTML = "Vui lòng điền thông tin miêu tả sản phẩm";
      // }
      let data_submit = {
        ...values,
        'listImage': listImage,
        'description': dynamicContent
      }
      props.saveArea(data_submit, false)
     //props.merge(data_submit)
    },
  });

  const handleSelectSuggest = (geocodedPrediction) => {
    formik.setFieldValue("address", geocodedPrediction.formatted_address, true)
    formik.setFieldValue("search", "")
  }

  const handleNoResult = () => {
    console.log('Không có kết quả')
  }

  const handleChangeAddress = name => event => {
    let values = event.target.value;
    if (values) {
      formik.setFieldValue("search", values);
      formik.setFieldValue("address", values, true);
    } else {
      formik.setFieldValue("search", values);
      formik.setFieldValue("address", values, true);
      formik.setFieldError("address", t('require'))
    }
  }
  React.useImperativeHandle(ref, () => ({
    handleMergeData(){
      formik.submitForm()
    }
  }));
  const dataStepOne = props.data; 
  React.useEffect(()=>{
      formik.setFieldValue("nameArea", dataStepOne.name)
      formik.setFieldValue("address", dataStepOne.address ?  dataStepOne.address.city : '')
      formik.setFieldValue("gln", dataStepOne.gln)
      formik.setFieldValue("type", dataStepOne.type)
      let temp = [];
      if(dataStepOne.image){
        for(let i = 0; i< dataStepOne.image.length; i++){
            temp.push(dataStepOne.image[i].secure_url)
        }
      }
      setImage(temp)

      let dynamicContent = dataStepOne.description;
      if(dynamicContent){
        let contentBlock = htmlToDraft(dynamicContent);
        if(contentBlock && dataStepOne.description && dataStepOne.description !== ""){
            let contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            let editorState = EditorState.createWithContent(contentState);
            setEditor(editorState)
        }
      }
  },[dataStepOne])

  return (
    <>
       <div className="wizard-steps horizontal">
          <form onSubmit={formik.handleSubmit}>
            <div className="p-4">
              <h5 className="font-size-xl font-weight-bold">{t('area.areaInfo')}</h5>
              <p className="text-black-50 mb-4">{t('area.aboutArea')}</p>
              <div className="form-row ">
                <div className="form-group col-md-6 pr-3">
                  <label className="font-weight-bold">{t('area.nameArea')} <span className="text-danger">(*)</span></label>
                  <Input
                    placeholder={t('area.nameArea')}
                    type="text"
                    name="nameArea"
                    value={formik.values.nameArea}
                    valid={formik.touched.nameArea && formik.errors.nameArea ? false : (formik.values.nameArea ? true : false)}
                    invalid={formik.touched.nameArea && formik.errors.nameArea ? true : false}
                    {...formik.getFieldProps('nameArea')}
                  />
                  {formik.touched.nameArea && formik.errors.nameArea ? (
                    <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.nameArea}</p>
                  ) : null}
                </div>
                <div className="form-group col-md-6 pl-3">
                  <label className="font-weight-bold">{t('product.address')} <span className="text-danger">(*)</span></label>
                  <ReactGoogleMapLoader
                    params={{
                      key: MY_API_KEY,
                      libraries: "places,geocode",
                    }}
                    render={googleMaps =>
                      googleMaps && (
                        <div>
                          <ReactGooglePlacesSuggest
                            autocompletionRequest={{ input: formik.values.search }}
                            googleMaps={googleMaps}
                            onSelectSuggest={handleSelectSuggest}
                            displayPoweredByGoogle={false}
                            onNoResult={handleNoResult}

                          >
                            <Input
                              placeholder={t('product.address')}
                              onChange={handleChangeAddress('address')}
                              type="text"
                              name="address"
                              value={formik.values.address}
                              valid={formik.touched.address && formik.errors.address ? false : (formik.values.address ? true : false)}
                              invalid={formik.touched.address && formik.errors.address ? true : false}
                            // {...formik.getFieldProps('address')}
                            />
                          </ReactGooglePlacesSuggest>
                        </div>
                      )
                    }
                  />
                  {formik.touched.address && formik.errors.address ? (
                    <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.address}</p>
                  ) : null}
                </div>
              </div>
              <div className="form-row ">
                <div className="form-group col-md-6 pr-3">
                  <label className="font-weight-bold" >{t('area.typeArea')} <span className="text-danger">(*)</span></label>
                  <CustomInput type="select" id="selectUnit" placeholder={t('area.typeArea')}
                    value={formik.values.type}
                    onChange={formik.handleChange}
                    valid={formik.touched.type && formik.errors.type ? false : (formik.values.type ||formik.values.type===0  ? true : false)}
                    invalid={formik.touched.type && formik.errors.type ? true : false}
                    name="customSelect"
                    {...formik.getFieldProps('type')}
                  >
                    <option value="">{t('area.typeArea')}</option>
                    <option value={0}>{t('area.farming')}</option>
                    <option value={1}>{t('area.processing')}</option>
                    <option value={2}>{t('area.both')}</option>
                    <option value={3}>{t('area.diff')}</option>
                  </CustomInput>
                  {formik.touched.type && formik.errors.type ? (
                    <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.type}</p>
                  ) : null}

                </div>
                <div className="form-group col-md-6 pl-3">
                  <label className="font-weight-bold">{t('area.gln')}</label>
                  <Input
                    placeholder={t('area.gln')}
                    type="text"
                    value={formik.values.gln}
                    {...formik.getFieldProps('gln')}
                  />
                </div>
              </div>
              <div className="from-row">
                <div
                  className="form-group col-md-12 px-0"
                >
                  <label className="font-weight-bold">{t('image')} </label>
                  <DropZone
                    api={true}
                    max={3}
                    onConfirm={handleAva}
                    data={listImage}
                    uuid={uuid}
                    path={LINK_API.UPDATE_IMG_AREA}
                  // sizeOut={300}
                  // sizeIn={250}
                  // mLeft={200}
                  />
                </div>

              </div>
              <div className="form-row ">
                <div className="form-group col-md-12">
                  <label className="font-weight-bold">{t('area.description')}</label>
                  <Editor
                    editorState={editorState}
                    wrapperClassName="demo-wrapper"
                    editorClassName="demo-editor borderEditor"
                    onEditorStateChange={handleChangeEditor}
                    toolbar={{
                      options: ["inline", 'image', 'embedded', 'textAlign', "link", ]
                  }}
                  />
                  <p id="desNoti" className="font-weight-regular font-size-sm text-danger" ></p>
                </div>
              </div>
              <div className="form-row ">
                <div className="form-group col-md-12">
                  <p><span className="text-danger">(*)</span> Trường thông tin bắt buộc</p>
                </div>
               
              </div>

            </div>

          </form>
        </div>
    </>
  )
})

const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
    empList: state.renderEmp
  }
}

const mapDispatchToProps = (dispatch) => ({
  saveArea: (dataOne, check) => dispatch({ type: "SAVE_DATA_EDIT_AREA", dataOne: dataOne, check: check}),
  renderEmp: (page, size) => dispatch({ type: "RENDER_EMPLOYEE_LIST", page: page, size: size }),
});

export default withTranslation('common', { withRef: true })(connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(Step1));