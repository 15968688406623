import {
  GET_LIST_PRODUCT_RETAIL,
  GET_LIST_PRODUCT_RETAIL_SUCCESS,
  GET_LIST_PRODUCT_RETAIL_FAILURE,
} from "actions/types"
import { takeLatest, call, put, select } from 'redux-saga/effects'
import { LINK_API } from 'constants/API.js'
import axios from 'axios'

const getToken = (state) => state.userInfo



// Get list product retail
function * getRetailList () {
  try {
    const tokenInfo = yield select(getToken)
    const response = yield call(fetchRetailList, tokenInfo.data.token)
    const data = response.data
    yield put({ type: GET_LIST_PRODUCT_RETAIL_SUCCESS, data: data.data, errorCode: data.errorCode })
  } catch (error) {
    yield put({ type: GET_LIST_PRODUCT_RETAIL_FAILURE, payload: '', errorCode: 0 })
  }
}
function fetchRetailList (checktoken) {
  axios.defaults.headers.common.Authorization = checktoken
  return axios({
    method: 'GET',
    url: LINK_API.GET_LIST_PRODUCT_RETAILER
  })
}


const handleManagementList = [
  takeLatest(GET_LIST_PRODUCT_RETAIL, getRetailList)
]

export default handleManagementList;
