import { all } from 'redux-saga/effects'
import Actions from "./actionsSaga"
import ActiveStamp from './activeStampSaga'
import adminListSaga from "./adminListSaga"
import allDistributorSaga from "./allDistributorSaga"
import Area from "./areaSaga"
import BranchList from './branchSaga'
import CreateArea from './createAreaSaga'
import createBranchForPartnerSaga from "./createBranchForPartnerSaga"
import CreateBranch from './createBranchSaga'
import CreateEmployee from "./createEmployeeSaga"
import CreatePartner from './createPartnerSaga'
import CustomerList from './customerListSaga'
import detailAccountSaga from "./detailAccountSaga"
import DetailPartner from './detailPartnerSaga'
import Employee from "./employeeSaga"
import Feedback from "./feedbackSaga"
import generalSaga from "./generalSaga"
import RenderAreaList from "./handleAreaListSaga"
import ImportBoxList from './importBoxSaga'
import ImportList from './importListSaga'
import InvoiceList from './invoiceSaga'
import LogInSaga from "./loginSaga"
import ManagementList from './managementSaga'
import notiSaga from "./notiListSaga"
import OProcessing from "./oProcessingSaga"
import OProduction from "./oProductionSaga"
import Partner from "./partnerSaga"
import permissionSaga from "./permissionSaga"
import PlanList from './planListSaga'
import PriceList from './priceSaga'
import Process from "./processSaga"
import ProductType from "./productTypeSaga"
import ProfileSaga from './profileSaga'
import quantityHarvestSaga from "./quantityHarvestSaga"
import RenderEmployeeList from "./renderEmployeeList"
import scanLocationSaga from "./scanLocationSaga"
import scanStampSaga from "./scanStampSaga"
import SettingSaga from './settingSaga'
import StampHistory from "./stampHistorySaga"
import SubscriptionList from './subscriptionsListSaga'
import UpdateArea from './updateAreaSaga'
import UpdateEmployee from "./updateEmployeeSaga"
import UsedStamp from "./usedStampSaga"
import verifyAccountSaga from "./verifyAccountSaga"
import versionSaga from "./versionSaga"
import actionSystemSaga from "./actionSystemSaga"
import allManuSaga from "./allManuSaga"
import activityAdminSaga from "./actionAdminSaga"
import allAccountSaga from "./allAccountSaga"
import getHarvestList from "./harvestListSaga"
import getValidStampRange from "./validStampRange"
import PortalSettingSaga from './portalSettingSaga'
import guardSaga from "./guardSaga"
import farmerSaga from "./farmerSaga"
import shipSaga from "./shipSaga"
import boxSaga from "./boxListSaga"
import historyImportSaga from "./historyImportSaga"
import receivedBoxSaga from "./receivedBox.js"
import boxSystemSaga from "./boxSystemSaga"
export default function* rootSaga() {
    yield all([
        ...boxSystemSaga,
        ...receivedBoxSaga,
        ...historyImportSaga,
        ...boxSaga,
        ...LogInSaga,
        ...ProductType,
        ...Area,
        ...Employee,
        ...OProduction,
        ...OProcessing,
        ...Process,
        ...Partner,
        ...Feedback,
        ...Actions,
        ...StampHistory,
        ...UsedStamp,
        ...RenderAreaList,
        ...CreateEmployee,
        ...UpdateEmployee,
        ...RenderEmployeeList,
        ...CreateArea,
        ...UpdateArea,
        ...CreatePartner,
        ...DetailPartner,
        ...BranchList,
        ...CreateBranch,
        ...ImportList,
        ...InvoiceList,
        ...PriceList,
        ...ManagementList,
        ...ImportBoxList,
        ...SubscriptionList,
        ...PlanList,
        ...CustomerList,
        ...ActiveStamp,
        ...ProfileSaga,
        ...SettingSaga,
        ...versionSaga,
        ...verifyAccountSaga,
        ...permissionSaga,
        ...scanStampSaga,
        ...scanLocationSaga,
        ...quantityHarvestSaga,
        ...generalSaga,
        ...notiSaga,
        ...createBranchForPartnerSaga,
        ...adminListSaga,
        ...PortalSettingSaga,
        ...detailAccountSaga,
        ...allDistributorSaga,
        ...actionSystemSaga,
        ...allManuSaga,
        ...activityAdminSaga,
        ...allAccountSaga,
        ...getHarvestList,
        ...getValidStampRange,
        ...guardSaga,
        ...farmerSaga,
        ...shipSaga
    ]);
}


