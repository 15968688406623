const initialState = { data: '', errorCode: '', options: [] }
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_BOX_SYSTEM':
      return {
        ...state
      }
    case 'GET_ALL_BOX_SYSTEM_SUCCESS':
      return {
        ...state,
        data: action.data,
        options: action.options,
        errorCode: action.errorCode
      }
    case 'GET_ALL_BOX_SYSTEM_FAILURE':
      return {
        ...state,
        data: action.data,
        errorCode: action.errorCode
      }
    default:
      return state
  }
}
export default reducer
