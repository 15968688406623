import { 
    GET_DETAIL_ACCOUNT,
    GET_DETAIL_ACCOUNT_SUCCESS,
    GET_DETAIL_ACCOUNT_FAILURE,
   } from "actions/types"
  import { takeLatest, call, put, select } from 'redux-saga/effects'
  import { LINK_API } from 'constants/API.js'
  import axios from 'axios'
  
  const getToken = (state) => state.userInfo
  
 
// Get user profile
function * getUserProfile (payload) {
  console.log('Check data', payload)
    try {
      const token = yield select(getToken)
      const response = yield call(fetchProfile, token.data.token, payload.uuid)
      const data = response.data
      yield put({ type: GET_DETAIL_ACCOUNT_SUCCESS, data: data.data })
    } catch (error) {
      yield put({ type: GET_DETAIL_ACCOUNT_FAILURE })
    }
  }
  function fetchProfile (checktoken, uuid) {
    axios.defaults.headers.common.Authorization = checktoken
    return axios({
      method: 'GET',
      url: LINK_API.GET_DETAIL_ACCOUNT + uuid
    })
  }
  const actions = [
    takeLatest(GET_DETAIL_ACCOUNT, getUserProfile)
  ]
  export default actions
  
  