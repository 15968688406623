import { 
  RENDER_AREA_LIST,
  RENDER_AREA_LIST_SUCCESS,
  RENDER_AREA_LIST_FAILURE,
 } from "actions/types"
import { takeLatest, call, put, select } from 'redux-saga/effects'
import { LINK_API } from 'constants/API.js'
import axios from 'axios'

const getToken = (state) => state.userInfo
// const getSaveSetting = (state) => state.settingList.data

// Get area list
function * renderArea (page) {
  try {
    const tokenInfo = yield select(getToken)
    // const setting_data = yield select(getSaveSetting)
    const size = 100;
    const response = yield call(fetchAreaforRender, page.page, size, tokenInfo.data.token, page.ownedBy)
    const data = response.data.data
    const temp = []
    const label = {}
    for (let i = 0; i < data.length; i++) {
      temp.push({
        value: data[i].uuid,
        label: data[i].name + ' - ' + data[i].uuid
      })
      label[`${data[i].uuid}`] = {
        label: data[i].name + ' - ' + data[i].uuid
      }
    }
    yield put({ type: RENDER_AREA_LIST_SUCCESS, data: temp, label: label, errorCode: 1 })
  } catch (error) {
    yield put({ type: RENDER_AREA_LIST_FAILURE, payload: '', errorCode: 0 })
  }
}

function fetchAreaforRender (page, size, checktoken, ownedBy) {
  axios.defaults.headers.common.Authorization = checktoken
  let url = ""
  if(ownedBy){
    url = LINK_API.ADMIN_GET_ALL_AREA.concat('?page=', page , '&size=' , size, '&ownedBy=', ownedBy)
  }
  else{
    url = LINK_API.GET_AREA_LIST.concat('?page=', page , '&size=' , size)
  }
  return axios({
    method: 'GET',
    url: url 
  })
}

const handleAreaList = [
  takeLatest(RENDER_AREA_LIST, renderArea)
]
export default handleAreaList

