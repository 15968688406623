import Axios from "axios";
import Confirm from "components/alert/confirmAlert";
import Error from "components/alert/error";
import Loading from "components/alert/loading";
import Success from "components/alert/success";
import { LINK_API } from "constants/API";
import { useFormik } from 'formik';
import React from "react";
import ReactGoogleMapLoader from "react-google-maps-loader";
import ReactGooglePlacesSuggest from "react-google-places-suggest";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { Button, Input } from "reactstrap";
import "view/css/select.css";
import * as Yup from 'yup';
import cookie from "react-cookies"

const MY_API_KEY = "AIzaSyCB7o1cgt10O8xP9lLbk7Iikfv5ypvr528";

const Contact = React.forwardRef((props, ref) => {
    const uuidManu = cookie.load("uuidManu");

    const t = props.t;
    const userProfile = props.userProfile;
    const [alert, setAlert] = React.useState(null);
    const [isChange, setChange] = React.useState(false)
    const phone_regex = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;  // validate phone vietnam
    const formik = useFormik({
        initialValues: {
            email: '',
            website: '',
            address: '',
            phone: '',
            search: '',
            emailContact: ''
        },
        validationSchema: Yup.object({
            emailContact: Yup.string()
                .email('Địa chỉ email không hợp lệ'),
            phone: Yup.string().matches(phone_regex, 'Số điện thoại không hợp lệ')
        }),
        validateOnChange: true,
        onSubmit: async (values) => {
            handleWarning()
        },
    });
    const handleWarning  = () => {
        setAlert(<Confirm 
            child={"Lưu lại thông tin đã thay đổi ?"}
            cancleBtnText={t('no')}
            confirmBtnText={t("yes")}
            onConfirm = {handleSaveData}
            onClose= {hideAlert}
            />)
    }
    React.useImperativeHandle(ref, () => ({
        handleSaveContact(tab) {
            setAlert(<Confirm
                child={"Lưu lại thông tin đã thay đổi ?"}
                cancelBtnText={t('no')}
                confirmBtnText={t("yes")}
                onConfirm={handleSaveData}
                onClose={() => handleChangeTab(tab)}
            />)
        }
    }));
    const handleChangeTab = (tab) => {
        hideAlert();
        if (props.changeTab) {
            props.changeTab(tab);
        }
    }
    React.useEffect(() => {
        if (userProfile) {
            formik.setValues({
                email: userProfile.username,
                website: userProfile.website,
                address: userProfile.address,
                emailContact: userProfile.publicEmail,
            })
            if(userProfile.phone){
                let indexOf = userProfile.phone.indexOf("+84");
                if (indexOf === 0) {
                    let phone = userProfile.phone.split(" ").join("");
                    phone = '0' + phone.substring(3, phone.length);
                    formik.setFieldValue("phone", phone)
    
                    if (userProfile.publicPhone) {
                        let phoneContact = userProfile.publicPhone.split(" ").join("");
                        phoneContact = '0' + phoneContact.substring(3, phoneContact.length);
                        formik.setFieldValue("phoneContact", phoneContact)
                    }
    
    
                } else {
                    formik.setFieldValue("phone", userProfile.phone)
                    formik.setFieldValue("phoneContact", userProfile.publicPhone)
                }
            }
            

        }

    }, [userProfile])

    const hideAlert = () => {
        setChange(false)
        setAlert(null)
        if (props.onChange) {
            props.onChange(false);
        }
    }
    const handleSaveData = () => {
        setAlert(<Loading />)
        let data = {
            // /email: formik.values.email,
            website: formik.values.website,
            address: formik.values.address,
            phone: formik.values.phone,
            publicEmail: formik.values.emailContact,
            publicPhone: formik.values.phoneContact
        }
        Axios.put(LINK_API.EDIT_PROFILE_USER + uuidManu, data)
            .then(res => {
                if (res.data.errorCode === 1) {
                    setAlert(<Success onConfirm={handleConfirm} hideCancel={true} confirmBtnText={t('confirm')} />)
                } else {
                    setAlert(<Error onClose={hideAlert} confirmBtnText={t('close')} />)
                }
            })
            .catch(err => {
                setAlert(<Error onClose={hideAlert} confirmBtnText={t('close')} />)
            })
    }
    const handleConfirm = () => {
        hideAlert();
        props.getUserProfile(uuidManu);
    }
    const handleChangeAddress = name => event => {
        setChange(true)
        let values = event.target.value;
        if (values) {
            formik.setFieldValue("search", values);
            formik.setFieldValue("address", values, true);
        } else {

            formik.setFieldValue("search", values);
            formik.setFieldValue("address", values, true);
            formik.setFieldError("address", "Thông tin bắt buộc")
        }
    }
    const handleSelectSuggest = (geocodedPrediction) => {
        formik.setFieldValue("address", geocodedPrediction.formatted_address)
        formik.setFieldValue("search", "")
    }
    const handleNoResult = () => {
        console.log('Không có kết quả')
    }
    const handleChangeField = name => async event => {
        await formik.setFieldValue(name, event.target.value, true);
        await formik.setFieldTouched(name, true, true);
        setChange(true)
        if (props.onChange) {
            props.onChange(true);
        }
    }

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-4">
                    <div className="form-row">
                        <div className="form-group col-md-8 text-left mb-0">
                            <h5 className="font-size-xl font-weight-bold">{t('profile.contact')}</h5>
                            <p className="text-black-50 mb-0">{t('profile.aboutContact')}</p>
                        </div>
                        {isChange ?
                            <div className="form-group col-md-4 text-right mb-0">
                                <Button color="info" type="submit" >{t('save')}</Button>
                            </div> : null}
                    </div>

                    <div className="divider my-4" />
                    <div className="form-row ">
                        {/* <div className="form-group col-md-12">
                            <label className="font-weight-bold">{"Tên đăng nhập"} <span className="text-danger">(*)</span></label>
                            <Input
                                placeholder={"Tên đăng nhập"}
                                type="text"
                                name="email"
                                disabled
                                value={formik.values.email}
                                valid={formik.touched.email && formik.errors.email ? false : (formik.values.email ? true : false)}
                                invalid={formik.touched.email && formik.errors.email ? true : false}
                                {...formik.getFieldProps('email')}
                            />
                            {formik.touched.email && formik.errors.email ? (
                                <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.email}</p>
                            ) : null}
                        </div> */}
                        <div className="form-group col-md-12">
                            <label className="font-weight-bold">{t('profile.emailContact')}</label>
                            <Input
                                placeholder={t('profile.emailContact')}
                                type="text"
                                name="emailContact"
                                value={formik.values.emailContact}
                                onChange={handleChangeField("emailContact")}
                                valid={formik.touched.emailContact && formik.errors.emailContact ? false : (formik.values.emailContact ? true : false)}
                                invalid={formik.touched.emailContact && formik.errors.emailContact ? true : false}
                            // {...formik.getFieldProps('emailContact')}
                            />
                            {formik.touched.emailContact && formik.errors.emailContact ? (
                                <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.emailContact}</p>
                            ) : null}
                        </div>
                        <div className="form-group col-md-12">
                            <label className="font-weight-bold">{t('profile.website')}</label>
                            <Input
                                placeholder={t('profile.website')}
                                type="text"
                                name="website"
                                onChange={handleChangeField("website")}
                                value={formik.values.website}
                                valid={formik.touched.website && formik.errors.website ? false : (formik.values.website ? true : false)}
                                invalid={formik.touched.website && formik.errors.website ? true : false}
                            //  {...formik.getFieldProps('website')}
                            />
                            {formik.touched.website && formik.errors.website ? (
                                <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.website}</p>
                            ) : null}
                        </div>
                        <div className="form-group col-md-12">
                            <label className="font-weight-bold">{t('profile.address')}</label>
                            <ReactGoogleMapLoader
                                params={{
                                    key: MY_API_KEY,
                                    libraries: "places,geocode",
                                }}
                                render={googleMaps =>
                                    googleMaps && (
                                        <div>
                                            <ReactGooglePlacesSuggest
                                                autocompletionRequest={{ input: formik.values.search }}
                                                googleMaps={googleMaps}
                                                onSelectSuggest={handleSelectSuggest}
                                                displayPoweredByGoogle={false}
                                                onNoResult={handleNoResult}

                                            >
                                                <Input
                                                    placeholder={t('profile.address')}
                                                    type="text"
                                                    name="address"
                                                    value={formik.values.address}
                                                    valid={formik.touched.address && formik.errors.address ? false : (formik.values.address ? true : false)}
                                                    invalid={formik.touched.address && formik.errors.address ? true : false}
                                                    onChange={handleChangeAddress('address')}
                                                />
                                            </ReactGooglePlacesSuggest>
                                        </div>
                                    )
                                }
                            />
                            {formik.touched.address && formik.errors.address ? (
                                <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.address}</p>
                            ) : null}
                        </div>

                        <div className="form-group col-md-12">
                            <label className="font-weight-bold">{t('profile.phone')}</label>
                            <Input
                                placeholder={t('profile.phone')}
                                type="text"
                                name="phone"
                                value={formik.values.phone}
                                valid={formik.touched.phone && formik.errors.phone ? false : (formik.values.phone ? true : false)}
                                invalid={formik.touched.phone && formik.errors.phone ? true : false}
                                onChange={handleChangeField("phone")}
                            // {...formik.getFieldProps('phone')}
                            />
                            {formik.touched.phone && formik.errors.phone ? (
                                <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.phone}</p>
                            ) : null}
                        </div>

                        <div className="form-group col-md-12">
                            <label className="font-weight-bold">{t('profile.phoneContact')}</label>
                            <Input
                                placeholder={t('profile.phoneContact')}
                                type="text"
                                name="phoneContact"
                                value={formik.values.phoneContact}
                                valid={formik.touched.phoneContact && formik.errors.phoneContact ? false : (formik.values.phoneContact ? true : false)}
                                invalid={formik.touched.phoneContact && formik.errors.phoneContact ? true : false}
                                onChange={handleChangeField("phoneContact")}
                            // {...formik.getFieldProps('phoneContact')}
                            />
                        </div>
                    </div>
                </div>
            </form>
            {alert}
        </>
    )
})
const mapStateToProps = (state) => {
    return {
        userProfile: state.detailAccount.data,
        userInfo: state.userInfo
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        getUserProfile: (uuid) => dispatch({ type: "GET_DETAIL_ACCOUNT", uuid: uuid }),
        updateBanner: (data) => dispatch({ type: "UPDATE_BANNER", data: data }),
        updateLogo: (data) => dispatch({ type: "UPDATE_LOGO", data: data }),
        cleanStore: () => dispatch({ type: "CLEAN_STORE" }),
        getSetting: () => dispatch({ type: "GET_SETTING" }),

    }
}
export default withTranslation('common', { withRef: true })(connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Contact));
