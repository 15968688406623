//formik
import React, { useEffect } from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Select from 'react-select';
import {UncontrolledAlert} from "reactstrap"
//style
import "view/css/select.css";
import "../../css/index.css";

const StepTwo = React.forwardRef((props, ref) => {
    const t = props.t;

    const [areaList, setAreaList] = React.useState([]);
    const [objectList, setObjectList] = React.useState([]);

    const [selectedArea, setSelectedArea] = React.useState({value: t("all"), label: t("all")});
    const [oldArea, setOldArea] = React.useState([]);

    const [selectedRole, setSelectedRole] = React.useState({
        value: "M",
        label: t('employee.manager')
    });
    const permission = props.permission.data;
    const [checkPer, setPer] = React.useState(false);
    React.useEffect(()=>{
        if(permission){
            setPer(permission.advanceSetting)
        }
    },[permission])
    const [selectedObject, setSelectedObject] = React.useState([]);
    const roleList = [
        {
            value: "M",
            label: t('employee.manager')
        },
        {
            value: "SM",
            label: t('employee.emp')
        },
        // {∏
        //     value: "SP",
        //     label: t('employee.pemp')
        // }
    ]
    const [checkedArea, setArea] = React.useState(false)
    React.useEffect(()=>{
        if(selectedArea){
            if(selectedArea.value){
                if(selectedArea.value === t('all')){
                    setArea(false)
                }else{
                    setArea(true)
                }
            }
            if(selectedArea[0]){
                if(selectedArea[0].value === t("all")){
                    setArea("false")
                }else{
                    setArea(true)
                }
            }
        }
        },[selectedArea])
    React.useImperativeHandle(ref, () => ({
        handleMergeData() {
            let deleteZone = [];
            let addZone = [];
            for (let i = 0; i < selectedArea.length; i++) {
                let check = false;
                for (let j = 0; j < oldArea.length; j++) {
                    if (oldArea[j].value === selectedArea[i].value) {
                        check = true;
                    }
                }
                if (check === false) {
                    addZone.push(selectedArea[i].value);
                }
            }
            for (let i = 0; i < oldArea.length; i++) {
                let check = false;
                for (let j = 0; j < selectedArea.length; j++) {
                    if (selectedArea[j].value === oldArea[i].value) {
                        check = true;
                    }
                }
                if (check === false) {
                    deleteZone.push(oldArea[i].value);
                }
            }
            let values = {
                role: selectedRole,
                object: selectedObject,
                addZone: addZone,
                deleteZone: deleteZone
            }
            props.saveData(values, false)
        }
    }));

    const dataStepTwo = props.data;
    const areaListRender = props.areaList;
    React.useEffect(() => {
        if (dataStepTwo.object) {
            setSelectedObject(dataStepTwo.object)
        }
        if (dataStepTwo.manageZone) {
            let listarea = dataStepTwo.manageZone;
            let tempSelect = []
            for (var i = 0; i < listarea.length; i++) {
                if (areaListRender.label[listarea[i]]) {
                    tempSelect.push({
                        value: listarea[i],
                        label: areaListRender.label[listarea[i]] ? areaListRender.label[listarea[i]].label : ""
                    })
                }
            }
            setOldArea(tempSelect)
            if(tempSelect.length > 0 ){
                setSelectedArea(tempSelect)
            }else{
                setSelectedArea({
                    value: t('all'),
                    label: t('all')
                })
            }
           
        }
        if (dataStepTwo.role) {
            if (dataStepTwo.role === "M") {
                setSelectedRole({
                    value: "M",
                    label: t('employee.manager')
                })
            }
            if (dataStepTwo.role === "SF") {
                setSelectedRole({
                    value: "SF",
                    label: t('employee.emp')
                })
            }
            if (dataStepTwo.role === "SM") {
                setSelectedRole({
                    value: "SF",
                    label: t('employee.emp')
                })
            }
            if (dataStepTwo.role === "SP") {
                setSelectedRole({
                    value: "SF",
                    label: t('employee.pemp')
                })
            }

        }
    }, [dataStepTwo, areaListRender, t])


    const renderArea = props.renderArea;
    const getObjectList = props.getObjectList;
    useEffect(() => {
        renderArea(1, 100);
        getObjectList(1, 100);

    }, [renderArea, getObjectList]);


    const objectListData = props.objectList.data;
    useEffect(() => {
        const all = t('all');
        let result = [{ value: all, label: all }];
        for (let i = 0; i < objectListData.length; i++) {
            result.push({
                value: objectListData[i].uuid,
                label: objectListData[i].name + ' - ' + objectListData[i].uuid
            });
        }

        setObjectList(result);
        setAreaList(areaListRender.data);
    }, [areaListRender, areaList, objectListData, t]);


    const handleChange_SelectArea = async (selectedOption) => {
       if(selectedOption){
           if(selectedOption.length){
               if(selectedOption[selectedOption.length -1].value === t('all')){
                   await setArea(false);
                   await setSelectedArea({
                       value: t('all'),
                       label: t('all')
                   })
               }else{
                setSelectedArea(selectedOption) 
               }
           }else{
            setSelectedArea(selectedOption) 
           }
       }else{
        await setSelectedArea({
            value: t('all'),
            label: t('all')
        })
       }
        
    }

    const handleChange_SelectRole = (selectedOption) => {
        if (selectedOption) {
            setSelectedRole(selectedOption)
        }
    }

    const handleChange_SelectObject = async (selectedOption) => {
        setSelectedObject(selectedOption)
    }


    return (
        <>
            <div className="wizard-steps horizontal">
                <div className="p-4">
                    <h5 className="font-size-xl font-weight-bold">{t('employee.addNewMember')}</h5>
                    <p className="text-black-50 mb-4"></p>
                    {!checkPer ? <UncontrolledAlert color="warning">
              {t('notiPlan')}
            </UncontrolledAlert> : null}
                    <div className="form-row ">
                        <div className="form-group col-md-6 pr-3">
                            <label className="font-weight-bold" >{t('employee.inChargeArea')}</label>
                            <Select
                                isSearchable
                                placeholder={t('employee.enterIDArea')}
                                options={areaList}
                                isMulti={checkedArea}
                                isDisabled={!checkPer}
                                value={selectedArea}
                                onChange={handleChange_SelectArea}
                                theme={theme => ({
                                    ...theme,
                                    borderRadius: '0.29rem',
                                    borderWidth: 1,
                                    colors: {
                                        ...theme.colors,
                                        primary25: 'rgba(60,68,177,0.15)',
                                        primary50: 'rgba(60,68,177,0.15)',
                                        primary: '#3c44b1'
                                    }
                                })}
                            />
                        </div>
                        <div className="form-group col-md-6 pl-3">

                            <label className="font-weight-bold" >{t('employee.role')}</label>
                            <Select
                                placeholder={t('employee.role')}
                                options={roleList}
                                value={selectedRole}
                                isDisabled={!checkPer}
                                onChange={handleChange_SelectRole}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: '0.29rem',
                                    borderWidth: 1,
                                    colors: {
                                        ...theme.colors,
                                        primary25: 'rgba(60,68,177,0.15)',
                                        primary50: 'rgba(60,68,177,0.15)',
                                        primary: '#4FB779'
                                    }
                                })}
                            />
                        </div>
                    </div>
                    {/* <div className="form-row">
                        <div className="form-group col-md-6 pr-3 ">
                            <label className="font-weight-bold" >{t('employee.inChargeObject')}</label>
                            <Select
                                placeholder={t('employee.inChargeObject')}
                                options={objectList}
                                value={selectedObject}
                                isMulti
                                onChange={handleChange_SelectObject}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: '0.29rem',
                                    borderWidth: 1,
                                    colors: {
                                        ...theme.colors,
                                        primary25: 'rgba(60,68,177,0.15)',
                                        primary50: 'rgba(60,68,177,0.15)',
                                        primary: '#4FB779'
                                    }
                                })}
                            />
                        </div>
                    </div> */}
                    <div className="form-row ">
                        <div className="form-group col-md-12">
                <p><span className="text-danger">(*)</span>{t('require')}</p>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
})

const mapStateToProps = (state) => {
    return {
        userInfo: state.userInfo,
        areaList: state.renderArea,
        objectList: state.objectList,
        permission: state.permission
    }
}

const mapDispatchToProps = (dispatch) => ({
    createEmployee: (data, image) => dispatch({ type: "CREATE_EMPLOYEE", data: data, avatar: image }),
    renderArea: (page, size) => dispatch({ type: "RENDER_AREA_LIST", page: page, size: size }),
    getObjectList: (page, size) => dispatch({ type: "GET_OBJECT_LIST", page: page, size: size }),
    saveData: (dataTwo, check) => dispatch({ type: "SAVE_DATA_EDIT_EMPLOYEE", dataTwo: dataTwo, check: check }),
});

export default withTranslation('common', { withRef: true })(connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(StepTwo));