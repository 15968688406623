import Header from "components/Headers/Header";
import PageTitle from 'layout-components/PageTitle/index.js';
import ContactIcon from "mdi-react/AccountBoxIcon";
import FaceIcon from 'mdi-react/FaceIcon';
import InfoIcon from "mdi-react/InfoCircleIcon";
import BrandIcon from "mdi-react/WatermarkIcon";
import React from "react";
import cookie from "react-cookies";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { Card, Col, Container, Nav, NavItem, NavLink as NavLinkStrap, Row, TabContent, TabPane } from "reactstrap";
import Brand from "./tabs/brand";
import Cert from "./tabs/cert";
import Contact from "./tabs/contact";
import Info from "./tabs/info";
import ChangePassword from "./tabs/password";
import Setting from "./tabs/setting";
import SettingIcon from "mdi-react/SettingsIcon"
function Profile(props) {
    const uuidManu = cookie.load("uuidManu");
    const t = props.t;

    const infoRef = React.createRef();
    const contactRef = React.createRef();

    const profileData = props.userProfile;
    const getUserProfile = props.getUserProfile;
    const getSetting = props.getSetting;
    const uuidUser = props.userInfo.data.uuid;
    const [activeTab, setActiveTab] = React.useState('1');
    const [isChange, setChange] = React.useState(false);
    const [isChangeInfo, setInfo] = React.useState(false);
    const [state, setState] = React.useState({
        name: "",
        avatar: "",
        uuid: ""
    })
    const toggle = tab => {
        if (isChange) {
            handleSaveContact(tab)
        } else {
            if (isChangeInfo) {
                handleSaveInfo(tab)
            } else {
                if (activeTab !== tab) setActiveTab(tab);
            }

        }
    }
    const handleSaveContact = (tab) => {
        contactRef.current.handleSaveContact(tab);
    }
    const handleSaveInfo = (tab) => {
        infoRef.current.handleSaveInfo(tab);
    }
    const handleChangeTab = (tab) => {
        setChange(false)
        if (activeTab !== tab) setActiveTab(tab);
    }


    React.useEffect(() => {
        function loadData(data) {
            setState({
                ...state,
                name: data.name,
                uuid: data.uuid,
                avatar: data.avatar ? (data.avatar[0] ? data.avatar[0].secure_url : 'https://res.cloudinary.com/dinhtq98/image/upload/v1608607307/Kontum%20Project/Logo_t%E1%BB%89nh_kon_tum-removebg-preview_kegjmn.png') : 'https://res.cloudinary.com/dinhtq98/image/upload/v1608607307/Kontum%20Project/Logo_t%E1%BB%89nh_kon_tum-removebg-preview_kegjmn.png',
            })
        }
        loadData(profileData)
    }, [profileData])
    React.useEffect(() => {
        console.log("Check uuid manu", uuidManu)
        props.getUserProfile(uuidManu)
       // getSetting(uuidManu)
    }, [uuidManu, getUserProfile])
    
    const handleChange = (value) => {
        setChange(value);
    }

    const handleChangeInfo = (value) => {
        setInfo(value);
    }
    return (
        <>
            <Header></Header>
            <Container className='mt--7' fluid>
                <PageTitle
                    titleHeading="Thông tin tài khoản"
                    titleDescription="Chỉnh sửa và thiết lập tài khoản"
                    pageTitleDescription={true}
                    titleIcon={<FaceIcon className="textSuccess" style={{ fill: "#4FB779" }} />}
                    children={null}
                />
                <Card className="card-box mb-5">
                    <Row className="no-gutters">
                        <Col lg="5" xl="5" className="border-right">
                            <div className="p-4 text-center">
                                <div className="avatar-icon-wrapper rounded-circle mx-auto">
                                    <div className="d-block p-0 avatar-icon-wrapper rounded-circle m-0 border-3 border-success">
                                        <div className="rounded-circle border-3 border-white overflow-hidden">
                                            <img alt="manuAva" className="img-fluid d-100" src={state.avatar} />
                                        </div>
                                    </div>
                                </div>
                                <h4 className="font-size-lg font-weight-bold my-2">
                                    {state.name}
                                </h4>
                                <h5 className="font-size-md font-weight-bold my-2">
                                    {state.uuid}
                                </h5>
                                <div className="divider my-4" />
                                <Nav className="nav-neutral-success nav-lg flex-column">
                                    <NavItem>
                                        <NavLinkStrap
                                            onClick={() => { toggle('1'); }}
                                            className={activeTab === '1' ? "rounded font-weight-bold profile-active d-flex my-2" : "my-2 rounded font-weight-bold d-flex"}>
                                            <div className="nav-link-icon mr-3">
                                                <InfoIcon icon={['far', 'chart-bar']} />
                                            </div>
                                            <span>{t('register.accInfo')}</span>
                                        </NavLinkStrap>
                                    </NavItem>
                                    <NavItem>
                                        <NavLinkStrap onClick={() => { toggle('2'); }}
                                            className={activeTab === '2' ? "rounded font-weight-bold profile-active d-flex my-2" : "my-2 rounded font-weight-bold d-flex"}>
                                            <div className="nav-link-icon mr-3">
                                                <ContactIcon icon={['far', 'question-circle']} />
                                            </div>
                                            <span>{t('profile.contact')}</span>
                                        </NavLinkStrap>
                                    </NavItem>

                                    {/* <NavItem>
                                        <NavLinkStrap onClick={() => { toggle('4'); }}
                                            className={activeTab === '4' ? "rounded font-weight-bold profile-active d-flex my-2" : "my-2 rounded font-weight-bold d-flex"}>
                                            <div className="nav-link-icon mr-3">
                                                <BrandIcon icon={['far', 'question-circle']} />
                                            </div>
                                            {t('profile.brand')}
                                        </NavLinkStrap>
                                    </NavItem>
                                    <NavItem>
                                    <NavLinkStrap onClick={() => { toggle('5'); }}
                                        className={activeTab === '5' ? "rounded font-weight-bold profile-active d-flex my-2" : "my-2 rounded font-weight-bold d-flex"}>
                                        <div className="nav-link-icon mr-3">
                                            <SettingIcon icon={['far', 'question-circle']} />
                                        </div>
                                        <span>{t('profile.setting')}</span>
                                    </NavLinkStrap>
                                </NavItem> */}
                                {/* <NavItem>
                                    <NavLinkStrap onClick={() => { toggle('6'); }}
                                        className={activeTab === '6' ? "rounded font-weight-bold profile-active d-flex my-2" : "my-2 rounded font-weight-bold d-flex"}>
                                        <div className="nav-link-icon mr-3">
                                            <CertIcon />
                                        </div>
                                        <span >{t('profile.changePass')}</span>
                                    </NavLinkStrap>
                                   
                                </NavItem> */}
                                </Nav>
                            </div>
                        </Col>
                        <Col lg="7" xl='7'>
                            <TabContent activeTab={activeTab}>
                                <TabPane tabId="1">
                                    <Info ref={infoRef} onChange={handleChangeInfo} changeTab={handleChangeTab} />
                                </TabPane>
                                <TabPane tabId="2">
                                    <Contact ref={contactRef} onChange={handleChange} changeTab={handleChangeTab} />
                                </TabPane>
                                <TabPane tabId="3">
                                    <Cert />
                                </TabPane>
                                <TabPane tabId="4">
                                    <Brand />
                                </TabPane>
                                <TabPane tabId="5">
                                    <Setting />
                                </TabPane>
                                <TabPane tabId="6">
                                    <ChangePassword />
                                </TabPane>
                            </TabContent>
                        </Col>
                    </Row>
                </Card>
            </Container>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        userProfile: state.detailAccount.data,
        userInfo: state.userInfo
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        getUserProfile: (uuid) => dispatch({ type: "GET_DETAIL_ACCOUNT", uuid: uuid }),
        updateBanner: (data) => dispatch({ type: "UPDATE_BANNER", data: data }),
        updateLogo: (data) => dispatch({ type: "UPDATE_LOGO", data: data }),
        cleanStore: () => dispatch({ type: "CLEAN_STORE" }),
        getSetting: () => dispatch({ type: "GET_SETTING" }),

    }
}

export default withRouter(withTranslation('common', { withRef: true })(connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Profile)))