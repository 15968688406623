import React from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import {
  Card,
  Container,
} from "reactstrap";
import Advertisement from "../containers/advertisement";
import Header from "components/Headers/Header";

function AdsSettingPage(props) {
  // const t = props.t;

  return (
    <>
      <Header></Header>
      <Container className="mt--7" fluid>
        <Card className="card-box mb-5">
          <Advertisement tab={1}/>
        </Card>
      </Container>
    </>
  );
}

export default withRouter(
  withTranslation("common", { withRef: true })(
      AdsSettingPage
  )
);
