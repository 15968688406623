import axios from 'axios';
import clsx from 'clsx';
import ConfirmAlert from "components/alert/confirmAlert.js";
import Error from 'components/alert/error';
import Loading from 'components/alert/loading';
import Success from 'components/alert/success';
import LoadingTable from "components/LoadingTable/index.js";
import Pagination from "components/Pagination/index.jsx";
import { LINK_API } from 'constants/API';
import { Field, Form, Formik } from 'formik';
import PlusCircle from "mdi-react/PlusCircleIcon";
import ReplayIcon from "mdi-react/ReplayIcon";
import moment from "moment";
import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Select from 'react-select';
import ReactTable from 'react-table-6';
import { Button, Card, FormGroup,Container,Input, CustomInput, Modal, ModalBody, ModalFooter, ModalHeader, Nav, NavItem, NavLink as NavLinkStrap, TabContent, TabPane } from 'reactstrap';
import * as Yup from 'yup';
import "../../css/reactTable.css";
import { useFormik } from 'formik';
import Header from "components/Headers/Header"
import "./index.css"
function SubscriptionList(props) {
  const [activeTab, setActiveTab] = React.useState('1');

  const hideAlert = () => {
    setAlert(null);
    formik.resetForm();
  }

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const [dataSubscriptionList, setDataSubscriptionList] = React.useState([])
  const [loadingState, setLoading] = React.useState(true)
  const [alert, setAlert] = React.useState(null)
  const [modal, setModal] = React.useState(false);
  const [totalPage, setTotalPage] = React.useState([])
  const { t } = props;
  const getListCustomer = props.getListCustomer;
  const listCustomer = props.listCustomer;
  const typeStamp = [
    {
      value: "UNIT",
      label: "Tem sản phẩm"
    },
    {
      value: "BOX",
      label: "Tem sản phẩm"
    },
  ]
  const [reModal, setRevoke] = React.useState(false);

  const reToggle = () => {
    setRevoke(!reModal)
  }
  const handleModal = () => {
    setModal(!modal)
  }
  const formik = useFormik({
    initialValues: {
      startId: '',
      endId: '',
      type: 'UNIT',
    },
    validationSchema: Yup.object({
      startId: Yup.string()
        .required("Thông tin bắt buộc"),
      endId: Yup.string()
        .required("Thông tin bắt buộc"),
      // type: Yup.string()
      //   .required("Thông tin bắt buộc")
    }),
    onSubmit: React.useCallback(async (values) => {
      let data = {
        "startId": values.startId,
        "endId": values.endId,
        "type": "UNIT",
        "ownedBy": props.userInfo.data.uuid
      }
      setRevoke(false);
      setAlert(<Loading />)
      axios.post(LINK_API.REVOKE_STAMP, data)
        .then((res) => {
          if (res.data.errorCode === 1) {
            getActionsList(1, 5)
            setAlert(<Success onClose={hideAlert} hideCancel={true} onConfirm={hideAlert} confirmBtnText={t('close')} />)
          } else {
            setAlert(<Error onClose={hideAlert} confirmBtnText={t('close')} />)
          }
        })
        .catch(err => {
          if(err && err.response && err.response.data && err.response.data.name === "STAMP_ID_IS_USED"){
            setAlert(<Error onClose={hideAlert} confirmBtnText={t('close')} title="Tem đã được thành viên sử dụng!"/>)
          }
          else{
            setAlert(<Error onClose={hideAlert} confirmBtnText={t('close')} />)
          }
          
        })
    }, []),
  });
  const onSuccess = (data) => {
    setAlert(<Loading />)
    axios.post(LINK_API.STAMP_AUTHORIZE, data)
      .then(async response => {
        if (response.data.errorCode === 1) {
          props.getActionsList(1, 5)
          setModal(false)
          setAlert(<Success onClose={hideAlert} hideCancel={true} onConfirm={hideAlert} confirmBtnText={t('close')} />)

          return
        }
        else if (response.data.errorCode === 251) {
          let ChildComponent = <div>{"Khoản tem đã tồn tại!"}</div>
          setAlert(
            <ConfirmAlert
              onClose={hideAlert}
              hideCancel={true}
              onConfirm={() => hideAlert()}
              confirmBtnText={"OK"}
              child={ChildComponent}
            />
          )
        }
        else {
          setAlert(<Error onClose={hideAlert} confirmBtnText={t('close')} />)
        }
      })
      .catch(err => {
        setAlert(<Error onClose={hideAlert} confirmBtnText={t('close')} />)
      });

  }

  const handleSubmit = (value) => {
    const data = {
      ownedBy: value.companyName.value,
      type: "UNIT",
      amount: -1,
      startId: value.startID,
      endId: value.endID,
    }

    onSuccess(data)
  }

  const handleSubmit2 = (value) => {
    const data = {
      ownedBy: value.companyName.value,
      type: "UNIT",
      amount: value.amount,
      startId: -1,
      endId: -1
    }

    onSuccess(data)
  }

  const enterValue = (value) => {
    const ChildComponent = (
      <div >
        <div >
          <Nav >
            <NavItem>
              <NavLinkStrap
                className={clsx({ 'active-tab-stamp': activeTab === '1' }, 'cursor-pointer')}
                onClick={() => {
                  toggle('1');
                }}>
                <span>Cấp theo khoảng</span>
                <div className="divider" />
              </NavLinkStrap>
            </NavItem>
            <NavItem>
              <NavLinkStrap
                className={clsx({ 'active-tab-stamp': activeTab === '2' }, 'cursor-pointer')}
                onClick={() => {
                  toggle('2');
                }}>
                <span>Cấp theo số lượng</span>
                <div className="divider" />
              </NavLinkStrap>
            </NavItem>

          </Nav>
        </div>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <div className="mt-3">
              <Formik
                initialValues={{ startID: '', endID: '', typeStamp: 'UNIT', companyName: null }}
                validationSchema={

                  Yup.object().shape({
                    companyName: Yup.string()
                      .required(t('require')),
                    // typeStamp: Yup.string()
                    //   .required(t('require')),
                    startID: Yup.number()
                      .required(t('require'))
                      .integer(t('stamp.integer'))
                      .test(t('stamp.endIDLessStartID'), t('stamp.endIDLessStartID'),
                        function (value) {
                          return value <= this.parent.endID;
                        }
                      ),

                    endID: Yup.number()
                      .required(t('require'))
                      .integer(t('stamp.integer'))
                  })
                }
                onSubmit={(value) => { handleSubmit(value) }}
              >
                {({ errors, touched, values, setFieldValue }) => (
                  <Form>
                    <Field
                      name="companyName"
                      render={(props) => (
                        <FormGroup>
                          <label className="font-weight-bold">
                            {"Chọn doanh nghiệp"}
                          </label>
                          <Select
                            placeholder="Nhập tên thành viên"
                            options={listCustomer}
                            value={values.companyName}
                            onChange={(selectedOption) => setFieldValue("companyName", selectedOption)}
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: '0.29rem',
                              borderWidth: 1,
                              colors: {
                                ...theme.colors,
                                primary25: 'rgba(60,68,177,0.15)',
                                primary50: 'rgba(60,68,177,0.15)',
                                primary: '#3c44b1'
                              }
                            })}
                          />
                        </FormGroup>
                      )}
                    />
                    {errors.startID && touched.companyName ? <div style={{ color: "red" }}>{errors.companyName}</div> : null}
                    <Field
                      name="startID"
                      render={(props) => (
                        <FormGroup>
                          <label className="font-weight-bold">
                            {t('stamp.startID')}
                          </label>
                          <Input {...props} type="number" {...props.field} placeholder={t('stamp.startID')} />
                        </FormGroup>
                      )}
                    />
                    {errors.startID && touched.startID ? <div style={{ color: "red" }}>{errors.startID}</div> : null}
                    <Field name="endID"
                      render={(props) => (
                        <FormGroup>
                          <label className="font-weight-bold">
                            {t('stamp.endID')}
                          </label>
                          <Input {...props} type="number" {...props.field} placeholder={t('stamp.endID')} />
                        </FormGroup>
                      )}
                    />
                    {errors.endID && touched.endID ? (
                      <div style={{ color: "red" }}>{errors.endID}</div>
                    ) : null}

                    {/* <Field
                      name="typeStamp"
                      render={(props) => (
                        <FormGroup>
                          <label className="font-weight-bold">
                            {"Chọn loại tem"}
                          </label>
                          <Select
                            placeholder="Chọn loại tem"
                            options={typeStamp}
                            value={values.typeStamp}
                            onChange={(selectedOption) => setFieldValue("typeStamp", selectedOption)}
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: '0.29rem',
                              borderWidth: 1,
                              colors: {
                                ...theme.colors,
                                primary25: 'rgba(60,68,177,0.15)',
                                primary50: 'rgba(60,68,177,0.15)',
                                primary: '#3c44b1'
                              }
                            })}
                          />
                        </FormGroup>
                      )}
                    />
                    {errors.startID && touched.typeStamp ? <div style={{ color: "red" }}>{errors.typeStamp}</div> : null} */}
                    <Button color="info" type="submit">{"Cấp"}</Button>
                  </Form>
                )}
              </Formik>
            </div>
          </TabPane>
          <TabPane tabId="2">
            <div className="mt-3">
              <Formik
                initialValues={{ amount: '', companyName: null }}
                validationSchema={

                  Yup.object().shape({
                    companyName: Yup.string()
                      .required(t('require')),
                    // typeStamp: Yup.string()
                    //   .required(t('require')),
                    amount: Yup.number()
                      .required(t('require'))
                      .integer(t('stamp.integer'))
                    ,
                  })
                }
                onSubmit={(value) => { handleSubmit2(value) }}
              >
                {({ errors, touched, values, setFieldValue }) => (
                  <Form>
                    <Field
                      name="companyName"
                      render={(props) => (
                        <FormGroup>
                          <label className="font-weight-bold">
                            {"Chọn doanh nghiệp"}
                          </label>
                          <Select
                            placeholder="Nhập tên thành viên"
                            options={listCustomer}
                            value={values.companyName}
                            onChange={(selectedOption) => setFieldValue("companyName", selectedOption)}
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: '0.29rem',
                              borderWidth: 1,
                              colors: {
                                ...theme.colors,
                                primary25: 'rgba(60,68,177,0.15)',
                                primary50: 'rgba(60,68,177,0.15)',
                                primary: '#3c44b1'
                              }
                            })}
                          />
                        </FormGroup>
                      )}
                    />
                    {errors.amount && touched.companyName ? <div style={{ color: "red" }}>{errors.companyName}</div> : null}
                    <Field
                      name="amount"
                      render={(props) => (
                        <FormGroup>
                          <label className="font-weight-bold">
                            {"Nhập số lượng"}
                          </label>
                          <Input {...props} type="number" {...props.field} placeholder={"Nhập số lượng"} />
                        </FormGroup>
                      )}
                    />
                    {errors.amount && touched.amount ? <div style={{ color: "red" }}>{errors.amount}</div> : null}

                    {/* <Field
                      name="typeStamp"
                      render={(props) => (
                        <FormGroup>
                          <label className="font-weight-bold">
                            {"Chọn loại tem"}
                          </label>
                          <Select
                            placeholder="Chọn loại tem"
                            options={typeStamp}
                            value={values.typeStamp}
                            onChange={(selectedOption) => setFieldValue("typeStamp", selectedOption)}
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: '0.29rem',
                              borderWidth: 1,
                              colors: {
                                ...theme.colors,
                                primary25: 'rgba(60,68,177,0.15)',
                                primary50: 'rgba(60,68,177,0.15)',
                                primary: '#3c44b1'
                              }
                            })}
                          />
                        </FormGroup>
                      )}
                    />
                    {errors.startID && touched.typeStamp ? <div style={{ color: "red" }}>{errors.typeStamp}</div> : null} */}
                    <Button color="info" type="submit">{"Cấp"}</Button>
                  </Form>
                )}
              </Formik>
            </div>
          </TabPane>

        </TabContent>
      </div>
    )

    return ChildComponent;
  }
  const genTable = React.useCallback((dataList) => {
    const newData = dataList || []
    let data = newData.map((prop, index) => {
      const createAt = moment(parseInt(Date.parse(prop.createdAt))).format('DD/MM/YYYY')
      return {
        id: index + 1,
        name: <div>
          {prop.name} <br></br> {"(Mã: " + prop.ownedBy + ")"}
        </div>,
        date: createAt,
        type: prop.stampType === 'BOX' ? 'Tem thùng' : 'Tem trái',
        start: prop.startId,
        end: prop.endId
      }
    })
    setDataSubscriptionList(data)
    setLoading(false)
  }, [])

  const getActionsList = props.getActionsList
  useEffect(() => {
    getActionsList(1, 5)
  }, [getActionsList]);

  useEffect(() => {
    getListCustomer();
  }, [getListCustomer]);

  const activeStamp = props.activeStamp.data
  const totalPageProps = props.activeStamp.totalPage
  useEffect(() => {
    if (activeStamp && activeStamp.length > 0) {
      genTable(activeStamp)
      setTotalPage(totalPageProps)
    } else {
      genTable([])
      setTotalPage(0)
    }

  }, [activeStamp]);

  const columns = React.useMemo(
    () => [
      {
        accessor: "id",
        filterable: false,
        className: "center",
        maxWidth: 100,
        Header: () => (
          <div style={{
            textAlign: "center",
          }}>
            #
          </div>
        )
      },
      {
        Header: t('activeStamp.name'),
        accessor: "name",
        sortable: false
      },
      {
        Header: t('activeStamp.createdTime'),
        accessor: "date",
        filterable: false
      },
      {
        Header: t('activeStamp.type'),
        accessor: "type",
        filterable: false
      },
      {
        Header: t('activeStamp.startID'),
        accessor: "start",
        filterable: false
      },
      {
        Header: t('activeStamp.endID'),
        accessor: "end",
        filterable: false
      }
    ],
    [t]
  );



  return (

    <>
     <Header/>
              <Container className='mt--7' fluid>
      <Card className="card-box mb-5">
        <div className="card-header py-3 d-flex justify-content-between">
     
          <div className=" font-weight-bold card-header--title font-size-lg">{t('activeStamp.list')}</div>
          <div className="card-header--actions">
            <Button outline onClick={reToggle} color="danger">
              <span className="btn-wrapper--icon">
                <ReplayIcon size={16} />
              </span>
              <span className="btn-wrapper--label">{t('activeStamp.revoke')}</span>
            </Button>
            <Button onClick={() => setModal(true)} color="info" className="ml-2">
              <span className="btn-wrapper--icon">
                <PlusCircle size={16} />
              </span>
              <span className="btn-wrapper--label">{t('addVote')}</span>
            </Button>
          </div>
        </div>

        <div className="divider" />
        <div className="card-body pt-2 px-4 pb-4">

          <ReactTable
            noDataText={t('noData')}
            data={dataSubscriptionList}
            columns={columns}
            previousText={"<"}
            nextText={">"}
            rowsText={t('row')}
            ofText="/"
            defaultPageSize={5}
            showPaginationBottom={true}
            sortable={false}
            resizable={true}
            PaginationComponent={Pagination}
            className="-striped -highlight"
            loading={loadingState}
            LoadingComponent={LoadingTable}
            manual
            pages={totalPage}

            onFetchData={async (state, instance) => {
              setLoading(true)
              let x = state.page + 1;
              getActionsList(x, state.pageSize)
            }}
          />
        </div>
      </Card>
      </Container>
      <Modal zIndex={2000} centered isOpen={modal} toggle={handleModal}>
        <ModalHeader className="d-flex" toggle={handleModal}>
          Nhập thông tin
        </ModalHeader>
        <ModalBody>
          {enterValue()}
        </ModalBody>
      </Modal>
      <Modal zIndex={2000} centered isOpen={reModal} toggle={reToggle}>
        <ModalHeader className="d-flex" toggle={reToggle}>
          {t('activeStamp.revoke')}
        </ModalHeader>
        <ModalBody>
          <form onSubmit={formik.handleSubmit}>
            <div className="form-row">
              <div className="form-group col-md-12">
                <label className="font-weight-bold">{t('activeStamp.startID')}</label>
                <Input
                  placeholder={t('activeStamp.startID')}
                  type="number"
                  value={formik.values.startId}
                  valid={formik.touched.startId && formik.errors.startId ? false : (formik.values.startId ? true : false)}
                  invalid={formik.touched.startId && formik.errors.startId ? true : false}
                  {...formik.getFieldProps('startId')}
                />
                {formik.touched.startId && formik.errors.startId ? (
                  <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.startId}</p>
                ) : null}
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-12">
                <label className="font-weight-bold">{t('activeStamp.endID')}</label>
                <Input
                  placeholder={t('activeStamp.endID')}
                  type="number"
                  value={formik.values.endId}
                  valid={formik.touched.endId && formik.errors.endId ? false : (formik.values.endId ? true : false)}
                  invalid={formik.touched.endId && formik.errors.endId ? true : false}
                  {...formik.getFieldProps('endId')}
                />
                {formik.touched.endId && formik.errors.endId ? (
                  <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.endId}</p>
                ) : null}
              </div>
            </div>
            {/* <div className="form-row">
              <div className="form-group col-md-12">
                <label className="font-weight-bold">Loại tem</label>
                <CustomInput
                  placeholder={t('activeStamp.type')}
                  type="select"
                  value={formik.values.type}
                  onChange={formik.handleChange}
                  valid={formik.touched.type && formik.errors.type ? false : (formik.values.type ? true : false)}
                  invalid={formik.touched.type && formik.errors.type ? true : false}
                  name="customSelect"
                  {...formik.getFieldProps('type')}
                >
                  <option value={'UNIT'}>{t('activeStamp.unit')}</option>
                  <option value={'BOX'}>{t('activeStamp.box')}</option>
                </CustomInput>
                {formik.touched.type && formik.errors.type ? (
                  <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.endId}</p>
                ) : null}
              </div>
            </div> */}
          </form>
        </ModalBody>
        <ModalFooter>
          <Button color="info" onClick={formik.submitForm}>
            {t('confirm')}
          </Button>
        </ModalFooter>
      </Modal>
      {alert}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
    activeStamp: state.activeStamp,
    listCustomer: state.listCustomer.data
  }
}

const mapDispatchToProps = (dispatch) => ({
  getActionsList: (page, size) => dispatch({ type: "GET_HISTORY_STAMP", page: page, size: size }),
  getListCustomer: () => dispatch({ type: "RENDER_SELECT_CUSTOMER" }),

});

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(SubscriptionList));