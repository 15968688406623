import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  Card,
  Container,
} from "reactstrap";
import Footer from "../containers/footer";
import Header from "components/Headers/Header";

function FooterSettingPage(props) {
  // const t = props.t;

  const contactRef = React.createRef();

  const portalSetting = props.portalSetting;
  const getPortalSetting = props.getPortalSetting;

  const [state, setState] = React.useState({
    phone: '',
    email: '',
    fax: '',
    address: ''
  });

  React.useEffect(() => {
    function loadData(data) {
      if(data){
        setState({
          ...state,
          phone: data.phone,
          email: data.email,
          fax: data.fax,
          address: data.address
        });
      }
    }
    loadData(portalSetting);
  }, [portalSetting]);

  React.useEffect(() => {
    getPortalSetting();
  }, [getPortalSetting]);

  return (
    <>
      <Header></Header>
      <Container className="mt--7" fluid>
        <Card className="card-box mb-5">
          <Footer
            ref={contactRef}
          />
        </Card>
      </Container>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    portalSetting: state.portalSetting.infoPortal,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getPortalSetting: () => dispatch({ type: "GET_PORTAL_SETTING" }),
  };
};
export default withTranslation("common", { withRef: true })(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    FooterSettingPage
  )
);