import Axios from "axios";
import clsx from "clsx";
import ConfirmAlert from "components/alert/confirmAlert";
import Failed from "components/alert/error";
import Loading from "components/alert/loading";
import Success from "components/alert/success";
import LoadingTable from "components/LoadingTable/index.js";
import Pagination from "components/Pagination/index.jsx";
import { LINK_API } from "constants/API";
import el from "date-fns/locale/vi";
import { useFormik } from 'formik';
import CheckIcon from "mdi-react/CheckIcon";
import CloseIcon from "mdi-react/CloseIcon";
import SpaIcon from "mdi-react/SpaIcon";
import moment from "moment";
import React from "react";
import cookie from "react-cookies";
import DatePicker, { registerLocale } from "react-datepicker";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import ReactTable from "react-table-6";
import { Button, Col, CustomInput, Modal, ModalBody, ModalFooter, ModalHeader, Nav, NavItem, NavLink as NavLinkStrap, Row, TabContent, Table, TabPane, UncontrolledAlert, UncontrolledTooltip } from "reactstrap";
import "view/css/reactTable.css";
import * as Yup from 'yup';
import ShowIcon from "mdi-react/EyeIcon"
import HideIcon from "mdi-react/EyeOffIcon"
import "../css/edit.css";
registerLocale("el", el);

const CryptoJS = require('crypto-js')
const EthereumTx = require('ethereumjs-tx');

const StepTwo = React.forwardRef((props, ref) => {
  const t = props.t
  const dataStepTwo = props.data;
  const [loadingState, setLoading] = React.useState(true);
  const [listProduct, setProduct] = React.useState([])
  const [listProcess, setProcess] = React.useState([])
  const [startDate, setStartDate] = React.useState(new Date());
  const [modal, setModal] = React.useState(false);
  const [dataTable, setTable] = React.useState([])
  const [activeTab, setActiveTab] = React.useState('1');
  const [alert, setAlert] = React.useState(null)
  const uuid = cookie.load("uuidOProduction");
  const ownedByProduct = cookie.load("ownedByProduct");
  const getCrop = props.getObjectCrop;
  const cropList = props.cropObject.data;
  const permission = props.permission.data;
  const [checkPer, setPer] = React.useState(false);
  React.useEffect(() => {
    if (permission) {
      if (permission.maxCrop !== -1) {
        if (permission.crop >= permission.maxCrop) {
          setPer(true)
        }
      }
    }
  }, [permission])
  const toggleModal = () => {
    setModal(!modal)
  }
  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  }
  const handleSubmitForm = () => {
    formik.submitForm();
  }
  const hideAlert = () => {
    setAlert(null)
  }
  const handleStopCrop = () => {
    setAlert(<Loading />);
    Axios.post(LINK_API.STOP_CROP, { "uuidOfProductionObject": uuid })
      .then(res => {
        if (res.data.errorCode === 1) {
          getCrop(uuid, ownedByProduct)
          setAlert(
            <Success
              hideCancel={true}
              onConfirm={hideAlert}
              confirmBtnText={t('close')}
              onClose={hideAlert}
            />
          )
          if (props.detail) {
            props.detail();
          }
        } else {
          setAlert(
            <Failed
              onClose={() => setAlert(null)}
              confirmBtnText={t('close')}
            />
          )
        }
      })
      .catch(err => {
        console.log(err)
        setAlert(
          <Failed
            onClose={() => setAlert(null)}
            confirmBtnText={t('close')}
          />
        )
      })
  }
  React.useImperativeHandle(ref, () => ({
    handleAddCrop() {
      setModal(true)
    }
  }));
  const handleSendRaw = (data) => {
    var bytes = CryptoJS.AES.decrypt(props.userInfo.data.privateEncrypted.toString(), props.userInfo.password);
    const privateKey = bytes.toString(CryptoJS.enc.Utf8);
    const privatekey_slice = privateKey.substring(2, privateKey.length);
    const Buffer_privatekey = Buffer.from(privatekey_slice.toString(), 'hex');
    const tx = new EthereumTx(data.rawFungibleAsset, { chain: 4 });
    tx.sign(Buffer_privatekey);
    const rawTx = '0x' + tx.serialize().toString('hex');
    const rawtosend = {
      ...data,
      "raw": rawTx,
      "uuidOfProductionObject": uuid,
      "processCode": formik.values.process || "D1",
      "uuidOfProductType": formik.values.product,
      "startTime": formik.values.time,
    }
    Axios.post(LINK_API.SEND_RAW_CROP, rawtosend)
      .then(res => {
        if (res.data.errorCode === 1) {
          setAlert(
            <Success
              hideCancel={true}
              onConfirm={hideAlert}
              confirmBtnText={t('close')}
              onClose={hideAlert}
            />
          )
          getCrop(uuid, ownedByProduct)
          if (props.detail) {
            props.detail();
          }
        } else {
          setAlert(<Failed
            onClose={() => setAlert(null)}
            confirmBtnText={t('close')}
          />)
        }
      })
      .catch(err => {
        console.log(err);
        setAlert(<Failed
          onClose={() => setAlert(null)}
          confirmBtnText={t('close')}
        />)
      })
  }
  const formik = useFormik({
    initialValues: {
      product: props.data.productType,
      process: props.data.processCode || listProcess[0],
      time: new Date(),
    },
    validationSchema: Yup.object({
      product: Yup.string()
        .required("Thông tin bắt buộc"),
      // process: Yup.string()
      //     .required("Thông tin bắt buộc"),
      time: Yup.date()
        .required("Thông tin bắt buộc"),
    }),
    onSubmit: async (values) => {
      await toggleModal();
      setAlert(<Loading />);
      let data_crop = {
        "startTime": values.time,
        "uuidOfProductionObject": uuid,
        "processCode": values.process || "D1",
        "uuidOfProductType": values.product,
      }
      Axios.post(LINK_API.ASSIGN_CROP, data_crop)
        .then(res => {
          if (res.data.errorCode === 1) {
            handleSendRaw(res.data.data)
          } else {
            setAlert(<Failed
              onClose={() => setAlert(null)}
              confirmBtnText={t('close')}
            />)
          }
        })
        .catch(err => {
          console.log(err);
          setAlert(<Failed
            onClose={() => setAlert(null)}
            confirmBtnText={t('close')}
          />)
        })

    },
  });
  const handleChangeDate = (date) => {
    setStartDate(date);
    formik.setFieldValue("time", date, true)
  }
  const handleReload = () => {
    getCrop(uuid, ownedByProduct)
    hideAlert();
  }
  const handleWarning = (uuid) => {
    setAlert(<ConfirmAlert
      child='Bạn muốn xóa nhật ký này ?'
      content=''
      onConfirm={() => handleDeleteDiary(uuid)}
      onClose={() => hideAlert()}
      hideCancle={false}
      cancleBtnText='Hủy'
      confirmBtnText='Xác nhận'
    />)
  }
  const handleWarningHide = (uuid, hide, title) => {
    setAlert(<ConfirmAlert
      child={title}
      content=''
      onConfirm={() => handleHideDiary(uuid, hide)}
      onClose={() => hideAlert()}
      hideCancle={false}
      cancleBtnText='Hủy'
      confirmBtnText='Xác nhận'
    />)
  }
  const handleHideDiary = (uuid, hide) => {
    setAlert(<Loading />)
    let dataSend = {
      "hideList": [],
      ownedBy: ownedByProduct
    }
    let temp = {};
    temp[`${uuid}`] = hide;
    dataSend.hideList.push(temp)

    Axios.post(LINK_API.ADMIN_HIDE_DIARY, dataSend)
      .then(res => {
        if (res.data.errorCode === 1) {
          setAlert(
            <Success
              hideCancel={true}
              onConfirm={handleReload}
              confirmBtnText={t('close')}
              onClose={hideAlert}
            />
          )
        } else {
          setAlert(
            <Failed
              onClose={() => setAlert(null)}
              confirmBtnText={t('close')}
            />
          )
        }
      })
      .catch(err => {
        setAlert(
          <Failed
            onClose={() => setAlert(null)}
            confirmBtnText={t('close')}
          />
        )
      })
  }
  const handleDeleteDiary = (id) => {
    setAlert(<Loading />)
    Axios.delete(LINK_API.ADMIN_DELETE_DIARY + "diaryId=" + id + "&ownedBy=" + ownedByProduct)
      .then(res => {
        if (res.data.errorCode === 1) {
          setAlert(
            <Success
              hideCancel={true}
              onConfirm={handleReload}
              confirmBtnText={t('close')}
              onClose={hideAlert}
            />
          )
        } else {
          setAlert(
            <Failed
              onClose={() => setAlert(null)}
              confirmBtnText={t('close')}
            />
          )
        }
      })
      .catch(err => {
        setAlert(
          <Failed
            onClose={() => setAlert(null)}
            confirmBtnText={t('close')}
          />
        )
      })
  }
  React.useEffect(() => {
    if (cropList) {
      genCropList(cropList)
    }
    async function genCropList(list) {
      await list.reverse();
      let data = list.map((prop, key) => {
        const length = prop.diary.length;
        const harvests_list = []
        const harvests_final = []
        let stepStatus = "";
        var i = 0;
        for (i; i < length; i++) {
          let certificate = [];
          //const temp = 
          for (let j = 0; j < prop.diary[i].images.length; j++) {
            certificate.push({
              src: prop.diary[i].images[j].secure_url,
              thumbnail: prop.diary[i].images[j].secure_url,
              caption: prop.nameOfStep[prop.diary[i].step],
            })
          }
          let k = 0;
          if (prop.diary[i].step !== prop.harvestStep) {
            console.log("prop.diary", prop.diary[i])
            stepStatus = prop.nameOfStep[prop.diary[i].step];
            const thisDiary = prop.diary[i]
            harvests_list.push([
              prop.nameOfStep[prop.diary[i].step],
              moment(parseInt(prop.diary[i].createdAt)).format("DD/MM/YYYY HH:mm:ss"),
              prop.diary[i].fromId + " - " + prop.diary[i].nameOfActor,
              <Row>
                {certificate.length === 0 ? <Col xs={12} md={12} sm={12} lg={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <p>{prop.diary[i].detail}</p>
                </Col> : ""}
                {certificate.length > 0 ? <Col xs={12} md={12} sm={12} lg={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <p>{prop.diary[i].detail}</p>
                </Col> : ""}
                {certificate.length > 0 ? <Col xs={6} md={6} sm={6} lg={6} >
                </Col> : ""}
              </Row>,
              <div className='d-flex justify-content-center'>
                <Button color="neutral-success" onClick={() => handleWarning(thisDiary._id)} className="mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center" id={"addDiary" + i}>
                  <span className="btn-wrapper--icon d-flex">
                    <CloseIcon size={18} className="text-danger font-size-sm" />
                  </span>
                </Button>
                <UncontrolledTooltip trigger="hover" popperClassName="tooltip-black" placement="top" container="body" target={"addDiary" + i}>
                  Xóa nhật ký
                </UncontrolledTooltip>
                {
                  !thisDiary.hide ?
                    <>
                      <Button color="neutral-success" onClick={() => handleWarningHide(thisDiary._id, !thisDiary.hide, "Bạn muốn hiển thị nhật ký này")} className="mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center" id={"hideDiary" + i}>
                        <span className="btn-wrapper--icon d-flex">
                          <HideIcon size={18} className="text-danger font-size-sm" />
                        </span>
                      </Button>
                      <UncontrolledTooltip trigger="hover" popperClassName="tooltip-black" placement="top" container="body" target={"hideDiary" + i}>
                        Ẩn nhật ký
                      </UncontrolledTooltip>
                    </>
                    :
                    <>
                      <Button color="neutral-success" onClick={() => handleWarningHide(thisDiary._id, !thisDiary.hide, "Bạn muốn ẩn nhật ký này")} className="mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center" id={"hideDiary" + i}>
                        <span className="btn-wrapper--icon d-flex">
                          <ShowIcon size={18} className="text-success font-size-sm" />
                        </span>
                      </Button>
                      <UncontrolledTooltip trigger="hover" popperClassName="tooltip-black" placement="top" container="body" target={"hideDiary" + i}>
                        Hiển thị nhật ký
                      </UncontrolledTooltip>
                    </>
                }
              </div>,

            ])
          } else {
            stepStatus = prop.nameOfStep[prop.diary[i].step];
            harvests_final.push([
              prop.nameOfStep[prop.diary[i].step],
              moment(prop.diary[i].createdAt).format("DD/MM/YYYY HH:mm:ss"),
              prop.diary[i].fromId + " - " + prop.diary[i].nameOfActor,
              <Row>
                {certificate.length === 0 ? <Col xs={12} md={12} sm={12} lg={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <p>{prop.diary[i].detail}</p>
                </Col> : ""}
                {certificate.length > 0 ? <Col xs={6} md={6} sm={6} lg={6} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <p>{prop.diary[i].detail}</p>
                </Col> : ""}
                {certificate.length > 0 ? <Col xs={6} md={6} sm={6} lg={6} >
                </Col> : ""}
              </Row>,
              <div className='d-flex justify-content-center'>
                <Button color="neutral-success" onClick={() => handleWarning(prop.diary[i - 1]._id)} className="mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center" id={"addDiary" + i}>
                  <span className="btn-wrapper--icon d-flex">
                    <CloseIcon size={18} className="text-danger font-size-sm" />
                  </span>
                </Button>
                <UncontrolledTooltip trigger="hover" popperClassName="tooltip-black" placement="top" container="body" target={"addDiary" + i}>
                  Xóa nhật ký
                </UncontrolledTooltip>
              </div>
            ])
            k = k + 1;
          }
        }
        if (prop.status === 1) {}
        return {
          id: key + 1,
          stepStatus: stepStatus,
          time: moment(prop.startTime).format("DD/MM/YYYY hh:ss"),
          product: prop.nameOfProductType,
          process: prop.nameOfProcess,
          status: prop.status === 1 ? "Đang sản xuất " : prop.status === 0 ? "Ngừng sản xuất" : "",
          takecare: harvests_list,
          harvest: harvests_final,
          actions: prop.status === 1 ?
            <>
              <Button onClick={handleStopCrop} color="neutral-danger" className="mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center" id="stopCrop">
                <span className="btn-wrapper--icon d-flex">
                  <CloseIcon
                    size={18}
                    className="text-danger font-size-sm"
                  />
                </span>
              </Button>
              <UncontrolledTooltip trigger="hover" popperClassName="tooltip-black" placement="top" container="body" target="stopCrop">
                {t('oProduction.stopCrop')}
              </UncontrolledTooltip>
            </>
            : (key === 0 ?
              <>
                <Button onClick={handleStopCrop} color="neutral-info" className="mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center" id="continueCrop">
                  <span className="btn-wrapper--icon d-flex">
                    <CheckIcon
                      size={18}
                      className="text-info font-size-sm"
                    />
                  </span>
                </Button>
                <UncontrolledTooltip trigger="hover" popperClassName="tooltip-black" placement="top" container="body" target="continueCrop">
                  {t('oProduction.continueCrop')}
                </UncontrolledTooltip>
              </>
              : null),
        };
      })
      setTable(data)
      setLoading(false)
    }
  }, [cropList, t])

  React.useEffect(() => {
    getCrop(uuid, ownedByProduct)
  }, [getCrop, uuid])
  const columns = React.useMemo(
    () => [
      {
        accessor: "id",
        filterable: false,
        className: "center",
        maxWidth: 150,
        Header: () => (
          <div style={{
            textAlign: "center"
          }}>
            #
          </div>
        )
      },
      {
        Header: t('oProduction.created'),
        minWidth: 107,
        accessor: "time",
        filterable: false
      },
      {
        Header: t('oProduction.productCrop'),
        accessor: "product",
        filterable: false,
        resizable: true,
      },
      {
        Header: t('oProduction.processCrop'),
        accessor: "process",
        filterable: false,
        resizable: true,
      },
      {
        Header: t('oProduction.status'),
        accessor: "status",
        filterable: false
      },
      // {
      //   Header: t('actions'),
      //   accessor: "actions",
      //   filterable: false
      // },
    ],
    [t]
  );
  const productListState = props.productList;
  const processListState = props.processList;
  React.useEffect(() => {
    if (productListState.data) {
      if (productListState.data.length > 0) {
        setProduct(productListState.data)
      }
    }
  }, [productListState])

  React.useEffect(() => {
    if (processListState.data) {
      if (processListState.data.length > 0) {
        setProcess(processListState.data)
      }
    }
  }, [processListState])
  return (
    <>
      <div className="wizard-steps horizontal">
        <div className="pt-4 px-2">
          {checkPer ? <UncontrolledAlert color="warning">
            {t('notiPlanCrop')}
          </UncontrolledAlert> : null}
        </div>
        <div className="w-100">
          <ReactTable
            noDataText={t('noData')}
            data={dataTable}
            columns={columns}
            previousText={"<"}
            nextText={">"}
            rowsText={t('row')}
            ofText="/"
            loading={loadingState}
            LoadingComponent={LoadingTable}
            defaultPageSize={5}
            showPaginationBottom={true}
            sortable={false}
            resizable={true}
            PaginationComponent={Pagination}
            className="-striped -highlight"
            SubComponent={row => {
              return (
                <>
                  <div className="borderTab">
                    <div className="nav-tabs-success">
                      <Nav tabs >
                        <NavItem>
                          <NavLinkStrap
                            className={clsx({ active: activeTab === '1' })}
                            onClick={() => {
                              toggle('1');
                            }}
                          >
                            <span className="px-3 py-2 font-weight-bold">{t('oProduction.diary')}</span>
                          </NavLinkStrap>
                        </NavItem>
                        <NavItem>
                          <NavLinkStrap
                            className={clsx({ active: activeTab === '2' })}
                            onClick={() => {
                              toggle('2');
                            }}
                          >
                            <span className="px-3 py-2 font-weight-bold">{t('oProduction.harvest')}</span>
                          </NavLinkStrap>
                        </NavItem>
                      </Nav>
                    </div>
                    <div className="nav-tabs-success widthTab2 "></div>
                  </div>
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      <div className="table-responsive-md mt-5">
                        <Table hover bordered striped className="mb-5">
                          <thead className="thead-light">
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">{t('oProduction.nameCrop')}</th>
                              <th scope="col">{t('oProduction.timeCrop')}</th>
                              <th scope="col">{t('oProduction.whoCrop')}</th>
                              <th scope="col" className="text-center">{t('oProduction.Note')}</th>
                              <th scope="col" className="text-center">Hành động</th>
                            </tr>
                          </thead>
                          <tbody>
                            {row.original.takecare.map((item, idx) => (
                              <tr>
                                <th scope="row">{idx + 1}</th>
                                <td>{item[0]}</td>
                                <td>{item[1]}</td>
                                <td>{item[2]}</td>
                                <td>{item[3]}</td>
                                <td>{item[4]}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    </TabPane>
                    <TabPane tabId="2">
                      <div className="table-responsive-md mt-5">
                        <Table hover bordered striped className="mb-5">
                          <thead className="thead-light">
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">{t('oProduction.nameCrop')}</th>
                              <th scope="col">{t('oProduction.timeCrop')}</th>
                              <th scope="col">{t('oProduction.whoCrop')}</th>
                              <th scope="col" className="text-center">{t('oProduction.Note')}</th>
                              <th scope="col" className="text-center">Hành động</th>
                            </tr>
                          </thead>
                          <tbody>
                            {row.original.harvest.map((item, idx) => (
                              <tr>
                                <th scope="row">{idx + 1}</th>
                                <td>{item[0]}</td>
                                <td>{item[1]}</td>
                                <td>{item[2]}</td>
                                <td>{item[3]}</td>
                                <td>{item[4]}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    </TabPane>
                  </TabContent>
                </>
              )
            }
            }
          />
        </div>
        {alert}
        <Modal zIndex={2000} centered isOpen={modal} toggle={toggleModal}>
          {/* <ModalHeader toggle={toggleModal}><SpaIcon className="text-success" /> {t('oProduction.addCrop')}</ModalHeader> */}
          <ModalBody>
            <form onSubmit={formik.handleSubmit}>
              <div className="form-group">
                <label className="font-weight-bold">{t('oProduction.product')} <span className="text-danger">(*)</span></label>
                <CustomInput
                  type="select"
                  name="customSelect"
                  id="selectType"
                  placeholder={t('oProduction.product')}
                  value={formik.values.product}
                  valid={formik.touched.product && formik.errors.product ? false : (formik.values.product ? true : false)}
                  invalid={formik.touched.product && formik.errors.product ? true : false}
                  onChange={formik.handleChange}
                  {...formik.getFieldProps('product')}
                >
                  <option value="">{t('oProduction.product')}</option>
                  {listProduct.map((item, idx) => (
                    <option key={idx} value={item.value}>{item.name}</option>
                  ))}
                </CustomInput>
                {formik.touched.product && formik.errors.product ? (
                  <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.product}</p>
                ) : null}
              </div>
              <div className="form-group">
                <label className="font-weight-bold" >{t('oProduction.startTime')} <span className="text-danger">(*)</span></label>
                <DatePicker className="form-control"
                  valid={formik.touched.time && formik.errors.time ? false : (formik.values.time ? true : false)}
                  name="time"
                  locale="el"
                  selected={startDate}
                  onChange={handleChangeDate} />
                {formik.touched.time && formik.errors.time ? (
                  <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.time}</p>
                ) : null}
              </div>
            </form>
          </ModalBody>
          <ModalFooter>
            <Button color="neutral-danger" onClick={toggleModal}>{t('cancel')}</Button>{' '}
            <Button color="success" className="ml-auto" type="submit" onClick={handleSubmitForm}>{t('addCrop')}</Button>
          </ModalFooter>
        </Modal>
      </div>
    </>
  )
})

const mapDispatchToProps = (dispatch) => {
  return {
    getObjectCrop: (data, ownedByProduct) => dispatch({ type: "GET_CROP_OBJECT", uuid: data, ownedBy: ownedByProduct }),
  }
}

const mapStateToProps = (state) => {
  return {
    cropObject: state.cropObject,
    productList: state.renderProduct,
    processList: state.renderProcess,
    userInfo: state.userInfo,
    permission: state.permission
  }
}
export default withTranslation('common', { withRef: true })(connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(StepTwo));