/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/plugins/nucleo/css/nucleo.css";
import "assets/scss/argon-dashboard-react.scss";
import i18next from 'i18next';
import React from "react";
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from 'redux-persist/lib/integration/react';
import { config as i18nextConfig } from 'translation/index.js';
import { persistor, store } from './config/configureStore';
import ManageRoute from "./manageRoute";

i18next.init(i18nextConfig);

const App = (props) => {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <BrowserRouter>
          <I18nextProvider i18n={i18next}>
           <ManageRoute/>
          </I18nextProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  )
}

export default App