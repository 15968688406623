import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import {
  Row,
  Col,
  Button,
  Input
} from 'reactstrap';
//import css
import "./css/index.css"
import { IMG_LOADING } from "constants/image";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import "view/css/select.css";
import ReactGoogleMapLoader from "react-google-maps-loader";
import ReactGooglePlacesSuggest from "react-google-places-suggest";
import PhoneInput from 'react-phone-input-2'
// import 'react-phone-input-2/lib/style.css'
import ReCAPTCHA from "react-google-recaptcha";

import ConfirmAlert from "components/alert/confirmAlert.js";
import { LINK_API } from 'constants/API'
import axios from 'axios';

const ethers = require('ethers');
const CryptoJS = require("crypto-js");
const EthereumTx = require('ethereumjs-tx');

const MY_API_KEY = "AIzaSyCB7o1cgt10O8xP9lLbk7Iikfv5ypvr528";
const SITE_KEY = '6Ld43NkUAAAAAFoyhv4bxVaWfnfTc1HyFahfIeyl'

function Step2(...props) {

  const t = props[0].t
  const [alert, setAlert] = React.useState(null)
  const [loading, setLoading] = React.useState(null)

  const formik = useFormik({
    initialValues: {
      name: props[0].data ? props[0].data.name : "",
      address: props[0].data ? props[0].data.address : "",
      phone: props[0].data ? props[0].data.phone : "",
      email: props[0].data ? props[0].data.email : "",
      tax: props[0].data ? props[0].data.tax : "",
      password: props[0].data ? props[0].data.password : "",
      rePassx: props[0].data ? props[0].data.rePassx : "",
      representative: props[0].data ? props[0].data.representative : "",
      captcha: ""
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required(t('require')),
      phone: Yup.string()
        .required(t('require')),
      email: Yup.string()
        .email(t('register.invalidEmail'))
        .required(t('require')),
      password: Yup.string()
        .min(6, t('numberCharOfPassword'))
        .required(t('require')),
      rePassx: Yup.string()
        .oneOf([Yup.ref('password'), null], t('noMatching'))
        .required(t('require')),
      captcha: Yup.string()
        .required(t('require')),
    }),
    onSubmit: async (values) => {
      handleSubmit(values)
    },
  });

  const hideAlert = () => {
    formik.setFieldValue("captcha", "")
    setAlert(null)
  }

  const onLogIn = () => {
    const data = {
      name: formik.getFieldProps("name").value,
      address: formik.getFieldProps("address").value,
      phone: formik.getFieldProps("phone").value,
      email: formik.getFieldProps("email").value,
      tax: formik.getFieldProps("tax").value,
      password: formik.getFieldProps("password").value,
      rePassx: formik.getFieldProps("rePassx").value,
      representative: formik.getFieldProps("representative").value,
    }
    props[0].backHandler()
    props[0].setData(data)
  }

  const handleSubmit = async (values) => {
    setLoading(
      <div className="paddingCard">
        <div className="text-center">
          <img src={IMG_LOADING} style={{ height: "150px" }} alt="loading" />
          <div className="font-weight-bold font-size-lg mt-4 text-black">{props[0].t('processing')}</div>
          <p className="mb-0 mt-2 text-muted text-black">Vui lòng chờ trong giây lát ...</p>
        </div>
      </div>)

    let newValue = values;
    const data = {
      "email": values.email,
      "name": values.name,
      "pw_hash": values.password,
      "address": values.address,
      "phone": values.phone ? values.phone : "",
      "username": "",
      "type": "M",
      "network": "88888",
      "captcha": values.captcha,
      "field": props[0].dataStep1,
      "numberOfStampUnix": "",
      "stampBrand": false,
      "representation": values.representative,
      "tax": values.tax
    }
    const path_get = await axios.get(LINK_API.GET_PATH_USER);
    const pathCount = path_get.data.data.countMasterAccount.toString()

    let wallet = ethers.Wallet.createRandom();
    let randomMnemonic = wallet.mnemonic;
    let walletPath = {
      "standard": "m/44'/60'/0'/" + pathCount + "/0",
    };
    let hdnode = ethers.utils.HDNode.fromMnemonic(randomMnemonic);
    let node = hdnode.derivePath(walletPath.standard);

    const accountAddress = node.address;
    const privatekey = node.privateKey;
    const privatekey_slice = privatekey.substring(2, privatekey.length)
    //console.log("randomMnemonic", randomMnemonic)
    // console.log("pass", data.pw_hash)
    const encryptedSeed = await CryptoJS.AES.encrypt(randomMnemonic, data.pw_hash);
    //console.log("encryptedSeed", encryptedSeed)
    const encryptedPrivateKey = await CryptoJS.AES.encrypt(privatekey, data.pw_hash);
    //console.log("encryptedPrivateKey", encryptedPrivateKey)
    var temp = new Buffer(data.pw_hash).toString('hex')
    const encryptedPass = await ethers.utils.keccak256('0x' + temp);
    data.password = data.pw_hash //password
    data.pw_hash = encryptedPass; //pass hash
    data.privateEncrypted = encryptedPrivateKey.toString(); // encrypted privatekey
    data.seedEncrypted = encryptedSeed.toString(); // encrypted seed
    data.bcAddress = accountAddress; // address of account on bc
    data.currentIndexPath = pathCount; // index path of account
    //console.log("Checkdown")
    axios.post(LINK_API.REGISTER_TRIAL, data)
      .then(async response => {
        if (response.data.errorCode === 1) {
          const Buffer_privatekey = Buffer.from(privatekey_slice.toString(), 'hex');
          const tx = new EthereumTx(response.data.data.raw, { chain: 4 });
          await tx.sign(Buffer_privatekey);
          const rawTx = '0x' + tx.serialize().toString('hex');
          const raw_to_send = {
            ...data,
            "raw": rawTx,
            "sign": "none",
            "date": "none",
            "bcAddress": accountAddress,
            "uuid": response.data.data.uuid,
            "nonce": response.data.data.nonce,
            "creator_nonce": response.data.data.creator_nonce
          }
          axios.post(LINK_API.SEND_RAW, raw_to_send)
            .then(response => {
              setLoading(null)
              if (response.data.errorCode === 1) {
                //to do success
                newValue.success = true
                props[0].nextHandler()
                props[0].setData(newValue)
              } else {
                newValue.success = false
                props[0].nextHandler()
                props[0].setData(newValue)
              }
            })
            .catch(err => {
              setLoading(null)
              newValue.success = false
              props[0].nextHandler()
              props[0].setData(newValue)
            })
        } else {
          setLoading(null)
          newValue.success = false
          props[0].nextHandler()
          props[0].setData(newValue)
        }
      })
      .catch(err => {
        setLoading(null)
        if (err.response.data.name === "EMAIL_EXIST") {
          let ChildComponent = <div>{t('register.existedEmail')}</div>
          setAlert(
            <ConfirmAlert
              hideCancel={true}
              onClose={hideAlert}
              onConfirm={hideAlert}
              confirmBtnText={t('confirm')}
              child={ChildComponent}
              color="warning"
            />
          )
        }

        if (err.response.data.name === "PHONE_EXIST") {
          let ChildComponent = <div>{t('register.existedPhone')}</div>
          setAlert(
            <ConfirmAlert
              hideCancel={true}
              onClose={hideAlert}
              onConfirm={hideAlert}
              confirmBtnText={t('confirm')}
              child={ChildComponent}
              color="warning"
            />
          )
        }

      })



  }



  const handleSelectSuggest = (geocodedPrediction) => {
    formik.setFieldValue("address", geocodedPrediction.formatted_address)
    formik.setFieldValue("search", "")
  }

  const handleNoResult = () => {
    console.log('Không có kết quả')
  }

  const handleChangeAddress = name => event => {
    let values = event.target.value;
    if (values) {
      formik.setFieldValue("search", values);
      formik.setFieldValue("address", values, true);
    } else {
      formik.setFieldValue("search", values);
      formik.setFieldValue("address", values, true);
      formik.setFieldError("address", t('require'))
    }

  }


  return (
    <>
      {
        loading ? loading :

          <div className="paddingCard">
            <h5 className="font-size-xl font-weight-bold">
              {t('register.busiInfo')}
            </h5>
            <p className="text-black-50 mb-4">
              {t('register.basicInfox')}
            </p>

            <form onSubmit={formik.handleSubmit}>
              <div >
                <p className="text-black-50 mb-4"></p>
                <div className="form-row ">
                  <div className="form-group col-md-6 pr-3">

                    <div className="form-group">
                      <label className="font-weight-bold">{t('account.name')} <span className="text-danger">(*)</span></label>
                      <Input
                        placeholder={t('account.name')}
                        type="text"
                        name="name"
                        value={formik.values.name}
                        valid={formik.touched.name && formik.errors.name ? false : (formik.values.name ? true : false)}
                        invalid={formik.touched.name && formik.errors.name ? true : false}
                        {...formik.getFieldProps('name')}
                      />
                      {formik.touched.name && formik.errors.name ? (
                        <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.name}</p>
                      ) : null}
                    </div>


                    <div className="form-group">
                      <label className="font-weight-bold">{t('register.email')} <span className="text-danger">(*)</span></label>
                      <Input
                        placeholder={t('register.email')}
                        type="text"
                        name="email"
                        value={formik.values.email}
                        valid={formik.touched.email && formik.errors.email ? false : (formik.values.email ? true : false)}
                        invalid={formik.touched.email && formik.errors.email ? true : false}
                        {...formik.getFieldProps('email')}
                      />
                      {formik.touched.email && formik.errors.email ? (
                        <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.email}</p>
                      ) : null}
                    </div>


                    <div className="form-group">
                      <label className="font-weight-bold">{t('register.password')} <span className="text-danger">(*)</span></label>
                      <Input
                        placeholder={t('register.password')}
                        type="password"
                        name="password"
                        value={formik.values.password}
                        valid={formik.touched.password && formik.errors.password ? false : (formik.values.password ? true : false)}
                        invalid={formik.touched.password && formik.errors.password ? true : false}
                        {...formik.getFieldProps('password')}
                      />
                      {formik.touched.password && formik.errors.password ? (
                        <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.password}</p>
                      ) : null}
                    </div>

                    <div className="form-group">
                      <label className="font-weight-bold">{t('register.rePassx')} <span className="text-danger">(*)</span></label>
                      <Input
                        placeholder={t('register.rePassx')}
                        type="password"
                        name="rePassx"
                        value={formik.values.rePassx}
                        valid={formik.touched.rePassx && formik.errors.rePassx ? false : (formik.values.rePassx ? true : false)}
                        invalid={formik.touched.rePassx && formik.errors.rePassx ? true : false}
                        {...formik.getFieldProps('rePassx')}
                      />
                      {formik.touched.rePassx && formik.errors.rePassx ? (
                        <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.rePassx}</p>
                      ) : null}
                    </div>

                    <div className="form-group responseCompulsory ">
                      <p><span className="text-danger">(*)</span> Trường thông tin bắt buộc</p>
                    </div>
                  </div>

                  <div className="form-group col-md-6 pr-3">
                    <div className="form-group">
                      <label className="font-weight-bold">{t('phone')} <span className="text-danger">(*)</span></label>
                      <PhoneInput
                        // style={{width: "100%", height: "40px"}}
                        country={'vn'}
                        onlyCountries={['vn']}
                        value={formik.values.phone}
                        valid={formik.touched.phone && formik.errors.phone ? false : (formik.values.phone ? true : false)}
                        invalid={formik.touched.phone && formik.errors.phone ? true : false}
                        {...formik.getFieldProps('phone')}
                        onChange={(phone) => formik.setFieldValue("phone", phone)}
                      />
                    </div>

                    <div className="form-group">
                      <label className="font-weight-bold">{t('product.address')}</label>
                      <ReactGoogleMapLoader
                        params={{
                          key: MY_API_KEY,
                          libraries: "places,geocode",
                        }}
                        render={googleMaps =>
                          googleMaps && (
                            <div>
                              <ReactGooglePlacesSuggest
                                autocompletionRequest={{ input: formik.values.search }}
                                googleMaps={googleMaps}
                                onSelectSuggest={handleSelectSuggest}
                                displayPoweredByGoogle={false}
                                onNoResult={handleNoResult}

                              >
                                <Input
                                  placeholder={t('product.address')}
                                  onChange={handleChangeAddress('address')}
                                  type="text"
                                  name="address"
                                  value={formik.values.address}
                                  valid={formik.touched.address && formik.errors.address ? false : (formik.values.address ? true : false)}
                                  invalid={formik.touched.address && formik.errors.address ? true : false}
                                // {...formik.getFieldProps('address')}
                                />
                              </ReactGooglePlacesSuggest>
                            </div>
                          )
                        }
                      />
                    </div>

                    <div className="form-group">
                      <label className="font-weight-bold">{t('product.tax')}</label>
                      <Input
                        placeholder={t('product.tax')}
                        type="text"
                        name="name"
                        {...formik.getFieldProps('tax')}
                      />
                    </div>
                    <div className="form-group">
                      <label className="font-weight-bold">{t('register.representative')}</label>
                      <Input
                        placeholder={t('register.representative')}
                        type="text"
                        name="name"
                        {...formik.getFieldProps('representative')}
                      />
                    </div>

                    <div className="form-group responseCompulsory2 ">
                      <p><span className="text-danger">(*)</span> Trường thông tin bắt buộc</p>
                    </div>

                    <div className="form-group">
                      <ReCAPTCHA
                        className="styleCaptcha g-recaptcha"
                        value={formik.values.captcha}
                        valid={formik.touched.captcha && formik.errors.captcha ? false : (formik.values.captcha ? true : false)}
                        invalid={formik.touched.captcha && formik.errors.captcha ? true : false}
                        {...formik.getFieldProps('captcha')}

                        sitekey={SITE_KEY}
                        onChange={(value) => formik.setFieldValue("captcha", value)}

                      />

                      {formik.touched.captcha && formik.errors.captcha ? (
                        <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.captcha}</p>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <Row justify="flex-end">
                <Col xs={6} alignItems="flex-end" style={{ textAlign: "start" }}>
                  <div style={{ width: "100%", height: "100%", textAlign: "start" }}>
                    <Button color="info" outline onClick={onLogIn}>{t('register.back')}</Button>
                  </div>
                </Col>
                <Col xs={6} alignItems="flex-end" style={{ textAlign: "end" }}>
                  <div style={{ width: "100%", height: "100%", textAlign: "end" }}>
                    <Button disabled={false} color="info" type="submit">{t('register.continue')}</Button>
                  </div>
                </Col>
              </Row>
            </form>
            {alert}
          </div>
      }
    </>
  )

}


const mapDispatchToProps = (dispatch) => {
  return {
    saveUserInfo: (data, password) => dispatch({ type: "SAVE_USER_INFO", data: data, password: password }),
    cleanStore: () => dispatch({ type: "CLEAN_STORE" }),
  }
}
const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
  }
}

export default withTranslation('common')(withRouter(connect(mapStateToProps, mapDispatchToProps)(Step2)));
