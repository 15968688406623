import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import axios from 'axios';
import Loading from "components/alert/loading";
import { LINK_API } from 'constants/API.js';
import CalenderIcon from "mdi-react/CalendarIcon";
import ExportIcon from "mdi-react/DownloadIcon";
import moment from "moment";
import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import Select from 'react-select';
import { Button, Card, FormGroup, Container } from "reactstrap";
import ActivatedStampReport from "./activated-stamp-report";
import ActivityReport from "./activity-report";
import {
  valueDataSheet_activatedStamp, valueDataSheet_activity,
  valueDataSheet_diary, valueDataSheet_harvest, valueDataSheet_scannedStamp, valueDataSheet_product,
  valueDataSheet_printStamp,
  valueDataSheet_productType
} from "./dataExport";
import DiaryWithObject from "./diary-with-object-report";
import HarvestWithObject from "./harvest-with-object-report";
import ScanStampReport from "./scan-stamp-report";
import PrintManagerment from "./print-managerment";
import DatePicker, { registerLocale } from "react-datepicker";
import el from "components/date-fns/locale/vi";
import Header from "components/Headers/Header"
import ProductPage from "./product-list"
registerLocale("el", el);

function Harvest(props) {

  const {
    userInfo
  } = props;

  const [selectedReport, setSelectedReport] = React.useState(null)
  const [viewReport, setViewReport] = React.useState(null)
  const [exportReportData, setExportReportData] = React.useState(null)
  const [dataSheet, setDataSheet] = React.useState([])
  const [valueDataSheet, setValueDataSheet] = React.useState([])
  const [nameOfFile, setNameOfFile] = React.useState("")
  const [alert, setAlert] = React.useState(null)

  const listReport = [
    {
      value: 1,
      label: "Báo cáo nhật ký hành động",
      url: "GET_ALL_ACTIVITY",
      dataSheetList: valueDataSheet_activity
    },
    {
      value: 2,
      label: "Báo cáo nhật ký quét tem",
      url: "GET_ALL_SCAN_STAMP",
      dataSheetList: valueDataSheet_scannedStamp
    },
    {
      value: 3,
      label: "Báo cáo số lượng tem kích hoạt",
      url: "GET_ACTIVATED_STAMP",
      dataSheetList: valueDataSheet_activatedStamp
    },
    {
      value: 4,
      label: "Báo cáo đợt thu hoạch theo hồ sơ truy xuất",
      url: "GET_ALL_HARVEST",
      dataSheetList: valueDataSheet_harvest
    },
    {
      value: 5,
      label: "Báo cáo nhật ký chăm sóc",
      url: "GET_ALL_DIARY_STATISTIC",
      dataSheetList: valueDataSheet_diary
    },
    {
      value: 6,
      label: "Báo cáo số lượng sản phẩm",
      url: "CREATE_PRODUCT",
      dataSheetList: valueDataSheet_product
    },
    {
      value: 7,
      label: "Báo cáo in ấn",
      url: "CREATE_PRINT_STAMP",
      dataSheetList: valueDataSheet_printStamp
    },
    {
      value: 8,
      label: "Danh sách sản phẩm",
      url: "EXPORT_PRODUCTTYPE",
      dataSheetList: valueDataSheet_productType
    }
  ]

  const [startDate, setStartDate] = React.useState(new Date(moment().subtract(6, 'months').format("llll")));
  const [endDate, setEndDate] = React.useState(new Date());

  const [startDateSend, setStartDateSend] = React.useState(new Date(moment().subtract(6, 'months').format("llll")));
  const [endDateSend, setEndDateSend] = React.useState(new Date());

  const handleView = () => {
    if (selectedReport) {
      setStartDateSend(startDate)
      setEndDateSend(endDate)
      setExportReportData(0)
      setViewReport(selectedReport.value)
    }
  }

  const ExampleCustomInput = ({ value, onClick }) => (
    <Button block color="neutral-info" size="sm" onClick={onClick}>
      <span className="btn-wrapper--icon">
        <CalenderIcon size={20} />
      </span>
      <span className="btn-wrapper--label">
        {moment(value).format("DD/MM/YYYY")}
      </span>
    </Button>
  );

  let _exporter = () => { };

  const exportData = async () => {
    setAlert(<Loading />)
    let url = "";
    let dataSheetList = "";
    let selectedReportRender = listReport.filter(item => item.value === selectedReport.value);
    url = selectedReport.value === 6 ? LINK_API[selectedReportRender[0].url] :LINK_API[selectedReportRender[0].url].concat('/csv?startTime=', moment(startDate).format("DD/MM/YYYY"), '&endTime=', moment(endDate).format("DD/MM/YYYY"));
    
    
    dataSheetList = selectedReportRender[0].dataSheetList;
    setNameOfFile(selectedReportRender[0].label)

    axios.defaults.headers.common.Authorization = userInfo.token
    let dataReponse = await axios({
      method: 'GET',
      url: url
    })

    if (dataReponse && dataReponse.data && dataReponse.data.data) {
      setDataSheet(dataReponse.data.data)
      setValueDataSheet(dataSheetList)
      _exporter.save();
    }
    setAlert(null)
  }

  return (
    <>
      <Header />
      <Container className='mt--7' fluid>
        <Card className="card-box mb-5">
          <div className="card-header--title px-2 py-3 d-flex flex-row">
            <div className="col-md-5 pr-0">
              <small className="d-block text-uppercase mt-1">{'Xuất báo cáo'}</small>
              <b style={{ fontSize: 15 }}>{"Hỗ trợ quản trị sản xuất cho doanh nghiệp"}</b>
            </div>
          </div>
          <div className="divider"></div>
          <div className="px-4 py-2 d-flex justify-content-center align-items-center w-100">
            <div className="w-100">
              <div className="form-row">
                <div className="form-group col-md-4">
                  <img alt="" width={"80%"} src="https://res.cloudinary.com/agridential/image/upload/v1597682446/AGDImage/Data_report-pana_2_zwc9b1.png" />
                </div>
                <div className="form-group col-md-8 pl-3 mb-0">
                  <div className="form-row mt-3">
                    <div className="form-group col-md-6">
                      <FormGroup>
                        <label className="font-weight-bold">Thời gian bắt đầu</label>
                        <DatePicker className="form-control"
                          selected={startDate}
                          locale="el"
                          onChange={date => {
                            setStartDate(date)
                            handleView()
                          }}
                          customInput={<ExampleCustomInput />}
                        />
                      </FormGroup>
                    </div>
                    <div className="form-group col-md-6">
                      <FormGroup>
                        <label className="font-weight-bold">Thời gian kết thúc</label>
                        <DatePicker className="form-control"
                          selected={endDate}
                          locale="el"
                          onChange={date => {
                            setEndDate(date)
                            handleView()
                          }}
                          customInput={<ExampleCustomInput />}
                        />
                      </FormGroup>
                    </div>

                    <div className="form-group col-md-8">
                      <Select
                        placeholder="Chọn loại báo cáo"
                        options={listReport}
                        value={selectedReport}
                        onChange={(selectedOption) => {
                          setSelectedReport(selectedOption)
                          setStartDateSend(startDate)
                          setEndDateSend(endDate)
                          setExportReportData(0)
                          setViewReport(selectedOption.value)
                        }}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: '0.29rem',
                          borderWidth: 1,
                          colors: {
                            ...theme.colors,
                            primary25: 'rgba(60,68,177,0.15)',
                            primary50: 'rgba(60,68,177,0.15)',
                            primary: '#4FB779'
                          }
                        })}
                      />
                    </div>

                    {/* <div className="form-group col-md-3 mt-auto">
                    <FormGroup>
                      <Button block color="neutral-success" className="px-0" size="sm" onClick={handleView}>
                        <span className="btn-wrapper--icon">
                          Xem báo cáo
                          <ViewIcon className="ml-2" style={{ marginTop: 3, marginBottom: 2 }} size={20} />
                        </span>
                      </Button>
                    </FormGroup>
                  </div> */}
                    <div className="form-group col-md-4 mt-auto">
                      <FormGroup>
                        <Button disabled={!selectedReport} color="info" size="sm" className="w-100" onClick={exportData}>
                          <span className="btn-wrapper--icon">
                            Xuất báo cáo
                          <ExportIcon className="ml-2" style={{ marginTop: 3, marginBottom: 2 }} size={20} />
                          </span>
                        </Button>
                        <ExcelExport
                          data={dataSheet}
                          fileName={nameOfFile}
                          ref={(exporter) => {
                            if (exporter) {
                              _exporter = exporter
                            }
                          }}
                        >
                          {valueDataSheet.map((field) =>
                            (<ExcelExportColumn field={field.value} title={field.name} width={200} />)
                          )}
                        </ExcelExport>
                      </FormGroup>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            {
              viewReport && viewReport === 1 ?
                <ActivityReport startDate={moment(startDateSend).format("DD/MM/YYYY")} endDate={moment(endDateSend).format("DD/MM/YYYY")} exportReportData={exportReportData} />
                :
                viewReport && viewReport === 2 ?
                  <ScanStampReport startDate={moment(startDateSend).format("DD/MM/YYYY")} endDate={moment(endDateSend).format("DD/MM/YYYY")} />
                  :
                  viewReport && viewReport === 3 ?
                    <ActivatedStampReport startDate={moment(startDateSend).format("DD/MM/YYYY")} endDate={moment(endDateSend).format("DD/MM/YYYY")} />
                    :
                    viewReport && viewReport === 4 ?
                      <HarvestWithObject startDate={moment(startDateSend).format("DD/MM/YYYY")} endDate={moment(endDateSend).format("DD/MM/YYYY")} />
                      :
                      viewReport && viewReport === 5 ?
                        <DiaryWithObject startDate={moment(startDateSend).format("DD/MM/YYYY")} endDate={moment(endDateSend).format("DD/MM/YYYY")} />
                        :
                        viewReport && viewReport === 6 ?
                        <ProductPage startDate={moment(startDateSend).format("DD/MM/YYYY")} endDate={moment(endDateSend).format("DD/MM/YYYY")} />
                        :
                        viewReport && viewReport === 7 ?
                          <PrintManagerment startDate={moment(startDateSend).format("DD/MM/YYYY")} endDate={moment(endDateSend).format("DD/MM/YYYY")} />
                          :
                          null

                        
            }
          </div>
          {alert}
        </Card>
      </Container>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo.data
  }
}

const mapDispatchToProps = (dispatch) => ({
});
export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(Harvest))