const valueDataSheet_activity = [
  {
    name: "Ngày thực hiện",
    value: "date"
  },
  {
    name: "Tên công đoạn",
    value: "stepName"
  },
  {
    name: "Thuộc quy trình",
    value: "processName"
  },
  {
    name: "Nhân viên thực hiện",
    value: "actor"
  },
  {
    name: "Sản phẩm thực hiện",
    value: "po"
  },
  {
    name: "Sản phẩm sản xuất",
    value: "pt"
  },
  {
    name: "Vùng nguyên liệu",
    value: "pa"
  }
]

const valueDataSheet_scannedStamp = [
  {
    name: "Mã doanh nghiệp",
    value: "uuid"
  },
  {
    name: "Tên thành viên",
    value: "name"
  },
  {
    name: "Mã truy xuất",
    value: "stampId"
  },
  {
    name: "Thời điểm quét",
    value: "date"
  },
  {
    name: "Vị trí quét",
    value: "location"
  },
  {
    name: "Loại tem",
    value: "type"
  },
  {
    name: "Sản phẩm",
    value: "pt"
  }
]

const valueDataSheet_activatedStamp = [
  {
    name: "Mã doanh nghiệp",
    value: "uuid"
  },
  {
    name: "Tên thành viên",
    value: "name"
  },
  {
    name: "Đợt kích hoạt",
    value: "period"
  },
  {
    name: "Số lượng",
    value: "number"
  },
  {
    name: "Thời điểm",
    value: "date"
  },
  {
    name: "Loại tem",
    value: "type"
  },
  {
    name: "Mã bắt đầu",
    value: "startId"
  },
  {
    name: "Mã kết thúc",
    value: "endId"
  },
  {
    name: "Sản phẩm truy xuất",
    value: "pt"
  }
]

const valueDataSheet_harvest = [
  {
    name: "Mã sản phẩm",
    value: "objectUuid"
  },
  {
    name: "Tên sản phẩm",
    value: "objectName"
  },
  {
    name: "Mã hồ sơ truy xuất",
    value: "cropUuid"
  },
  {
    name: "Trạng thái",
    value: "cropStatus"
  },
  {
    name: "Ngày thu hoạch",
    value: "harvestDate"
  },
  {
    name: "Số lượng",
    value: "harvestQuantity"
  },
  {
    name: "Đơn vị",
    value: "harvestUnit"
  }
]

const valueDataSheet_diary = [
  {
    name: "Mã sản phẩm",
    value: "objectUuid"
  },
  {
    name: "Tên sản phẩm",
    value: "objectName"
  },
  {
    name: "Mã hồ sơ truy xuất",
    value: "cropUuid"
  },
  {
    name: "Trạng thái",
    value: "cropStatus"
  },
  {
    name: "Tên quy trình",
    value: "diaryName"
  },
  {
    name: "Tên bước",
    value: "stepName"
  },
  {
    name: "Ngày thực hiện",
    value: "diaryDate"
  }
]
const valueDataSheet_product = [
  {
    name: "Tên sản phẩm",
    value: "name"
  },
  {
    name: "Mã GTIN",
    value: "uuid"
  },
  {
    name: "Trạng thái",
    value: "status"
  }
]
const valueDataSheet_manu = [
  {
    name: "Tên thành viên",
    value: "name"
  },
  {
    name: "Mã doanh nghiệp",
    value: "uuid"
  },
  {
    name: "Địa chỉ email",
    value: "email"
  },
  {
    name: "Số điện thoại",
    value: "phone"
  }
]
// const valueDataSheet_product = [
//   {
//     Header: 'Tên sản phẩm',
//     accessor: "nameOfProduct",
//     sortable: false
//   },
//   {
//     Header: 'Nhà sản xuất',
//     accessor: "nameOfEnterprise",
//     filterable: false
//   },
//   {
//     Header: 'Địa chỉ',
//     accessor: "address",
//     filterable: false
//   },
// ]

const valueDataSheet_printStamp = [
  {
    name: "Mã thành viên",
    value: "userUuid"
  },
  {
    name: "Tên thành viên",
    value: "username"
  },
  {
    name: "Mã sản phẩm",
    value: "productUuid"
  },
  {
    name: "Tên sản phẩm",
    value: "productName"
  },
  {
    name: "Thời gian in",
    value: "date"
  },
  {
    name: "Số lượng",
    value: "stampNo"
  },
  {
    name: "Tem bắt đầu",
    value: "startId"
  },
  {
    name: "Tem kết thúc",
    value: "endId"
  },
  {
    name: "Tên tem mẫu",
    value: "sampleStampName"
  },
  {
    name: "Loại tem",
    value: "stampType"
  }
]

const valueDataSheet_import = [
  {
    name: "Biển số xe",
    value: "vehicleNo"
  },
  {
    name: "Ngày nhập",
    value: "date"
  },
  {
    name: "Thành viên",
    value: "cooperativeName"
  },
  {
    name: "Mặt hàng",
    value: "productName"
  },
  {
    name: "Số lượng (tấn)",
    value: "quantity"
  },
  {
    name: "Ghi chú",
    value: "detail"
  }
]


const valueDataSheet_systemHistory = [
  {
    name: "Thời gian",
    value: "date"
  },
  {
    name: "Tên hành động",
    value: "step"
  },
  {
    name: "Người thực hiện",
    value: "from_name"
  },
  {
    name: "Miêu tả",
    value: "step_desc"
  }
]

const valueDataSheet_diaryHistory = [
  {
    name: "Mã thành viên",
    value: "uuid"
  },
  {
    name: "Tên thành viên",
    value: "name"
  },
  {
    name: "Bước thực hiện",
    value: "stepName"
  },  
  {
    name: "Ngày thực hiện",
    value: "date"
  },
  {
    name: "Người thực hiện",
    value: "actor"
  },
  {
    name: "Tên sản phẩm",
    value: "po"
  },
  {
    name: "Nhóm sản phẩm",
    value: "pt"
  },
  {
    name: "Tên quy trình",
    value: "process_name"
  },
  {
    name: "Vùng sản xuất",
    value: "pa"
  },
]



export {
  valueDataSheet_activity,
  valueDataSheet_scannedStamp,
  valueDataSheet_activatedStamp,
  valueDataSheet_harvest,
  valueDataSheet_diary,
  valueDataSheet_product,
  valueDataSheet_printStamp,
  valueDataSheet_manu,
  valueDataSheet_import,
  valueDataSheet_systemHistory,
  valueDataSheet_diaryHistory
}

export default {
  valueDataSheet_activity,
  valueDataSheet_scannedStamp,
  valueDataSheet_activatedStamp,
  valueDataSheet_harvest,
  valueDataSheet_diary,
  valueDataSheet_product,
  valueDataSheet_printStamp,
  valueDataSheet_manu,
  valueDataSheet_import,
  valueDataSheet_systemHistory,
  valueDataSheet_diaryHistory
}