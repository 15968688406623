import DropZone from "components/dropZone";
//formik
import { useFormik } from 'formik';
import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Input } from "reactstrap";
//style
import "view/css/select.css";
import * as Yup from 'yup';
import "../../css/index.css";


const CreateEmployee = React.forwardRef((props, ref) => {
    const t = props.t;
    const [listImage, setImage] = React.useState([]);
    const handleAva = (data) => {
        setImage(data);
    }

    const formik = useFormik({
        initialValues: {
            name: '',
            username: '',
            phone: '',
        },
        validationSchema: Yup.object({
            name: Yup.string()
                .required(t('require')),
            username: Yup.string()
                .required(t('require')),
            phone: Yup.number(),
                // .required(t('require')),
        }),
        onSubmit: async (values) => {
            let data = {
                ...values,
               // image: listImage.length > 0 ? listImage[0] : ""
            }
            if(listImage.length > 0 ){
                let path = "https://res.cloudinary.com/";
                let temp = listImage[0];
                let indexOf = temp.indexOf(path);
                if(indexOf < 0){
                    data.avatar = listImage[0];
                }else{
                    data.avatar = "dontChange"
                }
            }else{
                data.avatar = ""
            }
           props.saveData(data, false)
        },
    });

    React.useImperativeHandle(ref, () => ({
        handleMergeData(){
          formik.submitForm()
        }
      }));
    
    const dataStepOne = props.data; 
    React.useEffect(()=>{
        formik.setFieldValue("name", dataStepOne.name)
        formik.setFieldValue("username", dataStepOne.username)
        formik.setFieldValue("password", dataStepOne.password)
        if(dataStepOne.avatar){
            let temp = [];
            temp.push(dataStepOne.avatar.secure_url)
            setImage(temp)
        }
        },[dataStepOne])

    return (
        <>
            <div className="wizard-steps horizontal">
                <form onSubmit={formik.handleSubmit}>
                    <div className="p-4">
                        <h5 className="font-size-xl font-weight-bold">{t('employee.addNewMember')}</h5>
                        <p className="text-black-50 mb-4">{t('employee.accInfo')}</p>
                        <div className="form-row ">
                            <div className="form-group col-md-7 pr-3">
                                <div className="form-group">
                                    <label className="font-weight-bold">{t('employee.name')} <span className="text-danger">(*)</span></label>
                                    <Input
                                        placeholder={t('employee.name')}
                                        type="text"
                                        name="name"
                                        value={formik.values.name}
                                        valid={formik.touched.name && formik.errors.name ? false : (formik.values.name ? true : false)}
                                        invalid={formik.touched.name && formik.errors.name ? true : false}
                                        {...formik.getFieldProps('name')}
                                    />
                                    {formik.touched.name && formik.errors.name ? (
                                        <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.name}</p>
                                    ) : null}
                                </div>

                                <div className="form-group">
                                    <label className="font-weight-bold">{t('employee.username')} <span className="text-danger">(*)</span></label>
                                    <Input
                                        placeholder={t('employee.username')}
                                        type="text"
                                        disabled
                                        name="username"
                                        value={formik.values.username}
                                        valid={formik.touched.username && formik.errors.username ? false : (formik.values.username ? true : false)}
                                        invalid={formik.touched.username && formik.errors.username ? true : false}
                                        {...formik.getFieldProps('username')}

                                    />
                                    {formik.touched.username && formik.errors.username ? (
                                        <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.username}</p>
                                    ) : null}
                                </div>

                                {/* <div className="form-group" >
                                    <label className="font-weight-bold">{t('employee.phone')}</label>
                                    <Input
                                        placeholder={t('employee.phone')}
                                        type="number"
                                        name="phone"
                                        value={formik.values.phone}
                                        valid={formik.touched.phone && formik.errors.phone ? false : (formik.values.phone ? true : false)}
                                        invalid={formik.touched.phone && formik.errors.phone ? true : false}
                                        {...formik.getFieldProps('phone')}
                                    />
                                    {formik.touched.phone && formik.errors.phone ? (
                                        <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.phone}</p>
                                    ) : null}
                                </div> */}
                            </div>
                            <div
                                className="form-group d-flex justify-content-center col-md-5 pl-3"
                            >
                                <div className="form-group">
                                    <label className="font-weight-bold">{t('image')}</label>
                                    <DropZone
                                        max={1}
                                        onConfirm={handleAva}
                                        data={listImage}
                                    />
                                </div>
                            </div>

                        </div>
                        <div className="form-row">

                        </div>

                        <div className="form-row ">

                            <div className="form-group col-md-12">
                                <p><span className="text-danger">(*)</span> Trường thông tin bắt buộc</p>
                            </div>
                        </div>

                    </div>

                </form>
            </div>

        </>
    )
})

const mapStateToProps = (state) => {
    return {
        userInfo: state.userInfo,
        areaList: state.renderArea,
        objectList: state.objectList
    }
}

const mapDispatchToProps = (dispatch) => ({
    createEmployee: (data, image) => dispatch({ type: "CREATE_EMPLOYEE", data: data, avatar: image }),
    renderArea: (page, size) => dispatch({ type: "RENDER_AREA_LIST", page: page, size: size }),
    getObjectList: (page, size) => dispatch({ type: "GET_OBJECT_LIST", page: page, size: size }),
    saveData: (dataOne, check) => dispatch({ type: "SAVE_DATA_EDIT_EMPLOYEE", dataOne: dataOne, check: check}),
});

export default withTranslation('common', {withRef: true})(connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(CreateEmployee));