import {
    GET_BOX_LIST,
    GET_BOX_LIST_SUCCESS,
    GET_BOX_LIST_FAILURE
} from "actions/types"
import axios from 'axios'
import { LINK_API } from 'constants/API.js'
import { call, put, select, takeLatest } from 'redux-saga/effects'
const getToken = (state) => state.userInfo
// Get approve list admin
function* getAdminList() {
    try {
        const tokenInfo = yield select(getToken)
        const response = yield call(fetchAdminList, tokenInfo.data.token)
        const data = response.data;
        let temp = [];
        for (let i = 0; i < data.data.length; i++) {
            temp.push({
                label: data.data[i].ownedBy +  data.data[i].uuidOfObject + ' - ' + data.data[i].name,
                value: data.data[i].ownedBy +  data.data[i].uuidOfObject,
                ownedBy: data.data[i].ownedBy
            })
        }
        console.log('boxList',temp)
        yield put({ type: GET_BOX_LIST_SUCCESS, data: data.data, errorCode: data.errorCode, options: temp })
    } catch (error) {
        yield put({ type: GET_BOX_LIST_FAILURE, payload: '', errorCode: 0 })
    }
}
function fetchAdminList(checktoken) {
    axios.defaults.headers.common.Authorization = checktoken
    return axios({
        method: 'GET',
        url: LINK_API.GET_BOX_LIST
    })
}
const actions = [
    takeLatest(GET_BOX_LIST, getAdminList)
]
export default actions
