import LoadingTable from "components/LoadingTable/index.js";
import Pagination from "components/Pagination/index.jsx";
import { AGD_LOGO } from "constants/image";
import { useFormik } from 'formik';
import { motion } from "framer-motion";
import moment from "moment";
import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ReactTable from 'react-table-6';
import Truncate from 'react-truncate';
import { Button, Card, Col, Container, Row, UncontrolledTooltip } from 'reactstrap';
import * as Yup from 'yup';
import "../../css/reactTable.css";
import "../employObject/css/index.css";
import Header from "components/Headers/Header"
import CheckAllIcon from "mdi-react/EyeIcon"
import { LINK_API } from "constants/API";
import axios from "axios";
import Error from "components/alert/error";
import Success from "components/alert/success";
import CloseIcon from "mdi-react/EyeOffIcon";

function ObjectProductionList(props) {
  const getObjectList = props.getObjectList;
  const getObjectFilter = props.getObjectFilter;
  const [filterStatus, setFilter] = React.useState(false)
  const [dataObjectList, setDataObjectList] = React.useState([])
  const [totalPage, setTotalPage] = React.useState([])
  const [loadingState, setLoading] = React.useState(true)
  const [alert, setAlert] = React.useState(null);
  const t = props.t;

  const handleType = React.useCallback((type) => {
    if (type === 0) return (t('oProduction.row'));
    if (type === 1) return (t('oProduction.tree'));
    if (type === 2) return (t('oProduction.all'));
    if (type === 3) return (t('oProduction.batch'));
    if (type === 4) return (t('oProduction.farm'));
    if (type === 5) return (t('oProduction.house'));
    return "";
  }, [t])

  const formik = useFormik({
    initialValues: {
      name: 'name',
      value: '',
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required(t('require')),
      value: Yup.string()
        .required(t('require')),
    }),
    onSubmit: React.useCallback(async (values) => {
      setFilter(true);
      setLoading(true);
      getObjectFilter(1, values.name, values.value, 5)
    }, []),
  });

  const handleSendRequest = (uuid, status) => {
    const data = {
      "hide": !status,
      "uuidOfProduct": uuid
    }

    axios.defaults.headers.common.Authorization = props.userInfo.data.token
    axios.post(LINK_API.SEND_REQUEST, data)
      .then(res => {
        getAllProduct()
        setAlert(<Success onConfirm={hideAlert} hideCancel={true} confirmBtnText={t('confirm')} />)
      })
      .catch(err => {
        setAlert(<Error onClose={hideAlert} confirmBtnText={t('close')} />)
      })

  }

  const hideAlert = () => {
    setAlert(null)
  }

  const genTable = React.useCallback((data) => {
    let listObject = data.map((prop, index) => {
      return {
        ...prop,
        id: prop.uuid,
        name:
          <div style={{ display: "flex", flexFlow: "row", alignItems: "center" }}>
            <Container className="pl-0">
              <Row>
                <Col lg="3" style={{ textAlign: "start" }}>
                  {(prop.images360) && (prop.images360.length > 0) ? (
                    <img alt="profileAGD" src={prop.images360[0].secure_url} style={{ height: "37px", width: "37px" }} />
                  ) :
                    (prop.image) && (prop.image.length > 0) ? (
                      <img alt="profileAGD" src={prop.image[0].secure_url} style={{ height: "37px", width: "37px" }} />
                    ) :
                      <img alt="profileAGD" src={AGD_LOGO} style={{ height: "37px", width: "37px" }} />}
                </Col >
                <Col lg="9" style={{ fontWeight: 100 }}>
                  <div className="font-weight-bold" style={{ textAlign: "start" }}>
                    <Truncate lines={2} ellipsis={<span>...</span>}>
                      {prop.nameOfProduct}
                    </Truncate>
                  </div>
                  <div style={{ textAlign: "start" }}>
                    {`( Mã: ${prop.uuidOfProduct} )`}
                  </div>
                </Col>
              </Row>
            </Container>
          </div>,
        date: moment(prop.registeredDate).format("DD/MM/YYYY"),
        status: prop.EProductRequestInfo ? "Đã gửi yêu cầu chờ phê duyệt" : prop.hide === false ? "Đang hiển thị trên trang txng" : "Đang bị ẩn trên trang txng",
        area: prop.nameOfZone,
        type: handleType(prop.type),
        actions: <div className="d-flex justify-content-end">
          <>
            {
              !prop.EProductRequestInfo && prop.hide === false ?
                <>
                  <Button onClick={() => handleSendRequest(prop.uuidOfProduct, prop.hide)} className="mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center" id={"productDeactive" + index}>
                    <span className="btn-wrapper--icon d-flex">
                      <CloseIcon
                        size={18}
                        className="font-size-sm text-danger"
                      />
                    </span>
                  </Button>
                  <UncontrolledTooltip trigger="hover" popperClassName="tooltip-black" placement="top" container="body" target={"productDeactive" + index}>
                    Gửi yêu cầu ẩn sản phẩm trên trang truy xuất nguồn gốc
                  </UncontrolledTooltip>
                </>
                :
                null
            }
            {
              !prop.EProductRequestInfo && prop.hide === true ?
                <>
                  <Button onClick={() => handleSendRequest(prop.uuidOfProduct, prop.hide)} className="mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center" id={"productDeactive" + index}>
                    <span className="btn-wrapper--icon d-flex">
                      <CheckAllIcon
                        size={18}
                        className="font-size-sm text-success"
                      />
                    </span>
                  </Button>
                  <UncontrolledTooltip trigger="hover" popperClassName="tooltip-black" placement="top" container="body" target={"productDeactive" + index}>
                    Gửi yêu cầu hiển thị trên trang truy xuất nguồn gốc
                  </UncontrolledTooltip>
                </>
                :
                null
            }


          </>
        </div>
      }
    })
    setDataObjectList(listObject)
    setLoading(false)
  }, [t, handleType])

  useEffect(() => {
    getObjectList(1, 5)
  }, [getObjectList]);

  const objectList = props.objectList.data
  const totalPageProps = props.objectList.totalPage
  useEffect(() => {
    getAllProduct()
  }, []);


  const getAllProduct = () => {
    axios.defaults.headers.common.Authorization = props.userInfo.data.token;
    axios.get(LINK_API.GET_ALL_PRODUCT_ACTIVE_MAU)
      .then(res => {
        if (res.data && res.data.data) {
          genTable(res.data.data)
        }
      })
      .catch(err => {
        genTable([])
        setTotalPage(0)
      })
  }
  const columns = React.useMemo(
    () => [
      {
        Header: <div style={{
          textAlign: "left"
        }}>
          {t('oProduction.name')}
        </div>,
        minWidth: 107,
        accessor: "name",
        filterable: false
      },
      {
        Header: "Nhóm sản phẩm",
        accessor: "nameOfProduct",
        filterable: false,
        resizable: true,
      },
      {
        Header: "Trạng thái",
        accessor: "status",
        filterable: false,
        resizable: true,
      },
      {
        Header: <div className="pl-4" style={{
          textAlign: "right"
        }}>
          {t('actions')}
        </div>,
        accessor: "actions",
        filterable: false
      },
    ],
    [t]
  );

  return (
    <>
      <motion.div
        className="w-100"
        initial={{ x: -100 }}
        animate={{ x: 0 }}
        transition={{ ease: "easeOut", duration: 0.3 }}
      >
        <Header />
        <Container className='mt--7' fluid>
          <Card className="card-box mb-5">
            <div className="card-header py-3 d-flex justify-content-between">
              <div className=" font-weight-bold card-header--title font-size-lg">{"Danh sách sản phẩm đã kích hoạt"}</div>
            </div>
            <div className="divider" />
            <div className="card-body pt-2 px-4 pb-4">
              <ReactTable
                noDataText={t('noData')}
                data={dataObjectList}
                columns={columns}
                previousText={"<"}
                nextText={">"}
                rowsText={t('row')}
                ofText="/"
                loading={loadingState}
                LoadingComponent={LoadingTable}
                defaultPageSize={5}
                showPaginationBottom={true}
                sortable={false}
                resizable={true}
                PaginationComponent={Pagination}
                className="-striped -highlight"
              />
            </div>
          </Card>
        </Container>
        {alert}
      </motion.div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
    userProfile: state.userProfile.data,
    objectList: state.objectList,
    permission: state.permission.data
  }
}

const mapDispatchToProps = (dispatch) => ({
  getObjectList: (page, size) => dispatch({ type: "GET_OBJECT_LIST", page: page, size: size }),
  getObjectFilter: (page, name, value, size) => dispatch({ type: "GET_OBJECT_PRODUCTION_FILTER", page: page, name: name, value: value, size: size })
});

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(ObjectProductionList));