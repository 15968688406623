import axios from "axios";
import Error from 'components/alert/error';
import Loading from 'components/alert/loading';
import Success from 'components/alert/success';
import DropZone from "components/dropZone";
import Header from "components/Headers/Header";
import { LINK_API } from "constants/API";
//formik
import { useFormik } from 'formik';
import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Button, Card, Container, CustomInput, Input } from "reactstrap";
//style
import "view/css/select.css";
import * as Yup from 'yup';
import Select from "react-select"
const ethers = require('ethers');

const CreateEmployee = (props) => {
    const t = props.t;
    const passwordRandom = Math.floor(100000 + Math.random() * 900000);
    const [alert, setAlert] = React.useState(null)
    const [listImage, setImage] = React.useState([])
    const [listFarmer, setList] = React.useState([])
    const [listBox, setBox] = React.useState([])
    const farmerList = props.farmerList
    const getShipList = props.getShipList;
    const boxList = props.boxList;
    const [boxSelected, setSelected] = React.useState('')
    React.useEffect(() => {
        if(boxList){
            setBox(boxList)
        }
    }, [boxList])
    React.useEffect(() => {
        getShipList();
    }, [getShipList])
    React.useEffect(() => {
        if (farmerList) {
            setList(farmerList)
        }
    }, [farmerList])

    const formik = useFormik({
        initialValues: {
            id: '',
            farmer: '',
            // type: ''
        },
        validationSchema: Yup.object({
            id: Yup.string()
                .required(t('require')),
            farmer: Yup.string()
                .required(t('require')),
        }),
        onSubmit: async (values) => {
            setAlert(<Loading />);


            let data_signIn =
            {
                "uuidOfObject": values.id.substring(7, values.id.length),
                "uuidOfFarmer": values.farmer,
                "owner": values.id.substring(0, 7),

            }
            axios.post(LINK_API.APPROVE_FARMER, data_signIn)
                .then(res => {
                    if (res.data.errorCode === 1) {
                        setAlert(<Success onClose={hideAlert} hideCancel={true} onConfirm={reloadData} confirmBtnText={t('close')} />)

                    } else {
                        console.log(res.data.message)
                        setAlert(<Error onClose={hideAlert} title={res.data.message} confirmBtnText="Đóng" />)
                    }
                })
                .catch(err => {
                    console.log('Check')
                    console.log(err.response)
                    setAlert(<Error onClose={hideAlert} title={err.response.data.message} confirmBtnText="Đóng" />)
                })

        },
    });
    const getIdOwner = async (id) => {

        let owner = id.substring(0, 7)
        let idObject = id.substring(7, id.length)
        let responseData = await axios.get(LINK_API.GET_CROP_LIST + idObject + '&ownedBy=' + owner);
        let dataRes = responseData.data.data;
        // if(dataRes.length > 0){
        //     let uuid
        // }else{

        // }
        let uuid = dataRes[0].uuid;
        return uuid
    }
    const reloadData = () => {
        props.history.push('/wholesaler/list');
    }
    const hideAlert = () => {
        setAlert(null)
    }


    return (
        <>
            <Header></Header>
            <Container className=' mt--7' fluid>
                <Card className="card-box mb-5">
                    <div className="wizard-steps horizontal">
                        <form onSubmit={formik.handleSubmit}>
                            <div className="p-4">
                                <h5 className="font-size-xl font-weight-bold">Cấp quyền</h5>
                                <p className="text-black-50 mb-4">Cấp quyền cho nông hộ</p>
                                <div className="form-row ">
                                    <div className="form-group col-md-12 pr-3">
                                        <div className="form-group">
                                            <label className="font-weight-bold">Mã sản phẩm <span className="text-danger">(*)</span></label>
                                            {/* <Input
                                                placeholder='Mã sản phẩm'
                                                type="text"
                                                name="id"
                                                value={formik.values.id}
                                                valid={formik.touched.id && formik.errors.id ? false : (formik.values.id ? true : false)}
                                                invalid={formik.touched.id && formik.errors.id ? true : false}
                                                {...formik.getFieldProps('id')}
                                            /> */}
                                            {/* <CustomInput
                                                placeholder='Mã sản phẩm'
                                                type="select"
                                                name="id"
                                                value={formik.values.id}
                                                valid={formik.touched.id && formik.errors.id ? false : (formik.values.id ? true : false)}
                                                invalid={formik.touched.id && formik.errors.id ? true : false}
                                                {...formik.getFieldProps('id')}
                                            >
                                                {listBox.map((item, idx)=>(
                                                    <option value={item.value}>{item.label}</option>
                                                ))}
                                            </CustomInput> */}

                                            <Select
                                                placeholder="Chọn mã sản phẩm"
                                                options={listBox}
                                                value={boxSelected}
                                                onChange={(selectedOption) => {
                                                    console.log('selectedOption', selectedOption)
                                                    setSelected(selectedOption);
                                                    formik.setFieldValue('id', selectedOption.value)
                                                }}
                                                theme={(theme) => ({
                                                    ...theme,
                                                    borderRadius: '0.29rem',
                                                    borderWidth: 0.5,
                                                    height: 30,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary25: 'rgba(60,68,177,0.15)',
                                                        primary50: 'rgba(60,68,177,0.15)',
                                                        primary: '#50b77a'
                                                    }
                                                })}
                                            />
                                            {formik.touched.id && formik.errors.id ? (
                                                <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.id}</p>
                                            ) : null}
                                        </div>
                                        <div className="form-group">
                                            <label className="font-weight-bold">Nông hộ được cấp quyền<span className="text-danger">(*)</span></label>
                                            <CustomInput
                                                type="select"
                                                name="customSelect"
                                                id="selectType"
                                                placeholder='Nhà phân phối'
                                                value={formik.values.farmer}
                                                valid={formik.touched.farmer && formik.errors.farmer ? false : (formik.values.farmer ? true : false)}
                                                invalid={formik.touched.farmer && formik.errors.farmer ? true : false}
                                                onChange={formik.handleChange}
                                                {...formik.getFieldProps('farmer')}
                                            >
                                                <option disabled value=''>Chọn nông hộ</option>
                                                {listFarmer.map((item, idx) => (
                                                    <option key={idx} value={item.value}>{item.label}</option>
                                                ))}
                                            </CustomInput>
                                            {formik.touched.famer && formik.errors.famrer ? (
                                                <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.famrer}</p>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                <div className="form-row ">
                                    <div className="form-group col-md-6">
                                        <p><span className="text-danger">(*)</span> Trường thông tin bắt buộc</p>
                                    </div>

                                    <div className="form-group col-md-6 d-flex justify-content-end">
                                        <Button
                                            size="lg"
                                            type="submit"
                                            className="text-uppercase font-weight-bold font-size-sm"
                                            color="info">
                                            Tạo mới
                                </Button>
                                    </div>
                                </div>

                            </div>

                        </form>
                    </div>
                </Card>
            </Container>
            {alert}
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.userInfo,
        areaList: state.renderArea,
        objectList: state.objectList,
        farmerList: state.farmerList.options,
        boxList: state.boxList.options
    }
}

const mapDispatchToProps = (dispatch) => ({
    createEmployee: (data, image) => dispatch({ type: "CREATE_EMPLOYEE", data: data, avatar: image }),
    renderArea: (page, size) => dispatch({ type: "RENDER_AREA_LIST", page: page, size: size }),
    getObjectList: (page, size) => dispatch({ type: "GET_OBJECT_LIST", page: page, size: size }),
    getShipList: () => dispatch({ type: "GET_FARMER_LIST" })

});

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(CreateEmployee));