import Axios from "axios";
import Confirm from "components/alert/confirmAlert";
import Error from "components/alert/error";
import Loading from "components/alert/loading";
import Success from "components/alert/success";
import { LINK_API } from "constants/API";
import Switch from 'rc-switch';
import React from "react";
import { BlockPicker } from 'react-color';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { Button, Input } from "reactstrap";
import "view/css/select.css";
function Setting(props) {
    const t = props.t;
    const settingList = props.settingList;
    const [nameRep, setName] = React.useState("")
    const [alert, setAlert] = React.useState(null)
    const [colorValue, setColor] = React.useState('#4fb779')
    const handleChange = name => async event => {
        setName(event.target.value)
    }
    const [checkedName, setCheckedName] = React.useState(false);
    const toggleName = () => {
        setReset(true)
        setCheckedName(!checkedName)
    };
    const [checkedTime, setCheckedTime] = React.useState(false);
    const toggleTime = () => {
        setReset(true)
        setCheckedTime(!checkedTime)
    };
    const [checkedProcess, setCheckedProcess] = React.useState(false);
    const toggleProcess = () => {
        setReset(true)
        setCheckedProcess(!checkedProcess)
    };
    const [checkedMap, setCheckedMap] = React.useState(false);
    const toggleMap = () => {
        setReset(true)
        setCheckedMap(!checkedMap)
    };
    const [checkedCollapse, setCheckedCollapse] = React.useState(false);
    const toggleCollapse = () => {
        setReset(true)
        setCheckedCollapse(!checkedCollapse)
    };
    React.useEffect(() => {
        if (settingList) {
            setCheckedTime(settingList.showAlterName);
            setCheckedMap(settingList.satelliteImagery);
            setCheckedTime(settingList.showTimeDiary)
            setCheckedProcess(settingList.showDetailBizProcess);
            setName(settingList.alternativeName)
            setCheckedCollapse(settingList.isCollapse)
            setColor(settingList.colorCode)
        }
    }, [settingList])
    const [resetState, setReset] = React.useState(false);
    const handleWarningDefault = () => {
        setAlert(<Confirm 
            child={"Đặt các thiết lập về mặc định ?"}
            cancleBtnText={t('no')}
            confirmBtnText={t("yes")}
            onConfirm = {handleDefault}
            onClose= {hideAlert}
            />)
    }
    const handleDefault = () => {
           setReset(true);
           setAlert(<Loading />)
           let data = {
               alternativeName: '',
               rowOfPage: 5,
               satelliteImagery: false,
               showAlterName: false,
               showDetailBizProcess: false,
               showTimeDiary: false,
               isCollapse: false,
               colorCode: '#4fb779'
           }
           Axios.post(LINK_API.POST_THIETLAP, data)
               .then(res => {
                   if (res.data.errorCode === 1) {
                       setAlert(<Success onConfirm={handleConfirm} hideCancel={true} confirmBtnText={t('confirm')} />)
                   } else {
                       setAlert(<Error onClose={hideAlert} confirmBtnText={t('back')} />)
                   }
               })
               .catch(err => {
                   setAlert(<Error onClose={hideAlert} confirmBtnText={t('back')} />)
               })
    }
    const handleReset = () => {
        if (settingList) {
            setCheckedTime(settingList.showAlterName);
            setCheckedMap(settingList.satelliteImagery);
            setCheckedTime(settingList.showTimeDiary)
            setCheckedProcess(settingList.showDetailBizProcess);
            setName(settingList.alternativeName)
            setCheckedCollapse(settingList.isCollapse)
            setColor(settingList.colorCode)
        }
        setReset(false);
    }
    const hideAlert = () => {
        setAlert(null)
    }
    const handleConfirm = () => {
        hideAlert();
        props.getSetting();
        setReset(false)
    }
    const handleWarning  = () => {
        setAlert(<Confirm 
            child={"Lưu lại thông tin đã thay đổi ?"}
            cancleBtnText={t('no')}
            confirmBtnText={t("yes")}
            onConfirm = {handleSave}
            onClose= {hideAlert}
            />)
    }
    const handleSave = () => {
        setAlert(<Loading />)
        let data = {
            alternativeName: nameRep,
            rowOfPage: 5,
            satelliteImagery: checkedMap,
            showAlterName: checkedName,
            showDetailBizProcess: checkedProcess,
            showTimeDiary: checkedTime,
            isCollapse: checkedCollapse,
            colorCode: colorValue
        }
        Axios.post(LINK_API.POST_THIETLAP, data)
            .then(res => {
                if (res.data.errorCode === 1) {
                    setAlert(<Success onConfirm={handleConfirm} hideCancel={true} confirmBtnText={t('confirm')} />)
                } else {
                    setAlert(<Error onClose={hideAlert} confirmBtnText={t('back')} />)
                }
            })
            .catch(err => {
                setAlert(<Error onClose={hideAlert} confirmBtnText={t('back')} />)
            })
    }
    const handleChangeColor = (color) => {
        setReset(true)
        setColor(color.hex)
    }
    return (
        <>
            <div className="p-4">
                <div className="form-row">
                    <div className="col-md-6 text-left">
                        <h5 className="font-size-xl font-weight-bold">{t('profile.setting')}</h5>
                        <p className="text-black-50 mb-0">{t('profile.aboutSetting')}</p>
                    </div>
                    <div className="col-md-6 justify-content-end d-flex flex-row">
                        <div>
                            <Button color="danger" onClick={handleWarningDefault}>Mặc định</Button>
                        </div>
                        {resetState ? <div className='d-flex flex-row'>
                            <div>
                                <Button outline color="danger" onClick={handleReset} className='ml-2'>{t('reset')}</Button>
                            </div>
                            <div>
                                <Button color="info" className="ml-2" onClick={handleWarning}>{t('save')}</Button>
                            </div>
                        </div> : null}
                    </div>

                </div>

                <div className="divider my-4" />
                <div className="m-2 d-flex flex-column">
                    <label className='font-weight-bold'>Chọn màu sắc cho trang truy xuất</label>
                    <BlockPicker 
                    colors={['#4fb779', '#F47373', '#697689', '#37D67A', '#2CCCE4', '#555555', '#dce775', '#ff8a65', '#ba68c8', '#079992']}
                    color={colorValue}
                    onChangeComplete={handleChangeColor}
                    />
                </div>
                <div className="m-2 form-row">
                    <Switch checked={checkedTime} onClick={toggleTime} className="switch-small toggle-switch-success"></Switch>
                    <p className="font-weight-bold ml-2">{t(`profile.timeDiary`)}</p>
                </div>
                <div className="m-2 form-row">
                    <Switch checked={checkedProcess} onClick={toggleProcess} className="switch-small toggle-switch-success"></Switch>
                    <p className="font-weight-bold ml-2">{t(`profile.bizProcess`)}</p>
                </div>
                <div className="m-2 form-row">
                    <Switch checked={checkedMap} onClick={toggleMap} className="switch-small toggle-switch-success"></Switch>
                    <p className="font-weight-bold ml-2">{t(`profile.map`)}</p>
                </div>
                <div className="m-2 form-row">
                    <Switch checked={checkedCollapse} onClick={toggleCollapse} className="switch-small toggle-switch-success"></Switch>
                    <p className="font-weight-bold ml-2">{t(`profile.collapse`)}</p>
                </div>
                <div className="m-2 form-row">
                    <Switch checked={checkedName} onClick={toggleName} className="switch-small toggle-switch-success"></Switch>
                    <p className="font-weight-bold ml-2">{t(`profile.replaceName`)}</p>
                </div>
                {checkedName ? <div className="m-2 form-row">
                    <label className="font-weight-bold">{t('profile.nameRep')}</label>
                    <Input
                        placeholder={t('profile.nameRep')}
                        type="text"
                        name="email"
                        value={nameRep || ""}
                        valid={nameRep ? true : false}
                        onChange={handleChange('nameRep')}
                    />
                </div> : null}
            </div>
            {alert}
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        userProfile: state.userProfile.data,
        userInfo: state.userInfo,
        settingList: state.settingList.data
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        getSetting: () => dispatch({ type: "GET_SETTING" }),

    }
}
export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(Setting));
