import axios from 'axios';
import LoadingTable from "components/LoadingTable/index.js";
import Pagination from "components/Pagination/index.jsx";
import SubTable from "components/SubTable/table.js";
import { LINK_API } from 'constants/API.js';
import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ReactTable from 'react-table-6';
import moment from 'moment';
import "../../../css/reactTable.css";
import DeleteIcon from "mdi-react/DeleteIcon";
import Error from 'components/alert/error'
import Success from 'components/alert/success'
import ConfirmAlert from "components/alert/confirmAlert.js";
import { UncontrolledTooltip, Button } from "reactstrap"
function PrintManagerment(props) {
  const [printData, setPrintData] = React.useState([])
  const [loadingState, setLoading] = React.useState(true)
  const [alert, setAlert] = React.useState(null)
  const { t } = props;

  const {
    userInfo
  } = props;

  const hideAlert = () => {
    setAlert(null)
  }

  const confirm = (id) => {
    axios.delete(LINK_API.DELETE_PRINT_STAMP_HISTORY + id)
      .then(async res => {
        getData(props.startDate, props.endDate)
        setAlert(<Success onClose={hideAlert} hideCancel={true} onConfirm={hideAlert} confirmBtnText={t('close')} />)
      })
      .catch(err => {
        setAlert(<Error onClose={hideAlert} confirmBtnText={t('close')} />)
      })
  }

  const handleDelete = (id) => {
    let ChildComponent = <div>{"Bạn chắc chắn xoá lịch sử in tem này?"}</div>
    setAlert(
      <ConfirmAlert
        onClose={hideAlert}
        onConfirm={() => confirm(id)}
        confirmBtnText={t('confirm')}
        child={ChildComponent}
      />
    )
  }

  const genTable = React.useCallback((data) => {
    let dataRender = data.map((item, index) => {
      const dataDetail = item.printStamp.map(prop => {
        return {
          productName: prop.productName,
          time: moment(prop.date).format("HH:mm:ss DD/MM/YYYY"),
          number: <div>
            <div>
              {`${prop.stampNo} tem`}
            </div>
            <div>
              {`${prop.startId} - ${prop.endId}`}
            </div>
          </div>,
          sampleStamp: prop.sampleStampName ? prop.sampleStampName : "Tem đã bị xoá bởi quản trị viên",
          type: prop.endId.length === 13 ? "Tem sản phẩm" : "Tem sản phẩm",
          actions: <>
            <Button onClick={() => handleDelete(prop.productUuid)} color="neutral-danger" className="mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center" id={"active" + index}>
              <span className="btn-wrapper--icon d-flex">
                <DeleteIcon
                  size={18}
                  className="text-success font-size-sm"
                />
              </span>
            </Button>
            <UncontrolledTooltip trigger="hover" popperClassName="tooltip-black" placement="top" container="body" target={"active" + index}>
              Xoá vùng sản xuất
            </UncontrolledTooltip>
          </>
        }
      })
      return {
        id: item.userUuid,
        enterpriseName: item.username,
        dataDetail: dataDetail,
        count: item.count,
        address: item.address
      }
    })
    setPrintData(dataRender)
    setLoading(false)
  }, [])


  const getData = async (startDate, endDate) => {
    try {

      axios.defaults.headers.common.Authorization = userInfo.data.token
      const response = await axios({
        method: 'GET',
        url: LINK_API.GET_PRINT_STAMP_LIST_ADMIN.concat('?startTime=', startDate, '&endTime=', endDate)
      })
      if (response && response.data && response.data.data) {
        genTable(response.data.data)
      }
      else {
        genTable([])
      }
    }
    catch {
      genTable([]);
      setLoading(false)
    }
  }

  useEffect(() => {
    setLoading(true)
    getData(props.startDate, props.endDate)

  }, [props.startDate, props.endDate]);


  const columns = React.useMemo(
    () => [
      {
        accessor: "id",
        filterable: true,
        filterMethod: (filter, row) => {
          if (row.id) {
            const timeInRow = row.id.toUpperCase().search(filter.value.toUpperCase());
            if (timeInRow === -1) return false;
            return true;
          }
          return false;
        },
        className: "left",
        Header: () => (
          <div style={{
            textAlign: ""
          }}>
            Mã thành viên
          </div>
        )
      },
      {
        Header: "Thành viên",
        accessor: "enterpriseName",
        filterable: true,
        filterMethod: (filter, row) => {
          if (row.enterpriseName) {
            const timeInRow = row.enterpriseName.toUpperCase().search(filter.value.toUpperCase());
            if (timeInRow === -1) return false;
            return true;
          }
          return false;
        },
      },
      {
        Header: "Địa chỉ",
        accessor: "address",
        filterable: true,
        filterMethod: (filter, row) => {
          if (row.address) {
            const timeInRow = row.address.toUpperCase().search(filter.value.toUpperCase());
            if (timeInRow === -1) return false;
            return true;
          }
          return false;
        },
      },
      {
        Header: "Số lần in",
        accessor: "count",
        filterable: false,
      },
    ],
    [t]
  );


  const columnSubTable = [
    {
      Header: "Sản phẩm",
      accessor: "productName",
      filterable: true,
      filterMethod: (filter, row) => {
        if (row.productName) {
          const timeInRow = row.productName.toUpperCase().search(filter.value.toUpperCase());
          if (timeInRow === -1) return false;
          return true;
        }
        return false;
      },
    },
    {
      Header: "Thời gian in",
      accessor: "time",
      filterable: true,
      filterMethod: (filter, row) => {
        const timeInRow = row.time.search(filter.value);
        if (timeInRow === -1) return false;
        return true;
      },
    },
    {
      Header: "Số lượng",
      accessor: "number",
      filterable: false
    },
    {
      Header: "Tên tem mẫu",
      accessor: "sampleStamp",
      filterable: false
    },
    {
      Header: "Loại tem",
      accessor: "type",
      filterable: false,
      maxWidth: 100
    },
    {
      Header: "Hành động",
      accessor: "actions",
      filterable: false,
      maxWidth: 100
    }
  ]

  return (

    <>
      <div className="divider" />
      <div className="card-body pt-2 px-4 pb-4">
        <ReactTable
          noDataText={t('noData')}
          data={printData}
          columns={columns}
          previousText={"<"}
          nextText={">"}
          rowsText={t('row')}
          ofText="/"
          defaultPageSize={5}
          showPaginationBottom={true}
          sortable={false}
          resizable={true}
          PaginationComponent={Pagination}
          className="-striped -highlight"
          loading={loadingState}
          LoadingComponent={LoadingTable}
          SubComponent={row => {
            return (
              <ReactTable
                noDataText={t('noData')}
                data={row.original.dataDetail}
                columns={columnSubTable}
                previousText={"<"}
                nextText={">"}
                rowsText={t('row')}
                ofText="/"
                defaultPageSize={5}
                showPaginationBottom={true}
                sortable={false}
                resizable={true}
                PaginationComponent={Pagination}
                className="-striped -highlight"
                loading={loadingState}
                LoadingComponent={LoadingTable}
              />
            )
          }}
        />
        {alert}
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
    harvestWithObject: state.harvestWithObject
  }
}

const mapDispatchToProps = (dispatch) => ({
  getAllHarvestWithObject: (data) => dispatch({ type: "GET_ALL_HARVEST_WITH_OBJECT", data: data })
});

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(PrintManagerment));