const initialState = { data: [], errorCode: '' }
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_RECEIVED_BOX':
      return {
        ...state
      }
    case 'GET_RECEIVED_BOX_SUCCESS':
      return {
        ...state,
        data: action.data,
        errorCode: action.errorCode
      }
    case 'GET_RECEIVED_BOX_FALURE':
      return {
        ...state,
        data: action.data,
        errorCode: action.errorCode
      }
    default:
      return state
  }
}
export default reducer
