const initialState = { data: '', errorCode: '', fullData: {}, totalItem: 0, totalPage: 0 }
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_PRODUCT_LIST':
      return {
        ...state
      }
    case 'GET_PRODUCT_LIST_SUCCESS':
      return {
        ...state,
        data: action.data,
        fullData: action.fullData,
        errorCode: action.errorCode,
        totalItem: action.totalItem,
        totalPage: action.totalPage
      }
    case 'GET_PRODUCT_LIST_FALURE':
      return {
        ...state,
        data: action.data,
        errorCode: action.errorCode
      }
    case 'CREATE_PRODUCT':
      return {
        ...state
      }
    default:
      return state
  }
}
export default reducer
