import BannerDrop from "components/dropZone/bannerDrop";
import LogoDrop from "components/dropZone/logoDrop";
import { LINK_API } from "constants/API";
import PenIcon from "mdi-react/LeadPencilIcon";
import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { toast, Zoom } from 'react-toastify';
import { Button } from "reactstrap";
import Confirm from "components/alert/confirmAlert"

function Brand(props) {
    const t = props.t;
    const userProfile = props.userProfile;
    const getUserProfile = props.getUserProfile;
    const brandRef = React.createRef();
    const logoRef = React.createRef();
    const [editState, setEdit] = React.useState(false)
    const [state, setState] = React.useState({
        logo: "https://res.cloudinary.com/dinhtq98/image/upload/v1608607307/Kontum%20Project/Logo_t%E1%BB%89nh_kon_tum-removebg-preview_kegjmn.png",
        banner: "https://res.cloudinary.com/agridential/image/upload/v1593665050/AGDImage/bannergreenfarm_a88wax.jpg"
    })
    const [loadingState, setLoading] = React.useState(false)
    const handleEdit = () => {
        setEdit(!editState)
    }
    React.useEffect(() => {
        if (userProfile) {
            if (userProfile.logo && userProfile.banner)
                setState({
                    ...state,
                    logo: userProfile.logo.secure_url ? userProfile.logo.secure_url : "https://res.cloudinary.com/dinhtq98/image/upload/v1608607307/Kontum%20Project/Logo_t%E1%BB%89nh_kon_tum-removebg-preview_kegjmn.png",
                    banner: userProfile.banner.secure_url ? userProfile.banner.secure_url : "https://res.cloudinary.com/agridential/image/upload/v1593665050/AGDImage/bannergreenfarm_a88wax.jpg",
                })
        }
    }, [userProfile])
    const [alert, setAlert] = React.useState(null)
    const handleWarning  = () => {
        setAlert(<Confirm 
            child={"Lưu lại thông tin đã thay đổi ?"}
            cancleBtnText={t('no')}
            confirmBtnText={t("yes")}
            onConfirm = {handleSave}
            onClose= {hideAlert}
            />)
    }
    const hideAlert= () => {
        setAlert(null)
    }
    const handleSave = () => {
        handleUpLoad();
    }
    const handleUpLoad = async () => {
            await brandRef.current.handleUpload();
            await logoRef.current.handleUpload();
        handleEdit();
    }
    const handleReload = (name) => {
        toast.success("Cập nhật " + name + " thành công", { containerId: 'B', transition: Zoom, autoClose: 2000 });
        getUserProfile();
        setLoading(false);
    }
    const handleLoading = () => {
        if (!loadingState) {
            toast.warning(t('processing'), { containerId: 'B', transition: Zoom, autoClose: 2000 });
        }
    }
    return (
        <>
            <div className="p-4">
                <div className='d-flex justify-content-between'>

                    <div className='d-flex flex-column'>
                        <h5 className="font-size-xl font-weight-bold">{t('profile.brand')}</h5>
                        <p className="text-black-50 mb-4">{t('profile.aboutBrand')}</p>
                    </div>
                    <div>
                        {!editState ? <Button color="info" onClick={handleEdit}>
                            <span className="btn-wrapper--icon">
                                <PenIcon size={16} />
                            </span>
                            <span className="btn-wrapper--label">{t('edit')}</span>
                        </Button> : <>
                                <Button color="danger" className="mr-2" onClick={handleEdit}>
                                    <span className="btn-wrapper--label">Hủy</span>
                                </Button>
                                <Button color="info" onClick={handleUpLoad}>
                                    <span className="btn-wrapper--label">{t('save')}</span>
                                </Button>
                            </>}
                    </div>
                </div>
                <div className="divider my-4" />
                {!editState ?
                    <>
                        <div className="card-img-wrapper">

                            <p className='font-weight-bold'>Banner</p>
                            <img alt="..." className="card-img img-fit-container" src={state.banner} />
                        </div>
                        <p className='font-weight-bold'>Logo</p>
                        <div className="card-body text-left card-body-avatar">
                            <a href="#/" onClick={e => e.preventDefault()} className="avatar-icon-wrapper card-box-hover rounded-circle card-box-hover-rise hover-scale-lg d-130">
                                <div className="avatar-icon rounded-circle">
                                    <div className="d-block p-0 avatar-icon-wrapper rounded-circle m-0 border-3 border-white">
                                        <div className="rounded-circle border-3 border-white overflow-hidden">
                                            <img alt="..." className="img-fluid" src={state.logo} />
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div> </> : <>
                        <div className="card-img-wrapper h-180px">
                            <p className='font-weight-bold'>Banner</p>
                            <BannerDrop ref={brandRef} max={1} path={LINK_API.EDIT_PROFILE_BANNER} reload={handleReload} setLoad={handleLoading} />
                        </div>
                        <div className='mt-5'>
                        <p className='font-weight-bold'>Logo</p>
                        <div className="text-left card-body-avatar">
                            <a href="#/" onClick={e => e.preventDefault()} className="avatar-icon-wrapper card-box-hovercard-box-hover-rise hover-scale-lg d-130">
                                <LogoDrop ref={logoRef} max={1} path={LINK_API.EDIT_PROFILE_LOGO} reload={handleReload} setLoad={handleLoading} />
                            </a>
                        </div>
                        </div>
                       
                        {alert}
                    </>
                }
            </div>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        userProfile: state.userProfile.data,
        userInfo: state.userInfo
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        getUserProfile: () => dispatch({ type: "GET_USER_PROFILE" }),
        updateBanner: (data) => dispatch({ type: "UPDATE_BANNER", data: data }),
        updateLogo: (data) => dispatch({ type: "UPDATE_LOGO", data: data }),
        cleanStore: () => dispatch({ type: "CLEAN_STORE" }),
        getSetting: () => dispatch({ type: "GET_SETTING" }),

    }
}
export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(Brand));
