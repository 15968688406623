import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import DistributorLayout from "layouts/Distributor";
import SuperAdminLayout from "layouts/SuperAdmin";
import React from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import SuccessPage from "views/success-page/index";
import SuperUserLayout from 'layouts/SuperUser.js'
import EmployeeLayout from "layouts/Enployee.js"
import PortalAdminLayout from "layouts/PortalAdmin.js"
import MarketLayout from "layouts/Market.js"
import GuardLayout from "layouts/Guard.js"
import FarmerLayout from "layouts/Farmer"
import WholeSalerLayout from "layouts/Wholesaler"
const ManageRoute = (props) => {
    const userInfo = props.userInfo;
    const [role, setRole] = React.useState('M')

    React.useEffect(() => {
        if (userInfo.data) {
            if (userInfo.data.type) {
                setRole(userInfo.data.type)
            }
        }
    }, [userInfo])
    return (

        <Switch>
            {/* {role === 'U' ?
                <Route path="/padmin" render={props => <PortalAdminLayout {...props} />} />
                : null} */}
            {role === 'S' ?
                <Route path="/sadmin" render={props => <SuperUserLayout {...props} />} />
                : null}
            {role === 'M' ?
                <Route path="/user" render={props => <AdminLayout {...props} />} />
                : null}
            {role === 'F' ?
                <Route path="/distributor" render={props => <DistributorLayout {...props} />} />
                : null}
            {role === 'Z' ?
                <Route path="/admin" render={props => <SuperAdminLayout {...props} />} />
                : null}
            {role === 'SM' ?
                <Route path="/employee" render={props => <EmployeeLayout {...props} />} />
                : null}
            {role === 'U' ?
                <Route path="/market" render={props => <PortalAdminLayout {...props} />} />
                : null}
            {role === 'G' ?
                <Route path="/guard" render={props => <GuardLayout {...props} />} />
                : null}
            {role === 'FA' ?
                <Route path="/farmer" render={props => <FarmerLayout {...props} />} />
                : null}
            {role === 'W' ?
                <Route path="/wholesaler" render={props => <WholeSalerLayout {...props} />} />
                : null}
            <Route path="/success" render={props => <SuccessPage {...props} />} />
            <Route path="/auth" render={props => <AuthLayout {...props} />} />
            <Redirect from="/" to="/auth/login" />
        </Switch>

    )
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.userInfo,
    }
}
export default connect(mapStateToProps, null)(ManageRoute)