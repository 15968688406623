import {
    GET_HARVEST_LIST,
    GET_HARVEST_LIST_FAILURE,
    GET_HARVEST_LIST_SUCCESS,
  } from "actions/types"
  import { takeLatest, call, put, select } from 'redux-saga/effects'
  import { LINK_API } from 'constants/API.js'
  import axios from 'axios'
  
  const getToken = (state) => state.userInfo
  
  function* getAllHarvest() {
    try {
      const tokenInfo = yield select(getToken)
      const response = yield call(fetchAllHavest, tokenInfo.data.token)
      const data = response.data
      yield put({ type: GET_HARVEST_LIST_SUCCESS, data: data.data, errorCode: data.errorCode })
    } catch (error) {
      yield put({ type: GET_HARVEST_LIST_FAILURE, payload: '', errorCode: 0 })
    }
  }
  function fetchAllHavest(checktoken) {
    axios.defaults.headers.common.Authorization = checktoken
    return axios({
      method: 'GET',
      url: LINK_API.GET_HARVEST_LIST
    })
  }
  const harvestList = [
    takeLatest(GET_HARVEST_LIST, getAllHarvest)
  ]
  export default harvestList
  