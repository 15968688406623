/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Account from "view/dashboard/account-page/index";
import DetailAccount from "view/dashboard/account-page/profile-page/index";
import ActiveStampUser from "view/dashboard/activate_stamp_page/index";
import ActiveStamp from "view/dashboard/active-stamp-page/index";
import Activity from "view/dashboard/activity-history-page/index";
import ActivitySAdmin from "view/dashboard/activity-history-page/sadmin";
import SystemAction from "view/dashboard/activity-history-page/system";
import AdminList from "view/dashboard/admin-list-page/index";
import AllManu from "view/dashboard/admin-manu-page/index";
import AllProduct from "view/dashboard/admin-product-page/index";
import AllDis from "view/dashboard/all-dis-page/index";
import CreateArea from "view/dashboard/area-page/create-page/index";
import EditArea from "view/dashboard/area-page/edit-page/index";
import Area from "view/dashboard/area-page/index";
import AddBranch from "view/dashboard/branch-page/createBranch";
import Branch from "view/dashboard/branch-page/index";
import CreateEmployee from "view/dashboard/employee-page/create-page/index";
import EditEmployee from "view/dashboard/employee-page/edit-page/index";
import Employee from "view/dashboard/employee-page/index";
import Import from "view/dashboard/import-page/index";
import Arrive from "view/dashboard/import-retailer-page/index";
import CreateObjectProcessing from "view/dashboard/objectProcessing-page/create-page/index";
import ObjectProcessingDetail from "view/dashboard/objectProcessing-page/detail-page/index";
import ObjectProcessing from "view/dashboard/objectProcessing-page/index";
import CreateObjectProduction from "view/dashboard/objectProduction-page/create-page/index";
import ObjectDetail from "view/dashboard/objectProduction-page/detail-page/index";
import ObjectProduction from "view/dashboard/objectProduction-page/index";
import CreatePackage from "view/dashboard/package-page/createPackage";
import DetailPackage from "view/dashboard/package-page/detail";
import Package from "view/dashboard/package-page/index";
import AddPartner from "view/dashboard/partner-page/createPartner";
import DetailPartner from "view/dashboard/partner-page/detailPartner";
import Feedback from "view/dashboard/partner-page/feedback";
import Partner from "view/dashboard/partner-page/index";
import CreateProcess from "view/dashboard/process-page/create-page/index";
import EditProcess from "view/dashboard/process-page/edit-page/index";
import Process from "view/dashboard/process-page/index";
import CreateProduct from "view/dashboard/product-page/create-product/index";
import EditProduct from "view/dashboard/product-page/edit-page/index";
import ProductType from "view/dashboard/product-page/index";
import Report from "view/dashboard/report-page/index";
import Stamp from "view/dashboard/stamp-history-page/index";
import DetailStamp from "view/dashboard/stamp-history-page/stampDetail";
import StampPosition from "view/dashboard/stamp-position-page/index";
import OverviewAdmin from "view/dashboard/statistic-admin-page/report-page/index";
import Customer from "view/dashboard/subscription-page/index";
import Profile from "view/profile-page/index";
import CreateAccount from "view/register-page/index";
import CreateAdmin from "view/dashboard/admin-list-page/create-admin"
import Login from "views/examples/Login.js";
import Register from "views/examples/Register.js";
import CreateStamp from "view/dashboard/stamp-position-page/createStamp"
import EditStamp from "view/dashboard/stamp-position-page/editStamp"

import StampSample from "view/dashboard/stamp-position-page/stampSample"
import AddDiary from "view/dashboard/employObject/index"
import ManagePortal from "view/dashboard/manage-portal-page/index"
import ContactSetting from "view/dashboard/manage-portal-page/children/contact-setting-page"
import FooterSetting from "view/dashboard/manage-portal-page/children/footer-setting-page"
import AdsSetting from "view/dashboard/manage-portal-page/children/ads-setting-page"
import EnterpriseWithStamp from "view/dashboard/stamp-position-page/enterprise_with_sample_stamp"
import StampListOfUser from "view/dashboard/stamp-position-page/stampListOfUser"
import RegisterAccount from "view/dashboard/account-page/register-form"
import DiaryRecord from "view/dashboard/diary-record-page"
import CreateDiaryRecord from "view/dashboard/diary-record-page/create_diary_record"
import EditDiaryRecord from "view/dashboard/diary-record-page/edit_diary_record"
import ActivatedStamp from "view/dashboard/diary-record-page/activated_stamp"
import GuardList from "view/dashboard/guard-page/index"
import CreateGuard from "view/dashboard/guard-page/create-guard"
import ImportList from "view/dashboard/guard-page/list-box"
import CreateDiaryGuard from "view/dashboard/guard-page/import-box"
import HistoryImport from "view/dashboard/guard-page/history"
import CreateFamer from "view/dashboard/farmer/createFarmer"
import FarmerList from "view/dashboard/farmer/index"
import CreateDis from "view/dashboard/all-dis-page/createDis"
import FarmerHistory from "view/dashboard/farmer/diary"
import FarmerDiary from "view/dashboard/farmer/add-diary"
import FarmerAddDiary from "view/dashboard/farmer/add-diary-farmer"
import ShipHistory from "view/dashboard/ship-list/index"
import ShippingBox from "view/dashboard/ship-list/create-diary"
import ApproveFarmer from "view/dashboard/ship-list/approve"
import BoxList from "view/dashboard/ship-list/list-approve"
import BoxImport from "view/dashboard/import-history-page/index"
import BoxImportProcess from "view/dashboard/import-history-page/import-box"
import ReceivedBox from "view/dashboard/import-history-page/received"
import AllPartner from "view/dashboard/partner_admin/index"
import PersonalRecord from "view/dashboard/person-record-page/createPersonRecord"
import ProductActive from "view/dashboard/product_in_txng"
import RequestPage from "view/dashboard/request-page"
import AllRecordForAdmin from "view/dashboard/record_management_for_admin"
import EditRecordForAdmin from "view/dashboard/record_management_for_admin/editRecord"
import DiaryLegderForAdmin from "view/dashboard/diary-legder-admin"
import EditLegerForAdmin from "view/dashboard/diary-legder-admin/editLeger"
import AdminObjectDetail from "view/dashboard/admin-product-page/detail-page/index";

var routes = [
  //role manufacturer
  {
    path: "/profile",
    name: "Thông tin tài khoản",
    icon: "ni ni-app text-primary",
    component: Profile,
    layout: "/auth",
    hide: true,
    role: "ALL"
  },
  {
    path: "/stampSample",
    name: "Đăng nhập",
    icon: "ni ni-key-25 text-danger",
    component: StampSample,
    layout: "/user",
    hide: true,
    role: "M"
  },
    {
    path: "/productType",
    name: "Quản lý sản phẩm",
    icon: "ni ni-map-big text-blue",
    component: ProductType,
    layout: "/user",
    expand: true,
    id: 'areaExpand',
    role: "M",
    child: [
      {
        name: "Nhóm sản phẩm",
        path: '/productType'
      },
      {
        name: "Sản phẩm",
        path: '/objectProduction'
      },
      {
        name: "Sản phẩm đã kích hoạt",
        path: '/productActive'
      }
    ]
  },

  {
    path: "/productActive",
    name: "Sản phẩm đã kích hoạt",
    icon: "ni ni-app text-orange",
    component: ProductActive,
    layout: "/user",
    hide: true,
    role: "M"
  },

  {
    path: "/objectProduction",
    name: "Sản phẩm",
    icon: "ni ni-app text-orange",
    component: ObjectProduction,
    layout: "/user",
    hide: true,
    role: "M"
  },
  
  {
    path: "/productType",
    name: "Nhóm sản phẩm",
    icon: "ni ni-app text-primary",
    component: ProductType,
    layout: "/user",
    role: "M",
    hide: true
  },
  {
    path: "/profile",
    name: "Thông tin tài khoản",
    icon: "ni ni-app text-primary",
    component: Profile,
    layout: "/user",
    role: "M",
    hide: true
  },
  {
    path: "/productCreate",
    name: "Tạo nhóm sản phẩm",
    icon: "ni ni-app text-primary",
    component: CreateProduct,
    layout: "/user",
    hide: true,
    role: "M"
  },
  {
    path: "/productEdit",
    name: "Chỉnh sửa nhóm sản phẩm",
    icon: "ni ni-app text-primary",
    component: EditProduct,
    layout: "/user",
    hide: true,
    role: "M"
  },
  // {
  //   path: "/area",
  //   name: "Vùng nguyên liệu",
  //   icon: "ni ni-map-big text-blue",
  //   component: Area,
  //   layout: "/user",
  //   expand: true,
  //   id: 'areaExpand',
  //   role: "M",
  //   child: [
  //     {
  //       name: "Vùng nguyên liệu",
  //       path: '/area'
  //     },
  //     {
  //       name: "Danh sánh sản phẩm",
  //       path: '/objectProduction'
  //     }
  //   ]
  // },
  {
    path: "/area",
    name: "Vùng nguyên liệu",
    icon: "ni ni-pin-3 text-orange",
    component: Area,
    layout: "/user",
    role: "M"
  },
  {
    path: "/areaCreate",
    name: "Tạo vùng nguyên liệu",
    icon: "ni ni-pin-3 text-orange",
    component: CreateArea,
    layout: "/user",
    hide: true,
    role: "M"
  },
  {
    path: "/areaEdit",
    name: "Tạo vùng nguyên liệu",
    icon: "ni ni-pin-3 text-orange",
    component: EditArea,
    layout: "/user",
    hide: true,
    role: "M"
  },
  {
    path: "/createObjectProduction",
    name: "Tạo sản phẩm",
    icon: "ni ni-pin-3 text-orange",
    component: CreateObjectProduction,
    layout: "/user",
    hide: true,
    role: "M"
  },
  {
    path: "/detailObjectProduction",
    name: "Chi tiết sản phẩm",
    icon: "ni ni-pin-3 text-orange",
    component: ObjectDetail,
    layout: "/user",
    hide: true,
    role: "M"
  },
  {
    path: "/objectProcessing",
    name: "Sản phẩm",
    icon: "ni ni-pin-3 text-red",
    component: ObjectProcessing,
    layout: "/user",
    hide: true,
    role: "M"
  },
  {
    path: "/createObjectProcessing",
    name: "Tạo sản phẩm",
    icon: "ni ni-pin-3 text-orange",
    component: CreateObjectProcessing,
    layout: "/user",
    hide: true,
    role: "M"
  },
  {
    path: "/detailObjectProcessing",
    name: "Chi tiết sản phẩm",
    icon: "ni ni-pin-3 text-orange",
    component: ObjectProcessingDetail,
    layout: "/user",
    hide: true,
    role: "M"
  },
  {
    path: "/createRecord",
    name: "Tạo hồ sơ truy xuất",
    icon: "ni ni-books text-teal",
    component: CreateDiaryRecord,
    layout: "/user",
    role: "M",
    hide: true
  },
  {
    path: "/editRecord",
    name: "Chỉnh sửa hồ sơ truy xuất",
    icon: "ni ni-books text-teal",
    component: EditDiaryRecord,
    layout: "/user",
    role: "M",
    hide: true
  },
  // {
  //   path: "/employee",
  //   name: "Nhân viên",
  //   icon: "ni ni-single-02 text-yellow",
  //   component: Employee,
  //   layout: "/user",
  //   role: "M"
  // },
  {
    path: "/createEmployee",
    name: "Tạo nhân viên",
    icon: "ni ni-single-02 text-yellow",
    component: CreateEmployee,
    layout: "/user",
    hide: true,
    role: "M"
  },
  {
    path: "/editEmployee",
    name: "Chỉnh sửa nhân viên",
    icon: "ni ni-single-02 text-yellow",
    component: EditEmployee,
    layout: "/user",
    hide: true,
    role: "M"
  },
  {
    path: "/process",
    name: "Quy trình truy xuất",
    icon: "ni ni-bullet-list-67 text-green",
    component: Process,
    layout: "/user",
    role: "M"
  },
  {
    path: "/record",
    name: "Hồ sơ truy xuất",
    icon: "ni ni-books text-teal",
    component: DiaryRecord,
    layout: "/user",
    role: "M"
  },
  {
    path: "/createProcess",
    name: "Tạo quy trình sản xuất",
    icon: "ni ni-bullet-list-67 text-green",
    component: CreateProcess,
    layout: "/user",
    hide: true,
    role: "M"
  },
  {
    path: "/editProcess",
    name: "Chỉnh sửa quy trình sản xuất",
    icon: "ni ni-bullet-list-67 text-green",
    component: EditProcess,
    layout: "/user",
    hide: true,
    role: "M"
  },
  // {
  //   path: "/partner",
  //   name: "Nhà phân phối",
  //   icon: "ni ni-circle-08 text-cyan",
  //   component: Partner,
  //   layout: "/user",
  //   id: 'partner',
  //   expand: false,
  //   role: "M",
  //   child: [
  //     {
  //       name: 'Danh sách',
  //       path: '/partner'
  //     },
  //     {
  //       name: 'Phản hồi',
  //       path: '/feedback'
  //     }
  //   ],
  //   expand: true
  // },
  // {
  //   path: "/partner",
  //   name: "Nhà phân phối",
  //   icon: "ni ni-circle-08 text-cyan",
  //   component: Partner,
  //   layout: "/user",
  //   hide: false,
  //   role: "M"
  // },
  {
    path: "/partner",
    name: "Đơn vị phân phối",
    icon: "ni ni-collection text-pink",
    component: Partner,
    layout: "/user",
    // hide: true,
    role: "M"
  },
  {
    path: "/partnerDetail",
    name: "Chi tiết đối tác",
    icon: "ni ni-collection text-pink",
    component: DetailPartner,
    layout: "/user",
    hide: true,
    role: "M"
  },
  {
    path: "/feedback",
    name: "Danh sách phản hồi",
    icon: "ni ni-collection text-pink",
    component: Feedback,
    layout: "/user",
    hide: true,
    role: "M"
  },
  {
    path: "/addPartner",
    name: "Thêm khách hàng",
    icon: "ni ni-collection text-pink",
    component: AddPartner,
    layout: "/user",
    hide: true,
    role: "M"
  },
  {
    path: "/stamp",
    name: "Quản lý tem",
    icon: "ni ni-map-big text-blue",
    component: Stamp,
    layout: "/user",
    expand: true,
    id: 'stampExpand',
    role: "M",
    child: [
      {
        name: "Quản lý tem",
        path: '/stamp'
      },
      {
        name: "Danh sách tem mẫu",
        path: '/sample_stamp'
      }
    ]
  },

  {
    path: "/stamp",
    name: "Quản lý tem",
    icon: "ni ni-collection text-pink",
    component: Stamp,
    layout: "/user",
    role: "M",
    hide: true
  },
  {
    path: "/sample_stamp",
    name: "Danh sách tem mẫu",
    icon: "ni ni-collection text-pink",
    component: StampListOfUser,
    role: "M",
    layout: "/user",
    hide: true
  },
  {
    path: "/activateStamp",
    name: "Kích hoạt tem",
    icon: "ni ni-collection text-pink",
    component: ActiveStampUser,
    layout: "/user",
    role: "M",
    hide: true
  },
  {
    path: "/stamp/usedStamp",
    name: "Danh sách tem đã kích hoạt",
    icon: "ni ni-collection text-pink",
    component: DetailStamp,
    layout: "/user",
    role: "M",
    hide: true
  },
  {
    path: "/stampDetail",
    name: "Thông tin chi tiết",
    icon: "ni ni-collection text-pink",
    component: DetailStamp,
    layout: "/user",
    hide: true,
    role: "M"
  },
  {
    path: "/personalRecord",
    name: "Hồ sơ kinh doanh",
    icon: "ni ni-folder-17 text-gray-dark",
    component: PersonalRecord,
    layout: "/user",
    role: "M"
  },
  {
    path: "/activity",
    name: "Lịch sử cập nhật",
    icon: "ni ni-ui-04 text-teal",
    component: ActivitySAdmin,
    layout: "/user",
    role: "M"
  },
  {
    path: "/activatedStamp",
    name: "Tem đã kích hoạt",
    icon: "ni ni-books text-teal",
    component: ActivatedStamp,
    layout: "/user",
    role: "M",
    hide: true
  },
  {
    path: "/report",
    name: "Thống kê và báo cáo",
    icon: "ni ni-single-copy-04 text-pink",
    component: Report,
    layout: "/user",
    role: "M"
  },
  // {
  //   path: "/import",
  //   id: 'importBox',
  //   name: "Nhận hàng",
  //   icon: "ni ni-box-2 text-orange",
  //   component: BoxImport,
  //   layout: "/user",
  //   expand: true,
  //   role: "M",
  //   child: [
  //     {
  //       name: "Chưa nhận",
  //       path: '/import'
  //     },
  //     {
  //       name: "Đã nhận",
  //       path: '/received'
  //     },
  //   ]
  // },
  {
    path: "/boxImport",
    name: "Nhận hàng",
    icon: "ni ni-single-copy-04 text-gray-dark",
    component: BoxImportProcess,
    layout: "/user",
    role: "M",
    hide: true
  },
  {
    path: "/received",
    name: "Lịch sử nhận hàng",
    icon: "ni ni-single-copy-04 text-gray-dark",
    component: ReceivedBox,
    layout: "/user",
    role: "M",
    hide: true
  },
  {
    path: "/import",
    name: "Nhận hàng",
    icon: "ni ni-single-copy-04 text-gray-dark",
    component: BoxImport,
    layout: "/user",
    role: "M",
    hide: true
  },
 
  // {
  //   path: "/addDiary",
  //   name: "Thêm nhật ký",
  //   icon: "ni ni-fat-add text-blue",
  //   component: AddDiary,
  //   layout: "/user",
  //   role: "M"
  // },
  //role distributor
  {
    path: "/import",
    name: "Nhập hàng",
    icon: "ni ni-map-big text-blue",
    component: Import,
    layout: "/distributor",
    expand: true,
    id: 'distributorExpand',
    role: "F",
    child: [
      {
        name: "Lịch sử",
        path: '/import'
      },
      {
        name: "Hàng mới về",
        path: '/arrive'
      },
    ]
  },
  {
    path: "/import",
    name: "Lịch sử nhập hàng",
    icon: "ni ni-key-25 text-danger",
    component: Import,
    layout: "/distributor",
    hide: true,
    role: "F"
  },
  {
    path: "/arrive",
    name: "Hàng mới về",
    icon: "ni ni-key-25 text-danger",
    component: Arrive,
    layout: "/distributor",
    hide: true,
    role: "F"
  },
  {
    path: "/employee",
    name: "Nhân viên",
    icon: "ni ni-single-02 text-yellow",
    component: Employee,
    layout: "/distributor",
    role: "F"
  },
  {
    path: "/createEmployee",
    name: "Thêm nhân viên mới",
    icon: "ni ni-single-02 text-yellow",
    component: CreateEmployee,
    layout: "/distributor",
    role: "F",
    hide: true
  },
  {
    path: "/editEmployee",
    name: "Chỉnh sửa thông tin nhân viên",
    icon: "ni ni-single-02 text-yellow",
    component: EditEmployee,
    layout: "/distributor",
    role: "F",
    hide: true
  },
  {
    path: "/partner",
    name: "Đối tác",
    icon: "ni ni-circle-08 text-cyan",
    component: Partner,
    layout: "/distributor",
    role: "F",
  },
  {
    path: "/addPartner",
    name: "Thêm đối tác",
    icon: "ni ni-circle-08 text-cyan",
    component: AddPartner,
    layout: "/distributor",
    role: "F",
    hide: true
  },
  {
    path: "/partnerDetail",
    name: "Thông tin chi tiết đối tác",
    icon: "ni ni-circle-08 text-cyan",
    component: DetailPartner,
    layout: "/distributor",
    role: "F",
    hide: true
  },
  {
    path: "/branch",
    name: "Chi nhánh",
    icon: "ni ni-building text-blue",
    component: Branch,
    layout: "/distributor",
    role: "F",
    // hide: true
  },
  {
    path: "/addBranch",
    name: "Thêm mới chi nhánh",
    icon: "ni ni-building text-blue",
    component: AddBranch,
    layout: "/distributor",
    role: "F",
    hide: true
  },
  {
    path: "/profile",
    name: "Thông tin tài khoản",
    icon: "ni ni-building text-blue",
    component: Profile,
    layout: "/distributor",
    role: "F",
    hide: true
  },
  {
    path: "/profile",
    name: "Thông tin tài khoản",
    icon: "ni ni-app text-primary",
    component: Profile,
    layout: "/distributor",
    hide: true,
    role: "ALL"
  },
  //admin role
  {
    path: "/profile",
    name: "Thông tin tài khoản",
    icon: "ni ni-building text-blue",
    component: Profile,
    layout: "/admin",
    role: "Z",
    hide: true
  },
  // {
  //   path: "/allManu",
  //   name: "Quản lý đăng ký",
  //   icon: "ni ni-circle-08 text-blue",
  //   component: Customer,
  //   layout: "/admin",
  //   role: "Z",
  //   // hide: true
  // },
  // {
  //   path: "/package",
  //   name: "Danh sách gói",
  //   icon: "ni ni-money-coins text-yellow",
  //   component: Package,
  //   layout: "/admin",
  //   role: "Z",
  //   // hide: true
  // },
  {
    path: "/allManu",
    name: "Quản lý tài khoản",
    icon: "ni ni-badge text-green",
    component: AllManu,
    expand: true,
    id: 'accountExpand',
    layout: "/admin",
    role: "Z",
    child: [
      // {
      //   name: "Thành viên",
      //   path: '/subscribe'
      // },
      {
        name: "Thành viên",
        path: '/allManu'
      },
      {
        name: "Danh sách nhà phân phối",
        path: '/allpartner'
      },
    ]
    // hide: true
  },
  {
    path: "/allManu",
    name: "Danh sách thành viên",
    icon: "ni ni-badge text-gray-dark",
    component: AllManu,
    layout: "/admin",
    role: "Z",
    hide: true
  },
  {
    path: "/allProduct",
    name: "Danh sách sản phẩm",
    icon: "ni ni-atom text-teal",
    component: AllProduct,
    layout: "/admin",
    role: "Z",
    // hide: true
  },
  {
    path: "/detailProduct",
    name: "Chi tiết sản phẩm",
    icon: "ni ni-atom text-teal",
    component: AdminObjectDetail,
    layout: "/admin",
    role: "Z",
    hide: true
  },
  {
    path: "/allLegder",
    name: "Danh sách hồ sơ truy xuất của thành viên",
    icon: "ni ni-bag-17 text-green",
    component: DiaryLegderForAdmin,
    layout: "/admin",
    role: "Z",
    // hide: true
  },
  {
    path: "/editLedger",
    name: "Chỉnh sửa hồ sơ truy xuất thành viên",
    icon: "ni ni-bag-17 text-green",
    component: EditLegerForAdmin,
    layout: "/admin",
    role: "Z",
    hide: true
  },
  {
    path: "/allRequest",
    name: "Yêu cầu của thành viên",
    icon: "ni ni-bell-55 text-gray-dark",
    component: RequestPage,
    layout: "/admin",
    role: "Z",
    // hide: true
  },
  {
    path: "/allpartner",
    name: "Danh sách nhà phân phối",
    icon: "ni ni-user-run text-teal",
    component: AllPartner,
    layout: "/admin",
    role: "Z",
    hide: true
  },
  {
    path: "/allRecord",
    name: "Danh sách hồ sơ thành viên",
    icon: "ni ni-folder-17 text-teal",
    component: AllRecordForAdmin,
    layout: "/admin",
    role: "Z",
    hide: true
  },
  {
    path: "/admin_edit_record",
    name: "Chỉnh sửa hồ sơ thành viên",
    icon: "ni ni-folder-17 text-teal",
    component: EditRecordForAdmin,
    layout: "/admin",
    role: "Z",
    hide: true
  },
  {
    path: "/admin_create_record",
    name: "Tạo mới hồ sơ thành viên",
    icon: "ni ni-folder-17 text-teal",
    component: EditRecordForAdmin,
    layout: "/admin",
    role: "Z",
    hide: true
  },
  {
    path: "/createSub",
    name: "Thêm mới tài khoản",
    icon: "ni ni-building text-blue",
    component: RegisterAccount,
    layout: "/admin",
    role: "Z",
    hide: true
  },
  {
    path: "/createSub",
    name: "Thêm mới tài khoản",
    icon: "ni ni-building text-blue",
    component: RegisterAccount,
    layout: "/market",
    role: "U",
    hide: true
  },
  {
    path: "/createPackage",
    name: "Thêm gói mới",
    icon: "ni ni-building text-blue",
    component: CreatePackage,
    layout: "/admin",
    role: "Z",
    hide: true
  },
  {
    path: "/sampleStampList",
    name: "Quản lý tem",
    icon: "ni ni-map-big text-blue",
    component: StampPosition,
    layout: "/admin",
    expand: true,
    id: 'adminExpand',
    role: "Z",
    child: [
      {
        name: "Cấp quyền sử dụng mã số",
        path: '/stampAdmin'
      },
      {
        name: "Cấp quyền sử dụng tem mẫu",
        path: '/enterprise_sampleStamp'
      },
      {
        name: "Quản lý tem mẫu",
        path: '/sampleStampList'
      },
    ]
  },

  {
    path: "/stampAdmin",
    name: "Cấp quyền sử dụng mã số",
    icon: "ni ni-tag text-orange",
    component: ActiveStamp,
    layout: "/admin",
    role: "Z",
    hide: true
  },

  {
    path: "/sampleStampList",
    name: "Danh sách tem mẫu",
    icon: "ni ni-app text-primary",
    component: StampPosition,
    layout: "/admin",
    role: "Z",
    hide: true
  },
  {
    path: "/enterprise_sampleStamp",
    name: "Danh sách quyền sử dụng tem mẫu",
    icon: "ni ni-app text-primary",
    component: EnterpriseWithStamp,
    layout: "/admin",
    role: "Z",
    hide: true
  },
  {
    path: "/createStamp",
    name: "Tạo giao diện tem",
    icon: "ni ni-app text-primary",
    component: CreateStamp,
    layout: "/admin",
    role: "Z",
    hide: true
  },
  {
    path: "/editStamp",
    name: "Chỉnh sửa giao diện tem",
    icon: "ni ni-app text-primary",
    component: EditStamp,
    layout: "/admin",
    role: "Z",
    hide: true
  },
  {
    path: "/overviewAdmin",
    name: "Thống kê",
    icon: "ni ni-single-copy-04 text-danger",
    component: OverviewAdmin,
    layout: "/admin",
    role: "Z",
    // hide: true
  },
  {
    path: "/detailAccount/:id",
    name: "Thông tin tài khoản",
    icon: "ni ni-single-copy-04 text-danger",
    component: DetailAccount,
    layout: "/admin",
    role: "Z",
    hide: true
  },
  {
    path: "/detailPackage/:id",
    name: "Thông tin chi tiết gói",
    icon: "ni ni-single-copy-04 text-danger",
    component: DetailPackage,
    layout: "/admin",
    role: "Z",
    hide: true
  },
  // {
  //   path: "/actions",
  //   name: "Lịch sử hoạt động",
  //   icon: "ni ni-align-center text-cyan",
  //   component: Activity,
  //   layout: "/admin",
  //   role: "Z",
  //   // hide: true
  // },
  {
    path: "/actionsSystem",
    name: "Lịch sử hệ thống",
    icon: "ni ni-align-center text-black",
    component: SystemAction,
    layout: "/admin",
    role: "Z",
    hide: true
  },
  {
    path: "/allDis",
    name: "Nhà phân phối",
    icon: "ni ni-delivery-fast text-gray",
    component: AllDis,
    layout: "/admin",
    role: "Z",
    hide: true
  },
  {
    path: "/createDis",
    name: "Tạo mới nhà phân phối",
    icon: "ni ni-delivery-fast text-gray",
    component: CreateDis,
    layout: "/admin",
    role: "Z",
     hide: true
  },
  {
    path: "/guard",
    name: "Bảo vệ",
    icon: "ni ni-lock-circle-open text-blue",
    component: GuardList,
    layout: "/admin",
    role: "Z",
    hide: true
  },
  {
    path: "/createGuard",
    name: "Tạo bảo vệ mới",
    icon: "ni ni-lock-circle-open text-blue",
    component: CreateGuard,
    layout: "/admin",
    role: "Z",
     hide: true
  },
  {
    path: "/farmer",
    name: "Nông hộ",
    icon: "ni ni-pin-3 text-pink",
    component: FarmerList,
    layout: "/admin",
    role: "Z",
    hide: true
  },
  {
    path: "/createFarmer",
    name: "Tạo nông hộ mới",
    icon: "ni ni-lock-circle-open text-blue",
    component: CreateFamer,
    layout: "/admin",
    role: "Z",
    hide: true
  },
  {
    path: "/profile",
    name: "Thông tin tài khoản",
    icon: "ni ni-app text-primary",
    component: Profile,
    layout: "/admin",
    hide: true,
    role: "ALL"
  },
  //super admin role
  {
    path: "/profile",
    name: "Thông tin tài khoản",
    icon: "ni ni-building text-blue",
    component: Profile,
    layout: "/sadmin",
    role: "S",
    hide: true
  },
  // {
  //   path: "/allManu",
  //   name: "Quản lý đăng ký",
  //   icon: "ni ni-circle-08 text-blue",
  //   component: Customer,
  //   layout: "/sadmin",
  //   role: "S",
  //   // hide: true
  // },
  // {
  //   path: "/package",
  //   name: "Danh sách gói",
  //   icon: "ni ni-money-coins text-yellow",
  //   component: Package,
  //   layout: "/sadmin",
  //   role: "S",
  //   // hide: true
  // },
  {
    path: "/subscribe",
    name: "Quản lý tài khoản",
    icon: "ni ni-badge text-green",
    component: Account,
    expand: true,
    id: 'accountExpand',
    layout: "/sadmin",
    role: "S",
    child: [
      // {
      //   name: "Thành viên",
      //   path: '/subscribe'
      // },
      {
        name: "Thành viên",
        path: '/allManu'
      },
      {
        name: "Danh sách nhà phân phối",
        path: '/allpartner'
      },
      // {
      //   name: "Nhà phân phối",
      //   path: '/allDis'
      // },
      // {
      //   name: "Bảo vệ",
      //   path: '/guard'
      // },
      // {
      //   name: "Nông hộ",
      //   path: '/farmer'
      // },
      {
        name: "Quản trị viên",
        path: '/adminList'
      },
      // {
      //   name: "Danh sách hồ sơ thành viên",
      //   path: '/allRecord'
      // },
      
    ]
    // hide: true
  },
  {
    path: "/adminList",
    name: "Quản lý admin",
    icon: "ni ni-single-02 text-pink",
    component: AdminList,
    layout: "/sadmin",
    role: "S",
    hide: true
  },
  {
    path: "/createAdmin",
    name: "Tạo tài khoản admin",
    icon: "ni ni-single-02 text-pink",
    component: CreateAdmin,
    layout: "/sadmin",
    role: "S",
    hide: true
  },
  {
    path: "/createSub",
    name: "Thêm mới tài khoản",
    icon: "ni ni-building text-blue",
    component: RegisterAccount,
    layout: "/sadmin",
    role: "S",
    hide: true
  },
  {
    path: "/createPackage",
    name: "Thêm gói mới",
    icon: "ni ni-building text-blue",
    component: CreatePackage,
    layout: "/sadmin",
    role: "S",
    hide: true
  },
  {
    path: "/stampAdmin",
    name: "Cấp quyền sử dụng mã số",
    icon: "ni ni-tag text-orange",
    component: ActiveStamp,
    layout: "/sadmin",
    role: "S",
    hide: true
  },

  {
    path: "/allProduct",
    name: "Danh sách sản phẩm",
    icon: "ni ni-atom text-teal",
    component: AllProduct,
    layout: "/sadmin",
    role: "S",
    // hide: true
  },
  {
    path: "/detailProduct",
    name: "Chi tiết sản phẩm",
    icon: "ni ni-atom text-teal",
    component: AdminObjectDetail,
    layout: "/sadmin",
    role: "S",
    hide: true
  },
  {
    path: "/allLegder",
    name: "Danh sách hồ sơ truy xuất của thành viên",
    icon: "ni ni-bag-17 text-green",
    component: DiaryLegderForAdmin,
    layout: "/sadmin",
    role: "S",
    // hide: true
  },
  {
    path: "/editLedger",
    name: "Chỉnh sửa hồ sơ truy xuất thành viên",
    icon: "ni ni-atom text-teal",
    component: EditLegerForAdmin,
    layout: "/sadmin",
    role: "S",
    hide: true
  },
  {
    path: "/allRequest",
    name: "Yêu cầu của thành viên",
    icon: "ni ni-bell-55 text-gray-dark",
    component: RequestPage,
    layout: "/sadmin",
    role: "S",
    // hide: true
  },
  {
    path: "/allPartner",
    name: "Danh sách nhà phân phối",
    icon: "ni ni-user-run text-teal",
    component: AllPartner,
    layout: "/sadmin",
    role: "S",
    hide: true
  },
  {
    path: "/allRecord",
    name: "Danh sách hồ sơ thành viên",
    icon: "ni ni-folder-17 text-teal",
    component: AllRecordForAdmin,
    layout: "/sadmin",
    role: "S",
    hide: true
  },
  {
    path: "/admin_edit_record",
    name: "Chỉnh sửa hồ sơ thành viên",
    icon: "ni ni-folder-17 text-teal",
    component: EditRecordForAdmin,
    layout: "/sadmin",
    role: "S",
    hide: true
  },
  {
    path: "/admin_create_record",
    name: "Tạo mới hồ sơ thành viên",
    icon: "ni ni-folder-17 text-teal",
    component: EditRecordForAdmin,
    layout: "/sadmin",
    role: "S",
    hide: true
  },
  {
    path: "/sampleStampList",
    name: "Quản lý tem",
    icon: "ni ni-map-big text-blue",
    component: StampPosition,
    layout: "/sadmin",
    expand: true,
    id: 'adminExpand',
    role: "S",
    child: [
      {
        name: "Cấp quyền sử dụng mã số",
        path: '/stampAdmin'
      },
      {
        name: "Cấp quyền sử dụng tem mẫu",
        path: '/enterprise_sampleStamp'
      },
      {
        name: "Quản lý tem mẫu",
        path: '/sampleStampList'
      },
    ]
  },

  {
    path: "/sampleStampList",
    name: "Danh sách tem mẫu",
    icon: "ni ni-app text-primary",
    component: StampPosition,
    layout: "/sadmin",
    role: "S",
    hide: true
  },
  {
    path: "/enterprise_sampleStamp",
    name: "Danh sách quyền sử dụng tem mẫu",
    icon: "ni ni-app text-primary",
    component: EnterpriseWithStamp,
    layout: "/sadmin",
    role: "S",
    hide: true
  },
  {
    path: "/createStamp",
    name: "Tạo giao diện tem",
    icon: "ni ni-app text-primary",
    component: CreateStamp,
    layout: "/sadmin",
    role: "S",
    hide: true
  },
  {
    path: "/editStamp",
    name: "Chỉnh sửa giao diện tem",
    icon: "ni ni-app text-primary",
    component: EditStamp,
    layout: "/sadmin",
    role: "S",
    hide: true
  },
  {
    path: "/footer-setting",
    name: "Quản lý cổng thông tin",
    icon: "ni ni-world text-blue",
    component: FooterSetting,
    layout: "/sadmin",
    role: "S",
    expand: true,
    id: 'infoPortManagement',
    child:[
      {
        name:"Cấu hình cổng thông tin",
        path: '/footer-setting'
      } ,
      {
        name:"Cài đặt liên hệ",
        path: '/contact-setting'
      } ,
      // {
      //   name:"Cài đặt quảng cáo",
      //   path: '/ads-setting'
      // } ,        
    ]
  },
  {
    path: "/footer-setting",
    name: "Cấu hình cổng thông tin",
    icon: "ni ni-map-big text-green",
    component: FooterSetting,
    layout: "/sadmin",
    hide: true,
    role: "S"
  },
  {
    path: "/contact-setting",
    name: "Cài đặt liên hệ",
    icon: "ni ni-chat-round text-blue",
    component: ContactSetting,
    layout: "/sadmin",
    hide: true,
    role: "S"
  },
  // {
  //   path: "/ads-setting",
  //   name: "Cài đặt quảng cáo",
  //   icon: "ni ni-notification-70 text-orange",
  //   component: AdsSetting,
  //   layout: "/sadmin",
  //   hide: true,
  //   role: "S"
  // },
  {
    path: "/overviewAdmin",
    name: "Thống kê",
    icon: "ni ni-single-copy-04 text-danger",
    component: OverviewAdmin,
    layout: "/sadmin",
    role: "S",
    // hide: true
  },
  {
    path: "/detailAccount/:id",
    name: "Thông tin tài khoản",
    icon: "ni ni-single-copy-04 text-danger",
    component: DetailAccount,
    layout: "/sadmin",
    role: "S",
    hide: true
  },
  {
    path: "/detailPackage/:id",
    name: "Thông tin chi tiết gói",
    icon: "ni ni-single-copy-04 text-danger",
    component: DetailPackage,
    layout: "/sadmin",
    role: "S",
    hide: true
  },
  // {
  //   path: "/actions",
  //   name: "Lịch sử hoạt động",
  //   icon: "ni ni-align-center text-cyan",
  //   component: ActivitySAdmin,
  //   layout: "/sadmin",
  //   role: "S",
  //   // hide: true
  // },
  {
    path: "/actionsSystem",
    name: "Lịch sử hệ thống",
    icon: "ni ni-align-center text-black",
    component: SystemAction,
    layout: "/sadmin",
    role: "S",
    hide: true
  },
  {
    path: "/allManu",
    name: "Danh sách nhà sản xuất",
    icon: "ni ni-shop text-gray-dark",
    component: AllManu,
    layout: "/sadmin",
    role: "S",
    hide: true
  },
  {
    path: "/allDis",
    name: "Nhà phân phối",
    icon: "ni ni-delivery-fast text-gray",
    component: AllDis,
    layout: "/sadmin",
    role: "S",
    hide: true
  },
  {
    path: "/createDis",
    name: "Tạo mới nhà phân phối",
    icon: "ni ni-delivery-fast text-gray",
    component: CreateDis,
    layout: "/sadmin",
    role: "S",
     hide: true
  },
  {
    path: "/guard",
    name: "Bảo vệ",
    icon: "ni ni-lock-circle-open text-blue",
    component: GuardList,
    layout: "/sadmin",
    role: "S",
    hide: true
  },
  {
    path: "/createGuard",
    name: "Tạo bảo vệ mới",
    icon: "ni ni-lock-circle-open text-blue",
    component: CreateGuard,
    layout: "/sadmin",
    role: "S",
     hide: true
  },
  {
    path: "/farmer",
    name: "Nông hộ",
    icon: "ni ni-pin-3 text-pink",
    component: FarmerList,
    layout: "/sadmin",
    role: "S",
    hide: true
  },
  {
    path: "/createFarmer",
    name: "Tạo nông hộ mới",
    icon: "ni ni-lock-circle-open text-blue",
    component: CreateFamer,
    layout: "/sadmin",
    role: "S",
    hide: true
  },
  {
    path: "/profile",
    name: "Thông tin tài khoản",
    icon: "ni ni-app text-primary",
    component: Profile,
    layout: "/sadmin",
    hide: true,
    role: "ALL"
  },
  // admin cổng truy xuất thông tin chợ hòa cường
  {
    path: "/profile",
    name: "Thông tin tài khoản",
    icon: "ni ni-building text-blue",
    component: Profile,
    layout: "/market",
    role: "U",
    hide: true
  },
  // {
  //   path: "/footer-setting",
  //   name: "Quản lý cổng thông tin",
  //   icon: "ni ni-world text-blue",
  //   component: FooterSetting,
  //   layout: "/market",
  //   role: "U",
  //   expand: true,
  //   id: 'portalSetting',
  //   child:[
  //     {
  //       name:"Cấu hình cổng thông tin",
  //       path: '/footer-setting'
  //     } ,
  //     {
  //       name:"Cài đặt liên hệ",
  //       path: '/contact-setting'
  //     } ,
  //     {
  //       name:"Cài đặt quảng cáo",
  //       path: '/ads-setting'
  //     } ,        
  //   ]
  // },
  {
    path: "/allProduct",
    name: "Danh sách sản phẩm",
    icon: "ni ni-atom text-teal",
    component: AllProduct,
    layout: "/market",
    role: "U",
    // hide: true
  },
  {
    path: "/allManu",
    name: "Thành viên",
    icon: "ni ni-paper-diploma text-danger",
    component: AllManu,
    layout: "/market",
    role: "U",
    // hide: true
  },
  {
    path: "/footer-setting",
    name: "Cấu hình cổng thông tin",
    icon: "ni ni-map-big text-green",
    component: FooterSetting,
    layout: "/market",
    // hide: true,
    role: "U"
  },
  {
    path: "/contact-setting",
    name: "Cài đặt liên hệ",
    icon: "ni ni-chat-round text-blue",
    component: ContactSetting,
    layout: "/market",
    // hide: true,
    role: "U"
  },
  // {
  //   path: "/ads-setting",
  //   name: "Cài đặt quảng cáo",
  //   icon: "ni ni-notification-70 text-orange",
  //   component: AdsSetting,
  //   layout: "/market",
  //   // hide: true,
  //   role: "U"
  // },
  {
    path: "/profile",
    name: "Thông tin tài khoản",
    icon: "ni ni-app text-primary",
    component: Profile,
    layout: "/market",
    hide: true,
    role: "ALL"
  },
  // employee role
  {
    path: "/object",
    name: "Nhật ký sản xuất",
    icon: "ni ni-app text-primary",
    component: AddDiary,
    layout: "/employee",
    role: "SM"
  },
  {
    path: "/activateStamp",
    name: "Kích hoạt tem",
    icon: "ni ni-collection text-pink",
    component: ActiveStampUser,
    layout: "/employee",
    role: "SM",
  },
  //bảo vệ role
  // {
  //   path: "/list",
  //   name: "Danh sách sản phẩm",
  //   icon: "ni ni-app text-primary",
  //   component: ImportList ,
  //   layout: "/guard",
  //   role: "G"
  // },
  {
    path: "/history",
    name: "Lịch sử xuất nhập hàng",
    icon: "ni ni-bullet-list-67 text-pink",
    component: HistoryImport ,
    layout: "/guard",
    role: "G"
  },
  {
    path: "/profile",
    name: "Thông tin tài khoản",
    icon: "ni ni-app text-primary",
    component: Profile,
    layout: "/guard",
    hide: true,
    role: "G"
  },
  {
    path: "/import",
    name: "Thêm mới hành động",
    icon: "ni ni-collection text-pink",
    component: CreateDiaryGuard,
    layout: "/guard",
    role: "G",
    hide: true
  },
  //role nông hộ
  {
    path: "/list",
    name: "Lịch sử đóng thùng",
    icon: "ni ni-app text-primary",
    component: FarmerHistory ,
    layout: "/farmer",
    role: "FA"
  },
  {
    path: "/profile",
    name: "Thông tin tài khoản",
    icon: "ni ni-app text-primary",
    component: Profile,
    layout: "/farmer",
    hide: true,
    role: "FA"
  },
  {
    path: "/addDiary",
    name: "Đóng thùng",
    icon: "ni ni-app text-primary",
    component: FarmerAddDiary ,
    layout: "/farmer",
    role: "FA",
    hide: true
  },
  // role nhà phân phối
  {
    path: "/list",
    name: "Vận chuyển",
    icon: "ni ni-delivery-fast text-orange",
    component: ShipHistory ,
    layout: "/wholesaler",
    role: "W"
  },
  {
    path: "/pack",
    name: "Đóng thùng",
    icon: "ni ni-box-2 text-primary",
    component: FarmerHistory ,
    layout: "/wholesaler",
    role: "W"
  },
  {
    path: "/addDiary",
    name: "Thêm nhật ký",
    icon: "ni ni-app text-primary",
    component: FarmerDiary ,
    layout: "/wholesaler",
    role: "W",
    hide: true
  },
  {
    path: "/profile",
    name: "Thông tin tài khoản",
    icon: "ni ni-app text-primary",
    component: Profile,
    layout: "/wholesaler",
    hide: true,
    role: "W"
  },
  {
    path: "/ship",
    name: "Vận chuyển hàng hóa",
    icon: "ni ni-app text-primary",
    component: ShippingBox ,
    layout: "/wholesaler",
    role: "W",
    hide: true
  },
  {
    path: "/boxList",
    name: 'Cấp quyền',
    icon: "ni ni-circle-08 text-red",
    component: BoxList ,
    layout: "/wholesaler",
    role: "W",
  },
  {
    path: "/approve",
    name: "Cấp quyền nông hộ",
    icon: "ni ni-app text-primary",
    component: ApproveFarmer ,
    layout: "/wholesaler",
    role: "W",
    hide: true
  },
  //all role
  {
    path: "/login",
    name: "Đăng nhập",
    icon: "ni ni-key-25 text-danger",
    component: Login,
    layout: "/auth",
    hide: true,
    role: "ALL"
  },
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: Register,
    layout: "/auth",
    hide: true,
    role: "ALL"
  },
];
export default routes;
