import {
  CREATE_PLAN,
  DEACTIVE_NOTI_PRODUCT, GET_PLAN_LIST,
  GET_PLAN_LIST_FAILURE, GET_PLAN_LIST_SUCCESS,
  PUSH_NOTI_PRODUCT_SUCCESS,
  SAVE_NOTIFICATION
} from "actions/types"
import axios from 'axios'
import { LINK_API } from 'constants/API.js'
import responseCode from "constants/errorCode.js"
import { call, delay, put, select, takeLatest } from 'redux-saga/effects'

const getToken = (state) => state.userInfo


// Create Plan
function * watchCreatePlan (data) {
  yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Đang xử lý", color: 'warning' })
  yield delay(2000)
  yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
  const token = yield select(getToken)
  const { response, error } = yield call(createPlan, data.data, token.data.token)
  if (response) {
    const result = response.data
    if (result.errorCode === 1) {
      yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Tạo vùng gói thành công", color: 'success' })
      yield put({ type: SAVE_NOTIFICATION, data: { type: 'success', message: "Tạo vùng gói thành công " + result.data.uuid} })
      yield put({ type: GET_PLAN_LIST, page: 1, size:  5})
      yield delay(2000)
      yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
    } else {
      if (responseCode[`${result.name}`].vi) {
        yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: responseCode[`${result.name}`].vi, color: 'error' })
        yield put({ type: SAVE_NOTIFICATION, data: { type: 'success', message: responseCode[`${result.name}`].vi} })
        yield delay(2000)
        yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
      } else {
        yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Tạo gói đăng ký thất bại", color: 'error' })
        yield put({ type: SAVE_NOTIFICATION, data: { type: 'success', message: "Tạo gói đăng ký thất bại"} })
        yield delay(2000)
        yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
      }
    }
  } else {
    if (error.response.data.name) {
      yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: responseCode[`${error.response.data.name}`].vi, color: 'error' })
      yield put({ type: SAVE_NOTIFICATION, data: { type: 'success', message: responseCode[`${error.response.data.name}`].vi} })
      yield delay(2000)
      yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
    } else {
      yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Tạo gói đăng ký thất bại", color: 'error' })
      yield put({ type: SAVE_NOTIFICATION, data: { type: 'success', message: "Tạo gói đăng ký thất bại"} })
      yield delay(2000)
      yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
    }
  }
}

function createPlan (data, checktoken) {
  axios.defaults.headers.common.Authorization = checktoken
  return axios.post(LINK_API.CREATE_PLAN, data)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

// Get plan list
function * getPlanList () {
  try {
    const tokenInfo = yield select(getToken)
    const response = yield call(fetchPlan, tokenInfo.data.token)
    const data = response.data
    yield put({ type: GET_PLAN_LIST_SUCCESS, data: data.data, errorCode: data.errorCode })
  } catch (error) {
    yield put({ type: GET_PLAN_LIST_FAILURE, payload: '', errorCode: 0 })
  }
}
function fetchPlan (checktoken) {
  axios.defaults.headers.common.Authorization = checktoken
  return axios({
    method: 'GET',
    url: LINK_API.GET_PLAN_LIST
  })
}


const PlanList = [
  takeLatest(GET_PLAN_LIST, getPlanList),
  takeLatest(CREATE_PLAN, watchCreatePlan)
]
export default PlanList

