import {
  GET_BILL_LIST,
  GET_BILL_LIST_SUCCESS,
  GET_BILL_LIST_FAILURE
} from "actions/types"
import { takeLatest, call, put, select } from 'redux-saga/effects'
import { LINK_API } from 'constants/API.js'
import axios from 'axios'

const getToken = (state) => state.userInfo



// Get bill list for retailer
function * getBillList () {
  try {
    const tokenInfo = yield select(getToken)
    const response = yield call(fetchBillList, tokenInfo.data.token)
    const data = response.data
    yield put({ type: GET_BILL_LIST_SUCCESS, data: data.data, errorCode: data.errorCode })
  } catch (error) {
    yield put({ type: GET_BILL_LIST_FAILURE, payload: '', errorCode: 0 })
  }
}
function fetchBillList (checktoken) {
  axios.defaults.headers.common.Authorization = checktoken
  return axios({
    method: 'GET',
    url: LINK_API.GET_BILL_LIST
  })
}


const handleInvoiceList = [
  takeLatest(GET_BILL_LIST, getBillList)
]

export default handleInvoiceList;
