import { CHECK_STATUS_EXPAND, CHECK_STATUS_EXPAND_SUCCESS, CHECK_STATUS_EXPAND_FAILURE } from 'actions/types.js'
const initialState = { active: false }
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CHECK_STATUS_EXPAND:
      return { ...state }
    case CHECK_STATUS_EXPAND_SUCCESS:
      return {
        ...state,
        active: action.active
      }
    case CHECK_STATUS_EXPAND_FAILURE:
      return { ...state }
    default:
      return state
  }
}
export default reducer
