import { useFormik } from 'formik';
import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Button, Card, Input, Container } from "reactstrap";
import "view/css/select.css";
import * as Yup from 'yup';
import Header from "components/Headers/Header"
import "./css/index.css";

const CreatePartner = (props) => {
  const t = props.t;



  const TypeList = [
    {
      value: 0,
      label: "Sử dụng 1 lần"
    },
    {
      value: 1,
      label: "Plan chính"
    },
  ]

  const UnitList = [
    {
      value: "VND",
      label: "VND"
    },
    {
      value: "USD",
      label: "USD"
    }
  ]

  const TypeTimeList = [
    {
      value: "Y",
      label: "Năm"
    },
    {
      value: "M",
      label: "Tháng"
    },
    {
      value: "D",
      label: "Ngày"
    }
  ]

  const detailPackage = props.detailPackage;

  
  const submitData = async (value) => {
    const data = {
      "maxProductType": value.productNumber,
      "maxProductionObject": value.productObjectNumber,
      "maxProcessingObject": value.processingObjectNumber,
      "maxCrop": value.cropNumber,
      "maxEmployee": value.employee,
      "maxAction": value.actionNumber,
      "maxBizProcess": value.processingNumber,
      "price": value.price,
      "unit": value.unit.value,
      "periodTime": value.duration,
      "typePeriodTime": value.unitTime.value,
      "name": value.name,
      "uuid": props.userInfo.data.uuid,
      "numberStampBox": value.boxStampNumber,
      "numberStampUnit": value.unitStampNumber,
      "type": value.type.value
  }
    props.createPackage(data);
    props.history.push("/admin/package");
  }

  const formik = useFormik({
    initialValues: {
      name: '',
      type: '',
      productNumber: '',
      productObjectNumber: '',
      processingObjectNumber: '',
      cropNumber: '',
      unitStampNumber: '',
      boxStampNumber: '',
      employee: '',
      actionNumber: '',
      processingNumber: '',
      price: '',
      unit: '',
      duration: '',
      unitTime: ''
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required(t('require')),
      type: Yup.string()
        .required(t('require')),
      productNumber: Yup.string()
        .required(t('require')),
      productObjectNumber: Yup.string()
        .required(t('require')),
      processingObjectNumber: Yup.string()
        .required(t('require')),
      cropNumber: Yup.string()
        .required(t('require')),
      unitStampNumber: Yup.string()
        .required(t('require')),
      boxStampNumber: Yup.string()
        .required(t('require')),
      employee: Yup.string()
        .required(t('require')),
      actionNumber: Yup.string()
        .required(t('require')),
      processingNumber: Yup.string()
        .required(t('require')),
      price: Yup.string()
        .required(t('require')),
      unit: Yup.string()
        .required(t('require')),
      duration: Yup.string()
        .required(t('require')),
      unitTime: Yup.string()
        .required(t('require')),
    }),
    onSubmit: async (values) => {
      submitData(values)
    },
  });
  React.useEffect(()=>{
    if(detailPackage){
        formik.setFieldValue('name', detailPackage.name)
        formik.setFieldValue('productNumber', detailPackage.maxProductType)
        formik.setFieldValue('productObjectNumber', detailPackage.maxProductionObject)
        formik.setFieldValue('processingObjectNumber', detailPackage.maxProcessingObject)
        formik.setFieldValue('cropNumber', detailPackage.maxCrop)
        formik.setFieldValue('unitStampNumber', detailPackage.numberStampUnit)
        formik.setFieldValue('boxStampNumber', detailPackage.numberStampBox)
        formik.setFieldValue('employee', detailPackage.maxEmployee)
        formik.setFieldValue('actionNumber', detailPackage.maxAction)
        formik.setFieldValue('processingNumber', detailPackage.maxBizProcess)
        formik.setFieldValue('price', detailPackage.price)
        formik.setFieldValue('unit', detailPackage.unit)
        formik.setFieldValue('duration', detailPackage.periodTime)
        formik.setFieldValue('unitTime', detailPackage.typePeriodTime)




    }
  },[detailPackage])
  
  const handleChange_Select = (selectedOption, value) => {
    formik.setFieldValue([value], selectedOption);
  }

  return (
    <>
    <Header></Header>
    <Container className='mt--7' fluid>
      <Card className="card-box mb-5">
        <div className="wizard-steps horizontal">
          <form onSubmit={formik.handleSubmit}>
            <div className="p-4">
              <h5 className="font-size-xl font-weight-bold">{t('package.infoPackage')}</h5>
              <p className="text-black-50 mb-4"></p>
              <div className="form-row ">
                <div className="form-group col-md-6 pr-3">
                  <div className="form-group ">
                    <label className="font-weight-bold" >{t('package.type')}</label>
                    <Select
                      placeholder={t('package.type')}
                      options={TypeList}
                      onChange={(event) => handleChange_Select(event, "type")}
                      value={formik.values.type}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: '0.29rem',
                        borderWidth: 1,
                        colors: {
                          ...theme.colors,
                          primary25: 'rgba(60,68,177,0.15)',
                          primary50: 'rgba(60,68,177,0.15)',
                          primary: '#4FB779'
                        }
                      })}
                    />

                  {formik.touched.type && formik.errors.type ? (
                      <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.type}</p>
                    ) : null}
                  </div>

                  <div className="form-group">
                    <label className="font-weight-bold">{t('package.packageName')} <span className="text-danger">(*)</span></label>
                    <Input
                      placeholder={t('package.packageName')}
                      type="text"
                      name="name"
                      value={formik.values.name}
                      valid={formik.touched.name && formik.errors.name ? false : (formik.values.name ? true : false)}
                      invalid={formik.touched.name && formik.errors.name ? true : false}
                      {...formik.getFieldProps('name')}
                    />
                    {formik.touched.name && formik.errors.name ? (
                      <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.name}</p>
                    ) : null}
                  </div>

                  <div className="form-group">
                    <label className="font-weight-bold">{t('package.productNumber')} <span className="text-danger">(*)</span></label>
                    <Input
                      placeholder={t('package.productNumber')}
                      type="number"
                      name="productNumber"
                      value={formik.values.productNumber}
                      valid={formik.touched.productNumber && formik.errors.productNumber ? false : (formik.values.productNumber ? true : false)}
                      invalid={formik.touched.productNumber && formik.errors.productNumber ? true : false}
                      {...formik.getFieldProps('productNumber')}
                    />
                    {formik.touched.productNumber && formik.errors.productNumber ? (
                      <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.productNumber}</p>
                    ) : null}
                  </div>

                  
                  <div className="form-group">
                    <label className="font-weight-bold">{t('package.productObjectNumber')} <span className="text-danger">(*)</span></label>
                    <Input
                      placeholder={t('package.productObjectNumber')}
                      type="text"
                      name="productObjectNumber"
                      value={formik.values.productObjectNumber}
                      valid={formik.touched.productObjectNumber && formik.errors.productObjectNumber ? false : (formik.values.productObjectNumber ? true : false)}
                      invalid={formik.touched.productObjectNumber && formik.errors.productObjectNumber ? true : false}
                      {...formik.getFieldProps('productObjectNumber')}
                    />
                    {formik.touched.productObjectNumber && formik.errors.productObjectNumber ? (
                      <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.productObjectNumber}</p>
                    ) : null}
                  </div>
                  
                  <div className="form-group">
                    <label className="font-weight-bold">{t('package.processingObjectNumber')} <span className="text-danger">(*)</span></label>
                    <Input
                      placeholder={t('package.processingObjectNumber')}
                      type="number"
                      name="processingObjectNumber"
                      value={formik.values.processingObjectNumber}
                      valid={formik.touched.processingObjectNumber && formik.errors.processingObjectNumber ? false : (formik.values.processingObjectNumber ? true : false)}
                      invalid={formik.touched.processingObjectNumber && formik.errors.processingObjectNumber ? true : false}
                      {...formik.getFieldProps('processingObjectNumber')}
                    />
                    {formik.touched.processingObjectNumber && formik.errors.processingObjectNumber ? (
                      <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.processingObjectNumber}</p>
                    ) : null}
                  </div>

                  <div className="form-group">
                    <label className="font-weight-bold">{t('package.cropNumber')} <span className="text-danger">(*)</span></label>
                    <Input
                      placeholder={t('package.cropNumber')}
                      type="number"
                      name="cropNumber"
                      value={formik.values.cropNumber}
                      valid={formik.touched.cropNumber && formik.errors.cropNumber ? false : (formik.values.cropNumber ? true : false)}
                      invalid={formik.touched.cropNumber && formik.errors.cropNumber ? true : false}
                      {...formik.getFieldProps('cropNumber')}
                    />
                    {formik.touched.cropNumber && formik.errors.cropNumber ? (
                      <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.cropNumber}</p>
                    ) : null}
                  </div>

                  <div className="form-group">
                    <label className="font-weight-bold">{t('package.unitStampNumber')} <span className="text-danger">(*)</span></label>
                    <Input
                      placeholder={t('package.unitStampNumber')}
                      type="number"
                      name="unitStampNumber"
                      value={formik.values.unitStampNumber}
                      valid={formik.touched.unitStampNumber && formik.errors.unitStampNumber ? false : (formik.values.unitStampNumber ? true : false)}
                      invalid={formik.touched.unitStampNumber && formik.errors.unitStampNumber ? true : false}
                      {...formik.getFieldProps('unitStampNumber')}
                    />
                    {formik.touched.unitStampNumber && formik.errors.unitStampNumber ? (
                      <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.unitStampNumber}</p>
                    ) : null}
                  </div>

                  <div className="form-group">
                    <label className="font-weight-bold">{t('package.boxStampNumber')} <span className="text-danger">(*)</span></label>
                    <Input
                      placeholder={t('package.boxStampNumber')}
                      type="number"
                      name="boxStampNumber"
                      value={formik.values.boxStampNumber}
                      valid={formik.touched.boxStampNumber && formik.errors.boxStampNumber ? false : (formik.values.boxStampNumber ? true : false)}
                      invalid={formik.touched.boxStampNumber && formik.errors.boxStampNumber ? true : false}
                      {...formik.getFieldProps('boxStampNumber')}
                    />
                    {formik.touched.boxStampNumber && formik.errors.boxStampNumber ? (
                      <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.boxStampNumber}</p>
                    ) : null}
                  </div>

                </div>
                <div className="form-group col-md-6 pr-3">
                  <div className="form-group">
                    <label className="font-weight-bold">{t('package.employee')} <span className="text-danger">(*)</span></label>
                    <Input
                      placeholder={t('package.employee')}
                      type="number"
                      name="employee"
                      value={formik.values.employee}
                      valid={formik.touched.employee && formik.errors.employee ? false : (formik.values.employee ? true : false)}
                      invalid={formik.touched.employee && formik.errors.employee ? true : false}
                      {...formik.getFieldProps('employee')}
                    />
                    {formik.touched.employee && formik.errors.employee ? (
                      <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.employee}</p>
                    ) : null}
                  </div>

                  <div className="form-group">
                    <label className="font-weight-bold">{t('package.actionNumber')} <span className="text-danger">(*)</span></label>
                    <Input
                      placeholder={t('package.actionNumber')}
                      type="number"
                      name="actionNumber"
                      value={formik.values.actionNumber}
                      valid={formik.touched.actionNumber && formik.errors.actionNumber ? false : (formik.values.actionNumber ? true : false)}
                      invalid={formik.touched.actionNumber && formik.errors.actionNumber ? true : false}
                      {...formik.getFieldProps('actionNumber')}
                    />
                    {formik.touched.actionNumber && formik.errors.actionNumber ? (
                      <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.actionNumber}</p>
                    ) : null}
                  </div>

                  <div className="form-group">
                    <label className="font-weight-bold">{t('package.processingNumber')} <span className="text-danger">(*)</span></label>
                    <Input
                      placeholder={t('package.processingNumber')}
                      type="number"
                      name="processingNumber"
                      value={formik.values.processingNumber}
                      valid={formik.touched.processingNumber && formik.errors.processingNumber ? false : (formik.values.processingNumber ? true : false)}
                      invalid={formik.touched.processingNumber && formik.errors.processingNumber ? true : false}
                      {...formik.getFieldProps('processingNumber')}
                    />
                    {formik.touched.processingNumber && formik.errors.processingNumber ? (
                      <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.processingNumber}</p>
                    ) : null}
                  </div>

                  <div className="form-group">
                    <label className="font-weight-bold">{t('price.link')} <span className="text-danger">(*)</span></label>
                    <Input
                      placeholder={t('price.link')}
                      type="number"
                      name="price"
                      value={formik.values.price}
                      valid={formik.touched.price && formik.errors.price ? false : (formik.values.price ? true : false)}
                      invalid={formik.touched.price && formik.errors.price ? true : false}
                      {...formik.getFieldProps('price')}
                    />
                    {formik.touched.price && formik.errors.price ? (
                      <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.price} </p>
                    ) : null}
                  </div>

                  <div className="form-group ">
                    <label className="font-weight-bold" >{t('package.unit')} <span className="text-danger">(*)</span></label>
                    <Select
                      placeholder={t('package.unit')}
                      options={UnitList}
                      onChange={(event) => handleChange_Select(event, "unit")}
                      value={formik.values.unit}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: '0.29rem',
                        borderWidth: 1,
                        colors: {
                          ...theme.colors,
                          primary25: 'rgba(60,68,177,0.15)',
                          primary50: 'rgba(60,68,177,0.15)',
                          primary: '#4FB779'
                        }
                      })}
                    />
                    {formik.touched.unit && formik.errors.unit ? (
                      <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.unit}</p>
                    ) : null}
                  </div>

                  <div className="form-group">
                    <label className="font-weight-bold">{t('package.duration')} <span className="text-danger">(*)</span></label>
                    <Input
                      placeholder={t('package.duration')}
                      type="number"
                      name="duration"
                      value={formik.values.duration}
                      valid={formik.touched.duration && formik.errors.duration ? false : (formik.values.duration ? true : false)}
                      invalid={formik.touched.duration && formik.errors.duration ? true : false}
                      {...formik.getFieldProps('duration')}
                    />
                    {formik.touched.duration && formik.errors.duration ? (
                      <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.duration}</p>
                    ) : null}
                  </div>

                  <div className="form-group ">
                    <label className="font-weight-bold" >{t('package.unitTime')} <span className="text-danger">(*)</span></label>
                    <Select
                      placeholder={t('package.unitTime')}
                      options={TypeTimeList}
                      onChange={(event) => handleChange_Select(event, "unitTime")}
                      value={formik.values.unitTime}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: '0.29rem',
                        borderWidth: 1,
                        colors: {
                          ...theme.colors,
                          primary25: 'rgba(60,68,177,0.15)',
                          primary50: 'rgba(60,68,177,0.15)',
                          primary: '#4FB779'
                        }
                      })}
                    />
                    {formik.touched.unitTime && formik.errors.unitTime ? (
                      <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.unitTime}</p>
                    ) : null}
                  </div>

                </div>

              </div>
              <div className="form-row">
              </div>

              <div className="form-row ">
                <div className="form-group col-md-6">
                  <p><span className="text-danger">(*)</span> Trường thông tin bắt buộc</p>
                </div>
              </div>

              <div className="form-row ">
                <div className="form-group col-md-6 d-flex">
                  <Button
                    size="lg"
                    type="submit"
                    className="text-uppercase font-weight-bold font-size-sm"
                    color="info">
                    Lưu lại
                  </Button>
                  <Button
                    size="lg"
                    type="submit"
                    className="text-uppercase font-weight-bold font-size-sm"
                    color="danger">
                   Xóa gói
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </Card>
      </Container>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
    detailPackage: state.detailPackage.data
  }
}

const mapDispatchToProps = (dispatch) => ({
  createPackage: (data) => dispatch({ type: "CREATE_PLAN", data: data }),
});

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(CreatePartner));