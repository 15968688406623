import { 
  GET_BRANCH_LIST,
  GET_BRANCH_LIST_FAILURE,
  GET_BRANCH_LIST_SUCCESS,
 } from "actions/types"
import { takeLatest, call, put, select } from 'redux-saga/effects'
import { LINK_API } from 'constants/API.js'
import axios from 'axios'

const getToken = (state) => state.userInfo


// Get branch list
function * getBranchList () {
  try {
    const tokenInfo = yield select(getToken)
    const response = yield call(fetchBranch, tokenInfo.data.token)
    const data = response.data
    yield put({ type: GET_BRANCH_LIST_SUCCESS, data: data.data, errorCode: data.errorCode })
  } catch (error) {
    yield put({ type: GET_BRANCH_LIST_FAILURE, payload: '', errorCode: 0 })
  }
}
function fetchBranch (checktoken) {
  axios.defaults.headers.common.Authorization = checktoken
  return axios({
    method: 'GET',
    url: LINK_API.GET_BRANCH_LIST
  })
}


const branchList = [
  takeLatest(GET_BRANCH_LIST, getBranchList)
]
export default branchList

