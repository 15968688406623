import {
    GET_HARVEST_QUANTITY,
    GET_HARVEST_QUANTITY_SUCCESS,
    GET_HARVEST_QUANTITY_FAILURE,
  } from "actions/types";
  import { takeLatest, call, put, select } from 'redux-saga/effects'
  import { LINK_API } from 'constants/API.js'
  import axios from 'axios'
  import moment from "moment";
  const getToken = (state) => state.userInfo
  
  // Get user profile
  function* getStampScan(payload) {
    try {
      const token = yield select(getToken)
      const response = yield call(fetchScan, payload.startTime, payload.endTime, payload.cycle, token.data.token)
      const data = response.data.data;
      let label = [];
      let value = [];
      let startWeek = moment(payload.startTime, "DD-MM-YYYY").week();
      let startYear = moment(payload.startTime, "DD-MM-YYYY").year();
      let endWeek = moment(payload.endTime, "DD-MM-YYYY").week();
      let endYear = moment(payload.endTime, "DD-MM-YYYY").year();
      for (let i = 0; i < data.length; i++) {
        let j = startWeek;
        if (i === 0) {
          while (data[i].week > j) {
            label.push("T" + j);
            value.push(0)
            j++;
  
          }
          label.push("T" + data[i].week)
          value.push(data[i].quantity)
        }
        if (i !== data.length - 1) {
          if (data[i].week < data[i + 1].week - 1) {
            if (i !== 0) {
              label.push("T" + data[i].week)
              value.push(data[i].quantity)
            }
  
            for (let x = data[i].week + 1; x < data[i + 1].week; x++) {
              label.push("T" + x)
              value.push(0)
            }
  
          } else {
            if (i !== 0) {
              label.push("T" + data[i].week)
              value.push(data[i].quantity)
            }
  
          }
        } else {
          let j = data[i].week;
          while (j < endWeek) {
            label.push("T" + j);
            value.push(0)
            j++;
          }
          if(data[i].week === endWeek){
            label.push("T" + data[i].week)
            value.push(data[i].quantity)
          }else{
            label.push("T" + endWeek)
          value.push(0)
          }
          
        }
  
      }
      const responseMonth = yield call(fetchScan, payload.startTime, payload.endTime, "MONTH", token.data.token);
    const dataMonth = responseMonth.data.data;
    console.log("dataMonth", dataMonth)
    let labelMonth = [];
    let valueMonth = [];
    let startMonth = moment(payload.startTime, "DD-MM-YYYY").month() + 1;
    let endMonth = moment(payload.endTime, "DD-MM-YYYY").month() + 1;
    if (startYear === endYear) {
      for (let i = 0; i < dataMonth.length; i++) {
        let j = startMonth;
        if (i === 0) {
          while (dataMonth[i].month > j) {
            labelMonth.push("T" + j + ` (${dataMonth[i].year})`);
            valueMonth.push(0)
            j++;
          }
          labelMonth.push("T" + dataMonth[i].month + ` (${dataMonth[i].year})`)
          valueMonth.push(dataMonth[i].count)
        }
        if (i !== dataMonth.length - 1) {
          if (dataMonth[i].month < dataMonth[i + 1].month - 1) {
            if (i !== 0) {
              labelMonth.push("T" + dataMonth[i].month + ` (${dataMonth[i].year})`)
              valueMonth.push(dataMonth[i].count)
            }

            for (let x = dataMonth[i].month + 1; x < dataMonth[i + 1].month; x++) {
              labelMonth.push("T" + x + ` (${dataMonth[i].year})`)
              valueMonth.push(0)
            }

          } else {
            if (i !== 0) {
              labelMonth.push("T" + dataMonth[i].month + ` (${dataMonth[i].year})`)
              valueMonth.push(dataMonth[i].count)
            }
          }
        } else {
          let j = dataMonth[i].month + 1;
          while (j < endMonth) {
            labelMonth.push("T" + j + ` (${dataMonth[i].year})`);
            valueMonth.push(0)
            j++;
          }
          if (dataMonth[i].month === endMonth) {
            labelMonth.push("T" + dataMonth[i].month + ` (${dataMonth[i].year})`)
            valueMonth.push(dataMonth[i].count)
          } else {
            labelMonth.push("T" + endMonth + ` (${dataMonth[i].year})`)
            valueMonth.push(0)
          }

        }

      }
    } else {
      if (startYear < endYear) {
        if (dataMonth.length === 0) {
          for (let i = startMonth; i <= 12; i++) {
            labelMonth.push("T" + i + ` (${startYear})`)
            valueMonth.push(0)
          }
          for (let i = 1; i <= endMonth; i++) {
            labelMonth.push("T" + i + ` (${endYear})`)
            valueMonth.push(0)
          }
        }
        for (let i = 0; i < dataMonth.length; i++) {
          if (dataMonth[i].year < endYear) {
            let j = startMonth;
            if (i === 0) {
              while (dataMonth[i].month > j) {
                labelMonth.push("T" + j + ` (${dataMonth[i].year})`);
                valueMonth.push(0);
                j++;
              }
              labelMonth.push("T" + dataMonth[i].month + ` (${dataMonth[i].year})`);
              valueMonth.push(dataMonth[i].count);
            }

            if (i !== dataMonth.length - 1) {
              if (dataMonth[i + 1].year < endYear) {
                if (i !== 0) {
                  labelMonth.push("T" + dataMonth[i].month + ` (${dataMonth[i].year})`);
                  valueMonth.push(dataMonth[i].count);
                }
                for (let x = dataMonth[i].month + 1; x < dataMonth[i + 1].month; x++) {
                  labelMonth.push("T" + x + ` (${dataMonth[i].year})`);
                  valueMonth.push(0);
                }
              } else {

                if (i !== 0) {
                  labelMonth.push("T" + dataMonth[i].month + ` (${dataMonth[i].year})`);
                  valueMonth.push(dataMonth[i].count);
                }
                for (let x = dataMonth[i].month + 1; x <= 12; x++) {
                  labelMonth.push("T" + x + ` (${dataMonth[i].year})`);
                  valueMonth.push(0);
                }
                if (dataMonth[i].year < endYear) {
                  for (let y = 1; y < dataMonth[i + 1].month; y++) {
                    labelMonth.push("T" + y + ` (${dataMonth[i + 1].year})`);
                    valueMonth.push(0);
                  }
                } else {
                  for (let y = dataMonth[i].month + 1; y < dataMonth[i + 1].month; y++) {
                    labelMonth.push("T" + y + ` (${dataMonth[i].year})`);
                    valueMonth.push(0);
                  }
                }
              }
            } else {
              if (dataMonth[i].year < endYear) {
                labelMonth.push("T" + dataMonth[i].month + ` (${dataMonth[i].year})`);
                  valueMonth.push(dataMonth[i].count);
                for (let x = dataMonth[i].month + 1; x <= 12; x++) {
                  labelMonth.push("T" + x + ` (${dataMonth[i].year})`);
                  valueMonth.push(0);
                }
                for (let y = 1; y <= endMonth; y++) {
                  labelMonth.push("T" + y + ` ${endYear}`);
                  valueMonth.push(0);
                }
              }
            }

          } else {
            if (i !== dataMonth.length - 1) {
              if (dataMonth[i].month < dataMonth[i + 1].month - 1) {
                if (i !== 0) {
                  labelMonth.push("T" + dataMonth[i].month + ` (${dataMonth[i].year})`)
                  valueMonth.push(dataMonth[i].count)
                }
                for (let x = dataMonth[i].month + 1; x < dataMonth[i + 1].month; x++) {
                  labelMonth.push("T" + x + ` (${dataMonth[i].year})`)
                  valueMonth.push(0)
                }
              } else {
                if (i !== 0) {
                  labelMonth.push("T" + dataMonth[i].month + ` (${dataMonth[i].year})`)
                  valueMonth.push(dataMonth[i].count)
                }
              }
            } else {
              let j = dataMonth[i].month + 1;
              while (j < endMonth) {
                labelMonth.push("T" + j + ` (${dataMonth[i].year})`);
                valueMonth.push(0)
                j++;
              }
              if (dataMonth[i].month === endMonth) {
                labelMonth.push("T" + dataMonth[i].month + ` (${dataMonth[i].year})`)
                valueMonth.push(dataMonth[i].count)
              } else {
                labelMonth.push("T" + endMonth + ` (${dataMonth[i].year})`)
                valueMonth.push(endMonth)
              }
            }

          }
        }
      }
    }
    console.log("valueMonth", valueMonth)
      yield put({ type: GET_HARVEST_QUANTITY_SUCCESS, label: label, value: value, labelMonth: labelMonth, valueMonth: valueMonth })
    } catch (error) {
      yield put({ type: GET_HARVEST_QUANTITY_FAILURE })
    }
  }
  function fetchScan(startTime, endTime, cycle, checktoken) {
    axios.defaults.headers.common.Authorization = checktoken
    return axios({
      method: 'GET',
      url: LINK_API.GET_QUANTITY_HARVEST + "startTime=" + startTime + "&endTime=" + endTime + "&cycle=" + cycle
    })
  }
  const actions = [
    takeLatest(GET_HARVEST_QUANTITY, getStampScan)
  ]
  export default actions
  
  