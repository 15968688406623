import axios from 'axios';
import LoadingTable from "components/LoadingTable/index.js";
import Pagination from "components/Pagination/index.jsx";
import { LINK_API } from 'constants/API.js';
import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ReactTable from 'react-table-6';
import SubTable from "components/SubTable/table.js";
// import "../../css/reactTable.css";

function StampList(props) {
  const [activityRender, setActivity] = React.useState([])
  const [loadingState, setLoading] = React.useState(true)
  const { t } = props;

  const {
    userInfo
  } = props;

  useEffect(() => {
    setLoading(true)
    try{
      axios.defaults.headers.common.Authorization = userInfo.data.token
      axios({
        method: 'GET',
        url: LINK_API.ACTIVATE_STAMP_REPORT_ADMIN.concat('?date=', props.date)
      }).then((response) => {
        if (response && response.data && response.data.data) {
          let dataRender = response.data.data.map((item, index) => {
            let dataDetail = []
            if (item.activatedStamp && item.activatedStamp.length > 0) {
              dataDetail = item.activatedStamp.map(itemActivatedStamp => {
                return {
                  startId: itemActivatedStamp.startId,
                  endId: itemActivatedStamp.endId,
                  type: itemActivatedStamp.type,
                  period: itemActivatedStamp.period,
                  number: itemActivatedStamp.number,
                  pt: itemActivatedStamp.pt,
                  date: itemActivatedStamp.date
                }
              })
            }
            return {
              uuid: item.uuid,
              name: item.name,
              dataDetail: dataDetail
            }
          })
          setActivity(dataRender)
          setLoading(false)
        }
        else {
          setActivity([])
          setLoading(false)
        }
      })
      .catch(()=>{
        setActivity([])
        setLoading(false)
      })
    }
    catch{
      setLoading(false)
    }
  }, [props.date, userInfo.data.token]);

  const columns = React.useMemo(
    () => [
      {
        Header: t('profile.uuid'),
        accessor: "uuid",
        sortable: false
      },
      {
        Header: t('profile.name'),
        accessor: "name",
        filterable: false
      }
    ],
    [t]
  );

  const columnSubTable = [
    {
        dataField: 'period',
        text: t('statistic.activedPeriod'),
    },
    {
        dataField: 'number',
        text: t('management.amount'),
    },
    {
        dataField: 'date',
        text: t('statistic.time'),
    },
    {
      dataField: 'startId',
      text: t('statistic.startStamp'),
    },
    {
      dataField: 'endId',
      text: t('statistic.endStamp'),
    },
    {
      dataField: 'pt',
      text: t('product.name'),
    },
]

  return (

    <>
      <div className="divider" />
      <div className="card-body pt-2 px-4 pb-4">
        <ReactTable
          noDataText={t('noData')}
          data={activityRender}
          columns={columns}
          previousText={"<"}
          nextText={">"}
          rowsText={t('row')}
          ofText="/"
          // manual
          defaultPageSize={5}
          showPaginationBottom={true}
          sortable={false}
          resizable={true}
          PaginationComponent={Pagination}
          // pages={totalPage}
          className="-striped -highlight"
          loading={loadingState}
          LoadingComponent={LoadingTable}
          SubComponent={row => {
            return (
              <SubTable columns={columnSubTable} sizePerPage={5} data={row.original.dataDetail} />
            )
          }}
        // onFetchData={async (state, instance) => {
        //   setLoading(true)
        //   let x = state.page + 1;
        //   const page = x;
        //   const size = state.pageSize;
        // }}
        />
      </div>
      {/* </Card> */}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
    activatedStampCVS: state.activatedStampCVS
  }
}

const mapDispatchToProps = (dispatch) => ({
  getAllActivatedStamp: (data) => dispatch({ type: "GET_ALL_ACTIVATED_STAMP_CSV", data: data }),
});

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(StampList));