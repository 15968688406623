import Background from 'assets/images/apps/printObject.jpg'
import {Col, Row} from "reactstrap"
import QRCode from 'react-qr-code'
import React from 'react'

class ObjectPrintTemplate extends React.Component {
  render () {
    const { uuid, name, logo } = this.props

    return (
      <div style={{ backgroundImage: `url(${Background})`, marginLeft: "20px", marginTop: "15px", width: '100vw', height: '100vh', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'contain' }}>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12} style={{ textAlign: 'center', marginTop: '150px' }}>
            <p style={{ fontSize: '40px', fontWeight: '500' }}>{name}</p>
          </Col>
          <Col lg={12} md={12} sm={12} xs={12} style={{ textAlign: 'center' }}>
            <p style={{ fontSize: '40px', fontWeight: '500', color: '#4FB779' }}>{this.props.data.name + " - " + this.props.data.uuid }</p>
          </Col>
          {logo ? 
          <Row style={{display: "flex", flexFlow: "row", marginTop: '30px', width: "100%"}}>
              <Col md={6}  style={{ textAlign: 'center' }}>
            <img alt="logo" src={logo} style={{height: "300px"}}/>
              </Col>
              <Col md={6}  style={{ textAlign: 'center'}}>
              <QRCode value={'https://etrace.kontum.vn/q/' + uuid} size={300} />
              </Col>
          </Row>
          :
          <Col lg={12} md={12} sm={12} xs={12} style={{ textAlign: 'center', marginTop: '30px' }}>
            <QRCode value={'https://etrace.kontum.vn/q/' + uuid} size={300} />
          </Col>}
        </Row>
      </div>
    )
  }
}

export default ObjectPrintTemplate;
