import {
GET_PORTAL_SETTING,
GET_PORTAL_SETTING_SUCCESS,
GET_PORTAL_SETTING_FAILURE,
GET_ADS_SETTING,
GET_ADS_SETTING_SUCCESS,
GET_ADS_SETTING_FAILURE
} from "actions/types"
import { takeLatest, call, put, select, delay } from 'redux-saga/effects'
import { LINK_API } from 'constants/API.js'
import axios from 'axios'
import responseCode from 'constants/errorCode.js'

// Fetch portal setting function
function fetchPortalSetting () {
    return axios.get( LINK_API.PORTAL_SETTING );
}

function * handlePortalSetting () {
    try {
        const response = yield call(fetchPortalSetting)
        const data = response.data
        yield put({ type: GET_PORTAL_SETTING_SUCCESS, data: data.data, errorCode: data.errorCode })
    } catch (error) {
        yield put({ type: GET_PORTAL_SETTING_FAILURE, error, errorCode: 0 })
    }
}

// Fetch portal setting function
function fetchAdsSetting () {
    return axios.get( LINK_API.ADS_SETTING );
}

function * handleAdsSetting () {
    try {
        const response = yield call(fetchAdsSetting)
        const data = response.data
        yield put({ type: GET_ADS_SETTING_SUCCESS, data: data.data, errorCode: data.errorCode })
    } catch (error) {
        yield put({ type: GET_ADS_SETTING_FAILURE, error, errorCode: 0 })
    }
}

const actions = [
    takeLatest(GET_PORTAL_SETTING, handlePortalSetting),
    takeLatest(GET_ADS_SETTING, handleAdsSetting),
]
export default actions