import { default as Axios, default as axios } from "axios";
import Error from "components/alert/error";
import Loading from "components/alert/loading";
import Success from "components/alert/success";
import DropZone from "components/dropZone";
import { LINK_API } from "constants/API";
//formik
import { useFormik } from 'formik';
import React from "react";
import cookie from "react-cookies";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import Header from "components/Headers/Header"
import { Button, Card, CustomInput, Input, Container } from "reactstrap";
//style
import "view/css/select.css";
import * as Yup from 'yup';
import { Editor } from 'react-draft-wysiwyg';
import { convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

const CryptoJS = require('crypto-js')
const EthereumTx = require('ethereumjs-tx');

const CreateObjectProduction = (props) => {
  const t = props.t;
  const userInfo = props.userInfo;
  const productListState = props.productList;
  const processListState = props.processList;
  const areaListState = props.areaList;
  const permission = props.permission.data;

  const {
    renderProductList,
    renderProcessList,
    renderAreaList,
    getShipList
  } = props;

  const [listProduct, setProduct] = React.useState([]);
  const [listProcess, setProcess] = React.useState([]);
  const [listArea, setArea] = React.useState([])
  const [listImage, setImage] = React.useState([]);
  const [alert, setAlert] = React.useState(null);
  const [listCert, setCert] = React.useState([]);
  const [editorState, setEditor] = React.useState(EditorState.createEmpty());
  const handleAva = (data) => {
    setImage(data);
    document.getElementById("avaNoti").innerHTML = "";
  }
  const hideAlert = () => {
    setAlert(null)
  }
  const handleChangeEditor = async (editorState) => {
    document.getElementById("desNoti").innerHTML = "";
    setEditor(editorState)
}

  const handleCreateCrop = (uuid, process, product, dynamicContent) => {
    setAlert(<Loading title="Đang tạo hồ sơ truy xuất ... " />)
    let data = {
      "startTime": new Date(),
      "uuidOfProductionObject": uuid,
      "processCode": process,
      "uuidOfProductType": product,
      "images": listImage,
      "dynamicContent": dynamicContent,
    }
    axios.post(LINK_API.ASSIGN_CROP, data)
      .then(res => {
        if (res.data.errorCode === 1) {
          handleRawCrop(res.data.data, uuid, data);
        } else {
          setAlert(<Error onClose={hideAlert} confirmBtnText={t('close')} cancelBtnText={t('close')} />)
        }
      })
      .catch(err => {
        setAlert(<Error onClose={hideAlert} confirmBtnText={t('close')} cancelBtnText={t('close')} />)
      })
  }
  const handleDetail = async (uuid) => {
    await cookie.save("uuidOProduction", uuid, { path: "/" });
    props.history.push("/user/detailObjectProduction")
  }
  const handleRawCrop = (dataRaw, uuid, data) => {
    var bytes = CryptoJS.AES.decrypt(userInfo.data.privateEncrypted.toString(), userInfo.password)
    const decrypt_privatekey = bytes.toString(CryptoJS.enc.Utf8)
    const privatekey_slice = decrypt_privatekey.substring(2, decrypt_privatekey.length)
    const Buffer_privatekey = Buffer.from(privatekey_slice.toString(), 'hex')
    const tx = new EthereumTx(dataRaw.rawFungibleAsset, { chain: 4 });
    tx.sign(Buffer_privatekey)
    const rawTx = '0x' + tx.serialize().toString('hex');
    console.log("data", data)
    const rawtosend = {
      ...dataRaw,
      ...data,
      "raw": rawTx,
      "uuidOfProductionObject": uuid,
      "bc_id": dataRaw.bc_id
    }
    axios.post(LINK_API.SEND_RAW_CROP, rawtosend)
      .then(res => {
        if (res.data.errorCode === 1) {
          setAlert(<Success onClose={() => handleDetail(uuid)} title="Thành công!" onConfirm={() => handleDetail(uuid)} hideCancel={true} confirmBtnText={t('confirm')} />)
          // setAlert(<Loading title="Đang cấp quyền cho nhà phân phối ... " />)
          // handleApprove(uuid)
        } else {
          setAlert(<Error onClose={hideAlert} confirmBtnText={t('close')} cancelBtnText={t('close')} />)
        }
      })
      .catch(err => {
        setAlert(<Error onClose={hideAlert} confirmBtnText={t('close')} cancelBtnText={t('close')} />)
      })
  }
  const handleApprove = (uuid) => {
    let dataApprove = {
      "uuidOfObject": uuid,
      "uuidOfWholeSaler": formik.values.wholesaler
    }
    axios.post(LINK_API.APPROVE_WHOLESALER, dataApprove)
      .then(res => {
        if (res.data.errorCode === 1) {
          setAlert(<Success onClose={() => handleDetail(uuid)} title="Thành công!" onConfirm={() => handleDetail(uuid)} hideCancel={true} confirmBtnText={t('confirm')} />)
        } else {
          setAlert(<Error onClose={hideAlert} confirmBtnText={t('close')} cancelBtnText={t('close')} />)
        }
      })
      .catch(err => {
        setAlert(<Error onClose={hideAlert} confirmBtnText={t('close')} cancelBtnText={t('close')} />)
      })
  }
  const handleCreateObject = (data) => {
    setAlert(<Loading title="Đang tạo sản phẩm ... " />)
    Axios.post(LINK_API.CREATE_OBJECT_PRODUCTION, data)
      .then(res => {
        if (res.data.errorCode === 1) {
          handleCreateCrop(res.data.data.uuid, data.processCode, data.productType, data.dynamicContent)
        } else {
          setAlert(<Error onClose={hideAlert} confirmBtnText={t('close')} />)
        }
      })
      .catch(err => {
        setAlert(<Error onClose={hideAlert} confirmBtnText={t('close')} />)
      })
  }
  React.useEffect(() => {
    getShipList();
  }, [getShipList])
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: '',
      wholesaler: '',
      product: listProduct && listProduct[0] ? listProduct[0].value : 'noProduct',
      area: areaListState && areaListState.data && areaListState.data[0] ? areaListState.data[0].value : '',
      process: processListState && processListState.data && processListState.data[0] ? processListState.data[0].value : '',
      type: 1,
      number: '',
      status: 1,
      description: ''
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Thông tin bắt buộc")
    }),
    onSubmit: async (values) => {
      let dynamicContent = await draftToHtml(convertToRaw(editorState.getCurrentContent()))
      dynamicContent = await dynamicContent.toString()
      dynamicContent = await dynamicContent.replace("↵", '')
      const data = {
        name: values.name,
        images: listImage,
        zoneUuid: values.area,
        productType: values.product,
        processCode: values.process,
        gln: values.gtin,
        price: parseInt(values.price),
        unit: "VND",
        certificates: listCert,
        description: values.description,
        type: 1,
        "dynamicContent": dynamicContent,
      }
      handleCreateObject(data)
    },
  });

  React.useEffect(() => {
    renderProductList(1, 100);
    renderProcessList();
    renderAreaList(1, 100);
  }, [renderProductList, renderProcessList, renderAreaList])

  React.useEffect(() => {
    if (productListState.data) {
      if (productListState.data.length > 0) {
        setProduct(productListState.data)
      }
    }
  }, [productListState])

  React.useEffect(() => {
    if (processListState.data) {
      if (processListState.data.length > 0) {
        setProcess(processListState.data)
      }
    }
  }, [processListState])

  React.useEffect(() => {
    if (areaListState.data) {
      if (areaListState.data.length > 0) {
        setArea(areaListState.data)
      }
    }
  }, [areaListState])

  const validateGtin = function (valueInt) {

    if (!valueInt) return true;
    const value = valueInt.toString();
    var barcode = value.substring(0, value.length - 1);
    var checksum = parseInt(value.substring(value.length - 1), 10);
    var calcSum = 0;
    var calcChecksum = 0;

    barcode.split('').map(function (number, index) {
      number = parseInt(number, 10);
      if (value.length % 2 === 0) {
        index += 1;
      }
      if (index % 2 === 0) {
        calcSum += number;
      }
      else {
        calcSum += number * 3;
      }
    });

    calcSum %= 10;
    calcChecksum = (calcSum === 0) ? 0 : (10 - calcSum);

    if (calcChecksum !== checksum) {
      return false;
    }

    return true;

  };

  const statusList = [
    {
      name: "Đang sản xuất",
      value: 1
    },
    {
      name: "Ngưng sản xuất",
      value: 1
    },
  ]

  const handleCert = (data) => {
    setCert(data)
    document.getElementById("certNoti").innerHTML = "";
  }

  return (
    <>
      {
        permission && permission.agdqr ? <div></div> :
          <>
            <Header />
            <Container className='mt--7' fluid>
              <Card className="card-box mb-5">
                <div className="wizard-steps horizontal">
                  <form onSubmit={formik.handleSubmit}>
                    <div className="p-4">
                      <h5 className="font-size-xl font-weight-bold">{t('oProduction.oInfo')}</h5>
                      <div className="form-row">
                        <div className="form-group col-md-6 pr-3">
                          <label className="font-weight-bold">{t('oProduction.name')} <span className="text-danger">(*)</span></label>
                          <Input
                            placeholder={t('oProduction.name')}
                            type="text"
                            maxLength={120}
                            value={formik.values.name}
                            valid={formik.touched.name && formik.errors.name ? false : (formik.values.name ? true : false)}
                            invalid={formik.touched.name && formik.errors.name ? true : false}
                            {...formik.getFieldProps('name')}
                          />
                          {formik.touched.name && formik.errors.name ? (
                            <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.name}</p>
                          ) : null}
                        </div>
                        <div className="form-group col-md-6 pl-3">
                          <label className="font-weight-bold">Mô tả</label>
                          <Input
                            type="text"
                            name="description"
                            id="numberBox"
                            placeholder='Mô tả'
                            value={formik.values.description}
                            valid={formik.touched.description && formik.errors.description ? false : (formik.values.description ? true : false)}
                            invalid={formik.touched.description && formik.errors.description ? true : false}
                            onChange={formik.handleChange}
                            {...formik.getFieldProps('description')}
                          >
                          </Input>
                        </div>
                      </div>
                      <div className="form-row ">
                        <div className="form-group col-md-6 pr-3">
                          <label className="font-weight-bold">{t('product.retailPrice')}</label>
                          <Input
                            placeholder={"Giá"}
                            type="number"
                            valid={formik.touched.price && formik.errors.price ? false : (formik.values.price ? true : false)}
                            invalid={formik.touched.price && formik.errors.price ? true : false}
                            value={formik.values.price}
                            {...formik.getFieldProps('price')}
                          />
                        </div>
                        <div className="form-group col-md-6 pl-3">
                          <label className="font-weight-bold" >{t('product.gtin')}</label>
                          <Input
                            validate={validateGtin}
                            placeholder={t('product.gtin')}
                            type="number"
                            valid={formik.touched.gtin && formik.errors.gtin ? false : (formik.values.gtin ? true : false)}
                            invalid={formik.touched.gtin && formik.errors.gtin ? true : false}
                            value={formik.values.gtin}
                            {...formik.getFieldProps('gtin')}
                          />
                          {formik.touched.gtin && formik.errors.gtin ? (
                            <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.gtin}</p>
                          ) : null}
                        </div>
                      </div>
                      <div className="form-row ">
                        <div className="form-group col-md-6 pr-3">
                          <label className="font-weight-bold">{t('oProduction.product')} <span className="text-danger">(*)</span></label>
                          <CustomInput
                            type="select"
                            name="customSelect"
                            id="selectType"
                            placeholder={t('oProduction.product')}
                            value={formik.values.product}
                            valid={formik.touched.product && formik.errors.product ? false : (formik.values.product ? true : false)}
                            invalid={formik.touched.product && formik.errors.product ? true : false}
                            onChange={formik.handleChange}
                            {...formik.getFieldProps('product')}
                          >
                            <option value="noProduct">{t('oProduction.none') + formik.values.name}</option>
                            {listProduct.map((item, idx) => (
                              <option key={idx} value={item.value}>{item.name}</option>
                            ))}
                          </CustomInput>
                          {formik.touched.product && formik.errors.product ? (
                            <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.product}</p>
                          ) : null}
                        </div>
                        <div className="form-group col-md-6 pl-3">
                          <label className="font-weight-bold">{"Trạng thái hàng hoá"} <span className="text-danger">(*)</span></label>
                          <CustomInput
                            type="select"
                            name="customSelect"
                            id="selectStatus"
                            placeholder={"Trạng thái hàng hoá"}
                            value={formik.values.status}
                            valid={formik.touched.status && formik.errors.status ? false : (formik.values.status ? true : false)}
                            invalid={formik.touched.status && formik.errors.status ? true : false}
                            onChange={formik.handleChange}
                            {...formik.getFieldProps('status')}
                          >
                            {statusList.map((item, idx) => (
                              <option key={idx} value={item.value}>{item.name}</option>
                            ))}
                          </CustomInput>
                          {formik.touched.status && formik.errors.status ? (
                            <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.status}</p>
                          ) : null}
                        </div>
                      </div>
                      <div className="form-row ">
                        <div className="form-group col-md-6 pr-3">
                          <label className="font-weight-bold">{t('oProduction.area')} <span className="text-danger">(*)</span></label>
                          <CustomInput
                            type="select"
                            name="customSelect"
                            id="selectType"
                            placeholder={t('oProduction.area')}
                            value={formik.values.area}
                            valid={formik.touched.area && formik.errors.area ? false : (formik.values.area ? true : false)}
                            invalid={formik.touched.area && formik.errors.area ? true : false}
                            onChange={formik.handleChange}
                            {...formik.getFieldProps('area')}
                          >
                            {listArea.map((item, idx) => (
                              <option key={idx} value={item.value}>{item.label}</option>
                            ))}
                          </CustomInput>
                          {formik.touched.area && formik.errors.area ? (
                            <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.area}</p>
                          ) : null}
                        </div>
                        <div className="form-group col-md-6 pl-3">
                          <label className="font-weight-bold" >{t('oProduction.process')} <span className="text-danger">(*)</span></label>
                          <CustomInput
                            type="select"
                            name="customSelect"
                            id="selectType"
                            placeholder={t('oProduction.process')}
                            value={formik.values.process}
                            valid={formik.touched.process && formik.errors.process ? false : (formik.values.process ? true : false)}
                            invalid={formik.touched.process && formik.errors.process ? true : false}
                            onChange={formik.handleChange}
                            {...formik.getFieldProps('process')}
                          >
                            {/* <option  value="">{t('oProduction.process')}</option> */}
                            {listProcess.map((item, idx) => (
                              <option key={idx} value={item.value}>{item.name}</option>
                            ))}
                          </CustomInput>
                          {formik.touched.process && formik.errors.process ? (
                            <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.process}</p>
                          ) : null}
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-6 pr-3">
                          <label className="font-weight-bold">{t('oProduction.image')}</label>
                          <DropZone
                            max={3}
                            onConfirm={handleAva}
                            data={listImage}
                          />
                          <p id="avaNoti" className="font-weight-regular font-size-sm text-danger" ></p>
                        </div>
                        <div className="form-group col-md-6 pl-3">
                          <label className="font-weight-bold">{t('product.cert')} </label>
                          <DropZone max={3} onConfirm={handleCert} data={listCert} />
                          <p id="certNoti" className="font-weight-regular font-size-sm text-danger" ></p>
                        </div>
                      </div>
                      <div className="form-row ">
                                    <div className="form-group col-md-12">
                                        <label className="font-weight-bold">{t('oProduction.description')}</label>
                                        <Editor
                                            editorState={editorState}
                                            wrapperClassName="demo-wrapper"
                                            editorClassName="demo-editor borderEditor"
                                            onEditorStateChange={handleChangeEditor}
                                            toolbar={{
                                                options: ["inline", 'image', 'embedded', 'textAlign', "link",]
                                            }}
                                        />
                                        <p id="desNoti" className="font-weight-regular font-size-sm text-danger" ></p>
                                    </div>
                                </div>
                      <div className="form-row ">
                        <div className="form-group col-md-6">
                          <p><span className="text-danger">(*)</span>{t('require')}</p>
                        </div>
                        <div className="form-group col-md-6 d-flex justify-content-end">
                          <Button
                            size="lg"
                            type="submit"
                            className="text-uppercase font-weight-bold font-size-sm"
                            color="neutral-success">
                            {t('confirm')}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </Card>
            </Container>
          </>
      }
      {alert}
    </>
  )
}
const mapStateToProps = (state) => {
  return {
    productList: state.renderProduct,
    processList: state.renderProcess,
    areaList: state.renderArea,
    userInfo: state.userInfo,
    permission: state.permission,
    shipList: state.shipList.options
  }
}

const mapDispatchToProps = (dispatch) => ({
  renderProductList: (page, size) => dispatch({ type: "RENDER_PRODUCT_LIST", page: page, size: size }),
  renderAreaList: (page, size) => dispatch({ type: "RENDER_AREA_LIST", page: page, size: size }),
  renderProcessList: () => dispatch({ type: "RENDER_PROCESS_LIST" }),
  createObject: (data) => dispatch({ type: "CREATE_OBJECT", data: data }),
  createObjectProduct: (dataProduct, data) => dispatch({ type: "CREATE_OBJECT_PRODUCT", product: dataProduct, object: data }),
  getShipList: () => dispatch({ type: "GET_SHIP_LIST" })
  // setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable))
});

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(CreateObjectProduction));