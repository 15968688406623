import axios from "axios";
import Error from 'components/alert/error';
import Loading from 'components/alert/loading';
import Success from 'components/alert/success';
import DropZone from "components/dropZone";
import Header from "components/Headers/Header";
import { LINK_API } from "constants/API";
//formik
import { useFormik } from 'formik';
import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Button, Card, Container, CustomInput, Input, UncontrolledPopover,
  ListGroup,
  ListGroupItem,
  Alert } from "reactstrap";
//style
import "view/css/select.css";
import * as Yup from 'yup';
import DatePicker, { registerLocale } from "react-datepicker";
import el from "components/date-fns/locale/vi";
import CalenderIcon from "mdi-react/CalendarIcon";
import moment from "moment";
import DateTimePicker from 'react-datetime-picker'
import Select from "react-select"
import HelpBoxIcon from "mdi-react/HelpBoxIcon";

registerLocale("el", el);

const ethers = require('ethers');
const CryptoJS = require('crypto-js')
const EthereumTx = require('ethereumjs-tx');
const CreateEmployee = (props) => {
  const t = props.t;
  const userInfo = props.userInfo;
  const [alert, setAlert] = React.useState(null)
  const [listImage, setImage] = React.useState([])
  const [listBoxSelect, setListBoxSelect] = React.useState([])
  const [harvestTime, setHarvestTime] = React.useState(new Date());
  const [boxSelected, setSelected] = React.useState('')
  const getBoxList = props.getBoxList;
  const boxList = props.boxList
  const [listBox, setList] = React.useState([])
  const [validStampRange, setValidStampRange] = React.useState('')
  const handleAva = (data) => {
    setImage(data);
  }
  React.useEffect(() => {
    getBoxList();
  }, [getBoxList])
  React.useEffect(() => {
    setList(boxList)
  }, [boxList])
  const formik = useFormik({
    initialValues: {
      id: '',
      description: '',
      // type: ''
    },
    validationSchema: Yup.object({
      id: Yup.string()
        .required(t('require')),
      quantity: Yup.string()
        .required(t('require')),
      // type: Yup.string()
      // .required(t('require')),
      description: Yup.string()
    }),
    onSubmit: async (values) => {

      console.log("harvestTime", harvestTime)
      setAlert(<Loading />);

      let uuidCrop = await getIdOwner(values.id);
      let data_signIn =
      {
        "toId": uuidCrop, // id của mùa vụ
        "ownerOfCrop": values.id.substring(0, 7), // id của doanh nghiệp
        "detail": values.description,
        "description": "Đây là quá trình đóng thùng",
        "images": listImage,
        "quantity": parseInt(values.quantity),
        "startStampID": values.startId,
        "endStampID": values.endId,
        "harvestDate": Date.parse(harvestTime),
        "date": Date.parse(harvestTime)
      }
      axios.post(LINK_API.PACKED_FARMER, data_signIn)
        .then(res => {
          if (res.data.errorCode === 1) {
            // console.log(res.data.data)
            // setAlert(<Success onClose={hideAlert} hideCancel={true} onConfirm={reloadData} confirmBtnText={t('close')} />)
            handleSendRaw(res.data.data)

          } else {
            setAlert(<Error onClose={hideAlert} confirmBtnText="Đóng" />)
          }
        })
        .catch(err => {
          setAlert(<Error title={err.response.data.name} onClose={hideAlert} confirmBtnText="Đóng" />)
        })

    },
  });
  const checkPreStep = () => {
    let response = axios.get(LINK_API.GET_PRE_STEP);
    console.log(response)
  }
  const getIdOwner = async (id) => {

    let owner = id.substring(0, 7)
    let idObject = id.substring(7, id.length)
    let responseData = await axios.get(LINK_API.GET_CROP_LIST + idObject + '&ownedBy=' + owner);
    let dataRes = responseData.data.data;
    let uuid = dataRes[0].uuid;
    return uuid
  }
  const reloadData = () => {
    if (props.userInfo.data.type === 'FA') {
      props.history.push('/farmer/list');
    } else {
      props.history.push('/wholesaler/pack');
    }
    hideAlert();
  }
  const hideAlert = () => {
    setAlert(null)
  }
  const handleVietName = (str) => {
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    str = str.replace(/đ/g, "d");
    str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
    str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
    str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
    str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
    str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
    str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
    str = str.replace(/Đ/g, "D");
    return str;
  }
  const handleSendRaw = (dataRaw) => {
    var bytes = CryptoJS.AES.decrypt(userInfo.data.privateEncrypted.toString(), userInfo.password)
    const decrypt_privatekey = bytes.toString(CryptoJS.enc.Utf8)
    const privatekey_slice = decrypt_privatekey.substring(2, decrypt_privatekey.length)
    const Buffer_privatekey = Buffer.from(privatekey_slice.toString(), 'hex')
    const tx = new EthereumTx(dataRaw.raw, { chain: 4 });
    tx.sign(Buffer_privatekey)
    const rawTx = '0x' + tx.serialize().toString('hex');
    let data = {
      ...dataRaw,
      raw: rawTx,
    }
    axios.post(props.userInfo.data.type === 'FA' || props.userInfo.data.type === 'W' ? LINK_API.SEND_RAW_PACKED : LINK_API.SEND_RAW_IMPORTED, data)
      .then(res => {
        if (res.data.errorCode === 1) {
          setAlert(<Success onClose={hideAlert} hideCancel={true} onConfirm={reloadData} confirmBtnText={t('close')} />)
        } else {
          setAlert(<Error onClose={hideAlert} confirmBtnText="Đóng" />)
        }
      })
      .catch(err => {
        setAlert(<Error onClose={hideAlert} confirmBtnText="Đóng" />)
      })
  }
  const ExampleCustomInput = ({ value, onClick }) => (
    <Button block color="neutral-success" size="sm" onClick={onClick}>
      <span className="btn-wrapper--icon">
        <CalenderIcon size={20} />
      </span>
      <span className="btn-wrapper--label">
        {moment(value).format("DD/MM/YYYY")}
      </span>
    </Button>
  );

  React.useEffect(() => {
    axios.get(props.userInfo.data.type === 'W' ? LINK_API.GET_ALL_BOX_ID_FOR_WHOLESALER : LINK_API.GET_ALL_BOX_ID)
      .then(res => {
        const data = res.data.data.map(item => {
          return {
            value: item.ownedBy + item.uuidOfObject,
            label: item.ownedBy + item.uuidOfObject + " - " +  item.name,
            ownedBy: item.ownedBy
          }
        });

        setListBoxSelect(data)
      })
  }, [])

  const handleGetListStamp = (ownerBy) => {
    axios.get(LINK_API.GET_CURRENT_STAMP_BY_OWNER + ownerBy)
      .then((res) => {
        console.log("res.data.data", res.data.data)
        setValidStampRange(res.data.data)
      })
      .catch(() => {

      })
  }

  return (
    <>
      <Header></Header>
      <Container className=' mt--7' fluid>
        <Card className="card-box mb-5">
          <div className="wizard-steps horizontal">
            <form onSubmit={formik.handleSubmit}>
              <div className="p-4">
                <h5 className="font-size-xl font-weight-bold">Đóng thùng</h5>
                <p className="text-black-50 mb-4">Thông tin đóng thùng</p>
                <div className="form-row ">
                  <div className="form-group col-md-12 pr-3">
                    <div className="form-group">
                      <label className="font-weight-bold">Mã sản phẩm <span className="text-danger">(*)</span></label>
                      <Select
                        placeholder="Chọn mã sản phẩm"
                        options={listBoxSelect}
                        value={boxSelected}
                        onChange={(selectedOption) => {
                          handleGetListStamp(selectedOption.ownedBy)
                          setSelected(selectedOption);
                          formik.setFieldValue('id', selectedOption.value)
                        }}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: '0.29rem',
                          borderWidth: 0.5,
                          height: 30,
                          colors: {
                            ...theme.colors,
                            primary25: 'rgba(60,68,177,0.15)',
                            primary50: 'rgba(60,68,177,0.15)',
                            primary: '#50b77a'
                          }
                        })}
                      />
                       {/* <Input
                        placeholder='Mã sản phẩm'
                        type="text"
                        name="id"
                        value={formik.values.id}
                        valid={formik.touched.id && formik.errors.id ? false : (formik.values.id ? true : false)}
                        invalid={formik.touched.id && formik.errors.id ? true : false}
                        {...formik.getFieldProps('id')}
                      /> */}
                      {formik.touched.id && formik.errors.id ? (
                        <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.id}</p>
                      ) : null}
                    </div>
                    <div className="form-group">
                      <label className="font-weight-bold">Số lượng <span className="text-danger">(*)</span></label>
                      <Input
                        placeholder='Số lượng'
                        type="number"
                        name="quantity"
                        value={formik.values.quantity}
                        valid={formik.touched.quantity && formik.errors.quantity ? false : (formik.values.quantity ? true : false)}
                        invalid={formik.touched.quantity && formik.errors.quantity ? true : false}
                        {...formik.getFieldProps('quantity')}
                      />
                      {formik.touched.quantity && formik.errors.quantity ? (
                        <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.quantity}</p>
                      ) : null}
                    </div>
                    <div className="form-group">
                      <div className="d-flex">
                        <label className="font-weight-bold">Tem bắt đầu <span className="text-danger">(*)</span></label>
                        <HelpBoxIcon id="helpObject" className="text-info ml-2 cursor-pointer card-box-hover  card-box-hover-rise" />
                        <UncontrolledPopover placement="top" trigger="hover" target="helpObject" className="popover-custom-wrapper popover-custom-lg">
                          <ListGroup flush className="text-left bg-transparent">
                            <ListGroupItem className="rounded">
                              <div className="align-box-row">
                                <div className="pl-2">
                                  <span className="pb-1 d-block">
                                    <span className="font-weight-bold">
                                      Khoảng tem bạn có là
                                    </span>
                                  </span>
                                </div>
                              </div>
                              <Alert color="info" className="mt-2">
                                <div className="mt-1">
                                  {
                                    validStampRange && validStampRange.UNIT && validStampRange.UNIT.map(item => {
                                      return <div>{`- Từ ${item.startId} đến ${item.endId}`}</div>
                                    })
                                  }
                                </div>
                              </Alert>
                            </ListGroupItem>
                          </ListGroup>
                        </UncontrolledPopover>
                      </div>
                      <Input
                        placeholder='Tem bắt đầu'
                        type="text"
                        name="startId"
                        value={formik.values.startId}
                        valid={formik.touched.startId && formik.errors.startId ? false : (formik.values.startId ? true : false)}
                        invalid={formik.touched.startId && formik.errors.startId ? true : false}
                        {...formik.getFieldProps('startId')}
                      />
                      {formik.touched.startId && formik.errors.startId ? (
                        <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.startId}</p>
                      ) : null}
                    </div>
                    <div className="form-group">
                      <div className="d-flex">
                        <label className="font-weight-bold">Tem kết thúc <span className="text-danger">(*)</span></label>
                        <HelpBoxIcon id="helpObject" className="text-info ml-2 cursor-pointer card-box-hover  card-box-hover-rise" />
                        <UncontrolledPopover placement="top" trigger="hover" target="helpObject" className="popover-custom-wrapper popover-custom-lg">
                          <ListGroup flush className="text-left bg-transparent">
                            <ListGroupItem className="rounded">
                              <div className="align-box-row">
                                <div className="pl-2">
                                  <span className="pb-1 d-block">
                                    <span className="font-weight-bold">
                                      Khoảng tem bạn có là
                                    </span>
                                  </span>
                                </div>
                              </div>
                              <Alert color="info" className="mt-2">
                                <div className="mt-1">
                                  {
                                    validStampRange && validStampRange.UNIT && validStampRange.UNIT.map(item => {
                                      return <div>{`- Từ ${item.startId} đến ${item.endId}`}</div>
                                    })
                                  }
                                </div>
                              </Alert>
                            </ListGroupItem>
                          </ListGroup>
                        </UncontrolledPopover>
                      </div>
                      <Input
                        placeholder='Tem kết thúc'
                        type="text"
                        name="startId"
                        value={formik.values.endId}
                        valid={formik.touched.endId && formik.errors.endId ? false : (formik.values.endId ? true : false)}
                        invalid={formik.touched.endId && formik.errors.endId ? true : false}
                        {...formik.getFieldProps('endId')}

                      />
                      {formik.touched.endId && formik.errors.endId ? (
                        <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.endId}</p>
                      ) : null}
                    </div>
                    <div className="form-group">
                      <label className="font-weight-bold"> Thông tin sản phẩm</label>
                      <Input
                        placeholder='Thông tin sản phẩm'
                        type="text"
                        name="description"
                        value={formik.values.description}
                        valid={formik.touched.description && formik.errors.description ? false : (formik.values.description ? true : false)}
                        invalid={formik.touched.description && formik.errors.description ? true : false}
                        {...formik.getFieldProps('description')}

                      />
                      {formik.touched.description && formik.errors.description ? (
                        <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.description}</p>
                      ) : null}
                    </div>
                    <div className="form-group">
                      <label className="font-weight-bold"> Thời gian <span className="text-danger">(*)</span></label>
                      <DateTimePicker
                        className='form-control'
                        onChange={setHarvestTime}
                        value={harvestTime}
                        format="dd/MM/y HH:mm"
                        disableClock={true}
                        disableCalendar={true}
                      />
                      {harvestTime ? null : (
                        <p className="font-weight-regular font-size-sm text-danger" >Thông tin bắt buộc</p>
                      )}
                    </div>
                    <div className="form-group" >
                      <label className="font-weight-bold">Hình ảnh</label>
                      <DropZone
                        max={2}
                        onConfirm={handleAva}
                        data={listImage}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-row ">
                  <div className="form-group col-md-6">
                    <p><span className="text-danger">(*)</span> Trường thông tin bắt buộc</p>
                  </div>

                  <div className="form-group col-md-6 d-flex justify-content-end">
                    <Button
                      size="lg"
                      disabled={!harvestTime}
                      type="submit"
                      className="text-uppercase font-weight-bold font-size-sm"
                      color="info">
                      Tạo mới
                    </Button>
                  </div>
                </div>

              </div>

            </form>
          </div>
        </Card>
      </Container>
      {alert}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
    areaList: state.renderArea,
    objectList: state.objectList,
    boxList: state.boxList.options
  }
}

const mapDispatchToProps = (dispatch) => ({
  createEmployee: (data, image) => dispatch({ type: "CREATE_EMPLOYEE", data: data, avatar: image }),
  renderArea: (page, size) => dispatch({ type: "RENDER_AREA_LIST", page: page, size: size }),
  getObjectList: (page, size) => dispatch({ type: "GET_OBJECT_LIST", page: page, size: size }),
  getBoxList: () => dispatch({ type: "GET_BOX_LIST" }),

});

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(CreateEmployee));