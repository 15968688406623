import ContentAlert from 'components/alert/contentAlert';
import Loading from 'components/alert/loading'
import Error from 'components/alert/error'
import Success from 'components/alert/success'
import LoadingTable from "components/LoadingTable/index.js";
import ConfirmAlert from "components/alert/confirmAlert.js";
import Pagination from "components/Pagination/index.jsx";
import { AGD_LOGO } from "constants/image";
import { Field, Form, Formik } from 'formik';
import CloseIcon from "mdi-react/CloseIcon";
import CheckAllIcon from "mdi-react/CheckAllIcon";
import KeyIcon from "mdi-react/KeyVariantIcon";
import PenIcon from "mdi-react/LeadPencilIcon";
import PlusCircle from "mdi-react/PlusCircleIcon";
import moment from 'moment';
import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ReactTable from 'react-table-6';
import Truncate from 'react-truncate';
import { Button, Card, Col, Container, FormGroup, Row, UncontrolledTooltip, UncontrolledDropdown, DropdownToggle, DropdownMenu, NavItem, Nav, Input, CustomInput, NavLink as NavLinkStrap } from 'reactstrap';
import * as Yup from 'yup';
import "../../css/reactTable.css";
import axios from 'axios';
import { LINK_API } from 'constants/API'
import cookie from 'react-cookies';
import FilterIcon from "mdi-react/FilterIcon"
import { useFormik } from 'formik';
import ResetIcon from "mdi-react/RestoreIcon"
import { motion } from "framer-motion";
import Header from "components/Headers/Header"
const ethers = require('ethers');
const CryptoJS = require("crypto-js");

function EmployeeList(props) {
  const getEmployee = props.getEmployee;
  const getEmployeeFilter = props.getEmployeeFilter;
  const employeeList = props.employeeList.data
  const permission = props.permission.data;
  const [checkPer, setPer] = React.useState(false);
  React.useEffect(() => {

    if (permission.maxEmployee !== -1) {
      if (permission.employee >= permission.maxEmployee) {
        setPer(true)
      }
    }
  }, [permission, employeeList])
  const [filterStatus, setFilter] = React.useState(false)
  const [dataEmployeeList, setEmployeeList] = React.useState([])
  const [totalPage, setTotalPage] = React.useState([])
  const [loadingState, setLoading] = React.useState(true)
  const [alert, setAlert] = React.useState(null)
  const { t } = props;
  let linkUrl = "user/";
  if (props.userInfo && props.userInfo.data && props.userInfo.data.type === "F") {
    linkUrl = "distributor/"
  }

  const hideAlert = () => {
    setAlert(null)
  }

  const isEmptyObject = (obj) => {
    if (!obj) return true;
    if (obj.length === 0) return true;
    return Object.entries(obj).length === 0 && obj.constructor === Object
  }
  const handleRole = React.useCallback((type) => {
    if (type === "SF") return (t('employee.femp'));
    if (type === "SP") return (t('employee.pemp'));
    if (type === "SM") return (t('employee.demp'));
    if (type === "SD") return (t('employee.semp'));
    if (type === "SR") return (t('employee.remp'));
    return (t('employee.manager'));
  }, [t])


  const updatePassValidate = Yup.object().shape({
    newPass: Yup.string()
      .min(6, t('tooShort'))
      .required(t('require')),
    rePass: Yup.string()
      .min(6, t('tooShort'))
      .required(t('require'))
      .oneOf([Yup.ref('newPass'), null], t('noMatching'))
  });

  const userInfo = props.userInfo;
  const handleResetPassword = React.useCallback(async (values, path, uuid) => {
    setAlert(<Loading />)

    var bytes = CryptoJS.AES.decrypt(userInfo.data.seedEncrypted.toString(), userInfo.password);
    const randomMnemonic = bytes.toString(CryptoJS.enc.Utf8);
    let walletPath = {
      standard: "m/44'/60'/0'/" + userInfo.data.currentIndexPath + '/' + path
    };
    let hdnode = ethers.utils.HDNode.fromMnemonic(randomMnemonic);
    let node = hdnode.derivePath(walletPath.standard);
    const privatekey = node.privateKey;
    const enPrivateKey_emp = await CryptoJS.AES.encrypt(privatekey, values.newPass.toString());

    var temp_sub = new Buffer(values.newPass).toString('hex');
    const pass_en = await ethers.utils.keccak256('0x' + temp_sub);

    var data = {
      idOfEmployee: uuid,
      newPass: pass_en,
      newPrivatedEncrypted: enPrivateKey_emp.toString(),
      pathOfEmployee: path
    };

    axios.post(LINK_API.RESET_PASSWORD_EMPLOYEE, data)
      .then((response) => {
        if (response.data.errorCode === 1) {
          setAlert(<Success onClose={hideAlert} onConfirm={hideAlert} hideCancel={true} confirmBtnText={t('close')} />)
        }
        else {
          setAlert(<Error onClose={hideAlert} confirmBtnText={t('close')} />)
        }
      })
      .catch(() => {
        setAlert(<Error onClose={hideAlert} confirmBtnText={t('close')} />)
      })
  }, [t, userInfo])

  const resetPassword = React.useCallback((path, uuid) => {
    const ChildComponent = (
      <div>
        <Formik
          validationSchema={updatePassValidate}
          initialValues={{ newPass: '', rePass: '' }}
          onSubmit={values => handleResetPassword(values, path, uuid)}
        >
          {({ errors, touched }) => (
            <Form>
              <Field
                name="newPass"
                render={(props) => (
                  <FormGroup>
                    <label className="font-weight-bold">
                      {t('employee.passUser')}
                    </label>
                    <Input {...props} type="password" {...props.field} placeholder={t('employee.passUser')} />
                  </FormGroup>
                )}
              />
              {errors.newPass && touched.newPass ? <div className="text-danger">{errors.newPass}</div> : null}
              <Field name="rePass"
                render={(props) => (
                  <FormGroup>
                    <label className="font-weight-bold">
                      {t('employee.newPass')}
                    </label>
                    <Input {...props} type="password" {...props.field} placeholder={t('employee.newPass')} />
                  </FormGroup>
                )}
              />
              {errors.rePass && touched.rePass ? (
                <div className="text-danger">{errors.rePass}</div>
              ) : null}
              <Button color="info" type="submit">{t('confirm')}</Button>
            </Form>
          )}
        </Formik>
      </div>
    )
    setAlert(
      <ContentAlert
        title={t('employee.enterInfo')}
        hideCancel={true}
        onCancel={hideAlert}
        hideConfirm={true}
        child={ChildComponent}
      />

    )
  }, [t, updatePassValidate, handleResetPassword])


  const confirmActive = React.useCallback((uuid) => {
    setAlert(<Loading />)
    let body = { "uuid": uuid };
    axios.post(LINK_API.CHANGE_STATUS_EMPLOYEE, body)
      .then(res => {
        if (res.data.errorCode === 1) {
          setAlert(<Success onClose={hideAlert} onConfirm={hideAlert} hideCancel={true} confirmBtnText={t('close')} />)
          getEmployee(1, 5)
        } else {
          setAlert(<Error onClose={hideAlert} confirmBtnText={t('close')} />)
        }
      })
      .catch(err => {
        setAlert(<Error onClose={hideAlert} confirmBtnText={t('close')} />)
      })
  }, [t, getEmployee])
  const handleActive = React.useCallback((uuid, title) => {
    let ChildComponent = <div>{title}</div>
    setAlert(
      <ConfirmAlert
        onClose={hideAlert}
        onConfirm={() => confirmActive(uuid)}
        confirmBtnText={t('confirm')}
        child={ChildComponent}
      />
    )
  }, [confirmActive, t])

  const history = props.history;
  const handleEdit = React.useCallback((uuid) => {
    cookie.save("uuidEmp", uuid, { path: "/" });
    history.push(`/${linkUrl}editEmployee`)
  }, [history, linkUrl])

  const genTable = React.useCallback((listData) => {
    let data = listData.map((prop, index) => {
      return {
        id: prop.uuid,
        name:
          <div style={{ display: "flex", flexFlow: "row", alignItems: "center" }}>
            <Container>
              <Row>
                <Col xs={12} sm={12} md={5} style={{ textAlign: "start" }}>
                  {!isEmptyObject(prop.avatar) ? (
                    <img alt="profileAGD" src={prop.avatar.secure_url} style={{ height: "37px", width: "37px" }} />
                  ) :
                    <img alt="profileAGD" src={AGD_LOGO} style={{ height: "37px", width: "37px" }} />}
                </Col >
                <Col xs={12} sm={12} md={7} style={{ display: "flex", alignItems: "center", textAlign: "left" }}>
                  <Truncate lines={2} ellipsis={<span>...</span>}>
                    {prop.name}
                  </Truncate>
                </Col>
              </Row>
            </Container>
          </div>,
        time: moment(prop.createdAt).format("DD/MM/YYYY"),
        username: prop.username,
        role: handleRole(prop.role),
        actions: <div className="d-flex flex-row justify-content-center">
          <Button onClick={() => handleEdit(prop.uuid)} color="neutral-success" className="mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center" id={"empTooltipEdit" + index}>
            <span className="btn-wrapper--icon d-flex">
              <PenIcon
                size={18}
                className="text-success font-size-sm"
              />
            </span>
          </Button>
          <UncontrolledTooltip trigger="hover" popperClassName="tooltip-black" placement="top" container="body" target={"empTooltipEdit" + index}>
            {t('edit')}
          </UncontrolledTooltip>
          <Button onClick={() => resetPassword(prop.path, prop.uuid)} color="neutral-warning" className="mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center" id={"empTooltipRecover" + index}>
            <span className="btn-wrapper--icon d-flex">
              <KeyIcon
                size={18}
                className="text-warning font-size-sm"
              />
            </span>
          </Button>
          <UncontrolledTooltip trigger="hover" popperClassName="tooltip-black" placement="top" container="body" target={"empTooltipRecover" + index}>
            {t('employee.recover')}
          </UncontrolledTooltip>
          {
            prop.lock ?
              <>
                <Button onClick={() => handleActive(prop.uuid, t('employee.active'))} onClcolor="neutral-info" className="mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center" id={"empTooltipDeactive" + index}>
                  <span className="btn-wrapper--icon d-flex">
                    <CheckAllIcon
                      size={18}
                      className="text-info font-size-sm"
                    />
                  </span>
                </Button>
                <UncontrolledTooltip trigger="hover" popperClassName="tooltip-black" placement="top" container="body" target={"empTooltipDeactive" + index}>
                  {t('employee.active')}
                </UncontrolledTooltip>
              </>
              :
              <>
                <Button onClick={() => handleActive(prop.uuid, t('employee.deActive'))} color="neutral-danger" className="mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center" id={"empTooltipDeactive" + index}>
                  <span className="btn-wrapper--icon d-flex">
                    <CloseIcon
                      size={18}
                      className="text-danger font-size-sm"
                    />
                  </span>
                </Button>
                <UncontrolledTooltip trigger="hover" popperClassName="tooltip-black" placement="top" container="body" target={"empTooltipDeactive" + index}>
                  {t('employee.deActive')}
                </UncontrolledTooltip>
              </>
          }
        </div>
      }
    })
    setEmployeeList(data)
    setLoading(false)
  }, [handleRole, t, handleActive, resetPassword, handleEdit])

  useEffect(() => {
    getEmployee(1, 5)
  }, [getEmployee, userInfo]);


  const totalPageProps = props.employeeList.totalPage
  useEffect(() => {
    if (employeeList && employeeList.length > 0) {
      genTable(employeeList)
      setTotalPage(totalPageProps)
    } else {
      genTable([])
      setTotalPage(0)
    }

  }, [totalPageProps, employeeList]);
  const formik = useFormik({
    initialValues: {
      name: 'name',
      value: '',
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required(t('require')),
      value: Yup.string()
        .required(t('require')),
    }),
    onSubmit: React.useCallback(async (values) => {
      setFilter(true);
      setLoading(true);
      getEmployeeFilter(1, values.name, values.value, 5)
    }, [getEmployeeFilter]),
  });
  const columns = React.useMemo(
    () => [
      {
        accessor: "id",
        filterable: false,
        className: "center",
        Header: () => (
          <div style={{
            textAlign: "center"
          }}>
            {t('employee.id')}
          </div>
        )
      },
      {
        Header: t('employee.name'),
        accessor: "name",
        sortable: false
      },
      {
        Header: t('employee.date'),
        accessor: "time",
        filterable: false
      },
      {
        Header: t('employee.username'),
        accessor: "username",
        filterable: false
      },
      {
        Header: t('employee.role'),
        accessor: "role",
        filterable: false
      },
      {
        Header: t('area.actionHeader'),
        accessor: "actions",
        filterable: false
      },
    ],
    [t]
  );
  const handleResetData = () => {
    setFilter(false);
    getEmployee(1, 5)
  }
  const handleClearFilter = () => {
    formik.resetForm();
  }

  return (

    <>
      <motion.div
        className="w-100"
        initial={{ x: -100 }}
        animate={{ x: 0 }}
        transition={{ ease: "easeOut", duration: 0.3 }}
      >
        <Header/>
              <Container className='mt--7' fluid>
      <Card className="card-box mb-5">
        <div className="card-header py-3 d-flex justify-content-between">
            <div className=" font-weight-bold card-header--title font-size-lg">{t('employee.list')}</div>
            <div className="card-header--actions d-flex flex-row">
              <UncontrolledDropdown>
                <DropdownToggle id="filterButton" color="neutral-success" className="mx-2">
                  <FilterIcon size={20} /> {t('filter.filter')}
                </DropdownToggle>
                <DropdownMenu right className="dropdown-menu-xxl p-0">
                  <form onSubmit={formik.handleSubmit}>
                    <div className="p-3">
                      <Row>
                        <Col md="6">
                          <small className="font-weight-bold pb-2 text-uppercase text-primary d-block">
                            {t('filter.name')}
                          </small>
                          <CustomInput
                            type="select"
                            placeholder={t('filter.name')}
                            value={formik.values.name}
                            valid={formik.touched.name && formik.errors.name ? false : (formik.values.name ? true : false)}
                            invalid={formik.touched.name && formik.errors.name ? true : false}
                            {...formik.getFieldProps('name')}
                          >
                            <option value="name">{t('employee.name')}</option>
                            <option value="uuid">{t('employee.id')}</option>
                            <option value="username">{t('employee.username')}</option>
                            {/* <option value="status">{t('product.status')}</option> */}
                          </CustomInput>
                        </Col>
                        <Col md="6">
                          <small className="font-weight-bold pb-2 text-uppercase text-primary d-block">
                            {t('filter.value')}
                          </small>
                          {formik.values.name !== "status" ?
                            <Input
                              placeholder={t('filter.value')}
                              value={formik.values.value}
                              valid={formik.touched.value && formik.errors.value ? false : (formik.values.value ? true : false)}
                              invalid={formik.touched.value && formik.errors.value ? true : false}
                              {...formik.getFieldProps('value')}
                            >
                            </Input> : <CustomInput
                              type="select"
                              placeholder={t('filter.value')}
                              value={formik.values.value}
                              valid={formik.touched.value && formik.errors.value ? false : (formik.values.value || formik.values.value === 0 ? true : false)}
                              invalid={formik.touched.value && formik.errors.value ? true : false}
                              {...formik.getFieldProps('value')}
                            >
                              <option value={1}>{t('product.inProduction')}</option>
                              <option value={0}>{t('product.stopProduction')}</option>
                            </CustomInput>}
                        </Col>
                      </Row>

                    </div>
                    <div className="divider" />
                    <div className="p-3 text-center bg-secondary">
                      <Button color="primary" type="submit">
                        {t('filter.search')}
                      </Button>
                    </div>
                  </form>
                  <div className="divider" />
                  <div className="p-3">
                    <Row>
                      <Col md="6">
                        <Nav className="nav-neutral-danger flex-column p-0">
                          <NavItem>
                            <NavLinkStrap className="d-flex rounded-sm justify-content-center" onClick={handleClearFilter}>
                              <div className="nav-link-icon">
                                <CloseIcon />
                              </div>
                              <span>{t('filter.clear')}</span>
                            </NavLinkStrap>
                          </NavItem>
                        </Nav>
                      </Col>
                      <Col md="6">
                        <Nav className="nav-neutral-info flex-column p-0">
                          <NavItem>
                            <NavLinkStrap className="d-flex rounded-sm justify-content-center" onClick={handleResetData}>
                              <div className="nav-link-icon">
                                <ResetIcon />
                              </div>
                              <span>{t('filter.reset')}</span>
                            </NavLinkStrap>
                          </NavItem>
                        </Nav>
                      </Col>
                    </Row>
                  </div>
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledTooltip trigger="hover" popperClassName="tooltip-black" placement="top" container="body" target={"filterButton"}>
                {t('filter.filter')}
              </UncontrolledTooltip>
              <div className="d-inline-block" id="notiPlan">
                <Button onClick={() => props.history.push(`/${linkUrl}createEmployee`)} color="info" disabled={checkPer || permission.agdqr}>
                  <span className="btn-wrapper--icon">
                    <PlusCircle size={16} />
                  </span>
                  <span className="btn-wrapper--label">{t('product.add')}</span>
                </Button>
                {checkPer || permission.agdqr ?
                  <UncontrolledTooltip trigger="hover" popperClassName="tooltip-warning" placement="top" container="body" target={"notiPlan"}>
                    {t('notiPlan')}
                  </UncontrolledTooltip> : null}
              </div>
            </div>
          </div>

          <div className="divider" />
          <div className="card-body pt-2 px-4 pb-4">

            <ReactTable
              noDataText={t('noData')}
              data={dataEmployeeList}
              columns={columns}
              previousText={"<"}
              nextText={">"}
              rowsText={t('row')}
              ofText="/"
              manual
              defaultPageSize={5}
              showPaginationBottom={true}
              sortable={false}
              resizable={true}
              PaginationComponent={Pagination}
              pages={totalPage}
              className="-striped -highlight"
              loading={loadingState}
              LoadingComponent={LoadingTable}
              onFetchData={async (state, instance) => {
                setLoading(true)
                let x = state.page + 1;
                if (filterStatus === false) {
                  getEmployee(x, state.pageSize)
                } else {
                  getEmployeeFilter(x, formik.values.name, formik.values.value, state.pageSize)
                }
              }}
            />
          </div>
        </Card>
      </Container>
      </motion.div>
      {alert}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
    employeeList: state.employeeList,
    permission: state.permission
  }
}

const mapDispatchToProps = (dispatch) => ({
  getEmployee: (page, size) => dispatch({ type: "GET_EMPLOYEE_LIST", page: page, size: size }),
  getEmployeeFilter: (page, name, value, size) => dispatch({ type: "GET_EMPLOYEE_LIST_FILTER", page: page, name: name, value: value, size: size }),
});

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(EmployeeList));