import axios from 'axios';
import clsx from 'clsx';
import { LINK_API } from 'constants/API.js';
import React from "react";
import cookie from 'react-cookies';
import { withTranslation } from 'react-i18next';
import Loki from 'react-loki';
import { connect } from 'react-redux';
import Step1 from "./steps/stepOne";
import Step2 from "./steps/stepTwo";
import Success from "components/alert/success"
import Error from "components/alert/error"
import Loading from "components/alert/loading"
import Header from "components/Headers/Header"
import { Card, Container } from "reactstrap"
import "./index.css"
const CryptoJS = require('crypto-js')
const EthereumTx = require('ethereumjs-tx');

function CreateProduct(props) {
  axios.defaults.headers.common.Authorization = props.userInfo.data.token;
  const t = props.t;
  const permission = props.permission;
  const userInfo = props.userInfo;
  const uuid = cookie.load("uuidProductCopy");
  const [alert, setAlert] = React.useState(null);
  const [state, setState] = React.useState({
    nameManu: '',
    address: '',
    email: '',
    phone: '',
    website: '',
    tax: '',
    search: '',

    name: '',
    gtin: '',
    unit: '',
    type: '',
    price: '',
    checked: false,

    ava: [],
    cert: [],
    htmlString: '',

    banner: '',
    companyCert: '',
    companyDes: '',

  })

  React.useEffect(() => {
    if (uuid) {
      axios.get(LINK_API.GET_PRODUCT_DETAIL + uuid)
        .then(async response => {
          if (response.data.errorCode === 1) {
            let data = response.data.data;
            data.price = data.proposedPrice;
            data.type = data.category;
            data.htmlString = data.dynamicContent || "";
            if (data.origin) {
              data.nameManu = data.origin.name;
              data.address = data.origin.address;
              data.email = data.origin.email;
              data.phone = data.origin.phone;
              data.website = data.origin.website;
              data.tax = data.origin.tax;
              data.search = data.origin.address;
              data.companyDes = data.origin.description
            }
            await _mergeValues(data)
            if (response.data.data.image || response.data.data.certificates) {
              await createFile(data);
            }


          }
        })
        .catch(err => {
          console.log(err);
        })
      cookie.remove("uuidProductCopy", { path: "/" });
    }
  }, [uuid])
  const createFile = (data) => {
    let avaList = [];
    let certList = [];
    if (data.image) {
      let link = data.image;
      for (let i = 0; i < link.length; i++) {
        let xhr = new XMLHttpRequest();
        xhr.open('GET', link[i].secure_url, true);
        xhr.responseType = 'blob';
        xhr.onload = (e) => {
          e.preventDefault()
          let reader = new FileReader();
          let recoveredBlob = xhr.response;
          reader.onloadend = async () => {
            await avaList.push(reader.result)
          };
          reader.readAsDataURL(recoveredBlob);
        };
        xhr.send();
      }
    }
    if (data.certificates) {
      let linkCert = data.certificates;
      for (let i = 0; i < linkCert.length; i++) {
        let xhr = new XMLHttpRequest();
        xhr.open('GET', linkCert[i].secure_url, true);
        xhr.responseType = 'blob';
        xhr.onload = (e) => {
          e.preventDefault()
          let reader = new FileReader();
          let recoveredBlob = xhr.response;
          reader.onloadend = async () => {
            await certList.push(reader.result)
          };
          reader.readAsDataURL(recoveredBlob);
        };
        xhr.send();
      }
    }

    setTimeout(function () {
      setState({
        ...data,
        cert: certList,
        ava: avaList
      })
    }, 1000)
  }

  const _mergeValues = (data) => {
    setState({
      ...state,
      ...data
    })
  }

  const _onFinish = (receivedData) => {
    let data = {
      name: receivedData.name,
      description: '',
      dynamicContent: receivedData.htmlString,
      glnCheckbox: receivedData.gtin ? true : false,
      gtin: receivedData.gtin,
      image: receivedData.ava,
      certificates: receivedData.cert,
      // image: [],
      // certificates: [],
      category: receivedData.type,
      proposedPrice: receivedData.price,
      //   image: [],
      //   certificates: [],

      images360: [],
      language: "vi",
      options: [],

      showOrigin: receivedData.checked,
      unit: receivedData.unit,
      //type: receivedData.type
    }
    if (receivedData.checked) {
      data.origin = {
        name: receivedData.nameManu,
        email: receivedData.email,
        address: receivedData.address,
        phone: receivedData.phone[0] === "0" ? receivedData.phone : '0' + receivedData.phone,
        description: receivedData.companyDes,
        // banner: receivedData.banner,
        // certificates: receivedData.companyCert,
        banner: [],
        certificates: [],
        tax: receivedData.tax,
        website: receivedData.website
      }
    }
    // props.createProduct(data)
    // props.history.push('/product/list')
    setAlert(<Loading />);
    handleCreateProduct(data);
  };
  const handleCreateProduct = (data) => {
    axios.post(LINK_API.CREATE_PRODUCT, data)
      .then(res => {
        if (res.data.errorCode === 1) {
          handleRaw(res.data.data);
        } else {
          setAlert(<Error onClose={hideAlert} confirmBtnText={t('back')} />)
        }
      })
      .catch(err => {
        setAlert(<Error onClose={hideAlert} confirmBtnText={t('back')} />)
      })
  }
  const hideAlert = () => {
    setAlert(null)
  }
  const handleRaw = (dataRaw) => {
    var bytes = CryptoJS.AES.decrypt(userInfo.data.privateEncrypted.toString(), userInfo.password)
    const decrypt_privatekey = bytes.toString(CryptoJS.enc.Utf8)
    const privatekey_slice = decrypt_privatekey.substring(2, decrypt_privatekey.length)
    const Buffer_privatekey = Buffer.from(privatekey_slice.toString(), 'hex')
    const tx = new EthereumTx(dataRaw.raw, { chain: 4 });
    tx.sign(Buffer_privatekey)
    const rawTx = '0x' + tx.serialize().toString('hex')
    let data = {
      ...dataRaw,
      raw: rawTx,
      glnCheckbox: dataRaw.gtin ? true : false,
    }
    if (dataRaw.checked) {
      data.origin = {
        name: dataRaw.nameManu,
        email: dataRaw.email,
        address: dataRaw.address,
        phone: dataRaw.phone[0] === "0" ? dataRaw.phone : '0' + dataRaw.phone,
        description: dataRaw.companyDes,
        banner: dataRaw.banner,
        certificates: dataRaw.companyCert,
        tax: dataRaw.tax,
        website: dataRaw.website
      }
    }
    axios.post(LINK_API.SEND_RAW_PRODUCT, data)
      .then(res => {
        if (res.data.errorCode === 1) {
          setAlert(<Success title="Tạo nhóm sản phẩm thành công!" onConfirm={() => handleDetail(dataRaw.uuid)} hideCancel={true} confirmBtnText={t('confirm')} />)
        } else {
          setAlert(<Error onClose={hideAlert} confirmBtnText={t('back')} />)
        }
      })
      .catch(err => {
        setAlert(<Error onClose={hideAlert} confirmBtnText={t('back')} />)
      })
  }
  const handleDetail = async (uuid) => {
    await cookie.save("uuidProduct", uuid, { path: "/" });
    props.history.push("/user/productEdit")
  }
  return (
    <>
      <Header />
      <Container className='mt--7' fluid>
        <Card className="card-box mb-5">
          <div className="wizard-alternate-2 horizontal">
            <Step1 data={state} mergeData={_onFinish} />
          </div>
        </Card>
      </Container>
      {alert}
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    userProfile: state.userProfile.data,
    userInfo: state.userInfo,
    permission: state.permission.data
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    createProduct: (data) => dispatch({ type: "CREATE_PRODUCT", data: data }),
  }
}
export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(CreateProduct))