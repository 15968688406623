import axios from 'axios';
import LoadingTable from "components/LoadingTable/index.js";
import Pagination from "components/Pagination/index.jsx";
import { LINK_API } from 'constants/API.js';
import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ReactTable from 'react-table-6';
import "../../css/reactTable.css";

function StampList(props) {
  const [activityRender, setActivity] = React.useState([])
  const [loadingState, setLoading] = React.useState(true)
  const { t } = props;

  const {
    userInfo
  } = props;

  const genTable = React.useCallback((data) => {
    setActivity(data)
    setLoading(false)
  }, [])

  const getData = async (startDate, endDate) => {
    axios.defaults.headers.common.Authorization = userInfo.data.token
    const response = await axios({
      method: 'GET',
      url: LINK_API.GET_ALL_ACTIVITY.concat('?startTime=', startDate, '&endTime=', endDate)
    })
    if (response && response.data && response.data.data) {
      genTable(response.data.data)
    }
    else {
      genTable([])
    }
    setLoading(false)
  }

  useEffect(() => {
    setLoading(true)
    getData(props.startDate, props.endDate)
    
  }, [props.startDate, props.endDate]);


  const columns = React.useMemo(
    () => [
      {
        Header: t('statistic.date'),
        accessor: "date",
        sortable: false,
        filterable: true,
        filterMethod: (filter, row) => {
          if (row.date) {
            const timeInRow = row.date.toUpperCase().search(filter.value.toUpperCase());
            if (timeInRow === -1) return false;
            return true;
          }
          return false;
        },
      },
      {
        Header: t('process.nameStep'),
        accessor: "stepName",
        filterable: true,
        filterMethod: (filter, row) => {
          if (row.stepName) {
            const timeInRow = row.stepName.toUpperCase().search(filter.value.toUpperCase());
            if (timeInRow === -1) return false;
            return true;
          }
          return false;
        },
      },
      {
        Header: t('oProduction.processCrop'),
        accessor: "process_name",
        filterable: true,
        filterMethod: (filter, row) => {
          if (row.process_name) {
            const timeInRow = row.process_name.toUpperCase().search(filter.value.toUpperCase());
            if (timeInRow === -1) return false;
            return true;
          }
          return false;
        },
      },
      {
        Header: t('employee.link'),
        accessor: "actor",
        filterable: true,
        filterMethod: (filter, row) => {
          if (row.actor) {
            const timeInRow = row.actor.toUpperCase().search(filter.value.toUpperCase());
            if (timeInRow === -1) return false;
            return true;
          }
          return false;
        },
      },
      {
        Header: t('activities.object'),
        accessor: "po",
        filterable: true,
        filterMethod: (filter, row) => {
          if (row.po) {
            const timeInRow = row.po.toUpperCase().search(filter.value.toUpperCase());
            if (timeInRow === -1) return false;
            return true;
          }
          return false;
        },
      },
      {
        Header: "Nhóm sản phẩm",
        accessor: "pt",
        filterable: true,
        filterMethod: (filter, row) => {
          if (row.pt) {
            const timeInRow = row.pt.toUpperCase().search(filter.value.toUpperCase());
            if (timeInRow === -1) return false;
            return true;
          }
          return false;
        },
      },
      {
        Header: t('area.link'),
        accessor: "pa",
        filterable: true,
        filterMethod: (filter, row) => {
          if (row.pa) {
            const timeInRow = row.pa.toUpperCase().search(filter.value.toUpperCase());
            if (timeInRow === -1) return false;
            return true;
          }
          return false;
        },
      },
    ],
    [t]
  );

  return (

    <>
      <div className="divider" />
      <div className="card-body pt-2 px-4 pb-4">
        <ReactTable
          noDataText={t('noData')}
          data={activityRender}
          columns={columns}
          previousText={"<"}
          nextText={">"}
          rowsText={t('row')}
          ofText="/"
          defaultPageSize={5}
          showPaginationBottom={true}
          sortable={false}
          resizable={true}
          PaginationComponent={Pagination}
          className="-striped -highlight"
          loading={loadingState}
          LoadingComponent={LoadingTable}
        />
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
    allActivity: state.allActivity
  }
}

const mapDispatchToProps = (dispatch) => ({
  getAllActivity: (data) => dispatch({ type: "GET_ALL_ACTIVITY", data: data }),
});

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(StampList));