import { GET_DETAIL_ACCOUNT, GET_DETAIL_ACCOUNT_SUCCESS, GET_DETAIL_ACCOUNT_FAILURE } from 'actions/types'
const initialState = { data: '' }
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DETAIL_ACCOUNT:
      return {
        ...state
      }
    case GET_DETAIL_ACCOUNT_SUCCESS:
      return {
        ...state,
        data: action.data
      }
    case GET_DETAIL_ACCOUNT_FAILURE:
      return {
        ...state
      }
    default:
      return state
  }
}
export default reducer
