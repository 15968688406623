import Axios from "axios";
import Error from "components/alert/error";
import Loading from "components/alert/loading";
import Success from "components/alert/success";
import { LINK_API } from "constants/API";
import { useFormik } from "formik";
import React from "react";
import ReactGoogleMapLoader from "react-google-maps-loader";
import ReactGooglePlacesSuggest from "react-google-places-suggest";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap";
import "view/css/select.css";
import DropZone from "components/dropZone/index";
import * as Yup from "yup";
import YoutubeIcon from "mdi-react/YoutubeIcon";
import { convertToRaw, EditorState, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { Editor } from "react-draft-wysiwyg";

const MY_API_KEY = "AIzaSyCB7o1cgt10O8xP9lLbk7Iikfv5ypvr528";

const Footer = React.forwardRef((props, ref) => {
  const t = props.t;
  const portalSetting = props.portalSetting;
  const [alert, setAlert] = React.useState(null);
  const [isChange, setChange] = React.useState(false);
  const [editorState, setEditor] = React.useState(EditorState.createEmpty());
  const [logo, setLogo] = React.useState([]);
  const [id, setId] = React.useState("")
  const phone_regex = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/; // validate phone vietnam
  const formik = useFormik({
    initialValues: {
      websiteName: "",
      email: "",
      address: "",
      phone: "",
      fax: "",
      videoLink: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Địa chỉ email không hợp lệ"),
      // phone: Yup.string().matches(phone_regex, "Số điện thoại không hợp lệ"),
    }),
    validateOnChange: true,
    onSubmit: async (values) => {
      handleSaveData();
    },
  });

  React.useEffect(() => {
    if (portalSetting) {
      if (portalSetting.logo) {
        setLogo(portalSetting.logo);
      }
      setId(portalSetting.uuid)
      formik.setValues({
        websiteName: portalSetting.websiteName,
        email: portalSetting.email,
        fax: portalSetting.fax,
        address: portalSetting.address,
        videoLink: portalSetting.videoLink,
      });
      let indexOf = portalSetting.phone.indexOf("+84");
      if (indexOf === 0) {
        let phone = portalSetting.phone.split(" ").join("");
        phone = "0" + phone.substring(3, phone.length);
        formik.setFieldValue("phone", phone);
      } else {
        formik.setFieldValue("phone", portalSetting.phone);
      }

      if (portalSetting.description) {
        let dynamicContent = portalSetting.description;
        let contentBlock = htmlToDraft(dynamicContent);
        if (
          contentBlock &&
          portalSetting.description &&
          portalSetting.description !== ""
        ) {
          let contentState = ContentState.createFromBlockArray(
            contentBlock.contentBlocks
          );
          let editorState = EditorState.createWithContent(contentState);
          setEditor(editorState);
        }
      }
    }
  }, [portalSetting]);

  const handleChangeEditor = async (editorState) => {
    // console.log("editorState 1", editorState)
    setEditor(editorState);
    setChange(true);
  };

  const hideAlert = () => {
    setChange(false);
    setAlert(null);
    if (props.onChange) {
      props.onChange(false);
    }
  };
  const handleSaveData = async () => {
    setAlert(<Loading />);
    let description = await draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    description = await description.toString();
    description = await description.replace("↵", "");
    let uploadedlogo = "";
    if (logo && logo.length) {
      uploadedlogo = logo[0];
    }

    let data = {
      // /email: formik.values.email,
      websiteName: formik.values.websiteName,
      fax: formik.values.fax,
      address: formik.values.address,
      phone: formik.values.phone,
      email: formik.values.email,
      videoLink: formik.values.videoLink,
      description,
    };

    if (uploadedlogo) {
      data.logo = uploadedlogo;
    }

    Axios.put(`${LINK_API.PORTAL_SETTING}?id=${id}`, data)
      .then((res) => {
        if (res.data.errorCode === 1) {
          setAlert(
            <Success
              onConfirm={handleConfirm}
              hideCancel={true}
              confirmBtnText={t("confirm")}
            />
          );
        } else {
          setAlert(<Error onClose={hideAlert} confirmBtnText={t("close")} />);
        }
      })
      .catch((err) => {
        setAlert(<Error onClose={hideAlert} confirmBtnText={t("close")} />);
      });
  };
  const handleConfirm = () => {
    hideAlert();
    props.getPortalSetting();
  };
  const handleChangeAddress = (name) => (event) => {
    setChange(true);
    let values = event.target.value;
    if (values) {
      formik.setFieldValue("search", values);
      formik.setFieldValue("address", values, true);
    } else {
      formik.setFieldValue("search", values);
      formik.setFieldValue("address", values, true);
      formik.setFieldError("address", "Thông tin bắt buộc");
    }
  };
  const handleSelectSuggest = (geocodedPrediction) => {
    formik.setFieldValue("address", geocodedPrediction.formatted_address);
    formik.setFieldValue("search", "");
  };
  const handleNoResult = () => {
    console.log("Không có kết quả");
  };
  const handleChangeField = (name) => async (event) => {
    await formik.setFieldValue(name, event.target.value, true);
    await formik.setFieldTouched(name, true, true);
    setChange(true);
    if (props.onChange) {
      props.onChange(true);
    }
  };

  const handleChangeImage = (image) => {
    setLogo(image);
    setChange(true);
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="p-4">
          <div className="form-row">
            <div className="form-group col-md-8 text-left mb-0">
              <h5 className="font-size-xl font-weight-bold">
                {t("portal.portalSetting")}
              </h5>
              <p className="text-black-50 mb-0">
                {t("portal.aboutPortalSetting")}
              </p>
            </div>
            {isChange ? (
              <div className="form-group col-md-4 text-right mb-0">
                <Button color="info" type="submit">
                  {t("save")}
                </Button>
              </div>
            ) : null}
          </div>

          <div className="divider my-4" />
          <div className="form-row">
            <p className="font-weight-bold mb-2 col-12">Logo</p>
            <DropZone
              data={
                logo ? (Array.isArray(logo) ? logo : [logo.secure_url]) : []
              }
              max={1}
              onConfirm={handleChangeImage}
            />
          </div>
          <div className="divider my-4" />

          <div className="form-row">
            <div className="form-group col-lg-5 col-md-12">
              <label className="font-weight-bold">
                {t("portal.websiteName")}
              </label>
              <Input
                placeholder={t("portal.websiteName")}
                type="text"
                name="websiteName"
                value={formik.values.websiteName}
                onChange={handleChangeField("websiteName")}
                valid={
                  formik.touched.websiteName && formik.errors.websiteName
                    ? false
                    : formik.values.websiteName
                    ? true
                    : false
                }
                invalid={
                  formik.touched.websiteName && formik.errors.websiteName
                    ? true
                    : false
                }
                // {...formik.getFieldProps('emailContact')}
              />
              {formik.touched.websiteName && formik.errors.websiteName ? (
                <p className="font-weight-regular font-size-sm text-danger">
                  {formik.errors.websiteName}
                </p>
              ) : null}
            </div>

            <div className="col-lg-1 col-md-0" />

            <div className="form-group col-lg-5 col-md-12">
              <label className="font-weight-bold">
                {t("portal.videoLink")}
              </label>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <YoutubeIcon color="#FF0000" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder={t("portal.videoLink")}
                  type="text"
                  value={formik.values.videoLink}
                  onChange={handleChangeField("videoLink")}
                />
              </InputGroup>
            </div>

            <div className="form-group col-lg-5 col-md-12">
              <label className="font-weight-bold">
                {t("portal.emailContact")}
              </label>
              <Input
                placeholder={t("portal.emailContact")}
                type="text"
                name="email"
                value={formik.values.email}
                onChange={handleChangeField("email")}
                valid={
                  formik.touched.email && formik.errors.email
                    ? false
                    : formik.values.email
                    ? true
                    : false
                }
                invalid={
                  formik.touched.email && formik.errors.email ? true : false
                }
                // {...formik.getFieldProps('emailContact')}
              />
              {formik.touched.email && formik.errors.email ? (
                <p className="font-weight-regular font-size-sm text-danger">
                  {formik.errors.email}
                </p>
              ) : null}
            </div>

            <div className="col-lg-1 col-md-0" />

            <div className="form-group col-lg-5 col-md-12">
              <label className="font-weight-bold">{t("portal.address")}</label>
              <ReactGoogleMapLoader
                params={{
                  key: MY_API_KEY,
                  libraries: "places,geocode",
                }}
                render={(googleMaps) =>
                  googleMaps && (
                    <div>
                      <ReactGooglePlacesSuggest
                        autocompletionRequest={{ input: formik.values.search }}
                        googleMaps={googleMaps}
                        onSelectSuggest={handleSelectSuggest}
                        displayPoweredByGoogle={false}
                        onNoResult={handleNoResult}
                      >
                        <Input
                          placeholder={t("portal.address")}
                          type="text"
                          name="address"
                          value={formik.values.address}
                          valid={
                            formik.touched.address && formik.errors.address
                              ? false
                              : formik.values.address
                              ? true
                              : false
                          }
                          invalid={
                            formik.touched.address && formik.errors.address
                              ? true
                              : false
                          }
                          onChange={handleChangeAddress("address")}
                        />
                      </ReactGooglePlacesSuggest>
                    </div>
                  )
                }
              />
              {formik.touched.address && formik.errors.address ? (
                <p className="font-weight-regular font-size-sm text-danger">
                  {formik.errors.address}
                </p>
              ) : null}
            </div>

            <div className="col-lg-1 col-md-0" />

            <div className="form-group col-lg-5 col-md-12">
              <label className="font-weight-bold">{t("portal.phone")}</label>
              <Input
                placeholder={t("portal.phone")}
                type="text"
                name="phone"
                value={formik.values.phone}
                valid={
                  formik.touched.phone && formik.errors.phone
                    ? false
                    : formik.values.phone
                    ? true
                    : false
                }
                invalid={
                  formik.touched.phone && formik.errors.phone ? true : false
                }
                onChange={handleChangeField("phone")}
                // {...formik.getFieldProps('phone')}
              />
              {formik.touched.phone && formik.errors.phone ? (
                <p className="font-weight-regular font-size-sm text-danger">
                  {formik.errors.phone}
                </p>
              ) : null}
            </div>

            <div className="col-lg-1 col-md-0" />

            <div className="form-group col-lg-5 col-md-12">
              <label className="font-weight-bold">{t("portal.fax")}</label>
              <Input
                placeholder={t("portal.fax")}
                type="text"
                name="fax"
                value={formik.values.fax}
                valid={
                  formik.touched.fax && formik.errors.fax
                    ? false
                    : formik.values.fax
                    ? true
                    : false
                }
                invalid={formik.touched.fax && formik.errors.fax ? true : false}
                onChange={handleChangeField("fax")}
                // {...formik.getFieldProps('phoneContact')}
              />
            </div>

            <div className="form-group col-md-12">
              <label className="font-weight-bold">{t("portal.des")}</label>
              <Editor
                editorState={editorState}
                wrapperClassName="demo-wrapper"
                editorClassName="demo-editor borderEditor"
                onEditorStateChange={handleChangeEditor}
                toolbar={{
                  options: ["inline", "image", "embedded", "textAlign", "link"],
                }}
              />
              {formik.touched.des && formik.errors.des ? (
                <p className="font-weight-regular font-size-sm text-danger">
                  {formik.errors.des}
                </p>
              ) : null}
            </div>
          </div>
        </div>
      </form>
      {alert}
    </>
  );
});
const mapStateToProps = (state) => {
  return {
    portalSetting: state.portalSetting.infoPortal,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getPortalSetting: () => dispatch({ type: "GET_PORTAL_SETTING" }),
  };
};
export default withTranslation("common", { withRef: true })(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    Footer
  )
);
