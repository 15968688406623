import { 
  GET_PRICE_LIST,
  GET_PRICE_LIST_SUCCESS,
  GET_PRICE_LIST_FAILURE,
 } from "actions/types"
import { takeLatest, call, put, select } from 'redux-saga/effects'
import { LINK_API } from 'constants/API.js'
import axios from 'axios'

const getToken = (state) => state.userInfo


//get price list for retailer
function * getPriceList () {
  try {
    const tokenInfo = yield select(getToken)
    const response = yield call(fetchPriceList, tokenInfo.data.token)
    const data = response.data
    yield put({ type: GET_PRICE_LIST_SUCCESS, data: data.data, errorCode: data.errorCode })
  } catch (error) {
    yield put({ type: GET_PRICE_LIST_FAILURE, payload: '', errorCode: 0 })
  }
}
function fetchPriceList (checktoken) {
  axios.defaults.headers.common.Authorization = checktoken
  return axios({
    method: 'GET',
    url: LINK_API.GET_PRICE_LIST
  })
}


const price = [
  takeLatest(GET_PRICE_LIST, getPriceList)
]
export default price

