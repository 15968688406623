const initialState = { data: '', errorCode: '' }
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_VALID_STAMPS_RANGE':
      return {
        ...state
      }
    case 'GET_VALID_STAMPS_RANGE_SUCCESS':
      return {
        ...state,
        cases: action.data.BOX.reverse().slice(0, 5),
        products: action.data.UNIT.reverse().slice(0, 5),
        loadValidStampsRange: false,
      }
    case 'GET_VALID_STAMPS_RANGE_FAILURE':
      return {
        ...state,
        cases: [],
        products: [],
      }
    default:
      return state
  }
}
export default reducer
