import { 
    GET_PERMISSION,
    GET_PERMISSION_SUCCESS,
    GET_PERMISSION_FAILURE,
   } from "actions/types"
  import { takeLatest, call, put, select } from 'redux-saga/effects'
  import { LINK_API } from 'constants/API.js'
  import axios from 'axios'
  
  const getToken = (state) => state.userInfo
  
// Get distributor list
function * getPermission () {
    try {
      const tokenInfo = yield select(getToken)
      const response = yield call(fetchPermission, tokenInfo.data.token)
      const data = response.data
      yield put({ type: GET_PERMISSION_SUCCESS, data: data.data, errorCode: data.errorCode })
    } catch (error) {
      yield put({ type: GET_PERMISSION_FAILURE, payload: '', errorCode: 0 })
    }
  }
  function fetchPermission (checktoken) {
    axios.defaults.headers.common.Authorization = checktoken
    return axios({
      method: 'GET',
      url: LINK_API.GET_PERMISSION
    })
  }
  const partner = [
    takeLatest(GET_PERMISSION, getPermission)
  ]
  export default partner
  
  