/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { useFormik } from 'formik';
import React from "react";
import { withTranslation } from 'react-i18next';
// reactstrap components
import {
  Button,
  Card,
  CardBody, CardHeader,
  Col, FormGroup,
  Input,
  InputGroup, InputGroupAddon,
  InputGroupText,
  Row,
  Modal,
  ModalHeader, ModalBody
} from "reactstrap";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import ConfirmAlert from "components/alert/confirmAlert.js";
import Error from 'components/alert/error';
import Loading from 'components/alert/loading';
import Success from 'components/alert/success';
import * as Yup from 'yup';
import { LINK_API } from 'constants/API';
import ErrorCode from "constants/errorCode";
import axios from 'axios';
const ethers = require('ethers');

const Login = (props) => {
  const t = props.t;
  const userInfo = props.userInfo.data;
  const history = props.history;
  const [modal, setModal] = React.useState(false)
  const [alert, setAlert] = React.useState(null)
  const [emailReset, setEmailReset] = React.useState("")
  const validateEmail = (value) => {
    const emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
    return emailValid;
  }
  const toggle = () => setModal(!modal);
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      showPass: false
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(5, t('atLeast'))
        .required(t('require')),
      email: Yup.string()
        // .email(t('invalidMail'))
        .required(t('require')),
    }),
    onSubmit: async values => {
      handleSignIn();
      handleChangeRoute(values);
    },
  });
  const handleSendMail = () => {
    setAlert(<Loading />)
    const data = {
      email: formik.getFieldProps('email').value
    }

    axios.post(LINK_API.RESEND_EMAIL, data)
      .then(res => {
        if (res.data.errorCode === 1) {
          setAlert(<Success hideCancel={true} onConfirm={() => hideAlert()} onClose={() => hideAlert()} confirmBtnText={t('close')} />)

        } else {
          setAlert(<Error onConfirm={() => hideAlert()} onClose={() => hideAlert()} confirmBtnText={t('close')} />)
        }
      })
      .catch(err => {
        if (err.response.data.name === "EMAIL_HAD_ALREADY_VERIFIED") {
          let ChildComponent = <div>{t('register.verifiedEmail')}</div>
          setAlert(
            <ConfirmAlert
              onClose={hideAlert}
              hideCancel={true}
              onConfirm={() => hideAlert()}
              confirmBtnText={"OK"}
              child={ChildComponent}
            />
          )
        }
        else {
          setAlert(<Error onConfirm={() => hideAlert()} onClose={() => hideAlert()} confirmBtnText={t('close')} />)
        }
      })
  }
  const handleRegister = () => {
    props.history.push('/auth/register')
  }
  const handleSignIn = () => {
    setAlert(<Loading />)
  }
  const handleChangeRoute = async (values) => {
    let temp = new Buffer(values.password).toString('hex')
    let encryptedPass = await ethers.utils.keccak256('0x' + temp)
    let data_signIn = {
      "email": values.email,
      "pw_hash": encryptedPass
    }
    axios.post(LINK_API.SIGN_IN, data_signIn)
      .then(async res => {
        if (res.data.errorCode === 1) {
          if (res.data.data.uuid === res.data.data.ownedBy) {
            props.saveUserInfo(res.data.data, values.password)
            hideAlert();
            console.log("Check email");
            let verify_state = res.data.data.verifiedTxId;
            console.log('verify_state', verify_state)
            if (!verify_state) {
              console.log("Check state")
              if (!res.data.data.active) {
                let ChildComponent = <div>{t('register.noActive')}</div>
                setAlert(
                  <ConfirmAlert
                    colorCancel="neutral-success"
                    cancelBtnText={"OK"}
                    onClose={hideAlert}
                    onConfirm={hideAlert}
                    confirmBtnText={"OK"}
                    hideCancel={true}
                    child={ChildComponent}
                  />
                )
              }
              else {
                console.log('check email')
                props.saveUserInfo(res.data.data, values.password)
                hideAlert();
                let role = res.data.data.type ? res.data.data.type : res.data.data.role;
                let verify_state = res.data.data.verifiedTxId;
                if (!verify_state) {
                  //  props.verifyAccount(res.data.data, values.password)
                  // props.history.push("/seed")
                  // return;
                }
                console.log('role', role)
                if (role === "ZA") {
                  props.history.push("/accountant/registrationHistory/list")
                }
                if (role === "M") {
                  props.history.push("/product/list")
                }
                if (role === "F") {
                  props.history.push("/distributor/employee/list")
                }
                if (role === "Z") {
                  props.history.push("/admin/allManu")
                }
                if (role === "R") {
                  props.history.push("/retailer/receive/list")
                }
                if (role === "S") {
                  props.history.push("/sadmin/allManu")
                }
                if (role === "U") {
                  props.history.push("/market/portalSetting")
                }
                if (role === "G") {
                  props.history.push("/guard/list")
                }
                if (role === "FA") {
                  props.history.push("/farmer/list")
                }
                if (role === "W") {
                  props.history.push("/wholesaler/pack")
                }
              }
              // else {
              //   let ChildComponent = <div>{t('register.notVerify')}</div>
              //   setAlert(
              //     <ConfirmAlert
              //       hideCancel={true}
              //       onClose={hideAlert}
              //       onConfirm={() => hideAlert()}
              //       confirmBtnText={"OK"}
              //       child={ChildComponent}
              //     />
              //   )
              // }

            }
          } else {
            console.log('Check nhân viên')
            let dataEmp = res.data.data;
            dataEmp.role = 'SM';
            dataEmp.type = 'SM'
            console.log('dataEmp', dataEmp)
            await props.saveUserInfo(dataEmp, values.password);
            hideAlert();
            props.history.push('/employ/object/list')
          }

        }
      })
      .catch(err => {
        if (err.response && err.response.data && err.response.data.name && ErrorCode[`${err.response.data.name}`]) {
          setAlert(<Error onClose={hideAlert} confirmBtnText="Đóng" title={ErrorCode[`${err.response.data.name}`].vi} />)
        } else {
          setAlert(<Error onClose={hideAlert} confirmBtnText="Đóng" />)

        }
      })
  }
  const hideAlert = () => {
    setAlert(null)
  }
  const cleanStore = props.cleanStore;
  React.useEffect(() => {
    console.log(userInfo)
    if (userInfo) {
      if (!userInfo.active && userInfo.type !== 'SM') {
        console.log('Check clean nhân viên')
        cleanStore();
        history.push('/auth/login')
      }
      else {
        if (userInfo.type === "ZA") {
          history.push("/accountant/registrationHistory/list")
        }
        if (userInfo.type === "M") {
          history.push("/user/producttype")
        }
        if (userInfo.type === "F") {
          history.push("/distributor/import")
        }
        if (userInfo.type === "Z") {
          history.push("/admin/allManu")
        }
        if (userInfo.type === "R") {
          history.push("/retailer/receive/list")
        }
        if (userInfo.type === "SM") {
          history.push('/employee/object')
        }
        if (userInfo.type === "S") {
          history.push("/sadmin/allManu")
        }
        if (userInfo.type === "U") {
          history.push("/market/footer-setting")
        }
        if (userInfo.type === "G") {
          history.push("/guard/list")
        }
        if (userInfo.type === "FA") {
          history.push("/farmer/list")
        }
        if (userInfo.type === "W") {
          history.push("/wholesaler/pack")
        }
      }
    }

  }, [userInfo, history, cleanStore])
  const handleResetPass = () => {
    if (!emailReset) {

      return;
    }
    if (emailReset) {
      const checkmail = validateEmail(emailReset);
      if (checkmail === null) {

        return;
      }

    }

    const data = {
      "email": emailReset
    }

    setAlert(<Loading />)

    axios.post(LINK_API.SEND_LINK_PASSWORD, data)
      .then(res => {
        if (res.data.errorCode === 1) {
          setModal(false)
          let ChildComponent = <div>{t("checkEmailReset")}</div>
          setAlert(
            <ConfirmAlert
              onClose={hideAlert}
              hideCancel={true}
              onConfirm={hideAlert}
              confirmBtnText={"OK"}
              child={ChildComponent}
            />
          )
        }
      })
      .catch(err => {
        setAlert(
          <ConfirmAlert
            color="danger"
            hideCancel={true}
            onClose={hideAlert}
            onConfirm={() => hideAlert()}
            confirmBtnText={"Đóng"}
            child={"Email này không tồn tại trong hệ thống"}
          />
        )
      })
  }
  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0 pt-2">
          <CardHeader className="bg-transparent d-flex justify-content-center align-items-center">
            <img alt='logoVBC' src={'https://res.cloudinary.com/dinhtq98/image/upload/v1607266631/Kontum%20Project/Kontum_logo-removebg-preview_rpu0xl.png'} style={{ height: "50px" }} />
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-3">
            <form onSubmit={formik.handleSubmit}>
              <FormGroup className="mb-3">
                <label className='font-weight-bold text-sm'>Tên đăng nhập</label>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder={t('login.username')}
                    valid={formik.touched.email && formik.errors.email ? false : (formik.values.email ? true : false)}
                    invalid={formik.touched.email && formik.errors.email ? true : false}
                    value={formik.values.email}
                    name="email"
                    {...formik.getFieldProps('email')}
                  />
                </InputGroup>
              </FormGroup>
              {formik.touched.email && formik.errors.email ? (
                <p className="font-weight-regular text-sm text-danger" >{formik.errors.email}</p>
              ) : null}
              <FormGroup>
                <label className='font-weight-bold text-sm'>Mật khẩu</label>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    valid={formik.touched.password && formik.errors.password ? false : (formik.values.password ? true : false)}
                    invalid={formik.touched.password && formik.errors.password ? true : false}
                    placeholder={t('login.pass')}
                    type={formik.values.showPass ? "text" : "password"}
                    name="password"
                    value={formik.values.password}
                    {...formik.getFieldProps('password')}
                  />
                </InputGroup>
              </FormGroup>
              {formik.touched.password && formik.errors.password ? (
                <p className="font-weight-regular text-sm text-danger" >{formik.errors.password}</p>
              ) : null}
              <div className="custom-control custom-control-alternative custom-checkbox">
                <input
                  className="custom-control-input"
                  id=" customCheckLogin"
                  type="checkbox"
                />
                <label
                  className="custom-control-label"
                  htmlFor=" customCheckLogin"
                >
                  <span className="text-muted">Nhớ mật khẩu</span>
                </label>
              </div>
              <div className="text-center mt-4">
                <Button
                  size="lg"
                  type="submit"
                  color='info'
                  className="s font-weight-bold font-size-sm"
                >
                  {t('login.login')}
                </Button>
              </div>
            </form>
          </CardBody>
        </Card>
        {/* <Row className="mt-3">
          <Col xs="6">
            <a
              className="text-light"
              href="# "
              onClick={() => setModal(true)}
            >
              <small>Quên mật khẩu?</small>
            </a>
          </Col>
          <Col className="text-right" xs="6">
            <a
              className="text-light"
              href="# "
              onClick={handleRegister}
            >
              <small>Tạo tài khoản mới</small>
            </a>
          </Col>
        </Row> */}
        {alert}
      </Col>
      <Modal
        zIndex={2000}
        centered
        isOpen={modal}
        toggle={toggle}
      >
        <ModalHeader toggle={toggle} ><p className='font-size-lg'>Khôi phục mật khẩu</p></ModalHeader>
        <ModalBody>
          <div>
            <FormGroup>
              <label className="font-weight-bold">{t('login.email')}</label>
              <Input
                bsSize="lg"
                placeholder="email@gmail.com"
                type="email"
                value={emailReset}
                onChange={(event) => setEmailReset(event.target.value)}
              />
            </FormGroup>
            <p

              style={{
                fontSize: '0.875rem',
                fontWeight: '400',
                color: 'red',
                fontFamily: 'Muli',
                marginTop: 5,
                marginBottom: 5
              }}
            >
              {
                !emailReset
                  ?
                  t('register.plsEnterMail')
                  :
                  !validateEmail(emailReset)
                    ?
                    t('register.invalidEmail')
                    :
                    ""
              }
            </p>

            <div className="text-center">
              <Button
                onClick={() => handleResetPass()}
                className="btn-block text-uppercase font-weight-bold font-size-sm mt-4"
                color="primary">
                {t('login.sendEmail')}
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}
const mapDispatchToProps = (dispatch) => {
  return {
    saveUserInfo: (data, password) => dispatch({ type: "SAVE_USER_INFO", data: data, password: password }),
    cleanStore: () => dispatch({ type: "CLEAN_STORE" }),
    verifyAccount: (userInfo, password) => dispatch({ type: "VERIFY_ACCOUNT", userInfo: userInfo, password: password })
  }
}
const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
  }
}
export default withRouter(withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(Login)));
