import {
  GET_SCAN_STAMP,
  GET_SCAN_STAMP_SUCCESS,
  GET_SCAN_STAMP_FAILURE,
} from "actions/types";
import { takeLatest, call, put, select } from 'redux-saga/effects'
import { LINK_API } from 'constants/API.js'
import axios from 'axios'
import moment from "moment";
const getToken = (state) => state.userInfo

// Get user profile
function* getStampScan(payload) {
  try {
    const token = yield select(getToken)
    const response = yield call(fetchScan, payload.startTime, payload.endTime, payload.cycle, token.data.token)
    const data = response.data.data;
    let label = []
    let value = [];
    let startWeek = moment(payload.startTime, "DD-MM-YYYY").week();
    console.log("start week", startWeek)
    let startYear = moment(payload.startTime, "DD-MM-YYYY").year();
    let lastWeek = 52;
    let endWeek = moment(payload.endTime, "DD-MM-YYYY").week();
    let endYear = moment(payload.endTime, "DD-MM-YYYY").year();
    console.log("Check function")
    if (startYear === endYear) {
      console.log("Check inside")
      console.log("length", data)
      for (let i = 0; i < data.length; i++) {
        let j = startWeek;
        if (i === 0) {
          while (data[i].week > j) {
            label.push("T" + j);
            value.push(0)
            j++;
          }
          label.push("T" + data[i].week)
          value.push(data[i].count)
        }
       
        if (i !== data.length - 1) {
          if (data[i].week < data[i + 1].week - 1) {
            if (i !== 0) {
              label.push("T" + data[i].week)
              value.push(data[i].count)
            }

            for (let x = data[i].week + 1; x < data[i + 1].week; x++) {
              label.push("T" + x)
              value.push(0)
            }

          } else {
            if (i !== 0) {
              label.push("T" + data[i].week)
              value.push(data[i].count)
            }

          }
        } else {
          console.log("Check end week")
          if(i!==0){
            let j = data[i].week;
            while (j < endWeek) {
              label.push("T" + j);
              value.push(0)
              j++;
            }
            if (data[i].week === endWeek) {
              label.push("T" + data[i].week)
              value.push(data[i].count)
            } else {
              label.push("T" + endWeek)
              value.push(0)
            }
          }
        }
      }
    } else {
      if (startYear < endYear) {
        if (data.length === 0) {
          for (let i = startWeek; i <= lastWeek; i++) {
            label.push("T" + i + ` (${startYear})`)
            value.push(0)
          }
          for (let i = 1; i <= endWeek; i++) {
            label.push("T" + i + ` (${endYear})`)
            value.push(0)
          }
        }
        for (let i = 0; i < data.length; i++) {
          if (data[i].year < endYear) {
            let j = startWeek;
            if (i === 0) {
              while (data[i].week > j) {
               // console.log("Check j: ", j)
                label.push("T" + j + ` (${data[i].year})`);
                value.push(0);
                j++;
              }
              label.push("T" + data[i].week + ` (${data[i].year})`);
              value.push(data[i].count);
            }

            if (i !== (data.length - 1)) {
              if (data[i + 1].year < endYear) {
                if (i !== 0) {
                //  console.log(data[i].week )
                  label.push("T" + data[i].week + ` (${data[i].year})`);
                  value.push(data[i].count);
                }
                for (let x = data[i].week + 1; x < data[i + 1].week; x++) {
                 // console.log(x)
                  label.push("T" + x + ` (${data[i].year})`);
                  value.push(0);
                }
              } else {
                if (i !== 0) {
                 // console.log(data[i].week)
                
                  label.push("T" + data[i].week + ` (${data[i].year})`);
                  value.push(data[i].count);
                }
                for (let x = data[i].week + 1; x <= 52; x++) {
                 // console.log(x)
                  label.push("T" + x + ` (${data[i].year})`);
                  value.push(0);
                }
                if (data[i].year < endYear) {
                  for (let y = 1; y < data[i + 1].week; y++) {
                  //  console.log(y)
                    label.push("T" + y + ` (${data[i + 1].year})`);
                    value.push(0);
                  }
                } else {
                  for (let y = data[i].week + 1; y < data[i + 1].week; y++) {
                  //  console.log(y)
                    label.push("T" + y + ` (${data[i].year})`);
                    value.push(0);
                  }
                }
              }
            } else {
              if (data[i].year < endYear) {
                label.push("T" + data[i].week + ` (${data[i].year})`);
                value.push(data[i].count);
                for (let x = data[i].week + 1; x <= 52; x++) {
                  label.push("T" + x + ` (${data[i].year})`);
                  value.push(0);
                }
                for (let y = 1; y <= endWeek; y++) {
                  label.push("T" + y + ` ${endYear}`);
                  value.push(0);
                }
              }
            }

          } else {
            if(value.length === 0){
              for(let y = startWeek; y <= 52; y++){
               // console.log(y)
                label.push("T" + y + ` (${startYear})`)
                value.push(0)
              }
              for(let y = 1; y < data[i].week; y++){
               // console.log(y, data[i].week)
                label.push("T" + y + ` (${data[i].year})`)
                value.push(0)
              }
            }
            if (i !== data.length - 1) {
              if (data[i].week < data[i + 1].week - 1) {
                if (i !== 0) {
                  label.push("T" + data[i].week + ` (${data[i].year})`)
                  value.push(data[i].count)
                }
                for (let x = data[i].week + 1; x < data[i + 1].week; x++) {
                  label.push("T" + x + ` (${data[i].year})`)
                  value.push(0)
                }
              } else {
                if (i !== 0) {
                  label.push("T" + data[i].week + ` (${data[i].year})`)
                  value.push(data[i].count)
                }
              }
            } else {
              let j = data[i].week + 1;
             // console.log("j", endWeek)
              while (j < endWeek) {
              //  console.log(j)
                label.push("T" + j + ` (${data[i].year})`);
                value.push(0)
                j++;
              }
              if (data[i].week === endWeek) {
                label.push("T" + data[i].week + ` (${data[i].year})`)
                value.push(data[i].count)
              } else {
                //console.log("Check data", data[i].count)
               
                label.push("T" + endWeek + ` (${data[i].year})`)
                value.push(endWeek)
               // console.log("value", value)
              }
            }

          }
        }
      }
    }
    console.log("Value", value);
    console.log("label", label)

    const responseMonth = yield call(fetchScan, payload.startTime, payload.endTime, "MONTH", token.data.token);
    const dataMonth = responseMonth.data.data;
    let labelMonth = [];
    let valueMonth = [];
    let startMonth = moment(payload.startTime, "DD-MM-YYYY").month() + 1;
    console.log("startMonth", startMonth)
    let endMonth = moment(payload.endTime, "DD-MM-YYYY").month() + 1;
    console.log("endMonth", endMonth)
    if (startYear === endYear) {
      for (let i = 0; i < dataMonth.length; i++) {
        let j = startMonth;
        if (i === 0) {
          while (dataMonth[i].month > j) {
            labelMonth.push("T" + j + ` (${dataMonth[i].year})`);
            valueMonth.push(0)
            j++;
          }
          labelMonth.push("T" + dataMonth[i].month + ` (${dataMonth[i].year})`)
          valueMonth.push(dataMonth[i].count)
        }
        if (i !== dataMonth.length - 1) {
          if (dataMonth[i].month < dataMonth[i + 1].month - 1) {
            if (i !== 0) {
              labelMonth.push("T" + dataMonth[i].month + ` (${dataMonth[i].year})`)
              valueMonth.push(dataMonth[i].count)
            }

            for (let x = dataMonth[i].month + 1; x < dataMonth[i + 1].month; x++) {
              labelMonth.push("T" + x + ` (${dataMonth[i].year})`)
              valueMonth.push(0)
            }

          } else {
            if (i !== 0) {
              labelMonth.push("T" + dataMonth[i].month + ` (${dataMonth[i].year})`)
              valueMonth.push(dataMonth[i].count)
            }
          }
        } else {
          if(i!==0){
            let j = dataMonth[i].month + 1;
            while (j < endMonth) {
              labelMonth.push("T" + j + ` (${dataMonth[i].year})`);
              valueMonth.push(0)
              j++;
            }
            if (dataMonth[i].month === endMonth) {
              labelMonth.push("T" + dataMonth[i].month + ` (${dataMonth[i].year})`)
              valueMonth.push(dataMonth[i].count)
            } else {
              labelMonth.push("T" + endMonth + ` (${dataMonth[i].year})`)
              valueMonth.push(0)
            }
          }
        }

      }
    } else {
      if (startYear < endYear) {
        if (dataMonth.length === 0) {
          for (let i = startMonth; i <= 12; i++) {
            labelMonth.push("T" + i + ` (${startYear})`)
            valueMonth.push(0)
          }
          for (let i = 1; i <= endMonth; i++) {
            labelMonth.push("T" + i + ` (${endYear})`)
            valueMonth.push(0)
          }
        }
        for (let i = 0; i < dataMonth.length; i++) {
          if (dataMonth[i].year < endYear) {
            let j = startMonth;
            if (i === 0) {
              while (dataMonth[i].month > j) {
                labelMonth.push("T" + j + ` (${dataMonth[i].year})`);
                valueMonth.push(0);
                j++;
              }
              labelMonth.push("T" + dataMonth[i].month + ` (${dataMonth[i].year})`);
              valueMonth.push(dataMonth[i].count);
            }

            if (i !== dataMonth.length - 1) {
              if (dataMonth[i + 1].year < endYear) {
                if (i !== 0) {
                  labelMonth.push("T" + dataMonth[i].month + ` (${dataMonth[i].year})`);
                  valueMonth.push(dataMonth[i].count);
                }
                for (let x = dataMonth[i].month + 1; x < dataMonth[i + 1].month; x++) {
                  labelMonth.push("T" + x + ` (${dataMonth[i].year})`);
                  valueMonth.push(0);
                }
              } else {

                if (i !== 0) {
                  labelMonth.push("T" + dataMonth[i].month + ` (${dataMonth[i].year})`);
                  valueMonth.push(dataMonth[i].count);
                }
                for (let x = dataMonth[i].month + 1; x <= 12; x++) {
                  labelMonth.push("T" + x + ` (${dataMonth[i].year})`);
                  valueMonth.push(0);
                }
                if (dataMonth[i].year < endYear) {
                  for (let y = 1; y < dataMonth[i + 1].month; y++) {
                    labelMonth.push("T" + y + ` (${dataMonth[i + 1].year})`);
                    valueMonth.push(0);
                  }
                } else {
                  for (let y = dataMonth[i].month + 1; y < dataMonth[i + 1].month; y++) {
                    labelMonth.push("T" + y + ` (${dataMonth[i].year})`);
                    valueMonth.push(0);
                  }
                }
              }
            } else {
              if (dataMonth[i].year < endYear) {
                labelMonth.push("T" + dataMonth[i].month + ` (${dataMonth[i].year})`);
                  valueMonth.push(dataMonth[i].count);
                for (let x = dataMonth[i].month + 1; x <= 12; x++) {
                  labelMonth.push("T" + x + ` (${dataMonth[i].year})`);
                  valueMonth.push(0);
                }
                for (let y = 1; y <= endMonth; y++) {
                  labelMonth.push("T" + y + ` ${endYear}`);
                  valueMonth.push(0);
                }
              }
            }

          } else {
            if (i !== dataMonth.length - 1) {
              if (dataMonth[i].month < dataMonth[i + 1].month - 1) {
                if (i !== 0) {
                  labelMonth.push("T" + dataMonth[i].month + ` (${dataMonth[i].year})`)
                  valueMonth.push(dataMonth[i].count)
                }
                for (let x = dataMonth[i].month + 1; x < dataMonth[i + 1].month; x++) {
                  labelMonth.push("T" + x + ` (${dataMonth[i].year})`)
                  valueMonth.push(0)
                }
              } else {
                if (i !== 0) {
                  labelMonth.push("T" + dataMonth[i].month + ` (${dataMonth[i].year})`)
                  valueMonth.push(dataMonth[i].count)
                }
              }
            } else {
              let j = dataMonth[i].month + 1;
              while (j < endMonth) {
                labelMonth.push("T" + j + ` (${dataMonth[i].year})`);
                valueMonth.push(0)
                j++;
              }
              if (dataMonth[i].month === endMonth) {
                labelMonth.push("T" + dataMonth[i].month + ` (${dataMonth[i].year})`)
                valueMonth.push(dataMonth[i].count)
              } else {
                labelMonth.push("T" + endMonth + ` (${dataMonth[i].year})`)
                valueMonth.push(endMonth)
              }
            }

          }
        }
      }
    }
   
    yield put({ type: GET_SCAN_STAMP_SUCCESS, label: label, value: value, labelMonth: labelMonth, valueMonth: valueMonth })
  } catch (error) {
    yield put({ type: GET_SCAN_STAMP_FAILURE })
  }
}
function fetchScan(startTime, endTime, cycle, checktoken) {
  axios.defaults.headers.common.Authorization = checktoken
  return axios({
    method: 'GET',
    url: LINK_API.GET_SCAN_STAMP + "startTime=" + startTime + "&endTime=" + endTime + "&cycle=" + cycle
  })
}
const actions = [
  takeLatest(GET_SCAN_STAMP, getStampScan)
]
export default actions

