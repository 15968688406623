//formik
import React, { useEffect } from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Button, UncontrolledAlert } from "reactstrap";
//style
import "view/css/select.css";
import "../../css/index.css";



const StepTwo = (props) => {
    const t = props.t;
    const {
        isSubmitting,
        onBack,
        cantBack,
    } = props;
    const [areaList, setAreaList] = React.useState([]);
    const [objectList, setObjectList] = React.useState([]);
    const permission = props.permission.data;
    const [checkPer, setPer] = React.useState(false);
    React.useEffect(()=>{
        if(permission){
            setPer(permission.advanceSetting)
        }
    },[permission])
    const [checkedArea, setArea] = React.useState(false)
    const [selectedArea, setSelectedArea] = React.useState({value: t("all"), label: t("all")});
    React.useEffect(()=>{
        if(selectedArea){
            if(selectedArea.value){
                if(selectedArea.value === t('all')){
                    setArea(false)
                }else{
                    setArea(true)
                }
            }
            if(selectedArea[0]){
                if(selectedArea[0].value === t("all")){
                    setArea("false")
                }else{
                    setArea(true)
                }
            }
        }
        },[selectedArea])
    const [selectedRole, setSelectedRole] = React.useState({
        value: "M",
        label: t('employee.manager')
    });
    const [selectedObject, setSelectedObject] = React.useState([]);
    const roleList = [
        {
            value: "M",
            label: t('employee.manager')
        },
        {
            value: "SM",
            label: t('employee.emp')
        },
        // {
        //     value: "SP",
        //     label: t('employee.pemp')
        // }
    ]



    const handleBack = async () => {
        let values = {
            role: selectedRole,
            object: selectedObject,
            area: selectedArea
        }
        await props.mergeData(values);
        onBack();
    }
    const handleSubmitData = async () => {
        let values = {
            role: selectedRole,
            object: selectedObject,
            area: selectedArea
        }
        await props.mergeData(values);
        props.onNext(values);

    }

    const dataStepTwo = props.data;
    const areaListRender = props.areaList.data;
    React.useEffect(() => {
        if (dataStepTwo.object) {
            setSelectedObject(dataStepTwo.object)
        }
        if (dataStepTwo.area) {
            setSelectedArea(dataStepTwo.area)
        }
        if (dataStepTwo.role) {
            setSelectedRole(dataStepTwo.role)
        }
    }, [dataStepTwo, areaListRender])


    const renderArea = props.renderArea;
    const getObjectList = props.getObjectList;
    useEffect(() => {
        renderArea(1, 100);
        getObjectList(1, 100);

    }, [renderArea, getObjectList]);


    const objectListData = props.objectList.data;
    useEffect( () => {
        const all = t('all');
        let result = [{ value: all, label: all }];
        for (let i = 0; i < objectListData.length; i++) {
            result.push({
                value: objectListData[i].uuid,
                label: objectListData[i].name + ' - ' + objectListData[i].uuid
            });
        }

        setObjectList(result);
        let temp = areaListRender;
        if(temp[0]){
            if(temp[0].value !== t('all')){
                temp.unshift({ value: t('all'), label: t('all') })
            }
        }
         
        // temp.push({ value: t('all'), label: t('all') });
        // let area_array = temp.concat(areaListRender);
        setAreaList(temp);
    }, [areaListRender, areaList, objectListData, t]);


    const handleChange_SelectArea = async (selectedOption) => {
       if(selectedOption){
           if(selectedOption.length){
               if(selectedOption[selectedOption.length -1].value === t('all')){
                   await setArea(false);
                   await setSelectedArea({
                       value: t('all'),
                       label: t('all')
                   })
               }else{
                setSelectedArea(selectedOption) 
               }
           }else{
            setSelectedArea(selectedOption) 
           }
       }else{
        await setSelectedArea({
            value: t('all'),
            label: t('all')
        })
       }
        
    }

    const handleChange_SelectRole = (selectedOption) => {
        if (selectedOption) {
            setSelectedRole(selectedOption)
        }
    }

    // const handleChange_SelectObject = async (selectedOption) => {
    //     setSelectedObject(selectedOption)
    // }


    return (
        <>
            <div className="wizard-steps horizontal">
                <div className="p-4">
                    <h5 className="font-size-xl font-weight-bold">{t('employee.addNewMember')}</h5>
                    <p className="text-black-50 mb-4"></p>
                    {!checkPer ? <UncontrolledAlert color="warning">
              {t('notiPlan')}
            </UncontrolledAlert> : null}
                    <div className="form-row ">
                        <div className="form-group col-md-6 pr-3">
                            <label className="font-weight-bold" >{t('employee.inChargeArea')}</label>
                            <Select
                                placeholder={t('employee.enterIDArea')}
                                options={areaList}
                                isDisabled={!checkPer}
                                isMulti={checkedArea}
                                value={selectedArea}
                                onChange={handleChange_SelectArea}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: '0.29rem',
                                    borderWidth: 1,
                                    colors: {
                                        ...theme.colors,
                                        primary25: 'rgba(60,68,177,0.15)',
                                        primary50: 'rgba(60,68,177,0.15)',
                                        primary: '#4FB779'
                                    }
                                })}
                            />
                        </div>
                        <div className="form-group col-md-6 pl-3">

                            <label className="font-weight-bold" >{t('employee.role')}</label>
                            <Select
                                placeholder={t('employee.role')}
                                options={roleList}
                                isDisabled={!checkPer}
                                value={selectedRole}
                                onChange={handleChange_SelectRole}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: '0.29rem',
                                    borderWidth: 1,
                                    colors: {
                                        ...theme.colors,
                                        primary25: 'rgba(60,68,177,0.15)',
                                        primary50: 'rgba(60,68,177,0.15)',
                                        primary: '#4FB779'
                                    }
                                })}
                            />
                        </div>
                    </div>
                    {/* <div className="form-row">
                        <div className="form-group col-md-6 pr-3 ">
                            <label className="font-weight-bold" >{t('employee.inChargeObject')}</label>
                            <Select
                                placeholder={t('employee.inChargeObject')}
                                options={objectList}
                                value={selectedObject}
                                isMulti
                                onChange={handleChange_SelectObject}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: '0.29rem',
                                    borderWidth: 1,
                                    colors: {
                                        ...theme.colors,
                                        primary25: 'rgba(60,68,177,0.15)',
                                        primary50: 'rgba(60,68,177,0.15)',
                                        primary: '#4FB779'
                                    }
                                })}
                            />
                        </div>
                    </div> */}
                    <div className="form-row ">
                        <div className="form-group col-md-12">
                            <p><span className="text-danger">(*)</span> Trường thông tin bắt buộc</p>
                        </div>
                    </div>

                    <div className="form-row ">
                        <div className="form-group col-md-6 d-flex justify-content-start">
                            <Button
                                size="lg"
                                className="text-uppercase font-weight-bold font-size-sm"
                                outline
                                onClick={handleBack}
                                disabled={isSubmitting || cantBack}
                                color="info">
                                {t('pre')}
                            </Button>
                        </div>
                        <div className="form-group col-md-6 d-flex justify-content-end">
                            <Button
                                size="lg"
                                onClick={handleSubmitData}
                                className="text-uppercase font-weight-bold font-size-sm"
                                color="info">
                                {t('confirm')}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.userInfo,
        areaList: state.renderArea,
        objectList: state.objectList,
        permission: state.permission
    }
}

const mapDispatchToProps = (dispatch) => ({
    createEmployee: (data, image) => dispatch({ type: "CREATE_EMPLOYEE", data: data, avatar: image }),
    renderArea: (page, size) => dispatch({ type: "RENDER_AREA_LIST", page: page, size: size }),
    getObjectList: (page, size) => dispatch({ type: "GET_OBJECT_LIST", page: page, size: size }),
});

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(StepTwo));