import DropZone from "components/dropZone";
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
//formik
import { useFormik } from 'formik';
import htmlToDraft from 'html-to-draftjs';
import Switch from 'rc-switch';
import React from "react";
import { Editor } from 'react-draft-wysiwyg';
import ReactGoogleMapLoader from "react-google-maps-loader";
import ReactGooglePlacesSuggest from "react-google-places-suggest";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { Input } from "reactstrap";
import cookie from "react-cookies"
import * as Yup from 'yup';
import { LINK_API } from "constants/API"

//style
import "view/css/select.css";

//google api key
const MY_API_KEY = "AIzaSyCB7o1cgt10O8xP9lLbk7Iikfv5ypvr528";

const Step2 = React.forwardRef((props, ref) => {
    const t = props.t;
    const uuid = cookie.load("uuidProduct");
    const [listImage, setImage] = React.useState([]);
    const [listCert, setCert] = React.useState([]);
    const [editorState, setEditor] = React.useState(EditorState.createEmpty());
    const [checked, setChecked] = React.useState(false);
    const phone_regex = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;  // validate phone vietnam

    const toggle = () => {
        setChecked(!checked)
    };
    React.useImperativeHandle(ref, () => ({
        handleMergeData() {
            if (checked) {
                formik.submitForm()
            } else {
                let data = {
                    checked: false
                }
                props.saveData(data, false)
            }
        }
    }));

    const handleChangeEditor = async (editorState) => {
        document.getElementById("desNoti").innerHTML = "";
        setEditor(editorState)
    }

    const handleBanner = (data) => {
        setImage(data);
        document.getElementById("avaNoti").innerHTML = "";
    }
    const handleChangeAddress = name => event => {
        let values = event.target.value;
        if (values) {
            formik.setFieldValue("search", values);
            formik.setFieldValue("address", values, true);
        } else {
            formik.setFieldValue("search", values);
            formik.setFieldValue("address", values, true);
            formik.setFieldError("address", "Thông tin bắt buộc")
        }

        //formik.validateField("address")
    }
    const handleNoResult = () => {
        console.log('Không có kết quả')
    }
    const handleCert = (data) => {
        setCert(data)
        document.getElementById("certNoti").innerHTML = "";
    }

    const handleSelectSuggest = (geocodedPrediction) => {
        formik.setFieldValue("address", geocodedPrediction.formatted_address)
        formik.setFieldValue("search", "")
    }
    const formik = useFormik({
        initialValues: {
            nameManu: '',
            address: '',
            email: '',
            phone: '',
            website: '',
            tax: '',
            search: ''
        },
        validationSchema: Yup.object({
            nameManu: Yup.string()
                .required("Thông tin bắt buộc"),
            email: Yup.string()
                .email('Địa chỉ email không hợp lệ')
                .required('Thông tin bắt buộc'),
            address: Yup.string()
                .required('Thông tin bắt buộc'),
            phone: Yup.string().matches(phone_regex, 'Số điện thoại không hợp lệ')
                .required('Thông tin bắt buộc'),
        }),
        onSubmit: async (values) => {
            let dynamicContent = await draftToHtml(convertToRaw(editorState.getCurrentContent()))
            dynamicContent = await dynamicContent.toString()
            dynamicContent = await dynamicContent.replace("↵", '')
            if (dynamicContent === "<p></p>\n") {
                document.getElementById("desNoti").innerHTML = "Vui lòng điền thông tin miêu tả doanh nghiệp";
                return;
            }
            let data = {
                ...values,
                banner: listImage,
                companyCert: listCert,
                companyDes: dynamicContent,
                checked: checked
            }

            props.saveData(data, false)
        }
    });
    const dataStepTwo = props.data;
    React.useEffect(() => {
        setChecked(dataStepTwo.showOrigin)
        if (dataStepTwo.showOrigin) {
            if (dataStepTwo.origin) {
                formik.setFieldValue("nameManu", dataStepTwo.origin.name)
                formik.setFieldValue("address", dataStepTwo.origin.address)
                formik.setFieldValue("email", dataStepTwo.origin.email)
                formik.setFieldValue("tax", dataStepTwo.origin.tax)
                formik.setFieldValue("website", dataStepTwo.origin.website)
                let bannerList = [];
                if (dataStepTwo.origin.banner) {
                    if (dataStepTwo.origin.banner.length) {
                        for (let i = 0; i < dataStepTwo.origin.banner.length; i++) {
                            bannerList.push(dataStepTwo.origin.banner[i].secure_url)
                        }
                    }
                }
                let certList = [];
                if (dataStepTwo.origin.certificates) {
                    if (dataStepTwo.origin.certificates.length) {
                        for (let i = 0; i < dataStepTwo.origin.certificates.length; i++) {
                            certList.push(dataStepTwo.origin.certificates[i].secure_url)
                        }
                    }
                }

                setImage(bannerList)
                setCert(certList)
                let indexOf = dataStepTwo.origin.phone.indexOf("+84");
                if (indexOf === 0) {
                    let phone = dataStepTwo.origin.phone.split(" ").join("");
                    phone = '0' + phone.substring(3, phone.length);
                    formik.setFieldValue("phone", phone)
                } else {
                    formik.setFieldValue("phone", dataStepTwo.origin.phone)
                }


                if (dataStepTwo.origin.description) {
                    let dynamicContent = dataStepTwo.origin.description;
                    let contentBlock = htmlToDraft(dynamicContent);
                    if (contentBlock) {
                        let contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                        let editorState = EditorState.createWithContent(contentState);
                        setEditor(editorState)
                    }
                }
            }

        }

    }, [dataStepTwo])
    return (
        <>
            <div className="wizard-steps horizontal">
                <form onSubmit={formik.handleSubmit}>
                    <div className="p-4">
                        <h5 className="font-size-xl font-weight-bold">{t('brand')}</h5>
                        <p className="text-black-50 mb-4">{t('product.brandInfo')}</p>
                        <div className="m-2 form-row">
                            <Switch checked={checked} onClick={toggle} className="switch-small toggle-switch-success"></Switch>
                            <p className="font-weight-bold ml-2">{t(`product.showBrand`)}</p>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-6 pr-3">
                                <label className="font-weight-bold">{t('product.nameManu')} <span className="text-danger">(*)</span></label>
                                <Input
                                    placeholder={t('product.nameManu')}
                                    type="text"
                                    disabled={!checked}
                                    value={formik.values.nameManu}
                                    valid={formik.touched.nameManu && formik.errors.nameManu ? false : (formik.values.nameManu ? true : false)}
                                    invalid={formik.touched.nameManu && formik.errors.nameManu ? true : false}
                                    {...formik.getFieldProps('nameManu')}
                                />
                                {formik.touched.nameManu && formik.errors.nameManu ? (
                                    <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.nameManu}</p>
                                ) : null}
                            </div>
                            <div className="form-group col-md-6 pl-3">
                                <label className="font-weight-bold">{t('product.address')} <span className="text-danger">(*)</span></label>
                                <ReactGoogleMapLoader
                                    params={{
                                        key: MY_API_KEY,
                                        libraries: "places,geocode",
                                    }}
                                    render={googleMaps =>
                                        googleMaps && (
                                            <div>
                                                <ReactGooglePlacesSuggest
                                                    autocompletionRequest={{ input: formik.values.search }}
                                                    googleMaps={googleMaps}
                                                    onSelectSuggest={handleSelectSuggest}
                                                    displayPoweredByGoogle={false}
                                                    onNoResult={handleNoResult}

                                                >
                                                    <Input
                                                        placeholder={t('product.address')}
                                                        onChange={handleChangeAddress('address')}
                                                        type="text"
                                                        disabled={!checked}
                                                        name="address"
                                                        value={formik.values.address}
                                                        valid={formik.touched.address && formik.errors.address ? false : (formik.values.address ? true : false)}
                                                        invalid={formik.touched.address && formik.errors.address ? true : false}
                                                    // {...formik.getFieldProps('address')}
                                                    />
                                                </ReactGooglePlacesSuggest>
                                            </div>
                                        )
                                    }
                                />
                                {formik.touched.address && formik.errors.address ? (
                                    <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.address}</p>
                                ) : null}
                            </div>
                        </div>
                        <div className="form-row ">
                            <div className="form-group col-md-6 pr-3">
                                <label className="font-weight-bold">{t('product.email')} <span className="text-danger">(*)</span></label>
                                <Input
                                    placeholder={t('product.email')}
                                    type="email"
                                    disabled={!checked}
                                    name="email"
                                    id="numberPrice"
                                    valid={formik.touched.email && formik.errors.email ? false : (formik.values.email ? true : false)}
                                    invalid={formik.touched.email && formik.errors.email ? true : false}
                                    value={formik.values.email}
                                    {...formik.getFieldProps('email')}
                                />
                                {formik.touched.email && formik.errors.email ? (
                                    <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.email}</p>
                                ) : null}
                            </div>
                            <div className="form-group col-md-6 pl-3">
                                <label className="font-weight-bold" >{t('product.phone')} <span className="text-danger">(*)</span></label>
                                <Input
                                    placeholder={t('product.phone')}
                                    type="text"
                                    disabled={!checked}
                                    valid={formik.touched.phone && formik.errors.phone ? false : (formik.values.phone ? true : false)}
                                    invalid={formik.touched.email && formik.errors.email ? true : false}
                                    value={formik.values.phone}
                                    {...formik.getFieldProps('phone')}
                                />
                                {formik.touched.phone && formik.errors.phone ? (
                                    <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.phone}</p>
                                ) : null}
                            </div>
                        </div>
                        <div className="form-row ">
                            <div className="form-group col-md-6 pr-3">
                                <label className="font-weight-bold" >{t('product.tax')}</label>
                                <Input
                                    placeholder={t('product.tax')}
                                    type="number"
                                    disabled={!checked}
                                    valid={formik.touched.tax && formik.errors.tax ? false : (formik.values.tax ? true : false)}
                                    value={formik.values.tax}
                                    {...formik.getFieldProps('tax')}
                                />
                            </div>
                            <div className="form-group col-md-6 pl-3">
                                <label className="font-weight-bold" >{t('product.website')}</label>
                                <Input
                                    disabled={!checked}
                                    placeholder={t('product.website')}
                                    type="text"
                                    valid={formik.touched.website && formik.errors.website ? false : (formik.values.website ? true : false)}
                                    value={formik.values.website}
                                    {...formik.getFieldProps('website')}
                                />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-6 pr-3">
                                <label className="font-weight-bold">{t('product.banner')} </label>
                                <DropZone
                                    max={1}
                                    onConfirm={handleBanner}
                                    disabled={!checked}
                                    data={listImage}
                                    api={true}
                                    uuid={uuid}
                                    path={LINK_API.UPDATE_BANNER_ORIGIN}
                                />
                                <p id="avaNoti" className="font-weight-regular font-size-sm text-danger" ></p>
                            </div>

                            <div className="form-group col-md-6 pl-3">
                                <label className="font-weight-bold">{t('product.cert')}</label>
                                <DropZone
                                    max={3}
                                    onConfirm={handleCert}
                                    data={listCert}
                                    disabled={!checked}
                                    api={true}
                                    uuid={uuid}
                                    path={LINK_API.UPDATE_CERT_ORIGIN}
                                />
                                <p id="certNoti" className="font-weight-regular font-size-sm text-danger" ></p>
                            </div>
                        </div>
                        <div className="form-row ">
                            <div className="form-group col-md-12">
                                <label className="font-weight-bold">{t('product.companyDes')} <span className="text-danger">(*)</span></label>
                                <Editor
                                    readOnly={!checked}
                                    editorState={editorState}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName={!checked ? "demo-editor borderEditorRead" : "demo-editor borderEditor"}
                                    onEditorStateChange={handleChangeEditor}
                                    toolbar={{
                                        options: ["inline", 'image', 'embedded', 'textAlign', "link",]
                                    }}
                                />
                                <p id="desNoti" className="font-weight-regular font-size-sm text-danger" ></p>
                            </div>
                        </div>
                        <div className="form-row ">
                            <div className="form-group col-md-12">
                                <p><span className="text-danger">(*)</span> Trường thông tin bắt buộc</p>
                            </div>
                        </div>

                    </div>
                </form>
            </div>
        </>
    )
})
const mapDispatchToProps = (dispatch) => ({
    saveData: (dataTwo, check) => dispatch({ type: "SAVE_DATA_EDIT_PRODUCT", dataTwo: dataTwo, check: check }),
    renderEmp: (page, size) => dispatch({ type: "RENDER_EMPLOYEE_LIST", page: page, size: size }),
});

export default withTranslation('common', { withRef: true })(connect(null, mapDispatchToProps, null, { forwardRef: true })(Step2));