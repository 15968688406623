import {
    GET_VALID_STAMPS_RANGE,
    GET_VALID_STAMPS_RANGE_SUCCESS,
    GET_VALID_STAMPS_RANGE_FAILURE
  
  } from "actions/types"
  import { takeLatest, call, put, select } from 'redux-saga/effects'
  import { LINK_API } from 'constants/API.js'
  import axios from 'axios'
  
  const getToken = (state) => state.userInfo
  
  function* getValidStampRange() {
  
    try {
      const tokenInfo = yield select(getToken)
      const response = yield call(fetchValidStampRange, tokenInfo.data.token)
      const check = response.data
      yield put({ type: GET_VALID_STAMPS_RANGE_SUCCESS, data: check.data, errorCode: check.errorCode, totalPage: check.totalPage })
    } catch (error) {
      yield put({ type: GET_VALID_STAMPS_RANGE_FAILURE, payload: '', errorCode: 0, totalPage: 0 })
    }
  }
  
  function fetchValidStampRange(checktoken) {
    axios.defaults.headers.common.Authorization = checktoken;
    return axios({
      method: 'GET',
      url: LINK_API.GET_VALID_STAMPS_RANGE
    })
  }
  
  const validStampRange = [
    takeLatest(GET_VALID_STAMPS_RANGE, getValidStampRange)
  ]
  export default validStampRange
  
  