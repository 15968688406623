import {
  GET_USED_STAMP_LIST,
  GET_USED_STAMP_LIST_SUCCESS,
  GET_USED_STAMP_LIST_FAILURE

} from "actions/types"
import { takeLatest, call, put, select } from 'redux-saga/effects'
import { LINK_API } from 'constants/API.js'
import axios from 'axios'

const getToken = (state) => state.userInfo

// Get Stamp list
function* getUsedStampList(data) {

  try {
    const tokenInfo = yield select(getToken)
    const response = yield call(fetchUsedStampList, data.data, tokenInfo.data.token)
    const check = response.data
    yield put({ type: GET_USED_STAMP_LIST_SUCCESS, data: check.data, errorCode: check.errorCode, totalPage: check.totalPage })
  } catch (error) {
    yield put({ type: GET_USED_STAMP_LIST_FAILURE, payload: '', errorCode: 0, totalPage: 0 })
  }
}

function fetchUsedStampList(data, checktoken) {
  const { type, startTime, endTime, page, size, minId, maxId, diaryLedgerUuid } = data
  axios.defaults.headers.common.Authorization = checktoken;
  if (startTime && endTime) {
    return axios({
      method: 'GET',
      url: diaryLedgerUuid ? LINK_API.GET_USED_STAMP + `?type=UNIT&startTime=${startTime}&endTime=${endTime}&page=${page}&size=${size}&groupBy=productNames&diaryLedgerUuid=${diaryLedgerUuid}` : LINK_API.GET_USED_STAMP + `?type=${type}&startTime=${startTime}&endTime=${endTime}&page=${page}&size=${size}&groupBy=productNames`
    })
  }
  return axios({
    method: 'GET',
    url: diaryLedgerUuid ?  LINK_API.GET_USED_STAMP + `?type=UNIT&maxId=${maxId}&&minId=${minId}&page=${page}&size=${size}&groupBy=productNames&diaryLedgerUuid=${diaryLedgerUuid}`:  LINK_API.GET_USED_STAMP + `?type=${type}&maxId=${maxId}&&minId=${minId}&page=${page}&size=${size}&groupBy=productNames`
  })
}

const stampList = [
  takeLatest(GET_USED_STAMP_LIST, getUsedStampList)
]
export default stampList

