const initialState = { data: [] }

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_GENERAL':
      return {
        ...state
      }
    case 'GET_GENERAL_SUCCESS':
      return {
        ...state,
        data: action.data
      }
    case 'GET_GENERAL_FAILURE':
      return {
        ...state,
      }
    default:
      return state
  }
}
export default reducer
