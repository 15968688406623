import {
  GET_INFO_ACTIVE_STAMP,
  GET_INFO_ACTIVE_STAMP_SUCCESS,
  GET_INFO_ACTIVE_STAMP_FAILURE

} from "actions/types"
import { takeLatest, call, put, select } from 'redux-saga/effects'
import { LINK_API } from 'constants/API.js'
import axios from 'axios'

const getToken = (state) => state.userInfo
const getFullStampList = (state) => state.infoActiveStamp.fullData
const getToTalItemStamp = (state) => state.infoActiveStamp.totalItem
//const getSaveSetting = (state) => state.settingList.data

function fetchStampList(page, size, checktoken) {
  axios.defaults.headers.common.Authorization = checktoken
  return axios({
    method: 'GET',
    url: LINK_API.GET_STAMP_HISTORY.concat('?page=', page, '&size=', size)
  })
}

function* getStampList(page) {
  try {
    const tokenInfo = yield select(getToken)
    const fullData = yield select(getFullStampList)
    const totalItem = yield select(getToTalItemStamp)

    const size = page.size;
    if (fullData[`${page.page}`]) {
      yield put({
        type: GET_INFO_ACTIVE_STAMP_SUCCESS,
        data: fullData[`${page.page}`],
        fullData: fullData
      })
    }
    const response = yield call(fetchStampList, page.page, size, tokenInfo.data.token)
    const data = response.data.data
    if (totalItem !== response.data.totalItem) {
      const newList = data
      fullData[`${page.page}`] = newList
      yield put({
        type: GET_INFO_ACTIVE_STAMP_SUCCESS,
        data: newList,
        errorCode: response.data.errorCode,
        totalItem: response.data.totalItem,
        totalPage: response.data.totalPage,
        fullData: fullData
      })
    } else {
      if (!fullData[`${page.page}`]) {
        const newList = data
        fullData[`${page.page}`] = newList
        yield put({
          type: GET_INFO_ACTIVE_STAMP_SUCCESS,
          data: newList,
          errorCode: response.data.errorCode,
          totalItem: response.data.totalItem,
          totalPage: response.data.totalPage,
          fullData: fullData
        })
      } else {
        if (fullData[`${page.page}`] !== data) {
          const newList = data
          fullData[`${page.page}`] = newList
          yield put({
            type: GET_INFO_ACTIVE_STAMP_SUCCESS,
            data: newList,
            errorCode: response.data.errorCode,
            totalItem: response.data.totalItem,
            totalPage: response.data.totalPage,
            fullData: fullData
          })
        }
      }
    }
  } catch (error) {
    yield put({ type: GET_INFO_ACTIVE_STAMP_FAILURE, payload: '', errorCode: 0 })
  }
}

const stampList = [
  takeLatest(GET_INFO_ACTIVE_STAMP, getStampList)
]
export default stampList

