const initialState = { data: '', errorCode: '' }
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_BRANCH_LIST':
      return {
        ...state
      }
    case 'GET_BRANCH_LIST_SUCCESS':
      return {
        ...state,
        data: action.data,
        errorCode: action.errorCode
      }
    case 'GET_BRNACH_LIST_FALURE':
      return {
        ...state,
        data: action.data,
        errorCode: action.errorCode
      }
    case 'CREATE_BRANCH':
      return {
        ...state
      }
    default:
      return state
  }
}
export default reducer
