const initialState = { data: [] }
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_LIST_PRODUCT_RETAIL':
      return {
        ...state
      }
    case 'GET_LIST_PRODUCT_RETAIL_SUCCESS':
      return {
        ...state,
        data: action.data
      }
    case 'RGET_LIST_PRODUCT_RETAIL_FALURE':
      return state
    default:
      return state
  }
}
export default reducer
