const initialState = { status_parent: false, status_child: false, status_top: false }
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ACTIVE_TOOLTIP':
      return {
        ...state
      }
    case 'ACTIVE_TOOLTIP_SUCCESS':
      return {
        ...state,
        status_parent: action.parent,
        status_child: action.child,
        status_top: action.top
      }
    case 'ACTIVE_TOOLTIP_FAILURE':
      return {
        ...state
      }
    case 'DEACTIVE_TOOLTIP':
      return {
        ...state
      }
    case 'DEACTIVE_TOOLTIP_SUCCESS':
      return {
        ...state,
        status_parent: action.parent,
        status_child: action.child,
        status_top: action.top
      }
    case 'DEACTIVE_TOOLTIP_FAILURE':
      return {
        ...state
      }
    default:
      return state
  }
}
export default reducer
