import LoadingTable from "components/LoadingTable/index.js";
import Pagination from "components/Pagination/index.jsx";
import moment from 'moment';
import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ReactTable from 'react-table-6';
import { Card, Container } from 'reactstrap';
import "../../css/reactTable.css";
import { motion } from "framer-motion";
import Header from "components/Headers/Header"
function FeedbackList(props) {
  const getFeedbackList = props.getFeedbackList;
  const [dataFeedbackList, setdataFeedbackList] = React.useState([])
  const [loadingState, setLoading] = React.useState(true)
  const {t} = props;
  const genTable = React.useCallback((feedbackList) => {
    let data = feedbackList.map((prop, index) => {
      return {
        id: index + 1,
        content: prop.content,
        name: (
            <div className="d-flex flex-row text-center justify-content-center">
        <p>{prop.productName}</p>
        <p className="ml-2">{`(${prop.uuidOfProduct})`}</p>
        </div>
        ),
        time: moment(prop.createdAt).format("DD/MM/YYYY"),
        type: prop.type === "BOX" ? t('partner.stampBox') :  t('partner.stampProduct')
      }
    })
    setdataFeedbackList(data)
    setLoading(false)
  }, [t])

  useEffect(() => {
    getFeedbackList()
  },[getFeedbackList]);

  const feedbackList = props.feedbackList.data
  //const totalPageProps = props.processList.totalPage
  useEffect(() => {
      if (feedbackList && feedbackList.length > 0) {
        genTable(feedbackList)
       // setTotalPage(totalPageProps)
      } else {
        genTable([])
        //setTotalPage(0)
      }

    },[ feedbackList, genTable]);


  const columns = React.useMemo(
    () => [
      {
        accessor: "id",
        filterable: false,
        className: "center",
        Header: () => (
          <div style={{
            textAlign: "center"
          }}>
            #
          </div>
        )
      },
      {
        Header: t('partner.nameProduct'),
        accessor: "name",
        sortable: false
      },
      {
        Header: t('partner.typeProduct'),
        accessor: "type",
        filterable: false
      },
      {
        Header: t('partner.time'),
        accessor: "time",
        filterable: false
      },
      {
        Header: t('partner.feedback'),
        accessor: "content",
        filterable: false
      },
    ],
    [t]
  );



  return (

    <>
      <motion.div
                className="w-100"
                initial={{ x: -100 }}
                animate={{ x: 0 }}
                transition={{ ease: "easeOut", duration: 0.3 }}
            >
              <Header/>
              <Container className='mt--7' fluid>
      <Card className="card-box mb-5">
        <div className="card-header py-3 d-flex justify-content-between">
          <div className=" font-weight-bold card-header--title font-size-lg">{t('partner.feedbackCus')}</div>
        </div>

        <div className="divider" />
        <div className="card-body pt-2 px-4 pb-4">

          <ReactTable
            noDataText={t('noData')}
            data={dataFeedbackList}
            columns={columns}
            previousText={"<"}
            nextText={">"}
            rowsText= {t('row')}
            ofText="/"
            defaultPageSize={5}
            showPaginationBottom={true}
            sortable={false}
            resizable={true}
            PaginationComponent={Pagination}
            //pages={totalPage}
            className="-striped -highlight"
            loading={loadingState}
            LoadingComponent={LoadingTable}
            // onFetchData={async (state, instance) => {
            //   setLoading(true)
            //   let x = state.page + 1;
            //   getFeedbackList(x, state.pageSize)
            // }}
          />
        </div>
      </Card>
      </Container>
      </motion.div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
    userProfile: state.userProfile.data,
    feedbackList: state.feedback,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getFeedbackList: () => dispatch({ type: "GET_FEEDBACK_LIST"}),
});

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(FeedbackList));