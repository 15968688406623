import LoadingTable from "components/LoadingTable/index.js";
import Pagination from "components/Pagination/index.jsx";
import PlusCircle from "mdi-react/PlusCircleIcon";
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ReactTable from 'react-table-6';
import { Button, Card, Container, UncontrolledTooltip, Modal, ModalBody } from 'reactstrap';
import "../../css/reactTable.css";
import Header from "components/Headers/Header"
import PenIcon from "mdi-react/PenIcon";
import Select from 'react-select';
import { LINK_API } from 'constants/API'
import axios from 'axios';
import Error from "components/alert/error";
import Loading from "components/alert/loading";
import Success from "components/alert/success";

function ObjectProductionList(props) {

  const { t } = props;
  const [alert, setAlert] = React.useState(null);
  const [totalPage, setTotalPage] = React.useState([])
  const [loadingState, setLoading] = React.useState(false)
  const [stampList, setStampList] = React.useState([])
  const [modal, setModal] = React.useState(false);
  const [modal2, setModal2] = React.useState(false);
  const [listCurrentStamp, setListCurrentStamp] = React.useState([])
  const [listSampleStamp, setListSampleStamp] = React.useState([])
  const [listStampAdd, setListStampAdd] = React.useState([])
  const [listCompany, setListCompany] = React.useState([])
  const [currentCompany, setCurrentCompany] = React.useState([])
  const [currentUuid, setCurrentUuid] = React.useState("")
  
  const columns = React.useMemo(
    () => [
      {
        accessor: "id",
        filterable: false,
        className: "center",
        maxWidth: 150,
        Header: () => (
          <div style={{
            textAlign: "center"
          }}>
            STT
          </div>
        )
      },
      {
        Header: "Tên nhà sản xuất",
        minWidth: 107,
        accessor: "name",
        filterable: false
      },
      {
        Header: "Số tem mẫu được cấp",
        accessor: "count",
        filterable: false
      },
      {
        Header: "Danh sách tem mẫu được cấp",
        accessor: "stampList",
        filterable: false
      },
      {
        Header: t('actions'),
        accessor: "actions",
        filterable: false
      },
    ],
    [t]
  );

  const data = [
    {
      id: 1,
      name: "Tem mẫu 1"
    },
    {
      id: 2,
      name: "Tem mẫu 2"
    }
  ]

  React.useEffect(() => {
    axios.get(LINK_API.GET_SAMPLE_GROUP_BY_USER)
      .then(res => {
        genTable(res.data.data)
      })
      .catch(err => console.log("err", err))
  }, [])

  React.useEffect(() => {
    axios.get(LINK_API.GET_ALL_SAMPLE_STAMP)
    .then(res => {
      const listStampRender = res.data.data.map(item => {
        return {
          label: item.name,
          value: item.uuid
        }
      })

      setListSampleStamp(listStampRender)
    })
    .catch(err => console.log("err", err))
  }, [])

  React.useEffect(() => {
    axios.get(LINK_API.GET_COOPERATIVES + "?role=M&page=1&size=100")
    .then(res => {
      const listCompanyRender = res.data.data.map(item => {
        return {
          label: item.name,
          value: item.uuid
        }
      })

      setListCompany(listCompanyRender)
    })
    .catch(err => console.log("err", err))
  }, [])

  const handleModal = () => {
    setModal(!modal)
  }

  const handleModal2 = () => {
    setModal2(!modal2)
  }

  const handleSave = () => {

    setAlert(<Loading />)
    const body = {
      userUuid: currentUuid,
      stampUuidList: listCurrentStamp.map(item => {
        return item.value
      })
    }
    axios.post(LINK_API.MAPPING_SAMPLE_STAMP_FOR_ENTERPRISE, body)
    .then(res => {
      axios.get(LINK_API.GET_SAMPLE_GROUP_BY_USER)
      .then(res => {
        genTable(res.data.data)
      })
      .catch(err => console.log("err", err))
      setAlert(<Success onConfirm={() => setAlert(null)} hideCancel={true} confirmBtnText={t('confirm')} />)
    })
    .catch(err => {})

    setModal(false)
  }

  const enterValue = () => {
    return (
      <div>
        <div className="mb-3">
          Danh sách tem mẫu được gán
            </div>
        <Select
          placeholder="Thêm mẫu tem"
          options={listSampleStamp}
          value={listCurrentStamp}
          onChange={(selectedOption) => {
            setListCurrentStamp(selectedOption)
          }}
          isMulti={true}
          theme={(theme) => ({
            ...theme,
            borderRadius: '0.29rem',
            borderWidth: 0.5,
            height: 30,
            colors: {
              ...theme.colors,
              primary25: 'rgba(60,68,177,0.15)',
              primary50: 'rgba(60,68,177,0.15)',
              primary: '#50b77a'
            }
          })}
        />

        <div className="d-flex mt-3" onClick={() => setModal(false)}>
          <Button className="mr-3">
            Huỷ
          </Button>
          <Button color="info" onClick={() => handleSave()}>
            Lưu
          </Button>
        </div>
      </div>
    )

  }

  const handleClickEditButton = (item) => {
    const ownedStamp = item.sampleList && item.sampleList.map(prop => {
      return {
        label: prop.name,
        value: prop.uuid
      }
    })

    setListCurrentStamp(ownedStamp)
    setModal(true)
  }

  const genTable = React.useCallback((listData) => {
    if (listData.length > 0) {
      let stamp = listData.map((prop, index) => {
        return {
          id: index + 1,
          name: prop.username,
          uuid: prop.userUuid,
          count: prop.count,
          ownedStamp: prop.sampleList && prop.sampleList.map(item => {
            return {
              label: item.name,
              value: item.uuid
            }
          }),
          stampList: <div>
            {
              prop.sampleList && prop.sampleList.map(item => {
                return <div>
                  {`- ${item.name}`}
                </div>
              })
            }
          </div>,
          actions: (
            <>
              <Button
                color="neutral-success"
                onClick={() => { }}
                className="mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center"
                id={"productEdit" + index}>
                <span className="btn-wrapper--icon d-flex">
                  <PenIcon
                    size={18}
                    className="text-success font-size-sm"
                    onClick={() => {
                      setCurrentUuid(prop.userUuid)
                      handleClickEditButton(prop)}
                    }
                  />
                </span>
              </Button>
              <UncontrolledTooltip trigger="hover" popperClassName="tooltip-black" placement="top" container="body" target={"productEdit" + index}>
                {t('edit')}
              </UncontrolledTooltip>
            </>
          )
        }
      })
      setStampList(stamp)
      setLoading(false)
    }

  }, [])

  const handleAdd = () => {
    setAlert(<Loading />)
    const body = {
      userUuid: currentCompany.value,
      stampUuidList: listStampAdd.map(item => {
        return item.value
      })
    }
    axios.post(LINK_API.MAPPING_SAMPLE_STAMP_FOR_ENTERPRISE, body)
    .then(res => {
      axios.get(LINK_API.GET_SAMPLE_GROUP_BY_USER)
      .then(res => {
        genTable(res.data.data)
      })
      .catch(err => console.log("err", err))
      setAlert(<Success onConfirm={() => setAlert(null)} hideCancel={true} confirmBtnText={t('confirm')} />)
    })
    .catch(err => {})
  }

  const enterValue2 = () => {
    return (
      <div>
        <div className="mb-3">
          Nhà sản xuất
            </div>
        <Select
          placeholder="Chọn nhà sản xuất"
          options={listCompany}
          value={currentCompany}
          onChange={(selectedOption) => {
            setCurrentCompany(selectedOption)
          }}
          theme={(theme) => ({
            ...theme,
            borderRadius: '0.29rem',
            borderWidth: 0.5,
            height: 30,
            colors: {
              ...theme.colors,
              primary25: 'rgba(60,68,177,0.15)',
              primary50: 'rgba(60,68,177,0.15)',
              primary: '#50b77a'
            }
          })}
        />

        <div className="mb-3 mt-3">
          Tem mẫu
        </div>
        <Select
          placeholder="Chọn tem mẫu"
          options={listSampleStamp}
          value={listStampAdd}
          onChange={(selectedOption) => {
            setListStampAdd(selectedOption)
          }}
          isMulti={true}
          theme={(theme) => ({
            ...theme,
            borderRadius: '0.29rem',
            borderWidth: 0.5,
            height: 30,
            colors: {
              ...theme.colors,
              primary25: 'rgba(60,68,177,0.15)',
              primary50: 'rgba(60,68,177,0.15)',
              primary: '#50b77a'
            }
          })}
        />

        <div className="d-flex mt-3" onClick={() => setModal2(false)}>
          <Button className="mr-3">
            Huỷ
          </Button>
          <Button color="info" onClick={() => handleAdd()}>
            Lưu
          </Button>
        </div>
      </div>
    )
  }

  return (

    <>
      <Header />
      <Container className='mt--7' fluid>
        <Card className="card-box mb-5">
          <div className="card-header py-3 d-flex justify-content-between">
            <div className=" font-weight-bold card-header--title font-size-lg">Danh sách quyền sử dụng tem mẫu</div>
            <div id="notiPlan" className="d-inline-block">
              <Button color="info" onClick={() => setModal2(true)}>
                <span className="btn-wrapper--icon">
                  <PlusCircle size={16} />
                </span>
                <span className="btn-wrapper--label">{t('product.add')}</span>
              </Button>
            </div>
          </div>
          <div className="divider" />
          <div className="card-body pt-2 px-4 pb-4">
            <ReactTable
              noDataText={t('noData')}
              data={stampList}
              columns={columns}
              previousText={"<"}
              nextText={">"}
              rowsText={t('row')}
              ofText="/"
              loading={loadingState}
              LoadingComponent={LoadingTable}
              // manual
              defaultPageSize={5}
              showPaginationBottom={true}
              sortable={false}
              resizable={true}
              PaginationComponent={Pagination}
              // pages={totalPage}
              className="-striped -highlight"
            />
          </div>
        </Card>
        {alert}
      </Container>
      <Modal zIndex={2000} centered isOpen={modal} toggle={handleModal}>
        <ModalBody>
          {enterValue()}
        </ModalBody>
      </Modal>
      <Modal zIndex={2000} centered isOpen={modal2} toggle={handleModal2}>
        <ModalBody>
          {enterValue2()}
        </ModalBody>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
    userProfile: state.userProfile.data,
    objectList: state.objectList,
    permission: state.permission.data
  }
}

const mapDispatchToProps = (dispatch) => ({
  getObjectList: (page, size) => dispatch({ type: "GET_OBJECT_LIST", page: page, size: size }),
  getObjectFilter: (page, name, value, size) => dispatch({ type: "GET_OBJECT_PRODUCTION_FILTER", page: page, name: name, value: value, size: size }),
});

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(ObjectProductionList));