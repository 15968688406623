import { IMG_LOADING_LOADING } from "constants/image";
import React from "react";
import SweetAlert from 'react-bootstrap-sweetalert';
import { withTranslation } from 'react-i18next';
import "./css/sweet.css";
function Loading(...props) {
    const handleSubmit = () => {

    }
    return (
            <SweetAlert title="" showConfirm={false} onSubmit={handleSubmit} showCancel={false} style={{ display: "block", top: props[0].top ? props[0].top : "0%" }}>
                <div className="text-center">
                    <img src={IMG_LOADING_LOADING} style={{ height: "150px" }} alt="loading" />
                    <div className="font-weight-bold font-size-lg mt-4 text-black">{props[0].t('processing')}</div>
                    <p className="mb-0 mt-2 text-muted text-black">{props[0].title ? props[0].title : "Vui lòng chờ trong giây lát ..."}</p>
                </div>
            </SweetAlert >
    )
}

export default withTranslation('common')(Loading);