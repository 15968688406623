import { 
    GET_DISTRIBUTOR_LIST,
    GET_DISTRIBUTOR_LIST_FAILURE,
    GET_DISTRIBUTOR_LIST_SUCCESS,
   } from "actions/types"
  import { takeLatest, call, put, select } from 'redux-saga/effects'
  import { LINK_API } from 'constants/API.js'
  import axios from 'axios'
  
  const getToken = (state) => state.userInfo
  
// Get distributor list
function * getDistributorList (payload) {
  console.log(payload)
    try {
      const tokenInfo = yield select(getToken)
      const response = yield call(fetchDistributor, tokenInfo.data.token, payload.name, payload.value)
      const data = response.data
      yield put({ type: GET_DISTRIBUTOR_LIST_SUCCESS, data: data.data, errorCode: data.errorCode })
    } catch (error) {
      yield put({ type: GET_DISTRIBUTOR_LIST_FAILURE, payload: '', errorCode: 0 })
    }
  }

  function fetchDistributor (checktoken, name, value) {
    axios.defaults.headers.common.Authorization = checktoken
    return axios({
      method: 'GET',
      url: name && value ? LINK_API.GET_DISTRIBUTOR_LIST: LINK_API.GET_DISTRIBUTOR_LIST
    })
  }

  const partner = [
    takeLatest(GET_DISTRIBUTOR_LIST, getDistributorList)
  ]
  export default partner
  
  