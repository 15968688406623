import Axios from 'axios';
import Error from "components/alert/error";
import Loading from "components/alert/loading";
import Success from "components/alert/success";
import Header from "components/Headers/Header.js";
import LoadingTable from "components/LoadingTable/index.js";
import Pagination from "components/Pagination/index.jsx";
import { LINK_API } from 'constants/API.js';
import { AGD_LOGO } from "constants/image";
import { useFormik } from 'formik';
import { motion } from "framer-motion";
import CheckIcon from "mdi-react/CheckIcon";
import CloseIcon from "mdi-react/CloseIcon";
import PlusCopy from "mdi-react/ContentCopyIcon";
import FilterIcon from "mdi-react/FilterIcon";
import PenIcon from "mdi-react/LeadPencilIcon";
import PlusCircle from "mdi-react/PlusCircleIcon";
import ResetIcon from "mdi-react/RestoreIcon";
import React, { useEffect } from 'react';
import cookie from "react-cookies";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ReactTable from 'react-table-6';
import Truncate from 'react-truncate';
import { Button, Card, Col, Container, CustomInput, Modal, ModalBody, ModalHeader, Input, Nav, NavItem, NavLink as NavLinkStrap, Row, UncontrolledDropdown, UncontrolledTooltip } from 'reactstrap';
import * as Yup from 'yup';
import "../../css/reactTable.css";
import moment from "moment"
import KeyIcon from "mdi-react/KeyIcon"
import { Formik } from 'formik';
import axios from "axios"

const ethers = require('ethers');

function ProductList(props) {
  const getProductList = props.getProductTypeList;
  const getProductListFilter = props.getProductTypeListFilter;
  const productList = props.productList.data
  const productListToal = props.productList;
  const totalPageProps = props.productList.totalPage
  const permission = props.permission;
  const [checkPer, setPer] = React.useState(false);
  const [modalEdit, setModalEdit] = React.useState(false)
  const [modalPass, setModalPass] = React.useState(false)
  const [stateEdit, setEdit] = React.useState('')

  React.useEffect(() => {
    if (permission) {
      if (permission.maxProductType === -1) {
        setPer(false)
      } else {
        if (permission) {
          if (permission.productType >= permission.maxProductType) {
            setPer(true)
          }
        }
      }
    }
  }, [permission, productListToal])
  const [dataProductList, setDataProductList] = React.useState([])
  const [totalPage, setTotalPage] = React.useState([])
  const [loadingState, setLoading] = React.useState(true);
  const [filterStatus, setFilter] = React.useState(false)
  const { t, history } = props;
  const [alert, setAlert] = React.useState(null)
  const [uuidAdmin, setUuid] = React.useState('')

  // const handleEdit = React.useCallback((uuid) => {
  //   cookie.save("uuidProduct", uuid, { path: "/" });
  //   history.push("/user/productEdit")
  // }, [history])


  const handleResetPass = async (data) => {
    setAlert(<Loading />)
    toggleChangePass();
    let temp = new Buffer(data.newPass).toString('hex')
    let encryptedPass = await ethers.utils.keccak256('0x' + temp)
    let newData = {
      newPassword: encryptedPass
    }
    axios.put(LINK_API.CHANGE_PASS_ADMIN_ACCOUNT + uuidAdmin, newData)
      .then(res => {
        if (res.data.errorCode === 1) {
          setAlert(<Success onClose={hideAlert} hideCancel={true} onConfirm={hideAlert} confirmBtnText={t('close')} />)

        } else {
          setAlert(<Error onClose={hideAlert} confirmBtnText="Đóng" />)
        }
      })
      .catch(err => {
        setAlert(<Error onClose={hideAlert} confirmBtnText="Đóng" />)
      })
  }

  const handleSubmitInfo = (data) => {
    toggleEdit();
    setAlert(<Loading />);
    let payload = {
      "phone": data.phone,
      "active": data.active === "false" ? false : true,
      "fullname": data.fullname
    }
    axios.put(LINK_API.EDIT_ADMIN + data.uuid, payload)
      .then(res => {
        if (res.data.errorCode === 1) {
          setAlert(<Success onClose={hideAlert} hideCancel={true} onConfirm={hideAlert} confirmBtnText={t('close')} />)

        } else {
          setAlert(<Error onClose={hideAlert} confirmBtnText="Đóng" />)
        }
      })
      .catch(err => {
        setAlert(<Error onClose={hideAlert} confirmBtnText="Đóng" />)
      })
  }

  const handleCopy = React.useCallback((uuid) => {
    cookie.save("uuidProductCopy", uuid, { path: "/" });
    if (props.userInfo.data.role === 'Z') {
      history.push("/admin/createGuard")
    } else {
      history.push("/sadmin/createGuard")
    }
  }, [history])
  const hideAlert = () => {
    setAlert(null);
    getProductList(1, 5);
  }
  const handleChangeStatus = (uuid, status) => {
    setAlert(<Loading />);
    let data = {
      uuid: uuid,
      status: status === 1 ? 0 : 1
    }
    Axios.post(LINK_API.UPDATE_PRODUCT, data)
      .then(res => {
        if (res.data.errorCode === 1) {
          setAlert(<Success hideCancel={true} onConfirm={hideAlert} confirmBtnText={t('confirm')} />)
        } else {
          setAlert(<Error onClose={hideAlert} />)
        }
      })
      .catch(err => {
        setAlert(<Error onClose={hideAlert} />)
      })
  }

  const toggleChangePass = (uuid) => {
    if (uuid) {
      setUuid(uuid)
    }
    setModalPass(!modalPass)
  }
  const handleEdit = (data) => {
    setEdit(data);
    toggleEdit();
  }
  const toggleEdit = () => {
    setModalEdit(!modalEdit)
  }

  const genTable = React.useCallback((productAreaList) => {
    let data = productAreaList.map((prop, index) => {
      return {
        username: prop.username,
        name: prop.fullname,
        time: moment(prop.createdAt).format("DD/MM/YYYY"),
        active: prop.active ? 'Đang hoạt động' : "Đã khóa",
        action: (
          <>
            <Button
              color="neutral-success"
              onClick={() => handleEdit(prop)}
              className="mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center"
              id={"productEdit" + index}>
              <span className="btn-wrapper--icon d-flex">
                <PenIcon
                  size={18}
                  className="text-success font-size-sm"
                  onClick={() => handleEdit(prop)}
                />
              </span>
            </Button>
            <UncontrolledTooltip trigger="hover" popperClassName="tooltip-black" placement="top" container="body" target={"productEdit" + index}>
              {t('edit')}
            </UncontrolledTooltip>
            <Button
              color="neutral-success"
              onClick={() => toggleChangePass(prop.uuid)}
              className="mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center"
              id={"pass" + index}>
              <span className="btn-wrapper--icon d-flex">
                <KeyIcon
                  size={18}
                  className="text-warning font-size-sm"
                  onClick={() => toggleChangePass(prop.uuid)}
                />
              </span>
            </Button>
            <UncontrolledTooltip trigger="hover" popperClassName="tooltip-black" placement="top" container="body" target={"pass" + index}>
              Cập nhật mật khẩu tài khoản
            </UncontrolledTooltip>
          </>)
       
        //  ...prop
      }
    })
    setDataProductList(data)
    setLoading(false)
  }, [])

  const formik = useFormik({
    initialValues: {
      name: 'name',
      value: '',
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required(t('require')),
      value: Yup.string()
        .required(t('require')),
    }),
    onSubmit: React.useCallback(async (values) => {
      setFilter(true);
      setLoading(true);
      getProductListFilter(1, values.name, values.value, 5)
    }, []),
  });
  useEffect(() => {
    getProductList(1, 5)
    // }, [getProductList]);
    if (!props.userInfo || props.userInfo.data === "") {
      props.history.push('/login')
    }
    else {
      getProductList(1, 5)
    }
  }, [getProductList]);


  useEffect(() => {
    if (productList && productList.length > 0) {
      genTable(productList)
      setTotalPage(totalPageProps)
    } else {
      genTable([])
      setTotalPage(0)
    }
    if (!props.userInfo || props.userInfo.data === "") {
      props.history.push('/login')
    }
    else if (productList && productList.length > 0) {
      genTable(productList)
      setTotalPage(totalPageProps)
    } else {
      genTable([])
      setTotalPage(0)
    }

  }, [totalPageProps, productList]);


  const columns = React.useMemo(
    () => [
      // {
      //   Header: <div style={{
      //     textAlign: "left"
      //   }}>
      //     Mã bảo vệ
      //   </div>,
      //   minWidth: 107,
      //   accessor: "uuid",
      //   filterable: false
      // },
      {
        Header: 'Tên',
        accessor: "name",
        filterable: false,
        resizable: true,
      },
      {
        Header: 'Tên đăng nhập',
        accessor: "username",
        filterable: false,
        resizable: true,
      },
      {
        Header: 'Trạng thái',
        accessor: "active",
        filterable: false,
        resizable: true,
      },
      {
        Header: 'Hành động',
        accessor: "action",
        filterable: false
      },
    ],
    [t]
  );


const handleChangePage = () => {
  if(props.userInfo.data.role === 'S'){
    props.history.push('/sadmin/createGuard')
  }else{
    props.history.push('/admin/createGuard')
  }
}

  return (

    <>
      <motion.div
        className="w-100"
        initial={{ x: -100 }}
        animate={{ x: 0 }}
        transition={{ ease: "easeOut", duration: 0.3 }}
      >
        <Header/>
        <Container className="mt--7" fluid>
        <Card className="card-box mb-5">
          <div className="card-header py-3 d-flex justify-content-between">
            <div className="font-weight-bold card-header--title font-size-lg">Danh sách bảo vệ</div>
            <div className="card-header--actions d-flex flex-row">
              {/* <UncontrolledDropdown>
                <DropdownToggle id="filterButton" color="neutral-success" className="mx-2">
                  <FilterIcon size={20} /> {t('filter.filter')}
                </DropdownToggle>
                <DropdownMenu right className="dropdown-menu-xxl p-0">
                  <form onSubmit={formik.handleSubmit}>
                    <div className="p-3">
                      <Row>
                        <Col md="6">
                          <small className="font-weight-bold pb-2 text-uppercase text-primary d-block">
                            {t('filter.name')}
                          </small>
                          <CustomInput
                            type="select"
                            placeholder={t('filter.name')}
                            value={formik.values.name}
                            valid={formik.touched.name && formik.errors.name ? false : (formik.values.name ? true : false)}
                            invalid={formik.touched.name && formik.errors.name ? true : false}
                            {...formik.getFieldProps('name')}
                          >
                            <option value="name">Tên bảo vệ</option>
                            <option value="uuid">Mã bảo vệ</option>
                          </CustomInput>
                        </Col>
                        <Col md="6">
                          <small className="font-weight-bold pb-2 text-uppercase text-primary d-block">
                            {t('filter.value')}
                          </small>
                          {formik.values.name !== "status" ?
                            <Input
                              placeholder={t('filter.value')}
                              value={formik.values.value}
                              valid={formik.touched.value && formik.errors.value ? false : (formik.values.value ? true : false)}
                              invalid={formik.touched.value && formik.errors.value ? true : false}
                              {...formik.getFieldProps('value')}
                            >
                            </Input> : <CustomInput
                              type="select"
                              placeholder={t('filter.value')}
                              value={formik.values.value}
                              valid={formik.touched.value && formik.errors.value ? false : (formik.values.value || formik.values.value === 0 ? true : false)}
                              invalid={formik.touched.value && formik.errors.value ? true : false}
                              {...formik.getFieldProps('value')}
                            >
                              <option value={1}>{t('product.inProduction')}</option>
                              <option value={0}>{t('product.stopProduction')}</option>
                            </CustomInput>}
                        </Col>
                      </Row>

                    </div>
                    <div className="divider" />
                    <div className="p-3 text-center bg-secondary">
                      <Button color="primary" type="submit">
                        {t('filter.search')}
                      </Button>
                    </div>
                  </form>
                  <div className="divider" />
                  <div className="p-3">
                    <Row>
                      <Col md="6">
                        <Nav className="nav-neutral-danger flex-column p-0">
                          <NavItem>
                            <NavLinkStrap className="d-flex rounded-sm justify-content-center" onClick={handleClearFilter}>
                              <div className="nav-link-icon">
                                <CloseIcon />
                              </div>
                              <span>{t('filter.clear')}</span>
                            </NavLinkStrap>
                          </NavItem>
                        </Nav>
                      </Col>
                      <Col md="6">
                        <Nav className="nav-neutral-info flex-column p-0">
                          <NavItem>
                            <NavLinkStrap className="d-flex rounded-sm justify-content-center" onClick={handleResetData}>
                              <div className="nav-link-icon">
                                <ResetIcon />
                              </div>
                              <span>{t('filter.reset')}</span>
                            </NavLinkStrap>
                          </NavItem>
                        </Nav>
                      </Col>
                    </Row>
                  </div>
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledTooltip trigger="hover" popperClassName="tooltip-black" placement="top" container="body" target={"filterButton"}>
                {t('filter.filter')}
              </UncontrolledTooltip> */}
              <div style={{ display: "inline-block" }} id="notiPlan">
                <Button disabled={checkPer} color="info" onClick={() => handleChangePage()} >
                  <span className="btn-wrapper--icon">
                    <PlusCircle size={16} />
                  </span>
                  <span className="btn-wrapper--label">{t('product.add')}</span>
                </Button>
              </div>
              {checkPer ?
                <UncontrolledTooltip trigger="hover" popperClassName="tooltip-warning" placement="top" container="body" target={"notiPlan"}>
                  {t('notiPlan')}
                </UncontrolledTooltip> : null}
            </div>
          </div>

          <div className="divider" />
          <div className="card-body pt-2 px-4 pb-4">

            <ReactTable
              noDataText={t('noData')}
              data={dataProductList}
              columns={columns}
              previousText={"<"}
              nextText={">"}
              rowsText={t('row')}
              ofText="/"
              loading={loadingState}
              LoadingComponent={LoadingTable}
              //manual
              defaultPageSize={5}
              showPaginationBottom={true}
              sortable={false}
              resizable={true}
              PaginationComponent={Pagination}
              pages={totalPage}
              className="-striped -highlight"
              onFetchData={async (state, instance) => {
                setLoading(true)
                let x = state.page + 1;
                if (filterStatus === false) {
                  getProductList(x, state.pageSize)
                } else {
                  getProductListFilter(x, formik.values.name, formik.values.value, state.pageSize)
                }
              }}
            />
          </div>
        </Card>
        </Container>
      </motion.div>
      {alert}
      <Modal isOpen={modalEdit} toggle={toggleEdit}>
          <ModalHeader toggle={toggleEdit}>
            <p className='font-size-lg mb-0'>Chỉnh sửa thông tin</p>
          </ModalHeader>
          <ModalBody>
            <Formik
              initialValues={{ ...stateEdit }}
              onSubmit={(values, actions) => {
                handleSubmitInfo(values);
              }}
            >
              {props => (
                <form onSubmit={props.handleSubmit}>
                  <label className='font-weight-bold'>Họ và tên</label>
                  <Input
                    type="text"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.fullname}
                    name="fullname"
                    placeholder='Họ và tên'
                  />
                  {/* <label className="font-weight-bold">Vai trò <span className="text-danger"></span></label>
                  <CustomInput type="select" id="selectUnit" placeholder='Vai trò'
                    value={props.values.role}
                    name="role"
                    onChange={props.handleChange}
                  >
                    <option value={'U'}>QTV cổng truy xuất nguồn gốc</option>
                    <option value={'Z'}>QTV chợ đầu mới Hòa Cường</option>
                  </CustomInput> */}
                  <label className='font-weight-bold'>Trạng thái</label>

                  <CustomInput type="select" id="selectUnit" placeholder='Trạng thái tài khoản'
                    value={props.values.active}
                    onChange={props.handleChange}
                    name="active"
                  >
                    <option value={true}>Đang hoạt động</option>
                    <option value={false}>Đã khóa</option>
                  </CustomInput>
                  <div className='mt-3 d-flex justify-content-between'>
                    <Button color="danger" outline onClick={toggleEdit}>Hủy</Button>
                    <Button type='submit' color="success">Xác nhận</Button>{' '}
                  </div>

                </form>
              )}
            </Formik>
          </ModalBody>
        </Modal>

        <Modal isOpen={modalPass} toggle={toggleChangePass}>
          <ModalHeader toggle={toggleChangePass}>
            <p className='font-size-lg mb-0'>Thay đổi mật khẩu</p>
          </ModalHeader>
          <ModalBody>
            <Formik
              initialValues={{ newPass: '', rePass: '' }}
              onSubmit={(values, actions) => {
                handleResetPass(values);
              }}
              validationSchema={
                Yup.object().shape({
                  newPass: Yup.string().required('Thông tin bắt buộc'),
                  rePass: Yup.string()
                    .oneOf([Yup.ref('newPass'), null], 'Mật khẩu vừa nhập không trùng khớp')
                })
              }
            >
              {props => (
                <form onSubmit={props.handleSubmit}>
                  <label className='font-weight-bold'>Mật khẩu mới</label>
                  <Input
                    type="password"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.newPass}
                    name="newPass"
                    placeholder='Mật khẩu mới'
                  />
                  {props.errors.newPass && <p id="feedback text-danger">{props.errors.newPass}</p>}

                  <label className='font-weight-bold'>Nhập lại mật khẩu</label>
                  <Input
                    type="password"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.rePass}
                    name="rePass"
                    placeholder='Nhập lại mật khẩu'
                  />
                  {props.errors.rePass && <p id="feedback text-danger">{props.errors.rePass}</p>}
                  <div className='mt-3 d-flex justify-content-between'>
                    <Button color="danger" outline onClick={toggleChangePass}>Hủy</Button>
                    <Button type='submit' color="success">Xác nhận</Button>{' '}
                  </div>
                </form>
              )}
            </Formik>
          </ModalBody>
        </Modal>

    </>
  );
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
    userProfile: state.userProfile.data,
    productList: state.guardList,
    permission: state.permission.data
  }
}

const mapDispatchToProps = (dispatch) => ({
  getProductTypeList: (page, size) => dispatch({ type: "GET_GUARD_LIST", page: page, size: size }),
  getProductTypeListFilter: (page, name, value, size) => dispatch({ type: "GET_OBJECT_LIST_FILTER", page: page, name: name, value: value, size: size }),
});

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(ProductList));