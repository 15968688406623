import clsx from "clsx";
import PlusCircle from "mdi-react/PlusCircleIcon";
import React from "react";
import { withTranslation } from "react-i18next";
import Loki from "./loki/index";
import { connect } from "react-redux";
import { Button, UncontrolledTooltip } from "reactstrap";
import StepBasic from "./steps/stepBasic";
import StepHarvest from "./steps/stepHarvest";
import StepNew from "./steps/stepNew";
import CloseIcon from "mdi-react/CloseIcon"
import Header from "components/Headers/Header"
import { Card, Container } from "reactstrap"
import "./index.css"
function CreateProcess(props) {
    const t = props.t;
    const permission = props.permission.data;

    const [state, setState] = React.useState([]);
    const [countStep, setCount] = React.useState(1);
    const _mergeValues = async (data) => {
        console.log('data', data)
        let temp = state;
        if (data.process_name) {
            temp[0] = data;
            setState(temp);
        } else {
            temp[data.index] = data;
            if (data.index !== 1) {
                await setState(temp);
                renderNameStep(data.index, temp)
            } else {
                await setState(temp);
            }
        }
    }
    const defaultSteps = [
        {
            label: t('process.basicInfo'),
            number: 'TT',
            index: 0,
            component: <StepBasic data={state} merge={_mergeValues} />,
        },
        {
            label: "Công đoạn mới",
            number: '1',
            index: 1,
            component: <StepNew data={state} index={1} merge={_mergeValues} />,
        },
        {
            label: t('process.harvest'),
            number: 'TH',
            index: 0,
            component: <StepHarvest dataHarvest={state} index={1} merge={_mergeValues} />,
        }

    ];
    const [listSteps, setSteps] = React.useState(defaultSteps);
    const handleAddNewStep = async () => {
        let nextStep = countStep + 1;
        setCount(countStep + 1)

        let temp = {
            label: 'Công đoạn ' + (nextStep),
            number: nextStep,
            index: nextStep,
            component: <StepNew data={state} index={nextStep} merge={_mergeValues} />,
        }
        let slice_state = listSteps.length - 1;
        let tempList = listSteps;
        await tempList.splice(slice_state, 0, temp);
        let step_new = await tempList.map((item, idx) => {
            if (idx > 0 && idx < tempList.length - 1) {
                let tempx = {
                    label: idx === tempList.length - 2 ? 'Công đoạn mới' : item.label ? item.label : "Công đoạn " + idx,
                    number: idx,
                    index: item.index,
                    component: item.component,
                }
                return tempx;
            } else {
                return item
            }
        })
        setSteps([...step_new])
    }
    const renderNameStep = async (index, data) => {
        // const newLabel = await listSteps.map((item, idx) => {
        //     if (index === (item.index + 1)) {
        //         item.label = data[index].name;
        //         return item;
        //     } else {
        //         return item
        //     }
        // })
        // setSteps([...newLabel])
    }
    const handleRemoveStep = async (index) => {

        let temp = await listSteps.filter((item, idx) => idx !== index);
        let step_new = await temp.map((item, idx) => {
            if (idx > 0 && idx < temp.length - 2) {
                let tempx = {
                    label: item.label ? item.label : 'Công đoạn ' + idx,
                    number: idx,
                    index: item.index,
                    component: item.component,
                }
                return tempx;
            } else {
                return item
            }
        })

        if (state[index + 1]) {
            let tempRemove = await state.filter((item, idxx) => idxx !== index + 1);
            await setState(tempRemove)
        }
        setSteps([...step_new])
    }
    const _customRenderer = ({ currentStep }) => {
        const steps = listSteps.map((step, index) => {
            const isActive = currentStep === index + 1;
            return <li key={index} className={clsx("card-box", { 'current': isActive })}>
                <a href="#/" onClick={e => e.preventDefault()}>
                    <div className="label d-flex justify-content-start align-items-center flex-row">
                        <p className="mb-0">{step.label}</p>
                        {index > 0 && index < listSteps.length - 1 && listSteps.length > 3 ?
                            <>
                                <Button
                                    color="neutral-danger"
                                    onClick={() => handleRemoveStep(index)}
                                    className="mx-1 rounded-sm shadow-none hover-scale-sm d-30 ml-2 border-0 p-0 d-inline-flex align-items-center justify-content-center"
                                    id={"deleteStep" + index}>
                                    <span className="btn-wrapper--icon d-flex">
                                        <CloseIcon
                                            size={18}
                                            className="text-danger font-size-sm"
                                        />
                                    </span>
                                </Button>
                                <UncontrolledTooltip trigger="hover" popperClassName="tooltip-black" placement="top" container="body" target={"deleteStep" + index}>
                                    {t('process.remove')}
                                </UncontrolledTooltip>
                            </>
                            : null}
                    </div>
                    <div className="step-indicator">{step.number}</div>
                </a>
            </li>
        });

        return <div className="vertical"><ul className="steps-indicator">{steps}</ul></div>;
    };

    const _onFinish = async () => {
        let dataBasic = state[0];
        let dataHarvest = state[1];
        let stepsList = [];
        for (let i = 2; i < state.length; i++) {
            stepsList.push(state[i]);
        }
        await stepsList.push(dataHarvest);
        let data = {
            ...dataBasic,
            step: stepsList
        }
        props.createProcess(data);
        props.history.push('/user/process')
    };

    return (
        <>
            <Header></Header>
            <Container className='mt--7' fluid>
                <Card className="card-box mb-5">
                    {
                        permission.agdqr ? <div> </div> :
                            <div className="card card-box">
                                <div className="card-header d-flex justify-content-between">
                                    <div className="card-header--title d-flex flex-column">
                                        <small>{t('process.link')}</small>
                                        <b>{t('process.add')}</b>
                                    </div>
                                    <div className="card-header--actions">
                                        <Button color="info" onClick={handleAddNewStep}>
                                            <span className="btn-wrapper--icon">
                                                <PlusCircle size={16} />
                                            </span>
                                            <span className="btn-wrapper--label">{t('process.addStep')}</span>
                                        </Button>
                                    </div>
                                </div>
                                <div className="wizard vertical">
                                    <Loki
                                        steps={listSteps}
                                        renderSteps={_customRenderer}
                                        onNext={_mergeValues}
                                        onFinish={_onFinish}
                                        noActions />
                                </div>
                            </div>
                    }
                </Card>
            </Container>
        </>
    );
}

const mapStateToProps = (state) => ({
    permission: state.permission
})

const mapDispatchToProps = (dispatch) => ({
    createProcess: (data) => dispatch({ type: "CREATE_PROCESS", data: data }),
});
export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(CreateProcess));
