import LoadingTable from "components/LoadingTable/index.js";
import Pagination from "components/Pagination/index.jsx";
import { AGD_LOGO } from "constants/image";
import PlusCircle from "mdi-react/PlusCircleIcon";
import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ReactTable from 'react-table-6';
import Truncate from 'react-truncate';
import { Button, Card, Col, Container, Row } from 'reactstrap';
import "../../css/reactTable.css";
import Header from "components/Headers/Header"
function BranchList(props) {
  const getBranchList = props.getBranchList;
  const [dataBranchList, setDataBranchList] = React.useState([])
  const [loadingState, setLoading] = React.useState(true)
  const { t } = props;

  let linkUrl = "";
  if(props.userInfo && props.userInfo.data && props.userInfo.data.type === "F"){
    linkUrl = "distributor/"
  }

  const isEmptyObject = (obj) => {
    if (!obj) return true;
    if (obj.length === 0) return true;
    return Object.entries(obj).length === 0 && obj.constructor === Object
  }

  const genTable = React.useCallback((branchList) => {
    let data = branchList.map((prop, index) => {
      return {
        id: prop.uuid,
        name:
          <div style={{ display: "flex", flexFlow: "row", alignItems: "center" }}>
            <Container>
              <Row>
                <Col xs={12} sm={12} md={5} style={{ textAlign: "start" }}>
                  {!isEmptyObject(prop.image) ? (
                    <img alt="profileAGD" src={prop.image.secure_url} style={{ height: "37px", width: "37px" }} />
                  ) :
                    <img alt="profileAGD" src={AGD_LOGO} style={{ height: "37px", width: "37px" }} />}
                </Col >
                <Col xs={12} sm={12} md={7} style={{ display: "flex",  textAlign: "left", paddingLeft: "0px !important" }}>
                  <Truncate lines={2} ellipsis={<span>...</span>}>
                    {prop.name}
                  </Truncate>
                </Col>
              </Row>
            </Container>
          </div>,
        address: prop.address,
        phone: prop.phone
      }
    })
    setDataBranchList(data)
    setLoading(false)
  }, [])

  useEffect(() => {
    getBranchList()
  }, [getBranchList]);

  const branchListData = props.branchListData.data ? props.branchListData.data.reverse() : []
  useEffect(() => {
    if (branchListData && branchListData.length > 0) {
      genTable(branchListData)
    } else {
      genTable([])
    }

  }, [branchListData]);


  const columns = React.useMemo(
    () => [
      {
        accessor: "id",
        filterable: false,
        className: "center",
        maxWidth: 100,
        Header: () => (
          <div style={{
            textAlign: "center",
          }}>
            #
          </div>
        )
      },
      {
        Header: t('branch.name'),
        accessor: "name",
        sortable: false
      },
      {
        Header: t('branch.address'),
        accessor: "address",
        filterable: false
      },
      {
        Header: t('branch.phone'),
        accessor: "phone",
        filterable: false
      }
    ],
    [t]
  );



  return (

    <>
    <Header/>
    <Container className='mt--7' fluid>
      <Card className="card-box mb-5">
        <div className="card-header py-3 d-flex justify-content-between">
          <div className=" font-weight-bold card-header--title font-size-lg">{t('branch.list')}</div>
          <div className="card-header--actions">
            <Button onClick={() => props.history.push(`/${linkUrl}addBranch`)} color="info">
              <span className="btn-wrapper--icon">
                <PlusCircle size={16} />
              </span>
              <span className="btn-wrapper--label">{t('product.add')}</span>
            </Button>
          </div>
        </div>
        <div className="divider" />
        <div className="card-body pt-2 px-4 pb-4">
          <ReactTable
            noDataText={t('noData')}
            data={dataBranchList}
            columns={columns}
            previousText={"<"}
            nextText={">"}
            rowsText={t('row')}
            ofText="/"
            defaultPageSize={5}
            showPaginationBottom={true}
            sortable={false}
            resizable={true}
            PaginationComponent={Pagination}
            className="-striped -highlight"
            loading={loadingState}
            LoadingComponent={LoadingTable}
          // onFetchData={async (state, instance) => {
          //   setLoading(true)
          //   let x = state.page + 1;
          //   getPartnerList(x, state.pageSize)
          // }}
          />
        </div>
      </Card>
      </Container>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
    branchListData: state.branchList
  }
}

const mapDispatchToProps = (dispatch) => ({
  getBranchList: () => dispatch({ type: "GET_BRANCH_LIST" })
});

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(BranchList));