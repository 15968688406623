const initialState = { data: '', errorCode: '' }
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_PLAN_REGISTER':
      return {
        ...state
      }
    case 'GET_PLAN_REGISTER_SUCCESS':
      return {
        ...state,
        data: action.data,
        errorCode: action.errorCode
      }
    case 'GET_PLAN_REGISTER_FAILURE':
      return {
        ...state
      }
    default:
      return state
  }
}
export default reducer
