import { 
  GET_HISTORY_STAMP,
  GET_HISTORY_STAMP_FAILURE,
  GET_HISTORY_STAMP_SUCCESS,
} from "actions/types"
import { takeLatest, call, put, select } from 'redux-saga/effects'
import { LINK_API } from 'constants/API.js'
import axios from 'axios'

const getToken = (state) => state.userInfo

// Get approve list
function * getHistoryStampList(data) {
  
    const page = data.page;
    const size = data.size;

    try {
      const tokenInfo = yield select(getToken)
      const response = yield call(fetchHistoryStampList, tokenInfo.data.token, page, size)
      const data = response.data
      yield put({ type: GET_HISTORY_STAMP_SUCCESS, data: data.data, errorCode: data.errorCode, totalPage: data.totalPage })
    } catch (error) {
      yield put({ type: GET_HISTORY_STAMP_FAILURE, payload: '', errorCode: 0, totalPage: 0 })
    }
  }
  function fetchHistoryStampList (checktoken, page, size) {
    axios.defaults.headers.common.Authorization = checktoken
    return axios({
      method: 'GET',
      url: LINK_API.GET_HISTORY_STAMP_AUTHORIZE.concat('?page=' , page , '&size=' , size )
    })
  }
  const actions = [
    takeLatest(GET_HISTORY_STAMP, getHistoryStampList)
  ]
  export default actions
  
  