import { 
  GET_PRODUCT_LIST,
  GET_PRODUCT_LIST_FAILURE,
  GET_PRODUCT_LIST_SUCCESS,
  CREATE_PRODUCT,
  PUSH_NOTI_PRODUCT_SUCCESS, 
  DEACTIVE_NOTI_PRODUCT,
  RENDER_PRODUCT_LIST_SUCCESS,
  RENDER_PRODUCT_LIST_FAILURE,
  RENDER_PRODUCT_LIST,
  SAVE_DATA_EDIT_PRODUCT_SUCCESS,
  SAVE_DATA_EDIT_PRODUCT,
  UPDATE_INFO_PRODUCT,
  SAVE_NOTIFICATION,
  GET_PRODUCT_LIST_FILTER
  
 } from "actions/types"
import { takeLatest, call, put, select, delay } from 'redux-saga/effects'
import { LINK_API } from 'constants/API.js'
import axios from 'axios'
import responseCode from "constants/errorCode.js"

const CryptoJS = require('crypto-js')
const EthereumTx = require('ethereumjs-tx');

const getToken = (state) => state.userInfo
const getFullProductType = (state) => state.productType.fullData
const getToTalItemProductType = (state) => state.productType.totalItem

// const getSaveSetting = (state) => state.settingList.data

function* watchUpdateEmployee(data) {
  yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Đang xử lý", color: 'warning' })
  yield delay(1000)
  yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
  try {
    const tokenInfo = yield select(getToken)
    const { response } = yield call(unpdateInfoProduct, data.data, tokenInfo.data.token)
    const result = response.data
    if (result.errorCode === 1) {
      yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Cập nhật thông tin nhóm sản phẩm thành công", color: 'success' })
      yield put({ type: GET_PRODUCT_LIST, page: 1, size: 5 })
      yield delay(2000)
      yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
    } else {
      yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Cập nhật thông tin nhóm sản phẩm thất bại", color: 'error' })
      yield put({ type: GET_PRODUCT_LIST, page: 1, size: 5 })
      yield delay(2000)
      yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
    }
  } catch (error) {
    yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Cập nhật thông tin nhóm sản phẩm thất bại", color: 'error' })
    yield put({ type: GET_PRODUCT_LIST, page: 1, size: 5 })
    yield delay(2000)
    yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
  }
}

function unpdateInfoProduct (data, checktoken) {
  axios.defaults.headers.common.Authorization = checktoken
  return axios.post(LINK_API.UPDATE_PRODUCT, data)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
//update Info Product

//save data product
function *saveDataProduct(data){
  yield put({type: SAVE_DATA_EDIT_PRODUCT_SUCCESS, dataOne: data.dataOne, dataTwo: data.dataTwo, check: data.check})
}

//render productList
function * watchRenderProduct (data){
  const token = yield select(getToken)
  const response = yield call(fetchProduct, 1, 100,  token.data.token, data.ownedBy)
  if(response){
    const result = response.data;
    if(result.errorCode === 1){
      let list = result.data;
      let list_product = [];
      for(let i = 0; i < list.length; i++){
        if(list[i].status === 1){
          list_product.push({
            name: list[i].name + " - " + list[i].uuid,
            value: list[i].uuid
          })
        } 
      }
      yield put({
        type: RENDER_PRODUCT_LIST_SUCCESS,
        data: list_product,
        errorCode: result.errorCode
      })
    }else{
      yield put({
        type: RENDER_PRODUCT_LIST_FAILURE,
        data: [],
        errorCode: result.errorCode
      })
    }
  }
}
// Create Product
function * watchCreateProduct (data) {
  // let image_product = data.data.image;
  // let certificates = data.data.certificates;
  let dataCreate = {
    ...data.data,
    image: [],
    certificates:[]
  }
 yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Đang xử lý", color: 'warning' })
 yield delay(2000)
 yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
  const token = yield select(getToken)
  const { response, error } = yield call(createProduct, dataCreate, token.data.token)
  if (response) {
    const result = response.data
    if (result.errorCode === 1) {

      const dataraw = data.data
      var bytes = CryptoJS.AES.decrypt(token.data.privateEncrypted.toString(), token.password)
      const decrypt_privatekey = bytes.toString(CryptoJS.enc.Utf8)
      const privatekey_slice = decrypt_privatekey.substring(2, decrypt_privatekey.length)
      const Buffer_privatekey = Buffer.from(privatekey_slice.toString(), 'hex')
      const tx = new EthereumTx(result.data.raw, { chain: 4 })
      tx.sign(Buffer_privatekey)
      const rawTx = '0x' + tx.serialize().toString('hex')
      dataraw.raw = rawTx;
      // data.image = image_product;
      // data.certificates = certificates;
      dataraw.uuid = result.data.uuid;
      console.log('dataraw', dataraw)
      const {responseRaw, errorRaw} = yield call(sendRawCreate, dataraw, token.data.token)
      if(responseRaw){
        if(responseRaw.data.errorCode === 1){
          yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Tạo sản phẩm thành công", color: 'success' })
          yield put({ type: SAVE_NOTIFICATION, data: { type: 'success', message: "Tạo sản phẩm thành công" } })
           yield put({ type: GET_PRODUCT_LIST, page: 1, size: 5 })
           yield delay(3000)
           yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
        }else{
          if (responseCode[`${responseRaw.data.name}`].vi) {
            yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: responseCode[`${responseRaw.data.name}`].vi, color: 'error' })
           yield put({ type: SAVE_NOTIFICATION, data: { type: 'error', message: responseCode[`${result.name}`].vi } })
            yield delay(3000)
            yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
          } else {
            yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Tạo sản phẩm không thành công", color: 'error' })
           yield put({ type: SAVE_NOTIFICATION, data: { type: 'error', message: "Tạo sản phẩm không thành công" } })
            yield delay(3000)
            yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
         }
        }
      }else{
        if (errorRaw.response.data.name) {
          yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: responseCode[`${errorRaw.response.data.name}`].vi, color: 'error' })
          yield put({ type: SAVE_NOTIFICATION, data: { type: 'error', message: responseCode[`${error.response.data.name}`].vi } })
          yield delay(3000)
          yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
        } else {
          yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Tạo sản phẩm không thành công", color: 'error' })
          yield put({ type: SAVE_NOTIFICATION, data: { type: 'error', message: "Tạo sản phẩm không thành công" } })
          yield delay(3000)
          yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
        }
      }
    } else {
      if (responseCode[`${result.name}`].vi) {
        yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: responseCode[`${result.name}`].vi, color: 'error' })
       yield put({ type: SAVE_NOTIFICATION, data: { type: 'error', message: responseCode[`${result.name}`].vi } })
        yield delay(3000)
        yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
      } else {
        yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Tạo sản phẩm không thành công", color: 'error' })
       yield put({ type: SAVE_NOTIFICATION, data: { type: 'error', message: "Tạo sản phẩm không thành công" } })
        yield delay(3000)
        yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
     }
    }
  } else {
    if (error.response.data.name) {
      yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: responseCode[`${error.response.data.name}`].vi, color: 'error' })
      yield put({ type: SAVE_NOTIFICATION, data: { type: 'error', message: responseCode[`${error.response.data.name}`].vi } })
      yield delay(3000)
      yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
    } else {
      yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Tạo sản phẩm không thành công", color: 'error' })
      yield put({ type: SAVE_NOTIFICATION, data: { type: 'error', message: "Tạo sản phẩm không thành công" } })
      yield delay(3000)
      yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
    }
  }
}
function createProduct (data, checktoken) {
  axios.defaults.headers.common.Authorization = checktoken
  return axios.post(LINK_API.CREATE_PRODUCT, data)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
function sendRawCreate (data, checktoken) {
  axios.defaults.headers.common.Authorization = checktoken
  return axios.post(LINK_API.SEND_RAW_PRODUCT, data)
    .then(responseRaw => ({ responseRaw }))
    .catch(errorRaw => ({ errorRaw }))
}
//GET LIST
async function fetchProduct (page, size, checktoken, ownedBy) {
  let url = ""
  if(ownedBy){
    url = LINK_API.ADMIN_GET_ALL_PRODUCTTYPE.concat('?page=', page , '&size=' , size, '&ownedBy=', ownedBy)
  }
  else{
    url = LINK_API.CREATE_PRODUCT.concat('?page=', page , '&size=' , size)
  }
  axios.defaults.headers.common.Authorization = await checktoken
  return axios({
    method: 'GET',
    url: url
  })
}

function * getProductTypeList (page) {
  try {
    const tokenInfo = yield select(getToken)
    let fullData = yield select(getFullProductType)
    const totalItem = yield select(getToTalItemProductType)
   // const setting_data = yield select(getSaveSetting)
   // const size = setting_data.rowOfPage ? setting_data.rowOfPage : 5
   const size = page.size;
    if (fullData[`${page.page}`]) {
      yield put({
        type: GET_PRODUCT_LIST_SUCCESS,
        data: fullData[`${page.page}`],
        fullData: fullData
      })
    }
    const response = yield call(fetchProduct, page.page, size, tokenInfo.data.token)
    const data = response.data.data
    if (totalItem !== response.data.totalItem) {
      const newList = data
      fullData[`${page.page}`] = newList
      yield put({
        type: GET_PRODUCT_LIST_SUCCESS,
        data: newList,
        errorCode: response.data.errorCode,
        totalItem: response.data.totalItem,
        totalPage: response.data.totalPage,
        fullData: fullData
      })
    } else {
      if (!fullData[`${page.page}`]) {
        const newList = data
        fullData[`${page.page}`] = newList
        yield put({
          type: GET_PRODUCT_LIST_SUCCESS,
          data: newList,
          errorCode: response.data.errorCode,
          totalItem: response.data.totalItem,
          totalPage: response.data.totalPage,
          fullData: fullData
        })
      }else{
        if(fullData[`${page.page}`] !== data){
          const newList = data
        fullData[`${page.page}`] = newList
        yield put({
          type: GET_PRODUCT_LIST_SUCCESS,
          data: newList,
          errorCode: response.data.errorCode,
          totalItem: response.data.totalItem,
          totalPage: response.data.totalPage,
          fullData: fullData
        })
        }
      }
    }
  } catch (error) {
    if(error.response.status === 401){
      yield put({ type: "CLEAN_STORE", payload: null, errorCode: 0 })
    }
    yield put({ type: GET_PRODUCT_LIST_FAILURE, payload: '', errorCode: 0 })
  }
}
// Get product list filter
function * getProductTypeListFilter (page) {
  try {
    const tokenInfo = yield select(getToken)
    const fullData = yield select(getFullProductType)
    const totalItem = yield select(getToTalItemProductType)
    //const setting_data = yield select(getSaveSetting)
    const size = page.size;

    if (fullData[`${page.page}`]) {
      yield put({
        type: GET_PRODUCT_LIST_SUCCESS,
        data: fullData[`${page.page}`],
        fullData: fullData
      })
    }
    const response = yield call(fetchProductFilter, page.page, page.name, page.value, tokenInfo.data.token, size)
    const data = response.data.data
    if (totalItem !== response.data.totalItem) {
      const newList = data
      fullData[`${page.page}`] = newList
      yield put({
        type: GET_PRODUCT_LIST_SUCCESS,
        data: newList,
        errorCode: response.data.errorCode,
        totalItem: response.data.totalItem,
        totalPage: response.data.totalPage,
        fullData: fullData
      })
    } else {
      if (!fullData[`${page.page}`]) {
        const newList = data
        fullData[`${page.page}`] = newList
        yield put({
          type: GET_PRODUCT_LIST_SUCCESS,
          data: newList,
          errorCode: response.data.errorCode,
          totalItem: response.data.totalItem,
          totalPage: response.data.totalPage,
          fullData: fullData
        })
      }
    }
  } catch (error) {
    yield put({ type: GET_PRODUCT_LIST_FAILURE, payload: '', errorCode: 0 })
  }
}
async function fetchProductFilter (page, name, value, checktoken, size) {
  axios.defaults.headers.common.Authorization = await checktoken
  return axios({
    method: 'GET',
    url: LINK_API.CREATE_PRODUCT.concat( '?page=' , page , '&size=' , size+ '&' , name , '=' , value) 
  })
}
const productType = [
  takeLatest(GET_PRODUCT_LIST, getProductTypeList),
  takeLatest(CREATE_PRODUCT, watchCreateProduct),
  takeLatest(RENDER_PRODUCT_LIST, watchRenderProduct),
  takeLatest(SAVE_DATA_EDIT_PRODUCT, saveDataProduct),
  takeLatest(UPDATE_INFO_PRODUCT, watchUpdateEmployee),
  takeLatest(GET_PRODUCT_LIST_FILTER, getProductTypeListFilter)
]
export default productType

