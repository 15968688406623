import { GET_OBJECT_PROCESSING_DETAIL, GET_OBJECT_PROCESSING_DETAIL_SUCCESS, GET_OBJECT_PROCESSING_DETAIL_FAILURE } from 'actions/types'
const initialState = { data: '' }
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_OBJECT_PROCESSING_DETAIL:
      return {
        ...state
      }
    case GET_OBJECT_PROCESSING_DETAIL_SUCCESS:
      return {
        ...state,
        data: action.data
      }
    case GET_OBJECT_PROCESSING_DETAIL_FAILURE:
      return {
        ...state
      }
    default:
      return state
  }
}
export default reducer
