import Header from "components/Headers/Header";
import LoadingTable from "components/LoadingTable/index.js";
import Pagination from "components/Pagination/index.jsx";
import { useFormik } from 'formik';
import { motion } from "framer-motion";
import CloseIcon from "mdi-react/CloseIcon";
import FilterIcon from "mdi-react/FilterIcon";
import PenIcon from "mdi-react/LeadPencilIcon";
import PlusCircle from "mdi-react/PlusCircleIcon";
import ResetIcon from "mdi-react/RestoreIcon";
import moment from 'moment';
import React, { useEffect } from 'react';
import cookie from "react-cookies";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ReactTable from 'react-table-6';
import { Button, Card, Col, Container, CustomInput, DropdownMenu, DropdownToggle, Input, Nav, NavItem, NavLink as NavLinkStrap, Row, UncontrolledDropdown, UncontrolledTooltip } from 'reactstrap';
import * as Yup from 'yup';
import "../../css/reactTable.css";

function ProcessList(props) {
  const getProcessList = props.getProcessList;
  const permission = props.permission.data;
  const processList = props.processList.data
  const [checkPer, setPer] = React.useState(false);
  const [filterStatus, setFilter] = React.useState(false)
  React.useEffect(() => {
    if(permission){
      if (permission.maxBizProcess !== -1) {
        if (permission.bizProcess >= permission.maxBizProcess) {
          setPer(true)
        }
      }
    }
  }, [permission, processList])
  const [dataProcessList, setDataProcessList] = React.useState([])
  const [loadingState, setLoading] = React.useState(true)
  const { t } = props;
  const genTable = React.useCallback((processList) => {
    let data = processList.map((prop, index) => {
      return {
        id: prop.bizProcessCode,
        name: prop.processName,
        time: moment(prop.createdAt).format("DD/MM/YYYY"),
        number: prop.businessProcess.length,
        actions: <div>
          <Button disabled={permission.agdqr} onClick={() => handleEdit(prop.bizProcessCode)} color="neutral-success" className="mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center" id={"areaEdit" + index}>
            <span className="btn-wrapper--icon d-flex">
              <PenIcon
                size={18}
                className="text-success font-size-sm"
              />
            </span>
          </Button>
          <UncontrolledTooltip trigger="hover" popperClassName="tooltip-black" placement="top" container="body" target={"areaEdit" + index}>
            {t('edit')}
          </UncontrolledTooltip>
          <Button onClick={() => handleDelete(prop.bizProcessCode)} color="neutral-success" className="mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center" id={"process" + index}>
            <span className="btn-wrapper--icon d-flex">
              <CloseIcon
                size={18}
                className="text-danger font-size-sm"
              />
            </span>
          </Button>
          <UncontrolledTooltip trigger="hover" popperClassName="tooltip-black" placement="top" container="body" target={"process" + index}>
            Xóa quy trình
          </UncontrolledTooltip>
        </div>
      }
    })
    setDataProcessList(data)
    setLoading(false)
  }, [t])
  const handleDelete = (uuid) => {

  }
  const formik = useFormik({
    initialValues: {
      name: 'name',
      value: '',
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required(t('require')),
      value: Yup.string()
        .required(t('require')),
    }),
    onSubmit: React.useCallback(async (values) => {
      setFilter(true);
      setLoading(true);
      props.getProcessListFilter(1, values.name, values.value, 5)
    }, []),
  });
  useEffect(() => {
    getProcessList()
  }, [getProcessList]);

  const handleResetData = () => {
    setFilter(false);
    getProcessList(1, 5)
  }
  const handleClearFilter = () => {
    formik.resetForm();
  }
  //const totalPageProps = props.processList.totalPage
  useEffect(() => {
    if (processList && processList.length > 0) {
      genTable(processList)
      // setTotalPage(totalPageProps)
    } else {
      genTable([])
      //setTotalPage(0)
    }

  }, [processList, genTable]);


  const columns = React.useMemo(
    () => [
      {
        accessor: "id",
        filterable: false,
        className: "center",
        Header: () => (
          <div style={{
            textAlign: "center"
          }}>
            {t('process.id')}
          </div>
        )
      },
      {
        Header: t('process.name'),
        accessor: "name",
        sortable: false
      },
      {
        Header: t('process.date'),
        accessor: "time",
        filterable: false
      },
      {
        Header: t('process.step'),
        accessor: "number",
        filterable: false
      },
      {
        Header: t('actions'),
        accessor: "actions",
        filterable: false
      },
    ],
    [t]
  );

  const handleChangePage = () => {
    props.history.push("/user/createProcess")
  }
  const handleEdit = async (uuid) => {
    await cookie.save("uuidProcess", uuid, { path: "/" });
    props.history.push("/user/editProcess")
  }
  return (

    <>
     <motion.div
                className="w-100"
                initial={{ x: -100 }}
                animate={{ x: 0 }}
                transition={{ ease: "easeOut", duration: 0.3 }}
            >
      <Header/>
              <Container className='mt--7' fluid>
      <Card className="card-box mb-5">
        <div className="card-header py-3 d-flex justify-content-between">
          <div className=" font-weight-bold card-header--title font-size-lg">{t('process.list')}</div>
          <div className="card-header--actions">
            <div className="d-inline-block" id="notiPlan">
            <UncontrolledDropdown>
                <DropdownToggle id="filterButton" color="neutral-success" className="mx-2">
                  <FilterIcon size={20} /> {t('filter.filter')}
                </DropdownToggle>
                <DropdownMenu right className="dropdown-menu-xxl p-0">
                  <form onSubmit={formik.handleSubmit}>
                    <div className="p-3">
                      <Row>
                        <Col md="6">
                          <small className="font-weight-bold pb-2 text-uppercase text-primary d-block">
                            {t('filter.name')}
                          </small>
                          <CustomInput
                            type="select"
                            placeholder={t('filter.name')}
                            value={formik.values.name}
                            valid={formik.touched.name && formik.errors.name ? false : (formik.values.name ? true : false)}
                            invalid={formik.touched.name && formik.errors.name ? true : false}
                            {...formik.getFieldProps('name')}
                          >
                            <option value="name">Tên quy trình</option>
                            <option value="uuid">Mã quy trình</option>
                          </CustomInput>
                        </Col>
                        <Col md="6">
                          <small className="font-weight-bold pb-2 text-uppercase text-primary d-block">
                            {t('filter.value')}
                          </small>
                          {formik.values.name !== "status" ?
                            <Input
                              placeholder={t('filter.value')}
                              value={formik.values.value}
                              valid={formik.touched.value && formik.errors.value ? false : (formik.values.value ? true : false)}
                              invalid={formik.touched.value && formik.errors.value ? true : false}
                              {...formik.getFieldProps('value')}
                            >
                            </Input> : <CustomInput
                              type="select"
                              placeholder={t('filter.value')}
                              value={formik.values.value}
                              valid={formik.touched.value && formik.errors.value ? false : (formik.values.value || formik.values.value === 0 ? true : false)}
                              invalid={formik.touched.value && formik.errors.value ? true : false}
                              {...formik.getFieldProps('value')}
                            >
                              <option value={1}>{t('product.inProduction')}</option>
                              <option value={0}>{t('product.stopProduction')}</option>
                            </CustomInput>}
                        </Col>
                      </Row>

                    </div>
                    <div className="divider" />
                    <div className="p-3 text-center bg-secondary">
                      <Button color="primary" type="submit">
                        {t('filter.search')}
                      </Button>
                    </div>
                  </form>
                  <div className="divider" />
                  <div className="p-3">
                    <Row>
                      <Col md="6">
                        <Nav className="nav-neutral-danger flex-column p-0">
                          <NavItem>
                            <NavLinkStrap className="d-flex rounded-sm justify-content-center" onClick={handleClearFilter}>
                              <div className="nav-link-icon">
                                <CloseIcon />
                              </div>
                              <span>{t('filter.clear')}</span>
                            </NavLinkStrap>
                          </NavItem>
                        </Nav>
                      </Col>
                      <Col md="6">
                        <Nav className="nav-neutral-info flex-column p-0">
                          <NavItem>
                            <NavLinkStrap className="d-flex rounded-sm justify-content-center" onClick={handleResetData}>
                              <div className="nav-link-icon">
                                <ResetIcon />
                              </div>
                              <span>{t('filter.reset')}</span>
                            </NavLinkStrap>
                          </NavItem>
                        </Nav>
                      </Col>
                    </Row>
                  </div>
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledTooltip trigger="hover" popperClassName="tooltip-black" placement="top" container="body" target={"filterButton"}>
                {t('filter.filter')}
              </UncontrolledTooltip>
              <Button disabled={checkPer || permission.agdqr} color="info" onClick={handleChangePage}>
                <span className="btn-wrapper--icon">
                  <PlusCircle size={16} />
                </span>
                <span className="btn-wrapper--label">{t('product.add')}</span>
              </Button>
              {checkPer || permission.agdq ?
                <UncontrolledTooltip trigger="hover" popperClassName="tooltip-warning" placement="top" container="body" target={"notiPlan"}>
                  {t('notiPlan')}
                </UncontrolledTooltip> : null}
            </div>

          </div>
        </div>

        <div className="divider" />
        <div className="card-body pt-2 px-4 pb-4">

          <ReactTable
            noDataText={t('noData')}
            data={dataProcessList}
            columns={columns}
            previousText={"<"}
            nextText={">"}
            rowsText={t('row')}
            ofText="/"
            defaultPageSize={5}
            showPaginationBottom={true}
            sortable={false}
            resizable={true}
            PaginationComponent={Pagination}
            //pages={totalPage}
            className="-striped -highlight"
            loading={loadingState}
            LoadingComponent={LoadingTable}
          // onFetchData={async (state, instance) => {
          //   setLoading(true)
          //   let x = state.page + 1;
          //   getProcessList(x, state.pageSize)
          // }}
          />
        </div>
      </Card>
      </Container>
      </motion.div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
    userProfile: state.userProfile.data,
    processList: state.processList,
    permission: state.permission
  }
}

const mapDispatchToProps = (dispatch) => ({
  getProcessList: (page, size) => dispatch({ type: "GET_PROCESS_LIST", page: page, size: size }),
  getProcessListFilter: (page, name, value, size) => dispatch({ type: "GET_PROCESS_LIST_FILTER", page: page, name: name, value: value, size: size }),


});

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(ProcessList));