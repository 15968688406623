import Loading from 'components/alert/loading'
import Error from 'components/alert/error'
import Success from 'components/alert/success'
import LoadingTable from "components/LoadingTable/index.js";
import Pagination from "components/Pagination/index.jsx";
import TruckIcon from "mdi-react/TruckIcon";
import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ReactTable from 'react-table-6';
import { Button, Card, UncontrolledTooltip, Container } from 'reactstrap';
import "../../css/reactTable.css";
import axios from 'axios';
import { LINK_API } from 'constants/API';
import ConfirmAlert from "components/alert/confirmAlert.js";
import Header from "components/Headers/Header"
const CryptoJS = require("crypto-js");
const EthereumTx = require('ethereumjs-tx');

function ImportBoxList(props) {
  const getlist = props.getlist;
  const [dataImportBoxList, setImportBoxList] = React.useState([])
  const [loadingState, setLoading] = React.useState(true)
  const [alert, setAlert] = React.useState(null)
  const { t } = props;

  const hideAlert = () => {
    setAlert(null)
  }

  const userInfo = props.userInfo

const confirmActive = React.useCallback((id) => {
  setAlert(<Loading/>)
  const data = {
    "toId": id,
    "detail": "Nhận hàng"
  }
  axios.post(LINK_API.GET_INFO_BOX, data)
    .then(res => {
      if (res.data.errorCode === 1) {
        var bytes = CryptoJS.AES.decrypt(userInfo.data.privateEncrypted.toString(), userInfo.password);
        const privateKey = bytes.toString(CryptoJS.enc.Utf8);
        const privatekey_slice = privateKey.substring(2, privateKey.length);
        const Buffer_privatekey = Buffer.from(privatekey_slice.toString(), 'hex');
        const tx = new EthereumTx(res.data.data.contractRaw, { chain: 4 });
        tx.sign(Buffer_privatekey);
        const rawTx = '0x' + tx.serialize().toString('hex');
        const data = {
          "toId": id,
          "detail": "Nhận hàng",
          "raw": rawTx
        }
        axios.post(LINK_API.RECEIVE_BOX, data)
          .then(res => {
            if (res.data.errorCode === 1) {
              getlist();
              setAlert(<Success hideCancel={true} onConfirm={hideAlert} confirmBtnText={t('close')} />)
            } else {
              setAlert(<Error onClose={hideAlert} confirmBtnText={t('close')} />)
            }
          })
          .catch(err => {
            console.log(err)
            setAlert(<Error onClose={hideAlert} confirmBtnText={t('close')} />)
          })
      }
    })
    .catch(err => {
      console.log(err)
      setAlert(<Error onClose={hideAlert} confirmBtnText={t('close')} />)
    })

}, [t, getlist, userInfo])


  const handleEnterData = React.useCallback((uuid) => {
    let ChildComponent = <div>{t('import.doYouWantImport')}</div>
    setAlert(
      <ConfirmAlert
        onClose={hideAlert}
        onConfirm={() => confirmActive(uuid)}
        confirmBtnText={t('confirm')}
        child={ChildComponent}
      />
    )
  }, [t, confirmActive])

  const genTable = React.useCallback((listData) => {
    let data = listData.map((prop, index) => {
      return {
        status: prop.status === 0 ? t('import.closedBox') : prop.status === 1 ? t('import.Sold') : prop.status === 2 ? t('import.openedBox') : prop.status === 3 ? t('import.deliveried') : prop.status === 4 ? t('import.received') : "",
        id: index + 1,
        uuid: prop.id,
        action: <div className="d-flex flex-row justify-content-center">
          <Button onClick={() => handleEnterData(prop.id)} color="neutral-success" className="mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center" id={"empTooltipRecover" + index}>
            <span className="btn-wrapper--icon d-flex">
              <TruckIcon
                size={18}
                className="text-success font-size-sm"
              />
            </span>
          </Button>
          <UncontrolledTooltip trigger="hover" popperClassName="tooltip-black" placement="top" container="body" target={"empTooltipRecover" + index}>
            {t('import.link')}
          </UncontrolledTooltip>
        </div>
      }
    })
    setImportBoxList(data)
    setLoading(false)
  }, [t, handleEnterData])

  useEffect(() => {
    getlist()
  }, [getlist]);

  const importList = props.importList.data
  useEffect(() => {
    if (importList && importList.length > 0) {
      genTable(importList)
    } else {
      genTable([])
    }

  }, [importList]);

  const columns = React.useMemo(
    () => [
      {
        accessor: "id",
        filterable: false,
        className: "center",
        Header: () => (
          <div style={{
            textAlign: "center"
          }}>
            #
          </div>
        )
      },
      {
        Header: t('import.ID'),
        accessor: "uuid",
        sortable: false
      },
      {
        Header: t('import.status'),
        accessor: "status",
        filterable: false
      },
      {
        Header: t('management.action'),
        accessor: "action",
        filterable: false
      },
    ],
    [t]
  );


  return (

    <>
    <Header/>
    <Container className='mt--7' fluid>
      <Card className="card-box mb-5">
        <div className="card-header py-3">
          <div className=" font-weight-bold card-header--title font-size-lg">{t('import.list')}</div>
        </div>

        <div className="divider" />
        <div className="card-body pt-2 px-4 pb-4">

          <ReactTable
            noDataText={t('noData')}
            data={dataImportBoxList}
            columns={columns}
            previousText={"<"}
            nextText={">"}
            rowsText={t('row')}
            ofText="/"
            defaultPageSize={5}
            showPaginationBottom={true}
            sortable={false}
            resizable={true}
            PaginationComponent={Pagination}
            className="-striped -highlight"
            loading={loadingState}
            LoadingComponent={LoadingTable}
          />
        </div>
      </Card>
      </Container>
      {alert}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
    importList: state.allBox
  }
}

const mapDispatchToProps = (dispatch) => ({
  getlist: () => dispatch({ type: "GET_ALL_BOX" }),
});

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(ImportBoxList));