import { 
  GET_PORTAL_SETTING,
  GET_PORTAL_SETTING_SUCCESS,
  GET_PORTAL_SETTING_FAILURE,
  GET_ADS_SETTING,
  GET_ADS_SETTING_SUCCESS,
  GET_ADS_SETTING_FAILURE,
 } from 'actions/types.js'
const initialState = { infoPortal: '', adsInfo: '', error: '', loading: true }
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PORTAL_SETTING:
      return {
        ...state,
        loading: true
      }
    case GET_PORTAL_SETTING_SUCCESS:

      return {
        ...state,
        loading: false,
        infoPortal: action.data,
      }
    case GET_PORTAL_SETTING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    case GET_ADS_SETTING:
        return {
          ...state,
          loading: true
        }
    case GET_ADS_SETTING_SUCCESS:
      return {
        ...state,
        loading: false,
        adsInfo: action.data,
      }
    case GET_ADS_SETTING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    default:
      return state
  }
}
export default reducer
