import {
  RENDER_SELECT_CUSTOMER,
  RENDER_SELECT_CUSTOMER_SUCCESSFULL,
  RENDER_SELECT_CUSTOMER_FAILURE,

} from "actions/types"
import { takeLatest, call, put, select } from 'redux-saga/effects'
import { LINK_API } from 'constants/API.js'
import axios from 'axios'

const getToken = (state) => state.userInfo



// Get plan list
function * getCustomerList () {
  try {
    const tokenInfo = yield select(getToken)
    const response = yield call(fetchCustomer, tokenInfo.data.token)
    const data = response.data.data.filter(item => item.role === "M")
    const length = data.length
    const options = []
    for (let i = 0; i < length; i++) {
      if (data[i].active === true) {
        options.push({
          value: data[i].uuid,
          label: data[i].uuid + ' - ' + data[i].name
        })
      }
    }
    yield put({ type: RENDER_SELECT_CUSTOMER_SUCCESSFULL, data: options })
  } catch (error) {
    yield put({ type: RENDER_SELECT_CUSTOMER_FAILURE, data: '' })
  }
}
function fetchCustomer (checktoken) {
  axios.defaults.headers.common.Authorization = checktoken
  return axios({
    method: 'GET',
    url: LINK_API.GET_COMPANY
  })
}


const CustomerList = [
  takeLatest(RENDER_SELECT_CUSTOMER, getCustomerList)
]
export default CustomerList

