import clsx from 'clsx';
import React from "react";
import { withTranslation } from 'react-i18next';
import Loki from 'react-loki';
import { connect } from 'react-redux';
import Step1 from "./steps/stepOne";
import Step2 from "./steps/stepTwo";
import axios from "axios"
import { LINK_API } from "constants/API"
import Header from "components/Headers/Header"
import {Card, Container} from "reactstrap"
// const LZUTF8 = require('lzutf8');
const ethers = require('ethers');
const CryptoJS = require("crypto-js");

function CreateEmployee(props) {
    const t = props.t;
    const permission = props.permission.data;

    axios.defaults.headers.common['Authorization'] = props.userInfo.data.token;
    const [state, setState] = React.useState({})
    const [pathCountEmploy, setPathCountEmploy] = React.useState("");
    const [pathCountUser, setPathCountUser] = React.useState("");
    const _mergeValues = (data) => {
        setState({
            ...state,
            ...data
        })
    }
    const customSteps = [
        {
            label: t("description"),
            number: '1',
            description: t('employee.addNewMember'),
            component: <Step1 data={state} />,
        },
        {
            label: t('settingAdvanced'),
            number: '2',
            description: t('area.setting'),
            component: <Step2 data={state} mergeData={_mergeValues} />,
        },
    ];

    const _customRenderer = ({ currentStep, nextHandler, stepIndex, isInFinalStep }) => {
        const steps = customSteps.map((step, index) => {
            const isActive = currentStep === index + 1;
            return <li key={index} className={clsx("card-box w-50 p-3",{ 'current-tab-loki': isActive })}><a href="# " onClick={(e) => e.preventDefault()}>
                <div className="step-indicator">
                    <div className="d-flex">
                        <div className="font-size-lg d-44 rounded-sm mr-2 stepper-wrapper text-center">
                            <span className="font-weight-bold">{step.number}</span>
                        </div>
                        <div>
                            <div className="font-weight-bold">{step.label}</div>
                            <small className="text-black-50">{step.description}</small></div>
                    </div>
                </div>
            </a></li>
        });

        return <div className="horizontal">
            <ul className="steps-indicator d-flex">{steps}</ul>
        </div>;
    };



    const _onFinish = async () => {
        let bytes = CryptoJS.AES.decrypt(props.userInfo.data.seedEncrypted.toString(), props.userInfo.password);
        const randomMnemonic = bytes.toString(CryptoJS.enc.Utf8);
        let walletPath = {
            standard: "m/44'/60'/0'/" + pathCountUser + '/' + pathCountEmploy
        };
        let hdnode = ethers.utils.HDNode.fromMnemonic(randomMnemonic);
        let node = hdnode.derivePath(walletPath.standard);
        const privatekey = node.privateKey;
        const enPrivateKey_emp = await CryptoJS.AES.encrypt(privatekey, state.password.toString());

        let temp_sub = new Buffer(state.password).toString('hex');
        const pass_en = await ethers.utils.keccak256('0x' + temp_sub);
        let data = {
            name: state.name,
            phone: '',
            email: '',
            username: state.username,
            password: pass_en,
            address: node.address,
            publicKey: node.publicKey,
            privateEncrypted: enPrivateKey_emp.toString(),
            management: [],
            gsrn: '',
            role: state.role.value,
            avatar: state.image ? state.image[0] : "",
            path: pathCountEmploy,
            manageZone: [],
        };
        let tempArea = [];
        for (let j = 0; j < state.area.length; j++) {
            tempArea.push(state.area[j].value);
        }
        data.manageZone = tempArea
        props.CreateEmployee(data);
        props.history.push('/user/employee')
    };
    React.useEffect(() => {
        axios
            .get(LINK_API.GET_PATH_EMPLOY)
            .then(async (response) => {
                if (response.data.errorCode === 1) {
                    setPathCountEmploy(response.data.data.pathEmployee.toString());
                    setPathCountUser(response.data.data.pathUser.toString());
                }
            })
            .catch(async (error) => {
                console.log(error);
            });
    }, [])

    return (
        <>
        <Header></Header>
        <Container className='mt--7' fluid>
                <Card className="card-box mb-5">
            {permission.agdqr ? <div> </div> :
                <div className="wizard-alternate-2 horizontal">
                    <Loki
                        steps={customSteps}
                        renderSteps={_customRenderer}
                        onNext={_mergeValues}
                        onFinish={_onFinish}
                        noActions />
                </div>
            }
            </Card>
            </Container>
        </>
    );
}
const mapStateToProps = (state) => {
    return {
        userInfo: state.userInfo,
        areaList: state.renderArea,
        objectList: state.objectList,
        permission: state.permission
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        CreateEmployee: (data) => dispatch({ type: "CREATE_EMPLOYEE", data: data }),
    }
}
export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(CreateEmployee))