import Axios from "axios";
import clsx from "clsx";
import Loading from "components/alert/loading";
import { LINK_API } from "constants/API";
import CloseIcon from "mdi-react/CloseIcon";
import PlusCircle from "mdi-react/PlusCircleIcon";
import React from "react";
import cookie from "react-cookies";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Button, UncontrolledTooltip } from "reactstrap";
import Loki from "./loki/index";
import StepBasic from "./steps/stepBasic";
import StepHarvest from "./steps/stepHarvest";
import StepNew from "./steps/stepNew";
import Header from "components/Headers/Header"
import {Card, Container} from "reactstrap"
function CreateProcess(props) {
    const t = props.t;
    const permission = props.permission.data;

    const uuid = cookie.load("uuidProcess");
    const [state, setState] = React.useState([]);
    const [countStep, setCount] = React.useState(1);
    const [alert, setAlert] = React.useState(null)
    const [current, setCurrent] = React.useState(1);
    const _mergeValues = async (data) => {
        let temp = state;
        if (data.process_name) {
            temp[0] = data;
            setState(temp);
        } else {
            // console.log(data.index)
            temp[data.index] = data;
            if (data.index !== 1) {
                await setState(temp);
                renderNameStep(data.index, temp)
            } else {
                await setState(temp);
            }
            console.log("temp", temp)
        }
    }
    const hideAlert = () => {
        setAlert(null)
    }
    const defaultSteps = [
        {
            label: t('process.basicInfo'),
            number: 'TT',
            index: 0,
            component: <StepBasic data={state} merge={_mergeValues} />,
        },
        {
            label: "Công đoạn mới",
            number: '1',
            index: 1,
            component: <StepNew data={state} index={1} merge={_mergeValues} />,
        },
        {
            label: t('process.harvest'),
            number: 'TH',
            index: 0,
            component: <StepHarvest dataHarvest={state} index={1} merge={_mergeValues} />,
        }

    ];
    const [listSteps, setSteps] = React.useState(defaultSteps);
    const handleAddNewStep = async () => {
        let nextStep = countStep + 1;
        setCount(countStep + 1);

        let temp = {
            label: 'Công đoạn ' + (nextStep),
            number: nextStep,
            index: nextStep,
            status: true,
            component: <StepNew data={state} index={nextStep} merge={_mergeValues} />,
        }
        let slice_state = listSteps.length - 1;
        let tempList = listSteps;
        await tempList.splice(slice_state, 0, temp);
        let step_new = await tempList.map((item, idx) => {
            if (idx > 0 && idx < tempList.length - 1) {
                let tempx = {
                    label: idx === tempList.length - 2 ? 'Công đoạn mới' : item.label ? item.label : "Công đoạn " + idx,
                    number: idx,
                    index: item.index,
                    component: item.component,
                    status: item.status,
                }
                return tempx;
            } else {
                return item
            }
        })
        setSteps([...step_new])
    }
    const renderNameStep = async (index, data) => {
        //  console.log("listSteps", listSteps)
        //   let temp = listSteps;
        //   temp[index -1 ].label = data[index].name
        //   console.log(temp)
        // const newLabel = await listSteps.map((item, idx) => {
        //    // console.log("Item", item)
        //         if(item.index !== 0){
        //             console.log(data[item.index + 1].name)
        //             if(data[item.index + 1]){
        //                 item.label = data[item.index + 1].name;
        //             }
        //         }
        //         return item;
        //     })
        //  //   console.log("newLabel", newLabel)
        // await setSteps([...temp])
    }
    const handleRemoveStep = async (index) => {

        let temp = await listSteps.filter((item, idx) => idx !== index);
        let step_new = await temp.map((item, idx) => {
            if (idx > 0 && idx < temp.length - 2) {
                let tempx = {
                    label: item.label ? item.label : 'Công đoạn ' + idx,
                    number: idx,
                    index: item.index,
                    status: item.status,
                    component: item.component,
                }
                return tempx;
            } else {
                return item
            }
        })

        if (state[index + 1]) {
            let tempRemove = await state.filter((item, idxx) => idxx !== index + 1);
            await setState(tempRemove)
        }
        setSteps([...step_new])
    }
    const _customRenderer = ({ currentStep }) => {
        setCurrent(currentStep)
        const steps = listSteps.map((step, index) => {
            const isActive = currentStep === index + 1;
            return <li key={index} className={clsx("card-box", { 'current': isActive })}>
                <a href="#/" onClick={e => e.preventDefault()}>
                    <div className="label d-flex justify-content-start align-items-center flex-row">
                        <p className="mb-0">{step.label}</p>
                        {index > 0 && index < listSteps.length - 1 && listSteps.length > 3 && step.status === true ?
                            <>
                                <Button
                                    color="neutral-danger"
                                    onClick={() => handleRemoveStep(index)}
                                    className="mx-1 rounded-sm shadow-none hover-scale-sm d-30 ml-2 border-0 p-0 d-inline-flex align-items-center justify-content-center"
                                    id={"deleteStep" + index}>
                                    <span className="btn-wrapper--icon d-flex">
                                        <CloseIcon
                                            size={18}
                                            className="text-danger font-size-sm"
                                        />
                                    </span>
                                </Button>
                                <UncontrolledTooltip trigger="hover" popperClassName="tooltip-black" placement="top" container="body" target={"deleteStep" + index}>
                                    {t('process.remove')}
                                </UncontrolledTooltip>
                            </>
                            : null}
                        {/* {index > 0 && index < listSteps.length - 1 && step.status === false ?
                            <>
                                <Button
                                    color="neutral-warning"
                                    onClick={() => handleRemoveStep(index)}
                                    className="mx-1 rounded-sm shadow-none hover-scale-sm d-30 ml-2 border-0 p-0 d-inline-flex align-items-center justify-content-center"
                                    id={"hideStep" + index}>
                                    <span className="btn-wrapper--icon d-flex">
                                        <HideIcon
                                            size={18}
                                            className="text-warning font-size-sm"
                                        />
                                    </span>
                                </Button>
                                <UncontrolledTooltip trigger="hover" popperClassName="tooltip-black" placement="top" container="body" target={"hideStep" + index}>
                                    {t('process.hide')}
                                </UncontrolledTooltip>
                            </>
                            : null} */}
                    </div>
                    <div className="step-indicator">{step.number}</div>
                </a>
            </li>
        });

        return <div className="vertical"><ul className="steps-indicator">{steps}</ul></div>;
    };

    const _onFinish = async () => {
        //  console.log("State", state)
        let dataBasic = state[0];
        let dataHarvest = state[1];
        let stepsList = [];
        for (let i = 2; i < state.length; i++) {
            stepsList.push(state[i]);
        }
        await stepsList.push(dataHarvest);
        let data = {
            ...dataBasic,
            "process_code": uuid,
            "step": stepsList
        }
        props.createProcess(data);
        props.history.push('/user/process');
    };
    React.useEffect(() => {
        // console.log("Check how many")
        let uuidProcess = cookie.load("uuidProcess");
        async function getDetail(uuid) {
            Axios.get(LINK_API.GET_INFO_PROCESS + uuid)
                .then(async res => {
                    if (res.data.errorCode === 1) {
                        hideAlert();
                        let values = (res.data.data);
                        let temp = state;
                        let basicInfo = {
                            "process_name": values.process_name,
                            "type": 1,
                            "specialStep": values.specialStep
                        };
                        temp[0] = basicInfo;
                        let stepListData = values.step;
                        temp[1] = stepListData[stepListData.length - 1];
                        for (let i = 0; i < stepListData.length - 1; i++) {
                            await temp.push(stepListData[i])
                        }
                        // console.log("temp", temp)
                        await setState(temp)
                        let renderStep = [];
                        await renderStep.push(
                            {
                                label: t('process.basicInfo'),
                                number: 'TT',
                                index: 0,
                                component: <StepBasic data={state} merge={_mergeValues} />,
                            }
                        )
                        for (let i = 0; i < stepListData.length; i++) {
                            if (i < stepListData.length - 1) {
                                renderStep.push({
                                    label: stepListData[i].name,
                                    number: i + 1,
                                    index: i + 1,
                                    status: false,
                                    component: <StepNew data={state} index={i + 1} merge={_mergeValues} />,
                                })
                            } else {
                                await setCount(renderStep.length - 1);
                                renderStep.push({
                                    label: t('process.harvest'),
                                    number: 'TH',
                                    index: 0,
                                    status: false,
                                    component: <StepHarvest dataHarvest={state} index={1} merge={_mergeValues} />,
                                })
                            }

                        }
                        setSteps([...renderStep])

                    }
                })
        }
        getDetail(uuidProcess);
    }, []);
    React.useEffect(() => {
        setAlert(<Loading />)
    }, [])
    return (
        <>
          <Header></Header>
            <Container className='mt--7' fluid>
                <Card className="card-box mb-5">
            {
                permission.agdqr ? <div> </div> :

                    <div className="card card-box">
                        <div className="card-header d-flex justify-content-between">
                            <div className="card-header--title d-flex flex-column">
                                <small>{t('process.link')}</small>
                                <b>{t('process.editProcess')}</b>
                            </div>
                            <div className="card-header--actions">
                                <Button outline color="info" onClick={handleAddNewStep}>
                                    <span className="btn-wrapper--icon">
                                        <PlusCircle size={16} />
                                    </span>
                                    <span className="btn-wrapper--label">{t('process.addStep')}</span>
                                </Button>
                                {/* <Button color="info" className="ml-2" onClick={handleAddNewStep}>
                            <span className="btn-wrapper--icon">
                                <CheckIcon size={16} />
                            </span>
                            <span className="btn-wrapper--label">{t('save')}</span>
                        </Button> */}
                            </div>
                        </div>
                        <div className="wizard vertical">
                            <Loki
                                steps={listSteps}
                                renderSteps={_customRenderer}
                                onNext={_mergeValues}
                                onFinish={_onFinish}
                                noActions />
                        </div>
                    </div>
            }
            {alert}
            </Card>
            </Container>
        </>
    );
}

const mapStateToProps = (state) => ({
    permission: state.permission
})

const mapDispatchToProps = (dispatch) => ({
    createProcess: (data) => dispatch({ type: "EDIT_PROCESS", data: data }),
});
export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(CreateProcess));
