import { SAVE_PLAN, SAVE_PLAN_SUCCESS, SAVE_PLAN_FAILURE } from 'actions/types'
const initialState = {
  linhvuc: [],
  plan: '',
  stamp: '',
  infoDn: '',
  infoAcc: ''
}
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_PLAN:
      return {
        ...state
      }
    case SAVE_PLAN_SUCCESS:
      return {
        ...state,
        linhvuc: action.linhvuc,
        plan: action.plan,
        stamp: action.stamp,
        infoDn: action.infoDn,
        infoAcc: action.infoAcc
      }
    case SAVE_PLAN_FAILURE:
      return {
        ...state,
        data: action.data,
        password: action.password
      }
    default:
      return state
  }
}
export default reducer
