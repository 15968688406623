import CloseBoxIcon from "mdi-react/CloseBoxIcon";
import CloseIcon from "mdi-react/CloseCircleIcon";
import React from "react";
import { useDropzone } from 'react-dropzone';
import { Check, UploadCloud, X } from 'react-feather';
import { withTranslation } from 'react-i18next';
import { Button, UncontrolledTooltip } from "reactstrap";
import ConfirmAlert from "components/alert/confirmAlert" 
import "./css/dropZone.css";

const DropZone = (props) => {
  const maxImage = props.max;
  const sizeOut = props.sizeOut ? props.sizeOut : "90";
  const sizeIn = props.sizeIn ? props.sizeIn : "60"
  const sizeIcon = props.sizeIcon ? props.sizeIcon : "30"
  const mTop = props.mTop ? props.mTop : "-50px"
  const mLeft = props.mLeft ? props.mLeft : "53px"
  const disabled = props.disabled ? props.disabled : false;
  const t = props.t;
  const [list, setList] = React.useState([]);
  const [alert, setAlert] = React.useState(null)
  const propsData = props.data;
  const {
    isDragActive,
    isDragAccept,
    isDragReject, getRootProps, getInputProps } = useDropzone({
      accept: 'image/*',
      multiple: true,
      onDrop: async acceptedFiles => {
        let temp = await acceptedFiles.map(file => Object.assign(file, {
          preview: URL.createObjectURL(file)
        }))
        creatFile(temp[0].preview)
      }
    });
  const hideAlert = () => {
    setAlert(null)
  }
  const handleDeleteApi = (index) => {
    let temp = list.filter((item, idx) => {
      console.log("item", item, idx != index, idx, index)
      if(idx !== index) return item;
      console.log("https://res.cloudinary.com", item.includes("https://res.cloudinary.com"))
      if(item.includes("https://res.cloudinary.com") || item.includes("http://res.cloudinary.com")){
        const listDelete = props.listDelete;
        props.onDeleteImage([...listDelete, item])
      }
    })
    console.log("temp", temp)
    setList(temp)
    if (props.onConfirm) {
      props.onConfirm(temp)
    }
    hideAlert();
  }
  const creatFile = (link) => {
    var file_result = "";
    var xhr = new XMLHttpRequest();
    xhr.open('GET', link, true);
    xhr.responseType = 'blob';
    xhr.onload = (e) => {
      e.preventDefault()
      var reader = new FileReader();
      var recoveredBlob = xhr.response;
      reader.onloadend = () => {
        handleData(reader)
      };

      reader.readAsDataURL(recoveredBlob);
      file_result = (reader)
    };
    xhr.send();
    return file_result;
  }
  const handleData = async (data) => {
    let temp = list;
    temp.push(data.result);
    setList([...temp])
    if (props.onConfirm) {
      props.onConfirm(temp)
    }
  }
  
  const handleDeleteImage = async (index) => {
    for (let i = 0; i < list.length; i++) {
      if (index === i) {
        setAlert(<ConfirmAlert child="Bạn muốn xỏa ảnh vừa chọn ?" onClose={hideAlert} onConfirm={() => handleDeleteApi(index)} />)
      }
    }
  }


  React.useEffect(() => {
    if (propsData) {
      setList([...propsData])
    }
  }, [propsData])

  return (
    <>
      <div className="d-flex flex-wrap mt-1">
        {list.map((img, index) => (
          <div key={index} className={index === 0 || index === maxImage - 1 ? "p-2 mr-2 bg-white shadow-xxl border-dark card-box d-flex unSetOverflow rounded-sm d-" + sizeOut : "p-2 mx-3 bg-white shadow-xxl border-dark card-box d-flex unSetOverflow rounded-sm d-" + sizeOut} style={{ height: sizeOut, width: sizeOut }}>
            <div className="img-fluid img-fit-container rounded-sm containIcon" style={{ backgroundImage: `url(${img})`, backgroundColor: "#a6a6b9" }} >
              <div style={{ marginTop: mTop, marginLeft: mLeft }}>
                {
                  props.editLogo ?
                    <Button onClick={() => { }} style={{ backgroundColor: "#0000002e" }} className="btn-icon btn-animated-icon btn-transition-none d-40 p-0 m-2" id={"deleteImageAva" + index}>
                      <span className="btn-wrapper--icon d-flex">
                        <img alt="" width="20" src="https://res.cloudinary.com/agridential/image/upload/v1600421521/EBS/camera_ehwrhw.svg" />
                      </span>
                    </Button>
                    :
                    <Button onClick={() => handleDeleteImage(index)} color="neutral-danger" className="btn-icon btn-animated-icon btn-transition-none  d-40 p-0 m-2" id={"deleteImageAva" + index}>
                      <span className="btn-wrapper--icon d-flex">
                        <CloseIcon className="font-size-xs text-danger" />
                      </span>
                    </Button>
                }

                <UncontrolledTooltip trigger="hover" popperClassName="tooltip-black" placement="top" container="body" target={"deleteImageAva" + index}>
                  {t('Chọn ảnh khác')}
                </UncontrolledTooltip>
              </div>
            </div>
          </div>
        ))}
        {list.length < maxImage ?
          <div style={{ height: sizeOut, width: sizeOut, marginLeft: list.length > 0 ? "20px" : "0px" }}>
            {!disabled ?
              <div {...getRootProps({ className: 'dropzone-upload-wrapper' })}>
                <input {...getInputProps()} />
                <div className="dropzone-inner-wrapper dropzone-inner-wrapper-alt borderBlack">
                  {isDragAccept && (
                    <div>
                      <div className="d-90 btn-icon mb-3 hover-scale-rounded bg-success shadow-success-sm rounded-lg text-white">
                        <Check className="d-50" />
                      </div>
                    </div>
                  )}
                  {isDragReject && (
                    <div>
                      <div className="d-100 btn-icon mb-3 hover-scale-rounded bg-danger shadow-danger-sm rounded-lg text-white">
                        <X className="d-90" />
                      </div>
                    </div>
                  )}
                  {!isDragActive && (
                    <div className={"d-flex justify-content-center align-items-center d-" + sizeOut} style={{ height: sizeOut, width: sizeOut }}>
                      <div className={"btn-icon hover-scale-rounded bg-white shadow-light-sm rounded-lg text-first d-" + sizeIn} style={{ height: sizeIn, width: sizeIn }}>
                        <UploadCloud className={"d-" + sizeIcon} style={{ height: sizeIcon, width: sizeIcon }} />
                      </div>
                    </div>
                  )}
                </div>
              </div> :
              <div className='dropzone-upload-wrapper'>
                <div className="dropzone-inner-wrapper dropzone-inner-wrapper-alt borderBlack notAllowedActions">
                  <div className={"d-flex justify-content-center align-items-center d-" + sizeOut} style={{ height: sizeOut, width: sizeOut }}>
                    <div id="disabledIcon" className={"d-flex justify-content-center align-items-center hover-scale-rounded shadow-light-sm rounded-lg text-first notUpload d-" + sizeIn} style={{ height: sizeIn, width: sizeIn }}>
                      <CloseBoxIcon className={"text-danger d-" + sizeIcon} style={{ height: sizeIcon, width: sizeIcon }} />
                      <UncontrolledTooltip trigger="hover" popperClassName="tooltip-black" placement="top" container="body" target={"disabledIcon"}>
                        {t('notAllowed')}
                      </UncontrolledTooltip>
                    </div>
                  </div>
                </div>
              </div>}
          </div>
          : null}
      </div>
      {alert}
    </>
  )
}
export default withTranslation('common')(DropZone);