import Axios from "axios";
import Error from "components/alert/error";
import Loading from "components/alert/loading";
import Success from "components/alert/success";
import { LINK_API } from "constants/API";
import { useFormik } from 'formik';
import Switch from 'rc-switch';
import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { Button, Input } from "reactstrap";
import Confirm from "components/alert/confirmAlert"
import "view/css/select.css";
import * as Yup from 'yup';
import { withRouter } from 'react-router-dom';

const ethers = require('ethers');
const CryptoJS = require("crypto-js");

function ChangePasss(props) {
    Axios.defaults.headers.common['Authorization'] = props.userInfo.data.token;
    const t = props.t;
    const userProfile = props.userProfile;
    const userInfo = props.userInfo;
    const [checked, setCheck] = React.useState(false);
    const toggle = () => {
        setCheck(!checked)
    }
  
    const [alert, setAlert] = React.useState(null)

   
    const formik = useFormik({
        initialValues: {
            oldPass: '',
            newPass: '',
            rePass: '',
        },
        validationSchema: Yup.object({
            oldPass: Yup.string()
                .min(6, t('tooShort'))
                .required(t('require')),
            newPass: Yup.string()
                 .min(6, t('tooShort'))
                .required(t('require')),
            rePass: Yup.string()
                .min(6, t('tooShort'))
                .oneOf([Yup.ref('newPass'), null], 'Không trùng khớp')
                .required(t('require')),
        }),
        onSubmit: async (values) => {
            handleSaveData();
        },
    });
    const hideAlert = () => {
        setAlert(null)
    }
    const handleConfirm = () => {
       // hideAlert();
        formik.resetForm();
        setCheck(false);
        setAlert(
        <Confirm
        child="Vui lòng đăng nhập lại tài khoản!"
        hideCancel={true}
        onConfirm={handleSignOut}
        />)
    }
    const handleSignOut = () => {
        props.cleanStore();
        props.history.push("/login")
        setAlert(<Loading />)
    }
    
    const handleSaveData = async () => {
        setAlert(<Loading />)
      
        var bytes  = CryptoJS.AES.decrypt(userInfo.data.seedEncrypted.toString(), userInfo.password);
        const randomMnemonic = bytes.toString(CryptoJS.enc.Utf8);
        var bytes_private  = CryptoJS.AES.decrypt(userInfo.data.privateEncrypted.toString(), userInfo.password);
        const privateKey = bytes_private.toString(CryptoJS.enc.Utf8);
        const enPrivateKey_emp = await CryptoJS.AES.encrypt(privateKey, formik.values.newPass.toString());
        const enRandomMnemonic = await CryptoJS.AES.encrypt(randomMnemonic, formik.values.newPass.toString());
        //
        var temp_oldPass = new Buffer(userInfo.password).toString('hex');
        const pass_en_old = await ethers.utils.keccak256('0x' + temp_oldPass);
        //hash new pass
        var temp_newPass = new Buffer(formik.values.newPass).toString('hex');
            const pass_en_new = await ethers.utils.keccak256('0x' + temp_newPass);
        const data = {
          currentPassword: pass_en_old,
          newPassword: pass_en_new,
          newSeedEcrypted: enRandomMnemonic.toString(),
          newPrivatedEncrypted: enPrivateKey_emp.toString(),
        }

        Axios.post(LINK_API.CHANGE_PWD, data)
            .then(res => {
                if (res.data.errorCode === 1) {
                    setAlert(<Success onConfirm={handleConfirm} hideCancel={true} confirmBtnText={t('confirm')} />)
                    props.getUserProfile()
                } else {
                    setAlert(<Error onClose={hideAlert} confirmBtnText={t('close')} />)
                }
            })
            .catch(err => {
                setAlert(<Error onClose={hideAlert} confirmBtnText={t('close')} />)
            })
    }

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="p-4">
                    <div className="form-row ">
                        <div className="form-group col-md-8 mb-0">
                            <h5 className="font-size-xl font-weight-bold">{t('profile.changePass')}</h5>
                            <p className="text-black-50 mb-0">{t('profile.passAbout')}</p>
                        </div>
                        <div className="form-group col-md-4 text-right mb-0">
                            <Button color="info" type="submit">{t('confirm')}</Button>
                        </div>
                    </div>
                    <div className="divider my-4" />
                    <div className="form-row ">
                        <div className="form-group col-md-12">
                            <label className="font-weight-bold">{t('profile.oldPass')} <span className="text-danger">(*)</span></label>
                            <Input
                                placeholder={t('profile.oldPass')}
                                type={checked ? "text" : "password"}
                                name="oldPass"
                                value={formik.values.oldPass}
                                valid={formik.touched.oldPass && formik.errors.oldPass ? false : (formik.values.oldPass ? true : false)}
                                invalid={formik.touched.oldPass && formik.errors.oldPass ? true : false}
                                {...formik.getFieldProps('oldPass')}
                            />
                            {formik.touched.oldPass && formik.errors.oldPass ? (
                                <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.oldPass}</p>
                            ) : null}
                        </div>
                        <div className="form-group col-md-12">
                            <label className="font-weight-bold">{t('profile.newPass')} <span className="text-danger">(*)</span></label>
                            <Input
                                placeholder={t('profile.newPass')}
                                type={checked ? "text" : "password"}
                                name="newPass"
                                value={formik.values.newPass}
                                valid={formik.touched.newPass && formik.errors.newPass ? false : (formik.values.newPass ? true : false)}
                                invalid={formik.touched.newPass && formik.errors.newPass ? true : false}
                                {...formik.getFieldProps('newPass')}
                            />
                            {formik.touched.newPass && formik.errors.newPass ? (
                                <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.newPass}</p>
                            ) : null}
                        </div>
                        <div className="form-group col-md-12">
                            <label className="font-weight-bold">{t('profile.rePass')} <span className="text-danger">(*)</span></label>
                            <Input
                                placeholder={t('profile.rePass')}
                                type={checked ? "text" : "password"}
                                name="rePass"
                                value={formik.values.rePass}
                                valid={formik.touched.rePass && formik.errors.rePass ? false : (formik.values.rePass ? true : false)}
                                invalid={formik.touched.rePass && formik.errors.rePass ? true : false}
                                {...formik.getFieldProps('rePass')}
                            />
                            {formik.touched.rePass && formik.errors.rePass ? (
                                <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.rePass}</p>
                            ) : null}
                        </div>
                        
                    </div>
                    <div className="m-2 form-row">
                            <Switch checked={checked} onClick={toggle} className="switch-small toggle-switch-success"></Switch>
                            <p className="font-weight-bold ml-2">{t(`profile.showPass`)}</p>
                        </div>
                </div>
            </form>
            {alert}
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        userProfile: state.userProfile.data,
        userInfo: state.userInfo
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        getUserProfile: () => dispatch({ type: "GET_USER_PROFILE" }),
        updateBanner: (data) => dispatch({ type: "UPDATE_BANNER", data: data }),
        updateLogo: (data) => dispatch({ type: "UPDATE_LOGO", data: data }),
        cleanStore: () => dispatch({ type: "CLEAN_STORE" }),
        getSetting: () => dispatch({ type: "GET_SETTING" }),

    }
}
export default withRouter(withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(ChangePasss)));
