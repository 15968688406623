import axios from 'axios';
import LoadingTable from "components/LoadingTable/index.js";
import Pagination from "components/Pagination/index.jsx";
import { LINK_API } from 'constants/API.js';
import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ReactTable from 'react-table-6';
import SubTable from "components/SubTable/table.js";
import Header from "components/Headers/Header";
import { Card, Container, Modal, ModalHeader, ModalFooter, ModalBody, Button, UncontrolledTooltip, CustomInput } from 'reactstrap';
import TextareaAutosize from 'react-textarea-autosize';
import cookie from 'react-cookies';
import DateTimePicker from 'react-datetime-picker';
import DropZone from "components/dropZone/imageForDiary";
import Loading from "components/alert/loading";
import EditIcon from "mdi-react/PenIcon";
import Success from "components/alert/success"
import Error from "components/alert/error";
import RemoveIcon from "mdi-react/DeleteIcon";
import ConfirmAlert from "components/alert/confirmAlert"
// import "../../css/reactTable.css";

function StampList(props) {
  const [diaryDetail, setDiaryDetail] = React.useState([])
  const [loadingState, setLoading] = React.useState(true)
  const uuidCrop = cookie.load("uuidLedger");
  const [uuidDiaryCurrent, setUuidDiaryCurrent] = React.useState("")
  const [mobileStep, setMobile] = React.useState([]);
  const [datePicker, onDateChange] = React.useState(new Date());
  const [modal, setModal] = React.useState(false);
  const [listImage, setImage] = React.useState([]);
  const [imageDelete, setImageDelete] = React.useState([]);
  const uuidOwner = cookie.load("uuidOwner");
  const [alert, setAlert] = React.useState(null);
  const [options, setOptions] = React.useState([]);
  const { t } = props;

  const {
    userInfo
  } = props;


  const onPicker = (date) => {
    onDateChange(date)
  }

  const toggleModal = () => {
    setMobile([])
    setImage([])
    setModal(!modal)
  }

  const handleChangeInput = (index) => (event) => {
    let tempValue = event.target.value;

    let x = mobileStep.map((item, idx) => {
      if (idx === index) {
        return {
          ...item,
          value: tempValue
        }
      } else {
        return item
      }
    })
    setMobile(x)
  }

  const handleEdit = async (step, images, uuid, detail) => {
    const detailParse = detail.split("\n")
    let listValue = []
    const listLabel = detailParse.map(item => {
      listValue.push(item.split(": ")[1])
      return item.split(": ")[0]
    }
    )

    setUuidDiaryCurrent(uuid)
    const dataImage = images.map(item => item.url)
    let x = step.method.map((item, idx) => {
      let value = item.defaultValue ? item.defaultValue : "";
      const indexItem = listLabel.indexOf(item.label);
      if (indexItem !== -1) {
        value = listValue[indexItem]
      }
      return {
        ...item,
        value: value
      }
    })

    setMobile(x)
    setImage(dataImage)
    setModal(true)
  }

  const handleChangeStatus = (ownedBy, hide, id) => {
    const data = {
      hideList: [
        {
          [id]: hide
        }
      ],
      ownedBy: ownedBy
    }
    axios.post(LINK_API.ADMIN_HIDE_DIARY, data)
      .then(async res => {
        getListDiary();
        setAlert(<Success onClose={() => setAlert(null)} hideCancel={true} onConfirm={() => setAlert(null)} confirmBtnText={t('close')} />)
      })
      .catch(err => {
        setAlert(<Error onClose={() => setAlert(null)} confirmBtnText={t('close')} />)
      })
  }

  const confirm = (id, ownedBy) => {
    axios.delete(LINK_API.ADMIN_DELETE_DIARY + "diaryId=" + id + "&ownedBy=" + ownedBy)
      .then(async res => {
        getListDiary();
        setAlert(<Success onClose={() => setAlert(null)} hideCancel={true} onConfirm={() => setAlert(null)} confirmBtnText={t('close')} />)
      })
      .catch(err => {
        setAlert(<Error onClose={() => setAlert(null)} confirmBtnText={t('close')} />)
      })
  }

  const handleDelete = (id, ownedBy) => {
    let ChildComponent = <div>{"Bạn chắc chắn xoá nhật ký này"}</div>
    setAlert(
      <ConfirmAlert
        onClose={() => setAlert(null)}
        onConfirm={() => confirm(id, ownedBy)}
        confirmBtnText={t('confirm')}
        child={ChildComponent}
      />
    )
  }

  const getListDiary = () => {
    try {
      axios.defaults.headers.common.Authorization = userInfo.data.token
      axios({
        method: 'GET',
        url: LINK_API.ADMIN_GET_DIARY_GROUP_BY_LEDGER + "uuid=" + uuidCrop + "&ownedBy=" + uuidOwner
      }).then((response) => {
        if (response && response.data && response.data.data) {
          if (response.data && response.data.data && response.data.data[0] && response.data.data[0].DiaryInfo) {
            let dataRender = response.data.data[0].DiaryInfo.map((item, index) => {
              return {
                step: item.step.name,
                process: item.processName,
                detailInfo: item.step_attribute.split("\n").map(item => {
                  return <div>{item}</div>
                }),
                status: <div style={{ marginLeft: "10px" }}>
                  <CustomInput
                    type="select"
                    placeholder={t('filter.name')}
                    value={item.hide}
                    onChange={(e) => e.target.value == "false" ? handleChangeStatus(response.data.data[0].ownedBy, false, item._id) : handleChangeStatus(response.data.data[0].ownedBy, true, item._id)}
                  >
                    <option value={false}>Hiển thị</option>
                    <option value={true}>Ẩn</option>
                  </CustomInput>
                </div>,
                image: <div className="d-flex justify-content-center">
                  {item.images.map(prop => {
                    return <img src={prop.url} style={{ height: "50px", marginRight: 10 }} />
                  })}</div>,
                actions: <>
                  <Button onClick={() => handleEdit(item.step.mobile, item.images, item.uuid, item.step_attribute)} color="neutral-success" className="mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center" id={"detail" + index}>
                    <span className="btn-wrapper--icon d-flex">
                      <EditIcon
                        size={18}
                        className="text-success font-size-sm"
                      />
                    </span>
                  </Button>
                  <UncontrolledTooltip trigger="hover" popperClassName="tooltip-black" placement="top" container="body" target={"detail" + index}>
                    Chỉnh sửa
                  </UncontrolledTooltip>
                  <Button onClick={() => handleDelete(item._id, response.data.data[0].ownedBy)} color="neutral-success" className="mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center" id={"delete" + index}>
                    <span className="btn-wrapper--icon d-flex">
                      <RemoveIcon
                        size={18}
                        className="text-info font-size-sm"
                      />
                    </span>
                  </Button>
                  <UncontrolledTooltip trigger="hover" popperClassName="tooltip-black" placement="top" container="body" target={"delete" + index}>
                    Xoá
                  </UncontrolledTooltip>
                </>
              }
            })
            setDiaryDetail(dataRender)
          }
          setLoading(false)
        }
        else {
          setDiaryDetail([])
          setLoading(false)
        }
      })
        .catch(() => {
          setDiaryDetail([])
          setLoading(false)
        })
    }
    catch {
      setLoading(false)
    }
  }
  useEffect(() => {
    setLoading(true)
    getListDiary()
  }, [props.date, userInfo.data.token]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Tên bước",
        accessor: "step",
        sortable: false
      },
      {
        Header: "Quy trình",
        accessor: "process",
        filterable: false
      },
      {
        Header: "Thông tin chi tiết",
        accessor: "detailInfo",
        filterable: false
      },
      {
        Header: "Hình ảnh",
        accessor: "image",
        filterable: false
      },
      {
        Header: "Trạng thái trên trang TXNG",
        accessor: "status",
        filterable: false
      },
      {
        Header: "Hành động",
        accessor: "actions",
        filterable: false
      },
    ],
    [t]
  );

  const handleChangeImage = (data) => {
    setImage(data);
  }


  const handleChangeInfo = () => {
    setAlert(<Loading />);
    toggleModal();

    let detail = "";
    let haveInput = false
    for (let i = 0; i < mobileStep.length; i++) {
      if (i > 0 && i < mobileStep.length - 2) {
        haveInput = true;
        if (mobileStep[i].value) {
          detail = detail + mobileStep[i].label + ": " + mobileStep[i].value + "\n"
        }
      }
    }

    const listImageAdd = listImage.filter(item => !item.includes("https://res.cloudinary.com") && !item.includes("http://res.cloudinary.com"))

    if (haveInput) {
      const data = {
        uuid: uuidDiaryCurrent,
        ownedBy: uuidOwner,
        detail: detail,
      }

      axios.put(LINK_API.ADMIN_UPDATE_BASE_INFO_DIARY, data)
        .then(() => {
          if (listImageAdd.length > 0 || imageDelete.length > 0) {
            const dataImage = {
              addImageList: listImageAdd,
              delImageList: imageDelete,
              uuid: uuidDiaryCurrent,
              ownedBy: uuidOwner,
            }

            axios.put(LINK_API.ADMIN_UPDATE_IMAGE_DIARY, dataImage)
              .then(() => {
                getListDiary()
                setAlert(<Success onConfirm={() => {
                  setAlert(null)
                }} hideCancel={true} confirmBtnText={t('confirm')} />)
              })
              .catch(() => {
                setAlert(<Error onClose={() => setAlert(null)} confirmBtnText={t('confirm')} />)
              })

          }
          else {
            getListDiary()
            setAlert(<Success onConfirm={() => {
              setAlert(null)
            }} hideCancel={true} confirmBtnText={t('confirm')} />)
          }
        })
        .catch(err => {
          setAlert(<Error onClose={() => setAlert(null)} confirmBtnText={t('confirm')} />)
        })

    }
    else {
      if (listImageAdd.length > 0 || imageDelete.length > 0) {
        const dataImage = {
          addImageList: listImageAdd,
          delImageList: imageDelete,
          uuid: uuidDiaryCurrent,
          ownedBy: uuidOwner,
        }

        axios.put(LINK_API.ADMIN_UPDATE_IMAGE_DIARY, dataImage)
          .then(() => {
            getListDiary()
            setAlert(<Success onConfirm={() => {
              setAlert(null)
            }} hideCancel={true} confirmBtnText={t('confirm')} />)
          })
          .catch(() => {
            setAlert(<Error onClose={() => setAlert(null)} confirmBtnText={t('confirm')} />)
          })

      }
      else {
        setAlert(null)
      }
    }

  }

  const handleDeleteImage = (data) => {
    setImageDelete(data);
  }

  return (
    <>
      <Header></Header>
      <Container className='mt--7' fluid>
        <Card className="card-box mb-5">
          <div className="card-header py-3">
            <div className=" font-weight-bold card-header--title font-size-lg">{"Danh sách nhật ký truy xuất"}</div>
            <div className="card-header--actions">
            </div>
          </div>
          <div className="divider" />
          <div className="card-body pt-2 px-4 pb-4">
            <ReactTable
              noDataText={t('noData')}
              data={diaryDetail}
              columns={columns}
              previousText={"<"}
              nextText={">"}
              rowsText={t('row')}
              ofText="/"
              defaultPageSize={5}
              showPaginationBottom={true}
              sortable={false}
              resizable={true}
              PaginationComponent={Pagination}
              className="-striped -highlight"
              loading={loadingState}
              LoadingComponent={LoadingTable}
            />
          </div>
        </Card>
      </Container>
      <Modal isOpen={modal} zIndex={2000} centered toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>{"Chỉnh sửa nhật ký"}</ModalHeader>
        <ModalBody>
          {mobileStep.map((item, idx) => (
            idx > 0 && idx < mobileStep.length - 2 ?
              <div key={idx}>
                <label className="font-weight-bold">{item.label}</label>
                <TextareaAutosize className="form-control" style={{ maxRows: 5, boxSizing: 'border-box' }}
                  maxRows={5}
                  maxLength={1000}
                  placeholder={item.placeholder} value={item.value} onChange={handleChangeInput(idx)}
                />
              </div>
              : null
          ))}
          {mobileStep.length > 0 ?
            <div>
              <label className="font-weight-bold">Hình ảnh </label>
              <DropZone
                max={3}
                sizeOut={100}
                sizeIn={50}
                mLeft={50}
                mTop={-31}
                sizeIcon={50}
                data={listImage}
                listDelete={imageDelete}
                onDeleteImage={handleDeleteImage}
                onConfirm={handleChangeImage}
              />
            </div> : null}
        </ModalBody>
        <ModalFooter>
          <Button disabled={mobileStep.length > 0 ? false : true} color="primary" onClick={handleChangeInfo}>{t('confirm')}</Button>{' '}
          <Button color="danger" outline onClick={toggleModal}>{t('cancel')}</Button>
        </ModalFooter>
      </Modal>
      {alert}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
    allScanStamp: state.allScanStamp,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getAllScanStamp: (data) => dispatch({ type: "GET_ALL_SCAN_STAMP", data: data }),
});

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(StampList));