import Axios from "axios";
import Error from "components/alert/error";
import Loading from "components/alert/loading";
import Success from "components/alert/success";
import { LINK_API } from "constants/API";
import { useFormik } from "formik";
import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  Button,
} from "reactstrap";
import "view/css/select.css";
import * as Yup from "yup";
import { convertToRaw, EditorState, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { Editor } from "react-draft-wysiwyg";

const Contact = React.forwardRef((props, ref) => {
  const t = props.t;
  const portalSetting = props.portalSetting;
  const [alert, setAlert] = React.useState(null);
  const [isChange, setChange] = React.useState(false);
  const [editorState, setEditor] = React.useState(EditorState.createEmpty());
  const [logo, setLogo] = React.useState([]);
  const [id, setId] = React.useState("")
  
  const formik = useFormik({
    initialValues: {
     contact: ""
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Địa chỉ email không hợp lệ"),
      // phone: Yup.string().matches(phone_regex, "Số điện thoại không hợp lệ"),
    }),
    validateOnChange: true,
    onSubmit: async (values) => {
      handleSaveData();
    },
  });

  React.useEffect(() => {
    if (portalSetting) {
      if (portalSetting.logo) {
        setLogo(portalSetting.logo);
      }
      setId(portalSetting.uuid)
      formik.setValues({
        contact: portalSetting.contact,
      });

      if (portalSetting.contact) {
        let dynamicContent = portalSetting.contact;
        let contentBlock = htmlToDraft(dynamicContent);
        
        if (
          contentBlock &&
          portalSetting.contact &&
          portalSetting.contact !== ""
        ) {
          let contentState = ContentState.createFromBlockArray(
            contentBlock.contentBlocks
          );
          let editorState = EditorState.createWithContent(contentState);
          setEditor(editorState);
        }
      }
    }
  }, [portalSetting]);

  const handleChangeEditor = async (editorState) => {
    // console.log("editorState 1", editorState)
    setEditor(editorState);
    setChange(true);
  };

  const hideAlert = () => {
    setChange(false);
    setAlert(null);
    if (props.onChange) {
      props.onChange(false);
    }
  };
  const handleSaveData = async () => {
    setAlert(<Loading />);
    let contact = await draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    contact = await contact.toString();
    contact = await contact.replace("↵", "");

    let data = {
      // /email: formik.values.email,
      contact,
    };

    Axios.put(`${LINK_API.PORTAL_SETTING}?id=${id}`, data)
      .then((res) => {
        if (res.data.errorCode === 1) {
          setAlert(
            <Success
              onConfirm={handleConfirm}
              hideCancel={true}
              confirmBtnText={t("confirm")}
            />
          );
        } else {
          setAlert(<Error onClose={hideAlert} confirmBtnText={t("close")} />);
        }
      })
      .catch((err) => {
        setAlert(<Error onClose={hideAlert} confirmBtnText={t("close")} />);
      });
  };
  const handleConfirm = () => {
    hideAlert();
    props.getPortalSetting();
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="p-4">
          <div className="form-row">
            <div className="form-group col-md-8 text-left mb-0">
              <h5 className="font-size-xl font-weight-bold">
                {t("portal.contactSetting")}
              </h5>
              <p className="text-black-50 mb-0">
                {t("portal.aboutContact")}
              </p>
            </div>
            {isChange ? (
              <div className="form-group col-md-4 text-right mb-0">
                <Button color="info" type="submit">
                  {t("save")}
                </Button>
              </div>
            ) : null}
          </div>

          <div className="divider my-4" />

          <div className="form-row">
            <div className="form-group col-md-12">
              <label className="font-weight-bold">{t("portal.contact")}</label>
              <Editor
                editorState={editorState}
                wrapperClassName="demo-wrapper"
                editorClassName="demo-editor borderEditor"
                onEditorStateChange={handleChangeEditor}
                toolbar={{
                  options: ["inline", "image", "embedded", "textAlign", "link"],
                }}
              />
            </div>
          </div>
        </div>
      </form>
      {alert}
    </>
  );
});
const mapStateToProps = (state) => {
  return {
    portalSetting: state.portalSetting.infoPortal,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getPortalSetting: () => dispatch({ type: "GET_PORTAL_SETTING" }),
  };
};
export default withTranslation("common", { withRef: true })(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    Contact
  )
);
