import {
  GET_ALL_BOX,
  GET_ALL_BOX_SUCCESS,
  GET_ALL_BOX_FAILURE,
} from "actions/types"
import { takeLatest, call, put, select } from 'redux-saga/effects'
import { LINK_API } from 'constants/API.js'
import axios from 'axios'

const getToken = (state) => state.userInfo

// Get all box to receive
function * getAllBox () {
  try {
    const tokenInfo = yield select(getToken)
    const response = yield call(fetchAllBox, tokenInfo.data.token)
    const data = response.data
    yield put({ type: GET_ALL_BOX_SUCCESS, data: data.data, errorCode: data.errorCode })
  } catch (error) {
    yield put({ type: GET_ALL_BOX_FAILURE, payload: '', errorCode: 0 })
  }
}
function fetchAllBox (checktoken) {
  axios.defaults.headers.common.Authorization = checktoken
  return axios({
    method: 'GET',
    url: LINK_API.GET_ALL_IMPORT_LIST
  })
}

const handleImportList = [
  takeLatest(GET_ALL_BOX, getAllBox)
]

export default handleImportList;
