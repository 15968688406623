import React from "react";
import { useDropzone } from 'react-dropzone';
import DownloadIcon from 'mdi-react/FileFindIcon'
import { withTranslation } from 'react-i18next';
import "./css/dropZone.css";
import { Button } from "reactstrap";
import "./index.css"

const DropZone = (props) => {
  const maxImage = props.max;
  const [list, setList] = React.useState(props.data || []);
  const {
    isDragActive, getRootProps, getInputProps } = useDropzone({
      accept: '.pdf, .doc, .docx, .xlsx, .xlsm, .xls',
      multiple: true,
      onDrop: async acceptedFiles => {
        let temp = await acceptedFiles.map(file => Object.assign(file, {
          preview: URL.createObjectURL(file)
        }))
        setList([...list, temp[0]])
        if (props.onConfirm) {
          props.onConfirm([...list, temp[0]])
        }
      }
    });

  const handleDelete = (index) => {
    const temp = list.filter((item, idx) => {
      if (index !== idx) return item;
      if (props.onDelete && props.isEdit) {
        props.onDelete(item)
      }
    })

    if (temp && temp.length > 0) {
      setList([...temp])
    }
    else {
      setList([])
    }
  }

  React.useEffect(() => {
    if (props.data && props.data.length > 0) {
      setList(props.data)
    }
    else {
      setList([])
    }
  }, [props.data])

  const handleDownload = (data) => {
    if (data.preview) {
      window.open(data.preview)
    }
    else {
      const blobData = new Blob([new Uint8Array(data.buffer)], { type: 'application/pdf' });
      var fileURL = URL.createObjectURL(blobData);
      window.open(fileURL)
    }
  }

  return (
    <>
      <div className="mt-1">
        {list.map((item, index) => {
          return <div className="d-flex styleFile">
            <div style={{ width: "90%", fontSize: 12, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{item.name}</div>
            <Button color="link" className="d-flex justify-content-end p-0 mr-0" style={{ width: "5%" }} onClick={() => handleDownload(item)}>
              <DownloadIcon size="17" />
            </Button>
            <Button color="link" className="d-flex justify-content-end p-0" onClick={() => handleDelete(index)} style={{ width: "5%" }} ><div style={{ color: "red", fontSize: 12 }}>X</div></Button>
          </div>
        })}
        {list.length < maxImage ?
          <div>
            <div {...getRootProps({ className: 'dropzone-upload-wrapper' })}>
              <input {...getInputProps()} />
              <div>
                {!isDragActive && (
                  <Button color="first" size="sm">
                    Chọn tập tin
                  </Button>
                )}
              </div>
            </div>
          </div>
          : null}
      </div>
    </>
  )
}
export default withTranslation('common')(DropZone);