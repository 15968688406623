import axios from "axios";
import Error from 'components/alert/error';
import Loading from 'components/alert/loading';
import Success from 'components/alert/success';
import DropZone from "components/dropZone";
import Header from "components/Headers/Header";
import { LINK_API } from "constants/API";
//formik
import { useFormik } from 'formik';
import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Button, Card, Container, CustomInput, Input } from "reactstrap";
import Select from "react-select"
//style
import "view/css/select.css";
import * as Yup from 'yup';
import DateTimePicker from 'react-datetime-picker'

const ethers = require('ethers');
const CryptoJS = require('crypto-js')
const EthereumTx = require('ethereumjs-tx');
const CreateEmployee = (props) => {
  const t = props.t;
  const userInfo = props.userInfo
  const [alert, setAlert] = React.useState(null)
  const [listImage, setImage] = React.useState([])
  const getBoxList = props.getBoxList;
  const boxList = props.boxList
  const [listBox, setList] = React.useState([])
  const [boxSelected, setSelected] = React.useState('')
  const [harvestTime, setHarvestTime] = React.useState(new Date());
  React.useEffect(() => {
    getBoxList();
  }, [getBoxList])
  React.useEffect(() => {
    setList(boxList)
    setSelected(boxList[0])
  }, [boxList])
  const handleAva = (data) => {
    setImage(data);
  }
  const formik = useFormik({
    initialValues: {
      id: '',
      description: '',
      type: '',
      vehicleNo: ''
    },
    validationSchema: Yup.object({
      // id: Yup.string()
      //   .required(t('require')),
      // quantity: Yup.string()
      //   .required(t('require')),
      // type: Yup.string()
      //   .required(t('require')),
      vehicleNo: Yup.string()
        .required(t('require')),
      description: Yup.string(),
    }),
    onSubmit: async (values) => {
      if(!values.id){
        console.log("values.id", values.id)
        setAlert(<Loading />);
        let data_signIn = {
          "detail": values.description,
          "description": values.type === 'EXPORT' ? "Đây là quá trình xuất hàng khỏi chợ" : 'Đây là quá trình nhập hàng vào chợ',
          "images": listImage,
          "quantity": values.quantity,
          "type": "IMPORT",
          "vehicleNo": values.vehicleNo,
          "date": Date.parse(harvestTime)
        }
        console.log("data_signIn", data_signIn)
        axios.post(LINK_API.IMPORT_NO_DIARY, data_signIn)
          .then(res => {
            console.log("res", res)
            setAlert(<Success onClose={hideAlert} hideCancel={true} onConfirm={reloadData} confirmBtnText={t('close')} />)
          })
          .catch(err => {
            console.log("res", err)
            setAlert(<Error onClose={hideAlert} confirmBtnText="Đóng" />)
          })
      }
      else{
        setAlert(<Loading />);
        let cropId = await getIdOwner(values.id)
        let data_signIn = {
          "toId": cropId,  // id của sản phẩm
          "detail": values.description,
          "description": values.type === 'EXPORT' ? "Đây là quá trình xuất hàng khỏi chợ" : 'Đây là quá trình nhập hàng vào chợ',
          "images": listImage,
          "quantity": values.quantity,
          "type": "IMPORT",
          "vehicleNo": values.vehicleNo,
          "ownerOfCrop": values.id.substring(0, 7),
          "date": Date.parse(harvestTime)
        }
        axios.post(LINK_API.CREATE_GUARD_DIARY, data_signIn)
          .then(res => {
            if (res.data.errorCode === 1) {
              // setAlert(<Success onClose={hideAlert} hideCancel={true} onConfirm={reloadData} confirmBtnText={t('close')} />)
              handleSendRaw(res.data.data)
            } else {
              setAlert(<Error onClose={hideAlert} title={res.data.message} confirmBtnText="Đóng" />)
            }
          })
          .catch(err => {
            setAlert(<Error onClose={hideAlert} confirmBtnText="Đóng" />)
          })
      }
      

    },
  });
  const handleSendRaw = (dataRaw) => {
    var bytes = CryptoJS.AES.decrypt(userInfo.data.privateEncrypted.toString(), userInfo.password)
    const decrypt_privatekey = bytes.toString(CryptoJS.enc.Utf8)
    const privatekey_slice = decrypt_privatekey.substring(2, decrypt_privatekey.length)
    const Buffer_privatekey = Buffer.from(privatekey_slice.toString(), 'hex')
    const tx = new EthereumTx(dataRaw.raw, { chain: 4 });
    tx.sign(Buffer_privatekey)
    const rawTx = '0x' + tx.serialize().toString('hex');
    let data = {
      ...dataRaw,
      raw: rawTx,
    }
    axios.post(LINK_API.SEND_RAW_IMPORTED, data)
      .then(res => {
        if (res.data.errorCode === 1) {
          setAlert(<Success onClose={hideAlert} hideCancel={true} onConfirm={reloadData} confirmBtnText={t('close')} />)
        } else {
          setAlert(<Error onClose={hideAlert} confirmBtnText="Đóng" />)
        }
      })
      .catch(err => {
        setAlert(<Error onClose={hideAlert} confirmBtnText="Đóng" />)
      })
  }
  const getIdOwner = async (id) => {

    let owner = id.substring(0, 7)
    let idObject = id.substring(7, id.length)
    let responseData = await axios.get(LINK_API.GET_CROP_LIST + idObject + '&ownedBy=' + owner);
    let dataRes = responseData.data.data;
    let uuid = dataRes[0].uuid;
    return uuid
  }
  const reloadData = () => {
    props.history.push('/sadmin/guard')
  }
  const hideAlert = () => {
    setAlert(null)
  }

  return (
    <>
      <Header></Header>
      <Container className=' mt--7' fluid>
        <Card className="card-box mb-5">
          <div className="wizard-steps horizontal">
            <form onSubmit={formik.handleSubmit}>
              <div className="p-4">
                <h5 className="font-size-xl font-weight-bold">Xuất/nhập hàng</h5>
                <p className="text-black-50 mb-4">Thông tin xuất/nhập hàng hóa</p>
                <div className="form-row ">
                  <div className="form-group col-md-12 pr-3">
                    <div className="form-group">
                      <label className="font-weight-bold">Hành động<span className="text-danger">(*)</span></label>
                      <CustomInput
                        type="select" id="selectUnit"
                        value={formik.values.type}
                        name="type"
                        disabled
                        valid={formik.touched.type && formik.errors.type ? false : (formik.values.type ? true : false)}
                        invalid={formik.touched.type && formik.errors.type ? true : false}
                        {...formik.getFieldProps('type')}
                      >
                       <option value={'IMPORT'}>Nhập hàng</option>
                        <option value={'EXPORT'}>Xuất hàng</option>
                      </CustomInput>
                      {formik.touched.type && formik.errors.type ? (
                        <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.type}</p>
                      ) : null}
                    </div>

                    <div className="form-group">
                      <label className="font-weight-bold">Biển số xe<span className="text-danger">(*)</span></label>
                      <Input
                        placeholder='Biển số xe'
                        type="text"
                        name="vehicleNo"
                        value={formik.values.vehicleNo}
                        valid={formik.touched.vehicleNo && formik.errors.vehicleNo ? false : (formik.values.vehicleNo ? true : false)}
                        invalid={formik.touched.vehicleNo && formik.errors.vehicleNo ? true : false}
                        {...formik.getFieldProps('vehicleNo')}

                      />
                      {formik.touched.vehicleNo && formik.errors.vehicleNo ? (
                        <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.vehicleNo}</p>
                      ) : null}
                    </div>


                    <div className="form-group">
                      <label className="font-weight-bold">Mã sản phẩm</label>
                      <Select
                        placeholder="Chọn mã sản phẩm"
                        options={listBox}
                        value={boxSelected}
                        onChange={(selectedOption) => {
                          setSelected(selectedOption);
                          formik.setFieldValue('id', selectedOption.value)
                        }}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: '0.29rem',
                          borderWidth: 0.5,
                          height: 30,
                          colors: {
                            ...theme.colors,
                            primary25: 'rgba(60,68,177,0.15)',
                            primary50: 'rgba(60,68,177,0.15)',
                            primary: '#50b77a'
                          }
                        })}
                      />
                      {formik.touched.id && formik.errors.id ? (
                        <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.id}</p>
                      ) : null}
                    </div>
                    
                    <div className="form-group">
                      <label className="font-weight-bold">Số lượng (tấn)</label>
                      <Input
                        placeholder='Số lượng'
                        type="number"
                        name="quantity"
                        value={formik.values.quantity}
                        valid={formik.touched.quantity && formik.errors.quantity ? false : (formik.values.quantity ? true : false)}
                        invalid={formik.touched.quantity && formik.errors.quantity ? true : false}
                        {...formik.getFieldProps('quantity')}
                      />
                      {formik.touched.quantity && formik.errors.quantity ? (
                        <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.quantity}</p>
                      ) : null}
                    </div>
                    <div className="form-group">
                      <label className="font-weight-bold"> Ghi chú</label>
                      <Input
                        placeholder='Ghi chú'
                        type="text"
                        name="description"
                        value={formik.values.description}
                        valid={formik.touched.description && formik.errors.description ? false : (formik.values.description ? true : false)}
                        invalid={formik.touched.description && formik.errors.description ? true : false}
                        {...formik.getFieldProps('description')}

                      />
                      {formik.touched.description && formik.errors.description ? (
                        <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.description}</p>
                      ) : null}
                    </div>
                    

                    <div className="form-group">
                      <label className="font-weight-bold"> Thời gian </label>
                      <DateTimePicker
                        className='form-control'
                        onChange={setHarvestTime}
                        value={harvestTime}
                        format="dd/MM/y HH:mm"
                        disableClock={true}
                        disableCalendar={true}
                      />
                      {harvestTime ? null : (
                        <p className="font-weight-regular font-size-sm text-danger" >Thông tin bắt buộc</p>
                      )}
                    </div>

                    <div className="form-group" >
                      <label className="font-weight-bold">Hình ảnh</label>
                      <DropZone
                        max={2}
                        onConfirm={handleAva}
                        data={listImage}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-row ">
                  <div className="form-group col-md-6">
                    <p><span className="text-danger">(*)</span> Trường thông tin bắt buộc</p>
                  </div>

                  <div className="form-group col-md-6 d-flex justify-content-end">
                    <Button
                      size="lg"
                      type="submit"
                      className="text-uppercase font-weight-bold font-size-sm"
                      color="info">
                      Tạo mới
                   </Button>
                  </div>
                </div>

              </div>

            </form>
          </div>
        </Card>
      </Container>
      {alert}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
    areaList: state.renderArea,
    objectList: state.objectList,
    boxList: state.boxSystem.options
  }
}

const mapDispatchToProps = (dispatch) => ({
  createEmployee: (data, image) => dispatch({ type: "CREATE_EMPLOYEE", data: data, avatar: image }),
  renderArea: (page, size) => dispatch({ type: "RENDER_AREA_LIST", page: page, size: size }),
  getObjectList: (page, size) => dispatch({ type: "GET_OBJECT_LIST", page: page, size: size }),
  getBoxList: () => dispatch({ type: "GET_ALL_BOX_SYSTEM" }),
});

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(CreateEmployee));
