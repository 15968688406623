import clsx from 'clsx';
import React from "react";
import { withTranslation } from 'react-i18next';
import Loki from 'react-loki';
import { connect } from 'react-redux';
import Step1 from "./steps/stepOne";
import Step2 from "./steps/stepTwo";
import Header from "components/Headers/Header"
import { Container, Card } from "reactstrap"
function CreateProduct(props) {
    const t = props.t;
    const permission = props.permission.data;

    const [state, setState] = React.useState({
    })
    const _mergeValues = (data) => {
        setState({
            ...state,
            ...data
        })
        console.log("data", data)
        // _onFinish()

    }
    const customSteps = [
        {
            label: t("description"),
            number: '1',
            description: t('area.titleDes'),
            component: <Step1 data={state} />,
        },
        {
            label: t('settingAdvanced'),
            number: '2',
            description: t('area.setting'),
            component: <Step2 data={state} mergeData={_mergeValues} />,
        },
    ];

    const _customRenderer = ({ currentStep, nextHandler, stepIndex, isInFinalStep }) => {
        const steps = customSteps.map((step, index) => {
            const isActive = currentStep === index + 1;
            return <li key={index} className={clsx("card-box w-50 p-3",  { 'current-tab-loki': isActive })}><a href="# " onClick={(e) => e.preventDefault()}>
                <div className="step-indicator ">
                    <div className="d-flex">
                        <div className="font-size-lg d-44 rounded-sm mr-2 stepper-wrapper text-center">
                            <span className="font-weight-bold">{step.number}</span>
                        </div>
                        <div>
                            <div className="font-weight-bold">{step.label}</div>
                            <small className="text-black-50">{step.description}</small></div>
                    </div>
                </div>
            </a></li>
        });

        return <div className="horizontal">
            <ul className="steps-indicator d-flex">{steps}</ul>
        </div>;
    };



    const _onFinish = (data) => {
        let dataSunmit = {
            "name": data.nameArea,
            "gln": data.gln ? data.gln : '',
            "image": data.listImage,
            "type": data.type,
            "city": data.address,
            "managerId": data.employee,
            "district": "",
            "glnCheckbox": data.gln ? true : false,
            "description": data.description,
            "isShowOnPortal": data.portal === "true" ? true : false
        }
        props.createArea(dataSunmit)
        props.history.push('/user/area')
    };

    return (
        <>
            <Header />
            <Container className='mt--7' fluid>
                <Card className="card-box mb-5">
                    {permission.agdqr ? <div> </div> :
                        <div className="wizard-alternate-2 horizontal">
                            {/* <Loki
                                steps={customSteps}
                                renderSteps={_customRenderer}
                                onNext={_mergeValues}
                                //onBack = {_mergeValues}
                                onFinish={_onFinish}
                                noActions /> */}
                                <Step1 data={state} onNext={_onFinish}/>
                        </div>
                    }
                </Card>
            </Container>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        permission: state.permission
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        createArea: (data) => dispatch({ type: "CREATE_AREA", data: data }),
    }
}
export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(CreateProduct))