import {
    GET_NOTI_LIST,
    GET_NOTI_LIST_SUCCESS,
    GET_NOTI_LIST_FAILURE,
} from "actions/types"
import { takeLatest, call, put, select } from 'redux-saga/effects'
import { LINK_API } from 'constants/API.js'
import axios from 'axios'

const getToken = (state) => state.userInfo


//get price list for retailer
function* getNoti() {
    try {
        const tokenInfo = yield select(getToken)
        const response = yield call(fetchNoti, tokenInfo.data.token)
        const data = response.data
        yield put({ type: GET_NOTI_LIST_SUCCESS, data: data.data, errorCode: data.errorCode })
    } catch (error) {
        yield put({ type: GET_NOTI_LIST_FAILURE, payload: '', errorCode: 0 })
    }
}
function fetchNoti(checktoken) {
    axios.defaults.headers.common.Authorization = checktoken
    return axios({
        method: 'GET',
        url: LINK_API.GET_NOTI_LIST
    })
}


const price = [
    takeLatest(GET_NOTI_LIST, getNoti)
]
export default price

