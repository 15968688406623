const initialState = { data: [] }
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'RENDER_SELECT_CUSTOMER':
      return {
        ...state
      }
    case 'RENDER_SELECT_CUSTOMER_SUCCESSFULL':
      return {
        ...state,
        data: action.data
      }
    case 'RENDER_SELECT_CUSTOMER_FALURE':
      return {
        ...state,
        data: action.data
      }
    default:
      return state
  }
}
export default reducer
