import DropZone from "components/dropZone";
import { LINK_API } from "constants/API";
import download from "downloadjs";
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
//formik
import { useFormik } from 'formik';
import htmlToDraft from 'html-to-draftjs';
import * as htmlToImage from 'html-to-image';
import DownloadIcon from "mdi-react/DownloadIcon";
import HelpBoxIcon from "mdi-react/HelpBoxIcon";
import InfoIcon from "mdi-react/InfoCircleIcon";
import PrintIcon from "mdi-react/PrinterIcon";
import SearchIcon from "mdi-react/SearchIcon";
import React from "react";
import cookie from "react-cookies";
import { Editor } from 'react-draft-wysiwyg';
import { withTranslation } from 'react-i18next';
import QRCode from "react-qr-code";
import { connect } from "react-redux";
import ReactToPrint from 'react-to-print';
import { Alert, Button, CustomInput, Input, ListGroup, ListGroupItem, Modal, ModalBody, ModalFooter, ModalHeader, UncontrolledPopover } from "reactstrap";
//style
import "view/css/select.css";
import * as Yup from 'yup';
import PrintObject from "../printTemplate";

const Hashids = require('hashids/cjs')
const alphabet = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLNOQRSTUVWXYZ0123456789'

const CreateObjectProduction = React.forwardRef((props, ref) => {
  const urlPortal = process.env.REACT_APP_ENV === 'dev' ? 'https://etrace.kontum.vn/q/' : 'https://etrace.kontum.vn/q/'
  const t = props.t;
  const productListState = props.productList;
  const processListState = props.processList;
  const areaListState = props.areaList;
  const componentRef = React.useRef();
  const [editorState, setEditor] = React.useState(EditorState.createEmpty());
  const uuid = cookie.load("uuidOProduction");
  const {
    renderProductList,
    renderProcessList,
    renderAreaList,
    getShipList,
    shipList
  } = props;

  const [listProduct, setProduct] = React.useState([]);
  const [listProcess, setProcess] = React.useState([]);
  const [listArea, setArea] = React.useState([])
  const [listImage, setImage] = React.useState([]);
  // const [editorState, setEditor] = React.useState(EditorState.createEmpty());
  const [modal, setModal] = React.useState(false);
  const [listCert, setCert] = React.useState([]);
  const [listWholesale, setWholesaler] = React.useState([])
  const toggleModal = () => {
    setModal(!modal)
  }

  React.useEffect(() => {
    getShipList();
  }, [getShipList])
  const hashIdOfProductionObject = (uuid) => {
    if (!uuid) return null
    uuid = uuid.toString()
    if (uuid.length !== 11) return null
    if (uuid.length === 11 && uuid[0] === 'M') {
      uuid = uuid.substring(1, 11)
    }
    const hashids = new Hashids('', 8, alphabet)
    return 'M' + hashids.encode(uuid)
  }

  const downloadQR = async () => {
    document.getElementById("downloadBtn").innerHTML = "Đang tải...";
    var node = document.getElementById('qrObject');

    htmlToImage.toPng(node)
      .then(async function (dataUrl) {
        await download(dataUrl, 'qrCodeObject.png');
        toggleModal();
      })
      .catch(function (error) {
        console.error('oops, something went wrong!', error);
      });
  };
  const handleChangeEditor = async (editorState) => {
    document.getElementById("desNoti").innerHTML = "";
    setEditor(editorState)
  }
  const handleAva = (data) => {
    setImage(data);
    // document.getElementById("avaNoti").innerHTML = "";
  }

  const dataStepOne = props.data;
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      description: dataStepOne.description,
      price: dataStepOne.price,
      status: dataStepOne.status,
      gtin: dataStepOne.gln,
      number: dataStepOne.quantityOfPackage || 0,
      name: dataStepOne.name || '',
      product: dataStepOne.productType || '',
      area: dataStepOne.zoneUuid || '',
      process: dataStepOne.processCode || '',
      type: dataStepOne.type || '',
      wholesaler: dataStepOne.listWholeSalers && dataStepOne.listWholeSalers[0] ? dataStepOne.listWholeSalers[0] : '',
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Thông tin bắt buộc"),
    }),
    onSubmit: async (values) => {
      let dynamicContent = await draftToHtml(convertToRaw(editorState.getCurrentContent()))
            dynamicContent = await dynamicContent.toString()
            dynamicContent = await dynamicContent.replace("↵", '')

      const data = {
        "name": {
          "vi": values.name
        },
        "dynamicContent": {
          "vi": dynamicContent
      },
        images: listImage,
        zoneUuid: values.area,
        productType: values.product,
        processCode: values.process,
        gln: values.gtin,
        price: parseInt(values.price),
        unit: "VND",
        certificates: listCert,
        description: {
          "vi": values.description
        },
        type: 1,
        uuid: uuid
      }
      await props.updateObject(data);
    },
  });
  React.useEffect(() => {
    setWholesaler(shipList)
  }, [shipList])
  React.useEffect(() => {
    renderProductList(1, 100);
    renderProcessList();
    renderAreaList(1, 100);
  }, [renderProductList, renderProcessList, renderAreaList])

  React.useEffect(() => {
    if (productListState.data) {
      if (productListState.data.length > 0) {
        setProduct(productListState.data)
      }
    }
  }, [productListState])

  React.useEffect(() => {
    if (processListState.data) {
      if (processListState.data.length > 0) {
        setProcess(processListState.data)
      }
    }
  }, [processListState])

  React.useEffect(() => {
    if (areaListState.data) {
      if (areaListState.data.length > 0) {
        setArea(areaListState.data)
      }
    }
  }, [areaListState])

  React.useEffect(() => {

    let temp = [];
    if (dataStepOne.images) {
      for (let i = 0; i < dataStepOne.images.length; i++) {
        temp.push(dataStepOne.images[i])
      }
    }
    setImage(temp)

    let tempCert = [];
    if (dataStepOne.certificates) {
      for (let i = 0; i < dataStepOne.certificates.length; i++) {
        tempCert.push(dataStepOne.certificates[i])
      }
    }
    setCert(tempCert)

    if (dataStepOne.dynamicContent) {
      let dynamicContent = dataStepOne.dynamicContent;
      let contentBlock = htmlToDraft(dynamicContent);
      if (contentBlock && dataStepOne.dynamicContent && dataStepOne.dynamicContent !== "") {
          let contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
          let editorState = EditorState.createWithContent(contentState);
          setEditor(editorState)
      }
  }

  }, [dataStepOne])
  React.useImperativeHandle(ref, () => ({
    handleSaveData() {
      formik.submitForm()
    }
  }));

  const statusList = [
    {
      name: "Đang sản xuất",
      value: 1
    },
    {
      name: "Ngưng sản xuất",
      value: 1
    },
  ]


  const validateGtin = function (valueInt) {

    if (!valueInt) return true;
    const value = valueInt.toString();
    var barcode = value.substring(0, value.length - 1);
    var checksum = parseInt(value.substring(value.length - 1), 10);
    var calcSum = 0;
    var calcChecksum = 0;

    barcode.split('').map(function (number, index) {
      number = parseInt(number, 10);
      if (value.length % 2 === 0) {
        index += 1;
      }
      if (index % 2 === 0) {
        calcSum += number;
      }
      else {
        calcSum += number * 3;
      }
    });

    calcSum %= 10;
    calcChecksum = (calcSum === 0) ? 0 : (10 - calcSum);

    if (calcChecksum !== checksum) {
      return false;
    }

    return true;

  };

  const handleCert = (data) => {
    setCert(data)
  }

  return (
    <>

      <div className="wizard-steps horizontal">
        <form onSubmit={formik.handleSubmit}>
          <div className="p-4">
            <h5 className="font-size-xl font-weight-bold">{t('oProduction.oInfo')}</h5>
            <p className="text-black-50 mb-4">{t('oProduction.about')}</p>
            <div className="form-row">
              <div className="form-group col-md-9 pr-3 mb-0">
                <div className="form-row">
                  <div className="form-group col-md-6 pr-3">
                    <label className="font-weight-bold">{t('oProduction.name')} <span className="text-danger">(*)</span></label>
                    <Input
                      placeholder={t('oProduction.name')}
                      type="text"
                      maxLength={120}
                      value={formik.values.name}
                      valid={formik.touched.name && formik.errors.name ? false : (formik.values.name ? true : false)}
                      invalid={formik.touched.name && formik.errors.name ? true : false}
                      {...formik.getFieldProps('name')}
                    />
                    {formik.touched.name && formik.errors.name ? (
                      <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.name}</p>
                    ) : null}
                  </div>
                  <div className="form-group col-md-6 pl-3">
                    <label className="font-weight-bold">Mô tả</label>
                    <Input
                      type="text"
                      name="description"
                      id="numberBox"
                      placeholder='Mô tả'
                      value={formik.values.description}
                      valid={formik.touched.description && formik.errors.description ? false : (formik.values.description ? true : false)}
                      invalid={formik.touched.description && formik.errors.description ? true : false}
                      onChange={formik.handleChange}
                      {...formik.getFieldProps('description')}
                    >
                    </Input>
                  </div>
                </div>
                <div className="form-row ">
                  <div className="form-group col-md-6 pr-3">
                    <label className="font-weight-bold">{t('product.retailPrice')}</label>
                    <Input
                      placeholder={"Giá"}
                      type="number"
                      valid={formik.touched.price && formik.errors.price ? false : (formik.values.price ? true : false)}
                      invalid={formik.touched.price && formik.errors.price ? true : false}
                      value={formik.values.price}
                      {...formik.getFieldProps('price')}
                    />
                  </div>
                  <div className="form-group col-md-6 pl-3">
                    <label className="font-weight-bold" >{t('product.gtin')}</label>
                    <Input
                      validate={validateGtin}
                      placeholder={t('product.gtin')}
                      type="number"
                      valid={formik.touched.gtin && formik.errors.gtin ? false : (formik.values.gtin ? true : false)}
                      invalid={formik.touched.gtin && formik.errors.gtin ? true : false}
                      value={formik.values.gtin}
                      {...formik.getFieldProps('gtin')}
                    />
                    {formik.touched.gtin && formik.errors.gtin ? (
                      <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.gtin}</p>
                    ) : null}
                  </div>
                </div>
                <div className="form-row ">
                  <div className="form-group col-md-6 pr-3">
                    <label className="font-weight-bold">{t('oProduction.product')} <span className="text-danger">(*)</span></label>
                    <CustomInput
                      type="select"
                      name="customSelect"
                      id="selectType"
                      placeholder={t('oProduction.product')}
                      value={formik.values.product}
                      valid={formik.touched.product && formik.errors.product ? false : (formik.values.product ? true : false)}
                      invalid={formik.touched.product && formik.errors.product ? true : false}
                      onChange={formik.handleChange}
                      {...formik.getFieldProps('product')}
                    >
                      {listProduct.map((item, idx) => (
                        <option key={idx} value={item.value}>{item.name}</option>
                      ))}
                    </CustomInput>
                    {formik.touched.product && formik.errors.product ? (
                      <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.product}</p>
                    ) : null}
                  </div>
                  <div className="form-group col-md-6 pl-3">
                    <label className="font-weight-bold">{"Trạng thái hàng hoá"} <span className="text-danger">(*)</span></label>
                    <CustomInput
                      type="select"
                      name="customSelect"
                      id="selectStatus"
                      placeholder={"Trạng thái hàng hoá"}
                      value={formik.values.status}
                      valid={formik.touched.status && formik.errors.status ? false : (formik.values.status ? true : false)}
                      invalid={formik.touched.status && formik.errors.status ? true : false}
                      onChange={formik.handleChange}
                      {...formik.getFieldProps('status')}
                    >
                      {statusList.map((item, idx) => (
                        <option key={idx} value={item.value}>{item.name}</option>
                      ))}
                    </CustomInput>
                    {formik.touched.status && formik.errors.status ? (
                      <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.status}</p>
                    ) : null}
                  </div>
                </div>
                <div className="form-row ">
                  <div className="form-group col-md-6 pr-3">
                    <label className="font-weight-bold">{t('oProduction.area')} <span className="text-danger">(*)</span></label>
                    <CustomInput
                      type="select"
                      name="customSelect"
                      id="selectType"
                      placeholder={t('oProduction.area')}
                      value={formik.values.area}
                      valid={formik.touched.area && formik.errors.area ? false : (formik.values.area ? true : false)}
                      invalid={formik.touched.area && formik.errors.area ? true : false}
                      onChange={formik.handleChange}
                      {...formik.getFieldProps('area')}
                    >
                      {listArea.map((item, idx) => (
                        <option key={idx} value={item.value}>{item.label}</option>
                      ))}
                    </CustomInput>
                    {formik.touched.area && formik.errors.area ? (
                      <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.area}</p>
                    ) : null}
                  </div>
                  <div className="form-group col-md-6 pl-3">
                    <label className="font-weight-bold" >{t('oProduction.process')} <span className="text-danger">(*)</span></label>
                    <CustomInput
                      type="select"
                      name="customSelect"
                      id="selectType"
                      placeholder={t('oProduction.process')}
                      value={formik.values.process}
                      valid={formik.touched.process && formik.errors.process ? false : (formik.values.process ? true : false)}
                      invalid={formik.touched.process && formik.errors.process ? true : false}
                      onChange={formik.handleChange}
                      {...formik.getFieldProps('process')}
                    >
                      {/* <option  value="">{t('oProduction.process')}</option> */}
                      {listProcess.map((item, idx) => (
                        <option key={idx} value={item.value}>{item.name}</option>
                      ))}
                    </CustomInput>
                    {formik.touched.process && formik.errors.process ? (
                      <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.process}</p>
                    ) : null}
                  </div>
                </div>

              </div>

              <div className="form-group col-md-3 pl-3 mb-0">
                <div className="form-group">
                  <div className="d-flex flex-row">
                    <label className="font-weight-bold mr-1" >{t("qrCode")}</label>
                  </div>
                  <div className="w-100">
                    <div className="font-weight-bold sizeCode text-success" >{props.userInfo.uuid + uuid}</div>
                    <QRCode value={urlPortal + props.userInfo.uuid + props.data.uuid} size={200} />
                  </div>
                </div>
              </div>

            </div>
            <div className="form-row">
              <div className="form-group col-md-9 pr-3 mb-0">
                <div className="form-row">
                  <div className="form-group col-md-6 pr-3">
                    <div className="form-group">
                      <label className="font-weight-bold">{t('oProduction.image')} </label>
                      <div className="mt-3">
                        <DropZone
                          max={3}
                          onConfirm={handleAva}
                          data={listImage}
                          api={true}
                          path={LINK_API.UPDATE_PICTURE_OBJECT}
                          uuid={uuid}
                        />
                      </div>
                      <p id="avaNoti" className="font-weight-regular font-size-sm text-danger" ></p>
                    </div>
                  </div>
                  <div className="form-group col-md-6 pl-3">
                    <div className="form-group">
                      <label className="font-weight-bold">{t('product.cert')} </label>
                      <div className="mt-3">
                        <DropZone
                          max={3}
                          onConfirm={handleCert}
                          data={listCert}
                          api={true}
                          path={LINK_API.UPDATE_CERT_PRODUCT}
                          uuid={uuid}
                        />
                      </div>
                      <p id="avaNoti" className="font-weight-regular font-size-sm text-danger" ></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-row ">
                            <div className="form-group col-md-12">
                                <label className="font-weight-bold">{t('oProduction.description')}</label>
                                <Editor
                                    editorState={editorState}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor borderEditor"
                                    onEditorStateChange={handleChangeEditor}
                                    toolbar={{
                                        options: ["inline", 'image', 'embedded', 'textAlign', "link", ]
                                    }}
                                />
                                <p id="desNoti" className="font-weight-regular font-size-sm text-danger" ></p>
                            </div>
                        </div>
            <div className="form-row ">
              <div className="form-group col-md-6">
                <p><span className="text-danger">(*)</span> {t('require')}</p>
              </div>
            </div>
          </div>
        </form>
        <Modal zIndex={2000} centered isOpen={modal} toggle={toggleModal} className="widthFrame">
          <ModalHeader toggle={toggleModal}>{t('download')}</ModalHeader>
          <ModalBody>
            <div className="qrFrame" id="qrObject">
              &nbsp;
                            <div className="outSide">
                <QRCode value={urlPortal + hashIdOfProductionObject(props.userInfo.uuid + props.data.uuid)} size={165} className="qrMargin" />
              </div>
              <div className="inSide">
                <p className="text-white font-weight-bold font-size-xl">{props.data.name}</p>
                <p className="text-white font-weight-bold font-size-xl">{props.userInfo.uuid + props.data.uuid}</p>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="neutral-danger" className="btn-link-dark" onClick={toggleModal}>{t('cancel')}</Button>{' '}
            <Button id="downloadBtn" color="success" className="ml-auto" onClick={downloadQR}>{t('save')}</Button>
          </ModalFooter>
        </Modal>
      </div>
    </>
  )
})
const mapStateToProps = (state) => {
  return {
    productList: state.renderProduct,
    processList: state.renderProcess,
    areaList: state.renderArea,
    userInfo: state.userInfo.data,
    shipList: state.shipList.options

  }
}

const mapDispatchToProps = (dispatch) => ({
  renderProductList: (page, size) => dispatch({ type: "RENDER_PRODUCT_LIST", page: page, size: size }),
  renderAreaList: (page, size) => dispatch({ type: "RENDER_AREA_LIST", page: page, size: size }),
  renderProcessList: () => dispatch({ type: "RENDER_PROCESS_LIST" }),
  updateObject: (data) => dispatch({ type: "UPDATE_OBJECT_PRODUCTION", data: data }),
  getShipList: () => dispatch({ type: "GET_SHIP_LIST" })
});

export default withTranslation('common', { withRef: true })(connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(CreateObjectProduction));