import {
    GET_SHIP_LIST,
    GET_SHIP_LIST_SUCCESS,
    GET_SHIP_LIST_FAILURE
} from "actions/types"
import axios from 'axios'
import { LINK_API } from 'constants/API.js'
import { call, put, select, takeLatest } from 'redux-saga/effects'
  
  const getToken = (state) => state.userInfo
  


// Get approve list admin
function * getAdminList () {
  try {
    const tokenInfo = yield select(getToken)
    const response = yield call(fetchAdminList, tokenInfo.data.token)
    const data = response.data;
    let temp = [];
    for(let i = 0; i < data.data.length; i++){
      temp.push({
        label: data.data[i].fullname + " - " +  data.data[i].username ,
        value: data.data[i].uuid
      })
    }
    yield put({ type: GET_SHIP_LIST_SUCCESS, data: data.data, errorCode: data.errorCode , options: temp})
  } catch (error) {
    yield put({ type: GET_SHIP_LIST_FAILURE, payload: '', errorCode: 0 })
  }
}
function fetchAdminList (checktoken) {
  axios.defaults.headers.common.Authorization = checktoken
  return axios({
    method: 'GET',
    url: LINK_API.GET_SHIP_LIST
  })
}

  const actions = [
    takeLatest(GET_SHIP_LIST, getAdminList)
  ]
  export default actions
  