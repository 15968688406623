// Create
export const CREATE_PRODUCT = 'CREATE_PRODUCT'
export const CREATE_AREA = 'CREATE_AREA'
export const CREATE_OBJECT = 'CREATE_OBJECT'
export const CREATE_EMPLOYEE = 'CREATE_EMPLOYEE'
export const CREATE_PROCESS = 'CREATE_PROCESS'
export const CREATE_OBJECT_PROCESS = 'CREATE_OBJECT_PROCESS'
export const CREATE_BRANCH = 'CREATE_BRANCH'
export const CREATE_DISTRIBUTOR = 'CREATE_DISTRIBUTOR'

// Update banner
export const UPDATE_BANNER = 'UPDATE_BANNER'
export const UPDATE_BANNER_SUCCESS = 'UPDATE_BANNER_SUCCESS'
export const UPDATE_BANNER_FAILURE = 'UPDATE_BANNER_FAILURE'

// Update logo
export const UPDATE_LOGO = 'UPDATE_LOGO'
export const UPDATE_LOGO_SUCCESS = 'UPDATE_LOGO_SUCCESS'
export const UPDATE_LOGO_FAILURE = 'UPDATE_LOGO_FAILURE'

// Update avatar
export const UPDATE_AVATAR = 'UPDATE_AVATAR'
export const UPDATE_AVATAR_SUCCESS = 'UPDATE_AVATAR_SUCCESS'
export const UPDATE_AVATAR_FAILURE = 'UPDATE_AVATAR_FAILURE'

// Update certificate
export const UPDATE_CERTIFICATE = 'UPDATE_CERTIFICATE'
export const UPDATE_CERTIFICATE_SUCCESS = 'UPDATE_CERTIFICATE_SUCCESS'
export const UPDATE_CERTIFICATE_FAILURE = 'UPDATE_CERTIFICATE_FAILURE'

export const DELETE_CERTIFICATE = 'DELETE_CERTIFICATE'
export const DELETE_CERTIFICATE_SUCCESS = 'DELETE_CERTIFICATE_SUCCESS'
export const DELETE_CERTIFICATE_FAILURE = 'SUCCESS_CERTIFICATE_FAILURE'

// Notification
export const PUSH_NOTI_PRODUCT_SUCCESS = 'PUSH_NOTI_PRODUCT_SUCCESS'
export const DEACTIVE_NOTI_PRODUCT = 'PUSH_NOTI_PRODUCT'
export const PUSH_NOTI_PRODUCT_FAILURE = 'PUSH_NOTI_PRODUCT_FAILURE'
export const PUSH_NOTI_PRODUCT_PROCESSING = 'PUSH_NOTI_PRODUCT_PROCESSING'

// Branch List
export const GET_BRANCH_LIST = 'GET_BRANCH_LIST'
export const GET_BRANCH_LIST_SUCCESS = 'GET_BRANCH_LIST_SUCCESS'
export const GET_BRANCH_LIST_FAILURE = 'GET_BRANCH_LIST_FAILURE'

// Distributor List
export const GET_DISTRIBUTOR_LIST = 'GET_DISTRIBUTOR_LIST'
export const GET_DISTRIBUTOR_LIST_SUCCESS = 'GET_DISTRIBUTOR_LIST_SUCCESS'
export const GET_DISTRIBUTOR_LIST_FAILURE = 'GET_DISTRIBUTOR_LIST_FAILURE'

// Product List
export const GET_PRODUCT_LIST = 'GET_PRODUCT_LIST'
export const GET_PRODUCT_LIST_SUCCESS = 'GET_PRODUCT_LIST_SUCCESS'
export const GET_PRODUCT_LIST_FAILURE = 'GET_PRODUCT_LIST_FAILURE'

// Area List
export const GET_AREA_LIST = 'GET_AREA_LIST'
export const GET_AREA_LIST_SUCCESS = 'GET_AREA_LIST_SUCCESS'
export const GET_AREA_LIST_FAILURE = 'GET_AREA_LIST_FAILURE'

// Object List
export const GET_OBJECT_LIST = 'GET_OBJECT_LIST'
export const GET_OBJECT_LIST_SUCCESS = 'GET_OBJECT_LIST_SUCCESS'
export const GET_OBJECT_LIST_FAILURE = 'GET_OBJECT_LIST_FAILURE'

// Object Processing List
export const GET_OBJECT_PROCESSING_LIST = 'GET_OBJECT_PROCESSING_LIST'
export const GET_OBJECT_PROCESSING_LIST_SUCCESS = 'GET_OBJECT_PROCESSING_LIST_SUCCESS'
export const GET_OBJECT_PROCESSING_LIST_FAILURE = 'GET_OBJECT_PROCESSING_LIST_FAILURE'

// Crop Object List
export const GET_CROP_OBJECT = 'GET_CROP_OBJECT'
export const GET_CROP_OBJECT_SUCCESS = 'GET_CROP_OBJECT_SUCCESS'
export const GET_CROP_OBJECT_FAILURE = 'GET_CROP_OBJECT_FAILURE'

// Crop Object List
export const GET_CROP_OBJECT_PROCESSING = 'GET_CROP_OBJECT_PROCESSING'
export const GET_CROP_OBJECT_PROCESSING_SUCCESS = 'GET_CROP_OBJECT_PROCESSING_SUCCESS'
export const GET_CROP_OBJECT_PROCESSING_FAILURE = 'GET_CROP_OBJECT_PROCESSING_FAILURE'

// Crop List
export const GET_CROP_LIST = 'GET_CROP_LIST'
export const GET_CROP_LIST_SUCCESS = 'GET_CROP_LIST_SUCCESS'
export const GET_CROP_LIST_FAILURE = 'GET_CROP_LIST_FAILURE'

// Harvest List
export const GET_HARVEST_LIST = 'GET_HARVEST_LIST'
export const GET_HARVEST_LIST_SUCCESS = 'GET_HARVEST_LIST_SUCCESS'
export const GET_HARVEST_LIST_FAILURE = 'GET_HARVEST_LIST_FAILURE'

// Employee List
export const GET_EMPLOYEE_LIST = 'GET_EMPLOYEE_LIST'
export const GET_EMPLOYEE_LIST_SUCCESS = 'GET_EMPLOYEE_LIST_SUCCESS'
export const GET_EMPLOYEE_LIST_FAILURE = 'GET_EMPLOYEE_LIST_FAILURE'

// Process List
export const GET_PROCESS_LIST = 'GET_PROCESS_LIST'
export const GET_PROCESS_LIST_SUCCESS = 'GET_PROCESS_LIST_SUCCESS'
export const GET_PROCESS_LIST_FAILURE = 'GET_PROCESS_LIST_FAILURE'

// Get Object detail
export const GET_OBJECT_DETAIL = 'GET_OBJECT_DETAIL'
export const GET_OBJECT_DETAIL_SUCCESS = 'GET_OBJECT_DETAIL_SUCCESS'
export const GET_OBJECT_DETAIL_FAILURE = 'GET_OBJECT_DETAIL_FAILURE'

// Get Object Processing detail
export const GET_OBJECT_PROCESSING_DETAIL = 'GET_OBJECT_PROCESSING_DETAIL'
export const GET_OBJECT_PROCESSING_DETAIL_SUCCESS = 'GET_OBJECT_PROCESSING_DETAIL_SUCCESS'
export const GET_OBJECT_PROCESSING_DETAIL_FAILURE = 'GET_OBJECT_PROCESSING_DETAIL_FAILURE'

// Save User data when login
export const SAVE_USER_INFO = 'SAVE_USER_INFO'
export const SAVE_USER_INFO_SUCCESS = 'SAVE_USER_INFO_SUCCESS'
export const SAVE_USER_INFO_FAILURE = 'SAVE_USER_INFO_FAILURE'

// Get user profile
export const GET_USER_PROFILE = 'GET_USER_PROFILE'
export const GET_USER_PROFILE_SUCCESS = 'GET_USER_PROFILE_SUCCESS'
export const GET_USER_PROFILE_FAILURE = 'GET_USER_PROFILE_FAILURE'

// Manage Notifications
export const SAVE_NOTIFICATION = 'SAVE_NOTIFICATION'
export const SAVE_NOTIFICATION_SUCCESS = 'SAVE_NOTIFICATION_SUCCESS'
export const SAVE_NOTIFICATION_FAILED = 'SAVE_NOTIFICATION_FAILED'

// Clear Notifications
export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION'
export const CLEAR_NOTIFICATION_SUCCESS = 'CLEAR_NOTIFICAITON_SUCCESS'
export const CLEAR_NOTIFICATION_FAILURE = 'CLEAR_NOTIFICAITONF_FAILURE'

// Set tooltip
export const ACTIVE_TOOLTIP = 'ACTIVE_TOOLTIP'
export const ACTIVE_TOOLTIP_SUCCESS = 'ACTIVE_TOOLTIP_SUCCESS'
export const ACTIVE_TOOLTIP_FAILURE = 'ACTIVE_TOOLTIP_FAILURE'
export const DEACTIVE_TOOLTIP = 'DEACTIVE_TOOLTIP'
export const DEACTIVE_TOOLTIP_SUCCESS = 'DEACTIVE_TOOLTIP_SUCCESS'
export const DEACTIVE_TOOLTIP_FAILURE = 'DEACTIVE_TOOLTIP_FAILURE'

// Statistic
export const GET_HARVEST_STATISTIC = 'GET_HARVEST_STATISTIC'
export const HARVEST_STATISTIC_SUCCESS = 'HARVEST_STATISTIC_SUCCESS'
export const HARVEST_STATISTIC_FAILURE = 'HARVEST_STATISTIC_FAILURE'

export const GET_BRANCH_STATISTIC = 'GET_HARVEST_STATISTIC'
export const BRANCH_STATISTIC_SUCCESS = 'BRANCH_STATISTIC_SUCCESS'
export const BRANCH_STATISTIC_FAILURE = 'BRANCH_STATISTIC_FAILURE'

// Get list subscription
export const GET_SUBSCRIPTION = 'GET_SUBSCRIPTION'
export const SUBSCRIPTION_SUCCESS = 'SUBSCRIPTION_SUCCESS'
export const SUBSCRIPTION_FAILURE = 'SUBSCRIPTION_FAILURE'

// Get list plan
export const GET_PLAN_LIST = 'GET_PLAN_LIST'
export const GET_PLAN_LIST_SUCCESS = 'GET_PLAN_LIST_SUCCESS'
export const GET_PLAN_LIST_FAILURE = 'GET_PLAN_LIST_FAILURE'
export const CREATE_PLAN = 'CREATE_PLAN'

// Sidebar
export const CHECK_STATUS_EXPAND = 'CHECK_STATUS_EXPAND'
export const CHECK_STATUS_EXPAND_SUCCESS = 'CHECK_STATUS_EXPAND_SUCCESS'
export const CHECK_STATUS_EXPAND_FAILURE = 'CHECK_STATUS_EXPAND_FAILURE'

// Render Select Customer at Plan Page
export const RENDER_SELECT_CUSTOMER = 'RENDER_SELECT_CUSTOMER'
export const RENDER_SELECT_CUSTOMER_SUCCESSFULL = 'RENDER_SELECT_CUSTOMER_SUCCESSFULL'
export const RENDER_SELECT_CUSTOMER_FAILURE = 'RENDER_SELECT_CUSTOMER_FAILURE'

// Get box and unit stamp for retail
export const GET_BOX_UNIT = 'GET_BOX_UNIT'
export const GET_BOX_AND_UNIT_FAILURE = 'GET_BOX_AND_UNIT_FAILURE'
export const GET_BOX_AND_UNIT_SUCCESS = 'GET_BOX_AND_UNIT_SUCCESS'

// Un paid list
export const GET_UNPAID_LIST = 'GET_UNPAID_LIST'
export const GET_UNPAID_LIST_SUCCESS = 'GET_UNPAID_LIST_SUCCESS'
export const GET_UNPAID_LIST_FAILURE = 'GET_UNPAID_LIST_FAILURE'

// crop Of Product Object Statistic
export const GET_CROP_STATISTIC = 'GET_CROP_STATISTIC'
export const GET_CROP_STATISTIC_SUCCESS = 'GET_CROP_STATISTIC_SUCCESS'
export const GET_CROP_STATISTIC_FAILURE = 'GET_CROP_STATISTIC_FAILURE'

// get number of accesses
export const GET_NUMBER_ACCESSES = 'GET_NUMBER_ACCESSES'
export const GET_NUMBER_ACCESSES_SUCCESS = 'GET_NUMBER_ACCESSES_SUCCESS'
export const GET_NUMBER_ACCESSES_FAILURE = 'GET_NUMBER_ACCESSES_FAILURE'

// get statis branchs
export const GET_PRODUCT_TO_BRANCHS = 'GET_PRODUCT_TO_BRANCHS'
export const GET_PRODUCT_TO_BRANCHS_SUCCESS = 'GET_PRODUCT_TO_BRANCHS_SUCCESS'
export const GET_PRODUCT_TO_BRANCHS_FAILURE = 'GET_PRODUCT_TO_BRANCHS_FAILURE'

// get statis phanphoi
export const GET_PRODUCT_TO_DISTRIBUTOR = 'GET_PRODUCT_TO_DISTRIBUTOR'
export const GET_PRODUCT_TO_DISTRIBUTOR_SUCCESS = 'GET_PRODUCT_TO_DISTRIBUTOR_SUCCESS'
export const GET_PRODUCT_TO_DISTRIBUTOR_FAILURE = 'GET_PRODUCT_TO_DISTRIBUTOR_FAILURE'

// Get list approve
export const GET_LIST_APPROVE = 'GET_LIST_APPROVE'
export const GET_LIST_APPROVE_SUCCESS = 'GET_LIST_APPROVE_SUCCESS'
export const GET_LIST_APPROVE_FAILURE = 'GET_LIST_APPROVE_FAILURE'

// Get active stamp of product type
export const GET_ACTIVE_STAMP_PRODUCT_TYPE = 'GET_ACTIVE_STAMP_PRODUCT_TYPE'
export const GET_ACTIVE_STAMP_PRODUCT_TYPE_SUCCESS = 'GET_ACTIVE_STAMP_PRODUCT_TYPE_SUCCESS'
export const GET_ACTIVE_STAMP_PRODUCT_TYPE_FAILURE = 'GET_ACTIVE_STAMP_PRODUCT_TYPE_FAILURE'

// Get used stamp of product type
export const GET_USED_STAMP_LIST = 'GET_USED_STAMP_LIST'
export const GET_USED_STAMP_LIST_SUCCESS = 'GET_USED_STAMP_LIST_SUCCESS'
export const GET_USED_STAMP_LIST_FAILURE = 'GET_USED_STAMP_LIST_FAILURE'

// Get number crop of product type
export const GET_NUMBER_CROP_PRODUCT_TYPE = 'GET_NUMBER_CROP_PRODUCT_TYPE'
export const GET_NUMBER_CROP_PRODUCT_TYPE_SUCCESS = 'GET_NUMBER_CROP_PRODUCT_TYPE_SUCCESS'
export const GET_NUMBER_CROP_PRODUCT_TYPE_FAILURE = 'GET_NUMBER_CROP_PRODUCT_TYPE_FAILURE'

// Get number stamp active general
export const GET_STAMP_ACTIVE_GENERAL = 'GET_STAMP_ACTIVE_GENERAL'
export const GET_STAMP_ACTIVE_GENERAL_SUCCESS = 'GET_STAMP_ACTIVE_GENERAL_SUCCESS'
export const GET_STAMP_ACTIVE_GENERAL_FAILURE = 'GET_STAMP_ACTIVE_GENERAL_FAILURE'

// Get number harvest crop statistic
export const GET_HARVEST_CROP_STATISTIC = 'GET_HARVEST_CROP_STATISTIC'
export const GET_HARVEST_CROP_STATISTIC_SUCCESS = 'GET_HARVEST_CROP_STATISTIC_SUCCESS'
export const GET_HARVEST_CROP_STATISTIC_FAILURE = 'GET_HARVEST_CROP_STATISTIC_FAILURE'

// Render employee list
export const RENDER_EMPLOYEE_LIST = 'RENDER_EMPLOYEE_LIST'
export const RENDER_EMPLOYEE_LIST_SUCCESS = 'RENDER_EMPLOYEE_LIST_SUCCESS'
export const RENDER_EMPLOYEE_LIST_FAILURE = 'RENDER_EMPLOYEE_LIST_FAILURE'

// Render production area list
export const RENDER_AREA_LIST = 'RENDER_AREA_LIST'
export const RENDER_AREA_LIST_SUCCESS = 'RENDER_AREA_LIST_SUCCESS'
export const RENDER_AREA_LIST_FAILURE = 'RENDER_AREA_LIST_FAILURE'

// update info of area
export const UPDATE_INFO_AREA = 'UPDATE_INFO_AREA'
export const UPDATE_INFO_AREA_SUCCESS = 'UPDATE_INFO_AREA_SUCCESS'
export const UPDATE_INFO_AREA_FAILURE = 'UPDATE_INFO_AREA_FAILURE'

// Update info product
export const UPDATE_INFO_PRODUCT = 'UPDATE_INFO_PRODUCT'
export const UPDATE_INFO_PRODUCT_SUCCESS = 'UPDATE_INFO_PRODUCT_SUCCESS'
export const UPDATE_INFO_PRODUCT_FAILURE = 'UPDATE_INFO_PRODUCT_FAILURE'

// Update info employee
export const UPDATE_INFO_EMPLOYEE = 'UPDATE_INFO_EMPLOYEE'
export const UPDATE_INFO_EMPLOYEE_SUCCESS = 'UPDATE_INFO_EMPLOYEE_SUCCESS'
export const UPDATE_INFO_EMPLOYEE_FAILED = 'UPDATE_INFO_EMPLOYEE_FAILED'

// Get info about active stamp
export const GET_INFO_ACTIVE_STAMP = 'GET_INFO_ACTIVE_STAMP'
export const GET_INFO_ACTIVE_STAMP_SUCCESS = 'GET_INFO_ACTIVE_STAMP_SUCCESS'
export const GET_INFO_ACTIVE_STAMP_FAILURE = 'GET_INFO_ACTIVE_STAMP_FAILURE'

// Get Stamp list Success
export const GET_STAMP_LIST = 'GET_STAMP_LIST'
export const GET_STAMP_LIST_SUCCESS = 'GET_STAMP_LIST_SUCCESS'
export const GET_STAMP_LIST_FAILURE = 'GET_STAMP_LIST_FAILURE'

// Check status Stamp
export const CHECK_STATUS_STAMP = 'CHECK_STATUS_STAMP'
export const CHECK_STATUS_STAMP_SUCCESS = 'CHECK_STATUS_STAMP_SUCCESS'
export const CHECK_STATUS_STAMP_FAILURE = 'CHECK_STATUS_STAMP_FAILURE'

// Edit Process
export const EDIT_PROCESS = 'EDIT_PROCESS'

// Get Object in area
export const GET_OBJECT_IN_AREA = 'GET_OBJECT_IN_AREA'
export const GET_OBJECT_IN_AREA_SUCCESS = 'GET_OBJECT_IN_AREA_SUCCESS'
export const GET_OBJECT_IN_AREA_FAILURE = 'GET_OBJECT_IN_AREA_FAILURE'

// Filter
export const GET_PRODUCT_LIST_FILTER = 'GET_PRODUCT_LIST_FILTER'
export const GET_AREA_LIST_FILTER = 'GET_AREA_LIST_FILTER'
export const GET_OBJECT_PRODUCTION_FILTER = 'GET_OBJECT_PRODUCTION_FILTER'
export const GET_EMPLOYEE_LIST_FILTER = 'GET_EMPLOYEE_LIST_FILTER'
export const GET_OBJECT_PROCESSING_FILTER = 'GET_OBJECT_RPOCESSING_FILTER'

// Plan
export const SAVE_PLAN = 'SAVE_PLAN'
export const SAVE_PLAN_SUCCESS = 'SAVE_PLAN_SUCCESS'
export const SAVE_PLAN_FAILURE = 'SAVE_PLAN_FAILURE'

// SETTING
export const GET_SETTING = 'GET_SETTING'
export const GET_SETTING_SUCCESS = 'GET_SETTING_SUCCESS'
export const GET_SETTING_FAILURE = 'GET_SETTING_FAILURE'

//Get list product retailer
export const GET_LIST_PRODUCT_RETAIL = 'GET_LIST_PRODUCT_RETAIL'
export const GET_LIST_PRODUCT_RETAIL_SUCCESS = 'GET_LIST_PRODUCT_RETAIL_SUCCESS'
export const GET_LIST_PRODUCT_RETAIL_FAILURE = 'GET_LIST_PRODUCT_RETAIL_FAILURE'

//Get all box
export const GET_ALL_BOX = "GET_ALL_BOX"
export const GET_ALL_BOX_SUCCESS = "GET_ALL_BOX_SUCCESS"
export const GET_ALL_BOX_FAILURE = "GET_ALL_BOX_FAILURE"

//get plan register
export const GET_PLAN_REGISTER = "GET_PLAN_REGISTER"
export const GET_PLAN_REGISTER_SUCCESS = "GET_PLAN_REGISTER_SUCCESS"
export const GET_PLAN_REGISTER_FAILURE = "GET_PLAN_REGISTER_FAILURE"

//get list bill
export const GET_BILL_LIST = "GET_BILL_LIST";
export const GET_BILL_LIST_SUCCESS = "GET_BILL_LIST_SUCCESS";
export const GET_BILL_LIST_FAILURE = "GET_BILL_LIST_FAILURE";

//get sale price list
export const GET_PRICE_LIST = "GET_PRICE_LIST"
export const GET_PRICE_LIST_SUCCESS = "GET_PRICE_LIST_SUCCESS"
export const GET_PRICE_LIST_FAILURE = "GET_PRICE_LIST_FAILURE"

//get feedback list
export const GET_FEEDBACK_LIST = "GET_FEEDBACK_LIST"
export const GET_FEEDBACK_LIST_SUCCESS = "GET_FEEDBACK_LIST_SUCCESS"
export const GET_FEEDBACK_LIST_FAILURE = "GET_FEEDBACK_LIST_FAILURE"

//render product list
export const RENDER_PRODUCT_LIST ="RENDER_PRODUCT_LIST"
export const RENDER_PRODUCT_LIST_SUCCESS = "RENDER_PRODUCT_LIST_SUCCESS"
export const RENDER_PRODUCT_LIST_FAILURE = "RENDER_PRODUCT_LIST_FAILURE"

//render  process List
export const RENDER_PROCESS_LIST = "RENDER_PROCESS_LIST"
export const RENDER_PROCESS_LIST_SUCCESS = "RENDER_PROCESS_LIST_SUCCESS"
export const RENDER_PROCESS_LIST_FAILURE = "RENDER_PROCESS_LIST_FAILURE"

//save data edit area
export const SAVE_DATA_EDIT_AREA = "SAVE_DATA_EDIT_AREA"
export const SAVE_DATA_EDIT_AREA_SUCCESS = "SAVE_DATA_EDIT_AREA_SUCCESS"
export const SAVE_DATA_EDIT_AREA_FAILURE = "SAVE_DATA_EDIT_AREA_FAILURE"

//save data edit employee
export const SAVE_DATA_EDIT_EMPLOYEE = "SAVE_DATA_EDIT_EMPLOYEE"
export const SAVE_DATA_EDIT_EMPLOYEE_SUCCESS = "SAVE_DATA_EDIT_EMPLOYEE_SUCCESS"
export const SAVE_DATA_EDIT_EMPLOYEE_FAILURE = "SAVE_DATA_EDIT_EMPLOYEE_FAILURE"

//save data edit employee
export const SAVE_DATA_EDIT_PRODUCT = "SAVE_DATA_EDIT_PRODUCT"
export const SAVE_DATA_EDIT_PRODUCT_SUCCESS = "SAVE_DATA_EDIT_PRODUCT_SUCCESS"
export const SAVE_DATA_EDIT_PRODUCT_FAILURE = "SAVE_DATA_EDIT_PRODUCT_FAILURE"
// get history stamp
export const GET_HISTORY_STAMP = "GET_HISTORY_STAMP"
export const GET_HISTORY_STAMP_SUCCESS = "GET_HISTORY_STAMP_SUCCESS"
export const GET_HISTORY_STAMP_FAILURE = "GET_HISTORY_STAMP_FAILURE"

//update object production
export const UPDATE_OBJECT_PRODUCTION = "UPDATE_OBJECT_PRODUCTION"

//update object processing
export const UPDATE_OBJECT_PROCESSING = "UPDATE_OBJECT_PROCESSING"

//creare product when create object
export const CREATE_OBJECT_PRODUCT = "CREATE_OBJECT_PRODUCT"

//get version 
export const GET_VERSION = "GET_VERSION"
export const GET_VERSION_SUCCESS = "GET_VERSION_SUCCESS"
export const GET_VERSION_FAILURE = "GET_VERSION_FAILURE"

//verify account
export const VERIFY_ACCOUNT = "VERIFY_ACCOUNT"

//get permission
export const GET_PERMISSION = "GET_PERMISSION"
export const GET_PERMISSION_SUCCESS = "GET_PERMISSION_SUCCESS"
export const GET_PERMISSION_FAILURE = "GET_PERMISSION_FAILURE"

//get stamp scan
export const GET_SCAN_STAMP = "GET_SCAN_STAMP"
export const GET_SCAN_STAMP_SUCCESS = "GET_SCAN_STAMP_SUCCESS"
export const GET_SCAN_STAMP_FAILURE = "GET_SCAN_STAMP_FAILURE"

//get stamp location
export const GET_STAMP_LOCATION = "GET_STAMP_LOCATION"
export const GET_STAMP_LOCATION_SUCCESS = "GET_STAMP_LOCATION_SUCCESS"
export const GET_STAMP_LOCATION_FAILURE = "GET_STAMP_LOCATION_FAILURE"

//get harvest quantity
export const GET_HARVEST_QUANTITY = "GET_HARVEST_QUANTITY"
export const GET_HARVEST_QUANTITY_SUCCESS ="GET_HARVEST_QUANTITY_SUCCESS"
export const GET_HARVEST_QUANTITY_FAILURE = "GET_HARVEST_QUANTITY_FAILURE"

//get notification
export const GET_NOTI_LIST = "GET_NOTI_LIST"
export const GET_NOTI_LIST_SUCCESS = "GET_NOTI_LIST_SUCCESS"
export const GET_NOTI_LIST_FAILURE = "GET_NOTI_LIST_FAILURE"

//get General
export const GET_GENERAL = "GET_GENERAL"
export const GET_GENERAL_SUCCESS = "GET_GENERAL_SUCCESS"
export const GET_GENERAL_FAILURE = "GET_GENERAL_FAILURE"

//create branch
export const CREATE_BRANCH_FOR_PARTNER = "CREATE_BRANCH_FOR_PARTNER"

//revoke stamp
export const REVOKE_STAMP_LIST = "REVOKE_STAMP_LIST"
export const REVOKE_STAMP_LIST_SUCCESS = "REVOKE_STAMP_SUCCESS"
export const REVOKE_STAMP_LIST_FAILURE = "REVOKE_STAMP_LIST_FAILURE"

export const SAVE_PACKAGE = 'SAVE_PACKAGE'
export const SAVE_PACKAGE_SUCCESS = 'SAVE_PACKAGE_SUCCESS'
export const SAVE_PACKAGE_FAILURE = 'SAVE_PACKAGE_FAILURE'

export const GET_ACTION_LIST_ADMIN = 'GET_ACTION_LIST_ADMIN'
export const GET_ACTION_LIST_ADMIN_FAILURE = 'GET_ACTION_LIST_ADMIN_FAILURE'
export const GET_ACTION_LIST_ADMIN_SUCCESS = 'GET_ACTION_LIST_ADMIN_SUCCESS'

export const GET_ACTION_LIST_ADMIN_FILTER = 'GET_ACTION_LIST_ADMIN_FILTER'

export const GET_ADMIN_LIST = 'GET_ADMIN_LIST'
export const GET_ADMIN_LIST_SUCCESS = 'GET_ADMIN_LIST_SUCCESS'
export const GET_ADMIN_LIST_FAILURE = 'GET_ADMIN_LIST_FAILURE'

export const GET_DETAIL_ACCOUNT = 'GET_DETAIL_ACCOUNT'
export const GET_DETAIL_ACCOUNT_SUCCESS = 'GET_DETAIL_ACCOUNT_SUCCESS'
export const GET_DETAIL_ACCOUNT_FAILURE = 'GET_DETAIL_ACCOUNT_FAILURE'

export const GET_PORTAL_SETTING = 'GET_PORTAL_SETTING'
export const GET_PORTAL_SETTING_SUCCESS = 'GET_PORTAL_SETTING_SUCCESS'
export const GET_PORTAL_SETTING_FAILURE = 'GET_PORTAL_SETTING_FAILURE'

export const GET_ADS_SETTING = 'GET_ADS_SETTING'
export const GET_ADS_SETTING_SUCCESS = 'GET_ADS_SETTING_SUCCESS'
export const GET_ADS_SETTING_FAILURE = 'GET_ADS_SETTING_FAILURE'
export const GET_ALL_DISTRIBUTOR = 'GET_ALL_DISTRIBUTOR'
export const GET_ALL_DISTRIBUTOR_SUCCESS = 'GET_ALL_DISTRIBUTOR_SUCCESS'
export const GET_ALL_DISTRIBUTOR_FAILURE = 'GET_ALL_DISTRIBUTOR_FAILURE'

export const GET_ALL_DISTRIBUTOR_FILTER = 'GET_ALL_DISTRIBUTOR_FILTER'

export const GET_SYSTEM_ACTION = 'GET_SYSTEM_ACTION'
export const GET_SYSTEM_ACTION_SUCCESS = 'GET_SYSTEM_ACTION_SUCCESS'
export const GET_SYSTEM_ACTION_FAILURE = 'GET_SYSTEM_ACTION_FAILURE'

export const GET_SYSTEM_ACTION_FILTER = 'GET_SYSTEM_ACTION_FILTER'

export const GET_ALL_MANU = 'GET_ALL_MANU'
export const GET_ALL_MANU_SUCCESS = 'GET_ALL_MANU_SUCCESS'
export const GET_ALL_MANU_FAILURE = 'GET_ALL_MANU_FAILURE'

export const GET_ALL_MANU_FILTER = 'GET_ALL_MANU_FILTER'

export const GET_ALL_ACCOUNT = 'GET_ALL_ACCOUNT'
export const GET_ALL_ACCOUNT_SUCCESS = 'GET_ALL_ACCOUNT_SUCCESS'
export const GET_ALL_ACCOUNT_FAILURE = 'GET_ALL_ACCOUNT_FAILURE'

export const GET_ALL_ACCOUNT_FILTER = 'GET_ALL_ACCOUNT_FILTER'


//get valid stamps range
export const GET_VALID_STAMPS_RANGE= "GET_VALID_STAMPS_RANGE"
export const GET_VALID_STAMPS_RANGE_SUCCESS = "GET_VALID_STAMPS_RANGE_SUCCESS"
export const GET_VALID_STAMPS_RANGE_FAILURE = "GET_VALID_STAMPS_RANGE_FAILURE"

//get guard list
export const GET_GUARD_LIST = 'GET_GUARD_LIST'
export const GET_GUARD_LIST_SUCCESS = 'GET_GUARD_LIST_SUCCESS'
export const GET_GUARD_LIST_FAILURE = 'GET_GUARD_LIST_FAILURE'

export const GET_FARMER_LIST = 'GET_FARMER_LIST'
export const GET_FARMER_LIST_SUCCESS = 'GET_FARMER_LIST_SUCCESS'
export const GET_FARMER_LIST_FAILURE = 'GET_FARMER_LIST_FAILURE'

export const GET_SHIP_LIST = 'GET_SHIP_LIST'
export const GET_SHIP_LIST_SUCCESS = 'GET_SHIP_LIST_SUCCESS'
export const GET_SHIP_LIST_FAILURE= 'GET_SHIP_LIST_FAILURE'

export const GET_BOX_LIST = 'GET_BOX_LIST'
export const GET_BOX_LIST_SUCCESS = 'GET_BOX_LIST_SUCCESS'
export const GET_BOX_LIST_FAILURE = 'GET_BOX_LIST_FAILURE'

export const GET_HISTORY_IMPORT = 'GET_HISTORY_IMPORT'
export const GET_HISTORY_IMPORT_SUCCESS = 'GET_HISTORY_IMPORT_SUCCESS'
export const GET_HISTORY_IMPORT_FAILURE = 'GET_HISTORY_IMPORT_FAILURE'

export const GET_RECEIVED_BOX = 'GET_RECEIVED_BOX'
export const GET_RECEIVED_BOX_SUCCESS = 'GET_RECEIVED_BOX_SUCCESS'
export const GET_RECEIVED_BOX_FAILURE = 'GET_RECEIVED_BOX_FAILURE'

export const GET_ALL_BOX_SYSTEM = 'GET_ALL_BOX_SYSTEM'
export const GET_ALL_BOX_SYSTEM_SUCCESS = 'GET_ALL_BOX_SYSTEM_SUCCESS'
export const GET_ALL_BOX_SYSTEM_FAILURE = 'GET_ALL_BOX_SYSTEM_FAILURE'

