import axios from 'axios';
import LoadingTable from "components/LoadingTable/index.js";
import Pagination from "components/Pagination/index.jsx";
import { LINK_API } from 'constants/API.js';
import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ReactTable from 'react-table-6';
import SubTable from "components/SubTable/table.js";
// import "../../css/reactTable.css";

function StampList(props) {
  const [activityRender, setActivity] = React.useState([])
  const [loadingState, setLoading] = React.useState(true)
  const { t } = props;

  const {
    userInfo
  } = props;

  useEffect(() => {
    setLoading(true)
    try{
      axios.defaults.headers.common.Authorization = userInfo.data.token
      axios({
        method: 'GET',
        url: LINK_API.SCAN_STAMP_ADMIN.concat('?date=', props.date)
      }).then((response) => {
        if (response && response.data && response.data.data) {
          let dataRender = response.data.data.map((item) => {
            let dataDetail = []
            if (item.scannedStamp && item.scannedStamp.length > 0) {
              dataDetail = item.scannedStamp.map(itemDiary => {
                return itemDiary;
              })
            }
            return {
              uuid: item.uuid,
              name: item.name,
              dataDetail: dataDetail
            }
          })
          setActivity(dataRender)
          setLoading(false)
        }
        else {
          setActivity([])
          setLoading(false)
        }
      })
      .catch(() => {
        setActivity([])
          setLoading(false)
      })
    }
    catch{
      setLoading(false)
    }
  }, [props.date, userInfo.data.token]);

  const columns = React.useMemo(
    () => [
      {
        Header: t('profile.uuid'),
        accessor: "uuid",
        sortable: false
      },
      {
        Header: t('profile.name'),
        accessor: "name",
        filterable: false
      }
    ],
    [t]
  );


  const columnSubTable = [
    {
        dataField: 'id',
        text: t('import.traceabilityCode'),
    },
    {
      dataField: 'pt',
      text: t('price.product'),
    },
    {
        dataField: 'date',
        text: t('statistic.scanStampDate'),
    },
    {
      dataField: 'location',
      text: t('statistic.location'),
    },
    {
      dataField: 'type',
      text: t('stamp.type'),
    },
]


  return (
    <>
      <div className="divider" />
      <div className="card-body pt-2 px-4 pb-4">
        <ReactTable
          noDataText={t('noData')}
          data={activityRender}
          columns={columns}
          previousText={"<"}
          nextText={">"}
          rowsText={t('row')}
          ofText="/"
          // manual
          defaultPageSize={5}
          showPaginationBottom={true}
          sortable={false}
          resizable={true}
          PaginationComponent={Pagination}
          // pages={totalPage}
          className="-striped -highlight"
          loading={loadingState}
          LoadingComponent={LoadingTable}
          SubComponent={row => {
            return (
              <SubTable columns={columnSubTable} sizePerPage={5} data={row.original.dataDetail} />
            )
          }}
        // onFetchData={async (state, instance) => {
        //   setLoading(true)
        //   let x = state.page + 1;
        //   const page = x;
        //   const size = state.pageSize;
        // }}
        />
      </div>
      {/* </Card> */}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
    allScanStamp: state.allScanStamp,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getAllScanStamp: (data) => dispatch({ type: "GET_ALL_SCAN_STAMP", data: data }),
});

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(StampList));