import { RENDER_PROCESS_LIST_SUCCESS, RENDER_PROCESS_LIST, RENDER_PROCESS_LIST_FAILURE } from "actions/types"
const initialState = { data: '', errorCode: '' }
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case  RENDER_PROCESS_LIST:
      return {
        ...state
      }
    case  RENDER_PROCESS_LIST_SUCCESS:
      return {
        ...state,
        data: action.data,
        errorCode: action.errorCode
      }
    case RENDER_PROCESS_LIST_FAILURE:
      return {
        ...state,
        data: action.data,
        errorCode: action.errorCode
      }
    default:
      return state
  }
}
export default reducer
