const initialState = { data: '', errorCode: '' }
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_USED_STAMP_LIST':
      return {
        ...state
      }
    case 'GET_USED_STAMP_LIST_SUCCESS':
      return {
        ...state,
        data: action.data,
        errorCode: action.errorCode,
        totalPage: action.totalPage
      }
    case 'GET_USED_STAMP_LIST_FAILURE':
      return {
        ...state,
        data: action.data,
        errorCode: action.errorCode,
        totalPage: action.totalPage
      }
    default:
      return state
  }
}
export default reducer
