import {
  GET_BOX_UNIT,
  GET_BOX_AND_UNIT_FAILURE,
  GET_BOX_AND_UNIT_SUCCESS,
} from "actions/types"
import { takeLatest, call, put, select } from 'redux-saga/effects'
import { LINK_API } from 'constants/API.js'
import axios from 'axios'

const getToken = (state) => state.userInfo


// Get box Stamp and Unit Stamp list
function * getStampBoxandUnit () {
  try {
    const tokenInfo = yield select(getToken)
    const response = yield call(fetchStampandUnit, tokenInfo.data.token)
    const data = response.data
    yield put({ type: GET_BOX_AND_UNIT_SUCCESS, data: data.data, errorCode: data.errorCode })
  } catch (error) {
    yield put({ type: GET_BOX_AND_UNIT_FAILURE, payload: '', errorCode: 0 })
  }
}
function fetchStampandUnit (checktoken) {
  axios.defaults.headers.common.Authorization = checktoken
  return axios({
    method: 'GET',
    url: LINK_API.GET_BOX_UNIT
  })
}

const handleImportList = [
  takeLatest(GET_BOX_UNIT, getStampBoxandUnit)
]

export default handleImportList;
