import LoadingTable from "components/LoadingTable/index.js";
import Pagination from "components/Pagination/index.jsx";
import { AGD_LOGO } from "constants/image";
import CloseIcon from "mdi-react/CloseIcon";
import DetailIcon from "mdi-react/InformationIcon";
import PlusCircle from "mdi-react/PlusCircleIcon";
import moment from "moment";
import React, { useEffect } from 'react';
import cookie from "react-cookies";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ReactTable from 'react-table-6';
import Truncate from 'react-truncate';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Card, Col, Container, Row, UncontrolledTooltip, UncontrolledDropdown, DropdownToggle, DropdownMenu, NavItem, Nav, Input, CustomInput, NavLink as NavLinkStrap } from 'reactstrap';
import "../../css/reactTable.css";
import handlePartner from "saga/createPartnerSaga";
import FilterIcon from "mdi-react/FilterIcon"
import { useFormik } from 'formik';
import ResetIcon from "mdi-react/RestoreIcon"
import * as Yup from 'yup';
import { motion } from "framer-motion";
import { LINK_API, HOST_PORTAL } from "constants/API"
import DropZone from "components/dropZone"
import axios from "axios"
import Loading from "components/alert/loading"
import Error from "components/alert/error"
import Success from "components/alert/success"
import GenieToken from "./abi/GenieToken.js"
import Identity from "./abi/Identity.js"
import DateTimePicker from 'react-datetime-picker'
import "./css/index.css"
import Header from "components/Headers/Header"
import  Accounts  from 'web3-eth-accounts';
import  Contract  from 'web3-eth-contract';
import utils from 'web3-utils';
const account = new Accounts();
// account.create();

const CryptoJS = require('crypto-js')
const EthereumTx = require('ethereumjs-tx');
// const Web3 = require('web3');
// let web3 = new Web3(new Web3.providers.HttpProvider())
// var utils = require('web3-utils');
// var eth = require('web3-eth');
// var accounts = new Accounts(this);

function ObjectProductionList(props) {
  const getObjectList = props.getObjectList;
  const getObjectFilter = props.getObjectFilter;
  const [filterStatus, setFilter] = React.useState(false)
  const [dataObjectList, setDataObjectList] = React.useState([])
  const [totalPage, setTotalPage] = React.useState([])
  const [loadingState, setLoading] = React.useState(true)
  const permission = props.permission;
  const [checkPer, setPer] = React.useState(false);

  //add diary for employ
  const [modal, setModal] = React.useState(false);
  const [objectId, setId] = React.useState('')
  const [listStep, setStep] = React.useState([]);
  const [options, setOptions] = React.useState([]);
  const [mobileStep, setMobile] = React.useState([]);
  const [listImage, setImage] = React.useState([]);
  const [alert, setAlert] = React.useState(null);
  const [processCode, setIdProcess] = React.useState('');
  const [indexStep, setIndex] = React.useState('')
  const [datePicker, onDateChange] = React.useState(new Date());

  const handleChangeImage = (data) => {
    setImage(data);
  }

  const handleAddDiary = (uuid, processCode) => {
    setId(uuid);
    toggleModal();
    getProcess(processCode);
  }

  const getProcess = (id) => {
    setIdProcess(id)
    axios.defaults.headers.common.Authorization = props.userInfo.data.token;
    axios.get(LINK_API.GET_INFO_PROCESS + id)
      .then(res => {
        setStep(res.data.data.step);
        let temp = res.data.data.step;
        let select = [];
        for (let i = 0; i < temp.length; i++) {
          select.push({
            label: temp[i].name,
            value: i
          })
        }
        setOptions(select)
      })
  }

  const handleSelectStep = (event) => {
    let index = event.target.value;
    setIndex(index)
    // setMobile(listStep[event.target.value].mobile.method);
    let x = listStep[index].mobile.method.map((item, idx) => {
      return {
        ...item,
        value: item.defaultValue ? item.defaultValue : ""
      }
    })
    setMobile(x)
  }
  const hideAlert = () => {
    setAlert(null)
  }
  const onPicker = (date) => {
    console.log('date', date)
    console.log('moment', moment(date).format('DD/MM/YYYY hh:mm'))
    onDateChange(date)
  }
  const handleGetInfoDiary = () => {
    console.log(props.userInfo)
    setAlert(<Loading />);
    toggleModal();

    let detail = "";
    for (let i = 0; i < mobileStep.length; i++) {
      if (i > 0 && i < mobileStep.length - 2) {
        console.log(mobileStep[i])
        if (mobileStep[i].value) {
          detail = detail + mobileStep[i].label + ": " + mobileStep[i].value + "\n"
        }
      }
    }
    let data = {
      "processCode": processCode,
      "toId": objectId,
      "step": parseInt(indexStep),
      "moreInfo": {},
      "detail": detail,
      "images": []
    }
    console.log('data', data)
    axios.post(LINK_API.GET_INFO_DIARY, data)
      .then(async res => {
        if (res.data.errorCode === 1) {
          const {
            tokenId,
            action,
            detail,
            date,
            nonce,
            genieToken,
            contractAddress,
            fromId,
            toId,
            accountAddress,
            productName,
            identitierAddress,
          } = res.data.data;
          const sign = 'dataSign';
          const raw = await Execute(
            tokenId,
            action,
            fromId,
            toId,
            detail,
            date,
            nonce,
            genieToken,
            contractAddress,
            accountAddress,
            productName,
            identitierAddress,
          );
          console.log('raw', raw);

          const dataX = {
            ...res.data.data,
            raw: raw,
            sign: sign,
            images: listImage,
            date: moment(datePicker).format('DD/MM/YYYY hh:mm:ss')
          };
          handleSendRaw(dataX)
        } else {
          setAlert(<Error onClose={() => setAlert(null)} confirmBtnText={t('confirm')} />)
        }
      })
      .catch(err => {
        setAlert(<Error onClose={() => setAlert(null)} confirmBtnText={t('confirm')} />)
      })
  }
  const handleSendRaw = (data) => {
    axios.post(LINK_API.SEND_RAW_DIARY, data)
      .then(res => {
        if (res.data.errorCode === 1) {
          setAlert(<Success onClose={hideAlert} hideCancel={true} onConfirm={hideAlert} confirmBtnText={t('close')} />)
        } else {
          setAlert(<Error onClose={() => setAlert(null)} confirmBtnText={t('confirm')} />)

        }
      })
      .catch(err => {
        setAlert(<Error onClose={() => setAlert(null)} confirmBtnText={t('confirm')} />)

      })
  }
  const handleChangeInput = (index) => (event) => {
    let tempValue = event.target.value;

    let x = mobileStep.map((item, idx) => {
      if (idx === index) {
        return {
          ...item,
          value: tempValue
        }
      } else {
        return item
      }
    })
    setMobile(x)
  }
  const handleToPortal = (uuid) => {
    cookie.save("uuidOProduction", uuid, { path: "/" });
    props.history.push("/user/detailObjectProduction")
  //  window.location.replace(HOST_PORTAL + props.userInfo.data.ownedBy + uuid)
  }
  const toggleModal = () => {
    setMobile([])
    setImage([])
    setModal(!modal)
  }
  const dataBlockchain = async (
    identitierAddress,
    productName,
    action,
    fromId,
    toId,
    detail,
    date,
    genieToken,
    contractAddress, // dia chi loai san pham
    tokenId,
  ) => {

    try {
      const _organization = identitierAddress; // địa chỉ identifier address của tổ chức của nhân viên đó
      const _productType = productName || ''; //Tên sản phẩm
      const _cetegories = 1; // nhóm sản phẩm tuwj theem
      // const action = action;
      const fromID = fromId;
      const toID = toId;
      const description = detail; // không bắt buộc detail
      // const date = date;
      const hashedDataToSign = utils.soliditySha3(
        _organization,
        _productType,
        _cetegories,
        action,
        fromID,
        toID,
        description,
        date,
      );
      //await web3.eth.personal.unlockAccount("0xEd376914fFb2D7672c78080A3800cD74d1a7EDd4", "nam114111", 600);
      var bytes = CryptoJS.AES.decrypt(props.userInfo.data.privateEncrypted.toString(), props.userInfo.password)
      const decrypt_privatekey = bytes.toString(CryptoJS.enc.Utf8)
      const privateKey = decrypt_privatekey.substring(2, decrypt_privatekey.length)
      var signature = await account.sign(
        hashedDataToSign,
        privateKey, // privateKey of Employee
      );
      console.log('signature', signature.signature);

      // const recover = await web3.eth.accounts.recover(signature)
      // //console.log('recover', recover)
      // var claimIssuer = '0x103cd9d78b561a7ad62051cfe2c2a8ccdf06600f'; // Danh tinh Issuer
      // //console.log('claimIssuer', claimIssuer)
      const GenieTokenABI = GenieToken.abi;
      const GenieTokenDeployed = new Contract(
        GenieTokenABI,
        genieToken,
      );

      const diaryABI = await GenieTokenDeployed.methods
        .Non_FungibleAsset_ActionToDiary(
          tokenId,
          _organization,
          _productType,
          _cetegories,
          signature.signature,
          hashedDataToSign,
          action,
          fromID,
          toID,
          description,
          date,
          contractAddress,
        )
        .encodeABI();

        console.log("diaryABI", diaryABI)
      return diaryABI;
    } catch (err) {
      console.log('err_dataBlockchain', err);
    }
  };
  const Execute = async (
    tokenId,
    action,
    fromId,
    toId,
    detail,
    date,
    nonce,
    genieToken,
    contractAddress,
    accountAddress,
    productName,
    identitierAddress,
  ) => {
    const genieTABI = Identity.abi;
    console.log("genieTABI", genieTABI)
    const genieIdentityDeployed = new Contract(
      genieTABI,
      identitierAddress,
    );

    console.log("genieIdentityDeployed", genieIdentityDeployed)
    // const genieIdentityDeployed = {};
    const _data = await dataBlockchain(
      identitierAddress,
      productName,
      action,
      fromId,
      toId,
      detail,
      date,
      genieToken,
      contractAddress,
      tokenId,
    );
    console.log("_data", _data)
    const data = genieIdentityDeployed.methods
      .execute(genieToken, 0, _data)
      .encodeABI();
      console.log("data", data)
    const txParam = {
      from: accountAddress,
      nonce,
      gasPrice: 0,
      gasLimit: 8000000,
      to: identitierAddress,
      data,
    };
    console.log("txParam", txParam)
    try {
      const tx = await new EthereumTx(txParam, { chain: 4 });
      console.log("tx", tx)
      var bytes = CryptoJS.AES.decrypt(props.userInfo.data.privateEncrypted.toString(), props.userInfo.password)
      const decrypt_privatekey = bytes.toString(CryptoJS.enc.Utf8)
      const privateKey = decrypt_privatekey.substring(2, decrypt_privatekey.length)
      console.log("privateKey", privateKey)
      await tx.sign(Buffer.from(privateKey, 'hex'));
      const rawTx = await `0x${tx.serialize().toString('hex')}`;
      console.log("rawTx", rawTx)
      return rawTx;
    } catch (err) {
      console.log("err", err)
      //Tao lai raw khi tao raw bi loi
      try {
        const tx = await new EthereumTx(txParam, { chain: 4 });
        var bytes = CryptoJS.AES.decrypt(props.userInfo.data.privateEncrypted.toString(), props.userInfo.password)
        const decrypt_privatekey = bytes.toString(CryptoJS.enc.Utf8)
        const privateKey = decrypt_privatekey.substring(2, decrypt_privatekey.length)
        await tx.sign(Buffer.from(privateKey, 'hex'));
        const rawTx = await `0x${tx.serialize().toString('hex')}`;
        return rawTx;
      } catch { }
    }
  };
  React.useEffect(() => {
    if (permission) {
      if (permission.maxObject !== -1) {
        if (permission.object >= permission.maxObject) {
          setPer(true)
        }
      }
    }
  }, [permission])
  const { t } = props;
  const handleChangePage = () => {
    props.history.push('/user/createObjectProduction')
  }
  // const handleDetailPartner = (uuid) => {
  //   cookie.save("uuidOProduction", uuid, { path: "/" });
  //   props.history.push("/oProduction/detail")
  // }
  const handleType = React.useCallback((type) => {
    if (type === 0) return (t('oProduction.row'));
    if (type === 1) return (t('oProduction.tree'));
    if (type === 2) return (t('oProduction.all'));
    if (type === 3) return (t('oProduction.batch'));
    if (type === 4) return (t('oProduction.farm'));
    if (type === 5) return (t('oProduction.house'));
    return "";
  }, [t])
  const formik = useFormik({
    initialValues: {
      name: 'name',
      value: '',
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required(t('require')),
      value: Yup.string()
        .required(t('require')),
    }),
    onSubmit: React.useCallback(async (values) => {
      setFilter(true);
      setLoading(true);
      getObjectFilter(1, values.name, values.value, 5)
    }, []),
  });

  const genTable = React.useCallback((data) => {
    let listObject = data.map((prop, index) => {
      return {
        id: prop.uuid,
        name:
          <div style={{ display: "flex", flexFlow: "row", alignItems: "center" }}>
            <Container>
              <Row>
                <Col xs={12} sm={12} md={5} style={{ textAlign: "start" }}>
                  {(prop.images360) && (prop.images360.length > 0) ? (
                    <img alt="profileAGD" src={prop.images360[0].secure_url} style={{ height: "37px", width: "37px" }} />
                  ) :
                    (prop.image) && (prop.image.length > 0) ? (
                      <img alt="profileAGD" src={prop.image[0].secure_url} style={{ height: "37px", width: "37px" }} />
                    ) :
                      <img alt="profileAGD" src={AGD_LOGO} style={{ height: "37px", width: "37px" }} />}
                </Col >
                <Col xs={12} sm={12} md={7} style={{ display: "flex", alignItems: "center", textAlign: "left" }}>
                  <Truncate lines={2} ellipsis={<span>...</span>}>
                    {prop.name}
                  </Truncate>
                </Col>
              </Row>
            </Container>
          </div>,
        date: moment(prop.registeredDate).format("DD/MM/YYYY"),
        area: prop.nameOfZone,
        type: handleType(prop.type),
        actions: <div>
          <Button color="neutral-success" onClick={() => handleAddDiary(prop.uuid, prop.processCode)} className="mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center" id={"oInfo" + index}>
            <span className="btn-wrapper--icon d-flex">
              <PlusCircle size={18} className="text-success font-size-sm" />
            </span>
          </Button>
          <UncontrolledTooltip trigger="hover" popperClassName="tooltip-black" placement="top" container="body" target={"oInfo" + index}>
            {t('oProduction.addDiary')}
          </UncontrolledTooltip>
          <Button color="neutral-info" onClick={() => handleToPortal(prop.uuid)} className="mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center" id={"oProductionDeactive" + index}>
            <span className="btn-wrapper--icon d-flex">
              <DetailIcon
                size={18}
                className="text-info font-size-sm"
              />
            </span>
          </Button>
          <UncontrolledTooltip trigger="hover" popperClassName="tooltip-black" placement="top" container="body" target={"oProductionDeactive" + index}>
            Xem chi tiết
          </UncontrolledTooltip>
        </div>
      }
    })
    setDataObjectList(listObject)
    setLoading(false)
  }, [t, handleType, handlePartner])

  useEffect(() => {
    getObjectList(1, 5)
  }, [getObjectList]);

  const objectList = props.objectList.data
  const totalPageProps = props.objectList.totalPage
  useEffect(() => {
    if (objectList && objectList.length > 0) {
      genTable(objectList)
      setTotalPage(totalPageProps)
    } else {
      genTable([])
      setTotalPage(0)
    }

  }, [totalPageProps, objectList]);
  const handleResetData = () => {
    setFilter(false);
    getObjectList(1, 5)
  }
  const handleClearFilter = () => {
    formik.resetForm();
  }


  const columns = React.useMemo(
    () => [
      {
        accessor: "id",
        filterable: false,
        className: "center",
        maxWidth: 150,
        Header: () => (
          <div style={{
            textAlign: "center"
          }}>
            {t('oProduction.id')}
          </div>
        )
      },
      {
        Header: t('oProduction.name'),
        minWidth: 107,
        accessor: "name",
        filterable: false
      },
      {
        Header: t('oProduction.date'),
        accessor: "date",
        filterable: false,
        resizable: true,
      },
      {
        Header: t('oProduction.area'),
        accessor: "area",
        filterable: false,
        resizable: true,
      },
      {
        Header: t('oProduction.type'),
        accessor: "type",
        filterable: false
      },
      {
        Header: t('actions'),
        accessor: "actions",
        filterable: false
      },
    ],
    [t]
  );



  return (

    <>
      <motion.div
        className="w-100"
        initial={{ x: -100 }}
        animate={{ x: 0 }}
        transition={{ ease: "easeOut", duration: 0.3 }}
      >
         <Header></Header>
            <Container className='mt--7' fluid>
        <Card className="card-box mb-5">
          <div className="card-header py-3 d-flex justify-content-between">
            <div className=" font-weight-bold card-header--title font-size-lg">{t('oProduction.list')}</div>
            <div className="card-header--actions d-flex flex-row">
              <UncontrolledDropdown>
                <DropdownToggle id="filterButton" color="neutral-success" className="mx-2">
                  <FilterIcon size={20} /> {t('filter.filter')}
                </DropdownToggle>
                <DropdownMenu right className="dropdown-menu-xxl p-0">
                  <form onSubmit={formik.handleSubmit}>
                    <div className="p-3">
                      <Row>
                        <Col md="6">
                          <small className="font-weight-bold pb-2 text-uppercase text-primary d-block">
                            {t('filter.name')}
                          </small>
                          <CustomInput
                            type="select"
                            placeholder={t('filter.name')}
                            value={formik.values.name}
                            valid={formik.touched.name && formik.errors.name ? false : (formik.values.name ? true : false)}
                            invalid={formik.touched.name && formik.errors.name ? true : false}
                            {...formik.getFieldProps('name')}
                          >
                            <option value="name">{t('oProduction.name')}</option>
                            <option value="uuid">{t('oProduction.id')}</option>
                            {/* <option value="status">{t('product.status')}</option> */}
                          </CustomInput>
                        </Col>
                        <Col md="6">
                          <small className="font-weight-bold pb-2 text-uppercase text-primary d-block">
                            {t('filter.value')}
                          </small>
                          {formik.values.name !== "status" ?
                            <Input
                              placeholder={t('filter.value')}
                              value={formik.values.value}
                              valid={formik.touched.value && formik.errors.value ? false : (formik.values.value ? true : false)}
                              invalid={formik.touched.value && formik.errors.value ? true : false}
                              {...formik.getFieldProps('value')}
                            >
                            </Input> : <CustomInput
                              type="select"
                              placeholder={t('filter.value')}
                              value={formik.values.value}
                              valid={formik.touched.value && formik.errors.value ? false : (formik.values.value || formik.values.value === 0 ? true : false)}
                              invalid={formik.touched.value && formik.errors.value ? true : false}
                              {...formik.getFieldProps('value')}
                            >
                              <option value={1}>{t('product.inProduction')}</option>
                              <option value={0}>{t('product.stopProduction')}</option>
                            </CustomInput>}
                        </Col>
                  `</Row>

                    </div>
                    <div className="divider" />
                    <div className="p-3 text-center bg-secondary">
                      <Button color="primary" type="submit">
                        {t('filter.search')}
                      </Button>
                    </div>
                  </form>
                  <div className="divider" />
                  <div className="p-3">
                    <Row>
                      <Col md="6">
                        <Nav className="nav-neutral-danger flex-column p-0">
                          <NavItem>
                            <NavLinkStrap className="d-flex rounded-sm justify-content-center" onClick={handleClearFilter}>
                              <div className="nav-link-icon">
                                <CloseIcon />
                              </div>
                              <span>{t('filter.clear')}</span>
                            </NavLinkStrap>
                          </NavItem>
                        </Nav>
                      </Col>
                      <Col md="6">
                        <Nav className="nav-neutral-info flex-column p-0">
                          <NavItem>
                            <NavLinkStrap className="d-flex rounded-sm justify-content-center" onClick={handleResetData}>
                              <div className="nav-link-icon">
                                <ResetIcon />
                              </div>
                              <span>{t('filter.reset')}</span>
                            </NavLinkStrap>
                          </NavItem>
                        </Nav>
                      </Col>
                    </Row>
                  </div>
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledTooltip trigger="hover" popperClassName="tooltip-black" placement="top" container="body" target={"filterButton"}>
                {t('filter.filter')}
              </UncontrolledTooltip>
              <div id="notiPlan" className="d-inline-block">
                <Button disabled={checkPer || permission.agdqr} color="info" onClick={handleChangePage}>
                  <span className="btn-wrapper--icon">
                    <PlusCircle size={16} />
                  </span>
                  <span className="btn-wrapper--label">{t('product.add')}</span>
                </Button>
              </div>
            </div>
          </div>
          {checkPer || permission.agdqr ?
            <UncontrolledTooltip trigger="hover" popperClassName="tooltip-warning" placement="top" container="body" target={"notiPlan"}>
              {t('notiPlan')}
            </UncontrolledTooltip> : null}

          <div className="divider" />
          <div className="card-body pt-2 px-4 pb-4">

            <ReactTable
              noDataText={t('noData')}
              data={dataObjectList}
              columns={columns}
              previousText={"<"}
              nextText={">"}
              rowsText={t('row')}
              ofText="/"
              loading={loadingState}
              LoadingComponent={LoadingTable}
              manual
              defaultPageSize={5}
              showPaginationBottom={true}
              sortable={false}
              resizable={true}
              PaginationComponent={Pagination}
              pages={totalPage}
              className="-striped -highlight"
              onFetchData={async (state, instance) => {
                setLoading(true)
                let x = state.page + 1;
                if (filterStatus === false) {
                  getObjectList(x, state.pageSize)
                } else {
                  getObjectFilter(x, formik.values.name, formik.values.value, state.pageSize)
                }
              }}
            />
          </div>
        </Card>
        </Container>
      </motion.div>

      <Modal isOpen={modal} zIndex={2000} centered toggle={toggleModal}>
        <ModalHeader toggle={toggleModal} className='font-size-lg'>{t('oProduction.addDiary') + ' - ' + objectId}</ModalHeader>
        <ModalBody>
          <div>
            <label className="font-weight-bold">Chọn bước muốn thêm nhật ký <span className="text-danger">(*)</span></label>
            <CustomInput type="select" id="selectUnit" placeholder={t('product.from')}
              // value={formik.values.unit}
              onChange={handleSelectStep}
              // valid={formik.touched.unit && formik.errors.unit ? false : (formik.values.unit ? true : false)}
              // invalid={formik.touched.unit && formik.errors.unit ? true : false}
              name="customSelect"
            >
              <option value="">Chọn bước</option>
              {options.map((item) => (
                <option value={item.value}>{item.label}</option>
              ))}
            </CustomInput>
          </div>
          {mobileStep.map((item, idx) => (
            idx > 0 && idx < mobileStep.length - 2 ?
              <div key={idx}>
                <label className="font-weight-bold">{item.label}</label>
                <Input placeholder={item.placeholder} value={item.value} onChange={handleChangeInput(idx)} />
              </div>
              : null
          ))}
          {mobileStep.length > 0 ?
            <div>
              <label className="font-weight-bold">Hình ảnh </label>
              <DropZone max={2} onConfirm={handleChangeImage} />
            </div> : null}
          {mobileStep.length > 0 ?
            <div >
              <label className="font-weight-bold">Thời gian </label>
              <DateTimePicker
                className='form-control'
                onChange={onPicker}
                value={datePicker}
              />
            </div> : null}
        </ModalBody>
        <ModalFooter>
          <Button disabled={mobileStep.length > 0 ? false : true} color="primary" onClick={handleGetInfoDiary}>{t('confirm')}</Button>{' '}
          <Button color="danger" outline onClick={toggleModal}>{t('cancel')}</Button>
        </ModalFooter>
      </Modal>
      {alert}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
    userProfile: state.userProfile.data,
    objectList: state.objectList,
    permission: state.permission.data
  }
}

const mapDispatchToProps = (dispatch) => ({
  getObjectList: (page, size) => dispatch({ type: "GET_OBJECT_LIST", page: page, size: size }),
  getObjectFilter: (page, name, value, size) => dispatch({ type: "GET_OBJECT_PRODUCTION_FILTER", page: page, name: name, value: value, size: size }),

  // setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable))
});

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(ObjectProductionList));