import { useFormik } from 'formik';
import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { Button, Input, UncontrolledPopover, ListGroup, ListGroupItem, Alert } from "reactstrap";
//style
import "view/css/select.css";
import * as Yup from 'yup';
import Error from "components/alert/error";
import Loading from "components/alert/loading";
import Success from "components/alert/success";
import { LINK_API } from "constants/API";
import axios from "axios"
import Select from 'react-select';
import moment from "moment"
import CalenderIcon from "mdi-react/CalendarIcon";
import HelpBoxIcon from "mdi-react/HelpBoxIcon";
import DatePicker, { registerLocale } from "react-datepicker";
import el from "date-fns/locale/vi";
registerLocale("el", el);

const ActivateStamp = (props) => {
  const t = props.t;
  const userInfo = props.userInfo;
  const productListState = props.productList;
  const [alert, setAlert] = React.useState(null);
  const {
    validStampRange,
    getValidStampRange
  } = props;

  const [productDate, setProductDate] = React.useState(new Date());
  const [listProduct, setProduct] = React.useState([]);

  React.useEffect(() => {
    if (productListState.data) {
      if (productListState.data.length > 0) {
        const dataRender = productListState.data.map(item => {
          return {
            ...item,
            label: item.name
          }
        })
        setProduct(dataRender)
      }
    }
  }, [productListState])

  const formik = useFormik({
    initialValues: {
      startID: '',
      endID: '',
      productType: '',
    },
    validationSchema: Yup.object({
      startID: Yup.number()
        .required(t('require'))
        .integer(t('stamp.integer')),

      endID: Yup.number()
        .required(t('require'))
        .integer(t('stamp.integer'))
        .test(t('stamp.startIDLessEndID'), t('stamp.startIDLessEndID'),
          function (value) {
            return value >= this.parent.startID;
          }
        ),
      productType: Yup.string()
        .required(t('require')),
    }),
    onSubmit: async (values) => {
      setAlert(<Loading />)
      axios.defaults.headers.common.Authorization = userInfo.data.token;
      try {
        const response = await axios({
          method: 'POST',
          url: LINK_API.ACTIVATE_STAMP_AGDQR,
          data: {
            "startId": values.startID,
            "endId": values.endID,
            "id": values.productType.value,
            "date": productDate ? Date.parse(productDate) / 1000 : Date.parse(new Date()) / 1000
          }
        })

        if (response.data.errorCode === 1) {
          getValidStampRange();
          setAlert(<Success onClose={() => setAlert(null)} hideCancel={true} onConfirm={() => setAlert(null)} confirmBtnText={t('close')} />)
        }
        else {
          setAlert(<Error onClose={() => setAlert(null)} confirmBtnText={t('confirm')} />)
        }
      }
      catch (err) {
        setAlert(<Error onClose={() => setAlert(null)} title="Khoảng tem không hợp lệ" confirmBtnText={t('confirm')} />)
      }

    },
  });

  const ExampleCustomInput = ({ value, onClick }) => (
    <Button block color="link" className="py-2 d-flex" style={{ border: "1px solid #d1d2db" }} size="sm" onClick={onClick}>
      <span className="btn-wrapper--icon">
        <CalenderIcon size={20} />
      </span>
      <span className="btn-wrapper--label">
        {moment(value).format("DD/MM/YYYY")}
      </span>
    </Button>
  );

  return (
    <>
      <div className="d-flex justify-content-center">
        <form onSubmit={formik.handleSubmit}>
          <div className="p-0">
            <div className="form-row">
              <div className="form-group col-md-12">
                <label className="font-weight-bold">
                  {t("product.link")}
                  <span className="text-danger ml-1">(*)
                  </span>
                </label>
                <Select
                  placeholder="Chọn nhóm sản phẩm"
                  options={listProduct}
                  value={formik.productType}
                  onChange={(selectedOption) => {
                    formik.setFieldValue("productType", selectedOption)
                  }}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: '0.29rem',
                    borderWidth: 0.5,
                    height: 30,
                    colors: {
                      ...theme.colors,
                      primary25: 'rgba(60,68,177,0.15)',
                      primary50: 'rgba(60,68,177,0.15)',
                      primary: '#50b77a'
                    }
                  })}
                />
                {formik.touched.productType && formik.errors.productType ? (
                  <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.productType}</p>
                ) : null}
              </div>
              <div className="form-group col-md-12">
                <div className="d-flex">
                  <label className="font-weight-bold">{t('stamp.start')}
                    <span className="text-danger ml-1">(*)
                    </span>
                  </label>
                  <HelpBoxIcon id="helpObject" className="text-info ml-2 cursor-pointer card-box-hover  card-box-hover-rise" />
                  <UncontrolledPopover placement="top" trigger="hover" target="helpObject" className="popover-custom-wrapper popover-custom-lg">
                    <ListGroup flush className="text-left bg-transparent">
                      <ListGroupItem className="rounded">
                        <div className="align-box-row">
                          <div className="pl-2">
                            <span className="pb-1 d-block">
                              <span className="font-weight-bold">
                                Khoảng tem bạn có là
                              </span>
                            </span>
                          </div>
                        </div>
                        <Alert color="info" className="mt-2">
                          <div className="mt-1">
                            {
                              validStampRange && validStampRange.products && validStampRange.products.map(item => {
                                return <div>{`- Từ ${item.startId} đến ${item.endId}`}</div>
                              })
                            }
                          </div>
                        </Alert>
                      </ListGroupItem>
                    </ListGroup>
                  </UncontrolledPopover>
                </div>
                <Input
                  placeholder={t('stamp.start')}
                  type="number"
                  value={formik.startID}
                  valid={formik.touched.startID && formik.errors.startID ? false : (formik.values.startID ? true : false)}
                  invalid={formik.touched.startID && formik.errors.startID ? true : false}
                  {...formik.getFieldProps('startID')}
                />
                {formik.touched.startID && formik.errors.startID ? (
                  <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.startID}</p>
                ) : null}
              </div>
              <div className="form-group col-md-12">
                <div className="d-flex">
                  <label className="font-weight-bold">{t('stamp.end')}
                    <span className="text-danger ml-1">(*)
                    </span>
                  </label>
                  <HelpBoxIcon id="helpObjectend" className="text-info ml-2 cursor-pointer card-box-hover  card-box-hover-rise" />
                  <UncontrolledPopover placement="top" trigger="hover" target="helpObjectend" className="popover-custom-wrapper popover-custom-lg">
                    <ListGroup flush className="text-left bg-transparent">
                      <ListGroupItem className="rounded">
                        <div className="align-box-row">
                          <div className="pl-2">
                            <span className="pb-1 d-block">
                              <span className="font-weight-bold">
                                Khoảng tem bạn có là
                              </span>
                            </span>
                          </div>
                        </div>
                        <Alert color="info" className="mt-2">
                          <div className="mt-1">
                            {
                              validStampRange && validStampRange.products && validStampRange.products.map(item => {
                                return <div>{`- Từ ${item.startId} đến ${item.endId}`}</div>
                              })
                            }
                          </div>
                        </Alert>
                      </ListGroupItem>
                    </ListGroup>
                  </UncontrolledPopover>
                </div>
                <Input
                  placeholder={t('stamp.end')}
                  type="number"
                  value={formik.endID}
                  valid={formik.touched.endID && formik.errors.endID ? false : (formik.values.endID ? true : false)}
                  invalid={formik.touched.endID && formik.errors.endID ? true : false}
                  {...formik.getFieldProps('endID')}
                />
                {formik.touched.endID && formik.errors.endID ? (
                  <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.endID}</p>
                ) : null}
              </div>
            </div>
            <div className="form-row ">
              <div className="form-group col-md-12 d-flex flex-column">
                <label className="font-weight-bold">{t("stamp.productDate")}</label>
                <DatePicker className="form-control"
                  selected={productDate}
                  onChange={date => setProductDate(date)}
                  customInput={<ExampleCustomInput />}
                  locale="el"
                />
              </div>
            </div>
            <div className="form-row ">
              <div className="form-group col-md-12 mb-0">
                <p><span className="text-danger">(*)</span> Trường thông tin bắt buộc</p>
              </div>
            </div>
            <div className="form-row ">
              <div className="form-group col-md-12 mb-0">
                <Button
                  size="lg"
                  type="submit"
                  className="text-uppercase font-weight-bold font-size-sm"
                  color="info">
                  {t('stamp.activate')}
                </Button>
              </div>
            </div>
          </div>
        </form>
      </div>
      {alert}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
    permission: state.permission,
    harvestList: state.harvestList,
    validStampRange: state.validStampRange,
    productList: state.renderProduct,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getAllHarvest: () => dispatch({ type: "GET_HARVEST_LIST" }),
  getValidStampRange: () => dispatch({ type: "GET_VALID_STAMPS_RANGE" }),
});

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(ActivateStamp));