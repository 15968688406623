import LoadingTable from "components/LoadingTable/index.js";
import Pagination from "components/Pagination/index.jsx";
import PlusCircle from "mdi-react/PlusCircleIcon";
import Error from "components/alert/error";
import Loading from "components/alert/loading";
import Success from "components/alert/success";
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ReactTable from 'react-table-6';
import { Button, Card, Container, UncontrolledTooltip, Input } from 'reactstrap';
import "../../css/reactTable.css";
import Header from "components/Headers/Header"
import CloseIcon from "mdi-react/CloseCircleIcon";
import { LINK_API } from 'constants/API'
import axios from 'axios';
import PenIcon from "mdi-react/PenIcon";
import cookie from 'react-cookies';
function ObjectProductionList(props) {

  const { t } = props;
  const [totalPage, setTotalPage] = React.useState([])
  const [loadingState, setLoading] = React.useState(false)
  const [stampList, setStampList] = React.useState([])
  const [stampListAll, setStampListAll] = React.useState([])
  const [alert, setAlert] = React.useState(null);
  const [filterName, setFilterName] = React.useState("");
  const componentRef = React.useRef();
  const columns = React.useMemo(
    () => [
      {
        accessor: "id",
        filterable: false,
        className: "center",
        maxWidth: 150,
        Header: () => (
          <div style={{
            textAlign: "center"
          }}>
            STT
          </div>
        )
      },
      {
        Header: "Tên",
        minWidth: 107,
        accessor: "name",
        filterable: true,
        filterMethod: (filter, row) => {
          if (row.name) {
            const nameInRow = row.name.toUpperCase().search(filter.value.toUpperCase());
            if (nameInRow === -1) return false;
            return true;
          }
          return false;
        },
      },
      {
        Header: t('actions'),
        accessor: "actions",
        filterable: false
      },
    ],
    [t]
  );

  const data = [
    {
      id: 1,
      name: "Tem mẫu 1"
    },
    {
      id: 2,
      name: "Tem mẫu 2"
    }
  ]

  React.useEffect(() => {
    axios.get(LINK_API.GET_ALL_SAMPLE_STAMP)
      .then(res => {
        genTable(res.data.data)
      })
      .catch(err => console.log("err", err))

  }, [])

  const handleEditStamp = (id) => {
    cookie.save("sampleStampID", id, { path: "/" });
    if (props.userInfo.data.role === 'Z') {
      props.history.push("/admin/editStamp")
    } else {
      props.history.push("/sadmin/editStamp")
    }
  }

  const handleDelete = (uuid) => {
    setAlert(<Loading />)
    const body = {
      idList: [uuid]
    }
    axios.post(LINK_API.DELETE_SAMPLE_STAMP, body)
      .then(res => {
        axios.get(LINK_API.GET_ALL_SAMPLE_STAMP)
          .then(res => {
            setAlert(<Success onConfirm={() => setAlert(false)} hideCancel={true} confirmBtnText={"OK"} />)
            genTable(res.data.data)
          })
          .catch(err => { })
      })
      .catch(err => setAlert(<Error onClose={() => setAlert(false)} confirmBtnText={t('close')} />))

  }

  const genTable = React.useCallback((listData) => {
    if (listData.length > 0) {
      let stamp = listData.map((prop, index) => {
        return {
          id: index + 1,
          name: prop.name,
          actions: (
            <>
              <Button
                color="neutral-success"
                onClick={() => handleEditStamp(prop.uuid)}
                className="mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center"
                id={"productEdit" + index}>
                <span className="btn-wrapper--icon d-flex">
                  <PenIcon
                    size={18}
                    className="text-success font-size-sm"
                  />
                </span>
              </Button>
              <UncontrolledTooltip trigger="hover" popperClassName="tooltip-black" placement="top" container="body" target={"productEdit" + index}>
                {t('edit')}
              </UncontrolledTooltip>
              <Button
                color="neutral-success"
                onClick={() => handleDelete(prop.uuid)}
                className="mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center"
                id={"delete" + index}>
                <span className="btn-wrapper--icon d-flex">
                  <CloseIcon
                    size={18}
                    className="text-danger font-size-sm"
                  />
                </span>
              </Button>
              <UncontrolledTooltip trigger="hover" popperClassName="tooltip-black" placement="top" container="body" target={"delete" + index}>
                {"Xoá"}
              </UncontrolledTooltip>
            </>
          )
          // actions: <div className="d-flex flex-row justify-content-center">
          //   <ReactToPrint
          //     trigger={() => <Button color="info">
          //       <span className="btn-wrapper--icon">
          //         <PrintIcon size={18} />
          //       </span>
          //       <span className="btn-wrapper--label">{"In"}</span>
          //     </Button>}
          //     content={() => componentRef.current}
          //   />

          //   <div id="printObject" style={{ display: "none" }}>
          //     <StampSample
          //       positionEnterprise={positionEnterprise}
          //       positionProduct={positionProduct}
          //       positionStampID={positionStampID}
          //       positionQRCode={positionQRCode}
          //       ref={componentRef} />
          //   </div>
          // </div>
        }
      })
      setStampList(stamp)
      setStampListAll(stamp)
      setLoading(false)
    }

  }, [])

  const handleFilterName = (e) => {
    if (e && e.target && e.target.value) {
      setFilterName(e.target.value)
      const filterStamp = stampListAll.filter(item => {
        const nameUppercase = item.name.toUpperCase();
        const valueUppercase = e.target.value.toUpperCase();
        if (nameUppercase.search(valueUppercase) !== -1) return item;
      })
      setStampList(filterStamp)
    }
    else {
      setFilterName(e.target.value)
      setStampList(stampListAll)
    }
  }

  const handleEnterData = () => {
    if (props.userInfo.data.role === 'Z') {
      props.history.push("/admin/createStamp")
    } else {
      props.history.push("/sadmin/createStamp")
    }
  }

  return (

    <>
      <Header />
      <Container className='mt--7' fluid>
        <Card className="card-box mb-5">
          <div className="card-header py-3 d-flex justify-content-between">
            <div className=" font-weight-bold card-header--title font-size-lg">Danh sách tem mẫu</div>
            <div id="notiPlan" className="d-inline-block">
              <Button color="info" onClick={() => handleEnterData()}>
                <span className="btn-wrapper--icon">
                  <PlusCircle size={16} />
                </span>
                <span className="btn-wrapper--label">{t('product.add')}</span>
              </Button>
            </div>
          </div>
          <div className="divider" />
          <div className="card-body pt-2 px-4 pb-4">
            <ReactTable
              noDataText={t('noData')}
              data={stampList}
              columns={columns}
              previousText={"<"}
              nextText={">"}
              rowsText={t('row')}
              ofText="/"
              loading={loadingState}
              LoadingComponent={LoadingTable}
              defaultPageSize={5}
              showPaginationBottom={true}
              sortable={false}
              resizable={true}
              PaginationComponent={Pagination}
              // pages={totalPage}
              className="-striped -highlight"
            />
          </div>
        </Card>
        {alert}
      </Container>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
    userProfile: state.userProfile.data,
    objectList: state.objectList,
    permission: state.permission.data
  }
}

const mapDispatchToProps = (dispatch) => ({
  getObjectList: (page, size) => dispatch({ type: "GET_OBJECT_LIST", page: page, size: size }),
  getObjectFilter: (page, name, value, size) => dispatch({ type: "GET_OBJECT_PRODUCTION_FILTER", page: page, name: name, value: value, size: size }),
});

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(ObjectProductionList));