import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import "view/css/select.css";
import Switch from 'rc-switch';
import { Input, Button } from "reactstrap"
import Success from "components/alert/success"
import Error from "components/alert/error"
import Loading from "components/alert/loading"
import { LINK_API } from "constants/API";
import Axios from "axios"
import cookie from "react-cookies"
import Confirm from "components/alert/confirmAlert"
function Setting(props) {
    const t = props.t;
    const uuidManu = cookie.load("uuidManu");
    const settingList = props.settingList;
    const [nameRep, setName] = React.useState("")
    const [alert, setAlert] = React.useState(null)
    const handleChange = name => async event => {
        // console.log("Name", name)
        // console.log(event.target.value)
        await setName(event.target.value)
    }
    const [checkedName, setCheckedName] = React.useState(false);
    const toggleName = () => {
        setReset(true)
        setCheckedName(!checkedName)
    };
    const [checkedTime, setCheckedTime] = React.useState(false);
    const toggleTime = () => {
        setReset(true)
        setCheckedTime(!checkedTime)
    };
    const [checkedProcess, setCheckedProcess] = React.useState(false);
    const toggleProcess = () => {
        setReset(true)
        setCheckedProcess(!checkedProcess)
    };
    const [checkedMap, setCheckedMap] = React.useState(false);
    const toggleMap = () => {
        setReset(true)
        setCheckedMap(!checkedMap)
    };
    const [checkedCollapse, setCheckedCollapse] = React.useState(false);
    const toggleCollapse = () => {
        setReset(true)
        setCheckedCollapse(!checkedCollapse)
    };
    React.useEffect(() => {
        if (settingList) {
            setCheckedTime(settingList.showAlterName);
            setCheckedMap(settingList.satelliteImagery);
            setCheckedTime(settingList.showTimeDiary)
            setCheckedProcess(settingList.showDetailBizProcess);
            setName(settingList.alternativeName)
            setCheckedCollapse(settingList.isCollapse)
        }
    }, [settingList])
    const [resetState, setReset] = React.useState(false);
    const handleReset = () => {
        if (settingList) {
            setCheckedTime(settingList.showAlterName);
            setCheckedMap(settingList.satelliteImagery);
            setCheckedTime(settingList.showTimeDiary)
            setCheckedProcess(settingList.showDetailBizProcess);
            setName(settingList.alternativeName)
            setCheckedCollapse(settingList.isCollapse)
        }
        setReset(false);
    }
    const hideAlert = () => {
        setAlert(null)
    }
    const handleConfirm = () => {
        hideAlert();
        props.getSetting();
        setReset(false)
    }
    const handleWarning  = () => {
        setAlert(<Confirm 
            child={"Lưu lại thông tin đã thay đổi ?"}
            cancleBtnText={t('no')}
            confirmBtnText={t("yes")}
            onConfirm = {handleSave}
            onClose= {hideAlert}
            />)
    }
    const handleSave = () => {
        setAlert(<Loading/>)
        let data = {
            alternativeName: nameRep,
            rowOfPage: 5,
            satelliteImagery: checkedMap,
            showAlterName: checkedName,
            showDetailBizProcess: checkedProcess,
            showTimeDiary: checkedTime,
            isCollapse: checkedCollapse
        }
        Axios.put(LINK_API.EDIT_PROFILE_USER + uuidManu, data)
        .then(res=>{
            if(res.data.errorCode === 1){
                setAlert(<Success onConfirm={handleConfirm} hideCancel={true} confirmBtnText={t('confirm')}/>)
            }else{
                setAlert(<Error onClose={hideAlert} confirmBtnText={t('back')}/>)
            }
        })
        .catch(err=>{
            setAlert(<Error onClose={hideAlert} confirmBtnText={t('back')}/>)
        })
    }
    return (
        <>
            <div className="p-4">
                <div className="form-row">
                    <div className="col-md-6 text-left">
                        <h5 className="font-size-xl font-weight-bold">{t('profile.setting')}</h5>
                        <p className="text-black-50 mb-0">{t('profile.aboutSetting')}</p>
                    </div>
                    {resetState ? <div className="col-md-6 text-right">
                        <Button outline color="danger" onClick={handleReset}>{t('reset')}</Button>
                        <Button color="info" className="ml-2" onClick={handleWarning}>{t('save')}</Button>
                    </div> : null}
                </div>

                <div className="divider my-4" />
                <div className="m-2 form-row">
                    <Switch checked={checkedTime} onClick={toggleTime} className="switch-small toggle-switch-success"></Switch>
                    <p className="font-weight-bold ml-2">{t(`profile.timeDiary`)}</p>
                </div>
                <div className="m-2 form-row">
                    <Switch checked={checkedProcess} onClick={toggleProcess} className="switch-small toggle-switch-success"></Switch>
                    <p className="font-weight-bold ml-2">{t(`profile.bizProcess`)}</p>
                </div>
                <div className="m-2 form-row">
                    <Switch checked={checkedMap} onClick={toggleMap} className="switch-small toggle-switch-success"></Switch>
                    <p className="font-weight-bold ml-2">{t(`profile.map`)}</p>
                </div>
                <div className="m-2 form-row">
                    <Switch checked={checkedCollapse} onClick={toggleCollapse} className="switch-small toggle-switch-success"></Switch>
                    <p className="font-weight-bold ml-2">{t(`profile.collapse`)}</p>
                </div>
                <div className="m-2 form-row">
                    <Switch checked={checkedName} onClick={toggleName} className="switch-small toggle-switch-success"></Switch>
                    <p className="font-weight-bold ml-2">{t(`profile.replaceName`)}</p>
                </div>
                {checkedName ? <div className="m-2 form-row">
                    <label className="font-weight-bold">{t('profile.nameRep')}</label>
                    <Input
                        placeholder={t('profile.nameRep')}
                        type="text"
                        name="email"
                        value={nameRep || ""}
                        valid={nameRep ? true : false}
                        onChange={handleChange('nameRep')}
                    />
                </div> : null}
            </div>
            {alert}
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        userProfile: state.userProfile.data,
        userInfo: state.userInfo,
        settingList: state.settingList.data
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        getSetting: () => dispatch({ type: "GET_SETTING" }),

    }
}
export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(Setting));
