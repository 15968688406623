import {
  GET_PROCESS_LIST,
  GET_PROCESS_LIST_FAILURE,
  GET_PROCESS_LIST_SUCCESS,

  RENDER_PROCESS_LIST,
  RENDER_PROCESS_LIST_SUCCESS,
  RENDER_PROCESS_LIST_FAILURE,

  CREATE_PROCESS,
  PUSH_NOTI_PRODUCT_PROCESSING,
  SAVE_NOTIFICATION,
  DEACTIVE_NOTI_PRODUCT,
  PUSH_NOTI_PRODUCT_SUCCESS,

  EDIT_PROCESS
} from "actions/types"
import { takeLatest, call, put, select, delay } from 'redux-saga/effects'
import { LINK_API } from 'constants/API.js'
import axios from 'axios'
import responseCode from "constants/errorCode.js"

const getToken = (state) => state.userInfo
//edit process
// Edit Process
function * watchEditProcess (data) {
  yield put({ type: PUSH_NOTI_PRODUCT_PROCESSING, tr: true, message: "Đang xử lý", color: 'warning' })
  yield delay(2000)
  yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
  const token = yield select(getToken)
  const { response } = yield call(editProcess, data.data, token.data.token)
  if (response) {
    const result = response.data
    if (result.errorCode === 1) {
      yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message:"Cập nhật thông tin quy trình thành công", color: 'success' })
      yield put({ type: SAVE_NOTIFICATION, data: { type: 'success', message: 'Cập nhật thông tin quy trình thành công' } })
      yield put({ type: GET_PROCESS_LIST })
      yield delay(3000)
      yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
    } else {
      yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Cập nhật thông tin quy trình thất bại", color: 'danger' })
      yield put({ type: SAVE_NOTIFICATION, data: { type: 'error', message: "Cập nhật thông tin quy trình thất bại" } })
      yield delay(3000)
      yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
    }
  } else {
    yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Cập nhật thông tin quy trình thất bại", color: 'danger' })
    yield put({ type: SAVE_NOTIFICATION, data: { type: 'error', message: "Cập nhật thông tin quy trình thất bại" } })
    yield delay(3000)
    yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
  }
}
function editProcess (data, checktoken) {
  axios.defaults.headers.common.Authorization = checktoken
  return axios.put(LINK_API.EDIT_PROCESS, data)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

//create process
function* watchCreateProcess(data) {
  yield put({ type: PUSH_NOTI_PRODUCT_PROCESSING, tr: true, message: "Đang xử lý", color: 'warning' })
  yield delay(2000)
  yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
  const token = yield select(getToken)
  const { response, error } = yield call(createProcess, data.data, token.data.token)
  if (response) {
    const result = response.data
    if (result.errorCode === 1) {
      yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Tạo quy trình thành công", color: 'success' })
      yield put({ type: SAVE_NOTIFICATION, data: { type: 'success', message: "Tạo quy trình thành công" } })
      yield put({ type: GET_PROCESS_LIST })
      yield delay(3000)
      yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
    } else {
      if (responseCode[`${result.name}`]) {
        yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: responseCode[`${result.name}`].vi, color: 'danger' })
        yield put({ type: SAVE_NOTIFICATION, data: { type: 'error', message: responseCode[`${result.name}`].vi } })
        yield delay(3000)
        yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
      } else {
        yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Tạo quy trình thất bại", color: 'danger' })
        yield put({ type: SAVE_NOTIFICATION, data: { type: 'error', message: "Tạo quy trình thất bại" } })
        yield delay(3000)
        yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
      }
    }
  } else {
    if (error) {
      console.log(error)
      if (error.response.data.name) {
        console.log(error.response.data.name)
        if (responseCode[`${error.response.data.name}`]) {
          console.log("check error process")
          yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: responseCode[`${error.response.data.name}`].vi, color: 'danger' })
          yield put({ type: SAVE_NOTIFICATION, data: { type: 'error', message: responseCode[`${error.response.data.name}`].vi } })
          yield delay(3000)
          yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
        } else {
          yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Tạo quy trình thất bại", color: 'danger' })
          yield put({ type: SAVE_NOTIFICATION, data: { type: 'error', message: "Tạo quy trình thất bại" } })
          yield delay(3000)
          yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
        }
      } else {
        yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Tạo quy trình thất bại", color: 'danger' })
        yield put({ type: SAVE_NOTIFICATION, data: { type: 'error', message: "Tạo quy trình thất bại" } })
        yield delay(3000)
        yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
      }
    } else {
      yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Tạo quy trình thất bại", color: 'danger' })
      yield put({ type: SAVE_NOTIFICATION, data: { type: 'error', message: "Tạo quy trình thất bại" } })
      yield delay(3000)
      yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
    }

  }
}
function createProcess(data, checktoken) {
  axios.defaults.headers.common.Authorization = checktoken
  return axios.post(LINK_API.REGISTER_PROCESS, data)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

//render processList
function* watchRenderProcess(data) {
  const token = yield select(getToken)
  const response = yield call(fetchProcess, token.data.token, data.ownedBy)
  if (response) {
    const result = response.data;
    if (result.errorCode === 1) {
      let list = result.data;
      let list_product = [];
      for (let i = 0; i < list.length; i++) {
        list_product.push({
          name: list[i].processName + " - " + list[i].bizProcessCode,
          value: list[i].bizProcessCode
        })
      }
      yield put({
        type: RENDER_PROCESS_LIST_SUCCESS,
        data: list_product,
        errorCode: result.errorCode
      })
    } else {
      yield put({
        type: RENDER_PROCESS_LIST_FAILURE,
        data: [],
        errorCode: result.errorCode
      })
    }
  }
}

// Get process list
function* getProcessList() {
  try {
    const tokenInfo = yield select(getToken)
    const response = yield call(fetchProcess, tokenInfo.data.token)
    const data = response.data
    yield put({ type: GET_PROCESS_LIST_SUCCESS, data: data.data, errorCode: data.errorCode })
  } catch (error) {
    yield put({ type: GET_PROCESS_LIST_FAILURE, payload: '', errorCode: 0 })
  }
}
function fetchProcess(checktoken, ownedBy) {
  axios.defaults.headers.common.Authorization = checktoken
  let url = ""
  if(ownedBy){
    url = LINK_API.ADMIN_GET_ALL_PROCESS.concat('?ownedBy=', ownedBy)
  }
  else{
    url = LINK_API.GET_PROCESS_LIST
  }
  return axios({
    method: 'GET',
    url: url
  })
}

const process = [
  takeLatest(GET_PROCESS_LIST, getProcessList),
  takeLatest(RENDER_PROCESS_LIST, watchRenderProcess),
  takeLatest(CREATE_PROCESS, watchCreateProcess),
  takeLatest(EDIT_PROCESS, watchEditProcess)
]
export default process

