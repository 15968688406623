import {
  CREATE_AREA,
  GET_AREA_LIST,
  PUSH_NOTI_PRODUCT_SUCCESS,
  DEACTIVE_NOTI_PRODUCT,
  SAVE_DATA_EDIT_AREA,
  SAVE_DATA_EDIT_AREA_SUCCESS, 
  SAVE_NOTIFICATION,
} from "actions/types"
import { takeLatest, call, put, select, delay } from 'redux-saga/effects'
import { LINK_API } from 'constants/API.js'
import axios from 'axios'
import responseCode from "constants/errorCode.js"

const getToken = (state) => state.userInfo
//const getSaveSetting = (state) => state.settingList.data
//save data edit
function *saveDataArea(data){
  yield put({type: SAVE_DATA_EDIT_AREA_SUCCESS, dataOne: data.dataOne, dataTwo: data.dataTwo, check: data.check})
}
// Create Production Area
function * watchCreateArea (data) {
  yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Đang xử lý", color: 'warning' })
  yield delay(2000)
  yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
  const token = yield select(getToken)
  const { response, error } = yield call(createArea, data.data, token.data.token)
  if (response) {
    const result = response.data
    if (result.errorCode === 1) {
      yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Tạo vùng nguyên liệu thành công", color: 'success' })
      yield put({ type: SAVE_NOTIFICATION, data: { type: 'success', message: "Tạo vùng nguyên liệu thành công " + result.data.uuid} })
      yield put({ type: GET_AREA_LIST, page: 1, size:  5})
      yield delay(2000)
      yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
    } else {
      if (responseCode[`${result.name}`].vi) {
        yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: responseCode[`${result.name}`].vi, color: 'error' })
        yield put({ type: SAVE_NOTIFICATION, data: { type: 'success', message: responseCode[`${result.name}`].vi} })
        yield delay(2000)
        yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
      } else {
        yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Tạo vùng nguyên liệu thất bại", color: 'error' })
        yield put({ type: SAVE_NOTIFICATION, data: { type: 'success', message: "Tạo vùng nguyên liệu thất bại"} })
        yield delay(2000)
        yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
      }
    }
  } else {
    if (error.response.data.name) {
      yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: responseCode[`${error.response.data.name}`].vi, color: 'error' })
      yield put({ type: SAVE_NOTIFICATION, data: { type: 'success', message: responseCode[`${error.response.data.name}`].vi} })
      yield delay(2000)
      yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
    } else {
      yield put({ type: PUSH_NOTI_PRODUCT_SUCCESS, tr: true, message: "Tạo vùng nguyên liệu thất bại", color: 'error' })
      yield put({ type: SAVE_NOTIFICATION, data: { type: 'success', message: "Tạo vùng nguyên liệu thất bại"} })
      yield delay(2000)
      yield put({ type: DEACTIVE_NOTI_PRODUCT, tr: false })
    }
  }
}
function createArea (data, checktoken) {
  axios.defaults.headers.common.Authorization = checktoken
  return axios.post(LINK_API.CREATE_PRODUCTION_AREA, data)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}


const createAreaSaga = [
  takeLatest(CREATE_AREA, watchCreateArea),
  takeLatest(SAVE_DATA_EDIT_AREA, saveDataArea)
]
export default createAreaSaga

