import { 
    GET_USER_PROFILE,
    GET_USER_PROFILE_SUCCESS,
    GET_USER_PROFILE_FAILURE,
   } from "actions/types"
  import { takeLatest, call, put, select } from 'redux-saga/effects'
  import { LINK_API } from 'constants/API.js'
  import axios from 'axios'
  
  const getToken = (state) => state.userInfo
  
 
// Get user profile
function * getUserProfile () {
    try {
      const token = yield select(getToken)
      const response = yield call(fetchProfile, token.data.token)
      const data = response.data
      yield put({ type: GET_USER_PROFILE_SUCCESS, data: data.data })
    } catch (error) {
      yield put({ type: GET_USER_PROFILE_FAILURE })
    }
  }
  function fetchProfile (checktoken) {
    axios.defaults.headers.common.Authorization = checktoken
    return axios({
      method: 'GET',
      url: LINK_API.GET_PROFILE
    })
  }
  const actions = [
    takeLatest(GET_USER_PROFILE, getUserProfile)
  ]
  export default actions
  
  