import React from 'react';

import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
export default function Table(props) {
  return (
    <>
      <div className="table-responsive-md">
        <BootstrapTable
          bootstrap4
          keyField="id"
          data={props.data}
          columns={props.columns}
        />
      </div>
    </>
  );
}
