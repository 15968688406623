import PDFInput from "components/dropZone/pdfInput";
import React from "react";
import Header from "components/Headers/Header"
import {
  Button,
  Card,
  Col,
  Input,
  Row,
  Container
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import axios from 'axios'
import { LINK_API } from 'constants/API.js'
import Error from "components/alert/error";
import Loading from "components/alert/loading";
import Success from "components/alert/success";
import { setCommentRange } from "typescript";
import Select from 'react-select';
import ConfirmAlert from "components/alert/confirmAlert"
import cookie from "react-cookies";

const CreatePartner = (props) => {
  const [listPdf, setPdf] = React.useState([]);
  const [listOtherPdf, setOtherPdf] = React.useState([]);
  const [listPdfDelete, setPdfDelete] = React.useState([]);
  const [listOtherPdfDelete, setOtherPdfDelete] = React.useState([]);
  const [listPdfAdd, setPdfAdd] = React.useState([]);
  const [listOtherPdfAdd, setOtherPdfAdd] = React.useState([]);
  const [name, setName] = React.useState("");
  const [enterpriseName, setEnterpriseName] = React.useState("");
  const [tax, setTax] = React.useState("");
  const nameOfOwner = cookie.load("nameOfOwner");
  const addressOfOwner = cookie.load("addressOfOwner");
  const [address, setAddress] = React.useState("");
  const [alert, setAlert] = React.useState(null)
  const [isEdit, setIsEdit] = React.useState(false)
  const [uuid, setUuid] = React.useState("")
  const getListCustomer = props.getListCustomer;
  const listCustomer = props.listCustomer;
  const t = props.t;
  axios.defaults.headers.common.Authorization = props.userInfo.data.token
  
  const handleAva = (data) => {
    if (isEdit) {
      setPdfAdd(data.filter(item => !item.url))
    }
    else {
      setPdf(data);
    }
  }

  const handleAvaOther = (data) => {
    if (isEdit) {
      setOtherPdfAdd(data.filter(item => !item.url))
    }
    else {
      setOtherPdf(data)
    }
  }

  const hideAlert = () => {
    setAlert(null)
  }

  const handleSave = () => {
    if (isEdit) {
      handleEdit()
    }
    else {
      setAlert(<Loading />)
      let formData = new FormData();
      formData.append('name', name);
      formData.append('address', address);
      formData.append('taxNo', tax);
      formData.append('cooperativeName', enterpriseName);
      formData.append('ownedBy', uuid);
      
      for (let index = 0; index < listPdf.length; index++) {
        formData.append('certfood', listPdf[index]);
      }
      for (let idx = 0; idx < listOtherPdf.length; idx++) {
        formData.append('document', listOtherPdf[idx]);
      }

      axios({
        method: 'post',
        url: LINK_API.ADMIN_CREATE_RECORD,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' }
      })
        .then(function () {
          setAlert(<Success onConfirm={() => handleBackList()} onClose={hideAlert} hideCancel={true} confirmBtnText='Xác nhận' />)
        })
        .catch(function () {
          setAlert(<Error onClose={hideAlert} />)
        });
    }
  }

  const handleBackList = () => {
    if(props.userInfo.data.type === 'Z'){
      props.history.push('/admin/allManu');
  
    }else{
     props.history.push('/sadmin/allManu');
    }
  }

  const handleEditCertFood = () => {
    let formDataCertFood = new FormData();
    if (listPdfDelete.length > 0 || listPdfAdd.length > 0) {
      if (listPdfDelete.length > 0) {
        for (let index = 0; index < listPdfDelete.length; index++) {
          formDataCertFood.append('deleteList', listPdfDelete[index]);
        }
      }
      if (listPdfAdd.length > 0) {
        for (let index = 0; index < listPdfAdd.length; index++) {
          formDataCertFood.append('certfood', listPdfAdd[index]);
        }
      }

      axios({
        method: 'put',
        url: LINK_API.ADMIN_EDIT_CERTFOOD + uuid,
        data: formDataCertFood,
        headers: { 'Content-Type': 'multipart/form-data' }
      })
        .then(function () {
          if (listOtherPdfDelete.length > 0 || listOtherPdfAdd.length > 0) {
            handleEditDocument()
          }
          else {
            getDetail(uuid)
            setAlert(<Success onConfirm={hideAlert} onClose={hideAlert} hideCancel={true} confirmBtnText='Xác nhận' />)
          }
        })
        .catch(function () {
          setAlert(<Error onClose={hideAlert} />)
        });
    }
    else if (listOtherPdfDelete.length > 0 || listOtherPdfAdd.length > 0) {
      handleEditDocument()
    }
    else {
      getDetail(uuid)
      setAlert(<Success onConfirm={hideAlert} onClose={hideAlert} hideCancel={true} confirmBtnText='Xác nhận' />)
    }
  }

  const handleEditDocument = () => {
    let formDataDocument = new FormData();
    if (listOtherPdfDelete.length > 0) {
      for (let index = 0; index < listOtherPdfDelete.length; index++) {
        formDataDocument.append('deleteList', listOtherPdfDelete[index]);
      }
    }
    if (listOtherPdfAdd.length > 0) {
      for (let index = 0; index < listOtherPdfAdd.length; index++) {
        formDataDocument.append('document', listOtherPdfAdd[index]);
      }
    }

    axios({
      method: 'put',
      url: LINK_API.ADMIN_EDIT_DOCUMENT + uuid,
      data: formDataDocument,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then(function () {
        getDetail(uuid)
        setAlert(<Success onConfirm={hideAlert} onClose={hideAlert} hideCancel={true} confirmBtnText='Xác nhận' />)
      })
      .catch(function () {
        setAlert(<Error onClose={hideAlert} />)
      });
  }

  const handleEdit = () => {
    setAlert(<Loading />)
    let formData = new FormData();
    formData.append('name', name);
    formData.append('address', address);
    formData.append('taxNo', parseInt(tax));
    formData.append('cooperativeName', enterpriseName);
    axios({
      method: 'put',
      url: LINK_API.ADMIN_EDIT_RECORD + uuid,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then(function () {
        handleEditCertFood()
      })
      .catch(function () {
        setAlert(<Error onClose={hideAlert} />)
      });
  }

  React.useEffect(() => {
    const lengthPath = props.location.pathname.split("/");
    if(lengthPath[2] === "admin_create_record"){
      setUuid(lengthPath[3])
      getDetail(lengthPath[3])
      setIsEdit(false)
    }
    else if (lengthPath.length === 4) {
      setIsEdit(true)
      setUuid(lengthPath[3])
      getDetail(lengthPath[3])
    }
    else {
      setIsEdit(false)
    }
  }, [])


  const removeN = (str, num) => {
    const { length } = str;
    if (num > length) {
      return str;
    };
    const newStr = str.substr(num, length - num);
    return newStr;
  };

  const getDetail = (id) => {
    setPdfDelete([]);
    setOtherPdfDelete([]);
    setPdfAdd([]);
    setOtherPdfAdd([]);
    axios.get(LINK_API.ADMIN_GET_DETAIL_RECORD + id)
      .then(res => {
        if (res && res.data && res.data.data) {
          if (res.data.data._id) {
            setIsEdit(true)
          }
          const data = res.data.data;
          setName(data.name);
          setAddress(data.address);
          setTax(data.taxNo || "");
          setEnterpriseName(data.cooperativeName);
          if (data.certFoodPath) {
            const certFood = data.certFoodPath.map((item, index) => {
              const file_name = item.split("/")[4];
              if (file_name) {
                const file_name_remove_length = file_name.split("_")[0].length + 1;
                return {
                  name: removeN(file_name, file_name_remove_length),
                  path: item,
                  url: true,
                  buffer: data.certFood && data.certFood[index] && data.certFood[index].data
                }
              }
            })
            setPdf(certFood)
          }

          if (data.documentPath) {
            const document = data.documentPath.map((item, index) => {
              const file_name = item.split("/")[4];
              if (file_name) {
                const file_name_remove_length = file_name.split("_")[0].length + 1;
                return {
                  name: removeN(file_name, file_name_remove_length),
                  path: item,
                  url: true,
                  buffer: data.document && data.document[index] && data.document[index].data
                }
              }
            })
            setOtherPdf(document)
          }
        }
        else {
          setAddress(addressOfOwner || props.userInfo.data.address);
          setEnterpriseName(nameOfOwner || props.userInfo.data.name);
          cookie.remove('addressOfOwner', { path: '/' });
          cookie.remove('nameOfOwner', { path: '/' });
        }
      })
      .catch((err) => {
        setAddress(addressOfOwner || props.userInfo.data.address);
        setEnterpriseName(nameOfOwner || props.userInfo.data.name);
        cookie.remove('addressOfOwner', { path: '/' });
        cookie.remove('nameOfOwner', { path: '/' });
      })
  }

  const handleDelete = (data) => {
    if (data.url) {
      setPdfDelete([...listPdfDelete, data.path])
    }
  }

  const handleDeleteOther = (data) => {
    if (data.url) {
      setOtherPdfDelete([...listOtherPdfDelete, data.path])
    }
  }

  React.useEffect(() => {
    getListCustomer();
  }, [getListCustomer]);


  const handleChangeSelect = (selectedOption) => {
    axios.get(LINK_API.ADMIN_GET_DETAIL_RECORD + selectedOption.value)
    .then(() => {
      setAlert(<ConfirmAlert
        child='Thành viên này đã có hồ sơ, vui lòng chọn hồ sơ khác'
        onConfirm={() => setAlert(null)}
        hideCancel={true}
    />)
    })
    .catch(() => {
      setEnterpriseName(selectedOption)
    })
  }

  const handleDeleteLedger = () => {
    setAlert(<ConfirmAlert
      child='Bạn chắc chắn muốn xoá hồ sơ này!'
      onConfirm={() => confirmDelete()}
      onClose={() => setAlert(null)}
  />)
  }

  const confirmDelete = () => {
    axios.defaults.headers.common.Authorization = props.userInfo.data.token
    axios.delete(LINK_API.ADMIN_DELETE_RECORD + uuid)
      .then(res => {
        setAlert(<Success onConfirm={() => handleBackList()} hideCancel={true} confirmBtnText={t('confirm')} />)
      })
      .catch(err => {
        setAlert(<Error onClose={hideAlert} confirmBtnText={t('close')} />)
      })
  }

  console.log("name", name)
  return (
    <>
      <Header />
      <Container className='mt--7 d-flex justify-content-center' fluid>
        {
          <Card className="card-box mb-5 pb-5 w-75">
            <div className="card-header py-3 d-flex justify-content-between">
              <div className=" font-weight-bold card-header--title font-size-lg">{"Hồ sơ kinh doanh của thành viên"}</div>
              <div className="card-header--actions d-flex flex-row">
              <div style={{ display: "inline-block" }} id="notiPlan">
                  <Button color="info" onClick={() => handleDeleteLedger()} >
                    <span className="btn-wrapper--label">{"Xoá hồ sơ"}</span>
                  </Button>
                </div>
              </div>
            </div>
            <div className="divider" />
            <Row className="px-4">
              <Col lg="6" className="mb-2">
                <div className="mb-2">Tên hồ sơ</div>
                <Input
                  placeholder={"Tên hồ sơ"}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                {!name ? (
                  <p className="font-weight-regular font-size-sm text-danger" >Bắt buộc</p>
                ) : null}
              </Col>
              <Col lg="6" className="mb-2">
                <div className="mb-2">Tên thành viên</div>
                <Input
                      placeholder={"Tên thành viên"}
                      value={enterpriseName}
                      onChange={(e) => setEnterpriseName(e.target.value)}
                    />
                    {!enterpriseName ? (
                  <p className="font-weight-regular font-size-sm text-danger" >Bắt buộc</p>
                ) : null}
              </Col>
              <Col lg="6" className="mb-2">
                <div className="mb-2">Mã số thuế</div>
                <Input
                  type="number"
                  placeholder={"Mã số thuế"}
                  value={tax}
                  onChange={(e) => setTax(e.target.value)}
                />
              </Col>
              <Col lg="6" className="mb-2">
                <div className="mb-2">Địa chỉ</div>
                <Input
                  placeholder={"Địa chỉ"}
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </Col>
              <Col lg="6">
                <div>Giấy chứng nhận ATTP</div>
                <PDFInput
                  max={3}
                  onDelete={handleDelete}
                  isEdit={isEdit}
                  onConfirm={handleAva}
                  data={listPdf}
                />
              </Col>
              <Col lg="6">
                <div>Tài liệu khác</div>
                <PDFInput
                  max={3}
                  onDelete={handleDeleteOther}
                  isEdit={isEdit}
                  onConfirm={handleAvaOther}
                  data={listOtherPdf}
                />
              </Col>
              <Col className="mt-4 d-flex">
                <Button disabled={!enterpriseName || !name} color="info" onClick={() => handleSave()}> Lưu </Button>
              </Col>
            </Row>
          </Card>
        }
        {alert}
      </Container>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
    permission: state.permission,
    listCustomer: state.listCustomer.data
  }
}

const mapDispatchToProps = (dispatch) => ({
  createPartner: (data) => dispatch({ type: "CREATE_DISTRIBUTOR", data: data }),
  getListCustomer: () => dispatch({ type: "RENDER_SELECT_CUSTOMER" }),
});

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(CreatePartner));