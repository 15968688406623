import axios from 'axios';
import Error from 'components/alert/error';
import Loading from 'components/alert/loading';
import Success from 'components/alert/success';
import LoadingTable from "components/LoadingTable/index.js";
import Pagination from "components/Pagination/index.jsx";
import { LINK_API } from 'constants/API';
import PlusCircle from "mdi-react/PlusCircleIcon";
import moment from "moment";
import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ReactTable from 'react-table-6';
import { Button, Card, CardBody, CardTitle, Col, Row, FormGroup, Container, UncontrolledTooltip } from 'reactstrap';
import "../../css/reactTable.css";
import "./css/index.css";
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import Select from 'react-select';
import ContentAlert from 'components/alert/contentAlert';
import Header from "components/Headers/Header"
import CloseIcon from "mdi-react/CloseIcon"
function SubscriptionList(props) {
  const getSubscription = props.getSubscription;
  const getListCustomer = props.getListCustomer;
  const getListPlan = props.getListPlan;

  const [dataSubscriptionList, setDataSubscriptionList] = React.useState([])
  const [dataCustomerList, setDataCustomerList] = React.useState([])
  const [dataPlanList, setDataPlanList] = React.useState([])
  const [loadingState, setLoading] = React.useState(true)
  const [alert, setAlert] = React.useState(null)
  const { t } = props;

  const formalityList = [
    {
      value: true,
      label: t('registerList.replace')
    },
    {
      value: false,
      label: t('registerList.continute')
    }
  ]

  const hideAlert = () => {
    setAlert(null)
  }

  const renderSelectPlan = (data) => {
    const x = data ? data.length : 0;
    let temp = [];
    for (let i = 0; i < x; i++) {
      temp.push({
        label: data[i].name + " - " + data[i].uuid,
        uuid: data[i].uuid
      })
    }

    setDataPlanList(temp)
  }

  const genTable = React.useCallback((dataList) => {
    let data = dataList.map((prop, index) => {
      return {
        id: index + 1,
        name: prop.userInfo ? prop.userInfo.name : "",
        uuid: prop.userInfo ? prop.userInfo.uuid : "",
        date: moment(prop.createdAt).format("DD/MM/YYYY"),
        start: moment(prop.start).format("DD/MM/YYYY HH:mm:ss"),
        end: moment(prop.end).format("DD/MM/YYYY HH:mm:ss"),
        plan: prop.plan,
        action:(
          <>
          <Button onClick={() => console.log('Check')} color="neutral-success" className="mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center" id={"detailAccount" + index}>
          <span className="btn-wrapper--icon d-flex">
            <CloseIcon
              size={18}
              className="text-danger font-size-sm"
            />
          </span>
        </Button>
        <UncontrolledTooltip trigger="hover" popperClassName="tooltip-black" placement="top" container="body" target={"detailAccount" + index}>
         Gỡ gói khỏi tài khoản
        </UncontrolledTooltip>
        </>
        )
      }
    })
    setDataSubscriptionList(data)
    setLoading(false)
  }, [])

  useEffect(() => {
    getSubscription();
    getListCustomer();
    getListPlan();
  }, [getSubscription, getListCustomer, getListPlan]);

  const subscription = props.subscription.data;
  const listCustomer = props.listCustomer
  const planList = props.planList.data
  useEffect(() => {
    if (subscription) {
      if (subscription.length > 0) {
        genTable(subscription)
      } else {
        setLoading(false)
      }
    }

  }, [subscription, genTable]);

  useEffect(() => {
    if (listCustomer) {
      setDataCustomerList(listCustomer)
    }
  }, [listCustomer]);

  useEffect(() => {
    if (planList) {
      renderSelectPlan(planList)
    }
  }, [planList]);
  const columns = React.useMemo(
    () => [
      {
        accessor: "id",
        filterable: false,
        className: "center",
        maxWidth: 100,
        Header: () => (
          <div style={{
            textAlign: "center",
          }}>
            #
          </div>
        )
      },
      {
        Header: t('registerList.name'),
        accessor: "name",
        sortable: false
      },
      {
        Header: t('registerList.ID'),
        accessor: "uuid",
        filterable: false
      },
      {
        Header: t('registerList.createTime'),
        accessor: "date",
        filterable: false
      },
      {
        Header: t('registerList.effectiveTime'),
        accessor: "start",
        filterable: false
      },
      {
        Header: t('registerList.effectiveExpiredTime'),
        accessor: "end",
        filterable: false
      },
      {
        Header: 'Hành động',
        accessor: "action",
        filterable: false
      }
    ],
    [t]
  );


  const handleChange_SelectID = (prop, selectedOption) => {
    if (selectedOption) {
      prop.form.setFieldValue("id", selectedOption)
    }
  }

  const handleChange_SelectPackageCode = (prop, selectedOption) => {
    if (selectedOption) {
      prop.form.setFieldValue("packageCode", selectedOption)
    }
  }

  const handleChange_SelectFormality = (prop, selectedOption) => {
    if (selectedOption) {
      prop.form.setFieldValue("formality", selectedOption)
    }
  }


  const handleSubmit = (values) => {
    setAlert(<Loading />)
    const data = {
      "clientId": values.id.value,
      "planId": values.packageCode.uuid,
      "replace": values.formality.value
    }
    axios.post(LINK_API.GET_SUBSCRIPTION, data)
      .then(response => {
        if (response.data.errorCode === 1) {
          setAlert(<Success onClose={hideAlert} hideCancel={true} onConfirm={hideAlert} confirmBtnText={t('close')} />)
        } else {
          setAlert(<Error onClose={hideAlert} confirmBtnText={t('close')} />)
        }
      })
      .catch(err => {
        setAlert(<Error onClose={hideAlert} confirmBtnText={t('close')} />)
      })
  }


  const handleEnterData = React.useCallback(() => {
    const ChildComponent = (
      <div>
        <Formik
          validationSchema={Yup.object({
            id: Yup.string()
              .required(t('require')),
            packageCode: Yup.string()
              .required(t('require')),
            formality: Yup.string()
              .required(t('require')),
          })}
          initialValues={{
            packageCode: "",
            id: "",
            formality: {
              value: false,
              label: t('registerList.continute')
            }
          }}
          onSubmit={values => handleSubmit(values)}
        >
          {({ errors, touched }) => (
            <Form>
              <Field
                name="id"
                render={(props) => (
                  <FormGroup>
                    <label className="font-weight-bold" >{t('registerList.IDEnterPrise')}</label>
                    <Select
                      placeholder={t('registerList.enterIDEnterPrise')}
                      options={dataCustomerList}
                      value={props.field.value}
                      onChange={(selectedOption) => handleChange_SelectID(props, selectedOption)}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: '0.29rem',
                        borderWidth: 1,
                        colors: {
                          ...theme.colors,
                          primary25: 'rgba(60,68,177,0.15)',
                          primary50: 'rgba(60,68,177,0.15)',
                          primary: '#4FB779'
                        }
                      })}
                    />
                  </FormGroup>
                )}
              />
              <Field
                name="packageCode"
                render={(props) => (
                  <FormGroup>
                    <label className="font-weight-bold" >{t('registerList.packageCode')}</label>
                    <Select
                      placeholder={t('registerList.packageCode')}
                      options={dataPlanList}
                      value={props.field.value}
                      onChange={(selectedOption) => handleChange_SelectPackageCode(props, selectedOption)}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: '0.29rem',
                        borderWidth: 1,
                        colors: {
                          ...theme.colors,
                          primary25: 'rgba(60,68,177,0.15)',
                          primary50: 'rgba(60,68,177,0.15)',
                          primary: '#4FB779'
                        }
                      })}
                    />
                  </FormGroup>
                )}
              />
              <Field
                name="formality"
                render={(props) => (
                  <FormGroup>
                    <label className="font-weight-bold" >{t('registerList.formality')}</label>
                    <Select
                      placeholder={t('registerList.formality')}
                      options={formalityList}
                      value={props.field.value}
                      onChange={(selectedOption) => handleChange_SelectFormality(props, selectedOption)}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: '0.29rem',
                        borderWidth: 1,
                        colors: {
                          ...theme.colors,
                          primary25: 'rgba(60,68,177,0.15)',
                          primary50: 'rgba(60,68,177,0.15)',
                          primary: '#4FB779'
                        }
                      })}
                    />
                  </FormGroup>
                )}
              />
              <Button color="info" type="submit">{t('confirm')}</Button>
            </Form>
          )}
        </Formik>
      </div>
    )

    setAlert(
      <ContentAlert
        title={t('employee.enterInfo')}
        hideCancel={true}
        onCancel={hideAlert}
        hideConfirm={true}
        child={ChildComponent}
      />

    )
  }, [t, dataCustomerList, dataPlanList, formalityList, handleSubmit])


  return (

    <>
    <Header/>
    <Container className='mt--7' fluid>
      <Card className="card-box mb-5">
        <div className="card-header py-3 d-flex justify-content-between">
          <div className=" font-weight-bold card-header--title font-size-lg">{t('registerList.list')}</div>
          <div className="card-header--actions">
            <Button onClick={() => handleEnterData()} color="info">
              <span className="btn-wrapper--icon">
                <PlusCircle size={16} />
              </span>
              <span className="btn-wrapper--label">{t('addVote')}</span>
            </Button>
          </div>
        </div>

        <div className="divider" />
        <div className="card-body pt-2 px-4 pb-4">

          <ReactTable
            noDataText={t('noData')}
            data={dataSubscriptionList}
            columns={columns}
            previousText={"<"}
            nextText={">"}
            rowsText={t('row')}
            ofText="/"
            defaultPageSize={5}
            showPaginationBottom={true}
            sortable={false}
            resizable={true}
            PaginationComponent={Pagination}
            className="-striped -highlight"
            loading={loadingState}
            LoadingComponent={LoadingTable}
            SubComponent={row => {
              const planData = row.original.plan;
              return (
                <div>
                  <Card className="card-box">
                    <CardBody>
                      <CardTitle className="font-weight-bold font-size-lg mb-4">
                        {t('package.infoPackage')}
                      </CardTitle>
                      <Row>
                        <Col span={8}>
                          <p className="tagTitleSub">{t('package.type')} </p>
                          <p className="tagTitleSub">{t('package.actionNumber')} </p>
                          <p className="tagTitleSub">{t('package.processingNumber')} </p>
                          <p className="tagTitleSub">{t('package.cropNumber')} </p>
                          <p className="tagTitleSub">{t('package.employee')} </p>
                          <p className="tagTitleSub">{t('package.processingObjectNumber')} </p>
                          <p className="tagTitleSub">{t('package.productNumber')} </p>
                          <p className="tagTitleSub">{t('package.productObjectNumber')} </p>
                        </Col>
                        <Col span={16}>
                          <p>{planData.name}</p>
                          {planData.maxAction === -1 ? <p>{t('package.noMax')}</p> : <p>{planData.maxAction}</p>}
                          {planData.maxBizProcess === -1 ? <p>{t('package.noMax')}</p> : <p>{planData.maxBizProcess}</p>}
                          {planData.maxCrop === -1 ? <p>{t('package.noMax')}</p> : <p>{planData.maxCrop}</p>}
                          {planData.maxEmployee === -1 ? <p>{t('package.noMax')}</p> : <p>{planData.maxEmployee}</p>}
                          {planData.maxProcessingObject === -1 ? <p>{t('package.noMax')}</p> : <p>{planData.maxProcessingObject}</p>}
                          {planData.maxProductType === -1 ? <p>{t('package.noMax')}</p> : <p>{planData.maxProductType}</p>}
                          {planData.maxProductionObject === -1 ? <p>{t('package.noMax')}</p> : <p>{planData.maxProductionObject}</p>}
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </div>
              );
            }}
          />
        </div>
      </Card>
      </Container>
      {alert}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    subscription: state.subscription,
    planList: state.planList,
    userInfo: state.userInfo,
    listCustomer: state.listCustomer.data
  }
}

const mapDispatchToProps = (dispatch) => ({
  getSubscription: () => dispatch({ type: "GET_SUBSCRIPTION" }),
  getListPlan: () => dispatch({ type: "GET_PLAN_LIST" }),
  getListCustomer: () => dispatch({ type: "RENDER_SELECT_CUSTOMER" }),
});

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(SubscriptionList));