const ErrorCode = {
  FAIL: {
    status: 200,
    errorCode: 0,
    name: 'FAIL',
    message: 'Fail',
    vi: 'Lỗi'
  },
  SUCCESSFUL: {
    status: 200,
    errorCode: 1,
    name: 'SUCCESSFUL',
    message: 'Success',
    vi: 'Thành công'
  },
  INVALID_PARAMETER: {
    status: 400,
    errorCode: 2,
    name: 'INVALID_PARAMETER',
    message: 'Invalid param',
    vi: 'Thông số không hợp lệ'
  },
  INVALID_TOKEN: {
    status: 498,
    errorCode: 3,
    name: 'INVALID_TOKEN',
    message: 'Invalid token',
    vi: 'Token không hợp lệ'
  },
  DB_ERROR: {
    status: 500,
    errorCode: 4,
    name: 'DB_ERROR',
    message: 'Database error',
    vi: 'Lỗi cơ sở dữ liệu'
  },
  DATA_IS_NOT_ALLOW_NULL: {
    status: 422,
    errorCode: 5,
    name: 'DATA_IS_NOT_ALLOW_NULL',
    message: 'Data is not allow null ',
    vi: 'Dữ liệu không được phép'
  },
  NUMBER_OF_PRODUCT_REACH_MAXIMUM_THRESHOLD: {
    status: 500,
    errorCode: 5,
    name: 'NUMBER_OF_PRODUCT_REACH_MAXIMUM_THRESHOLD',
    message: 'Number of product was reached maximum threshold',
    vi: 'Số lượng sản phẩm đạt ngưỡng tối đa'
  },
  CANNOT_GENERATE_TOKEN: {
    status: 500,
    errorCode: 6,
    name: 'CANNOT_GENERATE_TOKEN',
    message: 'Can not generate token',
    vi: 'Không thể tạo token'
  },
  MISSING_TOKEN: {
    status: 499,
    errorCode: 7,
    name: 'MISSING_TOKEN',
    message: 'Missing token',
    vi: 'Thiếu token'
  },
  MISSING_VALUE: {
    status: 422,
    errorCode: 8,
    name: 'MISSING_VALUE',
    message: 'Missing value for param of integrated function.',
    vi: 'Thiếu giá trị cho param của hàm tích hợp.'
  },
  MISSING_PARAM: {
    status: 422,
    errorCode: 9,
    name: 'MISSING_PARAM',
    message: 'Missing param for integrated function.',
    vi: 'Thiếu giá trị cho param của hàm tích hợp.'
  },
  INVALID_SIGNATURE: {
    status: 401,
    errorCode: 10,
    name: 'INVALID_SIGNATURE',
    message: 'Invalid signature',
    vi: 'Chữ ký không hợp lệ'
  },
  CURRENT_PASSWORD_IS_INVALID: {
    status: 400,
    errorCode: 11,
    name: 'CURRENT_PASSWORD_IS_INVALID',
    message: 'Current password is invalid',
    vi: 'Mật khẩu hiện tại không tồn tại'
  },
  PASSWORD_CONFIRMATION_DOES_NOT_MATCH_NEW_PASSWORD: {
    status: 400,
    errorCode: 12,
    name: 'PASSWORD_CONFIRMATION_DOES_NOT_MATCH_NEW_PASSWORD',
    message: 'Password confirmation does not match new password',
    vi: 'Xác nhận mật khẩu không khớp mật khẩu mới'
  },
  // API Login
  FIRST_LOGIN: {
    status: 200,
    errorCode: 100,
    name: 'FIRST_LOGIN',
    message: 'Login in the first time',
    vi: 'Đăng nhập lần đầu'
  },
  LOGIN_IS_NOT_SUCCESSFUL: {
    status: 500,
    errorCode: 101,
    name: 'LOGIN_IS_NOT_SUCCESSFUL',
    message: 'Log in is not successful',
    vi: 'Đăng nhập không thành công'
  },

  // API SUBMIT Login errorCode
  MISSING_NAME: {
    status: 422,
    errorCode: 200,
    name: 'MISSING_NAME',
    message: 'Missing name field',
    vi: 'Thiếu tên'
  },
  MISSING_FIRSTNAME: {
    status: 422,
    errorCode: 201,
    name: 'MISSING_FIRSTNAME',
    message: 'Missing firstname field',
    vi: 'Thiếu tên'
  },
  MISSING_LASTNAME: {
    status: 500,
    errorCode: 202,
    name: 'MISSING_LASTNAME',
    message: 'Missing lastname field',
    vi: 'Thiếu họ'
  },
  MISSING_PASSWORD: {
    status: 422,
    errorCode: 203,
    name: 'MISSING_PASSWORD',
    message: 'Missing password field',
    vi: 'Thiếu mật khẩu'
  },
  MISSING_DATEOFBIRTH: {
    status: 422,
    errorCode: 204,
    name: 'MISSING_DATEOFBIRTH',
    message: 'Missing date of birth field',
    vi: 'Thiếu ngày sinh'
  },
  MISSING_EMAIL: {
    status: 422,
    errorCode: 205,
    name: 'MISSING_EMAIL',
    message: 'Missing email field',
    vi: 'Thiếu email'
  },
  MISSING_OLDPASSWORD: {
    status: 422,
    errorCode: 206,
    name: 'MISSING_OLDPASSWORD',
    message: 'Missing old password field',
    vi: 'Thiếu mật khẩu cũ'
  },
  MISSING_NEWPASSWORD: {
    status: 422,
    errorCode: 207,
    name: 'MISSING_NEWPASSWORD',
    message: 'Missing new password field',
    vi: 'Thiếu mật khẩu mới'
  },
  MISSING_TIMESTAMP: {
    status: 422,
    errorCode: 208,
    name: 'MISSING_TIMESTAMP',
    message: 'Missing timestamp',
    vi: 'Thiếu thời gian'
  },
  MISSING_GLN: {
    status: 422,
    errorCode: 209,
    name: 'MISSING_GLN',
    message: 'Missing GLN field',
    vi: 'Thiếu GLN'
  },
  OLDPASSWORD_MUST_NOT_BE_DIFFERENT_FROM_NEWPASSWORD: {
    status: 422,
    errorCode: 210,
    name: 'OLDPASSWORD_MUST_NOT_BE_DIFFERENT_FROM_NEWPASSWORD',
    message: 'Old pw must not be different from new pw',
    vi: 'Mật khẩu cũ phải khác với mật khẩu mới'
  },
  EMAIL_EXIST: {
    status: 409,
    errorCode: 211,
    name: 'EMAIL_EXIST',
    message: 'Email has already taken',
    vi: 'Email này đã tồn tại'
  },
  MISSING_USERNAME: {
    status: 422,
    errorCode: 212,
    name: 'MISSING_USERNAME',
    message: 'Missing username field',
    vi: 'Thiếu tên đăng nhập'
  },
  USER_EXIST: {
    status: 409,
    errorCode: 211,
    name: 'USER_EXIST',
    message: 'User has already taken',
    vi: 'Người dùng đã sử dụng'
  },
  MISSING_TYPE: {
    status: 422,
    errorCode: 213,
    name: 'MISSING_TYPE',
    message: 'Missing type field',
    vi: 'Thiếu loại'
  },
  MISSING_GCP_TYPE: {
    status: 422,
    errorCode: 213,
    name: 'MISSING_GCP_TYPE',
    message: 'Missing GCP TYPE',
    vi: 'Thiếu loại GCP'
  },
  MISSING_COOPERATIVE_GLN: {
    status: 422,
    errorCode: 214,
    name: 'MISSING_COOPERATIVE_GLN',
    message: 'Missing gln of cooperative field',
    vi: 'Thiếu GLN của hợp tác xã'
  },
  MISSING_FARM_GLN: {
    status: 422,
    errorCode: 215,
    name: 'MISSING_FARM_GLN',
    message: 'Missing gln of farm field',
    vi: 'Thiếu GLN của nông trại'
  },
  MISSING_STATE: {
    status: 422,
    errorCode: 216,
    name: 'MISSING_STATE',
    message: 'Missing state field',
    vi: 'Thiếu loại quốc gia'
  },
  MISSING_RENTER: {
    status: 422,
    errorCode: 217,
    name: 'MISSING_RENTER',
    message: 'Missing renter field',
    vi: 'Thiếu trường thuê'
  },
  MISSING_STEP: {
    status: 422,
    errorCode: 218,
    name: 'MISSING_STEP',
    message: 'Missing step field',
    vi: 'Thiếu các bước'
  },
  MISSING_DETAIL: {
    status: 422,
    errorCode: 219,
    name: 'MISSING_DETAIL',
    message: 'Missing detail field',
    vi: 'Thiếu chi tiết'
  },
  MISSING_TREE_GLN: {
    status: 422,
    errorCode: 220,
    name: 'MISSING_TREE_GLN',
    message: 'Missing gln of tree field',
    vi: 'Thiếu GLN của cây'
  },
  PRODUCTION_AREA_IS_NOT_EXIST: {
    status: 404,
    errorCode: 221,
    name: 'PRODUCTION_AREA_IS_NOT_EXIST',
    message: 'Production area is not exist',
    vi: 'Khu vực sản xuất không tồn tại'
  },
  GLN_OF_FARM_IS_NOT_EXIST: {
    status: 404,
    errorCode: 221,
    name: 'GLN_OF_FARM_IS_NOT_EXIST',
    message: 'Gln of farm is not exist',
    vi: 'GLN của trang trại không tồn tại'
  },
  GLN_OF_COOPERATIVE_IS_NOT_EXIST: {
    status: 400,
    errorCode: 222,
    name: 'GLN_OF_COOPERATIVE_IS_NOT_EXIST',
    message: 'Gln of cooperative is not exist',
    vi: 'GLN của hợp tác xã không tồn tại'
  },
  GLN_OF_TREE_IS_NOT_EXIST: {
    status: 400,
    errorCode: 223,
    name: 'GLN_OF_TREE_IS_NOT_EXIST',
    message: 'Gln of tree is not exist',
    vi: 'GLN của cây trồng không tồn tại'
  },
  GLN_OF_CROP_IS_NOT_EXIST: {
    status: 400,
    errorCode: 606,
    name: 'GLN_OF_CROP_IS_NOT_EXIST',
    message: 'Gln of crop is not exist',
    vi: 'GLN của hồ sơ truy xuất không tồn tại'
  },
  GLN_OF_PRODUCTIONAREA_ALDREADY_EXIST: {
    status: 400,
    errorCode: 607,
    name: 'GLN_OF_PRODUCTIONAREA_ALDREADY_EXIST',
    message: 'Gln of production area aldready exist',
    vi: 'GLN của khu vực sản xuất không tồn tại'
  },
  OBJECT_ALREADY_EXISTED: {
    status: 400,
    errorCode: 607,
    name: 'OBJECT_ALREADY_EXISTED',
    message: 'Object aldready exist',
    vi: 'Sản phẩm đã tồn tại'
  },
  CROP_IS_NOT_EXIST: {
    status: 400,
    errorCode: 609,
    name: 'CROP_IS_NOT_EXIST',
    message: 'Crop is not exist',
    vi: 'Hồ sơ truy xuất không tồn tại'
  },
  BATCH_PROCESSING_IS_NOT_EXIST: {
    status: 400,
    errorCode: 609,
    name: 'BATCH_PROCESSING_IS_NOT_EXIST',
    message: 'Batch processing is not exist',
    vi: 'Xử lý Batch không tồn tại'
  },
  CROP_IS_EXIST: {
    status: 400,
    errorCode: 609,
    name: 'CROP_IS_EXIST',
    message: 'Crop is exist',
    vi: 'Hồ sơ truy xuất tồn tại'
  },
  PROCESSCODE_OF_CROP_IS_NOT_EXIST: {
    status: 400,
    errorCode: 610,
    name: 'PROCESSCODE_OF_CROP_IS_NOT_EXIST',
    message: 'process code of crop is not exist',
    vi: 'Quy trình mã hồ sơ truy xuất không tồn tại'
  },
  GTIN_OF_PRODUCTTYPE_IS_NOT_EXIST: {
    status: 500,
    errorCode: 608,
    name: 'GLN_OF_CROP_IS_NOT_EXIST',
    message: 'Gln of product type is not exist',
    vi: 'GLN của nhóm sản phẩm không tồn tại'
  },
  GTIN_OF_PRODUCT_IS_NOT_EXIST: {
    status: 500,
    errorCode: 609,
    name: 'GLN_OF_PRODUCT_IS_NOT_EXIST',
    message: 'Gln of product is not exist',
    vi: 'GLN của sản phẩm không tồn tại'
  },
  GTIN_OF_CASETYPE_IS_NOT_EXIST: {
    status: 500,
    errorCode: 610,
    name: 'GLN_OF_CASETYPE_IS_NOT_EXIST',
    message: 'Gln of case type is not exist',
    vi: 'GLN của trường hợp không tồn tại'
  },
  BUSINESS_PROCESS_IS_NOT_EXIST: {
    status: 500,
    errorCode: 224,
    name: 'BUSINESS_PROCESS_IS_NOT_EXIST',
    message: 'Business process is not exist',
    vi: 'Quy trình kinh doanh không tồn tại'
  },
  STEP_OF_BUSINESS_PROCESS_IS_NOT_EXIST: {
    status: 404,
    errorCode: 225,
    name: 'STEP_OF_BUSINESS_PROCESS_IS_NOT_EXIST',
    message: 'Step of business process is not exist',
    vi: 'Các bước của quy trình kinh doanh không tồn tại'
  },
  MISSING_ID_OF_TREE: {
    status: 422,
    errorCode: 226,
    name: 'MISSING_ID_OF_TREE',
    message: 'Missing id of tree field',
    vi: 'Thiếu ID của loại cây trồng'
  },
  MISSING_ID_OF_BOX: {
    status: 422,
    errorCode: 226,
    name: 'MISSING_ID_OF_BOX',
    message: 'Missing id of box field',
    vi: 'Thiếu ID của loại hộp'
  },
  MISSING_FROM_ID: {
    status: 422,
    errorCode: 226,
    name: 'MISSING_FROM_ID',
    message: 'Missing from id field',
    vi: 'Thiếu loại ID'
  },
  MISSING_TO_ID: {
    status: 422,
    errorCode: 226,
    name: 'MISSING_TO_ID',
    message: 'Missing to id field',
    vi: 'Thiếu loại ID'
  },
  START_ID_MUST_NOT_BE_GREATER_THAN_END_ID: {
    status: 400,
    errorCode: 227,
    name: 'START_ID_MUST_NOT_BE_GREATER_THAN_END_ID',
    message: 'StartId field must not be greater than endId field',
    vi: 'StartID không được lớn hơn EndID'
  },
  UNIT_STAMP_ARE_NOT_EXIST_OR_INVALID: {
    status: 400,
    errorCode: 228,
    name: 'UNIT_STAMP_ARE_NOT_EXIST_OR_INVALID',
    message: 'id of unit stamps are not exist or invalid',
    vi: 'ID của tem không tồn tại'
  },
  MISSING_START_ID: {
    status: 422,
    errorCode: 229,
    name: 'MISSING_START_ID',
    message: 'Missing start id',
    vi: 'Thiếu ID bắt đầu'
  },
  MISSING_END_ID: {
    status: 422,
    errorCode: 230,
    name: 'MISSING_END_ID',
    message: 'Missing end id',
    vi: 'Thiếu ID kết thúc'
  },
  MISSING_TO_GLN: {
    status: 422,
    errorCode: 231,
    name: 'MISSING_TO_GLN',
    message: 'Missing to GLN field',
    vi: 'Thiếu loại GLN'
  },
  MISSING_STAMP_TYPE: {
    status: 422,
    errorCode: 232,
    name: 'MISSING_STAMP_TYPE',
    message: 'Missing stamptype',
    vi: 'Thiếu loại tem'
  },
  MISSING_PRODUCT_TYPE: {
    status: 422,
    errorCode: 233,
    name: 'MISSING_PRODUCT_TYPE',
    message: 'Missing product type',
    vi: 'Thiếu nhóm sản phẩm'
  },
  MISSING_CATEGORY: {
    status: 422,
    errorCode: 233,
    name: 'MISSING_CATEGORY',
    message: 'Missing category',
    vi: 'Thiếu loại'
  },
  STAMP_ID_IS_USED: {
    status: 403,
    errorCode: 234,
    name: 'STAMP_ID_IS_USED',
    message: 'Stamp id is used',
    vi: 'ID tem đã được sử dụng'
  },
  STAMP_BOX_ID_ARE_NOT_EXIST_OR_INVALID: {
    status: 400,
    errorCode: 235,
    name: 'STAMP_BOX_ID_ARE_NOT_EXIST_OR_INVALID',
    message: 'id of box-stamps are not exist or invalid',
    vi: 'ID của box stamp không tồn tại'
  },
  BOX_ID_IS_USED: {
    status: 403,
    errorCode: 236,
    name: 'BOX_ID_IS_USED',
    message: 'Id of box id is used',
    vi: 'ID của hộp đã được sử dụng'
  },
  CASE_IS_RECEIVE: {
    status: 403,
    errorCode: 234,
    name: 'CASE_IS_RECEIVE',
    message: 'Case is received',
    vi: 'Trường hợp được nhận'
  },
  CASE_IS_DELIVERED: {
    status: 403,
    errorCode: 234,
    name: 'CASE_IS_DELIVERED',
    message: 'Case is delivered',
    vi: 'Trường hợp được giao đi'
  },
  MISSING_BUSINESS_PROCESS: {
    status: 422,
    errorCode: 237,
    name: 'MISSING_BUSINESS_PROCESS',
    message: 'Missing business process',
    vi: 'Thiếu quy trình kinh doanh'
  },
  MISSING_SCOPE: {
    status: 422,
    errorCode: 238,
    name: 'MISSING_SCOPE',
    message: 'Missing scope',
    vi: 'Thiếu phạm vi'
  },
  MISSING_FARM_PHONE: {
    status: 422,
    errorCode: 239,
    name: 'MISSING_FARM_PHONE',
    message: 'Missing phone',
    vi: 'Thiếu số điện thoại'
  },
  MISSING_ADDRESS: {
    status: 422,
    errorCode: 240,
    name: 'MISSING_ADDRESS',
    message: 'Missing address',
    vi: 'Thiếu địa chỉ'
  },
  MISSING_UUID: {
    status: 422,
    errorCode: 240,
    name: 'MISSING_UUID',
    message: 'Missing uuid',
    vi: 'Thiếu uuid'
  },
  MISSING_PHONE: {
    status: 422,
    errorCode: 240,
    name: 'MISSING_PHONE',
    message: 'Missing phone',
    vi: 'Thiếu điện thoại'
  },
  MISSING_PUBLIC_KEY: {
    status: 422,
    errorCode: 241,
    name: 'MISSING_PUBLIC_KEY',
    message: 'Missing public key',
    vi: 'Thiếu public key'
  },
  DO_NOT_HAVE_PRIVILEGE: {
    status: 403,
    errorCode: 241,
    name: 'DO_NOT_HAVE_PRIVILEGE',
    message: 'Do not have privilege',
    vi: 'Không có đặc quyền'
  },
  DISTRIBUTOR_IS_NOT_REGISTERED: {
    status: 403,
    errorCode: 241,
    name: 'DISTRIBUTOR_IS_NOT_REGISTERED',
    message: 'Distriburo is not registered',
    vi: 'Distriburo không có đăng ký'
  },
  CAN_NOT_FIND_PRODUCT_TYPE: {
    status: 404,
    errorCode: 242,
    name: 'CAN_NOT_FIND_PRODUCT_TYPE',
    message: 'Can not find product type in db',
    vi: 'Không tìm thấy nhóm sản phẩm trong hệ thống'
  },
  STAMP_IS_INACTIVE: {
    status: 403,
    errorCode: 243,
    name: 'STAMP_IS_INACTIVE',
    message: 'Stamp is inactive',
    vi: 'Tem không được kích hoạt'
  },
  MISSING_GTIN: {
    status: 422,
    errorCode: 244,
    name: 'MISSING_GTIN',
    message: 'Missing gtin field',
    vi: 'Thiếu loại gtin'
  },
  MISSING_SIGNATURE: {
    status: 422,
    errorCode: 245,
    name: 'MISSING_SIGNATURE',
    message: 'Missing signature',
    vi: 'Thiếu chữ ký'
  },
  PRODUCT_TYPE_HAS_EXISTED: {
    status: 409,
    errorCode: 246,
    name: 'PRODUCT_TYPE_HAS_EXISTED',
    message: 'Product type has existed',
    vi: 'Nhóm sản phẩm đã tồn tại'
  },
  OBJECT_HAS_NOT_EXISTED: {
    status: 409,
    errorCode: 246,
    name: 'OBJECT_HAS_NOT_EXISTED',
    message: 'Object has not existed',
    vi: 'Sản phẩm không tồn tại'
  },
  OBJECT_HAS_EXISTED: {
    status: 409,
    errorCode: 246,
    name: 'OBJECT_HAS_EXISTED',
    message: 'Object has existed',
    vi: 'Sản phẩm đã tồn tại'
  },
  INVALID_STAMPTYPE: {
    status: 400,
    errorCode: 247,
    name: 'INVALID_STAMPTYPE',
    message: 'Invalid stamp type',
    vi: 'Loại tem không hợp lệ'
  },
  INVALID_CATEGORY: {
    status: 400,
    errorCode: 247,
    name: 'INVALID_CATEGORY',
    message: 'Invalid category',
    vi: 'Loại không hợp lệ'
  },
  INVALID_TYPE: {
    status: 400,
    errorCode: 247,
    name: 'INVALID_TYPE',
    message: 'Invalid type',
    vi: 'Loại không hợp lệ'
  },
  FARM_IS_NOT_BELONG_COOPERATIVE: {
    status: 403,
    errorCode: 248,
    name: 'FARM_IS_NOT_BELONG_COOPERATIVE',
    message: 'Farm is not belong cooperative',
    vi: 'Trang trại không thuộc về hợp tác xã'
  },
  MISSING_CONFIRM_PASSWORD: {
    status: 422,
    errorCode: 249,
    name: 'MISSING_CONFIRM_PASSWORD',
    message: 'Missing confirm password',
    vi: 'Thiếu mật khẩu xác nhận'
  },
  MISSING_MESSAGE: {
    status: 422,
    errorCode: 250,
    name: 'MISSING_MESSAGE',
    message: 'Missing message',
    vi: 'Thiếu Message'
  },
  DO_NOT_HAVE_PERMISSION: {
    status: 403,
    errorCode: 251,
    name: 'DO_NOT_HAVE_PERMISSION',
    message: 'Don\'t have permission.',
    vi: 'Không được phép'
  },
  DO_NOT_HAVE_ORDER: {
    status: 403,
    errorCode: 251,
    name: 'DO_NOT_HAVE_ORDER',
    message: 'Don\'t have order.',
    vi: 'Không có lệnh'
  },
  PRODUCT_WAS_SOLD: {
    status: 403,
    errorCode: 251,
    name: 'PRODUCT_WAS_SOLD',
    message: 'Product was sold',
    vi: 'Sản phẩm đã được bán'
  },
  INFORMATION_IS_INCORRECT: {
    status: 400,
    errorCode: 252,
    name: 'INFORMATION_IS_INCORRECT',
    message: 'Information is incorrect',
    vi: 'Thông tin không chính xác'
  },
  MISSING_RAW_TRANSACTION: {
    status: 422,
    errorCode: 253,
    name: 'MISSING_RAW_TRANSACTION',
    message: 'Missing raw transaction',
    vi: 'Thiếu giao dịch raw'
  },
  MISSING_NONCE: {
    status: 422,
    errorCode: 254,
    name: 'MISSING_NONCE',
    message: 'Missing nonce',
    vi: 'Thiếu nonce'
  },
  MISSING_DATE: {
    status: 422,
    errorCode: 255,
    name: 'MISSING_DATE',
    message: 'Missing date',
    vi: 'Thiếu ngày'
  },
  INVALID_GLN: {
    status: 400,
    errorCode: 256,
    name: 'INVALID_GLN',
    message: 'Invalid gln',
    vi: 'GLN không hợp lệ'
  },
  INVALID_GSRN: {
    status: 400,
    errorCode: 256,
    name: 'INVALID_GLN',
    message: 'Invalid gln',
    vi: 'GLN không hợp lệ'
  },
  INVALID_SSCC: {
    status: 400,
    errorCode: 256,
    name: 'INVALID_GLN',
    message: 'Invalid gln',
    vi: 'GLN không hợp lệ'
  },
  MISSING_ALTERNATIVE_NAME: {
    status: 422,
    errorCode: 257,
    name: ' MISSING_ALTERNATIVE_NAME',
    message: 'Missing alternative name',
    vi: 'Thiếu tên thay thế'
  },
  MISSING_TREE_INFO: {
    status: 422,
    errorCode: 258,
    name: ' MISSING_TREE_INFO',
    message: 'Missing tree info',
    vi: 'Thiếu thông tin sản phẩm'
  },
  MISSING_COOPERATIVE_INFO: {
    status: 422,
    errorCode: 259,
    name: ' MISSING_COOPERATIVE_INFO',
    message: 'Missing cooperative info',
    vi: 'Thiếu thông tin hợp tác xã'
  },
  MISSING_PRODUCT_INFO: {
    status: 422,
    errorCode: 260,
    name: ' MISSING_PRODUCT_INFO',
    message: 'Missing product info',
    vi: 'Thiếu thông tin sản phẩm'
  },
  MISSING_DIARY_INFO: {
    status: 422,
    errorCode: 261,
    name: ' MISSING_DIARY_INFO',
    message: 'Missing diary info',
    vi: 'Thiếu thông tin nhật ký'
  },
  PRODUCT_WAS_BOUGHT: {
    status: 423,
    errorCode: 30,
    name: 'PRODUCT_WAS_BOUGHT',
    message: 'Product was bought',
    vi: 'Sản phẩm đã đượoc bán'
  },
  HAS_NOT_EXIST: {
    status: 403,
    errorCode: 30,
    name: 'HAS_NOT_EXIST',
    message: 'has not exist',
    vi: 'Không tồn tại'
  },
  ACCOUNT_HAD_ALREADY_VERIFIED: {
    status: 409,
    errorCode: 50,
    name: 'ACCOUNT_HAD_ALREADY_VERIFIED',
    message: 'Account had already verified',
    vi: 'Tài khoản đã được xác nhận'
  },
  ACCOUNT_HAD_NOT_YET_VERIFIED: {
    status: 401,
    errorCode: 51,
    name: 'ACCOUNT_HAD_NOT_YET_VERIFIED',
    message: 'Account had yet verified',
    vi: 'Tài khoản chưa được xác nhận'
  },
  EMAIL_OR_PASSWORD_IS_INCORRECT: {
    status: 400,
    errorCode: 52,
    name: 'EMAIL_OR_PASSWORD_IS_INCORRECT',
    message: 'Email or password is incorrect!',
    vi: 'Email hoặc mật khẩu không chính xác'
  },
  CAN_NOT_FIND_INFO_OF_FARM: {
    status: 404,
    errorCode: 53,
    name: 'CAN_NOT_FIND_INFO_OF_FARM',
    message: 'Can not find info of farm',
    vi: 'Không thể tìm thấy thông tin trang trại'
  },
  INCORRECT_PASSWORD: {
    status: 400,
    errorCode: 54,
    name: 'INCORRECT_PASSWORD',
    message: 'Password is incorrect',
    vi: 'Mật khẩu không chính xác'
  },
  CAN_NOT_CHECK_VALID_EMAIL: {
    status: 500,
    errorCode: 55,
    name: 'CAN_NOT_CHECK_VALID_EMAIL',
    message: 'Can not check email valid or invalid',
    vi: 'Không thể kiểm tra email hợp lệ hay không'
  },
  CAN_NOT_COUNT_INDEX_IN_DB: {
    status: 500,
    errorCode: 56,
    name: 'CAN_NOT_COUNT_INDEX_IN_DB',
    message: 'Can not count index in db',
    vi: 'Không thể đếm index trong hệ thống'
  },
  CAN_NOT_STORE_TO_DB: {
    status: 500,
    errorCode: 57,
    name: 'CAN_NOT_STORE_TO_DB',
    message: 'Can not store to database',
    vi: 'Không thể lưu trữ trong database'
  },
  CAN_NOT_FIND_GLN_IN_DB: {
    status: 500,
    errorCode: 58,
    name: 'CAN_NOT_FIND_GLN_IN_DB',
    message: 'Can not find gln in DB',
    vi: 'Không thể kiếm GLN trong hệ thống'
  },
  FAIL_TO_ADD_NEW_FARM: {
    status: 500,
    errorCode: 59,
    name: 'FAIL_TO_ADD_NEW_FARM',
    message: 'Fail to add new farm',
    vi: 'Thất bại trong việc thêm trang trại'
  },
  CAN_NOT_FIND_STAMP_INFO_IN_DB: {
    status: 500,
    errorCode: 60,
    name: 'CAN_NOT_FIND_STAMP_INFO_IN_DB',
    message: 'Can not find stamp info in DB',
    vi: 'Không thể tìm thấy thông tin tem trong hệ thống'
  },
  CAN_NOT_CREATE_INDEX_IN_DB: {
    status: 500,
    errorCode: 61,
    name: 'CAN_NOT_CREATE_INDEX_IN_DB',
    message: 'Can not create index in DB',
    vi: 'Không thể tạo index trong hệ thống'
  },
  FAIL_TO_CREATE_TREE: {
    status: 500,
    errorCode: 62,
    name: 'FAIL_TO_CREATE_TREE',
    message: 'Fail to create a new tree',
    vi: 'Thất bại trong việc tạo ra cây mới'
  },
  STAMP_IS_CREATED: {
    status: 409,
    errorCode: 63,
    name: 'STAMP_IS_CREATED',
    message: 'Stamp is created',
    vi: 'Tem đã được tạo'
  },
  CAN_NOT_FIND_INDEX_IN_DB: {
    status: 500,
    errorCode: 64,
    name: 'CAN_NOT_FIND_INDEX_IN_DB',
    message: 'Can not find index in DB',
    vi: 'Không thể tìm thấy index trong hệ thống'
  },
  CAN_NOT_FIND_GTIN_IN_DB: {
    status: 500,
    errorCode: 65,
    name: 'CAN_NOT_FIND_GTIN_IN_DB',
    message: 'Can not find gtin in DB',
    vi: 'Không thể tìm thấy gtin trong hệ thống'
  },
  CAN_NOT_FIND_USER_IN_DB: {
    status: 400,
    errorCode: 66,
    name: 'CAN_NOT_FIND_USER_IN_DB',
    message: 'Can not find user in DB',
    vi: 'Không thể tìm thấy người dùng trong hệ thống'
  },
  INVALID_TYPE_OF_AMOUNT: {
    status: 400,
    errorCode: 67,
    name: 'INVALID_TYPE_OF_AMOUNT',
    message: 'Invalid type of amount',
    vi: 'Loại số lượng không hợp lệ'
  },
  PRODUCT_WAS_STOPPED: {
    status: 403,
    errorCode: 68,
    name: 'PRODUCT_WAS_STOPPED',
    message: 'Product was stopped produce',
    vi: 'Sản phẩm đã được dừng sản xuất'
  },
  FARMER_WAS_STOPPED: {
    status: 403,
    errorCode: 69,
    name: 'FARMER_WAS_STOPPED',
    message: 'Farmer was stopped produce',
    vi: 'Nông dân ngừng sản xuất'
  },
  PRODUCTION_AREA_WAS_STOPPED: {
    status: 403,
    errorCode: 69,
    name: 'PRODUCTION_WAS_STOPPED',
    message: 'Production area was stopped produce',
    vi: 'Khu vực sản xuất ngừng sản xuất'
  },
  // API
  INVALID_ID: {
    status: 400,
    errorCode: 399,
    name: 'INVALID_ID',
    message: 'Id is invalid',
    vi: 'ID không hợp lệ'
  },
  INVALID_EMAIL: {
    status: 400,
    errorCode: 400,
    name: 'INVALID_EMAIL',
    message: 'Invalid email',
    vi: 'Email không hợp lệ'
  },
  INVALID_DATEOFBIRTH: {
    status: 400,
    errorCode: 401,
    name: 'INVALID_DATEOFBIRTH',
    message: 'DateOfBirth is invalid',
    vi: 'Ngày tháng năm sinh không hợp lệ'
  },
  INVALID_TIMESTAMP: {
    status: 400,
    errorCode: 402,
    name: 'INVALID_TIMESTAMP',
    message: 'Timestamp is invalid',
    vi: 'Timestamp không hợp lệ'
  },
  INVALID_GTIN: {
    status: 400,
    errorCode: 403,
    name: 'INVALID_GTIN',
    message: 'Gtin is invalid',
    vi: 'GTIN không hợp lệ'
  },
  INVALID_STAMP_ID: {
    status: 400,
    errorCode: 405,
    name: 'INVALID_STAMP_ID',
    message: 'stamp id is invalid',
    vi: 'ID tem không hợp lệ'
  },
  INVALID_STAMP_BOX: {
    status: 400,
    errorCode: 406,
    name: 'INVALID_STAMP_BOX',
    message: 'Id of stamp box is invalid',
    vi: 'ID của thùng tem không hợp lệ'
  },

  PAGE_NOT_FOUND: {
    status: 404,
    errorCode: 404,
    name: 'PAGE_NOT_FOUND',
    message: 'Page not found',
    vi: 'Trang không tìm thấy'
  },
  USER_NAME_OR_PASSWORD_WAS_INVALID: {
    status: 400,
    errorCode: 406,
    name: 'USER_NAME_OR_PASSWORD_WAS_INVALID',
    message: 'usename or password was invalid',
    vi: 'Tên đăng nhập hoặc mật khẩu không hợp lệ'
  },
  ERROR_SERVER: {
    status: 500,
    errorCode: 500,
    name: 'ERROR_SERVER',
    message: 'Error server',
    vi: 'Lỗi máy chủ'
  },

  GLN_USED: {
    status: 409,
    errorCode: 600,
    name: 'GLN_USED',
    message: 'GLN is used',
    vi: 'GLN đã được sử dụng'
  },

  TX_SAME_HASH: {
    status: 500,
    errorCode: 601,
    name: 'TX_SAME_HASH',
    message: 'Transaction with the same hash was already imported.',
    vi: 'Giao dịch với một hash đã được nhập'
  },

  NONCE_TO_LOW: {
    status: 500,
    errorCode: 602,
    name: 'NONCE_TO_LOW',
    message: 'Transaction nonce is too low. Try incrementing the nonce.',
    vi: 'Giao dịch nonce quá thấp. Hãy thử tăng số nonce'
  },

  TX_REVERTED: {
    status: 500,
    errorCode: 603,
    name: 'TX_REVERTED',
    message: 'Transaction has been reverted by the EVM',
    vi: 'Giao dịch đã được hoàn nguyên bởi EVM'
  },

  RAWTX_ISNULL: {
    status: 500,
    errorCode: 604,
    name: 'RAWTX_ISNULL',
    message: 'Raw Transaction is null',
    vi: 'Giao dịch thô là null'
  },

  BC_atStep: {
    status: 500,
    errorCode: 606,
    name: 'BC_atStep',
    message: 'Error when execute BC Steps',
    vi: 'Lỗi khi thực hiện các bước BC'
  },

  MANY_REQUEST: {
    status: 429,
    errorCode: 429,
    name: 'MANY_REQUEST',
    message: 'Too many accounts created from this IP, please try again 15 minutes',
    vi: 'Quá nhiều tài khoản được tạo từ IP này, vui lòng thử lại sau 15 phút'
  },

  OUT_OF_RANGE_IN_ARRAY: {
    status: 429,
    errorCode: 429,
    name: 'OUT_OF_RANGE_IN_ARRAY',
    message: 'out of range in array',
    vi: 'Ngoài phạm vi trong mảng'
  },
  INVALID_PARAMS: {
    status: 429,
    errorCode: 429,
    name: 'INVALID_PARAMS',
    message: 'invalid params',
    vi: 'Thông số không hợp lệ'
  },
  STAMP_BOX_IS_USED: {
    status: 429,
    errorCode: 429,
    name: 'STAMP_BOX_IS_USED',
    message: 'Stamp box is used',
    vi: 'Mã tem thùng đã được sử dụng'
  },
  STAMP_UNIT_IS_USED: {
    status: 429,
    errorCode: 429,
    name: 'STAMP_UNIT_IS_USED',
    message: 'Stamp unit is used',
    vi: 'Mã tem sản phẩm đã được sử dụng'
  },
  MISSING_FIELD: {
    status: 422,
    errorCode: 226,
    name: 'MISSING_FIELD',
    message: 'Missing field',
    vi: 'Thiếu trường'
  },
  EMPLOYEE_DOES_NOT_EXIST: {
    status: 404,
    errorCode: 226,
    name: 'EMPLOYEE_DOES_NOT_EXIST',
    message: 'Employee does not existed',
    vi: 'Nhân viên không tồn tại trong hệ thống'
  },
  INVALID_PRICE: {
    status: 422,
    errorCode: 261,
    name: ' INVALID_PRICE',
    message: 'Invalid price',
    vi: 'Giá không hợp lệ'
  },
  INVALID_DATE: {
    status: 422,
    errorCode: 261,
    name: ' INVALID_DATE',
    message: 'Invalid date',
    vi: 'Thời gian không hợp lệ'
  },
  NUMBER_OF_EMPLOYEES_EXCEEDS_MAXIMUM: {
    status: 403,
    errorCode: 261,
    name: ' NUMBER_OF_EMPLOYEES_EXCEEDS_MAXIMUM',
    message: 'The number of employees exceeds the permitted number',
    vi: 'Số lượng nhân viên vượt quá số lượng cho phép'
  },
  NUMBER_OF_OBJECT_EXCEEDS_MAXIMUM: {
    status: 403,
    errorCode: 261,
    name: ' NUMBER_OF_OBJECT_EXCEEDS_MAXIMUM',
    message: 'The number of objects exceeds the permitted number',
    vi: 'Số lượng sản phẩm vượt quá số lượng cho phép'
  },
  NUMBER_OF_PRODUCT_TYPE_EXCEEDS_MAXIMUM: {
    status: 403,
    errorCode: 261,
    name: ' NUMBER_OF_PRODUCT_TYPE_EXCEEDS_MAXIMUM',
    message: 'The number of product type exceeds the permitted number',
    vi: 'Số lượng nhóm sản phẩm vượt quá số lượng cho phép'
  },
  NUMBER_OF_ACTIONS_EXCEEDS_MAXIMUM: {
    status: 403,
    errorCode: 261,
    name: ' NUMBER_OF_ACTIONS_EXCEEDS_MAXIMUM',
    message: 'The number of actions exceeds the permitted number',
    vi: 'Số lượng hành động vượt quá số lượng cho phép'
  },
  SUBSCRIPTION_HAS_EXISTED: {
    status: 404,
    errorCode: 226,
    name: 'SUBSCRIPTION_HAS_EXISTED',
    message: 'Subscription has existed',
    vi: 'Tài khoản đã được đăng ký'
  },
  ITEMS_MUST_HAS_AT_LEAST_ONE_PRODUCT: {
    status: 403,
    errorCode: 226,
    name: 'ITEMS_MUST_HAS_AT_LEAST_ONE_PRODUCT',
    message: 'Phải có ít nhất 1 sản phẩm'
  },
  RAW_TX_MUST_HAS_AT_LEAST_ONE_ELEMENT: {
    status: 403,
    errorCode: 226,
    name: 'RAW_TX_MUST_HAS_AT_LEAST_ONE_ELEMENT',
    message: 'RawTx phải có ít nhất 1 phần từ'
  },
  REQUEST_WAS_APPROVED: {
    status: 403,
    errorCode: 226,
    name: 'REQUEST_WAS_APPROVED',
    message: 'Request was aproved'
  },
  MODE_HAS_ALREADY_SETUP: {
    status: 403,
    errorCode: 226,
    name: 'MODE_HAS_ALREADY_SETUP',
    message: 'Mode has already setup'
  },
  CASE_IS_SOLD: {
    status: 403,
    errorCode: 234,
    name: 'CASE_IS_SOLD',
    message: 'Case is sold'
  },
  INVALID_ROLE: {
    status: 403,
    errorCode: 400,
    name: 'INVALID_ROLE',
    message: 'Loại tài khoản không hợp lệ'
  },
  ACCOUNT_IS_NOT_ACTIVE: {
    status: 401,
    errorCode: 51,
    name: 'ACCOUNT_IS_NOT_ACTIVE',
    message: 'Tài khoản chưa được kích hoạt'
  },
  RFID_EXIST: {
    status: 409,
    errorCode: 211,
    name: 'RFID_EXIST',
    message: 'Mã RFID đã tồn tại'
  },
  EMAIL_HAD_ALREADY_VERIFIED: {
    status: 409,
    errorCode: 50,
    name: 'EMAIL_HAD_ALREADY_VERIFIED',
    message: 'Email đã được xác thực'
  },
  PHONE_HAD_ALREADY_VERIFIED: {
    status: 409,
    errorCode: 50,
    name: 'PHONE_HAD_ALREADY_VERIFIED',
    message: 'Số điện thoại đã được xác thực'
  },
  SCHEDULE_DOES_NOT_EXIST: {
    status: 400,
    errorCode: 609,
    name: 'SCHEDULE_DOES_NOT_EXIST',
    message: 'Lịch không tồn tại'
  },
  EMAIL_HAS_NOT_BEEN_REGISTERED: {
    status: 409,
    errorCode: 50,
    name: 'EMAIL_HAS_NOT_BEEN_REGISTERED',
    message: 'Email chưa được đăng ký'
  },
  PHONE_HAS_NOT_BEEN_REGISTERED: {
    status: 409,
    errorCode: 50,
    name: 'PHONE_HAS_NOT_BEEN_REGISTERED',
    message: 'Số điện thoại không'
  },
  INVALID_PHONE_NUMBER: {
    status: 400,
    errorCode: 50,
    name: 'INVALID_PHONE_NUMBER',
    message: 'Số điện thoại không hợp lệ'
  },
  INVALID_CODE: {
    status: 400,
    errorCode: 50,
    name: 'INVALID_CODE',
    message: 'Mã không hợp lệ'
  },
  ID_IS_USED: {
    status: 400,
    errorCode: 50,
    name: 'ID_IS_USED',
    message: 'Id đã được sử dụng'
  },
  CATTLE_WAS_DIED_OR_STOLEN: {
    status: 400,
    errorCode: 50,
    name: 'CATTLE_WAS_DIED_OR_STOLEN',
    message: 'Cattle was died or stolen'
  },
  TOKEN_EXPIRED: {
    status: 401,
    errorCode: 3,
    name: 'TOKEN_EXPIRED',
    message: 'Token hết hạn'
  },
  INVALID_CAPTCHA: {
    status: 401,
    errorCode: 3,
    name: 'INVALID_CAPTCHA',
    message: 'Captcha không hợp lệ'
  },
  MISSING_CAPTCHA: {
    status: 401,
    errorCode: 7,
    name: 'MISSING_CAPTCHA',
    message: 'Thiếu captcha'
  },
  USER_HAS_NOT_BEEN_REGISTERED: {
    status: 409,
    errorCode: 50,
    name: 'USER_HAS_NOT_BEEN_REGISTERED',
    message: 'Tài khoản chưa được đăng ký'
  },
  PHONE_EXIST: {
    status: 409,
    errorCode: 211,
    name: 'PHONE_EXIST',
    message: 'Số điện thoại đã tồn tại trong hệ thống'
  },
  INVALID_USERNAME: {
    status: 401,
    errorCode: 3,
    name: 'INVALID_USERNAME',
    message: 'Tên đăng nhập không hợp lệ!'
  },
  CONNECTION_FAIL: {
    status: 444,
    errorCode: 444,
    name: 'CONNECTION_FAIL',
    message: 'Không thể kết nối!'
  },
  EXCEED_NUMBER: {
    status: 410,
    errorCode: 50,
    name: 'EXCEED_NUMBER',
    message: "Số điện thoại đã tồn tại"
  },
  NUMBER_OF_CROP_REACH_MAXIMUM_THRESHOLD: {
    status: 500,
    errorCode: 5,
    name: 'NUMBER_OF_CROP_REACH_MAXIMUM_THRESHOLD',
    message: 'Số hồ sơ truy xuất tối đa! Không thể tạo thêm!'
  },
  SYSTEM_MAINTENANCE: {
    status: 503,
    errorCode: 503,
    name: 'SYSTEM_MAINTENANCE',
    message: 'Hệ thống đang bảo trì! Vui lòng thử lại sau!'
  },
}

export default ErrorCode
