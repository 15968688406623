import LoadingTable from "components/LoadingTable/index.js";
import Pagination from "components/Pagination/index.jsx";
import moment from 'moment';
import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ReactTable from 'react-table-6';
import Truncate from 'react-truncate';
import { Card, Container } from 'reactstrap';
import "../../css/reactTable.css";
import { motion } from "framer-motion";
import Header from "components/Headers/Header"
function ProductAreaList(props) {
  const getActionsList = props.getActionsList;
  const [dataActionsList, setdataActionsList] = React.useState([])
  const [loadingState, setLoading] = React.useState(true)
  const {t} = props;

  const genTable = React.useCallback((productAreaList) => {
    let data = productAreaList.map((prop, index) => {
      return {
        id: prop.uuid,
        name: prop.step,
        time: moment(prop.date).format("DD/MM/YYYY hh:mm:ss"),
        who: prop.from_name,
        // object: <div>
        //   <p>{prop.toObject.name}</p>
        //   <p>{"(" + prop.toObject.uuid + ")"}</p>
        // </div>,
        des:
          <div>
            <Truncate lines={2} ellipsis={<span>...</span>}>
              {prop.step_desc}
            </Truncate>
          </div>
      }
    })
    setdataActionsList(data)
    setLoading(false)
  }, [])

  useEffect(() => {
    getActionsList()
  },[getActionsList]);

  const actionList = props.actionList.data
  useEffect(() => {
      if (actionList && actionList.length > 0) {
        genTable(actionList)
      } else {
        genTable([])
      }

    },[actionList, genTable]);


  const columns = React.useMemo(
    () => [
     
      {
        Header: t('activities.time'),
        accessor: "time",
        sortable: false
      },
      {
        Header: t('activities.name'),
        accessor: "name",
        filterable: false
      },
      {
        Header: t('activities.who'),
        accessor: "who",
        filterable: false
      },
      {
        Header: t('activities.des'),
        accessor: "des",
        filterable: false
      },
      // {
      //   Header: t('activities.object'),
      //   accessor: "object",
      //   filterable: false
      // },
    ],
    [t]
  );



  return (

    <>
      <motion.div
                className="w-100"
                initial={{ x: -100 }}
                animate={{ x: 0 }}
                transition={{ ease: "easeOut", duration: 0.3 }}
            >
         <Header/>
              <Container className='mt--7' fluid>
      <Card className="card-box mb-5">
        <div className="card-header py-3 d-flex justify-content-between">
          <div className=" font-weight-bold card-header--title font-size-lg">{t('activities.list')}</div>
        </div>

        <div className="divider" />
        <div className="card-body pt-2 px-4 pb-4">

          <ReactTable
            noDataText={t('noData')}
            data={dataActionsList}
            columns={columns}
            previousText={"<"}
            nextText={">"}
            rowsText= {t('row')}
            ofText="/"
            defaultPageSize={5}
            showPaginationBottom={true}
            sortable={false}
            resizable={true}
            PaginationComponent={Pagination}
            className="-striped -highlight"
            loading={loadingState}
            LoadingComponent={LoadingTable}
          />
        </div>
      </Card>
     </Container>
      </motion.div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
    userProfile: state.userProfile.data,
    actionList: state.approveList,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getActionsList: () => dispatch({ type: "GET_LIST_APPROVE" }),
});

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(ProductAreaList));