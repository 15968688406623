import {
  GET_LIST_APPROVE,
  GET_LIST_APPROVE_FAILURE,
  GET_LIST_APPROVE_SUCCESS,

  SAVE_PACKAGE,
  SAVE_PACKAGE_SUCCESS
} from "actions/types"
import axios from 'axios'
import { LINK_API } from 'constants/API.js'
import { call, put, select, takeLatest } from 'redux-saga/effects'
  
  const getToken = (state) => state.userInfo
  
// Get approve list
function * getApproveList () {
    try {
      const tokenInfo = yield select(getToken)
      const response = yield call(fetchApproveList, tokenInfo.data.token)
      const data = response.data
      yield put({ type: GET_LIST_APPROVE_SUCCESS, data: data.data, errorCode: data.errorCode })
    } catch (error) {
      yield put({ type: GET_LIST_APPROVE_FAILURE, payload: '', errorCode: 0 })
    }
  }
  function fetchApproveList (checktoken) {
    axios.defaults.headers.common.Authorization = checktoken
    return axios({
      method: 'GET',
      url: LINK_API.GET_APPORVE_LIST
    })
  }
function * savePackage(data){
  yield put({ type: SAVE_PACKAGE_SUCCESS, data: data.data })

}

// Get approve list admin
// function * getApproveListAdmin () {
//   try {
//     const tokenInfo = yield select(getToken)
//     const response = yield call(fetchApproveListAdmin, tokenInfo.data.token)
//     const data = response.data
//     yield put({ type: GET_ACTION_LIST_ADMIN_SUCCESS, data: data.data, errorCode: data.errorCode })
//   } catch (error) {
//     yield put({ type: GET_ACTION_LIST_ADMIN_FAILURE, payload: '', errorCode: 0 })
//   }
// }
// function fetchApproveListAdmin (checktoken) {
//   axios.defaults.headers.common.Authorization = checktoken
//   return axios({
//     method: 'GET',
//     url: LINK_API.GET_ACTION_LIST_ADMIN
//   })
// }

  const actions = [
    takeLatest(GET_LIST_APPROVE, getApproveList),
    takeLatest(SAVE_PACKAGE, savePackage),
   // takeLatest(GET_ACTION_LIST_ADMIN, getApproveListAdmin)
  ]
  export default actions
  