import {
  GET_STAMP_LOCATION,
  GET_STAMP_LOCATION_SUCCESS,
  GET_STAMP_LOCATION_FAILURE,
} from "actions/types";
import { takeLatest, call, put, select } from 'redux-saga/effects'
import { LINK_API } from 'constants/API.js'
import axios from 'axios'
const getToken = (state) => state.userInfo;

// Get user profile
function* getStampScan(payload) {
  try {
    const token = yield select(getToken)
    const response = yield call(fetchScan, payload.startTime, payload.endTime, token.data.token);
    yield put({ type: GET_STAMP_LOCATION_SUCCESS, data: response.data.data })
  } catch (error) {
    yield put({ type: GET_STAMP_LOCATION_FAILURE })
  }
}

function fetchScan(startTime, endTime, checktoken) {
  axios.defaults.headers.common.Authorization = checktoken
  return axios({
    method: 'GET',
    url: LINK_API.GET_STAMP_LOCATION + "startTime=" + startTime + "&endTime=" + endTime
  })
}

const actions = [
  takeLatest(GET_STAMP_LOCATION, getStampScan)
]
export default actions

