import clsx from 'clsx';
import BreadCrumbs from "layout-components/BreadCrumbs";
import React from 'react';
import { connect } from 'react-redux';
import {Card, Row, Col} from "reactstrap"
import './index.css'
function scrollFunction() {
  let navbar = document.getElementById("navBar");
  if (navbar) {
      if (window.pageYOffset >= 0) {
          navbar.classList.add("sticky")
      } else {
          navbar.classList.remove("sticky");
      }
  }
}
const PageTitle = (props) => {
  const {
    pageTitleStyle,
    pageTitleBackground,
    pageTitleShadow,
    children
  } = props;

  React.useEffect(() => {
    window.onscroll = function () { scrollFunction() }
}, [])
  return (
    <>
    <Card className='p-3 mb-3'>
    <div
        className={clsx('app-page-title', pageTitleStyle, pageTitleBackground, {
          'app-page-title--shadow': pageTitleShadow
        })}>
            <Row>
                <Col md='8'>
                <BreadCrumbs/>
                </Col>
                <Col md='4' className='d-flex justify-content-end mr-auto'>
          {children}
        </Col>
            </Row>
       
       
      </div>
    </Card>
    </>
  );
};

const mapStateToProps = (state) => ({
  pageTitleStyle: state.ThemeOptions.pageTitleStyle,
  pageTitleBackground: state.ThemeOptions.pageTitleBackground,
  pageTitleShadow: state.ThemeOptions.pageTitleShadow,
  pageTitleIconBox: state.ThemeOptions.pageTitleIconBox,
  pageTitleDescription: state.ThemeOptions.pageTitleDescription
});

export default connect(mapStateToProps)(PageTitle);
