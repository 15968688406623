import axios from 'axios';
import LoadingTable from "components/LoadingTable/index.js";
import Pagination from "components/Pagination/index.jsx";
import SubTable from "components/SubTable/table.js";
import { LINK_API } from 'constants/API.js';
import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ReactTable from 'react-table-6';
import "../../css/reactTable.css";

function StampList(props) {
    const [activityRender, setActivity] = React.useState([])
    const [loadingState, setLoading] = React.useState(true)
    const { t } = props;

    const {
        userInfo
    } = props;

    const genTable = React.useCallback((data) => {
        let dataRender = data.map((item, index) => {
            let dataDetail = []
            if (item.CropInfo && item.CropInfo.length > 0) {
                dataDetail = item.CropInfo.map(itemCrop => {
                    const lengOfList = itemCrop.DiaryInfo.length;
                    return {
                        cropUuid: itemCrop.uuid,
                        status: itemCrop.status === 1 ? t('product.inProduction') : t('product.stopProduction'),
                        harvest: <div style={{ maxHeight: 215, overflowY: "scroll" }}>
                            {
                                itemCrop.DiaryInfo.map((itemDiaryInfo, index) => {
                                    return <div>
                                        <div>
                                            {
                                                `- ${t('oProduction.timeCrop')} : ${itemDiaryInfo.date}`
                                            }
                                        </div>
                                        <div className="ml-2">
                                            {
                                                `+ ${t('statistic.stepName')} : ${itemDiaryInfo.stepName}`
                                            }
                                        </div>
                                        {
                                            index < lengOfList - 1 ? <hr /> : ""
                                        }

                                    </div>
                                })
                            }
                        </div>
                    }
                })
            }
            return {
                objectUuid: item.uuid,
                objectName: item.name,
                dataDetail: dataDetail
            }
        })
        setActivity(dataRender)
        setLoading(false)
    }, [t])

    const getData = async (startDate, endDate) => {
        axios.defaults.headers.common.Authorization = userInfo.data.token
        const response = await axios({
            method: 'GET',
            url: LINK_API.GET_ALL_DIARY_STATISTIC.concat('?startTime=', startDate, '&endTime=', endDate)
        })
        if (response && response.data && response.data.data) {
            genTable(response.data.data)
        }
        else {
            genTable([])
        }
    }

    useEffect(() => {
        setLoading(true)
        getData(props.startDate, props.endDate)

    }, [props.startDate, props.endDate]);


    const columns = React.useMemo(
        () => [
            {
                accessor: "id",
                filterable: false,
                className: "left",
                maxWidth: 50,
                Header: () => (
                    <div style={{
                        textAlign: ""
                    }}>

                    </div>
                )
            },
            {
                Header: t('oProduction.id'),
                accessor: "objectUuid",
                sortable: false
            },
            {
                Header: t('oProduction.name'),
                accessor: "objectName",
                filterable: false
            }
        ],
        [t]
    );



    const columnSubTable = [
        {
            dataField: 'cropUuid',
            text: t('oProduction.crop'),
        },
        {
            dataField: 'status',
            text: t('product.status'),
        },
        {
            dataField: 'harvest',
            text: t('oProduction.diary'),
        },
    ]

    return (

        <>
            <div className="divider" />
            <div className="card-body pt-2 px-4 pb-4">
                <ReactTable
                    noDataText={t('noData')}
                    data={activityRender}
                    columns={columns}
                    previousText={"<"}
                    nextText={">"}
                    rowsText={t('row')}
                    ofText="/"
                    // manual
                    defaultPageSize={5}
                    showPaginationBottom={true}
                    sortable={false}
                    resizable={true}
                    PaginationComponent={Pagination}
                    // pages={totalPage}
                    className="-striped -highlight"
                    loading={loadingState}
                    LoadingComponent={LoadingTable}
                    SubComponent={row => {
                        return (
                            <SubTable columns={columnSubTable} sizePerPage={5} data={row.original.dataDetail} />
                        )
                    }}
                // onFetchData={async (state, instance) => {
                //   setLoading(true)
                //   let x = state.page + 1;
                //   const page = x;
                //   const size = state.pageSize;
                // }}
                />
            </div>
            {/* </Card> */}
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.userInfo,
        diaryWithObject: state.diaryWithObject
    }
}

const mapDispatchToProps = (dispatch) => ({
    getAllDiaryWithObject: (data) => dispatch({ type: "GET_ALL_DIARY_WITH_OBJECT", data: data }),
});

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(StampList));