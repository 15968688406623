import "components/dropZone/css/dropZone.css";
import ReplaceIcon from "mdi-react/FileReplaceIcon";
import React from "react";
import { UploadCloud } from 'react-feather';
import { withTranslation } from 'react-i18next';
import { Button, Modal, ModalBody, ModalHeader, UncontrolledTooltip } from "reactstrap";
import MobileIcon from "./mobileIcon";
const DropZone = (props) => {
    const sizeOut = props.sizeOut ? props.sizeOut : "40";
    const sizeIn = props.sizeIn ? props.sizeIn : "20";
    const sizeIcon = props.sizeIcon ? props.sizeIcon : "20";
    const mTop = props.mTop ? props.mTop : "-30px"
    const mLeft = props.mLeft ? props.mLeft : "13px"
    const [iconIndex, setIconIndex] = React.useState(props.index);
    const [icon, setIcon] = React.useState(null);
    const [modal, setModal] = React.useState(false);
    const t = props.t;
    const toggleModal = () => {
        setModal(!modal)
    }
    const propsIndex = props.index;
    React.useEffect(()=>{
        if(propsIndex){
            setIconIndex(propsIndex)
        }
    },[propsIndex])
    const handleSetIcon = (idx) => {
        toggleModal();
        setIcon(MobileIcon[idx].src);
        if (props.submit) {
            {
                props.submit(MobileIcon[idx].name, idx)
            }
        }
    }
    return (
        <>
            <div className="d-flex flex-row">
                {icon ? <div className={"p-2 mr-3 bg-white shadow-xxl border-dark card-box d-flex unSetOverflow rounded-sm d-" + sizeOut} style={{ height: sizeOut, width: sizeOut }}>
                    <div className="img-fluid img-fit-container rounded-sm containIcon" style={{ backgroundImage: `url(${icon})` }} >
                        <div style={{ marginTop: mTop, marginLeft: mLeft }}>
                            <Button onClick={toggleModal} color="neutral-info" className="btn-icon btn-animated-icon btn-transition-none d-20 p-0 m-2" id={"replaceImageAva"}>
                                <span className="btn-wrapper--icon d-flex">
                                    <ReplaceIcon className="font-size-xs text-info"  size={10}/>
                                </span>
                            </Button>
                            <UncontrolledTooltip trigger="hover" popperClassName="tooltip-black" placement="top" container="body" target={"replaceImageAva"}>
                                {t('replace')}
                            </UncontrolledTooltip>
                        </div>
                    </div>
                </div> :
                    (MobileIcon[iconIndex].src ? <div className={"p-2 mr-3 bg-white shadow-xxl border-dark card-box d-flex unSetOverflow rounded-sm d-" + sizeOut} style={{ height: sizeOut, width: sizeOut }}>
                        <div className="img-fluid img-fit-container rounded-sm containIcon" style={{ backgroundImage: `url(${MobileIcon[iconIndex].src})` }} >
                            <div style={{ marginTop: mTop, marginLeft: mLeft }}>
                                <Button onClick={toggleModal} color="neutral-info" className="btn-icon btn-animated-icon btn-transition-none  d-20 p-0 m-2" id={"replaceImageAva"}>
                                    <span className="btn-wrapper--icon d-flex">
                                        <ReplaceIcon className="font-size-xs text-info" size={10}/>
                                    </span>
                                </Button>
                                <UncontrolledTooltip trigger="hover" popperClassName="tooltip-black" placement="top" container="body" target={"replaceImageAva"}>
                                    {t('replace')}
                                </UncontrolledTooltip>
                            </div>
                        </div>
                    </div>
                        : <div style={{ height: sizeOut, width: sizeOut, marginLeft: "0px" }}>
                            <div className="dropzone-upload-wrapper" onClick={toggleModal}>
                                <div className="dropzone-inner-wrapper dropzone-inner-wrapper-alt borderBlack">
                                    <div className={"d-flex justify-content-center align-items-center d-" + sizeOut} style={{ height: sizeOut, width: sizeOut }}>
                                        <div className={"btn-icon hover-scale-rounded bg-white shadow-light-sm rounded-lg text-first d-" + sizeIn} style={{ height: sizeIn, width: sizeIn }}>
                                            <UploadCloud className={"d-" + sizeIcon} style={{ height: sizeIcon, width: sizeIcon }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>)}
            </div>
            <Modal zIndex={2000} centered isOpen={modal} toggle={toggleModal} className="w-80">
                <ModalHeader toggle={toggleModal}>{t('process.chooseIcon')}</ModalHeader>
                <ModalBody>
                    {MobileIcon.map((item, idx) => (
                        <img key={idx} src={item.src} onClick={() => handleSetIcon(idx)} className="mx-1 my-2 iconMobile" />
                    ))}
                </ModalBody>
            </Modal>
        </>
    )
}
export default withTranslation('common')(DropZone);