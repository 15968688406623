import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import {
  Button,
  Card,
  NavLink as NavLinkStrap,
  Nav,
  NavItem,
  TabContent, TabPane, Container
} from "reactstrap";
//style
import "view/css/select.css";
// import { HeaderOfPage } from '../../../layout-components';
import clsx from 'clsx';
import ActivateStampForHarvest from "./activateStampForHarvest";
import ActivateStampForProductType from "./activateStampForProductType";
import Header from "components/Headers/Header"

const ActivateStamp = (props) => {
  const t = props.t;
  const {
    getAllHarvest,
    renderProductList,
    getValidStampRange
  } = props;

  const [activeTab, setActiveTab] = React.useState('1');

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };


  React.useEffect(() => {
    getAllHarvest();
    getValidStampRange();
    renderProductList(1, 100);
  }, [])

  const tabList = [
    {
      name: "Thêm nhật ký",
      index: 1,
    },
    {
      name: "Kích hoạt tem",
      index: 2,
    }
  ]


  const reportTab = (
    <div className="d-flex w-100">
      <div className="nav-tabs-success tabs-animated" style={{ width: "100%" }}>
        <Nav tabs>
          {
            tabList.map(item => {
              return (
                <NavItem>
                  <Button block color="link" size="sm" className="pl-0" onClick={() => item.index === 1 ? props.history.push("/employ/object/list") : {}}>
                    <span className={`px-0 py-0 ${item.index === 2 ? "text-success" : "text-black-50"}`} style={{ fontSize: 14 }}>{item.name}</span>
                  </Button>
                </NavItem>
              )
            })
          }
        </Nav>
      </div>
    </div>
  )

  return (
    <>
      {/* <HeaderOfPage
        styleFromChildren="hiddenOnLg"
        children={reportTab}
      /> */}
      <Header/>
      <Container fluid className='mt--7'>
      <div className="d-flex justify-content-center">
        <Card className="card-box mb-5 w-lg-75">
          <div className="wizard-steps horizontal">
              <div className="p-4">
                <h5 className="font-size-xl font-weight-bold">{t('stamp.activateStamp')}</h5>
                <p className="text-black-50 mb-4">{t('stamp.enterInfoActivateStamp')}</p>
                <div className="divider" />
                <div className="nav-tabs-success tabs-animated tabs-animated-line mb-4 pl-0 mt-2">
                  <Nav tabs className="pl-0">
                  </Nav>
                </div>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    <ActivateStampForHarvest />
                  </TabPane>
                  <TabPane tabId="2">
                    <ActivateStampForProductType />
                  </TabPane>
                </TabContent>
              </div>
          </div>
        </Card>
      </div>
      </Container>
      {alert}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getAllHarvest: () => dispatch({ type: "GET_HARVEST_LIST" }),
  getValidStampRange: () => dispatch({ type: "GET_VALID_STAMPS_RANGE" }),
  renderProductList: (page, size) => dispatch({ type: "RENDER_PRODUCT_LIST", page: page, size: size }),
});

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(ActivateStamp));