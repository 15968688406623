import LoadingTable from "components/LoadingTable/index.js";
import Pagination from "components/Pagination/index.jsx";
import moment from 'moment';
import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ReactTable from 'react-table-6';
import { Card, Container, Button, UncontrolledTooltip } from 'reactstrap';
import "../../css/reactTable.css";
import cookie from 'react-cookies';
import SubTable from "components/SubTable/table.js";
import Header from "components/Headers/Header"
import PlusCircle from "mdi-react/PlusCircleIcon";
import SearchIcon from "mdi-react/SearchIcon"

function StampList(props) {
  const getUsedStampList = props.getUsedStampList;
  const [dataStampList, setStampList] = React.useState([])
  const [totalPage, setTotalPage] = React.useState([])
  const [loadingState, setLoading] = React.useState(true)
  const { t } = props;

  useEffect(() => {
    const idRecord = cookie.load("diaryRecord");
    const page = 1;
    const size = 5;
    const data = {
      maxId: 99999999999999,
      minId: 0,
      page: page,
      size: size,
      diaryLedgerUuid: idRecord
    }

    getUsedStampList(data)
  }, [getUsedStampList]);

  const stampListData = props.usedStamp.data
  const totalPageProps = props.usedStamp.totalPage
  useEffect(() => {
    if (stampListData && stampListData.length > 0) {
      // genTable(stampListData)
      setTotalPage(totalPageProps)
      let used = stampListData.map((item, index) => {
        let dataDetail = []
        if (item.data && item.data.length > 0) {
          dataDetail = item.data.map(prop => {
            return {
              stampValue: `${prop.startId} - ${prop.endId}`,
              total: parseInt(prop.endId) - parseInt(prop.startId) + 1,
              activeTime: moment(prop.date).format("DD/MM/YYYY")
            }
          })
        }

        return {
          ...item,
          id: index + 1,
          dataDetail,
          actions: <div className="d-flex flex-row justify-content-center">
            <Button onClick={() => {
              const stampID = item.data[0].startId;
              const url = `${"https://etrace.kontum.vn/q/"}${stampID}`;
              window.open(url, '_blank');
            }} color="neutral-success" className="mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center" id={"empTooltipEdit" + index}>
              <span className="btn-wrapper--icon d-flex">
                <SearchIcon
                  size={18}
                  className="text-success font-size-sm"
                />
              </span>
            </Button>

            <UncontrolledTooltip trigger="hover" popperClassName="tooltip-black" placement="top" container="body" target={"empTooltipEdit" + index}>
              Thêm thông tin trên trang tra cứu
              </UncontrolledTooltip>
          </div>
        }
      })

      setStampList(used)
      setLoading(false)
    } else {
      // genTable([])
      setStampList([])
      setLoading(false)
      setTotalPage(0)
    }

  }, [totalPageProps, stampListData]);


  const columns = React.useMemo(
    () => [
      {
        accessor: "id",
        filterable: false,
        className: "center",
        maxWidth: 20,
        Header: () => (
          <div style={{
            textAlign: "center"
          }}>
            #
          </div>
        )
      },
      {
        Header: t('product.link'),
        accessor: "productName",
        sortable: false
      },
      {
        Header: t('stamp.number'),
        accessor: "amount",
        filterable: false
      },
      {
        Header: "Hành động",
        accessor: "actions",
        filterable: false
      },
    ],
    [t]
  );



  const columnSubTable = [
    {
      dataField: 'stampValue',
      text: t('stamp.StampRange')
    },
    {
      dataField: 'total',
      text: t('stamp.number'),
      width: 10,
    },
    {
      dataField: 'activeTime',
      text: t('stamp.ActivatedDate'),
    }
  ]


  const usedSum = cookie.load('used', { path: '/' });

  return (

    <>
      <Header />
      <Container fluid className='mt--7'>
        <Card className="card-box mb-5">
          <div className="card-header py-3 d-flex justify-content-between">
            <div className=" font-weight-bold card-header--title font-size-lg">Tem đã kích hoạt</div>
            <div className="card-header--actions">
              <Button onClick={() => {
                props.history.push("/user/activateStamp")
              }} color="info">
                <span className="btn-wrapper--icon">
                  <PlusCircle size={16} />
                </span>
                <span className="btn-wrapper--label">Kích hoạt tem</span>
              </Button>
            </div>
          </div>
          <div className="divider" />
          <div className="card-body pt-2 px-4 pb-4">
            <ReactTable
              noDataText={t('noData')}
              data={dataStampList}
              columns={columns}
              previousText={"<"}
              nextText={">"}
              rowsText={t('row')}
              ofText="/"
              manual
              defaultPageSize={5}
              showPaginationBottom={true}
              sortable={false}
              resizable={true}
              PaginationComponent={Pagination}
              pages={totalPage}
              className="-striped -highlight"
              loading={loadingState}
              LoadingComponent={LoadingTable}
              SubComponent={row => {
                return (
                  <SubTable columns={columnSubTable} data={row.original.dataDetail} />
                )
              }}
              onFetchData={async (state, instance) => {
                setLoading(true)
                let x = state.page + 1;
                const page = x;
                const size = state.pageSize;
                const idRecord = cookie.load("diaryRecord");
                const data = {
                  maxId: 999999999999999,
                  minId: 0,
                  page: page,
                  size: size,
                  diaryLedgerUuid: idRecord
                }

                
                getUsedStampList(data)
              }}
            />
          </div>
        </Card>
      </Container>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
    userProfile: state.userProfile.data,
    usedStamp: state.usedStamp,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getUsedStampList: (data) => dispatch({ type: "GET_USED_STAMP_LIST", data: data }),
});

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(StampList));