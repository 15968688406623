import { 
    GET_FEEDBACK_LIST,
    GET_FEEDBACK_LIST_FAILURE,
    GET_FEEDBACK_LIST_SUCCESS,
   } from "actions/types"
  import { takeLatest, call, put, select } from 'redux-saga/effects'
  import { LINK_API } from 'constants/API.js'
  import axios from 'axios'
  
  const getToken = (state) => state.userInfo
  
 
// Get feedback list
function * getFeedbackList () {
    try {
      const tokenInfo = yield select(getToken)
      const response = yield call(fetchFeedback,tokenInfo.data.uuid, tokenInfo.data.token)
      const data = response.data
      yield put({ type: GET_FEEDBACK_LIST_SUCCESS, data: data.data, errorCode: data.errorCode })
    } catch (error) {
      yield put({ type: GET_FEEDBACK_LIST_FAILURE, payload: '', errorCode: 0 })
    }
  }
  function fetchFeedback (uuid, checktoken) {
    axios.defaults.headers.common.Authorization = checktoken
    return axios({
      method: 'GET',
      url: LINK_API.CUSTOMER_FEEDBACK +  "&cooperativeUuid=" + uuid
    })
  }
  
  const feedback = [
    takeLatest(GET_FEEDBACK_LIST, getFeedbackList)
  ]
  export default feedback
  
  