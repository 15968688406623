import React, { Component } from 'react'
import Header from "components/Headers/Header"
import { Container, Card, Button, Modal, ModalBody, Input } from "reactstrap"
import Draggable from 'react-draggable';
import { useDropzone } from 'react-dropzone';
import "./style.css"
import cookie from 'react-cookies';
import EditIcon from "mdi-react/PencilIcon";
import { CompactPicker } from 'react-color'
import TextBox from "../stamp-position-page/textBox"
import DeleteIcon from "mdi-react/CloseIcon";
import Loading from "components/alert/loading"
import Error from 'components/alert/error'
import Success from 'components/alert/success'
import { LINK_API } from 'constants/API'
import axios from 'axios';
import { IMG_LOADING } from "constants/image";

function EditStamp(props) {
  const [backgroundImage, setBackgroundImage] = React.useState("https://res.cloudinary.com/agridential/image/upload/v1605255300/AGDImage/embyo_e0mo73-svg_1_le5cls.png");
  const [backgroundData, setBackgroundData] = React.useState("");
  const [logoData, setLogoData] = React.useState("https://res.cloudinary.com/dinhtq98/image/upload/v1607266631/Kontum%20Project/Kontum_logo-removebg-preview_rpu0xl.png");

  const [positionEnterprise, setPositionEnterprise] = React.useState({ x: 138, y: 33, fontSize: 20, color: "#000000" });
  const [positionProduct, setPositionProduct] = React.useState({ x: 138, y: 27, fontSize: 25, color: "#000000" });
  const [positionStampID, setPositionStampID] = React.useState({ x: 138, y: 65, fontSize: 16, color: "#000000" });
  const [positionQRCode, setPositionQRCode] = React.useState({ x: 10, y: 4, height: 100 });
  const [modal, setModal] = React.useState(false);
  const [modal2, setModal2] = React.useState(false);

  const [color, setColor] = React.useState("#000");
  const [fontSize, setFontSize] = React.useState(20);
  const [heightLogo, setHeightLogo] = React.useState(20);
  const [currentField, setCurrentField] = React.useState("enterprise");

  const [listTextBox, setListTextBox] = React.useState([]);
  const [listBoxSubmit, setListBoxSubmit] = React.useState(["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""]);
  const [listLogoSubmit, setListLogoSubmit] = React.useState(["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""]);

  const [listLogo, setListLogo] = React.useState([]);
  const [numberOfLogo, setNumberOfLogo] = React.useState(0);
  const [currentLogo, setCurrentLogo] = React.useState(0);
  const [editQRCode, setEditQRCode] = React.useState(false);
  const [name, setName] = React.useState("");
  const [alert, setAlert] = React.useState(null);
  const [listLogoAdd, setListLogoAdd] = React.useState([]);
  const [listLogoDelete, setListLogoDelete] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [productName, setProductName] = React.useState("");
  const [currentStamp, setCurrentStamp] = React.useState("");
  const [userName, setUserName] = React.useState("");

  const handleEdit = (type) => {
    if (type === "enterprise") {
      setColor(positionEnterprise.color)
      setFontSize(positionEnterprise.fontSize)
      setCurrentField(type)
    }
    else if (type === "product") {
      setColor(positionProduct.color)
      setFontSize(positionProduct.fontSize)
      setCurrentField(type)
    }
    else {
      setColor(positionStampID.color)
      setFontSize(positionStampID.fontSize)
      setCurrentField(type)
    }
    setModal(true)
  }


  const handleSaveEdit = () => {
    if (currentField === "enterprise") {
      const oldPosition = positionEnterprise;
      setPositionEnterprise({
        x: oldPosition.x,
        y: oldPosition.y,
        fontSize: parseInt(fontSize),
        color: color
      })
    }
    else if (currentField === "product") {
      const oldPosition = positionProduct;
      setPositionProduct({
        x: oldPosition.x,
        y: oldPosition.y,
        fontSize: parseInt(fontSize),
        color: color
      })
    }
    else {
      const oldPosition = positionStampID;
      setPositionStampID({
        x: oldPosition.x,
        y: oldPosition.y,
        fontSize: parseInt(fontSize),
        color: color
      })
    }

    setModal(false)
  }

  const handleSaveEdit2 = () => {
    if (editQRCode) {
      setPositionQRCode({
        ...positionQRCode,
        height: parseInt(heightLogo)
      })
      setEditQRCode(false)
    }
    else {
      const newListLogo = listLogo.map(item => {
        if (item.id === currentLogo.id) {
          return {
            ...item,
            height: parseInt(heightLogo)
          }
        }
        return item
      })

      setListLogo(newListLogo)
    }
    setModal2(false)
  }

  const enterValue = () => {
    return <div>
      <div>
        <div className="mb-2">
          Màu chữ:
        </div>
        <div>
          <CompactPicker color={color} onChange={newColor => setColor(newColor.hex)} />
        </div>
      </div>
      <div className="mt-3">
        <div className="mb-2">
          Kích thước chữ:
        </div>
        <div style={{ width: 240 }}>
          <Input
            type="number"
            value={fontSize}
            onChange={(e) => setFontSize(e.target.value)}
          />
        </div>
        {
          fontSize ?
            null
            :
            <div className="text-danger font-size-sm mt-2">
              Thiếu kích thước chữ
              </div>
        }
      </div>

      <div className="d-flex mt-4">
        <Button onClick={() => setModal(false)} color="neutral-info" className="mr-3">
          Huỷ
        </Button>
        <Button disabled={!fontSize} onClick={() => handleSaveEdit()} color="info">
          Lưu
        </Button>
      </div>
    </div>
  }

  const enterValue2 = () => {

    return <div>
      <div className="mt-3">
        <div className="mb-2">
          Chiều cao:
        </div>
        <div style={{ width: 240 }}>
          <Input
            type="number"
            value={heightLogo}
            onChange={(e) => setHeightLogo(e.target.value)}
          />
        </div>
        {
          heightLogo ?
            null
            :
            <div className="text-danger font-size-sm mt-2">
              Thiếu chiều cao
            </div>
        }
      </div>
      <div className="d-flex mt-4">
        <Button onClick={() => setModal2(false)} color="neutral-info" className="mr-3">
          Huỷ
        </Button>
        <Button disabled={!fontSize} onClick={() => handleSaveEdit2()} color="info">
          Lưu
        </Button>
      </div>
    </div>
  }

  const handleModal = () => {
    setModal(!modal)
  }

  const handleModal2 = () => {
    setModal2(!modal2)
  }

  const handleRotate = (rotateAngle) => {

  }

  const handleDragEnterprise = (event, ui) => {
    const oldPosition = positionEnterprise;
    setPositionEnterprise({
      x: ui.x,
      y: ui.y,
      fontSize: oldPosition.fontSize,
      color: oldPosition.color
    })
  }

  const handleDragProduct = (event, ui) => {
    const oldPosition = positionProduct;
    setPositionProduct({
      x: ui.x,
      y: ui.y,
      fontSize: oldPosition.fontSize,
      color: oldPosition.color
    })

  }

  const handleDragID = (event, ui) => {
    const oldPosition = positionStampID;
    setPositionStampID({
      x: ui.x,
      y: ui.y,
      fontSize: oldPosition.fontSize,
      color: oldPosition.color
    })

  }

  const handleDragQR = (event, ui) => {
    setPositionQRCode({
      ...positionQRCode,
      x: ui.x,
      y: ui.y,
    })

  }

  const onStart = () => {
    // setState({ activeDrags: ++state.activeDrags });
  };

  const onStop = () => {
    // setState({ activeDrags: --state.activeDrags });
  };

  const backgroundInput = useDropzone({
    accept: 'image/jpeg, image/png, image/jpg',
    multiple: true,
    onDrop: async acceptedFiles => {
      let temp = await acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      }))
      creatFile(temp[0].preview)
      // setFiles(temp)

      setBackgroundImage(temp[0].preview)
    }
  });

  const logoInput = useDropzone({
    accept: 'image/jpeg, image/png, image/jpg',
    multiple: true,
    onDrop: async acceptedFiles => {
      let temp = await acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      }))
      creatFileLogo(temp[0].preview)
    }
  });

  const handleLogo = (data, link) => {
    setListLogo([
      ...listLogo,
      {
        value: link,
        height: 25,
        width: "auto",
        x: 15,
        y: -26,
        id: numberOfLogo,
        data: data
      }
    ])
    setListLogoAdd([
      ...listLogoAdd,
      {
        value: link,
        height: 25,
        width: "auto",
        x: 15,
        y: -26,
        id: numberOfLogo,
        data: data
      }
    ])

    setNumberOfLogo(numberOfLogo + 1)
  }

  const creatFileLogo = (link) => {
    var file_result = "";
    var xhr = new XMLHttpRequest();
    xhr.open('GET', link, true);
    xhr.responseType = 'blob';
    xhr.onload = (e) => {
      e.preventDefault()
      var reader = new FileReader();
      var recoveredBlob = xhr.response;
      reader.onloadend = () => {
        handleLogo(reader.result, link)
      };

      reader.readAsDataURL(recoveredBlob);
      file_result = (reader)
    };
    xhr.send();
    return file_result;
  }

  const creatFile = (link) => {
    var file_result = "";
    var xhr = new XMLHttpRequest();
    xhr.open('GET', link, true);
    xhr.responseType = 'blob';
    xhr.onload = (e) => {
      e.preventDefault()
      var reader = new FileReader();
      var recoveredBlob = xhr.response;
      reader.onloadend = () => {
        setBackgroundData(reader.result)
      };

      reader.readAsDataURL(recoveredBlob);
      file_result = (reader)
    };
    xhr.send();
    return file_result;
  }


  const dragHandlers = { onStart: onStart, onStop: onStop };

  const handleSetData = (value, index, positionTextBox) => {
    let newList = listBoxSubmit
    if (value) {
      newList[index] = {
        ...positionTextBox,
        value: value
      }
    }
    else {
      newList[index] = ""
    }

    setListBoxSubmit(newList)
  }

  const handleDragLogo = (e, ui, index) => {
    const newListLogo = listLogo.map(item => {
      if (item.id === index) {
        return {
          ...item,
          x: ui.x,
          y: ui.y
        }
      }
      return item
    })

    const newListLogoAdd = listLogoAdd.map(item => {
      if (item.id === index) {
        return {
          ...item,
          x: ui.x,
          y: ui.y
        }
      }
      return item
    })

    setListLogoAdd(newListLogoAdd)
    setListLogo(newListLogo)
  }

  const handleDeleteLogo = (data) => {
    const newListLogo = listLogo.map(item => {
      if (item.id === data.id) {
        return ""
      }
      return item
    })

    setListLogo(newListLogo)
    if (data.image) {
      setListLogoDelete([
        ...listLogoDelete,
        data
      ])
    }
    else {
      const newListLogoAdd = listLogoAdd.map(item => {
        if (item.id === data.id) {
          return ""
        }
        return item
      })

      setListLogoAdd(newListLogoAdd)
    }
  }

  const handleEditLogo = (item) => {
    setCurrentLogo(item)
    setHeightLogo(item.height)
    setModal2(true)
  }

  React.useEffect(() => {
    handleNewData();
  }, [])

  const handleNewData = () => {
    const data1 = props.data1;
    if (data1 && data1.choosedSampleStamp && data1.values) {
      const choosedSampleStamp = data1.choosedSampleStamp && data1.choosedSampleStamp.value ? data1.choosedSampleStamp.value : data1.choosedSampleStamp;

      setUserName(data1.userName)
      setProductName(data1.values.productName.value ? data1.values.productName.value.split("-")[0] : data1.values.productName);
      setCurrentStamp(data1.values.startID.toString().padStart(props.lengthStamp, "0"))
      setBackgroundImage(choosedSampleStamp.background && choosedSampleStamp.background.secure_url ? choosedSampleStamp.background.secure_url : "https://res.cloudinary.com/agridential/image/upload/v1605255300/AGDImage/embyo_e0mo73-svg_1_le5cls.png")
      setPositionQRCode(choosedSampleStamp.qrcode)
      setPositionStampID(choosedSampleStamp.stampid)
      setPositionProduct(choosedSampleStamp.productName)
      setPositionEnterprise(choosedSampleStamp.enterpriseName)
      setNumberOfLogo(choosedSampleStamp.logos.length)

      const logoListRender = choosedSampleStamp.logos.map((item, index) => {
        return {
          ...item,
          id: index
        }
      })

      const listTextBoxRender = choosedSampleStamp.fields.map((item, index) => {
        return {
          ...item,
          id: index,
          render: <TextBox handleSetData={handleSetData} id={index} item={item} />,
          data: item.value,
          value: item.value
        }
      })


      setListLogo(logoListRender[0] ? logoListRender : [])
      setListTextBox(listTextBoxRender[0] ? listTextBoxRender : [])
      setListBoxSubmit(listTextBoxRender)
    }
  }

  const handleClickButtonContinue = () => {
    const newListLogoAdd = listLogo.filter(item => item !== "")
    const listText = listBoxSubmit.filter(item => item !== "")

    const newListLogoAddSubmit = newListLogoAdd.map(item => {
      return {
        x: item.x,
        y: item.y,
        width: item.width,
        height: item.height,
        image: item.data ? item.data : item.image
      }
    })

    const body = {
      name: name,
      qrcode: {
        ...positionQRCode,
        width: positionQRCode.height
      },
      enterpriseName: {
        ...positionEnterprise,
        font: "Muli",
        size: positionEnterprise.fontSize,
      },
      productName: {
        ...positionProduct,
        font: "Muli",
        size: positionProduct.fontSize
      },
      stampid: {
        ...positionStampID,
        font: "Muli",
        size: positionStampID.fontSize
      },
      fields: listText,
      logos: newListLogoAddSubmit,
      background: backgroundData
        ? backgroundData
        : backgroundImage
          ? {
            secure_url: backgroundImage
          } : "https://res.cloudinary.com/agridential/image/upload/v1605255300/AGDImage/embyo_e0mo73-svg_1_le5cls.png"
    }
    props.saveData2(body)
  }

  return (
    <>
      <div className="bg-white d-flex" style={{ width: 450, flexDirection: "column" }}>
        <div className="d-flex mb-4">
          <div {...backgroundInput.getRootProps({ className: 'dropzone-upload-wrapper w-100 h-100 mr-3' })}>
            <input {...backgroundInput.getInputProps()} />
            <Button color="info">
              Đổi ảnh tem
              </Button>
          </div>
          <div {...logoInput.getRootProps({ className: 'dropzone-upload-wrapper w-100 h-100' })}>
            <input {...logoInput.getInputProps()} />
            <Button color="info">
              Thêm logo
              </Button>
          </div>
        </div>

        <div className='d-inline block stampStyle' style={{ backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundImage: `url(${backgroundImage})`, border: backgroundImage ? "0px" : "1px dashed black", borderRadius: backgroundImage ? "0px" : "10px" }}>
          <div>
            <Draggable position={positionEnterprise} {...dragHandlers} onDrag={handleDragEnterprise}>
              <div className="d-flex">
                <div style={{ fontSize: positionEnterprise.fontSize, color: positionEnterprise.color }}>{userName}</div>
                <Button onClick={() => handleEdit("enterprise")} color="link" className="p-0">
                  <EditIcon size={13} className="text-danger font-size-sm" />
                </Button>
              </div>
            </Draggable>
          </div>
          {
            listTextBox.map((item) => {
              return item.render
            })
          }
          <div style={{ position: "absolute" }}>
            <Draggable position={positionProduct} {...dragHandlers} onDrag={handleDragProduct}>
              <div className="d-flex">
                <div className="box" style={{ fontSize: positionProduct.fontSize, color: positionProduct.color }}>{productName}</div>
                <Button onClick={() => handleEdit("product")} color="link" className="p-0">
                  <EditIcon size={13} className="text-danger font-size-sm" />
                </Button>
              </div>
            </Draggable>
          </div>
          <div style={{ position: "absolute" }}>
            <Draggable position={positionStampID} {...dragHandlers} onDrag={handleDragID}>
              <div className="d-flex">
                <div className="box" style={{ fontSize: positionStampID.fontSize, color: positionStampID.color }}>{currentStamp}</div>
                <Button onClick={() => handleEdit("stamp")} color="link" className="p-0">
                  <EditIcon size={13} className="text-danger font-size-sm" />
                </Button>
              </div>
            </Draggable>
          </div>
          <div style={{ position: "absolute" }}>
            <Draggable position={positionQRCode} {...dragHandlers} onDrag={handleDragQR}>
              <div className="d-flex">
                <img src='https://res.cloudinary.com/agridential/image/upload/v1603961693/images/qr-code_sw33nc.png' style={{ height: positionQRCode.height, width: positionQRCode.height }}></img>
                <Button onClick={() => {
                  setEditQRCode(true)
                  handleEditLogo(positionQRCode)
                }} color="link" className="p-0 d-flex">
                  <EditIcon size={13} className="text-danger font-size-sm" />
                </Button>
              </div>
            </Draggable>
          </div>
          {
            listLogo.map(item => {
              return <div style={{ position: "absolute" }}>
                {
                  item.value || item.image ?
                    <Draggable position={{ x: item.x, y: item.y }} {...dragHandlers} onDrag={(e, ui) => handleDragLogo(e, ui, item.id)}>
                      <div className="d-flex">
                        <img src={item.value || item.image.secure_url} style={{ height: item.height, width: item.width }}></img>
                        <div style={{ display: "block", width: 20, height: 20, marginTop: "-20px" }}>
                          <Button onClick={() => handleDeleteLogo(item)} color="link" className="p-0" style={{ height: 5 }}>
                            <DeleteIcon size={13} className="text-danger font-size-sm" />
                          </Button>
                          <Button onClick={() => handleEditLogo(item)} color="link" className="p-0" style={{ height: 5, marginTop: "-10px" }}>
                            <EditIcon size={13} className="text-danger font-size-sm" />
                          </Button>
                        </div>
                      </div>
                    </Draggable>
                    : null
                }
              </div>
            })
          }
        </div>
        <div className="d-flex">
          <Button color="info" className="mt-4" onClick={() => handleClickButtonContinue()}>
            Tiếp tục
          </Button>

          <Button color="info" className="mt-4" onClick={() => handleNewData()}>
          Thiết lập lại
          </Button>
        </div>
      </div>
      <Modal zIndex={2000} centered isOpen={modal} toggle={handleModal}>
        <ModalBody className="d-flex justify-content-center">
          {enterValue()}
        </ModalBody>
      </Modal>
      <Modal zIndex={2000} centered isOpen={modal2} toggle={handleModal2}>
        <ModalBody className="d-flex justify-content-center">
          {enterValue2()}
        </ModalBody>
      </Modal>
    </>
  )

}

export default EditStamp