import axios from 'axios';
import LoadingTable from "components/LoadingTable/index.js";
import Pagination from "components/Pagination/index.jsx";
import { LINK_API } from 'constants/API.js';
import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ReactTable from 'react-table-6';
import "../../../css/reactTable.css";
import moment from "moment"
import Confirm from "components/alert/confirmAlert"
import Error from "components/alert/error";
import Loading from "components/alert/loading";
import Success from "components/alert/success";
import { Button, UncontrolledTooltip } from 'reactstrap';
import CloseIcon from "mdi-react/CloseIcon";
function StampList(props) {
  const [activityRender, setActivity] = React.useState([])
  const [alert, setAlert] = React.useState(null)
  const [loadingState, setLoading] = React.useState(true)
  const { t } = props;

  const {
    userInfo
  } = props;

  const genTable = React.useCallback((data) => {
    const dataRender = data.map((item, index) => {
      return {
        ...item,
        vehicleNo: item.vehicleNo || "Cập nhật sau",
        cooperativeName: item.cooperativeName || "Cập nhật sau",
        productName: item.productName || "Cập nhật sau",
        quantity: item.quantity || "Cập nhật sau",
        note: item.detail || "Cập nhật sau",
        date: moment(item.date).format("HH:mm:ss DD/MM/YYYY"),
        actions: (
          <>
           <Button onClick={() => handleWarning(item.activity_id)} color="neutral-danger" className="mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center" id={"empTooltipDeactive" + index}>
                  <span className="btn-wrapper--icon d-flex">
                    <CloseIcon
                      size={18}
                      className="text-danger font-size-sm"
                    />
                  </span>
                </Button>
                <UncontrolledTooltip trigger="hover" popperClassName="tooltip-black" placement="top" container="body" target={"empTooltipDeactive" + index}>
                 Xóa log hệ thống
                </UncontrolledTooltip>
          </>
        ),
      }
    })
    setActivity(dataRender)
    setLoading(false)
  }, [])

  const handleWarning = (id) => {
    console.log('check id')
    setAlert(<Confirm
      child={"Bạn muốn xóa hoạt động này?"}
      cancleBtnText={t('no')}
      confirmBtnText={t("yes")}
      onConfirm={() => handleDelete(id)}
      onClose={() => setAlert(null)}
    />)
  }
  const handleDelete = (uuid) => {
    setAlert(<Loading />)

    axios.delete(LINK_API.DELETE_ACTIVITY + uuid)
      .then(res => {
        if (res.data.errorCode === 1) {
          getData(props.startDate, props.endDate)
          setAlert(
            <Success
              hideCancel={true}
              onConfirm={() => setAlert(null)}
              confirmBtnText={t('close')}
              onClose={() => setAlert(null)}
            />
          )
        } else {
          setAlert(
            <Error
              onClose={() => setAlert(null)}
              confirmBtnText={t('close')}
            />
          )
        }
      })
      .catch(err => {
        setAlert(
          <Error
            onClose={() => setAlert(null)}
            confirmBtnText={t('close')}
          />
        )
      })
  }
  
  const getData = async (startDate, endDate) => {
    axios.defaults.headers.common.Authorization = userInfo.data.token
    const response = await axios({
      method: 'GET',
      url: LINK_API.GET_SYSTEM_ACTION.concat('?startTime=', startDate, '&endTime=', endDate)
    })
    if (response && response.data && response.data.data) {
      genTable(response.data.data)
    }
    else {
      genTable([])
    }
    setLoading(false)
  }

  useEffect(() => {
    setLoading(true)
    getData(props.startDate, props.endDate)

  }, [props.startDate, props.endDate]);


  const columns = React.useMemo(
    () => [
      {
        Header: "Thời gian",
        accessor: "date",
        sortable: false
      },
      {
        Header: "Tên hành động",
        accessor: "step",
        filterable: true,
        filterMethod: (filter, row) => {
          if (row.step) {
            const timeInRow = row.step.toUpperCase().search(filter.value.toUpperCase());
            if (timeInRow === -1) return false;
            return true;
          }
          return false;
        },
      },
      {
        Header: "Người thực hiện",
        accessor: "from_name",
        filterable: true,
        filterMethod: (filter, row) => {
          if (row.from_name) {
            const timeInRow = row.from_name.toUpperCase().search(filter.value.toUpperCase());
            if (timeInRow === -1) return false;
            return true;
          }
          return false;
        },
      },
      {
        Header: "Miêu tả",
        accessor: "step_desc",
        filterable: false
      },
      {
        Header: "Hành động",
        accessor: "actions",
        filterable: false
      },
    ],
    [t]
  );

  return (

    <>
      <div className="divider" />
      <div className="card-body pt-2 px-4 pb-4">
        <ReactTable
          noDataText={t('noData')}
          data={activityRender}
          columns={columns}
          previousText={"<"}
          nextText={">"}
          rowsText={t('row')}
          ofText="/"
          defaultPageSize={5}
          showPaginationBottom={true}
          sortable={false}
          resizable={true}
          PaginationComponent={Pagination}
          className="-striped -highlight"
          loading={loadingState}
          LoadingComponent={LoadingTable}
        />
         {alert}
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
    allActivity: state.allActivity
  }
}

const mapDispatchToProps = (dispatch) => ({
  getAllActivity: (data) => dispatch({ type: "GET_ALL_ACTIVITY", data: data }),
});

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(StampList));