import { 
    GET_SETTING,
    GET_SETTING_SUCCESS,
    GET_SETTING_FAILURE,
   } from "actions/types"
  import { takeLatest, call, put, select } from 'redux-saga/effects'
  import { LINK_API } from 'constants/API.js'
  import axios from 'axios'
  
  const getToken = (state) => state.userInfo
  
 
// Get user profile
function * getSetting () {
    try {
      const token = yield select(getToken)
      const response = yield call(fetchSetting, token.data.token)
      const data = response.data
      yield put({ type: GET_SETTING_SUCCESS, data: data.data[0] })
    } catch (error) {
      yield put({ type: GET_SETTING_FAILURE })
    }
  }
  function fetchSetting (checktoken) {
    axios.defaults.headers.common.Authorization = checktoken
    return axios({
      method: 'GET',
      url: LINK_API.GET_SETTING
    })
  }
  const actions = [
    takeLatest(GET_SETTING, getSetting)
  ]
  export default actions
  
  