const valueDataSheet_activity = [
  {
    name: "Ngày thực hiện",
    value: "date"
  },
  {
    name: "Tên công đoạn",
    value: "stepName"
  },
  {
    name: "Thuộc quy trình",
    value: "processName"
  },
  {
    name: "Nhân viên thực hiện",
    value: "actor"
  },
  {
    name: "Sản phẩm thực hiện",
    value: "po"
  },
  {
    name: "Sản phẩm sản xuất",
    value: "pt"
  },
  {
    name: "Vùng nguyên liệu",
    value: "pa"
  }
]

const valueDataSheet_scannedStamp = [
  {
    name: "Mã truy xuất",
    value: "stampId"
  },
  {
    name: "Thời điểm quét",
    value: "date"
  },
  {
    name: "Vị trí quét",
    value: "location"
  },
  {
    name: "Loại tem",
    value: "type"
  },
  {
    name: "Sản phẩm",
    value: "pt"
  }
]

const valueDataSheet_activatedStamp = [
  {
    name: "Đợt kích hoạt",
    value: "period"
  },
  {
    name: "Số lượng",
    value: "number"
  },
  {
    name: "Thời điểm",
    value: "date"
  },
  {
    name: "Loại tem",
    value: "type"
  },
  {
    name: "Mã bắt đầu",
    value: "startId"
  },
  {
    name: "Mã kết thúc",
    value: "endId"
  },
  {
    name: "Sản phẩm truy xuất",
    value: "pt"
  }
]

const valueDataSheet_harvest = [
  {
    name: "Mã sản phẩm",
    value: "objectUuid"
  },
  {
    name: "Tên sản phẩm",
    value: "objectName"
  },
  {
    name: "Mã hồ sơ truy xuất",
    value: "cropUuid"
  },
  {
    name: "Trạng thái",
    value: "cropStatus"
  },
  {
    name: "Ngày thu hoạch",
    value: "harvestDate"
  },
  {
    name: "Số lượng",
    value: "harvestQuantity"
  },
  {
    name: "Đơn vị",
    value: "harvestUnit"
  }
]

const valueDataSheet_diary = [
  {
    name: "Mã sản phẩm",
    value: "objectUuid"
  },
  {
    name: "Tên sản phẩm",
    value: "objectName"
  },
  {
    name: "Mã hồ sơ truy xuất",
    value: "cropUuid"
  },
  {
    name: "Trạng thái",
    value: "cropStatus"
  },
  {
    name: "Tên quy trình",
    value: "diaryName"
  },
  {
    name: "Tên bước",
    value: "stepName"
  },
  {
    name: "Ngày thực hiện",
    value: "diaryDate"
  }
]

const valueDataSheet_printStamp = [
  {
    name: "Mã sản phẩm",
    value: "productUuid"
  },
  {
    name: "Tên sản phẩm",
    value: "productName"
  },
  {
    name: "Thời gian in",
    value: "date"
  },
  {
    name: "Số lượng",
    value: "stampNo"
  },
  {
    name: "Tem bắt đầu",
    value: "startId"
  },
  {
    name: "Tem kết thúc",
    value: "endId"
  },
  {
    name: "Tên tem mẫu",
    value: "sampleStampName"
  },
  {
    name: "Loại tem",
    value: "stampType"
  }
]

const valueDataSheet_product = [
  {
    name: "Tên sản phẩm",
    value: "name"
  },
  {
    name: "UUID",
    value: "uuid"
  },
  {
    name: "Trạng thái",
    value: "status"
  }
]

const valueDataSheet_productType = [
  {
    name: "Tên sản phẩm",
    value: "name"
  },
  {
    name: "Trạng thái",
    value: "status"
  },
  {
    name: "Số tem đã kích hoạt",
    value: "stampNo"
  },
  {
    name: "Số tem đã in",
    value: "stampPrintNo"
  },
]


export {
  valueDataSheet_activity,
  valueDataSheet_scannedStamp,
  valueDataSheet_activatedStamp,
  valueDataSheet_harvest,
  valueDataSheet_diary,
  valueDataSheet_product,
  valueDataSheet_printStamp,
  valueDataSheet_productType
}

export default {
  valueDataSheet_activity,
  valueDataSheet_scannedStamp,
  valueDataSheet_activatedStamp,
  valueDataSheet_harvest,
  valueDataSheet_diary,
  valueDataSheet_product,
  valueDataSheet_printStamp,
  valueDataSheet_productType
}