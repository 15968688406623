const initialState = { data: '' }
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SAVE_PACKAGE':
      return {
        ...state
      }
    case 'SAVE_PACKAGE_SUCCESS':
      return {
        ...state,
        data: action.data,
      
      }
    case 'SAVE_PACKAGE_FAILURE':
      return {
        ...state,
        data: action.data,
        
      }
    default:
      return state
  }
}
export default reducer
