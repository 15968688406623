import PDFInput from "components/dropZone/pdfInput";
import React from "react";
import Header from "components/Headers/Header"
import {
  Button,
  Card,
  Col,
  Input,
  Row,
  Container
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import axios from 'axios'
import { LINK_API } from 'constants/API.js'
import Error from "components/alert/error";
import Loading from "components/alert/loading";
import Success from "components/alert/success";

const CreatePartner = (props) => {
  const [listPdf, setPdf] = React.useState([]);
  const [listOtherPdf, setOtherPdf] = React.useState([]);
  const [listPdfDelete, setPdfDelete] = React.useState([]);
  const [listOtherPdfDelete, setOtherPdfDelete] = React.useState([]);
  const [listPdfAdd, setPdfAdd] = React.useState([]);
  const [listOtherPdfAdd, setOtherPdfAdd] = React.useState([]);
  const [name, setName] = React.useState("");
  const [enterpriseName, setEnterpriseName] = React.useState("");
  const [tax, setTax] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [alert, setAlert] = React.useState(null)
  const [isEdit, setIsEdit] = React.useState(false)

  const handleAva = (data) => {
    if(isEdit){
      setPdfAdd(data.filter(item => !item.url))
    }
    else{
      setPdf(data);
    }
  }

  const handleAvaOther = (data) => {
    if(isEdit){
      setOtherPdfAdd(data.filter(item => !item.url))
    }
    else{
      setOtherPdf(data)
    }
  } 

  const hideAlert = () => {
    setAlert(null)
  }

  const handleSave = () => {
    if(isEdit){
      handleEdit()
    }
    else{
      setAlert(<Loading />)
      let formData = new FormData();
      formData.append('name', name);
      formData.append('address', address);
      formData.append('taxNo', tax);
      formData.append('cooperativeName', enterpriseName);
      for(let index = 0; index < listPdf.length; index ++){
        formData.append('certfood', listPdf[index]);
      }
      for(let idx = 0; idx < listOtherPdf.length; idx ++){
        formData.append('document', listOtherPdf[idx]);
      }
  
      axios({
        method: 'post',
        url: LINK_API.CREATE_PERSONAL_RECORD,
        data: formData,
        headers: {'Content-Type': 'multipart/form-data' }
        })
        .then(function () {
          getDetail()
          setAlert(<Success onConfirm={hideAlert} onClose={hideAlert} hideCancel={true} confirmBtnText='Xác nhận' />)
      })
      .catch(function () {
        setAlert(<Error onClose={hideAlert} />)
      });
    }
  }

  const handleEditCertFood = () => {
    let formDataCertFood = new FormData();
    if(listPdfDelete.length > 0 || listPdfAdd.length > 0){
      if(listPdfDelete.length > 0){
        for(let index = 0; index < listPdfDelete.length; index ++){
          formDataCertFood.append('deleteList', listPdfDelete[index]);
        }
      }
      if(listPdfAdd.length > 0){
        for(let index = 0; index < listPdfAdd.length; index ++){
          formDataCertFood.append('certfood', listPdfAdd[index]);
        }
      }

      axios({
        method: 'put',
        url: LINK_API.UPDATE_CERTFOOD,
        data: formDataCertFood,
        headers: {'Content-Type': 'multipart/form-data' }
      })
      .then(function () {
        if (listOtherPdfDelete.length > 0 || listOtherPdfAdd.length > 0){
          handleEditDocument()
        }
        else{
          getDetail()
          setAlert(<Success onConfirm={hideAlert} onClose={hideAlert} hideCancel={true} confirmBtnText='Xác nhận' />)
        }
      })
      .catch(function () {
        setAlert(<Error onClose={hideAlert} />)
      });
    }
    else if (listOtherPdfDelete.length > 0 || listOtherPdfAdd.length > 0){
      handleEditDocument()
    }
    else{
      getDetail()
      setAlert(<Success onConfirm={hideAlert} onClose={hideAlert} hideCancel={true} confirmBtnText='Xác nhận' />)
    }
  }

  const handleEditDocument = () => {
    let formDataDocument = new FormData();
    if(listOtherPdfDelete.length > 0){
      for(let index = 0; index < listOtherPdfDelete.length; index ++){
        formDataDocument.append('deleteList', listOtherPdfDelete[index]);
      }
    }
    if(listOtherPdfAdd.length > 0){
      for(let index = 0; index < listOtherPdfAdd.length; index ++){
        formDataDocument.append('document', listOtherPdfAdd[index]);
      }
    }

    axios({
      method: 'put',
      url: LINK_API.UPDATE_DOCUMENT,
      data: formDataDocument,
      headers: {'Content-Type': 'multipart/form-data' }
    })
    .then(function () {
      getDetail()
      setAlert(<Success onConfirm={hideAlert} onClose={hideAlert} hideCancel={true} confirmBtnText='Xác nhận' />)
    })
    .catch(function () {
      setAlert(<Error onClose={hideAlert} />)
    });
  }

  const handleEdit = () => {
    setAlert(<Loading />)
    let formData = new FormData();
    formData.append('name', name);
    formData.append('address', address);
    formData.append('taxNo', tax);
    formData.append('cooperativeName', enterpriseName);
    axios({
      method: 'put',
      url: LINK_API.EDIT_BASIC_INFO_PERSONAL_RECORD,
      data: formData,
      headers: {'Content-Type': 'multipart/form-data' }
      })
      .then(function () {
        handleEditCertFood()
    })
    .catch(function () {
      setAlert(<Error onClose={hideAlert} />)
    });
  }

  React.useEffect(() => {
    getDetail()
  }, [])

  const removeN = (str, num) => {
    const { length } = str;
    if(num > length){
       return str;
    };
    const newStr = str.substr(num, length - num);
    return newStr;
 };
 
  const getDetail = () => {
    setPdfDelete([]);
    setOtherPdfDelete([]);
    setPdfAdd([]);
    setOtherPdfAdd([]);
    axios.get(LINK_API.GET_DETAIL_PERSONAL_RECORD)
    .then(res => {
      if(res && res.data && res.data.data){
        if(res.data.data._id){
          setIsEdit(true)
        }
        const data = res.data.data;
        setName(data.name);
        setAddress(data.address || props.userInfo.data.address);
        setTax(data.taxNo);
        setEnterpriseName(data.cooperativeName || props.userInfo.data.name);
        if(data.certFoodPath){
          const certFood = data.certFoodPath.map((item, index) => {
            const file_name = item.split("/")[4];
            if(file_name){
              const file_name_remove_length = file_name.split("_")[0].length + 1;
              return {
                name: removeN(file_name, file_name_remove_length),
                path: item,
                url: true,
                buffer: data.certFood && data.certFood[index] && data.certFood[index].data
              }
            }
          })
          setPdf(certFood)
        }
        
        if(data.documentPath){
          const document = data.documentPath.map((item, index) => {
            const file_name = item.split("/")[4];
            if(file_name){
              const file_name_remove_length = file_name.split("_")[0].length + 1;
              return {
                name: removeN(file_name, file_name_remove_length),
                path: item,
                url: true,
                buffer: data.document && data.document[index] && data.document[index].data
              }
            }
          })
          setOtherPdf(document)
        }
      }
      else{
        setAddress(props.userInfo.data.address);
        setEnterpriseName(props.userInfo.data.name);
      }
    })
    .catch((err) => {
      setAddress(props.userInfo.data.address);
      setEnterpriseName(props.userInfo.data.name);
    })
  }

  const handleDelete = (data) => {
    if(data.url){
      setPdfDelete([...listPdfDelete, data.path])
    }
  }

  const handleDeleteOther = (data) => {
    if(data.url){
      setOtherPdfDelete([...listOtherPdfDelete, data.path])
    }
  }

  return (
    <>
      <Header />
      <Container className='mt--7 d-flex justify-content-center' fluid>
        {
          <Card className="card-box mb-5 pb-5 w-75">
            <div className="card-header py-3">
              <div className=" font-weight-bold card-header--title font-size-lg">{"Hồ sơ kinh doanh"}</div>
            </div>
            <div className="divider" />
            <Row className="px-4">
              <Col lg="6" className="mb-2">
                <div className="mb-2">Tên hồ sơ</div>
                <Input
                  placeholder={"Tên hồ sơ"}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                {!name ? (
                  <p className="font-weight-regular font-size-sm text-danger" >Bắt buộc</p>
                ) : null}
              </Col>
              <Col lg="6" className="mb-2">
                <div className="mb-2">Tên thành viên</div>
                <Input
                  placeholder={"Tên thành viên"}
                  value={enterpriseName}
                  onChange={(e) => setEnterpriseName(e.target.value)}
                />
                {!enterpriseName ? (
                  <p className="font-weight-regular font-size-sm text-danger" >Bắt buộc</p>
                ) : null}
              </Col>
              <Col lg="6" className="mb-2">
                <div className="mb-2">Mã số thuế</div>
                <Input
                  placeholder={"Mã số thuế"}
                  value={tax}
                  onChange={(e) => setTax(e.target.value)}
                />
              </Col>
              <Col lg="6" className="mb-2">
                <div className="mb-2">Địa chỉ</div>
                <Input
                  placeholder={"Địa chỉ"}
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </Col>
              <Col lg="6">
                <div>Giấy chứng nhận ATTP</div>
                <PDFInput
                  max={3}
                  onDelete={handleDelete}
                  isEdit={isEdit}
                  onConfirm={handleAva}
                  data={listPdf}
                />
              </Col>
              <Col lg="6">
                <div>Tài liệu khác</div>
                <PDFInput
                  max={3}
                  onDelete={handleDeleteOther}
                  isEdit={isEdit}
                  onConfirm={handleAvaOther}
                  data={listOtherPdf}
                />
              </Col>
              <Col className="mt-4 d-flex">
                <Button color="info" disabled={!enterpriseName || !name} onClick={() => handleSave()}> Lưu </Button>
              </Col>
            </Row>
          </Card>
        }
        {alert}
      </Container>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
    permission: state.permission
  }
}

const mapDispatchToProps = (dispatch) => ({
  createPartner: (data) => dispatch({ type: "CREATE_DISTRIBUTOR", data: data }),
});

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(CreatePartner));