import DropZone from "components/dropZone";
//formik
import { useFormik } from 'formik';
import React from "react";
import { withTranslation } from 'react-i18next';
import { Input, Button, Card, Collapse, Container } from "reactstrap";
//style
import "view/css/select.css";
import * as Yup from 'yup';

import { connect } from 'react-redux';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import ReactGoogleMapLoader from "react-google-maps-loader";
import ReactGooglePlacesSuggest from "react-google-places-suggest";
import "./css/index.css"
import Header from "components/Headers/Header"

const MY_API_KEY = "AIzaSyCB7o1cgt10O8xP9lLbk7Iikfv5ypvr528";


const CreateBranch = (props) => {
  const t = props.t;
  const [isOpen, setIsOpen] = React.useState(false);
  const [listImage, setImage] = React.useState([]);
  const toggle = () => setIsOpen(!isOpen);

  const handleAva = (data) => {
    setImage(data);
  }

  const submitData = async (value) => {
    const data = {
      "name": value.name,
      "image": "",
      "address": value.address,
      "phone": value.phone,
      "wesite": "",
      "description": (value.description === "") ? t('none') : value.description
    }
    props.handleCreateBranch(data);
    props.history.push("/distributor/branch/list");
  }

  const formik = useFormik({
    initialValues: {
      nameArea: '',
      address: '',
      phone: '',
      description: ''
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required(t('require')),
      address: Yup.string()
        .required(t('require')),
      phone: Yup.string()
        .required(t('require')),
    }),
    onSubmit: async (values) => {
      submitData(values)
    },
  });

  const handleSelectSuggest = (geocodedPrediction) => {
    formik.setFieldValue("address", geocodedPrediction.formatted_address)
    formik.setFieldValue("search", "")
  }

  const handleNoResult = () => {
    console.log('Không có kết quả')
  }

  const handleChangeAddress = name => event => {
    let values = event.target.value;
    if (values) {
      formik.setFieldValue("search", values);
      formik.setFieldValue("address", values, true);
    } else {
      formik.setFieldValue("search", values);
      formik.setFieldValue("address", values, true);
      formik.setFieldError("address", t('require'))
    }

  }

  return (
    <>
      <Header/>
    <Container className='mt--7' fluid>
      <Card className="card-box mb-5">
        <div className="wizard-steps horizontal">
          <form onSubmit={formik.handleSubmit}>
            <div className="p-4">
              <h5 className="font-size-xl font-weight-bold">{t('branch.inforBranch')}</h5>
              <p className="text-black-50 mb-4"></p>
              <div className="form-row ">
                <div className="form-group col-md-7 pr-3">
                  <div className="form-group">
                    <label className="font-weight-bold">{t('branch.name')} <span className="text-danger">(*)</span></label>
                    <Input
                      placeholder={t('branch.name')}
                      type="text"
                      name="name"
                      value={formik.values.name}
                      valid={formik.touched.name && formik.errors.name ? false : (formik.values.name ? true : false)}
                      invalid={formik.touched.name && formik.errors.name ? true : false}
                      {...formik.getFieldProps('name')}
                    />
                    {formik.touched.name && formik.errors.name ? (
                      <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.name}</p>
                    ) : null}
                  </div>

                  <div className="form-group">
                    <label className="font-weight-bold">{t('product.address')} <span className="text-danger">(*)</span></label>
                    <ReactGoogleMapLoader
                      params={{
                        key: MY_API_KEY,
                        libraries: "places,geocode",
                      }}
                      render={googleMaps =>
                        googleMaps && (
                          <div>
                            <ReactGooglePlacesSuggest
                              autocompletionRequest={{ input: formik.values.search }}
                              googleMaps={googleMaps}
                              onSelectSuggest={handleSelectSuggest}
                              displayPoweredByGoogle={false}
                              onNoResult={handleNoResult}

                            >
                              <Input
                                placeholder={t('product.address')}
                                onChange={handleChangeAddress('address')}
                                type="text"
                                name="address"
                                value={formik.values.address}
                                valid={formik.touched.address && formik.errors.address ? false : (formik.values.address ? true : false)}
                                invalid={formik.touched.address && formik.errors.address ? true : false}
                              // {...formik.getFieldProps('address')}
                              />
                            </ReactGooglePlacesSuggest>
                          </div>
                        )
                      }
                    />
                    {formik.touched.address && formik.errors.address ? (
                      <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.address}</p>
                    ) : null}
                  </div>

                  <div className="form-group">
                    <label className="font-weight-bold">{t('branch.phone')} <span className="text-danger">(*)</span></label>
                    <Input
                      placeholder={t('branch.phone')}
                      type="text"
                      name="phone"
                      value={formik.values.phone}
                      valid={formik.touched.phone && formik.errors.phone ? false : (formik.values.phone ? true : false)}
                      invalid={formik.touched.phone && formik.errors.phone ? true : false}
                      {...formik.getFieldProps('phone')}
                    />
                    {formik.touched.phone && formik.errors.phone ? (
                      <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.phone}</p>
                    ) : null}
                  </div>

                  <div>
                    <div className="form-group ">
                      <Card style={{ borderRadius: 0, boxShadow: "none", borderLeft: "none", borderRight: "none", borderTop: "none" }} className={clsx('card-box', { 'panel-open': isOpen })}>
                        <Button
                          color="link"
                          size="lg"
                          className="d-flex align-items-center justify-content-between paddingButton"
                          onClick={toggle}>
                          <strong>{t('branch.moreInfoBranch')}</strong>
                          {/* <FontAwesomeIcon
                            icon={['fas', 'angle-up']}
                            className="font-size-xl accordion-icon"
                          /> */}
                        </Button>
                        <Collapse isOpen={isOpen}>

                          <div style={{ marginTop: 10 }} className="form-group ">
                            <label className="font-weight-bold">{t('description')}</label>
                            <Input
                              placeholder={t('description')}
                              type="text"
                              value={formik.description}
                              {...formik.getFieldProps('description')}
                            />
                          </div>

                        </Collapse>
                      </Card>
                    </div>
                  </div>

                </div>

                <div
                  className="form-group d-flex justify-content-center col-md-5 pl-3"
                >

                  <div className="form-group">
                    <label className="font-weight-bold">{t('image')}</label>
                    <DropZone
                      max={1}
                      onConfirm={handleAva}
                      // sizeOut={250}
                      // sizeIn={200}
                      // mLeft={150}
                    />
                  </div>


                </div>

              </div>
              <div className="form-row">

              </div>

              <div className="form-row ">

                <div className="form-group col-md-6">
                  <p><span className="text-danger">(*)</span> Trường thông tin bắt buộc</p>
                </div>
              </div>

              <div className="form-row ">
                <div className="form-group col-md-6 d-flex">
                  <Button
                    size="lg"
                    type="submit"
                    className="text-uppercase font-weight-bold font-size-sm"
                    color="info">
                    {t('addVote')}
                  </Button>
                </div>
              </div>

            </div>

          </form>
        </div>
      </Card>
      </Container>
      {alert}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo
  }
}

const mapDispatchToProps = (dispatch) => ({
  handleCreateBranch: (data) => dispatch({ type: "CREATE_BRANCH", data: data }),
});

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(CreateBranch));