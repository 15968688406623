import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Card, Container } from "reactstrap";
import Contact from "./containers/contact";
import Header from "components/Headers/Header";

function ManagePortalPage(props) {
  // const t = props.t;

  const contactRef = React.createRef();

  const portalSetting = props.portalSetting;
  const getPortalSetting = props.getPortalSetting;

  const [state, setState] = React.useState({
    phone: "",
    email: "",
    fax: "",
    address: "",
  });

  React.useEffect(() => {
    function loadData(data) {
      if(data){
        setState({
          ...state,
          phone: data.phone,
          email: data.email,
          fax: data.fax,
          address: data.address,
        });
      }
    }
    loadData(portalSetting);
  }, [portalSetting]);

  React.useEffect(() => {
    getPortalSetting();
  }, [getPortalSetting]);

  const handleChange = () => {};

  return (
    <>
      <Header></Header>
      <Container className="mt--7" fluid>
        <Card className="card-box mb-5">
          <Contact ref={contactRef} onChange={handleChange} />
        </Card>
      </Container>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    portalSetting: state.portalSetting.data,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getPortalSetting: () => dispatch({ type: "GET_PORTAL_SETTING" }),
  };
};
export default withTranslation("common", { withRef: true })(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    ManagePortalPage
  )
);
