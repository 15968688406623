import DropZone from "components/dropZone";
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
//formik
import { useFormik } from 'formik';
import htmlToDraft from 'html-to-draftjs';
import React from "react";
import CurrencyInput from 'react-currency-input';
import { Editor } from 'react-draft-wysiwyg';
import { withTranslation } from 'react-i18next';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { connect } from "react-redux";
import { Button, CustomInput, Input } from "reactstrap";
//style
import "view/css/select.css";
import * as Yup from 'yup';
import "../index.css"
const Step1 = React.forwardRef((props, ref) => {
    const t = props.t;
    const {
        onNext,
        permission
    } = props;

    const [listImage, setImage] = React.useState([]);
    const [listCert, setCert] = React.useState([]);
    const [editorState, setEditor] = React.useState(EditorState.createEmpty());

    const handleChangeEditor = async (editorState) => {
        document.getElementById("desNoti").innerHTML = "";
        setEditor(editorState)
    }
    const handleAva = (data) => {
        setImage(data);
        document.getElementById("avaNoti").innerHTML = "";
    }
    const handleCert = (data) => {
        setCert(data)
        document.getElementById("certNoti").innerHTML = "";
    }
    const formik = useFormik({
        initialValues: {
            name: '',
            gtin: '',
            unit: 2,
            type: t('product.fruit'),
            price: ''
        },
        validationSchema: Yup.object({
            name: Yup.string()
                .required("Thông tin bắt buộc"),
            unit: Yup.string()
                .required("Thông tin bắt buộc"),
            type: Yup.string()
                .required("Thông tin bắt buộc"),
            gtin: Yup.string()
                .test(t('Mã GTIN không hợp lệ'), t('Mã GTIN không hợp lệ'),
                    function (value) {
                        return validateGtin(value);
                    }
                )
        }),
        onSubmit: React.useCallback(async (values) => {
            let dynamicContent = await draftToHtml(convertToRaw(editorState.getCurrentContent()))
            dynamicContent = await dynamicContent.toString()
            dynamicContent = await dynamicContent.replace("↵", '')
            let data = {
                ...values,
                ava: listImage,
                cert: listCert,
                htmlString: dynamicContent
            }
            //console.log(data)
            props.mergeData(data)
        }, [editorState, listCert, listImage, onNext]),
    });

    React.useImperativeHandle(ref, () => ({
        handleMergeData() {
            formik.submitForm()
        }
    }));

    const dataStepOne = props.data;
    React.useEffect(() => {
        function processData() {
            if (dataStepOne) {
                formik.setFieldValue("name", dataStepOne.name)
                formik.setFieldValue("price", dataStepOne.price)
                formik.setFieldValue("gtin", dataStepOne.gtin)
                if (dataStepOne.type) {
                    formik.setFieldValue("type", dataStepOne.type)
                }
                if (dataStepOne.unit) {
                    formik.setFieldValue("unit", dataStepOne.unit)
                }
                setImage(dataStepOne.ava)
                setCert(dataStepOne.cert)
                if (dataStepOne.htmlString) {
                    let dynamicContent = dataStepOne.htmlString;
                    let contentBlock = htmlToDraft(dynamicContent);
                    if (contentBlock && dataStepOne.htmlString && dataStepOne.htmlString !== "") {
                        let contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                        let editorState = EditorState.createWithContent(contentState);
                        setEditor(editorState)
                    }
                }
            }
        }
        processData();
    }, [dataStepOne])
    const handleChangePrice = (event, maskedvalue, floatvalue) => {
        formik.setFieldValue("price", maskedvalue, true)
    }

    const validateGtin = function (valueInt) {

        if (!valueInt) return true;
        const value = valueInt.toString();
        var barcode = value.substring(0, value.length - 1);
        var checksum = parseInt(value.substring(value.length - 1), 10);
        var calcSum = 0;
        var calcChecksum = 0;

        barcode.split('').map(function (number, index) {
            number = parseInt(number, 10);
            if (value.length % 2 === 0) {
                index += 1;
            }
            if (index % 2 === 0) {
                calcSum += number;
            }
            else {
                calcSum += number * 3;
            }
        });

        calcSum %= 10;
        calcChecksum = (calcSum === 0) ? 0 : (10 - calcSum);

        if (calcChecksum !== checksum) {
            return false;
        }

        return true;

    };
    return (
        <>
            <div className="wizard-steps horizontal">
                <form onSubmit={formik.handleSubmit}>
                    <div className="p-4">
                        <h5 className="font-size-xl font-weight-bold">{t('product.productInfo')}</h5>
                        <p className="text-black-50 mb-4">{t('product.introProduct')}</p>
                        <div className="form-row">
                            <div className="form-group col-md-6 pr-3">
                                <label className="font-weight-bold">{"Tên nhóm sản phẩm"} <span className="text-danger">(*)</span></label>
                                <Input
                                    placeholder={"Tên nhóm sản phẩm"}
                                    type="text"
                                    value={formik.values.name}
                                    valid={formik.touched.name && formik.errors.name ? false : (formik.values.name ? true : false)}
                                    invalid={formik.touched.name && formik.errors.name ? true : false}
                                    {...formik.getFieldProps('name')}
                                />
                                {formik.touched.name && formik.errors.name ? (
                                    <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.name}</p>
                                ) : null}
                            </div>
                            <div className="form-group col-md-6 pl-3">
                                <label className="font-weight-bold">{t('product.from')} <span className="text-danger">(*)</span></label>
                                <CustomInput type="select" id="selectUnit" placeholder={t('product.from')}
                                    value={formik.values.unit}
                                    onChange={formik.handleChange}
                                    valid={formik.touched.unit && formik.errors.unit ? false : (formik.values.unit ? true : false)}
                                    invalid={formik.touched.unit && formik.errors.unit ? true : false}
                                    name="customSelect"
                                    {...formik.getFieldProps('unit')}
                                >
                                    {/* <option value="">{t('product.from')}</option> */}
                                    <option value={2}>{t('product.product')}</option>
                                    <option value={1}>{t('product.kg')}</option>
                                </CustomInput>
                                {formik.touched.unit && formik.errors.unit ? (
                                    <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.unit}</p>
                                ) : null}
                            </div>
                        </div>

                        <div className="form-row ">
                            {/* <div className="form-group col-md-6 pr-3">
                                <label className="font-weight-bold">{t('product.retailPrice')}</label>
                                <CurrencyInput
                                    className={formik.touched.price && formik.errors.price ? false : (formik.values.price ? "form-control is-valid" : "form-control")}
                                    placeholder={t('product.retailPrice')}
                                    value={formik.values.price}
                                    precision={0}
                                    maxLength="11"
                                    valid={formik.touched.price && formik.errors.price ? false : (formik.values.price ? true : false)}
                                    onChangeEvent={handleChangePrice} />
                            </div> */}
                            <div className="form-group col-md-6 pr-3">
                                <label className="font-weight-bold">{"Loại"} <span className="text-danger">(*)</span></label>
                                <CustomInput
                                    type="select"
                                    name="customSelect"
                                    id="selectType"
                                    placeholder={"Loại"}
                                    value={formik.values.type}
                                    valid={formik.touched.type && formik.errors.type ? false : (formik.values.type ? true : false)}
                                    invalid={formik.touched.type && formik.errors.type ? true : false}
                                    onChange={formik.handleChange}
                                    {...formik.getFieldProps('type')}
                                >
                                    <option value={t('product.fruit')}>{t('product.fruit')}</option>
                                    {/* <option value="">{t('product.link')}</option> */}
                                    <option value={t('product.meat')}>{t('product.meat')}</option>
                                    <option value={t('product.veget')}>{t('product.veget')}</option>
                                    <option value={t('product.cold')}>{t('product.cold')}</option>
                                    <option value={t('product.spice')}>{t('product.spice')}</option>
                                    <option value={t('product.dry')}>{t('product.dry')}</option>
                                    <option value={t('product.beer')}>{t('product.beer')}</option>
                                    <option value={t('product.milk')}>{t('product.milk')}</option>
                                </CustomInput>
                                {formik.touched.type && formik.errors.type ? (
                                    <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.type}</p>
                                ) : null}
                            </div>
                        </div>
                        {/* <div className="form-row ">
                            <div className="form-group col-md-6 pr-3">
                                <label className="font-weight-bold" >{t('product.gtin')}</label>
                                <Input
                                    validate={validateGtin}
                                    placeholder={t('product.gtin')}
                                    type="number"
                                    valid={formik.touched.gtin && formik.errors.gtin ? false : (formik.values.gtin ? true : false)}
                                    invalid={formik.touched.gtin && formik.errors.gtin ? true : false}
                                    value={formik.values.gtin}
                                    {...formik.getFieldProps('gtin')}
                                />
                                {formik.touched.gtin && formik.errors.gtin ? (
                                    <p className="font-weight-regular font-size-sm text-danger" >{formik.errors.gtin}</p>
                                ) : null}
                            </div>
                        </div> */}
                        {/* <div className="form-row">
                            <div className="form-group col-md-6 pr-3">
                                <label className="font-weight-bold">{t('product.image')}</label>
                                <DropZone
                                    max={3}
                                    onConfirm={handleAva}
                                    data={listImage}
                                />
                                <p id="avaNoti" className="font-weight-regular font-size-sm text-danger" ></p>
                            </div>

                            <div className="form-group col-md-6 pl-3">
                                <label className="font-weight-bold">{t('product.cert')} </label>
                                <DropZone max={3} onConfirm={handleCert} data={listCert} />
                                <p id="certNoti" className="font-weight-regular font-size-sm text-danger" ></p>
                            </div>
                        </div>
                        <div className="form-row ">
                            <div className="form-group col-md-12">
                                <label className="font-weight-bold">{t('product.productInfo')} </label>
                                <Editor
                                    editorState={editorState}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor borderEditor"
                                    onEditorStateChange={handleChangeEditor}
                                    toolbar={{
                                        options: ["inline", 'image', 'embedded', 'textAlign', "link",]
                                    }}
                                />
                                <p id="desNoti" className="font-weight-regular font-size-sm text-danger" ></p>
                            </div>
                        </div> */}
                        <div className="form-row ">
                            <div className="form-group col-md-12">
                                <p><span className="text-danger">(*) </span> {t('require')}</p>
                            </div>
                            <div className="form-group col-md-12 d-flex">
                                <Button
                                    size="lg"
                                    type="submit"
                                    className="text-uppercase font-weight-bold font-size-sm"
                                    color="neutral-success">
                                    {t('confirm')}
                                </Button>

                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
})

const mapStateToProps = (state) => {
    return {
        permission: state.permission.data
    }
}

const mapDispatchToProps = (dispatch) => ({
    saveData: (dataOne, check) => dispatch({ type: "SAVE_DATA_EDIT_PRODUCT", dataOne: dataOne, check: check }),
});

export default withTranslation('common', { withRef: true })(connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Step1));