import {
    GET_EMPLOYEE_LIST,
    GET_EMPLOYEE_LIST_FAILURE,
    GET_EMPLOYEE_LIST_FILTER,
    GET_EMPLOYEE_LIST_SUCCESS,
} from "actions/types"
import { takeLatest, call, put, select } from 'redux-saga/effects'
import { LINK_API } from 'constants/API.js'
import axios from 'axios'

const getToken = (state) => state.userInfo
const getToTalItemEmployee = (state) => state.employeeList.totalItem
const getFullEmployeeList = (state) => state.employeeList.fullData
//const getSaveSetting = (state) => state.settingList.data

function* getEmployeeList(page) {
    try {
        const tokenInfo = yield select(getToken)
        const fullData = yield select(getFullEmployeeList)
        const totalItem = yield select(getToTalItemEmployee)
        const size = page.size;
        if (fullData[`${page.page}`]) {
            yield put({
                type: GET_EMPLOYEE_LIST_SUCCESS,
                data: fullData[`${page.page}`],
                fullData: fullData
            })
        }
        const response = yield call(fetchEmployee, page.page, size, tokenInfo.data.token)
        const data = response.data.data
        if (totalItem !== response.data.totalItem) {
            const newList = data
            fullData[`${page.page}`] = newList
            yield put({
                type: GET_EMPLOYEE_LIST_SUCCESS,
                data: newList,
                errorCode: response.data.errorCode,
                totalItem: response.data.totalItem,
                totalPage: response.data.totalPage,
                fullData: fullData
            })
        } else {
            if (!fullData[`${page.page}`]) {
                const newList = data
                fullData[`${page.page}`] = newList
                yield put({
                    type: GET_EMPLOYEE_LIST_SUCCESS,
                    data: newList,
                    errorCode: response.data.errorCode,
                    totalItem: response.data.totalItem,
                    totalPage: response.data.totalPage,
                    fullData: fullData
                })
            } else {
                if (fullData[`${page.page}`] !== data) {
                    const newList = data
                    fullData[`${page.page}`] = newList
                    yield put({
                        type: GET_EMPLOYEE_LIST_SUCCESS,
                        data: newList,
                        errorCode: response.data.errorCode,
                        totalItem: response.data.totalItem,
                        totalPage: response.data.totalPage,
                        fullData: fullData
                    })
                }
            }
        }
    } catch (error) {
        yield put({ type: GET_EMPLOYEE_LIST_FAILURE, payload: '', errorCode: 0 })
    }
}
function fetchEmployee(page, size, checktoken) {
    axios.defaults.headers.common.Authorization = checktoken
    return axios({
        method: 'GET',
        url: LINK_API.GET_EMPLOYEE_LIST.concat('?', 'page=', page, '&size=', size)
    })
}

function * getEmployeeListFilter (page) {
    try {
      const tokenInfo = yield select(getToken)
      const fullData = yield select(getFullEmployeeList)
      const size = page.size;
      const totalItem = yield select(getToTalItemEmployee)
      if (fullData[`${page.page}`]) {
        yield put({
          type: GET_EMPLOYEE_LIST_SUCCESS,
          data: fullData[`${page.page}`],
          fullData: fullData
        })
      }
      const response = yield call(fetchEmployeeFilter, page.page, page.name, page.value, tokenInfo.data.token, size)
      const data = response.data.data
      if (totalItem !== response.data.totalItem) {
        const newList = data
        fullData[`${page.page}`] = newList
        yield put({
          type: GET_EMPLOYEE_LIST_SUCCESS,
          data: newList,
          errorCode: response.data.errorCode,
          totalItem: response.data.totalItem,
          totalPage: response.data.totalPage,
          fullData: fullData
        })
      } else {
        if (!fullData[`${page.page}`]) {
          const newList = data
          fullData[`${page.page}`] = newList
          yield put({
            type: GET_EMPLOYEE_LIST_SUCCESS,
            data: newList,
            errorCode: response.data.errorCode,
            totalItem: response.data.totalItem,
            totalPage: response.data.totalPage,
            fullData: fullData
          })
        }
      }
    } catch (error) {
      yield put({ type: GET_EMPLOYEE_LIST_FAILURE, payload: '', errorCode: 0 })
    }
  }
  function fetchEmployeeFilter (page, name, value, checktoken, size) {
    axios.defaults.headers.common.Authorization = checktoken
    return axios({
      method: 'GET',
      url: LINK_API.GET_EMPLOYEE_LIST.concat( '?page=' , page , '&size=' , size , '&' , name , '=' , value)
    })
  }


const employeeRoot = [
    takeLatest(GET_EMPLOYEE_LIST, getEmployeeList),
    takeLatest(GET_EMPLOYEE_LIST_FILTER, getEmployeeListFilter)
]
export default employeeRoot

