const Breadcrumb = {
    '/product/list': [
        {
            name: "Nhóm sản phẩm",
            path: "/product/list"
        }
    ],
  
    '/user/productEdit': [
        {
            name: "Nhóm sản phẩm",
            path: "/productType"
        },
        {
            name: "Chỉnh sửa nhóm sản phẩm",
            path: "/user/productEdit"
        }
    ],
   
   
    '/user/areaEdit': [
        {
            name: "Vùng nguyên liệu",
            path: "/user/area"
        },
        {
            name: "Chỉnh sửa vùng nguyên liệu",
            path: "/user/areaEdit"
        }
    ],
    '/oProduction/list': [
        {
            name: "Danh sánh sản phẩm",
            path: "/oProduction/list"
        }
    ],
    '/oProduction/create': [
        {
            name: "Danh sánh sản phẩm",
            path: "/oProduction/list"
        },
        {
            name: "Tạo sản phẩm",
            path: "/oProduction/create"
        }
    ],
    '/user/detailObjectProduction': [
        {
            name: "Danh sánh sản phẩm",
            path: "/objectProduction"
        },
        {
            name: "Chi tiết sản phẩm",
            path: "/user/detailObjectProduction"
        }
    ],
    '/employee/crate': [
        {
            name: "Nhân viên",
            path: "/employee/list"
        },
        {
            name: "Tạo nhân viên",
            path: "/employee/create"
        },
    ],
    '/user/editEmployee': [
        {
            name: "Nhân viên",
            path: "/user/employee"
        },
        {
            name: "Chỉnh sửa nhân viên",
            path: "/user/editEmployee"
        },
    ],
    '/process/list': [
        {
            name: "Quy trình",
            path: "/process/list"
        },
    ],
    '/process/create': [
        {
            name: "Quy trình",
            path: "/process/list"
        },
        {
            name: "Tạo quy trình",
            path: "/process/create"
        },
    ],
    '/process/edit': [
        {
            name: "Quy trình",
            path: "/process/list"
        },
        {
            name: "Chỉnh sửa quy trình",
            path: "/process/edit"
        },
    ],
    '/partner/list': [
        {
            name: "Đối tác",
            path: "/partner/list"
        },
    ],
    '/partner/create': [
        {
            name: "Đối tác",
            path: "/partner/list"
        },
        {
            name: "Thêm đối tác",
            path: "/partner/create"
        },
    ],
    '/partner/detail': [
        {
            name: "Đối tác",
            path: "/partner/list"
        },
        {
            name: "Thông tin nhà phân phối",
            path: "/partner/detail"
        },
    ],
    '/activities/list': [
        {
            name: "Lịch sử cập nhật",
            path: '/activities/list'
        }
    ],
    '/registrationHistory/list': [
        {
            name: "Lịch sử đăng ký",
            path: '/registrationHistory/list'
        }
    ],
    '/registrationHistory/detail': [
        {
            name: "Lịch sử đăng ký",
            path: '/registrationHistory/list'
        },
        {
            name: "Thông tin đăng ký",
            path: '/registrationHistory/detail'
        }
    ],
    '/feedback/list': [
        {
            name: "Phản hồi",
            path: '/feedback/list'
        }
    ],
    '/stamp/list': [
        {
            name: "Quản lý tem",
            path: '/stamp/list'
        }
    ],
    '/stamp/usedStamp': [
        {
            name: "Quản lý tem",
            path: '/stamp/list'
        },
        {
            name: "Chi tiết",
            path: '/stamp/usedStamp'
        }
    ],
    '/package': [
        {
            name: "Quản lý gói",
            path: '/package'
        },
    ],
    '/user/profile': [
        {
            name: "Thông tin tài khoản",
            path: '/user/profile'
        },
    ],
    '/admin/profile': [
        {
            name: "Thông tin tài khoản",
            path: '/admin/profile'
        },
    ],
    '/distributor/profile': [
        {
            name: "Thông tin tài khoản",
            path: '/distributor/profile'
        },
    ]
}
export default Breadcrumb