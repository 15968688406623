import axios from "axios";
import CloseBoxIcon from "mdi-react/CloseBoxIcon";
import React from "react";
import AvatarEditor from 'react-avatar-editor';
import { useDropzone } from 'react-dropzone';
import { Check, UploadCloud, X } from 'react-feather';
import { withTranslation } from 'react-i18next';
import { Button, UncontrolledTooltip } from "reactstrap";
import "./css/dropZone.css";
import ConfirmAlert from "components/alert/confirmAlert"

const DropZone = React.forwardRef((props, ref) => {
    const maxImage = props.max;
    const sizeOut = props.sizeOut ? props.sizeOut : "90";
    const sizeIn = props.sizeIn ? props.sizeIn : "60"
    // const sizeIcon = props.sizeIcon ? props.sizeIcon : "30"
    // const mTop = props.mTop ? props.mTop : "-50px"
    // const mLeft = props.mLeft ? props.mLeft : "53px"
    const disabled = props.disabled ? props.disabled : false;
    const apiStatus = props.api;
    const t = props.t;
    const [list, setList] = React.useState([]);
    const [height, setHeight] = React.useState(300);
    const [width, setWidth] = React.useState(300)
    const [alert, setAlert] = React.useState(null)
    const propsData = props.data;
    const {
        isDragActive,
        isDragAccept,
        isDragReject, getRootProps, getInputProps } = useDropzone({
            accept: 'image/jpeg, image/png, image/jpg',
            multiple: true,
            onDrop: async acceptedFiles => {
                let temp = await acceptedFiles.map(file => Object.assign(file, {
                    preview: URL.createObjectURL(file)
                }))
                if (temp[0].size > 2097152) {
                    handleWarning();
                } else {
                    creatFile(temp[0].preview)
                }
            }
        });
    const [banner, setBanner] = React.useState("");

    const hideAlert = () => {
        setAlert(null)
    }

    const handleWarning = () => {
        setAlert(<ConfirmAlert
            child='Ảnh có kích thước lớn!'
            content='Vui lòng tải lên ảnh có kích thước nhỏ hơn 2MB'
            onConfirm={hideAlert}
            hideCancel={true}
        />)
    }

    React.useImperativeHandle(ref, () => ({
        handleUpload() {
            if (cropper) {
                let banner = cropper.getImageScaledToCanvas().toDataURL();
                if (banner) {
                    handleAddApi(banner)
                }
            }
        }
    }));

    const handleAddApi = (data) => {
        if (data) {
            if (props.setLoad) {
                props.setLoad();
            }
            let data_send = {
                banner: data
            }
            axios.post(props.path, data_send)
                .then(async res => {
                    if (res.data.errorCode === 1) {
                        if (props.reload) {
                            props.reload('banner');
                        }
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }

    const creatFile = (link) => {
        var file_result = "";
        var xhr = new XMLHttpRequest();
        xhr.open('GET', link, true);
        xhr.responseType = 'blob';
        xhr.onload = (e) => {
            e.preventDefault()
            var reader = new FileReader();
            var recoveredBlob = xhr.response;
            reader.onloadend = () => {
                handleData(reader)
            };

            reader.readAsDataURL(recoveredBlob);
            file_result = (reader)
        };
        xhr.send();
        return file_result;
    }
    const handleData = async (data) => {
        let temp = list;
        setBanner(data.result)
        if (apiStatus) {
            handleAddApi(data.result)
        }
        await temp.push(data.result);
        await setList([...temp])
        if (props.onConfirm) {
            props.onConfirm(temp)
        }

    }
    React.useEffect(() => {
        if (propsData) {
            if (propsData.length > 0) {
                setList([...propsData])
            }
        }
    }, [propsData])
    const [cropper, setCrop] = React.useState("");
    const setRef = (editor) => setCrop(editor)

    React.useEffect(() => {
        let result_height = document.getElementById("myDiv").offsetHeight;
        setHeight(result_height)
        let result_width = document.getElementById("myDiv").offsetWidth;
        setWidth(result_width)
    }, [])
    return (
        <>

            <div id="myDiv" className="d-flex flex-row mt-1 w-100 h-100">
                {list.map((img, index) => (
                    <AvatarEditor
                        key={index}
                        image={img}
                        width={width}
                        height={height}
                        border={1}
                        color={[0, 0, 0, 1]}
                        scale={1}
                        rotate={0}
                        ref={setRef}
                    />
                ))}
                {list.length < maxImage ?
                    <div className="w-100 h-100">
                        {!disabled ?
                            <div {...getRootProps({ className: 'dropzone-upload-wrapper w-100 h-100' })}>
                                <input {...getInputProps()} />
                                <div className="dropzone-inner-wrapper dropzone-inner-wrapper-alt borderBlack h-100 w-100">
                                    {isDragAccept && (
                                        <div>
                                            <div className="d-90 btn-icon mb-3 hover-scale-rounded bg-success shadow-success-sm rounded-lg text-white">
                                                <Check className="d-50" />
                                            </div>
                                        </div>
                                    )}
                                    {isDragReject && (
                                        <div>
                                            <div className="d-100 btn-icon mb-3 hover-scale-rounded bg-danger shadow-danger-sm rounded-lg text-white">
                                                <X className="d-90" />
                                            </div>

                                        </div>
                                    )}
                                    {!isDragActive && (
                                        <div className={"d-flex justify-content-center align-items-center card-img h-100"}>
                                            <div className={"btn-icon hover-scale-rounded bg-white shadow-light-sm rounded-lg text-first d-200 d-flex justify-content-center align-items-center"} style={{ height: "100px", width: "100px" }}>
                                                <UploadCloud size='50px' />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div> :
                            <div className='dropzone-upload-wrapper'>
                                <div className="dropzone-inner-wrapper dropzone-inner-wrapper-alt borderBlack notAllowedActions">
                                    <div className={"d-flex justify-content-center align-items-center d-" + sizeOut} style={{ height: sizeOut, width: sizeOut }}>
                                        <div id="disabledIcon" className={"d-flex justify-content-center align-items-center hover-scale-rounded shadow-light-sm rounded-lg text-first notUpload d-" + sizeIn} style={{ height: sizeIn, width: sizeIn }}>
                                            <CloseBoxIcon className={"text-danger d-90"} />
                                            <UncontrolledTooltip trigger="hover" popperClassName="tooltip-black" placement="top" container="body" target={"disabledIcon"}>
                                                {t('notAllowed')}
                                            </UncontrolledTooltip>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                    </div>
                    : null}

                {alert}
            </div>

        </>
    )
})
export default withTranslation('common', { withRef: true })(DropZone);