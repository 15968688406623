import {
    GET_GENERAL,
    GET_GENERAL_SUCCESS,
    GET_GENERAL_FAILURE,
  } from "actions/types";
  import { takeLatest, call, put, select } from 'redux-saga/effects'
  import { LINK_API } from 'constants/API.js'
  import axios from 'axios'
  const getToken = (state) => state.userInfo;
  
  // Get user profile
  function* getGeneral(payload) {
    try {
      const token = yield select(getToken)
      const response = yield call(fetchScan, payload.startTime, payload.endTime, token.data.token);
      yield put({ type: GET_GENERAL_SUCCESS, data: response.data.data })
    } catch (error) {
      yield put({ type: GET_GENERAL_FAILURE })
    }
  }
  
  function fetchScan(startTime, endTime, checktoken) {
    axios.defaults.headers.common.Authorization = checktoken
    return axios({
      method: 'GET',
      url: LINK_API.GET_GENERAL_STATISTIC + "startTime=" + startTime + "&endTime=" + endTime
    })
  }
  
  const actions = [
    takeLatest(GET_GENERAL, getGeneral)
  ]
  export default actions
  
  